import React, { useState, useEffect } from "react";
import { BiUpload } from "react-icons/bi";
import { Tooltip, Modal } from "antd";
import { CloseOutlined } from '@ant-design/icons'; // Import CloseOutlined icon
import "./input-component.css";
import { CgAttachment } from "react-icons/cg";

const DocumentUpload = ({
  imageSrc,
  inputRef,
  handleFileChange,
  onClickHandler,
  isCircle = false,
  isPersonalImage = false,
  label = "Upload Image",
  isMandatory = false,
  disableStyle = true,
  images,
  onImagesSorted,
  attachment
}) => {

  const [uploadState, setUploadState] = useState(images);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);

  useEffect(() => {
    setUploadState(images);
  }, [images]);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDragStart = (index) => {
    setIsDragging(true);
    setDraggedIndex(index);
  };

  const handleDragEnd = (index) => {
    setIsDragging(false);
    if (draggedIndex !== null && draggedIndex !== index) {
      const reorderedImages = [...uploadState];
      const [removed] = reorderedImages.splice(draggedIndex, 1);
      reorderedImages.splice(index, 0, removed);
      setUploadState(reorderedImages);
      if (onImagesSorted) onImagesSorted(reorderedImages);
    }
    setDraggedIndex(null);
  };

  const handleDragOver = (index, e) => {
    e.preventDefault();
  };

  const handleDrop = (index) => {
    if (draggedIndex !== null && draggedIndex !== index) {
      const reorderedImages = [...uploadState];
      const [removed] = reorderedImages.splice(draggedIndex, 1);
      reorderedImages.splice(index, 0, removed);
      setUploadState(reorderedImages);
      if (onImagesSorted) onImagesSorted(reorderedImages);
    }
    setIsDragging(false);
    setDraggedIndex(null);
  };

  // Remove image
  const removeImage = (index) => {
    const updatedImages = uploadState.filter((_, i) => i !== index);
    console.log(updatedImages,'updatedImages')
    setUploadState(updatedImages);
    if (onImagesSorted) onImagesSorted(updatedImages);
  };

  return (
    <div className={attachment === undefined ? "upload-container": ''} style={{margin:label==="" ? "0px" : '20px 10px'}}>
    {attachment === undefined ?
      <label className={"upload-label"}>
        {label} {isMandatory && <span style={{ color: "red" }}>*</span>}
      </label> : null }
      <div
        className={attachment === undefined ? `upload-box ${disableStyle ? "" : "custom-disabled-style"} ${isCircle ? "circle-shape" : ""}`:''}
        onClick={onClickHandler ? onClickHandler : () => inputRef.current?.click()}
        style={{ cursor: disableStyle ? "pointer" : "not-allowed" }}
      >
        <div className="upload-content" style={{float: attachment ? "right" : "none"}}>
          {imageSrc ? (
            <img
              src={imageSrc}
              alt="Uploaded Preview"
              className={`uploaded-image ${isCircle ? "circle-image" : ""}`}
            />
          ) : (
            <>
            {attachment ?
              <CgAttachment title="Attachment" className="upload-icon" /> :
              <BiUpload title="Upload Document" className="upload-icon" />}
              {attachment === undefined ? <span>Click to upload</span> : null }
              {attachment === undefined ? <span className="supported-files">Files supported: JPG, PNG & PDF</span>: null}
            </>
          )}
        </div>
        <input
          ref={inputRef}
          type="file"
          onChange={handleFileChange}
          className="upload-input"
          name="upload"
          hidden
          multiple
          disabled={!disableStyle}
        />
      </div>

      <div className=" mt-3">
        {uploadState.length > 0 && (
          <div style={{background:'white'}}>
            {uploadState.map((img, index) => (
              <div
                key={index}
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragEnd={() => handleDragEnd(index)}
                onDragOver={(e) => handleDragOver(index, e)}
                onDrop={() => handleDrop(index)}
                style={{
                  margin: "10px",
                  cursor: "move",
                  display: "inline-block",
                  position: "relative", // Position relative to place the close icon
                  border: isDragging && draggedIndex === index ? "2px solid blue" : "none",
                }}
              >
                <img
                  src={img.src}
                  alt={`Image ${index + 1}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    openModal(index);
                  }}
                  className="thumbnail-image"
                  style={{ width: "100px", height: "100px"}}
                />
                {/* Close icon for removing image */}
                <CloseOutlined
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the modal from opening
                    removeImage(index); // Remove the image
                  }}
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    background: "#f0f0f0",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
              </div>
            ))}
            <Modal visible={isModalOpen} onCancel={closeModal} footer={null} width={600}>
              <div className="modal-content">
                <div className="main-image-container">
                  <img
                    src={uploadState[currentImageIndex].src}
                    alt={`Property Large ${currentImageIndex + 1}`}
                    className="main-image"
                  />
                </div>
                <div className="modal-thumbnail-gallery">
                  {uploadState.map((upload, index) => (
                    <img
                      key={index}
                      src={upload.src}
                      alt={`Thumbnail ${index + 1}`}
                      onClick={() => setCurrentImageIndex(index)}
                      className={`modal-thumbnail ${index === currentImageIndex ? "active-thumbnail" : ""}`}
                      style={{ width: "100px", height: "100px", cursor: "pointer" }}
                    />
                  ))}
                </div>
              </div>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentUpload;
