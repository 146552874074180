import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {getmaintenancelistfortradesman,reviewedbyagent,} from "../../../components/CommonSection/apiresources";
import {GetCookie,currentProfile,} from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import "../../../components/Dashboard/Dashboard-agent/AgentList.css";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { ActionStatus, eMaintenanceStatus } from "../../../Utilities/enum";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import "../../Components/openHome/openHome.css";
import { FormatDate } from "../../../components/CommonSection/Utility/Utility";
import CustomModal from "../../input-components/Modal";
import SelectBox from "../../input-components/selectBoxNew";
import TextArea from "../../input-components/textarea";
import SubNav from "../../../components/CommonSection/Header/subHeader";
import AntdTableExample from "../../input-components/dataTable";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";

const ListMaintainanceTrade = () => {
  const [maintainancelist, setMaintainanceList] = useState([]);
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agentID, setAgentId] = useState("");
  const navigate = useNavigate();
  const ToastRef = useRef();
  const [searchText, setSearchText] = useState("");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayTable, setDisplaytable] = useState(true);
  const [reason, setReason] = useState("");
  const [reviewStatus, setReviewStatus] = useState("");
  const [rowData, setRowData] = useState({});
  const [reviewStatusArr, setReviewStatusArr] = useState([
    { value: 2, label: "Approved" },
    { value: 3, label: "Rejected" },
  ]);
  const [openModal, setOpenModal] = useState(false);
  const [userUniqueId, setUserUniqueId] = useState(false);
  const [purpose, setPurpose] = useState(2);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.supplierId);
          setAgentId(parsedData.agentId);
          setUserUniqueId(parsedData.userUnique_ID)
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) PostApiWithSpinner();
  }, [userId, searchText,purpose]);

  const PostApiWithSpinner = (currentPage, totalRows) => {
    setDisplaytable(false);
    const baseUrl = getmaintenancelistfortradesman;
    let data = {
      TradeId: agencyID,
      Search: searchText,
      Status: purpose
    };
    //data = addParametersForProfiles(data);
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        setDisplaytable(true);
        if (response.status === ActionStatus.Successfull) {
          if (response.list.length > 0) {
            let issueData = response.list;
            let issueList = issueData.map((issueDetails) => ({
              Title: issueDetails.title,
              Description: issueDetails.description,
              CreatedDate: FormatDate(issueDetails.createdAt),
              maintenanceUniqueId: issueDetails.maintenanceUniqueId,
              ReportType: issueDetails.sRepairType,
              maintainanceId: issueDetails.maintenanceId,
              eStatus: issueDetails.status,
              isNew : issueDetails.isNew,
              Tenant: issueDetails.tenantName,
              Status: issueDetails.sStatus,
            }));

            setMaintainanceList(issueList);
            setTotalPages(response.totalCount);
          } else {
            //ToastRef.current.notify(["warn", "User not added"]);
          }
        } else {
          ToastRef.current.notify(["error", response.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", response.message]);
      });
  };

  const handleFinalSubmit = () => {
    let formData = {
      MaintenanceId: rowData.maintainanceId,
      AgentId: parseInt(agentID),
      ReviewStatus: parseInt(reviewStatus),
      ReasonOfRejection: reason,
    };
    const url = reviewedbyagent;
    //formData = addParametersForProfiles(formData);
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          PostApiWithSpinner();
          setOpenModal(false);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handlePageChange = (event, pageNumber) => {
    PostApiWithSpinner(event.page + 1, event.rows);
    setCurrentPage(pageNumber);
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleEditClick = (e,rowData) => {
    e.stopPropagation()
    e.preventDefault()
    setReason("");
    setReviewStatus(null);
    setRowData(rowData);
    setOpenModal(true);
  };


  const actions = [
    { icon: "/assets/images/pen.svg", onClick: (e, data) => handleEditClick(e, data),condition: (record) => eMaintenanceStatus.Approved > record.eStatus  },
    { icon: "/assets/images/dots.svg",key:"View", onClick: (e, data) => handleRowClick(e, data) },
]



const tabColumns = {
  Status: {
      colorMap: {
          "Report Repair": "#5499c7",
          "Repair Rejected": "#ec7063",
          "Reschedule": "#f5b041 ",
      },
      defaultColor: 'blue'
  }
};

  const handleInputChange = (e, label) => {
    if (label === "reason") {
      setReason(e.target.value);
    } else if (label === "status") {
      setReviewStatus(e.value);
    }
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleRowClick = (e, rowData) => {
    dynamicUrl(navigate,"ViewIssueDetails" + "?muid=" + rowData.maintenanceUniqueId + `&uid=${userUniqueId}`);
};

const handleStatClick = (selectedPurpose) => {
  setPurpose(selectedPurpose);
};

  return (
    <div>
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
            <SubNav title={"Maintainance"} goback={false} extraData={true} extraContent={
                <div className="property-details-buttons">
                   
                </div>
            } />
            <div className="material-work-desk-new bg-white m-3">
            <ToastAlert ref={ToastRef} />
            <div style={{ borderBottom: '1px solid #e9ecef', padding: '10px' }}>
                            <div className="col-lg-8">
                                <span
                                    className={`col-lg-2 col-sm-2 stat-item ${purpose === 2 ? 'active' : ''}`}
                                    onClick={() => handleStatClick(2)}
                                >
                                    Active 
                                </span>
                                <span
                                    className={`col-lg-2 col-sm-2 stat-item ${purpose ===  10? 'active' : ''}`}
                                    onClick={() => handleStatClick(10)}
                                >
                                    Completed 
                                </span>
                            </div>
                        </div>
                <AntdTableExample
                    label="List Maintainance"
                    dataSource={maintainancelist}
                    globalSearch
                    hideCol={"maintainanceId,maintenanceUniqueId,ReportType,eStatus,isNew"}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                    totalRows={rows}
                    totalRecords={totalPages}
                    actions={actions}
                    onRowClick={handleRowClick}
                    tagColumns={tabColumns}
                />

<CustomModal
                  title="Review"
                  width={600}
                  closable={closeModal}
                  visible={openModal}
                  onCancel={closeModal}
                  modalData={
                    <div>
                      <div style={{ marginTop: "30px", marginLeft: "10px" }}>
                        <SelectBox
                          colVal={12}
                          label="Review Status"
                          id="issueType"
                          name="issueType"
                          value={reviewStatus}
                          onChange={(e) => handleInputChange(e, "status")}
                          options={reviewStatusArr}
                        />
                        {reviewStatus === 3 ? (
                          <TextArea
                            colVal={12}
                            label="Reason"
                            id="Reason"
                            name="Reason"
                            value={reason}
                            onChange={(e) => handleInputChange(e, "reason")}
                          />
                        ) : null}
                      </div>
                    </div>
                  }
                  footer={[
                    <div
                      key="footer"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        style={{ marginRight: "20px" }}
                        className="pdb-save-changes mt-2"
                        onClick={handleFinalSubmit}
                      >
                        Submit
                      </button>
                    </div>,
                  ]}
                />
            </div>
        </div>
    </div>
</div>
  );
};

export default ListMaintainanceTrade;
