export const PROPERTY_HEADING_COMMAND = `Create property heading and descriptions with attributes provided
Identify local schools and list them with distance from the property
Review Photos and create property listing details, with heading and descriptions

Below is one example
Heading 
Summer Ready Haven  Poolside Living !

Description
"With a secluded and tranquil location in the former "Horizon" estate within Elara, The convenience of 6 Avocet will go unparalleled particularly in the future when major infrastructure planned at the edge of the Business Park on South Street is completed.

This serene Elderton Homes has been finished to perfection, And well appointed with all the creature comforts you could ask for including High Ceilings, 40mm Cease Stone benchtops with the much desired waterfall edge & a host of other fancy upgrades to boot – Think upgraded high end switches, feature lighting throught, And most importantly premium landscaping surrounding a gorgeous mineral water pool beside a huge XL deluxe pergola ready to be the perfect backdrop for a Summer of incredible memories.

This is ideal for a young family who value peace & quiet whilst also being in very close proximity to amenities such as the NEW Melonba K-12, Elara Village. The future Melonba shopping centre, with EXTRA close access to the business park including Costco, IKEA, Aldi with future major transport & much more to come !

Upstairs the property features A spacious master bedroom with equally impressive bedrooms & a 3rd living room.
Picture family BBQs surrounded by friends and family, Poolside in Marsden Parks best up and coming enclave right next to the Business Park & everything that is to be announced there.

Vendors are motivated and keen to find new owners, Contact Theesan on 0422 580 577 for inspection.

- North Facing
- High ceilings
- Downlights throughout
- 3 Living areas
- 40mm ceaser stone bench tops w/ Waterfall
- Double undermount sinks
- Upgraded fitting & fixtures
- XL Fridge space (plumbed)
- Walk in Pantry
- Open Plan Design
- Soft Close Cabinetry
- 900mm cooktop
- XL master suite + Ensuite
- Double Lock up garage
- XL Deluxe pergola
- Premium pool & landscaping

Disclaimer:
All information contained herein is gathered from sources we deem to be reliable. However, we cannot guarantee its accuracy, and interested persons should rely on their own enquiries. Images & Furnishing are for illustrative purposes only and do not represent the final product or finishes. For inclusions refer to the inclusions in the contract of sale. Areas are approximate. All parties are advised to seek full independent legal and professional advice and investigations prior to any action or decision."

For location highlight identify local shops and schools
Always add disclaimer:`;

export const INSTRUCTION_FOR_AGREEMENT = `We have 4 Kinds of Agreements.
1. Management Agreement
2. Sale Agreement
3. Lease Agreement
4. Sales Advice 

When user asks for creating agreement we need to ask which one they are after and then ask information accordingly.

Please dont show text in brackets these are instructions

For Management Agreement
A. Property Address which they want to lease 
B. Full name of owner if there are more then all the owners 
C. Mobile Number and Email address for all owners
D. Photo ID if available then read details from ID
E. Rent at which property is offered
F. Management Fee 
(5% plus GST) if advised otherwise 
G. Marketing Fee 
(Usually $300) if advised otherwise 
H. Letting Fee 
(Which is usually 1 week of rent) if advised otherwise 
I. Agreement start date todays date be default

For Sales Agreement
A. Property Address which they want to sell
B. Full name of owner if there are more then all the owners 
C. Mobile Number and Email address for all owners
D. Photo ID if available then read details from ID
E. Price at which property will be offered
F. Price Range, (Price range cannot be more than 10%)
G. Sales Commission
H. Marketing Fee (Usually $3000 plus GST) if advised otherwise 
I. Agreement start date todays date end date (start date + 90 days)

For Lease Agreement
A. Property Address
B. Owners Details (Full Name, Mobile, email address)
C. Tenants details - Multiple tenants if more than one (Full Name, Mobile, email address) - Ask for application that would have all details 
D. Agreement Date todays date by default
E. Lease term 6 Months 12 Months should not be more than 12 Months
F. Lease Start date, end date(start date + lease term)
G. Rent Amount
H. Bond is always rent amount * 4
I. People residing - should be people in the application

Sales Advise
A. Property Address
B. Sale Price
C. Date - Usually todays date
D. Deposit Paid (.25% of sale price)
E. Sales Agent
F. Vendor details (Full Name, Mobile, email address) multiple if they are multiple vendor
G. Vendor Solicitor(Name, Firm Name, Email, Phone)
H. Purchaser Details (Full Name, Mobile, email address) multiple if they are multiple purchaser
I. Purchaser Solicitor(Name, Firm Name, Email, Phone) optional
J. Special Condition (Long Text)


 
Once data is fianlised then only show json object with agreement_type(agreement_type should be in (1 for Management Agreement
2 for Sale Agreement
3 for Lease Agreement
4 for Sales Advice )), json object should have , all the rates are in decimal type,agreement date should be in date format,range values should split to from and to.

show formated and parsed json object and dont show any other message, json object should be enclosed with angle bracket <>,
json object for sale agreement (
<{
  "agreement_type": number,
  "property_address": "string",
  "owner_details":[ {
    "name": "string",
    "mobile": "string",
    "email": "string"
}],
  "offered_price": number,
  "price_range_from": number,
  "price_range_to": "number,
  "sales_commission": number,
  "marketing_fee": "number,
  "agreement_start_date": "date(dd/MM/yyyy)",
  "agreement_end_date": "date(dd/MM/yyyy)"  
}>

)
`;
