import React from 'react';
import './collapse.css';


const TextBox = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  error,
  touched,
  icon,
  isMandatory = false,
  type,
  readOnly,
  colVal = '',
  addressMargin,
  className,
  iconSubmit,
  minDate,
  extraDetails,
  unitOptions,
  selectedUnit,
  onUnitChange,
  gst,
  extraData
}) => {
  let colVals = colVal === 8 ? 'col-lg-8 col-sm-12' : colVal === 12 ? 'col-lg-12 col-sm-12' : colVal === 2 ? 'col-lg-2 col-md-4 col-sm-4': colVal === 3 ? 'col-lg-3 col-sm-12' :colVal === 4 ? 'col-lg-4 col-sm-12': colVal === 'mt-2' ? '' :colVal === 5 ? 'col-lg-5 col-sm-12':colVal === 'sm-6' ? 'col-lg-5 col-sm-6': 'col-lg-6 col-sm-12';

  const handleNumberChange = (e) => {
    const newValue = Math.max(0, parseInt(e.target.value, 10));
    onChange({ target: { value: newValue, name } });
  };

  const handleChange = (e) => {
    if (readOnly === undefined || readOnly === false) {
      if (type === 'number') {
        handleNumberChange(e);
      } else {
        onChange(e);
      }
    }
  };

  return (
    <div className={`pd-${name} ${colVals} ${addressMargin ? 'mt-5' : ''}`}>
      <label htmlFor={name} className='s1'>
        {label} {isMandatory ? <span style={{color:'red'}}>*</span> : null }
      </label>
      <div className="input-group mb-2">
        {icon && (
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              {icon}
            </span>
          </div>
        )}
        <input
          type={type ? type : 'text'}
          className={`form-control tenant-date`}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          name={name}
          aria-describedby="basic-addon1"
          readOnly={readOnly}
          min={minDate}
          style={{ backgroundColor: readOnly ? '#e9ecef' : 'white'}}
        />
      {unitOptions ?  <select
          className="select-dropdown1"
          value={selectedUnit}
          onChange={onUnitChange}
        >
          {unitOptions && unitOptions.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select> : null }
        {iconSubmit && (
          <div className="input-group-prepend" style={{cursor:'pointer'}}>
            <span className="input-group-text" id="basic-addon1">
              {iconSubmit}
            </span>
          </div>
        )}
      </div>
      {extraDetails != 0 && (<div className='s1'>{gst ? 'GST : ' + extraDetails : extraDetails}</div>)}
      {extraData && (<div className='s1'>{extraData}</div>)}

      {error && touched ? <p className="form-error">{error}</p> : null}
    </div>
  );
};

export default TextBox;
