import React, { useState, useEffect } from 'react';
import './subheader.css';
import { Button, Dropdown, Menu, Select } from 'antd';
import { DownOutlined, SaveOutlined } from '@ant-design/icons';

const { Option } = Select;

const SubNavbar = (props) => {
    const [selectedTab, setSelectedTab] = useState('');

    useEffect(() => {
        if (typeof props.navTabs !== 'undefined' && props.navTabs.length > 0) {
            setSelectedTab(props.navTabs[0]);
        }
    }, [props.navTabs]);

    const goHome = () => {
        let data = props.sideModuleData;
        data[1] = { activeSub: "Dashboard", activeMain: "Dashboard" };
        props.fetchSidebarModule(data);
        props.history.push('/procurement/index/DashBoard');
    };

    const addNew = () => {
        props.onClickCreate();
    };

    const goBack = () => {
        props.goBack();
    };

    const selectTab = (module) => {
        setSelectedTab(module);
        props.onClick(module);
    };

    const selectChange = (data) => {
        props.selectOnchange(data);
    };

    const renderMenu = () => {
        let menuOption = [];
        if (props.actionButton) {
            props.actionMenuOptions.map((data) => {
                menuOption.push(
                    <Menu.Item key={data.value}>{data.option}</Menu.Item>
                );
            });
            return (
                <Menu onClick={(data) => props.actionMenuClick(data)}>
                    {menuOption}
                </Menu>
            );
        }
    };

    const pageHeading = props.title;
    const actionButton = props.actionButton;
    const backButton = props.backButton;
    const addBtn = props.createButton;
    const saveButton = props.saveButton;
    const extraData = props.extraData;
    const newClass = props.newClass;
    const selectOption = props.selectOptions || [];
    
    const selectOptions = selectOption.map((data) => (
        <Option key={data.value} value={data.value}>{data.option}</Option>
    ));

    const tabData = props.navTabs || [];
    const tabContent = tabData.map((tab) => (
        <span key={tab} className={`tabHeader weight-400 font-8rem ${selectedTab === tab ? 'tabHeader-Active' : ''}`} onClick={() => selectTab(tab)}>{tab}</span>
    ));

    return (
        <div className="material-sub-header">
            <div className="subHeader-subdivision-left">
                {props.goback !== false &&
                    <div onClick={goBack} className="backButtonNavbar pointer mr-3">
                        <img src="../../../assets/images/left-arrow.jpeg" className="pointer" style={{ width: '16px' }} alt="Go Back" />
                    </div>
                }
                {pageHeading &&
                    <div>
                        <label className="font-1 color-21 weight-500 mr-5 headingTxt" style={{ marginLeft: newClass, fontWeight: "500" }}>{pageHeading}</label>
                    </div>
                }
                {tabData.length > 0 &&
                    <div className="sub-header-box-2">
                        {tabContent}
                    </div>
                }
            </div>
            <div className="subHeader-subdivision-right">
                {backButton &&
                    <Button onClick={goHome} className="backToDashboard">
                        Home
                    </Button>
                }
                {actionButton &&
                    <Dropdown overlay={renderMenu()} className="ml-2">
                        <Button>
                            {props.actionButtonName} <DownOutlined />
                        </Button>
                    </Dropdown>
                }
                {selectOption.length > 0 &&
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder={props.selectName}
                        onChange={selectChange}
                    >
                        {selectOptions}
                    </Select>
                }
                {addBtn &&
                    <Button onClick={addNew} className="backToDashboard">
                        {addBtn}
                    </Button>
                }
                {saveButton &&
                    <Button type="primary" className="ml-2" onClick={props.saveButton} icon={<SaveOutlined />} size={"default"}>
                        Save
                    </Button>
                }
                {extraData &&
                    <div>{props.extraContent}</div>
                }
            </div>
        </div>
    );
};

export default SubNavbar;
