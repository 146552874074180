import React from "react";
import { useToggle, useEffect, useState, useRef } from "react";
import Header from "../../CommonSection/Header/Header";
import "./profile.css";
import PlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";
import axios from "axios";
import { useFormik } from "formik";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import {
  apiUrlowner,
  BucketName,
  Region,
  AccessKey,
  Keyid,
  apiUrlamo,
  GetPendingProfiletenant,
  getProfileDetails,
  addportalaccountProfileDetails,
} from "../../CommonSection/apiresources";
import {
  BiCalendar,
  BiEnvelope,
  BiMailSend,
  BiMap,
  BiMessage,
  BiPhone,
} from "react-icons/bi";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { ActionStatus } from "../../../Utilities/enum";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";

const initialValues = {
  reaAgencyId: "",
  clientIdDad: "",
  clientIdRad: "",
  clientSecretKeyDad: "",
  clientSecretKeyRad: "",
  userName: "",
  passwordRad: "",
  partyId: "",
  domainAgencyId: "",
  domainUserName: "",
};

const PortalAccountDetailsProfile = ({
  handleToastMessage,
  handleCurrentPage,
}) => {
  const { showSpinner, hideSpinner } = useSpinnerContext();

  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyUniqueID, setAgencyUniqueId] = useState("");
  const [tempValue, setTempValue] = useState("");

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyUniqueId(parsedData.agencyUniqueID);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };

    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userUId !== "") {
      const timeout = setTimeout(() => {
        GetAgencyDetails();
      }, 2);
    }
  }, [userUId]);

  const GetAgencyDetails = () => {
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      `${getProfileDetails}?agencyUID=${agencyUniqueID}`,
      "get"
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          let agencyDetails = returnData.object;
          values.clientIdDad = agencyDetails.domainAccountDetails.clientId;
          values.clientIdRad = agencyDetails.reaAccountDetails.clientId;
          values.clientSecretKeyDad =
            agencyDetails.domainAccountDetails.clientSecretKey;
          values.clientSecretKeyRad =
            agencyDetails.reaAccountDetails.clientSecretKey;
          values.domainAgencyId = agencyDetails.domainAccountDetails.agencyId;
          values.domainUserName =
            agencyDetails.domainAccountDetails.providerName;
          values.userName = agencyDetails.reaAccountDetails.userName;
          values.partyId = agencyDetails.reaAccountDetails.partnerId;
          values.passwordRad = agencyDetails.reaAccountDetails.password;
          values.reaAgencyId = agencyDetails.reaAccountDetails.reaAgencyId;
          values.authorizationIdDad =
            agencyDetails.domainAccountDetails.authorizationCode;
          values.authorizationSecretKeyDad =
            agencyDetails.domainAccountDetails.authorizationSecretKey;
        } else {
          handleToastMessage(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleToastMessage(["error", error]);
      });
  };

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      // validationSchema: addresshistorySchema,
      onSubmit: (values, action) => {
        submitPortalAccDetails();
      },
    });

  const submitPortalAccDetails = () => {
    const formData = {
      AgencyUID: agencyUniqueID,
      ReaPortalDetails: {
        UserName: values.userName,
        Password: values.passwordRad,
        PartnerId: values.partyId,
        ClientId: values.clientIdRad,
        ClientSecretKey: values.clientSecretKeyRad,
        ReaAgencyId: values.reaAgencyId,
      },
      DomainPortalDetails: {
        DomainAgencyId: values.domainAgencyId,
        ProviderName: values.domainUserName,
        ClientId: values.clientIdDad,
        ClientSecretKey: values.clientSecretKeyDad,
        AuthorizationCode: values.authorizationIdDad,
        AuthorizationSecretKey: values.authorizationSecretKeyDad,
      },
    };
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      addportalaccountProfileDetails,
      "post",
      formData
    )
      .then((returnData) => {
        console.log(returnData,'returnData')
        if (returnData.status == ActionStatus.Successfull) {
          handleToastMessage(["success", returnData.message]);
          handleCurrentPage("PersonalDetails");
        } else {
          // alert(`Message: ${response.data.message}`);
          handleparent(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleToastMessage(["error", returnData.message]);
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <form action="none" onSubmit={handleSubmit} className="details-form">
        <div className="df-all">
          <div className="all-input">
            <div className="df-group" style={{display:'initial'}}>
              <div className="df-group-name col-lg-12 col-sm-12">
                Rea Account Details
              </div>
              <div className="padf-reaAgencyId col-lg-5 col-sm-12">
                <label htmlFor="accname" className="s1">
                  Rea Agency Id
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder=""
                    value={values.reaAgencyId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="reaAgencyId"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.reaAgencyId && touched.reaAgencyId ? (
                  <p className="form-error">{errors.reaAgencyId}</p>
                ) : null}
              </div>
              <div className="padf-partyId col-lg-5 col-sm-12">
                <label htmlFor="partyId" className="s1">
                  Party Id
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder=""
                    value={values.partyId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="partyId"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.partyId && touched.partyId ? (
                  <p className="form-error">{errors.partyId}</p>
                ) : null}
              </div>
              <div className="padf-userName col-lg-5 col-sm-12">
                <label htmlFor="userName" className="s1">
                  Username
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder=""
                    value={values.userName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="userName"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.userName && touched.userName ? (
                  <p className="form-error">{errors.userName}</p>
                ) : null}
              </div>
              <div className="padf-passwordRad col-lg-5 col-sm-12">
                <label htmlFor="accname" className="s1">
                  Password
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder=""
                    value={values.passwordRad}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="passwordRad"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.passwordRad && touched.passwordRad ? (
                  <p className="form-error">{errors.password}</p>
                ) : null}
              </div>
              <div className="padf-clientIdRad col-lg-5 col-sm-12">
                <label htmlFor="clientIdRad" className="s1">
                  Client Id
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder=""
                    value={values.clientIdRad}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="clientIdRad"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.clientIdRad && touched.clientIdRad ? (
                  <p className="form-error">{errors.clientIdRad}</p>
                ) : null}
              </div>
              <div className="padf-clientSecretKeyRad col-lg-5 col-sm-12">
                <label htmlFor="clientSecretKeyRad" className="s1">
                  Client Secret Key
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder=""
                    value={values.clientSecretKeyRad}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="clientSecretKeyRad"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.clientSecretKeyRad && touched.clientSecretKeyRad ? (
                  <p className="form-error">{errors.clientSecretKeyRad}</p>
                ) : null}
              </div>
            </div>
            <div className="df-group" style={{display:'initial'}}>
              <div className="df-group-name col-lg-12 col-sm-12">
                Domain Account Details<span style={{color:'red'}}>*</span>
              </div>
              <div className="padf-domainAgencyId col-lg-5 col-sm-12">
                <label htmlFor="domainAgencyId" className="s1">
                  Domain Agency Id
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder=""
                    value={values.domainAgencyId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="domainAgencyId"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.domainAgencyId && touched.domainAgencyId ? (
                  <p className="form-error">{errors.domainAgencyId}</p>
                ) : null}
              </div>
              <div className="padf-domainUserName col-lg-5 col-sm-12">
                <label htmlFor="domainUserName" className="s1">
                  Domain Username
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder=""
                    value={values.domainUserName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="domainUserName"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.domainUserName && touched.domainUserName ? (
                  <p className="form-error">{errors.domainUserName}</p>
                ) : null}
              </div>
              <div className="padf-clientIdDad col-lg-5 col-sm-12">
                <label htmlFor="clientIdDad" className="s1">
                  Client Id
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder=""
                    value={values.clientIdDad}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="clientIdDad"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.clientIdDad && touched.clientIdDad ? (
                  <p className="form-error">{errors.clientIdDad}</p>
                ) : null}
              </div>
              <div className="padf-clientSecretKeyDad col-lg-5 col-sm-12">
                <label htmlFor="clientSecretKeyDad" className="s1">
                  Client Secret Key
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder=""
                    value={values.clientSecretKeyDad}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="clientSecretKeyDad"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.clientSecretKeyDad && touched.clientSecretKeyDad ? (
                  <p className="form-error">{errors.clientSecretKeyDad}</p>
                ) : null}
              </div>
              <div className="padf-authorizationIdDad col-lg-5 col-sm-12">
                <label htmlFor="authorizationIdDad" className="s1">
                  Authorization Id (with user context)
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder=""
                    value={values.authorizationIdDad}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="authorizationIdDad"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.authorizationIdDad && touched.authorizationIdDad ? (
                  <p className="form-error">{errors.authorizationIdDad}</p>
                ) : null}
              </div>
              <div className="padf-authorizationSecretKeyDad col-lg-5 col-sm-12">
                <label htmlFor="authorizationSecretKeyDad" className="s1">
                  Authrization Secret Key
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder=""
                    value={values.authorizationSecretKeyDad}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="authorizationSecretKeyDad"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.authorizationSecretKeyDad &&
                touched.authorizationSecretKeyDad ? (
                  <p className="form-error">
                    {errors.authorizationSecretKeyDad}
                  </p>
                ) : null}
              </div>
              <div className="tempvalues col-lg-5 col-sm-12">
                <div className="input-group mb-3">
                  <input
                    hidden
                    type="text"
                    className="form-control tenant-date"
                    placeholder="address"
                    value={tempValue}
                    onChange={setTempValue}
                    onBlur={setTempValue}
                    name="fname1"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.fname1 && touched.fname1 ? (
                  <p className="form-error">{errors.fname1}</p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="property-details-buttons">
          <button
            className="pdb-cancel"
            type="reset"
            onClick={() => handleparent(["warn", "changes are canceled"])}
          >
            Cancel
          </button>
          <button className="pdb-save-changes" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default PortalAccountDetailsProfile;
