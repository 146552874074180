import React from "react";
import { useEffect, useState, useRef } from "react";
import { BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { getmaintenanceList } from "../../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import "../../../../components/Dashboard/Dashboard-agent/AgentList.css";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { ActionStatus } from "../../../../Utilities/enum";
import { dynamicUrl } from "../../../../Utilities/profileManagement";
import "../../openHome/openHome.css"
import { FormatDate } from "../../../../components/CommonSection/Utility/Utility";
import SubNavbar from "../../../../components/CommonSection/Header/subHeader";
import AntdTableExample from "../../../input-components/dataTable";
import { useSidebarContext } from "../../../../Utilities/sidebarcontext";
import { useReadNotificationContext } from '../../../../Utilities/readNotificationContext';

const ListIssue = () => {
  const [listIssueDetails, setListIssueDetails] = useState([]);
  const navigate = useNavigate();
  const ToastRef = useRef();
  const [searchText, setSearchText] = useState("");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [purpose, setPurpose] = useState(0);
  const [listCount, setListCount] = useState({});
 const { navbarData, getLoginDataforTopBar } = useSidebarContext(); 
  const { readNotificationApi } = useReadNotificationContext();

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          PostApiWithSpinner(parsedData.tenantId,parsedData.agencyID);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [searchText,purpose]);

  useEffect(() => {
    window.scrollTo(0, 0);
  },[])

  const referenceIdSet = new Set(
    navbarData.flatMap(item => item.referenceIds || []) // Flatten and handle null values
);

const handleReadNotification = (referenceId) => {
  readNotificationApi(referenceId, getLoginDataforTopBar) // Pass the function as a parameter
    .then(() => {
      console.log("Notification successfully marked as read.");
    })
    .catch((error) => {
      console.error("Failed to mark notification as read:", error);
    });
};


  const PostApiWithSpinner = (tenantId,agencyID) => {
    const baseUrl = getmaintenanceList;
    let data = {
      AgencyId: agencyID,
      TenantId: tenantId,
      Search: searchText,
      Status: purpose
    };
    //data = addParametersForProfiles(data);
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
            let issueData = response.object.maintenanceDetails;
            let issueList = issueData.map((issueDetails) => ({
              PropertyAddress: issueDetails.propertyAddress,
              Title: issueDetails.title,
              Description: issueDetails.description,
              CreatedDate: FormatDate(issueDetails.createdAt),
              maintenanceUniqueId: issueDetails.maintenanceUniqueId,
              IssueType: issueDetails.sIssueType,
              Status: issueDetails.sStatus,
              maintainanceId: issueDetails.maintenanceId,
              eStatus: issueDetails.status,
              isNew: issueDetails.isNew,
            }));
            setListIssueDetails(issueList);
            setTotalPages(response.totalCount)
            setListCount(response.object)
        } else {
          ToastRef.current.notify(["error", response.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", response.message]);
      });
  };

  const handleAddIssue = () => {
    dynamicUrl(navigate, "IssueDetails");
  };

  const handlePageChange = (event, pageNumber) => {
    PostApiWithSpinner(event.page + 1, event.rows)
    setCurrentPage(pageNumber);
    setRows(event.rows);
  };

  const handleEditClick = (e,rowData) => {
    e.stopPropagation()
    e.preventDefault()
    dynamicUrl(navigate, "IssueDetails" + "?maintenanceUId=" + rowData.maintenanceUniqueId);
  };

  const handleRowClick = (e, rowData) => {
    e.stopPropagation()
    e.preventDefault()
    handleReadNotification(rowData.maintainanceId)
      dynamicUrl(navigate,"ViewIssueDetails" + "?maintenanceUId=" + rowData.maintenanceUniqueId + `&isNew=${rowData.isNew}&maintainanceId=${rowData.maintainanceId}`);
  };

  const tabColumns = {
    Status: {
      colorMap: {
        "Report Repair": "#3498db",
        "Repair Rejected": "#e74c3c",
        "Reschedule": "#f39c12",
        "Job Started": "#1abc9c",
        "Repair Approved": "#16a085",
        "Job Schedule": "#8e44ad",
        "Reported": "#2c3e50"
      },
      defaultColor: 'blue'
    }
};

  const actions = [
    { icon: "/assets/images/editing.svg", onClick: (e, data) => handleEditClick(e, data) },
    { icon: "/assets/images/dots.svg", key:"View",onClick: (e, data) => handleRowClick(e, data) },
]

const handleStatClick = (selectedPurpose) => {
  setPurpose(selectedPurpose);
};

  return (
    <div>
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
        <SubNavbar goback={false} title={"Reported Issue"} actionButton={false} extraData={true} extraContent={
                <button
                  className="Add-Property-button"
                  type="submit"
                  onClick={handleAddIssue}
                >
                  <div className="text-parent">
                    <BiPlus style={{ width: "25px", height: "25px" }} />
                    <div className="text7" style={{ fontSize: "14px" }}>
                      Add
                    </div>
                  </div>
                </button>
              } />
            <div className="material-work-desk-new bg-white m-3">
            <div style={{ borderBottom: '1px solid #e9ecef', padding: '10px' }}>
                            <div className="col-lg-8">
                            <span
                                    className={`col-lg-2 col-sm-2 stat-item ${purpose ===0 ? 'active' : ''}`}
                                    onClick={() => handleStatClick(0)}
                                >
                                    New  <span className="count">{listCount.newCount}</span>
                                </span>
                                <span
                                    className={`col-lg-2 col-sm-2 stat-item ${purpose === 2 ? 'active' : ''}`}
                                    onClick={() => handleStatClick(2)}
                                >
                                    Active <span className="count">{listCount.activeCount}</span>
                                </span>
                                <span
                                    className={`col-lg-2 col-sm-2 stat-item ${purpose ===1 ? 'active' : ''}`}
                                    onClick={() => handleStatClick(1)}
                                >
                                    Rejected <span className="count">{listCount.rejectedCount}</span>
                                </span>
                                <span
                                    className={`col-lg-2 col-sm-2 stat-item ${purpose ===  10? 'active' : ''}`}
                                    onClick={() => handleStatClick(10)}
                                >
                                    Completed <span className="count">{listCount.completedCount}</span>
                                </span>
                            </div>
                        </div>
                <AntdTableExample
                    label="List of Reported Issue"
                    dataSource={listIssueDetails}
                    globalSearch
                    hideCol={"maintenanceUniqueId,maintainanceId,eStatus,isNew"}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                    totalRows={rows}
                    totalRecords={totalPages}
                    actions={actions}
                    onRowClick={handleRowClick}
                    tagColumns={tabColumns}
                    rowClassName={(record) => {
                      return referenceIdSet.has(record.maintainanceId.toString()) ? "highlighted-row" : "";
                    }}
                />
            </div>
        </div>
    </div>
</div>
  );
};

export default ListIssue;
