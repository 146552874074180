import React, { useState, useRef, useEffect } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import "./inspection.css"; // Import the new CSS file
import { useNavigate } from "react-router-dom";
import { BiDotsVerticalRounded } from "react-icons/bi";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import SideBar from "../../../components/CommonSection/SideBar/sideBar";
import Header from "../../../components/CommonSection/Header/Header";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import CustomModal from "../../input-components/Modal";
import TextBox from "../../input-components/textbox";
import { addfacility, viewinspectionmasterdate, addattribute,linkfacilitiestoattribute ,updateattribute} from "../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { Checkbox } from "antd";
import { BiEditAlt } from "react-icons/bi";
import SubNav from "../../navbar/subNav";

dayjs.extend(customParseFormat);

const AddMaster = () => {
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [activeTopic, setActiveTopic] = useState("AddOpenHome");
    const [topicValue, setTopicValue] = useState("AddOpenHome");
    const [openAddFacilities, setOpenAddFacilities] = useState(false);
    const [openAddAttributes, setOpenAddAttributes] = useState(false);
    const [facilities, setFacilities] = useState([{ id: 1, name: "" }]);
    const [facilityList, setFacilityList] = useState([]);
    const [attributeList, setAttributeList] = useState([]);
    const [attributeVal, setAttributeVal] = useState('');
    const [isDefault, setIsDefault] = useState(false);
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [editFacilityModalVisible, setEditFacilityModalVisible] = useState(false);
    const [updateAttribute , setUpdateAttribute] = useState(false);
    const [selectedFacilities, setSelectedFacilities] = useState([]);
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const ToastRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        viewinspectionmasterdata();
    }, [])

    const viewinspectionmasterdata = () => {
        const baseUrl = viewinspectionmasterdate;
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "get")
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    setFacilityList(response.object.facilities);
                    setAttributeList(response.object.attributes)
                } else {
                    ToastRef(response.message);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleFinalSubmit = (facilityNames) => {
        const url = addfacility;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", facilityNames)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    setOpenAddFacilities(false);
                    viewinspectionmasterdata()
                    //navigate(`/agency/ListOpenHome`);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const handleTopicValue = (text) => {
        setTopicValue(text);
        dynamicUrl(navigate, text);
        setActiveTopic(text);
    };

    const handleOpenFacility = () => {
        setOpenAddFacilities(true);
    };

    const closeInspetionFacilities = () => {
        setOpenAddFacilities(false);
    };

    const handleAddFacility = () => {
        setFacilities([...facilities, { id: facilities.length + 1, name: "" }]);
    };

    const handleFacilityChange = (index, event) => {
        const newFacilities = facilities.map((facility, i) => {
            if (i === index) {
                return { ...facility, name: event.target.value };
            }
            return facility;
        });
        setFacilities(newFacilities);
    };

    const handleSubmitFacilities = () => {
        const facilityNames = facilities.filter(facility => facility.name).map(facility => facility.name);
        setFacilityList([...facilityList, ...facilityNames]);
        setFacilities([{ id: 1, name: "" }]);
        closeInspetionFacilities();
        handleFinalSubmit(facilityNames); // Send data to the API in the required format
    };

    const handleOpenAttributes = (data,status) => {
        if(status === "update"){
            setOpenAddAttributes(true);
            setUpdateAttribute(true)
            setAttributeVal(data?.attribute?.attribute)
        }else{
            setOpenAddAttributes(true);
        }

    };

    const closeAttributes = () => {
        setOpenAddAttributes(false);
        setUpdateAttribute(false)
    };

    const handleAttribute = (e) => {
        setAttributeVal(e.target.value)
    }

    const handleDefault = (e) => {
        setIsDefault(e.target.checked)
    }

    const handleFinalAttributes = (facilityNames) => {
        let finalData = {
            Attribute: attributeVal,
            IsDefault: isDefault
        }
        const url = updateAttribute ? updateattribute :  addattribute;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", finalData)
            .then((returnData) => {
                debugger;
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    setOpenAddAttributes(false);
                    viewinspectionmasterdata()
                    setUpdateAttribute(false);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };
    

    const handleEditFacilities = (attribute) => {
        setSelectedAttribute(attribute);
            if (attribute.facilities && Array.isArray(attribute.facilities)) {
            const selectedFacilityIds = attribute.facilities.map(facility => facility.id);
                setSelectedFacilities(selectedFacilityIds);
        } else {
            setSelectedFacilities([]);
        }
            setEditFacilityModalVisible(true);
    };
    

    const closeEditFacilityModal = () => {
        setEditFacilityModalVisible(false);
        setSelectedAttribute(null);
    };

    const handleFacilityCheckboxChange = (facilityId) => {
        setSelectedFacilities(prevState =>
            prevState.includes(facilityId)
                ? prevState.filter(id => id !== facilityId)
                : [...prevState, facilityId]
        );
    };

    const handleSubmitEditFacilities = () => {
        const finalData = {
            Attribute: selectedAttribute.attribute?.attribute,
            Facilities: selectedFacilities.join(",")
        };
        const url = linkfacilitiestoattribute;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", finalData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    closeEditFacilityModal();
                    viewinspectionmasterdata();
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };


    return (
        // <>
        //     <div className="dashboard-agency">
        //         <Header />
        //         <ToastAlert ref={ToastRef} />

        //         <div className="blank_space">
        //             <BiDotsVerticalRounded onClick={() => setSidebarVisible(!sidebarVisible)} />
        //         </div>

        //         <div className="container-dbag">
        //             <div className="dashboardagency-content">
        //                 <SideBar
        //                     visible={sidebarVisible}
        //                     handleTopicValue={handleTopicValue}
        //                     topicValue={activeTopic}
        //                 />

        //                 <div style={{ width: "100%", display: "flex" }}>
                         
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </>

        <div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
                    <SubNav title={"Add Inspection"} goBack={false} extraData={true} />
                    <div className="material-work-desk-new bg-white m-3">
                    <ToastAlert ref={ToastRef} />
                        <div className="content-section d-flex">
                            {/* Left Section */}
                            <div className="left-section">
                                <div className="right-section-header">
                                    <div>Attribute List</div>
                                    <div>
                                        <button
                                            className="pdb-save-changes"
                                            type="button"
                                            onClick={handleOpenAttributes}
                                        >
                                            Add Attribute
                                        </button>
                                    </div>
                                </div>
                                {attributeList?.map((attributeObj, index) => (
                                    <div key={index} className="attribute-item">
                                        <div className="attribute-header">
                                            <div >{attributeObj.attribute?.attribute} <span><BiEditAlt style={{ cursor: 'pointer' }} onClick={() => handleOpenAttributes(attributeObj, 'update')}>Edit facility</BiEditAlt></span></div>
                                            <BiEditAlt style={{ cursor: 'pointer' }} onClick={() => handleEditFacilities(attributeObj)}>Edit facility</BiEditAlt>

                                        </div>
                                        <ul style={{ fontWeight: '500' }}>
                                            {attributeObj.facilities?.map((facility, facilityIndex) => (
                                                <li key={facilityIndex}>{facility?.facility}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>

                            {/* Right Section */}
                            <div className="right-section">
                                <div className="right-section-header">
                                    <div>Facility List</div>
                                    <div>
                                        <button
                                            className="pdb-save-changes"
                                            type="button"
                                            onClick={handleOpenFacility}
                                        >
                                            Add Facility
                                        </button>
                                    </div>
                                </div>
                                {facilityList?.map((facility, index) => (
                                    <ul className="facility-list ">
                                        <li key={index}>{facility.facility}</li>
                                    </ul>
                                ))}
                                <CustomModal
                                    title="Inspection Facilities"
                                    width={600}
                                    closable={closeInspetionFacilities}
                                    visible={openAddFacilities}
                                    onCancel={closeInspetionFacilities}
                                    modalData={
                                        <div
                                            className="row"
                                            style={{ marginTop: "30px", marginLeft: "10px" }}
                                        >
                                            {facilities.map((facility, index) => (
                                                <div key={facility.id} className="col-12">
                                                    <TextBox
                                                        colVal={12}
                                                        // label={`Facility ${facility.id}`}
                                                        placeholder={"Facility"}
                                                        value={facility.name}
                                                        onChange={(e) => handleFacilityChange(index, e)}
                                                    />
                                                </div>
                                            ))}
                                            <div style={{ display: 'flex', justifyContent: 'end', marginLeft: '-20px' }}>
                                                <button style={{ border: '1px solid #d0d5dd', height: '30px', borderRadius: '4px' }} className="mt-2" type="button" onClick={handleAddFacility}>
                                                    Add Facility
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    footer={[
                                        <div
                                            key="footer"
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <button
                                                style={{ marginRight: "20px" }}
                                                className="pdb-save-changes mt-2"
                                                onClick={handleSubmitFacilities}
                                            >
                                                Submit
                                            </button>
                                        </div>,
                                    ]}
                                />
                                <CustomModal
                                    title="Inspection Attributes"
                                    width={600}
                                    closable={closeAttributes}
                                    visible={openAddAttributes}
                                    onCancel={closeAttributes}
                                    modalData={
                                        <div
                                            className="row"
                                            style={{ marginTop: "30px", marginLeft: "10px" }}
                                        >
                                            <TextBox
                                                colVal={12}
                                                label={`Attribute`}
                                                value={attributeVal}
                                                onChange={handleAttribute}
                                            />
                                            <Checkbox
                                                checked={isDefault}
                                                onChange={handleDefault}
                                            >
                                                add instantly to upcoming inspections
                                            </Checkbox>
                                        </div>
                                    }
                                    footer={[
                                        <div
                                            key="footer"
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <button
                                                style={{ marginRight: "20px" }}
                                                className="pdb-save-changes mt-2"
                                                onClick={handleFinalAttributes}
                                            >
                                                Submit
                                            </button>
                                        </div>,
                                    ]}
                                />

                                <CustomModal
                                    title="Edit Facilities"
                                    width={600}
                                    closable={closeEditFacilityModal}
                                    visible={editFacilityModalVisible}
                                    onCancel={closeEditFacilityModal}
                                    modalData={
                                        <div
                                            className="row"
                                            style={{ marginTop: "30px", marginLeft: "10px" }}
                                        >
                                            {facilityList.map((facility, index) => (
                                                <div key={facility.id}>
                                                    <Checkbox
                                                        checked={selectedFacilities.includes(facility.id)}
                                                        onChange={() => handleFacilityCheckboxChange(facility.id)}
                                                    >
                                                        {facility.facility}
                                                    </Checkbox>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    footer={[
                                        <div
                                            key="footer"
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <button
                                                style={{ marginRight: "20px" }}
                                                className="pdb-save-changes mt-2"
                                                onClick={handleSubmitEditFacilities}
                                            >
                                                Submit
                                            </button>
                                        </div>,
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddMaster;
