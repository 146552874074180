import React, { createContext, useContext, useState } from "react";
import { ActionStatus } from "../Utilities/enum";
import { useSpinnerContext } from "./SpinnerContext";
import { getsidebarforuser,getnavbarforuser } from "../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../Utilities/client";
import { currentProfile } from "../components/CommonSection/Cookie/Session";
const SidebarContext = createContext();

export const useSidebarContext = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }) => {
  const [sideBarData, setSideBarData] = useState([]);
  const [navbarData, setNavBarData] = useState([])
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const currentLogin = currentProfile.get();

  const getLoginDataforSideBar = (id,currentLogin) => {
    const baseUrl = `${getsidebarforuser}?UserId=${id}&UserType=${currentLogin}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "get")
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          const updatedSidebarData = response.list.map((section) => {
            if (section.key === "Supports") {
              const aiAssistantExists = section.items.some(
                (item) => item.name === "AI-Assistant"
              );
              if (
                !aiAssistantExists &&
                (import.meta.env.MODE === "development" || import.meta.env.MODE === "staging")
              ) {
                return {
                  ...section,
                  items: [
                    ...section.items,
                    { name: "AI-Assistant", icon: "fa fa-robot", path: "/AiAssistant" },
                  ],
                };
              }
            }
            return section;
          });

          setSideBarData(updatedSidebarData);
        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error fetching sidebar data:", error);
      });
  };

  const getLoginDataforTopBar = (id, currentLogin) => {
    const baseUrl = `${getnavbarforuser}?UserId=${id}&UserType=${currentLogin}`;
    console.log("Calling navbar API:", baseUrl); // Debugging log
  
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "get")
      .then((response) => {
        if (response.list) {
          console.log("Navbar API response:", response.list); // Debugging log
          setNavBarData(response.list);
        } else {
          console.warn("Navbar API returned no list, using default menuItems."); // Warning log
          setNavBarData(menuItems); // Use fallback
        }
      })
      .catch((error) => {
        console.error("Error fetching navbar data:", error);
      });
  };
  

  return (
    <SidebarContext.Provider value={{ sideBarData, setSideBarData, getLoginDataforSideBar,navbarData,setNavBarData,getLoginDataforTopBar }}>
      {children}
    </SidebarContext.Provider>
  );
};
