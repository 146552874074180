import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AgreementTemplate } from "../../../../components/CommonSection/apiresources";
import { GetCookie, } from "../../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import "../../../../components/Dashboard/Dashboard-agent/AgentList.css";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { ActionStatus } from "../../../../Utilities/enum";
import { addParametersForProfiles, dynamicUrl } from "../../../../Utilities/profileManagement";
import "../../openHome/openHome.css"
import "../../Task/task.css"
import { format } from 'date-fns';
import AntdTableExample from "../../../input-components/dataTable"
import ListAgreement from "../ListAgreement"

const ListAgreementType = () => {
  const [agreementlist, setAgreementList] = useState([]);
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agentID, setAgentId] = useState("");
  const [agencyUniqueID, setAgencyUniqueId] = useState("");
  const [chartData, setChartData] = useState();
  const navigate = useNavigate();
  const ToastRef = useRef();
  const [searchText, setSearchText] = useState("");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [agentUID, setAgentUID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [displayTable, setDisplaytable] = useState(true);
  const [activeTab, setActiveTab] = useState("Agreement Type List");
  const tabs = ["Agreement Type List", "Agreement List"];

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgentId(parsedData.agentId);
          setAgencyUniqueId(parsedData.agencyUniqueID);
          setAgentUID(parsedData.agentId)
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) PostApiWithSpinner();
  }, [userId]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const page = url.searchParams.get('Page');
    if (page) {
      setActiveTab("Agreement List");
    }
  }, []);

  const PostApiWithSpinner = (currentPage, totalRows) => {
    setDisplaytable(false)
    const baseUrl = AgreementTemplate;
    let data = {
      SortBy: "AddedOn",
      SortOrder: "Desc",
      RecordsPerPage: rows,
      AgencyId: agencyID,
      Search: searchText,
      PageNo: currentPage,
      AgencyUniqueId: agencyUniqueID
    };
    if (data.SearchDateType === "") {
      delete data.SearchDateType;
    }
    data = addParametersForProfiles(data);
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data, null, false)
      .then((response) => {
        setDisplaytable(true)
        if (response.status === ActionStatus.Successfull) {
          if (response.list.length > 0) {
            let templatesData = response.list;
            let templatesList = templatesData.map((task) => ({
              Title: task.title,
              Content: task.content,
              CreatedDate: format(new Date(task.addedOn), "dd/MM/yyyy hh:mm aa"),
            }));
            setAgreementList(templatesList);
            setTotalPages(response.totalCount)
            setChartData(response.object);
          } else {
            //ToastRef.current.notify(["warn", "User not added"]);
          }
        } else {
          ToastRef.current.notify(["error", response.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", response.message]);
      });
  };

  const handleAgreement = (e, rowData) => {
    if (rowData.Title === "Management Agreement") {
      dynamicUrl(navigate, "agreementtabs");
    }else if(rowData.Title === "Residential Tenancy Agreement"){
      dynamicUrl(navigate, "tenancyagreement");
  }
  else  if(rowData.Title === "Exclusive Sales Agency Agreement"){
    dynamicUrl(navigate, "SaleAgreementTabs");
}
  }

  const actions = [
    { icon: "/assets/images/Add.png", onClick: (e, data) => handleAgreement(e, data), key: "Add Agreement", iconSize:"30px" },
  ]

  return (
    <div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
          <div style={{ display: "flex", padding: "5px", background: 'white', margin: '5px 15px' }}>
            {tabs.map((tab) => (
              <div key={tab} className={`button ${activeTab === tab ? "active-tab" : ""}`} onClick={() => setActiveTab(tab)}>
                {tab}
              </div>
            ))}
          </div>
          <div className="material-work-desk-new bg-white m-3">
            {activeTab === "Agreement Type List" ?
              <AntdTableExample
                label="List Of Agreement Templates"
                dataSource={agreementlist}
                globalSearch
                hideCol={"id"}
                currentPage={currentPage}
                totalRows={rows}
                totalRecords={totalPages}
                actions={actions}
                onRowClick={handleAgreement}

              /> : <ListAgreement />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListAgreementType;
