import React from "react";
import { useEffect, useState, useRef, useMemo } from "react";
import "./propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { PropertySetPrimaryUrl, SearchContacts, ContactDetailsApi, addupdateTenant, addtenantfromcontact, updatetenantfoliodetails } from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../../src/input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { BiEnvelope, BiPhone } from "react-icons/bi";
import Collapse from "../../../src/input-components/collapse";
import { rentDetails, TenantContactSchema } from "../../../components/CommonSection/Schema/schema";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import { addParametersForProfiles, dynamicUrl } from "../../../Utilities/profileManagement";

const initialValues = {
  fname1: "",
  lname1: "",
  email: "",
  mobileNumber: "",
  tenantId: ""
}

const TenantPropertyAgency = ({ onDataRecive, handleparentsuccess, getDetails, handleCurrentPage, cancel, tenantId }) => {
  const [userId, setUserId] = useState("");
  const [agentId, setAgentId] = useState("");
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  var url = new URL(window.location.href);
  var propertyUId = url.searchParams.get("propertyUID");
  const [agencyId, setAgencyId] = useState("");
  const [fetching, setFetching] = useState(false);
  const [option, setOption] = useState([]);
  const fetchRef = useRef(0);
  const [selectedValues, setSelectedValues] = useState([]);
  const [submitExistigContact, setSubitExisitingContact] = useState(false);
  const [contactId, setContactId] = useState(0);


  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgentId(parsedData.agentId);
          setAgencyId(parsedData.agencyID);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);


  useEffect(() => {
    if (getDetails) {
      const matchedOwner = getDetails?.tenantDetails?.find(
        (owner) => owner.tenantId === tenantId
      );
      if (matchedOwner) {
        setSubitExisitingContact(false)
        setFieldValue("fname1", matchedOwner.tenantFname);
        setFieldValue("lname1", matchedOwner.tenantLname);
        setFieldValue("email", matchedOwner.tenantEmail);
        setFieldValue("mobileNumber", matchedOwner.tenantMobile);
        setFieldValue(matchedOwner.contactUId);
        setFieldValue("tenantId", matchedOwner.tenantId)
      }
    }
  }, [getDetails])

  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }
    const [year, month, day] = dateString.split("T")[0].split("-");
    return `${year}-${month}-${day}`;
  }

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: TenantContactSchema,
    onSubmit: (values) => {
      submitExistigContact ? handleExisitingContact() : handleFinalSubmit();
    }
  });


  const handleFinalSubmit = () => {
    const formData = {
      TenantId: values.tenantId ? values.tenantId : 0,
      PropertyId: getDetails.propertyId,
      TenantFname: values.fname1,
      TenantLname: values.lname1,
      TenantMobile: values.mobileNumber,
      TenantEmail: values.email,
      UserId: userId,
      AgencyId: agencyId
    };
    const url = addupdateTenant;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          cancel();
        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error]);
      });
  };

  const handleExisitingContact = () => {
    debugger;
    const formData = {
      PropertyId: getDetails.propertyId,
      ContactId: contactId,
      UserId: userId,
      AgencyId: agencyId
    };
    const url = addtenantfromcontact;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          cancel();
          // var url = new URL(window.location.href);
          // var propertyUId = url.searchParams.get("propertyUID");
          // onDataRecive(propertyUId)
          // handleparentsuccess(["success", returnData.message]);
          //GetApi(propertyUId);
        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]);
      });
  };

  useEffect(() => {
    agentUIDRef.current = agentId;
  }, [agentId]);

  const agentUIDRef = useRef(agentId);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      const agentId = agentUIDRef.current;
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOption([]);
      setFetching(true);
      const baseUrl = SearchContacts;
      let data = {
        Search: value,
        RecordsPerPage: 10,
        PageNo: 1,
        AgentID: agentId,
      };
      data = addParametersForProfiles(data);
      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name,
              uid: item.uid
            }));
            if (fetchId === fetchRef.current) {
              setOption(transformedData);
              setFetching(false);
            }
          } else {
            setFetching(false);
          }
        })
        .catch((error) => {
          setFetching(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);

  const handleSearchChange = (e, value) => {
    const label = value.map((item) => item.label).join(",");
    const values = value.map((item) => item.value).join(",");
    const uid = value.map((item) => item.uid).join(",");
    GetContactDetails(uid)
    setFieldValue("contactId", values);
    setFetching("propertyName", label);
    setContactId(values);
    debounceFetcher(value);
    setSubitExisitingContact(value.length != 0 ? true : false);
  };

  const GetContactDetails = (value) => {
    var url = new URL(window.location.href);
    var contactUniqueId = value;
    const baseUrl = ContactDetailsApi;
    const apiUrl = `${baseUrl}?contactUniqueId=${contactUniqueId}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get")
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          let data = response.object
          setFieldValue("fname1", data.firstName)
          setFieldValue("lname1", data.lastName)
          setFieldValue("mobileNumber", data.mobileNumber)
          setFieldValue("email", data.email)
        } else {
          handleparentsuccess(["warn", response.data.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error]);
      });
  };


  return (
    <div>
      <form onSubmit={handleSubmit} className="details-form">
        <div className="all-input">
          <div className="pddpn-group pd-address">

            <Collapse
              id={"Personal-Details"}
              title={"Personal-Details"}
              data={
                <div>
                  <div className="row contacts-group">
                    <div className={`lda col-lg-6 col-sm-12`}>
                      <label className="s1">Contacts </label>
                      <div style={{ display: "flex" }}>
                        <Select
                          style={{
                            width: "100%",
                            height: "40px",
                          }}
                          mode="multiple"
                          placeholder="Search for contacts"
                          labelInValue
                          filterOption={false}
                          onSearch={debounceFetcher}
                          notFoundContent={
                            fetching ? <Spin size="small" /> : null
                          }
                          options={option}
                          onChange={(label, value) =>
                            handleSearchChange(label, value)
                          }
                          defaultValue={selectedValues}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row contacts-group">
                    <TextBox
                      label={"First Name"}
                      isMandatory
                      placeholder="First Name"
                      id="fname1"
                      name="fname1"
                      value={values.fname1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.fname1}
                      touched={touched.fname1}
                    />
                    <TextBox
                      label="Last Name"
                      placeholder="Last Name"
                      id="lname1"
                      name="lname1"
                      value={values.lname1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.lname1}
                      touched={touched.lname1}
                    />
                    <TextBox
                      label="Mobile"
                      placeholder="Mobile"
                      id="mobileNumber"
                      name="mobileNumber"
                      value={values.mobileNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.mobileNumber}
                      touched={touched.mobileNumber}
                      icon={<BiPhone height="100%" width="100%" />}
                    />
                    <TextBox
                    isMandatory
                      label="Email"
                      placeholder="Email"
                      id="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.email}
                      touched={touched.email}
                      icon={<BiEnvelope height="100%" width="100%" />}
                    />

                  </div>
                  <div className="property-details-buttons">
                    <button
                      className="pdb-cancel"
                      type="reset"
                      onClick={cancel}
                    >
                      Cancel
                    </button>
                    <button className="pdb-save-changes" type="submit">
                      Save
                    </button>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </form>

    </div>
  );
};

export default TenantPropertyAgency;
