import React from "react";
import { useEffect, useState } from "react";
import "./propertiesagency.css";
import { BiEnvelope, BiPhone } from "react-icons/bi";
import { useFormik } from "formik";
import { addPropertyLegal, ReaUplode, searchpropertyconvyencor } from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import Collapse from "../../input-components/collapse";
import AddressInput from "../../input-components/address";
import { PropertyLegalSchema } from "../../../components/CommonSection/Schema/schema";
import { FaEdit } from "react-icons/fa";
import { Space } from "antd";
import ShowValue from "../../input-components/ShowValue";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import { useNavigate } from "react-router-dom";
import { Checkbox, Select, Spin } from "antd";
import CustomModal from "../../input-components/Modal";
import debounce from "lodash/debounce";

const initialValues = {
  selectSellerName: "",
  sellerName: "",
  sellerPrincipalName: "",
  sellerEmail: "",
  sellerAddress: "",
  sellerNumber: "",
  sellerFax: "",
  selectBuyerName:"",
  buyerName: "",
  buyerPrincipalName: "",
  buyerEmail: "",
  buyerAddress: "",
  buyerNumber: "",
  buyerFax: "",
  PropertySellerConvyencorId: "",
  PropertyBuyerConvyencorId:""
};

const PropertyLegal = ({
  handleparentsuccess,
  getDetails,
  handleCurrentPage1,
  cancel
}) => {
  const [sellerAddress, setSellerAddress] = useState("");
  const [buyerAddress, setBuyerAddress] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [userId, setUserId] = useState("");
  const [edit, setEdit] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [reaUplode, setReaUplode] = useState(false);
  const [domainUplode, setDomainUplode] = useState(false);
  const [isReaUploaded, setIsReaUploaded] = useState(null);
  const [isDomainUploaded, setIsDomainUploaded] = useState(null);
  const [fetchingFirms, setFetchingFirms] = useState(false);
  const [firmOptions, setFirmOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setAgencyUID(parsedData.agencyUniqueID);
          setUserId(parsedData.userId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (getDetails) {
      setFieldValue("sellerName", getDetails.propertySellerFirmName);
      setFieldValue(
        "sellerPrincipalName",
        getDetails.propertySellerPrincipalName
      );
      setFieldValue("sellerAddress", getDetails.propertySellerAddress);
      setFieldValue("sellerNumber", getDetails.propertySellerPhone);
      setFieldValue("sellerFax", getDetails.propertySellerFax);
      setFieldValue("sellerEmail", getDetails.propertySellerEmail);
      setSellerAddress(getDetails.propertySellerAddress);

      setFieldValue("buyerName", getDetails.propertyBuyerFirmName);
      setFieldValue(
        "buyerPrincipalName",
        getDetails.propertyBuyerPrincipalName
      );
      setFieldValue("buyerAddress", getDetails.propertyBuyerAddress);
      setFieldValue("buyerNumber", getDetails.propertyBuyerPhone);
      setFieldValue("buyerFax", getDetails.propertyBuyerFax);
      setFieldValue("buyerEmail", getDetails.propertyBuyerEmail);
      setBuyerAddress(getDetails.propertyBuyerAddress);
      setPropertyId(getDetails.propertyId);
      if (getDetails.propertySellerFirmName === null && getDetails.propertyBuyerFirmName === null) {
        setEdit(true)
      }
    }
  }, [getDetails]);

  useEffect(() => {
    handlSearchpropertyconvyencor()
  }, [])

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: PropertyLegalSchema,
    onSubmit: (values, action) => {
      values.sellerAddress = sellerAddress;
      values.buyerAddress = buyerAddress;
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = () => {
    const formData = {
      PropertyId: propertyId ? propertyId : 0,
      PropertySellerFirmName: values.sellerName,
      PropertySellerPrincipalName: values.sellerPrincipalName,
      PropertySellerEmail: values.sellerEmail,
      PropertySellerPhone: values.sellerNumber,
      PropertySellerFax: values.sellerFax,
      PropertySellerAddress: values.sellerAddress,
      PropertyBuyerFirmName: values.buyerName,
      PropertyBuyerPrincipalName: values.buyerPrincipalName,
      PropertyBuyerEmail: values.buyerEmail,
      PropertyBuyerPhone: values.buyerNumber,
      PropertyBuyerFax: values.buyerFax,
      PropertyBuyerAddress: values.buyerAddress,
      UserId: userId,
      PropertyBuyerConvyencorId:values.PropertyBuyerConvyencorId,
      PropertySellerConvyencorId: values.PropertySellerConvyencorId
    };

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      addPropertyLegal,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          handleCurrentPage1("propertyListing")

        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]);
      });
  };

  const handleSellerAddress = (selectedAddress) => {
    setSellerAddress(selectedAddress);
  };

  const handleByuerAddress = (selectedAddress) => {
    setBuyerAddress(selectedAddress);
  };

  const closeUpload = () => {
    setOpenUpload(false);
  };

  const handleReaSubmit = () => {
    if (getDetails.propertyPictures.length === 0) {
      handleparentsuccess(["error", "Property Images are required."]);
    } else if (
      getDetails.propertyType != "16" &&
      !getDetails.countBedrooms === null
    ) {
      handleparentsuccess([
        "error",
        "A 'number of bedrooms' has not been provided for this property.",
      ]);
    } else if (
      getDetails.propertyType != "16" &&
      !getDetails.countBathrooms === null
    ) {
      handleparentsuccess([
        "error",
        "A 'number of bathrooms' has not been provided for this property.",
      ]);
    } else if (
      getDetails.propertyDescription === null ||
      getDetails.propertyDescription === ""
    ) {
      handleparentsuccess(["error", "Please enter description for property"]);
    } else if (getDetails.propertyHeading === null) {
      handleparentsuccess(["error", "Please enter Heading for property"]);
    } else {
      const data = {
        propertyID: getDetails.propertyUniqueID,
        isrea: reaUplode,
        isdomain: domainUplode,
        isPrincipleLogged: true,
        ListingId: listingId,
      };

      makeRequestWithSpinner(showSpinner, hideSpinner, ReaUplode, "post", data)
        .then((returnData) => {
          if (returnData.status === ActionStatus.Successfull) {
            handleparentsuccess(["success", returnData.message]);
            setOpenUpload(false);
          } else {
            handleparentsuccess(["error", returnData.message]);
          }
        })
        .catch((error) => {
          handleparentsuccess(["error", error.message]);
        });
    }
  };

  const onChangeUpload = (type) => {
    if (type === "rea") {
      setReaUplode(!reaUplode);
    } else if (type === "domain") {
      setDomainUplode(!domainUplode);
    }
  };

  const handlSearchpropertyconvyencor = () => {
    const data = {
      Search: "",
    };

    makeRequestWithSpinner(showSpinner, hideSpinner, searchpropertyconvyencor, "post", data)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          console.log(returnData, 'returnData')
        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]);
      });
  };

  const fetchFirmNames = debounce((value) => {
    setFetchingFirms(true);
    const data = { Search: value };

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      searchpropertyconvyencor,
      "post",
      data
    )
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          const options = response.list.map((firm) => ({
            value: firm.convyencorId,
            label: firm.firmName,
            email: firm.email,
            address: firm.address,
            convyencorId: firm.convyencorId,
            phone: firm.phone,
            principalName: firm.principalName,
            fax: firm.fax
          }));
          setFirmOptions(options);
          setFetchingFirms(false);
        } else {
          handleparentsuccess(["error", response.message]);
          setFetchingFirms(false);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]);
        setFetchingFirms(false);
      });
  }, 800);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="all-input">
          <Collapse
            id={"Seller"}
            title={"Seller Solicitor/Conveyancer Details"}
            data={
              <div className="contacts-group row">
                <div className="col-lg-6 col-sm-12">
                  <label className="s1">Select the Firm</label>
                  <div style={{ display: "flex" }}>
                    <Select
                      style={{ width: "100%", height: "40px" }}
                      showSearch
                      placeholder="Search for Firm"
                      value={values.selectSellerName || undefined} // Controlled by Formik
                      onSearch={fetchFirmNames} // Fetch options dynamically as user types
                      onChange={(value, option) => {
                        if (value && option) {
                          // If an option is selected, set all seller fields
                          setFieldValue("selectSellerName",option.label)
                          setFieldValue("sellerName", option.label);
                          setFieldValue("sellerPrincipalName", option.principalName || "");
                          setFieldValue("sellerEmail", option.email || "");
                          setFieldValue("sellerNumber", option.phone || "");
                          setFieldValue("sellerFax", option.fax || "");
                          setSellerAddress(option.address || "");
                          setFieldValue("PropertySellerConvyencorId", option.convyencorId)
                        } else {
                          // If cleared, reset all seller fields
                          setFieldValue("sellerName", "");
                          setFieldValue("sellerPrincipalName", "");
                          setFieldValue("sellerEmail", "");
                          setFieldValue("sellerNumber", "");
                          setFieldValue("sellerFax", "");
                          setSellerAddress(""); // Clear seller address
                          setFieldValue("PropertySellerConvyencorId", ""); // Clear ID

                        }
                      }}
                      filterOption={false} // Disable local filtering
                      notFoundContent={fetchingFirms ? <Spin size="small" /> : "No data"}
                      options={firmOptions} // Dynamically populated options
                      allowClear
                    />

                  </div>
                </div>
                <TextBox
                  label="Name of the Firm"
                  placeholder="Name of the Firm"
                  name="sellerName"
                  value={values.sellerName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.sellerName}
                  readOnly={values.PropertySellerConvyencorId ? true : false} // Read-only when Select has a value
                  touched={touched.sellerName}
                />
                <TextBox
                  label="Principal's Name"
                  placeholder="Principal's Name"
                  name="sellerPrincipalName"
                  value={values.sellerPrincipalName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.sellerPrincipalName}
                  touched={touched.sellerPrincipalName}
                />
                <TextBox
                  label="Email"
                  placeholder="Email Address"
                  name="sellerEmail"
                  value={values.sellerEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.sellerEmail}
                  touched={touched.sellerEmail}
                  icon={<BiEnvelope height="100%" width="100%" />}
                />
                <TextBox
                  label="Phone"
                  placeholder="Phone"
                  name="sellerNumber"
                  value={values.sellerNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.sellerNumber}
                  touched={touched.sellerNumber}
                  icon={<BiPhone height="100%" width="100%" />}
                />
                <TextBox
                  label="Fax"
                  placeholder="Fax"
                  name="sellerFax"
                  value={values.sellerFax}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.sellerFax}
                  touched={touched.sellerFax}
                />
                <AddressInput
                  labelName="Seller Address"
                  address={sellerAddress}
                  setAddress={setSellerAddress}
                  handlePressSelect={handleSellerAddress}
                  error={errors.sellerAddress}
                  touched={touched.sellerAddress}
                />
              </div>
            }
          />
          <Collapse
            id={"Buyer"}
            title={"Buyer Solicitor/Conveyancer Details"}
            data={
              <div className="contacts-group row">
                <div className="col-lg-6 col-sm-12">
                  <label className="s1">Select the Buyer Firm</label>
                  <div style={{ display: "flex" }}>
                    <Select
                      style={{ width: "100%", height: "40px" }}
                      showSearch
                      placeholder="Search for Buyer Firm"
                      value={values.selectBuyerName || undefined} // Controlled by Formik
                      onSearch={fetchFirmNames} // Fetch options dynamically as user types
                      onChange={(value, option) => {
                        if (value && option) {
                          // If an option is selected, set all buyer fields
                          setFieldValue("selectBuyerName",option.label)
                          setFieldValue("buyerName", option.label);
                          setFieldValue("buyerPrincipalName", option.principalName || "");
                          setFieldValue("buyerEmail", option.email || "");
                          setFieldValue("buyerNumber", option.phone || "");
                          setFieldValue("buyerFax", option.fax || "");
                          setBuyerAddress(option.address || "");
                          setFieldValue("PropertyBuyerConvyencorId", option.convyencorId);
                        } else {
                          // If cleared, reset all buyer fields
                          setFieldValue("buyerName", "");
                          setFieldValue("buyerPrincipalName", "");
                          setFieldValue("buyerEmail", "");
                          setFieldValue("buyerNumber", "");
                          setFieldValue("buyerFax", "");
                          setBuyerAddress(""); // Clear buyer address
                          setFieldValue("PropertyBuyerConvyencorId", ""); // Clear ID
                        }
                      }}
                      filterOption={false} // Disable local filtering
                      notFoundContent={fetchingFirms ? <Spin size="small" /> : "No data"}
                      options={firmOptions} // Dynamically populated options
                      allowClear
                    />
                  </div>
                </div>
                <TextBox
                  label="Name of the Buyer Firm"
                  placeholder="Name of the Buyer Firm"
                  name="buyerName"
                  value={values.buyerName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.buyerName}
                  readOnly={values.PropertyBuyerConvyencorId ? true : false} // Read-only when Select has a value
                  touched={touched.buyerName}
                />
                <TextBox
                  label="Principal's Name"
                  placeholder="Principal's Name"
                  name="buyerPrincipalName"
                  value={values.buyerPrincipalName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.buyerPrincipalName}
                  touched={touched.buyerPrincipalName}
                />
                <TextBox
                  label="Email"
                  placeholder="Email Address"
                  name="buyerEmail"
                  value={values.buyerEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.buyerEmail}
                  touched={touched.buyerEmail}
                  icon={<BiEnvelope height="100%" width="100%" />}
                />
                <TextBox
                  label="Phone"
                  placeholder="Phone"
                  name="buyerNumber"
                  value={values.buyerNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.buyerNumber}
                  touched={touched.buyerNumber}
                  icon={<BiPhone height="100%" width="100%" />}
                />
                <TextBox
                  label="Fax"
                  placeholder="Fax"
                  name="buyerFax"
                  value={values.buyerFax}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.buyerFax}
                  touched={touched.buyerFax}
                />
                <AddressInput
                  labelName="Buyer Address"
                  address={buyerAddress}
                  setAddress={setBuyerAddress}
                  handlePressSelect={handleByuerAddress}
                  error={errors.buyerAddress}
                  touched={touched.buyerAddress}
                />
              </div>
            }
          />


          <CustomModal
            title="Upload property to"
            width={500}
            closable={closeUpload}
            visible={openUpload}
            onCancel={closeUpload}
            modalData={
              <div
                className="row"
                style={{ marginTop: "30px", marginLeft: "10px" }}
              >
                <div className="col-lg-5 col-sm-12">
                  <Checkbox
                    checked={reaUplode}
                    onChange={() => onChangeUpload("rea")}
                  >
                    REA {isReaUploaded ? "(Update)" : null}
                  </Checkbox>
                </div>
                <div className="col-lg-5 col-sm-12">
                  <Checkbox
                    checked={domainUplode}
                    onChange={() => onChangeUpload("domain")}
                  >
                    Domain {isDomainUploaded ? "(Update)" : null}
                  </Checkbox>
                </div>
              </div>
            }
            footer={[
              <div
                key="footer"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  style={{ marginRight: "20px" }}
                  className="pdb-save-changes mt-2"
                  onClick={handleReaSubmit}
                >
                  Submit
                </button>
              </div>,
            ]}
          />
        </div>
        <div className="property-details-buttons">
          <button
            className="pdb-cancel"
            type="reset"
            onClick={cancel}
          >
            Cancel
          </button>
          <button className="pdb-save-changes" type="submit">
            Next
          </button>
          {/* <button
                  className="pdb-cancel"
                  type="button"
                  onClick={handleItemClickInPropertyListings}
                >
                  Save & Publish
                </button> */}
        </div>
      </form>

    </div>
  );
};

export default PropertyLegal;
