import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { updatemangmntagrmnttribunalinsurancedetails } from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { addParametersForProfiles, dynamicUrl, } from "../../../Utilities/profileManagement";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Switch, Checkbox } from "antd";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import "../openHome/openHome.css";
import Collapse from "../../input-components/collapse";
import { BiDollar } from "react-icons/bi";
import { tribunalFeesAgency } from "../../../components/CommonSection/Schema/schema";
import { useScrollToFirstError } from "../../../Utilities/ScrollToErrorField";

dayjs.extend(customParseFormat);


const initialValues = {
    ApplicationFeesForUtilites: '',
    OfficeExpenses: '',
    Redirect: false,
    STRATA: false,
    Water: false,
    CouncilRates: false,
    Insurance: false,
    PAccountName: '',
    PBSB: '',
    PAccountNo: '',
    councilRates: false
};

const TribulantFees = ({ onDataRecive, handleparentsuccess, handleCurrentPage, getDetails, reportTo, handleNextPage }) => {
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const ToastRef = useRef();


    useEffect(() => {
        if (getDetails) {
            setValues({ ...values, ...getDetails });
            setFieldValue("ApplicationFeesForUtilites", getDetails.applicationFeesForUtilites)
            setFieldValue("OfficeExpenses", getDetails.officeExpenses)
            setFieldValue("Redirect", getDetails.redirect)
            setFieldValue("STRATA", getDetails.strata)
            setFieldValue("Water", getDetails.water)
            setFieldValue("Insurance", getDetails.insurance)
            setFieldValue("PAccountName", getDetails.pAccountName)
            setFieldValue("PBSB", getDetails.pbsb)
            setFieldValue("PAccountNo", getDetails.pAccountNo)
            setFieldValue("councilRates", getDetails.councilRates)
        }
    }, [getDetails, reportTo]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
    }, []);

    const {
        errors,
        values,
        touched,
        isValid,
        submitCount,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
    } = useFormik({
        initialValues: initialValues,
        validationSchema: tribunalFeesAgency,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });

    const handleFinalSubmit = (data) => {
        let formData = {
            AgreementId: getDetails.id,
            OfficeExpenses: values.OfficeExpenses,
            Redirect: values.Redirect,
            STRATA: values.STRATA,
            Water: values.Water,
            CouncilRates: values.councilRates,
            Insurance: values.Insurance,
            PAccountName: values.PAccountName,
            PBSB: values.PBSB,
            PAccountNo: values.PAccountNo,
            ApplicationFeesForUtilites: values.ApplicationFeesForUtilites
        };
        const url = updatemangmntagrmnttribunalinsurancedetails;
        formData = addParametersForProfiles(formData);
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    onDataRecive(getDetails.propertyUId)
                    handleNextPage("PromotionalActivities")
                    ToastRef.current.notify(["success", returnData.message]);
                    //navigate(`/agency/ListOpenHome`);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const handleClear = () => {
        handleNextPage("FeesAndCharges")
    };

    const onChangeSwitch = (e, label) => {
        if (label === "Redirect") {
            setFieldValue("Redirect", e);
        }
    };

    const onChangeRadio = (e, label) => {
        if (label === "STRATA") {
            setFieldValue("STRATA", e.target.checked);
        } else if (label === "Water") {
            setFieldValue("Water", e.target.checked);
        } else if (label === "CouncilRates") {
            setFieldValue("councilRates", e.target.checked);
        } else if (label === "Insurance") {
            setFieldValue("Insurance", e.target.checked);
        }
    };

    const handleCancle = () => {
        navigate("/agency/listagreementtype");
    }
    useScrollToFirstError(isValid, errors, submitCount);

    return (
        <>
            <div className="dashboard-agency">
                <ToastAlert ref={ToastRef} />
                <div className="continer-dbag">
                    <div className="dashboardagency-content">
                        <div style={{ width: "100%" }}>
                            <form onSubmit={handleSubmit} className="details-form">
                                <div style={{ width: '100%' }}>

                                    <div style={{ width: '100%' }}>
                                        <Collapse
                                            id={"Fees and Charges"}
                                            title={"Tribunal Fees & Insurance Claims (Charges In Relation To The Management Of The Property)"}
                                            data={
                                                <div style={{ padding: "0px 30px" }}>
                                                    {/* <div className='s1'>Application fees for NCAT, Sherriff or other statutory authorities. Preparation of NCAT case. Organizing repairs, maintenance or refurbishments. Calculation of payments of any outgoings.</div> */}
                                                    <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                                                        <div className="address-pair">
                                                            <div className="labelssss ">Application fees for NCAT/Tribunal</div>
                                                            <div className="valuessss">: {"At Cost"}</div>
                                                        </div>
                                                    </div>
                                                    <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                                                        <div className="address-pair">
                                                            <div className="labelssss ">Sherriff or other statutory authorities</div>
                                                            <div className="valuessss">: {"At Cost"}</div>
                                                        </div>
                                                    </div>
                                                    <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                                                        <div className="address-pair">
                                                            <div className="labelssss ">Office expenses/Postage in relation to NCAT</div>
                                                            <div className="valuessss">: {"At Cost"}</div>
                                                        </div>
                                                    </div>
                                                    <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                                                        <div className="address-pair">
                                                            <div className="labelssss ">Postage </div>
                                                            <div className="valuessss">: {"At Cost"}</div>
                                                        </div>
                                                    </div>
                                                    <TextBox
                                                        isMandatory
                                                        label="Preparation, commuting and attendance of NCAT/Tribunal & Insurance"
                                                        name="ApplicationFeesForUtilites"
                                                        value={values.ApplicationFeesForUtilites}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.ApplicationFeesForUtilites}
                                                        touched={touched.ApplicationFeesForUtilites}
                                                        icon={
                                                            <BiDollar height="100%" width="100%" />
                                                        }
                                                        extraDetails="7 days from Invoice"
                                                    />
                                                </div>
                                            } />
                                        <Collapse
                                            id={"If the property is leased either:"}
                                            title={"I Authorize Foreal Property To Pay The Following Accounts:"}
                                            data={
                                                <div style={{ padding: "0px 30px" }}>
                                                    <div className="mb-3">
                                                        <div className="pd-eventDate col-lg-5 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <div className="mr-3" >Redirect</div>
                                                                <div><Switch checked={values.Redirect} onChange={(e) => onChangeSwitch(e, "Redirect")} /></div>
                                                            </div>
                                                        </div>
                                                        <div className="pd-eventDate col-lg-5 col-sm-12 mt-3">

                                                            <div><Checkbox className='s1' checked={values.STRATA} onChange={(e) => onChangeRadio(e, "STRATA")}>Strata</Checkbox></div>
                                                            <div><Checkbox className='s1' checked={values.Water} onChange={(e) => onChangeRadio(e, "Water")}>Water</Checkbox></div>
                                                            <div><Checkbox className='s1' checked={values.councilRates} onChange={(e) => onChangeRadio(e, "CouncilRates")}>Council Rates</Checkbox></div>
                                                            <div><Checkbox className='s1' checked={values.Insurance} onChange={(e) => onChangeRadio(e, "Insurance")}>Insurance</Checkbox></div>
                                                        </div>
                                                    </div>
                                                    {/* <div className='s1'>Please provide copy of rates for payment reference</div> */}
                                                </div>
                                            } />
                                        <Collapse
                                            id={"The Agent shall be entitled to the following ongoing fees"}
                                            title={"Rental Monies to Be Deposited Into the Following Account"}
                                            data={
                                                <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                    <TextBox
                                                        label="A/C Name"
                                                        name="PAccountName"
                                                        value={values.PAccountName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.PAccountName}
                                                        touched={touched.PAccountName}
                                                    />
                                                    <TextBox
                                                        label="BSB"
                                                        name="PBSB"
                                                        value={values.PBSB}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.PBSB}
                                                        touched={touched.PBSB}

                                                    />
                                                    <TextBox
                                                        label="A/C Number"
                                                        name="PAccountNo"
                                                        value={values.PAccountNo}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.PAccountNo}
                                                        touched={touched.PAccountNo}
                                                    />

                                                </div>
                                            } />
                                    </div>

                                </div>
                                <div className="property-details-buttons">
                                    <button
                                        className=" pdb-save-changes"
                                        type="reset"
                                        onClick={handleCancle}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="pdb-cancel"
                                        type="reset"
                                        onClick={handleClear}
                                    >
                                        Previous
                                    </button>
                                    <button className="pdb-save-changes" type="submit">
                                        Save & Next
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TribulantFees;
