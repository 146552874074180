import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { viewaccountchartpaginglist, ContactDetailsApi,addaccountchart } from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import "../../../components/Dashboard/Dashboard-agent/AgentList.css";
//import "./propertiesagency.css";
import { ActionStatus, ePropertyPurpose } from "../../../Utilities/enum";
import { addParametersForProfiles, dynamicUrl } from "../../../Utilities/profileManagement";
import SubNav from "../../navbar/subNav";
import AntdTableExample from "../../input-components/dataTable";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { message, Steps } from 'antd';
import CustomModal from "../../input-components/Modal";
import ToggleTabs from "../../input-components/toggleButtonTabs";
import axios from "axios";
import ShowValue from "../../input-components/ShowValue";
import TextBox from "../../input-components/textbox";
import TextArea from "../../input-components/textarea";
import SelectBox from "../../input-components/selectBoxNew";
import { useFormik } from "formik";

const initialValues = {
    code: "",
    type: "",
    isTax: false,
    hidden: false,
    name: "",
    category: "",
    description: ""
};

const AccountsList = () => {
    const [contactLists, setContactLists] = useState([]);
    const [userId, setUserId] = useState("");
    const [agencyID, setAgencyId] = useState("");
    const navigate = useNavigate();
    const [propertyType, setPropertyType] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [purpose, setPurpose] = useState("");
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [rows, setRows] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [openAddAccount, setOpenAddAccount] = useState(false);
    const [currentStep, setCurrentStep] = useState(2); // State for current step in Steps component
    const [getDetails, setGetDetails] = useState(null);
    const [dpdata, setDpdata] = useState([]);
    const [tabValue, setTabValue] = useState(1);
    const [addNew, setAddNew] = useState(false);
    const [edit, setEdit] = useState(false);
    const [view, setView] = useState(false);
    const [typeData, setTypeData] = useState([
        { value: 1, label: "Individual" },
        { value: 2, label: "Company" },
    ]);
    const [titleData, setTitleData] = useState([
        { value: 1, label: "Mr." },
        { value: 2, label: "Miss." },
        { value: 3, label: "Mrs." },
    ]);
    const [budgetData, setBudgetData] = useState([
        { value: 1, label: "$700000 & below" },
        { value: 2, label: "1M below" },
        { value: 3, label: "1M to 1.2M" },
        { value: 4, label: "$1.2 M & above" },
    ]);
    const accountChartType = [
        { value: 1, label: "Income" },
        { value: 2, label: "Expense" },
    ];
    const accountChartCategory = [
        { value: 1, label: "Letting Fee" },
        { value: 2, label: "Management Fee" },
        { value: 3, label: "Recoverable Fee" },
        { value: 4, label: "Rental Advertising" },
        { value: 5, label: "Sales Advertising" },
        { value: 6, label: "Sales Commission" },
        { value: 7, label: "Other Agency Fee" },

    ];

    const hiddenArr = [
        { value: true, label: "Yes" },
        { value: false, label: "No" },
    ]
    const taxArr = [
        { value: true, label: "Tax Inclusive" },
        { value: false, label: "Tax Exclusive" },
    ]
    const [selectedCountries, setSelectedCountries] = useState(null);

    const { Step } = Steps;



    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setUserId(parsedData.userId);
                    setAgencyId(parsedData.agencyID);
                    reportData(parsedData.agencyUniqueID);
                }
            } catch (error) {
                console.error("Error retrieving data from AsyncStorage:", error);
            }
        };
        retrieveData();
    }, [userId]);

    useEffect(() => {
        if (userId) PostApiWithSpinner(10);
    }, [userId, searchText, purpose, propertyType]);
    var url = new URL(window.location.href);
    var contactUID = url.searchParams.get("contactUID");

    useEffect(() => {
        if (contactUID) {
            GetApi(contactUID)
        }
    }, [contactUID])

    useEffect(() => {
        if (getDetails?.postcode != null) {
            setSelectedCountries(getDetails.postcode.split(";"));
        }
    }, [])


    const PostApiWithSpinner = (totalRows) => {
        const baseUrl = viewaccountchartpaginglist;
        let data = {
            AgencyId: agencyID,
            Search: searchText,
            RecordsPerPage: totalRows,
        };
        data = addParametersForProfiles(data);
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    let accData = response.list;
                    let accountList = accData.map((acc) => ({
                        Code: acc.code,
                        Name: acc.name,
                        Category: acc.sCategory,
                        Type: acc.type === 1 ? "Income" : "Expense",
                        Tax: acc.isTax ? "Yes" : "No",
                        Hidden: acc.hidden ? "Yes" : "No",
                        Description: acc.description,
                    }));
                    setTotalPages(response.totalCount)
                    setContactLists(accountList);
                } else {
                    alert(`Message: ${response.message}`);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleSearchChange = (searchValue) => {
        setSearchText(searchValue);
    };

    // Handler for page change
    const handlePageChange = (pageNumber, totalRows) => {
        setCurrentPage(pageNumber);
        setRows(totalRows);
        PostApiWithSpinner(pageNumber, totalRows);
    };

    const handleAddAgentClick = () => {
        dynamicUrl(navigate, "properties");
    };

    const tabColumns = {
        Status: {
            colorMap: {
                "Prospect": "gray",
                "Listed": "green",
            },
            defaultColor: 'blue'
        }
    };

    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm
    } = useFormik({
        initialValues: initialValues,
       // validationSchema: addNewUserOpenHome,
        onSubmit: (values, action) => {
            handleAddAccounts()
        },
    });

    const handleAddAccounts = () => {
        let formData = {
            id: 0,
            code: values.code,
            type: values.type,
            isTax: values.isTax,
            hidden: values.hidden,
            default: true, 
            name: values.name,
            category: values.category,
            agencyId: agencyID,
            description: values.description
        };
        const url = addaccountchart;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    message.success(returnData.message);
                    setOpenAddAccount(false);
                    PostApiWithSpinner(10);
                } else {
                    message.warning(returnData.message);
                }
            })
            .catch((error) => {
                //handleparentsuccess(["error", error.message]);
            });
    };


    const handleEditClick = (rowData) => {
        dynamicUrl(navigate, "properties" + "?propertyUID=" + rowData.guid);
    };

    const handleRowClick = (event, rowData) => {
        const clickedElement = event.originalEvent.target;
        if (clickedElement.className.includes("action-column")) {
            return;
        } else {
            dynamicUrl(navigate, "properties" + "?propertyUID=" + rowData.guid);
        }
    };

    const handleStatClick = (selectedPurpose) => {
        setPurpose(selectedPurpose);
    };

    const handleSearchValueChange = (value) => {
        setSearchText(value);
    };

    const handleOpenAccount = () => {
        const baseUrl = window.location.pathname;
        navigate(baseUrl);
        setOpenAddAccount(true);
        setTabValue(1);
        setAddNew(true);
        setEdit(false);
        setView(false);
        setGetDetails(null);
    }

    const closeAccount = () => {
        const baseUrl = window.location.pathname;
        navigate(baseUrl);
        setOpenAddAccount(false);
        setEdit(false);
        setView(false);
        setGetDetails(null);
        setAddNew(false);
    }

    const GetApi = (value) => {
        var url = new URL(window.location.href);
        var contactUniqueId = value ? value : url.searchParams.get("contactUID");
        const baseUrl = ContactDetailsApi;
        const apiUrl = `${baseUrl}?contactUniqueId=${contactUniqueId}`;
        makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get")
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    setGetDetails(response.object);
                } else {
                    handleparentsuccess(["warn", response.data.message]);
                }
            })
            .catch((error) => {
                handleparentsuccess(["error", error]);
            });
    };

    const reportData = (agencyUID) => {
        const apiUrl = assignedTo;
        const baseUrl = apiUrl + "?agencyUID=" + agencyUID;
        axios.get(baseUrl).then((response) => {
            if (response.data.object) setDpdata(response.data.object);
        });
    };

    const handleTabChange = (key) => {
        if (key === null) {
            setOpenAddAccount(false);
            PostApiWithSpinner();

        } else {
            setTabValue(key)
        }
    };

    const tabs = [
        { label: 'Personal Details', value: 1 },
        { label: 'Company Details', value: 2 },
        { label: 'Preference', value: 3 },
    ];

    const handleDataChild = (value) => {
        GetApi(value);
    };

    const handleEdit = (e, data) => {
        const baseUrl = window.location.pathname; // Get the current path without query params
        const newUrl = `${baseUrl}?contactUID=${data.guid}`; // Append the contactUID to the URL
        navigate(newUrl);
        setOpenAddAccount(true);
        setEdit(true);
        setTabValue(1)
    }

    const handleView = (e, data) => {
        const baseUrl = window.location.pathname; // Get the current path without query params
        const newUrl = `${baseUrl}?contactUID=${data.guid}`; // Append the contactUID to the URL
        navigate(newUrl);
        setOpenAddAccount(true);
        setView(true);
        setTabValue(1)
    }

    const actions = [
        { icon: "/assets/images/visible.svg", onClick: (e, data) => handleView(e, data) },
        { icon: "/assets/images/editing.svg", onClick: (e, data) => handleEdit(e, data) },
    ]

    const options = [
        { value: 1, label: "Seller" },
        { value: 2, label: "Buyer" },
        { value: 3, label: "Investor" },
        { value: 4, label: "Tenant" },
        { value: 5, label: "Landlord" },
        { value: 6, label: "Developer" },
        { value: 7, label: "Supplier" },
    ];

    const commaSeparatedString =
        getDetails?.typeIAM && typeof getDetails?.typeIAM === "string"
            ? getDetails?.typeIAM
                .split(",")
                .map((value) => {
                    const option = options.find((opt) => opt.value === parseInt(value));
                    return option ? option.label : null;
                })
                .filter((label) => label !== null)
                .join(", ")
            : null;

    const optionsPrefference = [
        { value: 1, label: "Investment" },
        { value: 2, label: "Development" },
        { value: 3, label: "First Home Buyer" },
        { value: 4, label: "Renting" },
        { value: 5, label: "Selling" },
        { value: 6, label: "Buying" },
    ];

    const commaSeparatedString1 =
        getDetails?.otherDetailsType && typeof getDetails?.otherDetailsType === "string"
            ? getDetails?.otherDetailsType
                .split(",")
                .map((value) => {
                    const option = optionsPrefference.find((opt) => opt.value === parseInt(value));
                    return option ? option.label : null;
                })
                .filter((label) => label !== null)
                .join(", ")
            : null;
    return (
        <div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
                    <SubNav title={"Chart of Accounts"} goback={false} extraData={true} extraContent={
                        <div className="property-details-buttons">
                            <button onClick={handleOpenAccount} className="subnav-btn" type="submit">
                                <HiOutlinePlusSmall style={{ fontSize: '24px' }} /> Add
                            </button>
                        </div>
                    } />
                    <div className="material-work-desk-new bg-white m-3">
                        <AntdTableExample
                            label="List of Accounts"
                            dataSource={contactLists}
                            globalSearch
                            hideCol={"uniqueId"}
                            onPageChange={handlePageChange}
                            currentPage={currentPage}
                            totalRows={rows}
                            totalRecords={totalPages}
                            tagColumns={tabColumns}
                            onSearchValueChange={handleSearchValueChange}
                            //actions={actions}
                        />
                    </div>
                    <CustomModal footer={null} title={view ? "View Contact Details" : edit ? "Update Contacts" : "Add Accounts"} width={800} closable={closeAccount} visible={openAddAccount} onCancel={closeAccount}
                        modalData={
                            <div>
                                <div>
                                    {/* View Mode: Display only details */}
                                    {view && (
                                            <div className="details-form p-3">
                                                {tabValue === 1 && (
                                                    <div className="contacts-group">
                                                        <ShowValue Label='Contact Type' Data={typeData} setData={getDetails?.contactType} />
                                                        <ShowValue Label='Title' Data={titleData} setData={getDetails?.title} />
                                                        <ShowValue Label='First Name' setData={getDetails?.firstName} />
                                                        <ShowValue Label='Last Name' setData={getDetails?.lastName} />
                                                        <ShowValue Label='Type (I am)' setData={commaSeparatedString} />
                                                        <ShowValue Label='Phone' setData={getDetails?.mobileNumber} />
                                                        <ShowValue Label='Email Address' setData={getDetails?.email} />
                                                        <ShowValue Label='Address' setData={getDetails?.address} />
                                                    </div>
                                                )}
                                                {tabValue === 2 && (
                                                    <div className="contacts-group">
                                                        <ShowValue Label='ABN' setData={getDetails?.abn} />
                                                        <ShowValue Label='ACN' setData={getDetails?.acn} />
                                                        <ShowValue Label='Company Name' setData={getDetails?.companyName} />
                                                    </div>
                                                )}
                                                {tabValue === 3 && (
                                                    <div className="contacts-group">
                                                        <ShowValue Label='Purpose' setData={commaSeparatedString1} />
                                                        <ShowValue Label='Budget' Data={budgetData} setData={getDetails?.priceRange} />
                                                        <ShowValue Label='State / Region' setData={getDetails?.regions} />
                                                        <ShowValue Label='Postcode' setData={selectedCountries} />
                                                    </div>
                                                )}
                                            </div>

                                    )}

                                    {/* Edit Mode: Display forms for editing */}
                                    {!view && (
                                        <form onSubmit={handleSubmit} className="details-form" style={{ display: 'initial', width: '100%' }}>
                                        <div className="row">
                                            <TextBox label="Account No" name="code" value={values.code} onChange={handleChange} onBlur={handleBlur} error={errors.code} touched={touched.code}/>
                                            <SelectBox label="Account Type" name="type" options={accountChartType} value={values.type}  onChange={(val) => setFieldValue("type", val.value)} onBlur={handleBlur} error={errors.type} touched={touched.type}/>
                                            <TextBox label="Name" name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} error={errors.name} touched={touched.name}/>
                                            <SelectBox label="Tax" name="isTax" options={taxArr} value={values.isTax}onChange={(val) => setFieldValue("isTax", val.value)} onBlur={handleBlur} error={errors.isTax} touched={touched.isTax}/>
                                            <SelectBox label="Category" name="category" options={accountChartCategory} value={values.category} onChange={(val) => setFieldValue("category", val.value)} onBlur={handleBlur} error={errors.category} touched={touched.category}/>
                                            <SelectBox label="Hidden" name="hidden" options={hiddenArr} value={values.hidden} onChange={(val) => setFieldValue("hidden", val.value)} onBlur={handleBlur} error={errors.hidden} touched={touched.hidden}/>
                                            <TextArea colVal={12} name="description" label="Description" value={values.description} onChange={handleChange} onBlur={handleBlur} error={errors.description} touched={touched.description}/>
                                                <div className="property-details-buttons">
                                                    <button className="pdb-cancel" type="reset">
                                                        Cancel
                                                    </button>
                                                    <button className="pdb-save-changes" type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                        </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                        }

                    />
                </div>
            </div>
        </div>
    );
};

export default AccountsList;
