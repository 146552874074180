const base = import.meta.env.VITE_API_BASE_URL;

export const apiGetPendingProfile =
  base + "user/getpendingprofilefornewlandlord";
export const apiUrlap = base + "user/addpropertyfornewlandlord";
export const apiUrlowner = base + "user/addownershipproofTonewlandlord";
export const apiUrlver = base + "user/addverificationdocumenttonewlandlord";
export const apiUrlamo = base + "user/addotherownersTonewlandlord";

//rent
export const GetPendingProfiletenant =
  base + "/Rent/getdetailsforrentapplication";
export const addressRentPropertyDetails =
  base + "Rent/addrentapppropertydetails";
export const baseUrl1 = base + "Rent/addverificationdocumenttonewtenant";
export const addAddressHistoryDetails = base + "rent/addrentappaddresshistory";
export const addEmploymentDetails = base + "rent/addrentappemployement";

//account
export const userRegistration = base + "Account/Register";
export const userLogin = base + "Account/Login";
export const userOtpToken = base + "Account/GetUserByTokenAndTokenType";
export const userPasswordChange = base + "Account/changepassword";
export const userForgotPassword = base + "Account/forgotpasswordotp";
export const GetUserByTokenAndTokenType =
  base + "/Account/GetUserByTokenAndTokenType";

//agency
export const addPrincipalDetails = base + "agency/addPrincipalDetails";
export const addVerificationAgency =
  base + "agency/addverificationdocumenttonewagency";
export const addLicenseDetails = base + "agency/addAgencyLicenseDetails";
export const addAddCompany = base + "agency/addAgencyCompanyDetails";
export const addInsuranceDetails = base + "agency/addAgencyInsuranceDetails";
export const addPaymentDetails = base + "agency/addUpdatePaymentDetails";
export const getPendingAgencyDetails = base + "agency/getagencybyUseruid";
export const getAgencyApprovalDetails = base + "agency/getagencybyuid";
export const addAgencyApprovalStatus = base + "agency/approveNewAgency";
export const resubmitAgency = base + "agency/resubmitAgency";
//profile---agency
export const getProfileDetails = base + "agency/getagencybyuid";
export const addPersonalDetailsProfile =
  base + "agency/addpersonalProfileDetails";
export const addCompanyProfileDetails =
  base + "agency/addCompanyProfileDetails";
export const addBrandingProfileDetails =
  base + "agency/addBrandingProfileDetails";
export const addtrustaccountProfileDetails =
  base + "agency/addtrustaccountProfileDetails";
export const addportalaccountProfileDetails =
  base + "agency/addportalaccountProfileDetails";
export const searchAreaOfOperation =
  base + "property/SearchPostCodesForSelect2";

export const getAgentList = base + "agency/agent/getagentlist";
export const getAgentDetails = base + "agency/agent/getagentbyagentid";
export const addpersonalAgentDetails =
  base + "agency/agent/addpersonalAgentDetails";
export const addLicenseAgentDetails =
  base + "agency/agent/addlicensingAgentDetails";
export const addExperienceAgentDetails =
  base + "agency/agent/addexperienceAgentDetails";
export const addContactAgentDetails =
  base + "agency/agent/addcontactAgentDetails";
export const addRemunrationAgentDetails =
  base + "agency/agent/addremunerationAgentDetails";
export const getReportingAgent =
  base + "agency/agencystaff/getAgencyPrincipleDDL";

//AWS S# Bucket Crendentials
export const AccessKey = import.meta.env.VITE_AWS_ACCESS_KEY;
export const Keyid = import.meta.env.VITE_AWS_SECRET_KEY;
export const Region = import.meta.env.VITE_AWS_REGION;
export const BucketName = import.meta.env.VITE_AWS_BUCKET_NAME;
export const FilePath = import.meta.env.VITE_AWS_FILE_PATH;


export const defaultProfilePic = import.meta.env.VITE_DEFAULT_PROFILE_PIC;
// ABN Lookup Configuration
export const ABNLookUpGuid = import.meta.env.VITE_ABN_LOOKUP_GUID;
export const ABNLookUpbaseURL = import.meta.env.VITE_ABN_LOOKUP_BASE_URL;
// License Number Configuration
export const LicenceNumberBaseKey = import.meta.env.VITE_LICENSE_NUMBER_BASE_KEY;
export const LicenseTokenUrl = import.meta.env.VITE_LICENSE_TOKEN_URL;
export const LicenseVerifyUrl = import.meta.env.VITE_LICENSE_VERIFY_URL;
export const LicenseApiKey = import.meta.env.VITE_LICENSE_API_KEY;

export const createPropertyAddress = base + "property/addpropertyAddress";
export const assignedTo = base + "agency/agent/getActiveAgentDDL";
export const PropertyList = base + "property/getpropertylist";
export const propertyDetails = base + "property/getpropertybyid";
export const addPropertyLegal = base + "property/addpropertySellerBuyer";
export const addPropertyListings = base + "property/addpropertyListing";
export const PropertyDocumentsUrl = base + "property/addpropertyDocument";
export const addPropertyAttributes = base + "property/addpropertyAttributes";
export const propertyPhotos = base + "property/addpropertyImages";
export const PropertyAddOwnerShipUrl = base + "property/addmultipleowners";
export const PropertySetPrimaryUrl = base + "property/setprimaryowner";

export const LandingPageListDetails = base + "property/getGenericpropertylist";
export const LandingPageSearch = base + "property/SearchPostCodesForSelect2";

export const ContactLists = base + "contact/getcontactlist";
export const ContactDetailsApi = base + "contact/getcontactbyid";
export const ContactPersonalApi = base + "contact/updatecontactpersonal";
export const ContactCompanyUrl = base + "contact/updatecontactcompany";
export const ContactPreferenceUrl = base + "contact/updatecontactpreference";
export const ContactOtherUrl = base + "contact/updatecontactother";

export const LandingPageDetails = base + "property/getpropertyforuser";
export const AddEnquiry = base + "Enquiry/addUpdateEnquiry";

export const ViewSelectedPostCode =
  base + "property/SearchPostCodesByIdsForSelect2";

export const ReaUplode = base + "property/ListPropertyOnRea";

export const EnquiryListSearch =
  base + "property/SearchPortalEnquiryPropertyForSelect2";

export const ListPortalEnquiry = base + "property/ListPropertyEnquiryOnRea";

//export const ReaUplode = base + "property/ListPropertyOnRea";

export const SearchProperty = base + "property/searchenlistPropertyForSelect2";

export const OpenListHome = base + "property/getopenhomepropertylist";

export const AddOpenHome = base + "property/addupdateopenhomeproperty";

export const getOpenHomeDetails = base + "property/getopenhomedetailAndusers";

export const updateOpenHomeDetails = base + "property/getopenhomedetail";

export const searchPropertyList =
  base + "property/searchenlistPropertyByIdsForSelect2";

export const SearchUserByContact = base + "property/openhomeuserForSelect2";

export const addExistingUser = base + "property/openhomeregisterexistinguser";

export const addNewUserOpenHome = base + "property/addopenhomeregistration";

export const SearchContacts = base + "property/SearchContactsByAgentForSelect2";

export const AddExistingContact = base + "property/updatepropertyOwnerDetails";

export const getContactActivitiesList =
  base + "contact/getcontactactivtieslist";

export const deleteOpenhome = base + "property/deleteopenhome";

export const ListPropertyActiviyies = base + "property/getPropertyActivities";

export const ListTask = base + "agency/task/gettasklist";

export const AddTask = base + "agency/task/addupdateTask";

export const TaskDetails = base + "agency/task/getTaskdetails";

export const TaskCompleted = base + "agency/task/completeTask";

export const TaskApproved = base + "agency/task/approvetask";

export const AgreementTemplate =
  base + "agency/AgreementTemplate/getAgreementtemplatelist";

export const ActiveProperty = base + "property/SearchPropertyForSelect2";

export const getManagementAgreement =
  base + "/agency/AgreementTemplate/getmanagementagreementbypropertyId";

export const CreateManagementAgreement =
  base + "agency/AgreementTemplate/createManagementAgreement";

export const UpdateManagementAgreementStatus =
  base + "agency/AgreementTemplate/updateManagementAgreementStatus";

export const updatemangmntAgrmntPropertyDetails =
  base + "agency/AgreementTemplate/updatemangmntAgrmntPropertyDetails";

export const updatemangmntagrmntperioddetails =
  base + "agency/AgreementTemplate/updatemangmntagrmntperioddetails";

export const updatemangmntagrmntfeeschargedetails =
  base + "agency/AgreementTemplate/updatemangmntagrmntfeeschargedetails";

export const updatemangmntagrmnttribunalinsurancedetails =
  base + "agency/AgreementTemplate/updatemangmntagrmnttribunalinsurancedetails";

export const updatemangmntagrmntpromotiondetails =
  base + "agency/AgreementTemplate/updatemangmntagrmntpromotiondetails";

export const updatemangmntagrmntrepairsdetails =
  base + "agency/AgreementTemplate/updatemangmntagrmntrepairsdetails";

export const sendmangmntagrmnttoowners =
  base + "agency/AgreementTemplate/sendmangmntagrmnttoowners";

export const getmanagementagreementforsign =
  base + "/agency/AgreementTemplate/getmanagementagreementforsign";

export const managementagreementsignature =
  base + "agency/AgreementTemplate/managementagreementsignature";

export const generatePdfReport =
  base + "agency/AgreementTemplate/generatePdfReport";

export const VerifyDigitalId = base + "user/digitalIdVerification";

export const AgreementDocumentList =
  base + "agency/AgreementTemplate/getAgreemetnDocumentList";

export const getTenancyAgreement =
  base + "/agency/AgreementTemplate/gettenantleaseagreementbypropertyId";

export const CreateTenancyAgreement =
  base + "agency/AgreementTemplate/createTenantAgreement";

export const updateLandlordTenantAgreementDetails =
  base + "agency/AgreementTemplate/updateLandlordTenantAgreementDetails";

export const updateRentBondTenantAgreementDetails =
  base + "agency/AgreementTemplate/updateRentBondTenantAgreementDetails";

export const updateInformationAgreementDetails =
  base + "agency/AgreementTemplate/updateInformationAgreementDetails";

export const updateTenantsInAgreement =
  base + "agency/AgreementTemplate/updateTenantsInAgreement";

export const sendtenantagrmnttotenants =
  base + "agency/AgreementTemplate/sendtenantagrmnttotenants";

export const gettenantagreementforsign =
  base + "agency/AgreementTemplate/gettenantagreementforsign";

export const tenantagreementsignature =
  base + "agency/AgreementTemplate/tenantagreementsignature";

export const generateTenantAgreementPdfReport =
  base + "agency/AgreementTemplate/generateTenantAgreementPdfReport";

export const generatetenantcheckreport = base + "User/generateusercheckreport";

export const ListAllProperties = base + "property/getPropertyListingList";

export const OpenHomeSearchProperty =
  base + "property/searchPropertyListingForSelect2";

export const SearchPropertyForAddListing =
  base + "property/SearchPropertyForAddListing";

export const uploadagreementfromproperty =
  base + "agency/AgreementTemplate/uploadsalesagreementfromproperty";

export const updatefeefromproperty =
  base + "agency/AgreementTemplate/updatefeefromproperty";

export const getsalesagreementbypropertyId =
  base + "/agency/AgreementTemplate/getsalesagreementbypropertyId";

export const createSaleAgreement =
  base + "agency/AgreementTemplate/createSaleAgreement";

export const updateSalesAgreementStatus =
  base + "agency/AgreementTemplate/updateSalesAgreementStatus";

export const updatesalesAgrmntsolicitorDetails =
  base + "agency/AgreementTemplate/updatesalesAgrmntsolicitorDetails";

export const updatesalesAgrmntPropertyDetails =
  base + "agency/AgreementTemplate/updatesalesAgrmntPropertyDetails";

export const updatesalesagrmntperioddetails =
  base + "agency/AgreementTemplate/updatesalesagrmntperioddetails";

export const updatesalesagrmntfeeschargedetails =
  base + "agency/AgreementTemplate/updatesalesagrmntfeeschargedetails";

export const updatesalesagrmnttribunalinsurancedetails =
  base + "agency/AgreementTemplate/updatesalesagrmnttribunalinsurancedetails";

export const updatesalesagrmntpromotiondetails =
  base + "agency/AgreementTemplate/updatesalesagrmntpromotiondetails";

export const updatesalesagrmntrepairsdetails =
  base + "agency/AgreementTemplate/updatesalesagrmntrepairsdetails";

export const sendsaleagrmnttoowners =
  base + "agency/AgreementTemplate/sendsaleagrmnttoowners";

export const getsalesagreementforsign =
  base + "/agency/AgreementTemplate/getsalesagreementforsign";

export const salesagreementsignature =
  base + "agency/AgreementTemplate/salesagreementsignature";

export const generatesalesagreementPdfReport =
  base + "agency/AgreementTemplate/generatesalesagreementPdfReport";

export const addupdateTenant = base + "property/addupdateTenant";

export const addtenantfromcontact = base + "property/addtenantfromcontact";

export const updatetenantfoliodetails =
  base + "property/updatetenantfoliodetails";

export const updatesalesagrmntremunerationdetails =
  base + "agency/AgreementTemplate/updatesalesagrmntremunerationdetails";

export const updatesalesagrmntexpenseschargedetails =
  base + "agency/AgreementTemplate/updatesalesagrmntexpenseschargedetails";

export const resetEmail =
  base + "agency/AgreementTemplate/resendagreementforsign";

export const getAgreementDocumentListForProperty =
  base + "/agency/AgreementTemplate/getAgreementDocumentListForProperty";

export const getpropertyhistorybypropertyuid =
  base + "property/getpropertyhistorybypropertyuid";

export const uploadtenantagreementfromproperty =
  base + "agency/AgreementTemplate/uploadtenantagreementfromproperty";

export const uploadmanagementagreementfromproperty =
  base + "agency/AgreementTemplate/uploadmanagementagreementfromproperty";

export const getTicketlist = base + "Ticket/getTicketlist";

export const addupdateTicket = base + "/Ticket/addupdateTicket";

export const getTicketbyid = base + "Ticket/getTicketbyid";

export const addUpdateComment = base + "Ticket/addUpdateComment";

export const closeTicket = base + "Ticket/closeTicket";

export const deleteagreement =
  base + "agency/AgreementTemplate/deleteagreement";

export const resendverificationtonewuser =
  base + "User/resendverificationtonewuser";

export const addupdatetaskNote = base + "agency/Task/addupdatetaskNote";


export const addupdatetenant = base + "Maintenance/addupdatemaintenance";

export const getmaintenanceList = base + "Maintenance/getmaintenanceList";

export const getmaintenancebyuniqueid =
  base + "Maintenance/getmaintenancebyuniqueid";

export const getpropertybyfolioid = base + "property/getpropertybyfolioid";

export const reviewedbyagent = base + "Maintenance/reviewedbyagent";

export const getmaintenanceListforportal =
  base + "Maintenance/getmaintenanceListforportal";

export const addupdateqoute = base + "/Maintenance/addupdateqoute";

export const getquotepaginglistbymaintenanceuid =
  base + "/Maintenance/getquotepaginglistbymaintenanceuid";

export const quotereviewedbyagent = base + "/Maintenance/quotereviewedbyagent";

export const getquotedetailsbyid = base + "/Maintenance/getquotedetailsbyid";

export const viewjobdetailsbymaintenanceuid =
  base + "/Maintenance/viewjobdetailsbymaintenanceuid";

export const addjobschedule = base + "/Maintenance/addjobschedule";

export const reviewjobschedule = base + "/Maintenance/reviewjobschedule";

export const updatemaintenancestatus =
  base + "/Maintenance/updatemaintenancestatus";

export const uploadjobphotos = base + "/Maintenance/uploadjobphotos";

export const completemaintenance = base + "/Maintenance/completemaintenance";
export const resendcompletedagreement = base + "/agency/AgreementTemplate/resendcompletedagreement";

export const addfacility = base + 'inspection/addfacility';

export const viewinspectionmasterdate = base + 'inspection/viewinspectionmasterdate';

export const addattribute = base + 'inspection/addattribute';

export const updateattribute = base + 'inspection/updateattribute';

export const linkfacilitiestoattribute = base + 'inspection/linkfacilitiestoattribute';

export const addinspection = base + 'inspection/addinspection';

export const getinspectionspagedlist = base + 'inspection/getinspectionspagedlist';

export const getinspectionbyuniqueid = base + 'inspection/getinspectionbyuniqueid';

export const geteditinspectiontemplate = base + 'inspection/geteditinspectiontemplate';

export const updateinspection = base + 'inspection/updateinspection';

export const submittemplatedetails = base + 'inspection/submittemplatedetails';

export const addpicturestoinspection = base +'inspection/addpicturestoinspection';

export const submitupdatedtemplatedetails = base + 'inspection/submitupdatedtemplatedetails';

export const getaddedittemplatedetails = base + 'inspection/getaddedittemplatedetails';

export const getaddupdatedacilitiestotemplate = base + 'inspection/getaddupdatedacilitiestotemplate';

export const submitupdatetemplatefacilities = base +'inspection/submitupdatetemplatefacilities';

export const generateroutineinspectionreport = base + 'inspection/generateroutineinspectionreport';

export const generateentryexitinspectionreport = base + 'inspection/generateentryexitinspectionreport';


export const addupdatesupplier = base + "supplier/addupdatesupplier";

export const updatesupplierdetails = base + "supplier/updatesupplierdetails";

export const updatesupplierpaymentdetails =
  base + "supplier/updatesupplierpaymentdetails";

export const getsupplierslist = base + "supplier/getsupplierslist";

export const getbillslist = base + "transaction/bill/getbillslist";

export const getaccountcharts = base + "transaction/accountchart/getaccountcharts";

export const addrentreceipt = base + "receipt/addrentreceipt";

export const addaccountchart = base + "transaction/accountchart/addaccountchart";

export const viewaccountchartpaginglist =
  base + "transaction/accountchart/viewaccountchartpaginglist";

  export const addupdateInvoice = base + "transaction/invoice/addupdateInvoice";

  export const getInvoiceDetailsById =
  base + "transaction/invoice/getInvoiceDetailsById";

export const payinvoices = base + "/transaction/invoice/payinvoices";

export const getInvoicelist = base + "transaction/invoice/getInvoicelist";

export const getmaintenancelistfortradesman = base + "Maintenance/getmaintenancelistfortradesman";

export const addupdatemessagethread = base + "messagethread/addupdatemessagethread";

export const getmaintenanceListforportalforproperty = base + "Maintenance/getmaintenanceListforportalforproperty";

export const getsupplierslistwithtype = base + "supplier/getsupplierslistwithtype"

export const getpropertyactivitiesdetails = base +"property/getpropertyactivitiesdetails"

//ai apis
export const processaisaleagreement = base + "agency/AgreementTemplate/processalesagreementfromai"

export const addupdatecontact = base +"contact/addupdatecontact"

export const addgenericnotes = base +"/genericnotes/addgenericnotes"

export const getgenericnotes = base + "/genericnotes/getgenericnotes"

export const finaliseinspection = base + "inspection/finaliseinspection"

export const deletepropertylisting = base + "/property/deletepropertylisting"

export const searchpropertyconvyencor = base +"property/searchpropertyconvyencor"

export const getsidebarforuser = base + "module/getsidebarforuser"

export const getmodulesfortheUser = base + "module/getmodulesfortheUser"

export const submitpermissionsforuser = base + "module/submitpermissionsforuser"

export const getnavbarforuser = base + "module/getnavbarforuser"

export const readnotification = base + "module/readnotification"

export const getactivitylogformodule = base + "module/getactivitylogformodule"