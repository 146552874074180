import React, { useEffect, useState, useRef } from 'react';
import "./../maintainance.css";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { getmaintenancebyuniqueid, getmaintenanceListforportal, addupdateqoute } from "../../../../components/CommonSection/apiresources";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../Utilities/enum";
import { useNavigate } from "react-router-dom";
import SubNav from '../../../navbar/subNav';
import { GetCookie, currentProfile } from '../../../../components/CommonSection/Cookie/Session';
import ImageGalleryModal from '../../../input-components/imageGallary';
import CustomModal from '../../../input-components/Modal';
import TextBox from '../../../input-components/textbox';
import { useFormik } from "formik";
import { BiDollar } from "react-icons/bi";
import { Checkbox } from 'antd';
import TextArea from '../../../input-components/textarea';
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";

const initialValues = {
    QuotePrice: "",
    CallOutCharge: "",
    ServiceCategory: "",
    ServiceDescription: "",
    TotalAmount: "",
};

const JobPortalDetails = () => {
    const [jobDetails, setJobDetails] = useState({});
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [openAddQuote, setOpenAddQuote] = useState(false)
    const navigate = useNavigate();
    var url = new URL(window.location.href);
    var maintenanceUId = url.searchParams.get("maintenanceUId");
    var supplierid = url.searchParams.get("supplier");
    const currentLogin = currentProfile.get();
    const [tradeId, setTradeId] = useState("");
    const [userId, setUserId] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const ToastRef = useRef();


    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setUserId(parsedData.userId);
                    setTradeId(parsedData.supplierId)
                    fetchDataWithSpinner1(parsedData.supplierId);
                } else {
                    navigate("/signin");
                }

            } catch (error) {
                console.error("Error retrieving data from AsyncStorage:", error);
            }
        };
        retrieveData();
    }, [userId]);

    useEffect(() => {
        fetchDataWithSpinner();
    }, []);

    const fetchDataWithSpinner = () => {
        let baseUrl = getmaintenancebyuniqueid + `?maintenaceUId=${maintenanceUId}`;
        if (supplierid) {
            baseUrl += `&SupplierId=${supplierid}`;
        }
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "get", null, null, false)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    setJobDetails(response.object);

                    let data = response.object
                    setJobDetails(data);

                    if (data.quoteDetails) {
                        setFieldValue("QuotePrice", data.quoteDetails.quotePrice || "");
                        setFieldValue("CallOutCharge", data.quoteDetails.callOutCharge || "");
                        setFieldValue("ServiceCategory", data.quoteDetails.serviceCategory || "");
                        setFieldValue("ServiceDescription", data.quoteDetails.serviceDescription || "");
                        setFieldValue("TotalAmount", data.quoteDetails.totalAmount || "");
                    }
                } else {
                    console.error("Error:", response.message);
                }
            })
            .catch((error) => {
                console.error("Error:", error.message);
            });
    };

    const handleListQuote = (id, name, details) => {
        if (name) {
            setFieldValue("ServiceCategory", name)
        }
        setOpenAddQuote(true)
    };

    const goBack = () => {
        navigate(`/JobPortal`);
    }

    const closeAddQuote = () => {
        setOpenAddQuote(false)
    }

    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
    } = useFormik({
        initialValues: initialValues,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });

    console.log(jobDetails,'pppp')

    const handleFinalSubmit = (data) => {
        let formData = {
            MaintenanceId: parseInt(jobDetails.maintenanceId),
            QuoteId: jobDetails.quoteDetails ? jobDetails.quoteDetails.quoteId : 0,
            QuotePrice: parseInt(values.QuotePrice),
            CallOutCharge: values.CallOutCharge,
            ServiceCategory: values.ServiceCategory,
            ServiceDescription: values.ServiceDescription,
            TotalAmount: parseInt(values.TotalAmount),
            TradeId: tradeId ? tradeId : null,
            TradeIdUniqueId:  supplierid,
        };
        const url = addupdateqoute;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData, null, false)
            .then((returnData) => {
                debugger;
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    setOpenAddQuote(false);
                    fetchDataWithSpinner();
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };


    const handleChangeCheck = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleChangePrice = (e) => {
        const quotePrice = parseFloat(e.target.value) || 0;
        const callOutCharge = parseFloat(values.CallOutCharge) || 0;

        if (isChecked) {
            setFieldValue("TotalAmount", quotePrice + callOutCharge);
        } else {
            setFieldValue("TotalAmount", quotePrice);
        }

        setFieldValue("QuotePrice", e.target.value);
    };

    const handleCallOutChargeChange = (e) => {
        const callOutCharge = parseFloat(e.target.value) || 0;
        const quotePrice = parseFloat(values.QuotePrice) || 0;

        if (isChecked) {
            setFieldValue("TotalAmount", quotePrice + callOutCharge);
        }

        setFieldValue("CallOutCharge", e.target.value);
    };

    console.log(jobDetails,'supplierid')
    return (
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
                <SubNav title={"Job Portal Details"} goback={goBack} extraData={true} extraContent={
                    <div>

                        {supplierid || tradeId && <button className="quote-btn" onClick={() => handleListQuote(jobDetails.maintenanceId, jobDetails.title, jobDetails.quoteDetails)}>{jobDetails.quoteDetails === null ? "Add Quote":"Update Quote"}</button>}

                    </div>
                } />
                <div className="material-work-desk-new bg-white m-3">
                <ToastAlert ref={ToastRef} />
                    <div className="job-details-card">
                        <div className="main-view-label">
                            <div className="pl-2 d-flex align-items-center">
                                <div className="labelMaintainance mr-2" style={{ color: '#70c1d0' }}>
                                    Location:
                                </div>
                                <div>
                                    {jobDetails.propertySuburb}
                                </div>
                            </div>
                            <div className="pl-2 d-flex align-items-center">
                                <div className="labelMaintainance mr-2" style={{ color: '#70c1d0' }}>
                                    Type:
                                </div>
                                <div>
                                    {jobDetails.sIssueType}
                                </div>
                            </div>
                            <div className="pl-2 d-flex align-items-center">
                                <div className="labelMaintainance mr-2" style={{ color: '#70c1d0' }}>
                                    Posted Date:
                                </div>
                                <div>
                                    {new Date(jobDetails.createdAt).toLocaleDateString()}
                                </div>
                            </div>
                            <div className="pl-2 d-flex align-items-center">
                                <div className="labelMaintainance mr-2" style={{ color: '#70c1d0' }}>
                                    Due Date:
                                </div>
                                <div>
                                    {jobDetails.dueDate ? new Date(jobDetails.dueDate).toLocaleDateString() : '-'}
                                </div>
                            </div>
                        </div>
                        <div className="main-section d-flex">
                            <div className="section-left pl-5 pr-5 pb-5 pt-1">
                                <div className="pl-2 mb-4 d-flex">
                                    <div className="w-50 mr-3">
                                        <div className="labelMaintainance mb-3" style={{ fontSize: '20px' }}>{jobDetails.title}</div>
                                        <div >{jobDetails.description}</div>
                                    </div>
                                    <div className="section-right">

                                        {jobDetails.photos?.length > 0 ?
                                            <div className="image-container">
                                                {/* Job Images Section */}
                                                <div className="uploaded-images-container">
                                                    <ImageGalleryModal images={jobDetails.photos} filePath={true} />
                                                </div>
                                            </div> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CustomModal footer={null} title={jobDetails.quoteDetails !== null ? "Update Quote":"Add Quote"} width={800} closable={closeAddQuote} visible={openAddQuote} onCancel={closeAddQuote}
                            modalData={
                                <div>
                                    <form onSubmit={handleSubmit} className="" style={{ display: "initial", width: "100%" }}>
                                        <div >
                                            <div className="row" style={{ padding: "0px 30px" }}>
                                                <TextBox
                                                    label="Quote Price"
                                                    id="QuotePrice"
                                                    name="QuotePrice"
                                                    value={values.QuotePrice}
                                                    onChange={handleChangePrice}
                                                    onBlur={handleBlur}
                                                    error={errors.QuotePrice}
                                                    touched={touched.QuotePrice}
                                                    icon={
                                                        <BiDollar height="100%" width="100%" />
                                                    }
                                                    extraDetails={<Checkbox checked={isChecked} onClick={handleChangeCheck}>Call out chargeable</Checkbox>}
                                                />


                                                {isChecked ?
                                                    <TextBox
                                                        label="Call Out Charge"
                                                        id="CallOutCharge"
                                                        name="CallOutCharge"
                                                        value={values.CallOutCharge}
                                                        onChange={handleCallOutChargeChange}
                                                        onBlur={handleBlur}
                                                        error={errors.CallOutCharge}
                                                        touched={touched.CallOutCharge}
                                                    /> : null}

                                                <TextBox
                                                    label="Total Amount"
                                                    id="TotalAmount"
                                                    name="TotalAmount"
                                                    value={values.TotalAmount}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.TotalAmount}
                                                    touched={touched.TotalAmount}
                                                    icon={
                                                        <BiDollar height="100%" width="100%" />
                                                    }
                                                />



                                            </div>
                                            <div className="row" style={{ padding: "0px 30px" }}>

                                                <TextBox
                                                    label="Service Category"
                                                    id="ServiceCategory"
                                                    name="ServiceCategory"
                                                    value={values.ServiceCategory}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.ServiceCategory}
                                                    touched={touched.ServiceCategory}
                                                />
                                                <TextArea
                                                    label="Service Description"
                                                    id="ServiceDescription"
                                                    name="ServiceDescription"
                                                    value={values.ServiceDescription}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.ServiceDescription}
                                                    touched={touched.ServiceDescription}
                                                />

                                            </div>
                                        </div>

                                        <div className="property-details-buttons">
                                            <button className="pdb-cancel" type="reset" onClick={closeAddQuote}>
                                                Cancel
                                            </button>
                                            <button className="pdb-save-changes" type="submit">
                                                Save
                                            </button>
                                        </div>
                                    </form>

                                </div>
                            }

                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JobPortalDetails;
