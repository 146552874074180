import axios from "axios";
import { useSpinnerContext } from "./SpinnerContext";
import {
  GetCookie,
  RemoveCookie,
} from "../components/CommonSection/Cookie/Session";

import {
  AccessKey,
  BucketName,
  Keyid,
  Region,
} from "../components/CommonSection/apiresources";
import { ActionStatus } from "./enum";

export const CheckSessionExpiration = (cookiename) => {
  const session = GetCookie(cookiename);
  if (!session) {
    // Session has expired, redirect to sign-in page
    RemoveCookie(cookiename);
    window.location.href = "/signin";
  }
};

// Check session expiration after each API call
const handleAPIResponse = (cookiename) => {
  CheckSessionExpiration(cookiename);
};

export async function makeRequest(path, method, data) {
  if (method === "get") {
    try {
      const response = await axios.get(path);

      if (response.status === 200) {
        handleAPIResponse("@UserData");
        return response.data;
      } else {
        throw {
          code: response.status,
          reason: response.statusText,
        };
      }
    } catch (error) {
      console.error(`Request to ${path} failed.`, error);
      throw error;
    }
  }

  try {
    const response = await axios({
      method: method,
      url: path,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });

    if (response.status === 200) {
      handleAPIResponse("@UserData");
      return response.data;
    } else {
      throw {
        code: response.status,
        reason: response.statusText,
      };
    }
  } catch (error) {
    console.error(`Request to ${path} failed.`, error);
    throw error;
  }
}
export async function makeRequestWithSpinner(
  showSpinner,
  hideSpinner,
  path,
  method,
  data,
  headers,
  isAuthenticationRequired = true
) {
  showSpinner();

  // Add UserId to data if authentication is required and the method is not "GET"
  if (method.toLowerCase() !== "get" && isAuthenticationRequired) {
    const userData = GetCookie("@UserData");
    if (userData) {
      try {
        const parsedData = JSON.parse(userData);
        if (parsedData?.userId) {
          data = {
            ...data,
            LoggedUserId: parsedData.userId,
          };
        }
      } catch (error) {
        console.error("Failed to parse @UserData cookie:", error);
        RemoveCookie("@UserData");
        window.location.href = "/signin";
      }
    } else {
      console.warn("@UserData cookie not found.");
      window.location.href = "/signin";
    }
  }

  // Handle GET requests
  if (method.toLowerCase() === "get") {
    try {
      const response = await axios.get(path, { headers: headers });
      if (response.status === 200) {
        hideSpinner();
        if (isAuthenticationRequired) {
          handleAPIResponse("@UserData");
        }
        return response.data;
      } else {
        throw {
          code: response.status,
          reason: response.statusText,
        };
      }
    } catch (error) {
      hideSpinner();
      console.error(`Request to ${path} failed.`, error);
      throw error;
    }
  }

  // Handle non-GET requests
  try {
    const response = await axios({
      method: method,
      url: path,
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      data: data,
    });

    if (response.status === 200) {
      hideSpinner();
      if (isAuthenticationRequired) {
        handleAPIResponse("@UserData");
      }
      return response.data;
    } else {
      throw {
        code: response.status,
        reason: response.statusText,
      };
    }
  } catch (error) {
    hideSpinner();
    console.error(`Request to ${path} failed.`, error);
    throw error;
  }
}

export async function DownloadFiletWithSpinner(
  showSpinner,
  hideSpinner,
  path,
  method,
  data,
  headers,
  isAuthenticationRequired = true
) {
  debugger;
  showSpinner();
  if (method === "get") {
    try {
      const response = await axios.get(path, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        hideSpinner();
        if (isAuthenticationRequired) {
          handleAPIResponse("@UserData");
        }
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        return pdfUrl;
      } else {
        throw {
          code: response.status,
          reason: response.statusText,
        };
      }
    } catch (error) {
      hideSpinner();
      console.error(`Request to ${path} failed.`, error);
      throw error;
    }
  }

  try {
    const response = await axios({
      method: method,
      url: path,
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      responseType: "blob",

      data: data,
    });

    if (response.status === 200) {
      hideSpinner();
      if (isAuthenticationRequired) {
        handleAPIResponse("@UserData");
      }
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      return pdfUrl;
    } else {
      throw {
        code: response.status,
        reason: response.statusText,
      };
    }
  } catch (error) {
    hideSpinner();
    console.error(`Request to ${path} failed.`, error);
    throw error;
  }
}
export async function uploadToS3(filepath, preName, selectedFile) {
  return new Promise((resolve, reject) => {
    // Check if a file is selected
    if (!selectedFile) {
      reject("Please select a file to upload.");
      return;
    }
    filepath +
      AWS.config.update({
        accessKeyId: AccessKey,
        secretAccessKey: Keyid,
        region: Region,
      });

    const s3 = new AWS.S3();
    const fileExtension = selectedFile.name.split(".").pop();
    let new_name = preName + Date.now() + "." + fileExtension;
    let key = filepath + new_name;
    let envkey = import.meta.env.VITE_AWS_BUCKET_PREFIX + filepath + new_name;
    const params = {
      Bucket: BucketName,
      Key: envkey,
      Body: selectedFile,
    };

    s3.upload(params, (err, data) => {
      if (err) {
        console.error("Error uploading file:", err);
        const returnObject = {
          ...data,
          status: ActionStatus.Failed,
          message: err,
        };
        reject(returnObject);
      } else {
        const returnObject = {
          Key: key,
          status: ActionStatus.Successfull,
          message: "File uploaded successfully",
        };
        resolve(returnObject);
      }
    });
  });
}
export async function uploadToS3WithSpinner(
  showSpinner,
  hideSpinner,
  filepath,
  preName,
  selectedFile
) {
  showSpinner();
  return new Promise((resolve, reject) => {
    // Check if a file is selected
    if (!selectedFile) {
      reject("Please select a file to upload.");
      return;
    }

    AWS.config.update({
      accessKeyId: AccessKey,
      secretAccessKey: Keyid,
      region: Region,
    });
    debugger;
    const s3 = new AWS.S3();
    const fileExtension = selectedFile.name.split(".").pop();
    const contentType = getContentType(fileExtension);
    let new_name = preName + Date.now() + "." + fileExtension;
    var key = filepath + new_name;
    let envkey = import.meta.env.VITE_AWS_BUCKET_PREFIX + filepath + new_name;
    const params = {
      Bucket: BucketName,
      Key: envkey,
      Body: selectedFile,
      ContentType: contentType,
      ContentDisposition: "inline",
    };

    s3.upload(params, (err, data) => {
      hideSpinner();
      if (err) {
        console.error("Error uploading file:", err);
        const returnObject = {
          ...data,
          status: ActionStatus.Failed,
          message: err,
        };
        reject(returnObject);
      } else {
        const returnObject = {
          Key: key,
          status: ActionStatus.Successfull,
          message: "File uploaded successfully",
        };
        resolve(returnObject);
      }
    });
  });
}

export async function uploadSignToS3WithSpinner(
  showSpinner,
  hideSpinner,
  filepath,
  preName,
  imgInByte
) {
  debugger;
  showSpinner();
  return new Promise((resolve, reject) => {
    // Check if a file is selected
    if (!imgInByte) {
      reject("Please select a file to upload.");
      return;
    }
    imgInByte = imgInByte.split(",")[1];
    AWS.config.update({
      accessKeyId: AccessKey,
      secretAccessKey: Keyid,
      region: Region,
    });

    const s3 = new AWS.S3();
    const fileExtension = "png";
    let new_name = preName + Date.now() + "." + fileExtension;
    var key = filepath + new_name;
    let envkey = import.meta.env.VITE_AWS_BUCKET_PREFIX + filepath + new_name;
    const params = {
      Bucket: BucketName,
      Key: envkey,
      Body: Buffer.from(imgInByte, "base64"),
      ContentType: "image/png", // Set content type for the uploaded file
      ACL: "public-read", // Set ACL to make the file publicly accessible
      ContentEncoding: "base64", // Set content encoding
    };

    s3.upload(params, (err, data) => {
      hideSpinner();
      if (err) {
        console.error("Error uploading file:", err);
        const returnObject = {
          ...data,
          status: ActionStatus.Failed,
          message: err,
        };
        reject(returnObject);
      } else {
        const returnObject = {
          Key: key,
          status: ActionStatus.Successfull,
          message: "File uploaded successfully",
        };
        resolve(returnObject);
      }
    });
  });
}
function getContentType(fileExtension) {
  const mimeTypes = {
    // PDF
    pdf: "application/pdf",

    // Images
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    bmp: "image/bmp",
    webp: "image/webp",
    svg: "image/svg+xml",

    // Text
    txt: "text/plain",
    csv: "text/csv",

    // Documents
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ppt: "application/vnd.ms-powerpoint",
    pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

    // Audio
    mp3: "audio/mpeg",
    wav: "audio/wav",

    // Video
    mp4: "video/mp4",
    avi: "video/x-msvideo",
    mov: "video/quicktime",

    // JSON
    json: "application/json",

    // Default fallback
    default: "application/octet-stream",
  };

  return mimeTypes[fileExtension.toLowerCase()] || mimeTypes.default;
}
