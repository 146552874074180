import React from "react";
import { useEffect, useState } from "react";
import "../PropertiesAgency/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../input-components/selectBoxNew";
import {
  ContactPreferenceUrl,
  searchAreaOfOperation,
} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { AutoComplete } from "primereact/autocomplete";
import ShowValue from "../../input-components/ShowValue";
import { FaEdit } from "react-icons/fa";
import { Space } from "antd";

const initialValues = {
  otherDetailsType: null,
  priceRange: null,
  regions: "",
  postcode: "",
};

const ContactPrefference = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  closeModal
}) => {
  const [selectedCountries, setSelectedCountries] = useState(null);
  const [filteredCountries, setFilteredCountries] = useState(null);
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agencyUID, setAgencyUID] = useState("");
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [edit, setEdit] = useState(true);

  const [budgetData, setBudgetData] = useState([
    { value: 1, label: "700000$ & below" },
    { value: 2, label: "1M below" },
    { value: 3, label: "1M to 1.2M" },
    { value: 4, label: "1.2$ M & above" },
  ]);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgencyUID(parsedData.agencyUniqueID);
        } else {
          navigate("/signin");
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      if (getDetails.postcode != null) {
        setSelectedCountries(getDetails.postcode.split(";"));
      }
      setEdit(false)
    }
   
  }, [getDetails]);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      debugger;
      if (selectedCountries) values.postcode = selectedCountries.join(";");
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = () => {
    debugger
    const commaSeparatedString =
      values.otherDetailsType && Array.isArray(values.otherDetailsType)
        ? values.otherDetailsType.map((obj) => obj.value).join(",")
        : values.otherDetailsType;
    const formData = {
      ContactUniqueId: getDetails.contactUniqueId,
      PriceRange: values.priceRange,
      Regions: values.regions,
      Postcode: values.postcode,
      OtherDetailsType: commaSeparatedString,
    };

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      ContactPreferenceUrl,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          handleCurrentPage(null);
          //handleparentsuccess(["success", returnData.message]);
          //navigate("/agency/ContactList");
        } else {
          //handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        //handleparentsuccess(["error", error.message]);
      });
  };

  const options = [
    { value: 1, label: "Investment" },
    { value: 2, label: "Development" },
    { value: 3, label: "First Home Buyer" },
    { value: 4, label: "Renting" },
    { value: 5, label: "Selling" },
    { value: 6, label: "Buying" },
  ];

  const handleSelectChange = (selectedValues) => {
    setFieldValue("otherDetailsType", selectedValues);
  };

  const search = (event) => {
    setTimeout(() => {
      const apiUrl = searchAreaOfOperation;
      if (event.query.toLowerCase()) {
        const formData = {
          Search: event.query.toLowerCase(),
        };
        makeRequestWithSpinner(
          showSpinner,
          hideSpinner,
          searchAreaOfOperation,
          "post",
          formData
        ).then((returnData) => {
          setFilteredCountries(returnData.list.map((obj) => obj.name));
        });
      }
    }, 250);
  };

  const handleEdit = () => {
    setEdit(!edit)
  }

  const handlecancel = () => {
    closeModal();
  };
  

  const commaSeparatedString =
  values.otherDetailsType && typeof values.otherDetailsType === "string"
    ? values.otherDetailsType
      .split(",")
      .map((value) => {
        const option = options.find((opt) => opt.value === parseInt(value));
        return option ? option.label : null;
      })
      .filter((label) => label !== null)
      .join(", ")
    : null;



  return (
    <div>
          <form onSubmit={handleSubmit}>
            <div className="p-3">
              <div className="row contacts-group">
                <SelectBox
                  label="Purpose"
                  name="otherDetailsType"
                  options={options}
                  placeholder={
                    values.otherDetailsType
                      ? values.otherDetailsType.label
                      : "Select purpose"
                  }
                  value={values.otherDetailsType}
                  onChange={handleSelectChange}
                  error={errors.otherDetailsType}
                  touched={touched.otherDetailsType}
                  isMulti={true}
                />
                <SelectBox
                  label="Budget"
                  defaultValue={""}
                  name="priceRange"
                  options={budgetData}
                  placeholder={"Select Budget"}
                  value={values.priceRange}
                  onChange={(val) => setFieldValue("priceRange", val.value)}
                  error={errors.priceRange}
                  touched={touched.priceRange}
                />
                <TextBox
                  label="State/Region"
                  placeholder="State/Region"
                  id="regions"
                  name="regions"
                  value={values.regions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.regions}
                  touched={touched.regions}
                />
                <div className="pd-companyaddress col-lg-6 col-sm-12">
                  <label htmlFor="lnumber" className="s1">
                    Postcode
                  </label>
                  <div className="">
                    <AutoComplete
                      placeholder="Postcode"
                      multiple
                      value={selectedCountries}
                      suggestions={filteredCountries}
                      completeMethod={search}
                      onChange={(e) => setSelectedCountries(e.value)}
                      className="w-100"
                      style={{padding:"0px 10px"}}
                    />
                  </div>
                  {errors.companyaddress && touched.companyaddress ? (
                    <p className="form-error">{errors.companyaddress}</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="property-details-buttons">
              <button
                className="pdb-cancel"
                type="reset"
                onClick={() => handlecancel()}
              >
                Cancel
              </button>
              <button className="pdb-save-changes" type="submit">
                Save
              </button>
            </div>
          </form>
    </div>
  );
};

export default ContactPrefference;
