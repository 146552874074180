import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { UserOutlined, SettingOutlined, FileOutlined } from "@ant-design/icons";
import "./Timeline.css";
import { getactivitylogformodule } from "../../components/CommonSection/apiresources";
import { useSpinnerContext } from "../../Utilities/SpinnerContext";
import { makeRequestWithSpinner } from "../../Utilities/client";
import { ActionStatus } from "../../Utilities/enum";
import moment from "moment";

const Timeline = ({ ModuleType, RecordId }) => {
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    getActivities();
  }, []);

  const getActivities = () => {
    const url = getactivitylogformodule + `?ModuleType=${ModuleType}&RecordId=${RecordId}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "get")
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          console.log(returnData, "returnData");
          setEvents(returnData.list);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const getIconAndType = (event) => {
    if (event.isSystemGenerated) {
      return { icon: <SettingOutlined className="icon system" />, type: "system" };
    }  else {
      return { icon: <UserOutlined className="icon user" />, type: "user" };
    }
  };

  return (
    <div className="timeline-container">
      <div className="timeline-line">
        {events.map((event, index) => {
          const { icon, type } = getIconAndType(event);
          return (
            <div key={index} className="timeline-item">
              <div className={`timeline-icon ${type}`}>{icon}</div>
              <div className="timeline-content">
                <div className="timeline-header">
                  <h3 className="timeline-user">{event.isSystemGenerated ? "System" : event.userName}</h3>
                  <span className="timeline-time">{moment(event.createdAt).fromNow()}</span>
                </div>
                <Card className="timeline-card timecard">
                  <p className="timeline-description">{event.description}</p>
                </Card>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Timeline;