import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { GetCookie } from "../../../../components/CommonSection/Cookie/Session";
import { dynamicUrl } from "../../../../Utilities/profileManagement";
import "./../maintainance.css"
import TextArea from "../../../input-components/textarea";
import VerticalProgressive from "../../../input-components/verticalProgressivebar"
import SubNav from "../../../../components/CommonSection/Header/subHeader";

const PropertyDetailsAgency = ({ }) => {
    const [selectedCity, setSelectedCity] = useState(null);
    const [userId, setUserId] = useState("");
    const [userUId, setUserUId] = useState("");
    const [agencyID, setAgencyId] = useState("");
    const [agencyUID, setAgencyUID] = useState("");
    const [displayAddNewProperty, setDisplayAddNewProperty] = useState(false)
    const navigate = useNavigate();
    const [topicValue, setTopicValue] = useState("Profile");
    const [activeTopic, setActiveTopic] = useState("ListAgreement");
    var url = new URL(window.location.href);

    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setUserUId(parsedData.userUnique_ID);
                    setUserId(parsedData.userId);
                    setAgencyId(parsedData.agencyID);
                    setAgencyUID(parsedData.agencyUniqueID);
                } else {
                    navigate("/signin");
                }
            } catch (error) {
                console.error("Error retrieving data from AsyncStorage:", error);
            }
        };
        retrieveData();
    }, [userId]);


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
    }, []);

    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        setFieldValue,
    } = useFormik({
        //initialValues: initialValues,
        //validationSchema: PropertyDetailsSchema,

        onSubmit: (values, action) => {
            values.address = values.address;
            values.propertyFor = values.propertyFor;
            values.propertyType = values.propertyType;
            values.assignedTo = parseInt(selectedCity, 10);
            displayAddNewProperty ? handleFinalSubmit() : handleFinalSubmit1();
        },
    });

    const handleTopicValue = (text) => {
        setTopicValue(text);
        dynamicUrl(navigate, text);
        setActiveTopic(text);
    };

    const steps = [
        { label: 'Step 1' },
        { label: 'Step 2' },
        { label: 'Step 3' }
    ];

    const customItemTemplate = (item, index) => {
        if (index === 2) {
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="../../../assets/images/upload.png" alt="upload-icon" style={{ width: '100%', height: '100%' }} />
                </div>
            );
        }
        return item.label;
    };

    const isFinished = true; // Change this based on your condition

    // Different descriptions for each step
    const descriptions = [
        'Report Repair, 12/11/2023',
        'Repair Approved, 14/11/2023 2Days',
        'Job Assigned, 14/11/2023 2Days',
        'Job Schedule, 14/11/2023 2Days',
        'Reschedule, 14/11/2023 2Days',
        'Work Completed, 14/11/2023 2Days',
    ];

    const items = [
        {
            title: 'Finished',
            description: descriptions[0],
        },
        {
            title: 'In Progress',
            description: descriptions[1],
        },
        {
            title: 'Finished',
            description: descriptions[2],
        },
        {
            title: 'Finished',
            description: descriptions[3],
        },
        {
            title: 'Waiting',
            description: descriptions[4],
        },
        {
            title: 'Waiting',
            description: descriptions[5],
        },
    ];

    const stepsData = [
        {
            img: '12/11/2023',
            title: 'Report Repair',
        },
        {
            img: '14/11/2023',
            title: 'Repair Approved',
        },
        {
            img: '14/11/2023',
            title: 'Job Assigned',
        },
        {
            img: '17/11/2023',
            title: 'Job Schedule',
        },
        {
            img: '19/11/2023',
            title: 'Reschedule',
        },
        {
            img: '19/11/2023',
            title: 'Work Completed',
        }
    ];

    // State to manage active step
    const [activeStep, setActiveStep] = useState(0); // Initially, the first step is active

    // Function to handle changing active step
    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        // <div className="dashboard-agency">
        //     <Header />
        //     <div className="continer-dbag">
        //         {/* <ToastAlert ref={ToastRef} /> */}
        //         <div
        //             className="blank_space"
        //             style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
        //         ></div>
        //         <div className="dashboardagency-content">
        //             <Sidebar
        //                 visible={sidebarVisible}
        //                 handleTopicValue={handleTopicValue}
        //                 topicValue={activeTopic}
        //             />
        //             <div
        //                 className="pa-content-top-level"
        //                 onClick={() => setVisiblet(true)}
        //             >
        //                 <div className="agent-list-data-table">
        //                     <div className="filter-container agent-container">
        //                         <div className="search-container">
        //                             <div className="text-parent">
        //                                 <div className="text7" style={{ fontSize: "24px" }}>
        //                                     Maintainance Request
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     {/* First section */}
        //                     <div className="section-left">
        //                         <div className="p-5">
        //                             <div className="mb-4" style={{ display: 'flex', alignItems: 'center' }}>
        //                                 <img src="../../../assets/images/upload.png" alt="upload-icon" className="upload-iconMaintainance mr-5" />
        //                                 <div>Sam Roche</div>
        //                             </div>
        //                             <div className="pl-2">
        //                                 <div className="col-lg-12 col-sm-12 labelMaintainance mb-3" style={{ paddingLeft: '0px' }} >{`Location`}</div>
        //                                 <div className="col-lg-12 col-sm-12 mb-3" style={{ paddingLeft: '0px' }} >{`17 Jackwood Crescent, Marsd..`}</div>
        //                             </div>
        //                             <div className="pl-2">
        //                                 <div className="col-lg-12 col-sm-12 labelMaintainance mb-3" style={{ paddingLeft: '0px' }} >{`Issue Topic`}</div>
        //                                 <div className="col-lg-12 col-sm-12 mb-3" style={{ paddingLeft: '0px' }} >{`Plumbing`}</div>
        //                             </div>
        //                             <div className="pl-2">
        //                                 <div className="col-lg-12 col-sm-12 labelMaintainance mb-3" style={{ paddingLeft: '0px' }} >{`Title`}</div>
        //                                 <div className="col-lg-12 col-sm-12 mb-3" style={{ paddingLeft: '0px' }} >{`Wash bashin issue`}</div>
        //                             </div>
        //                             <div>
        //                                 <div className="col-lg-12 col-sm-12 pl-2 labelMaintainance" style={{ paddingLeft: '0px' }} >{`Description`}</div>
        //                                 <TextArea colVal={8} name="description" onChange={handleChange} onBlur={handleBlur} />
        //                             </div>
        //                             <div>
        //                                 <div className="col-lg-12 col-sm-12 pl-2 labelMaintainance" style={{ paddingLeft: '0px' }} >{`Images`}</div>
        //                                 <div className="mb-4" style={{ display: 'flex', alignItems: 'center' }}>
        //                                     {/* Looping the image */}
        //                                     {[...Array(5)].map((_, index) => (
        //                                         <img
        //                                             key={index}
        //                                             src="../../../assets/images/upload.png"
        //                                             alt="upload-icon"
        //                                             style={{ width: '100px', height: '100px', padding: '10px' }}
        //                                         />
        //                                     ))}
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     {/* Second section */}
        //                     <div className="section-right">
        //                         <div className="p-5">
        //                             <div style={{ width: '100%', height: '100%', border: "1px solid gray", borderRadius: "4px" }}>
        //                                 <div className="p-3">
        //                                     <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>Track Job</div>
        //                                 </div>
        //                                 <div className="p-4" style={{ display: 'flex', justifyContent:'center'}}>
        //                                     <div style={{ display: 'flex', flexDirection: 'column' }}>
        //                                         {/* <Steps
        //                                              direction="vertical"
        //                                              current={isFinished ? 0 : 1} // Set the current step based on the condition
        //                                              items={items}
        //                                         /> */}
        //                                         <VerticalProgressive  items={stepsData} activeStep={activeStep} />
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>

        <div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
                <SubNav title={"Maintainance Request"} goBack={handleGoBack}  extraData={true} />
                <div className="material-work-desk-new bg-white m-3">
                <div className="content-section">
                         {/* First section */}
                         <div className="section-left">
                                <div className="p-5">
                                    <div className="mb-4" style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src="../../../assets/images/upload.png" alt="upload-icon" className="upload-iconMaintainance mr-5" />
                                        <div>Sam Roche</div>
                                    </div>
                                    <div className="pl-2">
                                        <div className="col-lg-12 col-sm-12 labelMaintainance mb-3" style={{ paddingLeft: '0px' }} >{`Location`}</div>
                                        <div className="col-lg-12 col-sm-12 mb-3" style={{ paddingLeft: '0px' }} >{`17 Jackwood Crescent, Marsd..`}</div>
                                    </div>
                                    <div className="pl-2">
                                        <div className="col-lg-12 col-sm-12 labelMaintainance mb-3" style={{ paddingLeft: '0px' }} >{`Issue Topic`}</div>
                                        <div className="col-lg-12 col-sm-12 mb-3" style={{ paddingLeft: '0px' }} >{`Plumbing`}</div>
                                    </div>
                                    <div className="pl-2">
                                        <div className="col-lg-12 col-sm-12 labelMaintainance mb-3" style={{ paddingLeft: '0px' }} >{`Title`}</div>
                                        <div className="col-lg-12 col-sm-12 mb-3" style={{ paddingLeft: '0px' }} >{`Wash bashin issue`}</div>
                                    </div>
                                    <div>
                                        <div className="col-lg-12 col-sm-12 pl-2 labelMaintainance" style={{ paddingLeft: '0px' }} >{`Description`}</div>
                                        <TextArea colVal={8} name="description" onChange={handleChange} onBlur={handleBlur} />
                                    </div>
                                    <div>
                                        <div className="col-lg-12 col-sm-12 pl-2 labelMaintainance" style={{ paddingLeft: '0px' }} >{`Images`}</div>
                                        <div className="mb-4" style={{ display: 'flex', alignItems: 'center' }}>
                                            {/* Looping the image */}
                                            {[...Array(5)].map((_, index) => (
                                                <img
                                                    key={index}
                                                    src="../../../assets/images/upload.png"
                                                    alt="upload-icon"
                                                    style={{ width: '100px', height: '100px', padding: '10px' }}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Second section */}
                            <div className="section-right">
                                <div className="p-5">
                                    <div style={{ width: '100%', height: '100%', border: "1px solid gray", borderRadius: "4px" }}>
                                        <div className="p-3">
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>Track Job</div>
                                        </div>
                                        <div className="p-4" style={{ display: 'flex', justifyContent:'center'}}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {/* <Steps
                                                     direction="vertical"
                                                     current={isFinished ? 0 : 1} // Set the current step based on the condition
                                                     items={items}
                                                /> */}
                                                <VerticalProgressive  items={stepsData} activeStep={activeStep} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default PropertyDetailsAgency;
