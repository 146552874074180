import React from 'react';

const RadioInput = ({ label, name, value, checked, onChange, onBlur, error, touched, options, isMandatory,colVal, row }) => {
  let colVals = colVal === 12 ? 'col-lg-12 col-sm-12' : colVal === 3 ? 'col-lg-3 col-sm-12' : 'col-lg-6 col-sm-12';
  return (
    <div className={`lda-${name} ${colVals}`}>
      <label className="s1">
        {label} {isMandatory && <span style={{ color: 'red' }}>*</span>}
      </label>
      <div className="radio-section ">
        {options.map((option) => (
          <label className={row ? '' : 'col-lg-3 col-sm-12'} key={option.value} htmlFor={`${name}-${option.value}`} style={{ marginRight: '50px' }}>
            <input
              type="radio"
              id={`${name}-${option.value}`}
              name={name}
              value={option.value}
              checked={value === String(option.value)}
              onChange={onChange}
              onBlur={onBlur}
            />
            &nbsp;&nbsp;{option.label}
          </label>
        ))}
      </div>
      {error && touched ? <p className="form-error">{error}</p> : null}
    </div>
  );
};

export default RadioInput;
