import React, { useState, useEffect, useRef } from "react";
import { Tabs, Checkbox, Button } from "antd";
import { BiDotsVerticalRounded } from "react-icons/bi";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useNavigate } from "react-router-dom";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import SideBar from "../../../components/CommonSection/SideBar/sideBar";
import { geteditinspectiontemplate, updateinspection, submitupdatetemplatefacilities, getaddupdatedacilitiestotemplate, submittemplatedetails, addpicturestoinspection, FilePath, submitupdatedtemplatedetails, getaddedittemplatedetails } from "../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner, uploadToS3WithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { PlusOutlined } from "@ant-design/icons";
import "./inspection.css";
import TextBox from "../../input-components/textbox";
import SelectInput from "../../input-components/selectBoxNew";
import TextArea from "../../input-components/textarea";
import CustomCheckbox from "../../input-components/customCheckbox";
import Header from "../../../components/CommonSection/Header/Header";
import { FaCamera } from "react-icons/fa";
import CustomModal from "../../input-components/Modal";
import { TiDeleteOutline } from "react-icons/ti";
import { Spin } from 'antd';
import { MdDeleteOutline } from "react-icons/md";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import SubNav from "../../../components/CommonSection/Header/subHeader";
import { currentProfile} from "../../../components/CommonSection/Cookie/Session";
import ImageGalleryModal from "../../input-components/imageGallary";
dayjs.extend(customParseFormat);

const { TabPane } = Tabs;

const EditReport = () => {
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [getDetails, setGetDetails] = useState({ templates: [] });
    const [shareWithOwner, setShareWithOwner] = useState(null);
    const [shareWithTenant, setShareWithTenant] = useState(null);
    const [activeTopic, setActiveTopic] = useState("ListInspection");
    const [openUpload, setOpenUpload] = useState(false);
    const [templateOptions, setTemplateOptions] = useState([]);
    const [openAddTemplate, setOpenAddTemplate] = useState(false);
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [editFacilityModalVisible, setEditFacilityModalVisible] = useState(false);
    const [comments, setComments] = useState("");
    const [formData, setFormData] = useState({
        RentReview: "",
        WaterMeterReading: "",
        GeneralNotes: "",
        FollowupActions: ""
    });
    const [toggleState, setToggleState] = useState(null);
    const [activeTab, setActiveTab] = useState("1");
    const [propertyimageitems, setPropertyimageitems] = useState([]);
    const [propertyImages, setPropertyImages] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const primaryDocumentRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [facilityList, setFacilityList] = useState([]);
    const [facilities, setFacilities] = useState([{ id: 1, name: "" }]);
    const [selectedFacilities, setSelectedFacilities] = useState([]);
    const [templateArr, setTemplateArr] = useState([]);
    const [templateVal,setTemplateVal] = useState("")
    const [disagreeIndexes, setDisagreeIndexes] = useState({});
    const [disagree, setDisagree] = useState(null);
    const ToastRef = useRef();
    const navigate = useNavigate();

    const url = new URL(window.location.href);
    const InspectionUniqueId = url.searchParams.get("id");
    const InspectionType = url.searchParams.get("inspectionType");
    const currentProfiles = currentProfile.get();

    useEffect(() => {
        viewinspectionmasterdata(InspectionUniqueId);
    }, [InspectionUniqueId]);

    const viewinspectionfacilitrydata = () => {
        const activeTemplate = getDetails.templates.find(template => `tab-${template.id}` === activeTab);
        const activeTemplateId = activeTemplate ? activeTemplate.id : null;
        const baseUrl = getaddupdatedacilitiestotemplate + '?TemplateId=' + activeTemplateId;
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "get")
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    setFacilityList(response.object.facilities);
                    setSelectedFacilities(response.object.selectedFacilities)
                } else {
                    ToastRef(response.message);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const viewinspectionmasterdata = (InspectionUniqueId) => {
        const data = { id: InspectionUniqueId };
        const baseUrl = geteditinspectiontemplate;
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    const data = response.object;
                    setGetDetails(data);
                    setFormData({
                        RentReview: data.inspectionDetails.rentReview,
                        WaterMeterReading: data.inspectionDetails.waterMeterReading,
                        GeneralNotes: data.inspectionDetails.generalNotes,
                        FollowupActions: data.inspectionDetails.followupActions
                    });
                    setShareWithOwner(data.inspectionDetails.shareWithOwner);
                    setShareWithTenant(data.inspectionDetails.shareWithTenant);
                  
                } else {
                    ToastRef.current.notify(["error", response.message]);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const viewinspectiondata = (InspectionUniqueId) => {
        const baseUrl = getaddedittemplatedetails + '?InspectionId=' + InspectionUniqueId;
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "get")
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    const data = response.object;
                    setGetDetails(data);
                    const transformedTemplates = data.templates.map((template) => {
                        return {
                            TemplateId: template.id,
                            Attribute: template.attribute,
                            AttributeLabel: template.label,
                        };
                    });
                    setTemplates(transformedTemplates);
                    const transformedAttributes = data.attributes.map(attribute => ({
                        value: attribute,
                        label: attribute
                    }));
                    setComments()
                    setTemplateOptions(transformedAttributes)
                } else {
                    ToastRef.current.notify(["error", response.message]);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleSubmitOverView = () => {
        const finalData = {
            Id: InspectionUniqueId,
            ShareWithOwner: shareWithOwner,
            ShareWithTenant: shareWithTenant,
            RentReview: formData.RentReview,
            WaterMeterReading: formData.WaterMeterReading,
            GeneralNotes: formData.GeneralNotes,
            FollowupActions: formData.FollowupActions
        };
        const url = updateinspection;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", finalData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    viewinspectionmasterdata(InspectionUniqueId);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const onChange = (e, label) => {
        if (label === "ShareWithOwner") {
            setShareWithOwner(e.target.checked);
        } else if (label === "ShareWithTenant") {
            setShareWithTenant(e.target.checked);
        }
    };

    const handleInput = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    const handleToggleAll = (e, fieldName) => {
        setToggleState(prevState => {
            // Determine the new toggle state based on the current state
            const newToggleState = prevState === null
                ? true
                : prevState === true
                    ? false
                    : prevState === false
                        ? null
                        : null;
            // Update the state of each facility based on the new toggle state
            setGetDetails(prevDetails => {
                const updatedTemplates = prevDetails.templates.map(template => ({
                    ...template,
                    templatesDetails: template.templatesDetails.map(facility => ({
                        ...facility,
                        [fieldName]: e
                    }))
                }));
                return { ...prevDetails, templates: updatedTemplates };
            });

            return newToggleState;
        });
    };

    const handleCheckboxChange = (e, fieldName, index, templateIndex) => {
        const newValue = e === true ? true : e === false ? false : null;
        setGetDetails(prevDetails => {
            const updatedTemplates = prevDetails.templates.map((template, tIndex) => {
                if (tIndex === templateIndex) {
                    const updatedFacilities = template.templatesDetails.map((item, i) => {
                        if (i === index) {
                            return { ...item, [fieldName]: newValue };
                        }
                        return item;
                    });
                    return { ...template, templatesDetails: updatedFacilities };
                }
                return template;
            });
            return { ...prevDetails, templates: updatedTemplates };
        });
    };

    const handleCommentChange = (e, index, templateIndex) => {
        const value = e.target.value;
        setGetDetails(prevDetails => {
            const updatedTemplates = prevDetails.templates.map((template, tIndex) => {
                if (tIndex === templateIndex) {
                    const updatedFacilities = template.templatesDetails.map((item, i) => {
                        if (i === index) {
                            // Conditionally update the agentComment or tenantComment
                            if (currentProfiles === 13) {
                                return { ...item, tenantComment: value }; // Update tenantComment if currentProfiles is 13
                            } else {
                                return { ...item, agentComment: value }; // Update agentComment otherwise
                            }
                        }
                        return item;
                    });
                    return { ...template, templatesDetails: updatedFacilities };
                }
                return template;
            });
            return { ...prevDetails, templates: updatedTemplates };
        });
    };

    const extractSelectedAttributeList = () => {
        // Find the currently active template based on activeTopic
        const activeTemplate = getDetails.templates.find(template => `tab-${template.id}` === activeTab);

        if (!activeTemplate) return [];

        // Extract details from the active template
        return activeTemplate.templatesDetails.map(facility => ({
            Id: facility.id,
            Cleaned: facility.cleaned,
            Undermanaged: facility.undermanaged,
            Working: facility.working,
            AgentComment: currentProfiles === 13 ? null : (facility.agentComment || ""),
            TenantComment: currentProfiles === 13 ? (facility.tenantComment || "") : null,
            IsTenantAgree: facility.isTenantAgree,
            CleanedByTenant: facility.cleanedByTenant,
            WorkingByTenant: facility.workingByTenant,
            UndermanagedByTenant: facility.undermanagedByTenant
        }));
    };


    const handleFinalSubmit = () => {
        const activeTemplate = getDetails.templates.find(template => `tab-${template.id}` === activeTab);
        const activeTemplateId = activeTemplate ? activeTemplate.id : null;

        const finalData = {
            InspectionId: InspectionUniqueId,
            TemplateId: activeTemplateId,
            SelectedAttributeList: extractSelectedAttributeList(),
            TemplateNotes: comments
        };
       
        if (InspectionType === "Routine") {
            delete finalData.SelectedAttributeList
        }
        const url = submittemplatedetails;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", finalData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    viewinspectionmasterdata(InspectionUniqueId);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const newToggleState1 = toggleState === null
        ? true
        : toggleState === true
            ? false
            : toggleState === false
                ? null
                : null;

    const closeUpload = () => {
        setOpenUpload(false)
    }

    const handleOpenUpload = () => {
        setOpenUpload(true)
    }

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy";
    };

    const handleInputChangeFile = (e, indexforfiles, documentType) => {
        const files = e.target.files;
        handleFileChange(files, indexforfiles, documentType);
    };

    const handleFileChange = async (files, indexforfiles, documentType) => {
        for (let i = 0; i < files.length; i++) {
            const selectedFile = files[i];
            await handleUpload(selectedFile, indexforfiles, documentType);
        }
    };

    const handleUpload = async (selectedFile) => {
        await uploadToS3WithSpinner(
            showSpinner,
            hideSpinner,
            "Inspection/",
            `Inspection`,
            selectedFile
        ).then((returnData) => {
            if (returnData.status === ActionStatus.Successfull) {
                const transformedItem = {
                    id: Math.floor(Math.random() * 1000000),
                    src: FilePath + returnData.Key,
                    width: 1,
                    height: 1,
                };
                setPropertyimageitems((prevItems) => [...prevItems, transformedItem]);
                setPropertyImages((prevDocuments) => [
                    ...prevDocuments,
                    returnData.Key,
                ]);
                ToastRef.current.notify(["success", "File uploaded successfully", true]);
            } else {
                ToastRef.current.notify([
                    "error",
                    "Error uploading file. Please try again.",
                ]);
            }
        });
    };


    const handleFileUploader = () => {
        primaryDocumentRef.current.click();
    };

    const handleDeleteImage = (id, src) => {
        setPropertyimageitems((prevItems) =>
            prevItems.filter((item) => item.id !== id)
        );
        setPropertyImages((prevImages) =>
            prevImages.filter((image) => image !== src)
        );
    };

    const handleFinalImages = () => {
        const activeTemplate = getDetails.templates.find(template => `tab-${template.id}` === activeTab);
        const activeTemplateId = activeTemplate ? activeTemplate.id : null;

        if(InspectionType !== "Routine" && templateVal === ''){
            ToastRef.current.notify(["error", "Select Template"]);
        }else{

        const finalData = {
            InspectionId: InspectionUniqueId,
            TemplateId: activeTemplateId,
            PicturePath: propertyImages,
            TemplateDetailsId: InspectionType !== "Routine" ? templateVal : null,
            IsTenantUploaded: currentProfiles === 13 ? true : false
        };
        const url = addpicturestoinspection;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", finalData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    viewinspectionmasterdata(InspectionUniqueId);
                    setOpenUpload(false)
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
        }
    };

    const openAddTemplateModal = () => {
        setOpenAddTemplate(true);
        viewinspectiondata(InspectionUniqueId);
    }

    const closeAddTemplate = () => {
        setOpenAddTemplate(false)
    }

    const handleFieldChange = (index, field, value) => {
        const updatedTemplates = [...templates];
        updatedTemplates[index][field] = value;
        setTemplates(updatedTemplates);
    };

    // Function to add a new empty template row
    const handleAddTemplate = () => {
        setTemplates([
            ...templates,
            { TemplateId: 0, Attribute: "", AttributeLabel: "" },
        ]);
    };

    const [templates, setTemplates] = useState([
        { TemplateId: 0, Attribute: "", AttributeLabel: "" },
    ]);

    const handleRemoveTemplate = (index) => {
        const updatedTemplates = templates.filter((_, i) => i !== index);
        setTemplates(updatedTemplates);
    };

    const handleAddTemplates = () => {
        const finalData = {
            InspectionId: InspectionUniqueId,
            Templates: templates,
        };
        const url = submitupdatedtemplatedetails;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", finalData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    viewinspectionmasterdata(InspectionUniqueId);
                    closeAddTemplate(false)
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const closeEditFacilityModal = () => {
        setEditFacilityModalVisible(false);
        setSelectedAttribute(null);
    };

    const handleEditFacilities = (attribute) => {
        setEditFacilityModalVisible(true);
        viewinspectionfacilitrydata();

    };

    const handleFacilityCheckboxChange = (facilityId) => {
        setSelectedFacilities(prevState =>
            prevState.includes(facilityId)
                ? prevState.filter(id => id !== facilityId)
                : [...prevState, facilityId]
        );
    };

    const handleSubmitEditFacilities = () => {
        const activeTemplate = getDetails.templates.find(template => `tab-${template.id}` === activeTab);
        const activeTemplateId = activeTemplate ? activeTemplate.id : null;
        const finalData = {
            TemplateId: activeTemplateId,
            FacilitiesId: selectedFacilities
        };
        const url = submitupdatetemplatefacilities;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", finalData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    closeEditFacilityModal();
                    viewinspectionmasterdata(InspectionUniqueId);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const handleMainTabChange = (key) => {
        const activeTemplate = getDetails.templates.find(template => `tab-${template.id}` === key);
        setActiveTab(key)
        if (activeTemplate) {
            setComments(activeTemplate.routineNotes || "");
            const templateArr = InspectionType !== "Routine" ? activeTemplate.templatesDetails.map(data => ({
                value: data.id,
                label: data.facilityName
            })) : null;
            setTemplateArr(templateArr)
        }
    }

    const handleComment = (e) => {
        setComments(e.target.value);
    };

    const handleGoBack = () => {
        dynamicUrl(navigate,"ViewInspection" + `?InspectionUniqueId=${getDetails.inspectionDetails.inspectionUniqueId}&inspectionType=${InspectionType}`);
    }

    const handleDisagree = (value, templateIndex, facilityIndex) => {
        setDisagreeIndexes((prev) => {
            if (value === true) {
                // Remove the facilityIndex from disagreeIndexes for the given templateIndex
                const updatedIndexes = { ...prev };
                if (updatedIndexes[templateIndex]) {
                    updatedIndexes[templateIndex] = updatedIndexes[templateIndex].filter(
                        (index) => index !== facilityIndex
                    );
                    if (updatedIndexes[templateIndex].length === 0) {
                        delete updatedIndexes[templateIndex]; // Remove the templateIndex if no values exist
                    }
                }
                return updatedIndexes;
            } else {
                // Add the facilityIndex to disagreeIndexes for the given templateIndex
                return {
                    ...prev,
                    [templateIndex]: [...(prev[templateIndex] || []), facilityIndex],
                };
            }
        });
    
        setGetDetails((prevDetails) => {
            const updatedTemplates = prevDetails.templates.map((template, tIndex) => {
                if (tIndex === templateIndex) {
                    const updatedFacilities = template.templatesDetails.map((item, i) => {
                        if (i === facilityIndex) {
                            return { ...item, isTenantAgree: value }; // Update isTenantAgree
                        }
                        return item;
                    });
                    return { ...template, templatesDetails: updatedFacilities };
                }
                return template;
            });
            return { ...prevDetails, templates: updatedTemplates };
        });
    };
        
    return (
        <>
            <div className="dashboard-agency">
            <ToastAlert ref={ToastRef}/>
                <div className="container-dbag">
                    <div className="dashboardagency-content">
                        <div className="tab-content">
                        <SubNav
                            title={"Reports"}
                            actionButton={false}
                            extraData={true}
                            goBack={handleGoBack} 

                        />
                            <Tabs
                                tabPosition="left"
                                className="tabs-custom"
                                activeKey={activeTab}
                                onChange={(key) => handleMainTabChange(key)}
                            >
                                <TabPane style={{ background: 'whitesmoke', padding: '10px' }} tab="Overview" key="1">
                                    <div className="overview-content">
                                        <div className="right-section-header">
                                            <div>Overview</div>
                                        </div>
                                        <div>
                                            <Checkbox checked={shareWithOwner} onChange={(e) => onChange(e, "ShareWithOwner")}>Share with Owner</Checkbox>
                                            <Checkbox checked={shareWithTenant} onChange={(e) => onChange(e, "ShareWithTenant")}>Share with Tenant</Checkbox>
                                        </div>
                                        <TextBox  value={formData.RentReview} name="RentReview" label="Rent Review" onChange={handleInput} />
                                        <TextBox  value={formData.WaterMeterReading} name="WaterMeterReading" label="Water Meter Reading" onChange={handleInput} />
                                        <TextArea value={formData.GeneralNotes} name="GeneralNotes" label="General Notes" onChange={handleInput} />
                                        <TextArea value={formData.FollowupActions} name="FollowupActions" label="Followup Actions" onChange={handleInput} />
                                        <div style={{ display: 'flex', justifyContent: 'end' }}><button onClick={handleSubmitOverView} className="pdb-save-changes mt-2">Submit</button></div>
                                    </div>
                                </TabPane>
                                {getDetails.templates.map((item, index) => (
                                    <TabPane style={{ background: 'whitesmoke', padding: '10px' }} tab={item.label} key={`tab-${item.id}`}>
                                        <div className="property-details-section">
                                            <div className="facility-header">
                                                <div className="mb-3 right-section-header">
                                                    <div>{item.label}</div>
                                                </div>
                                                <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                                    <TextArea
                                                        label="Overview Notes"
                                                        placeholder="Add Notes"
                                                        value={comments}
                                                        onChange={handleComment} // Update comment state
                                                        className="facility-comment"
                                                    />
                                                    <div className="mr-2 p-2 cameraBack " onClick={handleOpenUpload}>
                                                        <FaCamera style={{ fontSize: '25px' }} />
                                                    </div>
                                                </div>
                                            </div>
                                            {InspectionType === 'Entry' && item.templatesDetails.map((facility, i) => (
                                                <div key={`facility-${i}`} className="facility-item pl-3">
                                                    <label style={{ fontSize: '18px', fontWeight: '500' }}>{facility.facilityName}</label>
                                                    <div style={{ display: 'flex',padding:"5px" }}>
                                                        <div style={{ display: 'flex' }} className="facility-status">
                                                            <div>
                                                                <div className={`mr-3 mb-3 ${i === 0 ? "CleanedTitle" : ""}`} onClick={(e) => i === 0 && currentProfiles !== 13 && handleToggleAll(newToggleState1, 'cleaned')}>Cleaned</div>
                                                                <div style={{ marginRight: "16px" }}>
                                                                    <CustomCheckbox
                                                                        labelName="Cleaned"
                                                                        defaultChecked={facility.cleaned}
                                                                        onChange={(e) => handleCheckboxChange(e, 'cleaned', i, index)}
                                                                        readOnly={currentProfiles === 13 ? true : false}
                                                                    />
                                                                </div>
                                                            </div>
                                                          
                                                            <div>
                                                                    <div className={`mr-3 mb-3 ${i === 0 ? "WorkingTitle" : ""}`} onClick={() => i === 0 && handleToggleAll(newToggleState1, 'working')}>Working</div>
                                                                <div style={{ marginRight: "16px" }}>
                                                                    <CustomCheckbox
                                                                        labelName="Working"
                                                                        defaultChecked={facility.working}
                                                                        onChange={(e) => handleCheckboxChange(e, 'working', i, index)}
                                                                        readOnly={currentProfiles === 13 ? true : false}

                                                                    />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className={`mr-3 mb-3 ${i === 0 ? "UnmanagedTitle" : ""}`} onClick={() => i === 0 && handleToggleAll(newToggleState1, 'undermanaged')}>Unmanaged</div>
                                                                <div style={{ marginRight: "16px" }}>
                                                                    <CustomCheckbox
                                                                        labelName="Unmanaged"
                                                                        defaultChecked={facility.undermanaged}
                                                                        onChange={(e) => handleCheckboxChange(e, 'undermanaged', i, index)}
                                                                        readOnly={currentProfiles === 13 ? true : false}

                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <TextArea
                                                            placeholder={"Agent Comment"}
                                                            value={facility.agentComment}
                                                            onChange={(e) => handleCommentChange(e, i, index)}
                                                            className="facility-comment"
                                                        />

                                                           
                                                       {currentProfiles === 13 ?
                                                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                            <Button
                                                                className={facility.isTenantAgree === true? "button-agree": "button-default"}
                                                                onClick={() => handleDisagree(true, index, i)}
                                                            >
                                                                Agree
                                                            </Button>
                                                            <Button
                                                                className={facility.isTenantAgree === false? "button-disagree": "button-default"}
                                                                onClick={() => handleDisagree(false, index, i)}
                                                            >
                                                                Disagree
                                                            </Button>
                                                        </div> :  null }

                                                    </div>
                                                    {item.pictures.length > 0 && InspectionType === "Routine" ?<ImageGalleryModal images={item.pictures.map((pic) => pic.fileChecksum)} filePath={true} /> : null }
                                                    {facility.templateDetailsPictures.length > 0 && InspectionType !== "Routine" ?<ImageGalleryModal images={facility.templateDetailsPictures.map((pic) => pic.fileChecksum)} filePath={true} /> : null }
                                                    {disagreeIndexes[index]?.includes(i) && (
                                                        <div style={{ display: 'flex', background:'whitesmoke'  }}>
                                                        <div style={{ display: 'flex',padding:"5px" }} className="facility-status">
                                                            <div>
                                                                <div className={`mr-3 mb-3`} onClick={(e) => i === 0 && handleToggleAll(newToggleState1, 'cleaned')}>Cleaned</div>
                                                                <div style={{ marginRight: "16px" }}>
                                                                    <CustomCheckbox
                                                                        labelName="Cleaned"
                                                                        defaultChecked={facility.cleanedByTenant}
                                                                        onChange={(e) => handleCheckboxChange(e, 'cleanedByTenant', i, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                          
                                                            <div>
                                                                    <div className={`mr-3 mb-3`} onClick={() => i === 0 && handleToggleAll(newToggleState1, 'working')}>Working</div>
                                                                <div style={{ marginRight: "16px" }}>
                                                                    <CustomCheckbox
                                                                        labelName="Working"
                                                                        defaultChecked={facility.workingByTenant}
                                                                        onChange={(e) => handleCheckboxChange(e, 'workingByTenant', i, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className={`mr-3 mb-3`} onClick={() => i === 0 && handleToggleAll(newToggleState1, 'undermanaged')}>Unmanaged</div>
                                                                <div style={{ marginRight: "16px" }}>
                                                                    <CustomCheckbox
                                                                        labelName="Unmanaged"
                                                                        defaultChecked={facility.undermanagedByTenant}
                                                                        onChange={(e) => handleCheckboxChange(e, 'undermanagedByTenant', i, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <TextArea
                                                            placeholder={"Tenant Comment"}
                                                            value={currentProfiles === 13 ? facility.tenantComment:facility.agentComment}
                                                            onChange={(e) => handleCommentChange(e, i, index)}
                                                            className="facility-comment"
                                                        />
                                                       
                                                    </div>    )}
                                                   
                                                </div>
                                            ))}
                                            
                                               
                                            {InspectionType === 'Entry' ?
                                                <Button className="ml-3 mt-3" onClick={() => handleEditFacilities()}>
                                                    Add Facility
                                                </Button> : null}
                                            <div>
                                                {/* Display uploaded images */}
                                                <div className="uploaded-images-container">
                                                    {item.pictures.map((item) => (
                                                        <div key={item.id} className="uploaded-image-wrapper">
                                                            <img
                                                                src={FilePath + item.fileName}
                                                                alt={`Property Image ${item.id}`}
                                                                className="uploaded-image"
                                                            />
                                                            <TiDeleteOutline
                                                                className="delete-icon1"
                                                                onClick={() => handleDeleteImage(item.id, item.src)}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <CustomModal
                                                title="Upload Image"
                                                width={800}
                                                closable={closeUpload}
                                                visible={openUpload}
                                                onCancel={closeUpload}
                                                modalData={
                                                    <div style={{ marginLeft: "10px" }}>
                                                   {InspectionType !== "Routine" ? <SelectInput label="Template" name={"templateVal"} options={templateArr} value={templateVal} onChange={(e) => setTemplateVal(e.value)} placeholder="Select Template" colVal={4} isMandatory={true}/>: null }
                                                        <div className={`contacts-group ${propertyimageitems.length > 2 ? "col-md-12" : "col-md-12"}`}>
                                                            <div
                                                                className={`upload-section1 ${isDragging ? "dragging" : ""
                                                                    }`}
                                                                onDragEnter={handleDragEnter}
                                                                onDragLeave={handleDragLeave}
                                                                onDragOver={handleDragOver}
                                                            >
                                                                <Spin spinning={loading}>
                                                                    <div className="upload-setion-input">
                                                                        <div>
                                                                            <img
                                                                                src="../../../assets/images/upload.png"
                                                                                alt="upload-icon"
                                                                                className="upload-icon"
                                                                            />
                                                                            <span
                                                                                className="upload-select"
                                                                                onClick={handleFileUploader}
                                                                            >
                                                                                Click to upload
                                                                            </span>
                                                                            <input
                                                                                ref={primaryDocumentRef}
                                                                                type="file"
                                                                                onChange={(e) => handleInputChangeFile(e, 0)}
                                                                                className="upload-input"
                                                                                name="upload"
                                                                                multiple
                                                                            />
                                                                            or drag or drop
                                                                        </div>
                                                                    </div>
                                                                </Spin>
                                                                <div>
                                                                    {/* Display uploaded images */}
                                                                    <div className="uploaded-images-container">
                                                                        {propertyimageitems.map((item) => (
                                                                            <div key={item.id} className="uploaded-image-wrapper">
                                                                                <img
                                                                                    src={item.src}
                                                                                    alt={`Property Image ${item.id}`}
                                                                                    className="uploaded-image"
                                                                                />
                                                                                <TiDeleteOutline
                                                                                    className="delete-icon1"
                                                                                    onClick={() => handleDeleteImage(item.id, item.src)}
                                                                                />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                }
                                                footer={[
                                                    <div key="footer" style={{ display: "flex",justifyContent: "flex-end"}}>
                                                        <button style={{ marginRight: "20px" }} className="pdb-save-changes mt-2" onClick={handleFinalImages}>
                                                            Submit
                                                        </button>
                                                    </div>,
                                                ]}
                                            />
                                            <div className="property-details-buttons">
                                                <Button className="pdb-save-changes" type="button" onClick={handleFinalSubmit}>
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </TabPane>
                                ))}
                                <TabPane
                                    tab={
                                        <Button type="primary" icon={<PlusOutlined />} block onClick={openAddTemplateModal}>
                                            Add Template
                                        </Button>
                                    }
                                    key={getDetails?.templates?.length + 2}
                                ></TabPane>
                            </Tabs>
                        </div>
                        <CustomModal
                            title="Add/Update Template"
                            width={800}
                            closable={closeAddTemplate}
                            visible={openAddTemplate}
                            onCancel={closeAddTemplate}
                            modalData={
                                <div style={{ marginLeft: "10px" }}>
                                    {templates.map((template, index) => (
                                        <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                            {/* SelectInput for Attribute */}
                                            {index === templates.length - 1 && (
                                                <SelectInput
                                                    label={`Attribute ${index + 1}`}
                                                    name={`attribute-${index}`}
                                                    options={templateOptions}
                                                    value={template.Attribute}
                                                    onChange={(selectedOption) =>
                                                        handleFieldChange(index, "Attribute", selectedOption.value)
                                                    }
                                                    placeholder="Select Attribute"
                                                    colVal={4}
                                                    isMandatory={true}
                                                />
                                            )}
                                            {/* TextBox for Attribute Label */}
                                            <TextBox
                                                label={`Label`}
                                                name={`attributeLabel-${index}`}
                                                placeholder="Enter Attribute Label"
                                                value={template.AttributeLabel}
                                                onChange={(e) => handleFieldChange(index, "AttributeLabel", e.target.value)}
                                            />
                                            {/* Remove button */}
                                            {templates.length > 1 && (
                                                <MdDeleteOutline className="mt-3" style={{ fontSize: '25px' }} onClick={() => handleRemoveTemplate(index)} />
                                            )}
                                        </div>
                                    ))}

                                    {/* Button to add new template */}
                                    <div style={{ display: 'flex', justifyContent: 'end', marginRight: '20px' }} onClick={handleAddTemplate}>
                                        <span className="addTemplate">Add Template</span>
                                    </div>
                                </div>
                            }
                            footer={[
                                <div key="footer" style={{ display: "flex",justifyContent: "flex-end"}}>
                                    <button style={{ marginRight: "20px" }} className="pdb-save-changes mt-2" onClick={handleAddTemplates}>
                                        Submit
                                    </button>
                                </div>,
                            ]}
                        />

                        <CustomModal
                            title="Edit Facilities"
                            width={600}
                            closable={closeEditFacilityModal}
                            visible={editFacilityModalVisible}
                            onCancel={closeEditFacilityModal}
                            modalData={
                                <div className="row" style={{ marginTop: "30px", marginLeft: "10px" }}>
                                    {facilityList.map((facility, index) => (
                                        <div key={facility.id}>
                                            <Checkbox
                                                checked={selectedFacilities.includes(facility.id)}
                                                onChange={() => handleFacilityCheckboxChange(facility.id)}
                                            >
                                                {facility.facility}
                                            </Checkbox>
                                        </div>
                                    ))}
                                </div>
                            }
                            footer={[
                                <div key="footer" style={{display: "flex",justifyContent: "flex-end"}}>
                                    <button style={{ marginRight: "20px" }} className="pdb-save-changes mt-2" onClick={handleSubmitEditFacilities}>
                                        Submit
                                    </button>
                                </div>,
                            ]}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditReport;
