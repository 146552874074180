import React from 'react'
import { useEffect, useState, useRef } from 'react'
import axios from 'axios';
import { useFormik } from "formik";
import { addpersonalAgentDetails, searchAreaOfOperation } from "../../CommonSection/apiresources";
import { BiEnvelope, BiPhone } from 'react-icons/bi';
import { GetCookie } from '../../CommonSection/Cookie/Session';
import { AutoComplete } from "primereact/autocomplete";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import ProfileImage from "../../CommonSection/ProfileImage/ProfileImage";
import TextBox from "../../../src/input-components/textbox";
import { makeRequestWithSpinner, uploadToS3WithSpinner, } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { AgencyPersonalDetails } from "../../CommonSection/Schema/schema";
import "./AgentList.css";
import RadioInput from "../../../src/input-components/radioBtn"
import { useNavigate } from 'react-router-dom';
import SelectInput from '../../../src/input-components/selectBoxNew';
import ShowValue from '../../../src/input-components/ShowValue';
import { FiEdit } from "react-icons/fi";


const initialValues = {
    fname1: "",
    lname1: "",
    phone: "",
    email: "",
    reaportalname: "",
    pdgst: "",
    dpn1: "",
    dpn2: "",
    profilePath: "",
    workArea: "",
    ProfilePic: null,
    workingAddress: "",
    agentType: ''
}

const PersonalDetailsAgent = ({ onDataRecive, handleparentsuccess, handleCurrentPage, getDetails }) => {
    let toastdata = [];
    const [selectedCountries, setSelectedCountries] = useState(null);
    const [filteredCountries, setFilteredCountries] = useState(null);
    let selectedFile;
    const [userId, setUserId] = useState('');
    const [userUId, setUserUId] = useState('');
    const [agencyID, setAgencyId] = useState('');
    var url = new URL(window.location.href);
    var agentId = url.searchParams.get("value");
    let gdata;
    var new_data = [];
    const inputRef = useRef();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const navigate = useNavigate();
    const personalDetails = getDetails ? getDetails : null
    const [view, setView] = useState(true)
    
    var url = new URL(window.location.href);
    var edit = url.searchParams.get("edit");

    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie('@UserData');
                if (userData) {
                    const parsedData = JSON.parse(userData)
                    setUserUId(parsedData.userUnique_ID);
                    setUserId(parsedData.userId);
                    setAgencyId(parsedData.agencyID);
                }
            } catch (error) {
                console.error('Error retrieving data from AsyncStorage:', error);
            }
        };
        retrieveData();
    }, [userId]);

    useEffect(() => {
        if (getDetails) {

            console.log(getDetails.areaOfWork)
            if (getDetails.areaOfWork != null) {
                setSelectedCountries(getDetails.areaOfWork.split(";"));
            }
            // const trimmedAddressArray = addressArray.map(item => item.trim());
            // setSelectedCountries(trimmedAddressArray);
            setFieldValue('ProfilePic', getDetails.profilePic);
            setFieldValue('fname1', getDetails.firstName);
            setFieldValue('lname1', getDetails.lastName);
            setFieldValue('phone', getDetails.contactNumber);
            setFieldValue('email', getDetails.email);
            // setFieldValue('workArea', trimmedAddressArray);
            setFieldValue('reaportalname', getDetails.reaAgentName);
            setFieldValue('dpn1', getDetails.domainAgentFName);
            setFieldValue('dpn2', getDetails.domainAgentLName);
            setFieldValue('agentType', getDetails.agentType === 1 ? 'Employee' : 'Contractor');
        }
    }, [getDetails]);




    const { errors, values, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: AgencyPersonalDetails,
        onSubmit: (values, action) => {
            if (selectedCountries)
                // values.workArea = selectedCountries.map(obj => obj.name).join(';');
                values.workArea = selectedCountries.join(";");
            handleFinalSubmit();
        }
    });
    const search = (event) => {
        setTimeout(() => {
            const apiUrl = searchAreaOfOperation;
            if (event.query.toLowerCase()) {
                const formData = {
                    Search: event.query.toLowerCase(),
                };
                makeRequestWithSpinner(
                    showSpinner,
                    hideSpinner,
                    searchAreaOfOperation,
                    "post",
                    formData
                ).then((returnData) => {
                    setFilteredCountries(returnData.list.map((obj) => obj.name));
                });
            }
        }, 250);
    };

    // const search = (event) => {
    //     setTimeout(() => {
    //         const apiUrl = searchAreaOfOperation;
    //         if (event.query.toLowerCase()) {
    //             const getData = {
    //                 Search: event.query.toLowerCase(),
    //             };
    //             axios.post(apiUrl, getData).then(response => {
    //                 new_data = response.data.list;
    //                 setFilteredCountries(returnData.list.map((obj) => obj.name));

    //                 setFilteredCountries(response.data.list.map((obj) => obj.name));
    //                 // setFilteredCountries(response.data.list);
    //             });
    //         }

    //     }, 250);
    // }

    const select = (event) => {
        inputRef.current.click();
    }

    const handleFileChange = async (e, indexforfiles) => {
        for (let i = 0; i < e.target.files.length; i++) {
            selectedFile = e.target.files[i];
            await handleUpload(indexforfiles);
        }
    };

    const handleUpload = async (indexforfiles) => {
        await uploadToS3WithSpinner(
            showSpinner,
            hideSpinner,
            "profile/",
            "profilepic",
            selectedFile
        ).then((returnData) => {
            if (returnData.status == ActionStatus.Successfull) {
                setFieldValue("ProfilePic", returnData.Key);
                handleparentsuccess(["success", "File uploaded successfully"]);
            } else {
                handleparentsuccess([
                    "error",
                    "Error uploading file. Please try again.",
                ]);
            }
        });
    };

    const handleFinalSubmit = () => {
        debugger
        const objectData = {
            AgencyId: agencyID,
            AgentId: agentId ? agentId : 0,
            ProfilePic: values.ProfilePic,
            FirstName: values.fname1,
            LastName: values.lname1,
            Phone: values.phone,
            Email: values.email,
            AreaOfWork: values.workArea,
            ReaAgentName: values.reaportalname,
            DomainAgentFName: values.dpn1,
            DomainAgentLName: values.dpn2,
            AgentType: values.agentType === 'Employee' ? 1 : 2,
        };
        console.log(objectData)

        makeRequestWithSpinner(showSpinner, hideSpinner, addpersonalAgentDetails, "post", objectData).then((response) => {
            debugger
            if (response.status == ActionStatus.Successfull) {
                onDataRecive(response.id);
                var currentURL = window.location.href;
                var newValue = response.id;
                var updatedURL;
                if (currentURL.includes("?")) {
                    updatedURL = currentURL + "&value=" + newValue;
                } else {
                    updatedURL = currentURL + "?value=" + newValue;
                }
                history.pushState(null, null, updatedURL);
                handleparentsuccess(['success', response.message]);
                handleCurrentPage("LicensingDetailsAgent");
            } else {
                handleparentsuccess(["error", response.message]);
            }
        })
            .catch((error) => {
                handleparentsuccess(['error', error]);
                console.error(error);
            });
    };

    const agentTypeOptions = [
        { label: 'Employee', value: 'Employee' },
        { label: 'Contractor', value: 'Contractor' },
    ];

    const handleView = () => {
        setView(!view)
    }

    return (
        <div style={{ background: '#fff' }}>
            <div className='pl-4 pr-4 pt-4 mainLabel'>
                <div>Personal Details </div>
                {edit && view ?  <div className='edit' onClick={handleView}><FiEdit /> Edit </div>: null }
            </div>
            {edit && view ? <div className="all-input pl-4 pr-4 pb-2">
                <div className="details-form w-100">
                    <div className="agent-detial-dp">
                        <ProfileImage borderRadius="50%" handleFileChange={handleFileChange} inputRef={inputRef} imageSrc={values.ProfilePic} onClickHandler={select} isCircle={false} isPersonalImage={true} />
                    </div>
                    <div className="contacts-group d-flex">
                        <div className='col-lg-4'>
                            <ShowValue displayrow={false} Label='Working Address' setData={getDetails?.firstName} />
                        </div>
                        <div className='col-lg-4'>
                            <ShowValue displayrow={false} Label='Rea Portal Name' setData={getDetails?.reaAgentName} />
                        </div>
                        <div className='col-lg-4'>
                            <ShowValue displayrow={false} Label='Domain Portal Name' setData={`${getDetails?.domainAgentFName} ${getDetails?.domainAgentLName}`} />
                        </div>
                        <div className='col-lg-4'>
                            <ShowValue displayrow={false} Label='Agent Type' setData={getDetails?.agentType === 1 ? 'Employee' : 'Contractor'} />
                        </div>

                    </div>
                </div>
            </div> :
                <form onSubmit={handleSubmit} className="details-form" style={{ display: "initial", width: "100%" }}>
                    <div className="p-4 contacts-group row">
                        <TextBox isMandatory label="First name" placeholder="First name" name="fname1" value={values.fname1} onChange={handleChange} onBlur={handleBlur} error={errors.fname1} touched={touched.fname1} />
                        <TextBox isMandatory label="Last name" placeholder="Last name" name="lname1" value={values.lname1} onChange={handleChange} onBlur={handleBlur} error={errors.lname1} touched={touched.lname1} />
                        <TextBox isMandatory label="Phone" placeholder="Phone" name="phone" value={values.phone} onChange={handleChange} onBlur={handleBlur} error={errors.phone} touched={touched.phone} icon={<BiPhone height="100%" width="100%" />} />
                        <TextBox isMandatory label="Email address" placeholder="Email" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} error={errors.email} touched={touched.email} icon={<BiEnvelope height="100%" width="100%" />} />
                        <div className="pd-companyaddress col-lg-6 col-sm-12">
                            <label htmlFor="lnumber" className='s1'>Working Address</label>
                            <div className="">
                                <AutoComplete
                                    multiple
                                    value={selectedCountries}
                                    suggestions={filteredCountries}
                                    completeMethod={search}
                                    onChange={(e) => setSelectedCountries(e.value)}
                                    className="w-100"
                                />
                            </div>
                            {errors.companyaddress && touched.companyaddress ? <p className="form-error">{errors.companyaddress}</p> : null}
                        </div>
                        <TextBox label="Rea Portal Name" placeholder="Rea Portal Name" name="reaportalname" value={values.reaportalname} onChange={handleChange} onBlur={handleBlur} error={errors.reaportalname} touched={touched.reaportalname} />
                        <div className="pd-domainportalname col-lg-6 col-sm-12">
                            <label htmlFor="fname" className='s1'>Domain Portal Name</label>
                            <div className="pddpn-group">
                                <div className="mb-3 pddpng1">
                                    <input type="text" className="form-control tenant-date" placeholder="First name" value={values.dpn1} onChange={handleChange} onBlur={handleBlur} name="dpn1" aria-describedby="basic-addon1" />
                                    {errors.dpn1 && touched.dpn1 ? <p className="form-error">{errors.dpn1}</p> : null}
                                </div>
                                <div className="mb-3 pddpng1">
                                    <input type="text" className="form-control tenant-date" placeholder="Last name" value={values.dpn2} onChange={handleChange} onBlur={handleBlur} name="dpn2" aria-describedby="basic-addon1" />
                                    {errors.dpn2 && touched.dpn2 ? <p className="form-error">{errors.dpn2}</p> : null}
                                </div>
                            </div>
                        </div>
                        <SelectInput isMandatory label="Agent Type" name="agentType" options={agentTypeOptions} placeholder="Agent Type" value={values.agentType} onChange={(val) => setFieldValue("agentType", val.value)} error={errors.agentType} touched={touched.agentType} />
                    </div>
                    <div className="property-details-buttons">
                        <button className="pdb-cancel" type="reset">
                            Cancel
                        </button>
                        <button className="pdb-save-changes" type="submit">
                            Save
                        </button>
                    </div>
                </form>}
        </div>
    )
}

export default PersonalDetailsAgent