import React, { useEffect, useState } from "react";
import "./../maintainance.css";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { getmaintenanceListforportal, FilePath } from "../../../../components/CommonSection/apiresources";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../Utilities/enum";
import { useNavigate } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import SubNav from "../../../navbar/subNav";
import { Pagination } from "antd";

const JobSearch = () => {
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [currentImageIndexes, setCurrentImageIndexes] = useState({});
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10); 
    const [totalRecords, setTotalRecords] = useState(0);
    const navigate = useNavigate();

    const fetchJobs = async (page = 1, pageSize = 10) => {
        try {
            const baseUrl = getmaintenanceListforportal;
            const requestData = {
                tradeId: 123, // Replace with dynamic data if needed
                PageNo: page,
                RecordsPerPage: pageSize,
            };

            const response = await makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", requestData, null, false);
            if (response.status === ActionStatus.Successfull) {
                setFilteredJobs(response.list);
                setTotalRecords(response.totalCount || 0); // Update total records for pagination
            } else {
                console.error("Error:", response.message);
            }
        } catch (error) {
            console.error("Error fetching jobs:", error);
        }
    };

    useEffect(() => {
        fetchJobs(currentPage, rowsPerPage); // Fetch jobs when the component loads or pagination changes
    }, [currentPage, rowsPerPage]);

    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setRowsPerPage(pageSize);
    };

    const handleViewDetails = (maintenanceId) => {
        navigate(`/JobPortalDetails?maintenanceUId=${maintenanceId}`);
    };

    const handleNextImage = (jobId, photos) => {
        setCurrentImageIndexes((prevIndexes) => {
            const currentIndex = prevIndexes[jobId] || 0;
            const nextIndex = (currentIndex + 1) % photos.length;
            return {
                ...prevIndexes,
                [jobId]: nextIndex,
            };
        });
    };

    const handlePreviousImage = (jobId, photos) => {
        setCurrentImageIndexes((prevIndexes) => {
            const currentIndex = prevIndexes[jobId] || 0;
            const prevIndex = (currentIndex - 1 + photos.length) % photos.length;
            return {
                ...prevIndexes,
                [jobId]: prevIndex,
            };
        });
    };

    return (
        <div>
            <div className="col-12 px-0 changedateantdstyle">
                <SubNav title={"Job Portal"} goback={false} extraData={true} />
                <div className="material-work-desk-new bg-white m-3">
                <div className=" d-flex justify-content-center">
                <div className="job-list mt-3">
                        {filteredJobs.map((job) => (
                            <div key={job.maintenanceId} className="property-card">
                                {/* Image Slider */}
                                <div className="property-image-slider">
                                    <LeftOutlined
                                        className="slider-arrow"
                                        onClick={() =>
                                            job.photos && job.photos.length > 1 && handlePreviousImage(job.maintenanceId, job.photos)
                                        }
                                    />
                                    <img
                                        src={
                                            job.photos && job.photos.length > 0
                                                ? `${FilePath}${job.photos[currentImageIndexes[job.maintenanceId] || 0]}`
                                                : "https://via.placeholder.com/300"
                                        }
                                        alt={job.title}
                                    />
                                    <RightOutlined
                                        className="slider-arrow"
                                        onClick={() =>
                                            job.photos && job.photos.length > 1 && handleNextImage(job.maintenanceId, job.photos)
                                        }
                                    />
                                </div>

                                {/* Property Details */}
                                <div className="property-details1" onClick={() => handleViewDetails(job.maintenanceUniqueId)}>
                                    <div className="propertyAdrress">{job.propertyAddress}</div>
                                    <div className="propertyTitle">
                                        <strong>Title:</strong> {job.title || "No Title Provided"}
                                    </div>
                                    <div className="propertyTitle">
                                        <strong>Issue Type:</strong> {job.sIssueType || "No Issue Type"}
                                    </div>
                                    <div className="propertyTitle">
                                        <strong>Status:</strong> {job.sStatus || "No Status"}
                                    </div>
                                </div>

                                {/* Agent Info */}
                                <div className="agent-info1">
                                    <div>
                                        <div>{job.agentName || "No Agent Assigned"}</div>
                                        <div>{job.tradesmanContact || "No Contact Info"}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {filteredJobs.length === 0 && <div>No jobs available at the moment.</div>}
                    </div>
                </div>
                   
                    <div className="pagination-container mt-4">
                        <Pagination
                            current={currentPage}
                            pageSize={rowsPerPage}
                            total={totalRecords}
                            onChange={handlePaginationChange}
                            showSizeChanger
                            pageSizeOptions={["10", "20", "50", "100"]}
                            responsive
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobSearch;
