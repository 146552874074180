import React, { useEffect, useState, useRef } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import axios from "axios";
import Header1 from "../../../CommonSection/Header/Header";
import Sidebar from "../../../CommonSection/SideBar/sideBar";
import PropertyDetailsAgency from "../../../../src/view/PropertiesAgency/PropertyDetailsAgency";
import PropertyListings from "../../../../src/view/PropertiesAgency/propertyListings";
import PropertyOwnerShip from "../../../../src/view/PropertiesAgency/propertyOwnerShip";
import PropertyDocuments from "../../../../src/view/PropertiesAgency/propertyDocuments";
import ToastAlert from "../../../CommonSection/ToastAlert/ToastAlert";
//import TenantPropertyAgency from "./TenantPropertyAgency";
import {propertyDetails,assignedTo,getAgreementDocumentListForProperty} from "../../../CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../Utilities/enum";
import { GetCookie } from "../../../CommonSection/Cookie/Session";
import { dynamicUrl } from "../../../../Utilities/profileManagement";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ViewPropertyDetails from "./viewPropertyDetails";

const PropertiesAgency = () => {
  const [visiblet, setVisiblet] = useState(true);
  const [getDetails, setGetDetails] = useState(null);
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [dpdata, setDpdata] = useState([]);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("propertiesList");
  const [activeSection, setActiveSection] = useState("Overview");
  const [getDocDetails, setGetDocDetails] = useState(null);
  const [getHistoryDetails , setGetHistoryDetails] = useState([]);
  const ToastRef = useRef();
  const navigate = useNavigate();
  var url = new URL(window.location.href);
  var propertyUId = url.searchParams.get("propertyUID");

  const menuItems = [
    { label: "Overview", key: "Overview", disabled: false },
    { label: "Property", key: "Property", disabled: false },
    { label: "Ownership", key: "Ownership", disabled: !propertyUId },
    { label: "Listing", key: "Listing", disabled: !propertyUId },
    { label: "Tenants", key: "Tenants", disabled: !propertyUId },
    { label: "Documents", key: "Documents", disabled: !propertyUId },
    { label: "Accounts", key: "Accounts", disabled: !propertyUId },
  ];



  useEffect(() => {
    if (propertyUId) {
      GetApi(propertyUId);
    }
  }, []);

  useEffect(() => {
    if(propertyUId === null){
      setActiveSection("Property")
    }
  },[])

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");

        if (userData) {
          const parsedData = JSON.parse(userData);
          reportData(parsedData.agencyUniqueID);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, []);

  const reportData = (agencyUID) => {
    const apiUrl = assignedTo;
    const baseUrl = apiUrl + "?agencyUID=" + agencyUID;
    axios.get(baseUrl).then((response) => {
      if (response.data.object) setDpdata(response.data.object);
    });
  };

  const GetApi = (value) => {
    const baseUrl = propertyDetails;
    const apiUrl = `${baseUrl}?propertyID=${value}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get")
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          setGetDetails(response.object);
          GetDocumentsApi(response.object)
          var switchTab = url.searchParams.get("switchTab");
          if (switchTab) {
            setActiveSection(switchTab);
          }
        } else {
          //handleparentsuccess(["warn", response.data.message]);
        }
      })
      .catch((error) => {
        //handleparentsuccess(["error", error]);
      });
  };

  const GetDocumentsApi = (data) => {
    const formData = {
      PropertyId: data.propertyId,
      AgencyId: data.agencyId
    }
    const baseUrl = getAgreementDocumentListForProperty;
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", formData)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          setGetDocDetails(response.list);
        } else {
          handleparentsuccess(["warn", response.data.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error]);
      });
  };


  const handleTopicValue = (text) => {
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const handleDataChild = (value) => {
    GetApi(value);
  };

  const handleTabChange = (key) => {
    setActiveSection(key);
  };

  const handleToastMessage = (toastdata) => {
    ToastRef.current.notify(toastdata);
  };

  const handleMenuChange = (section) => {
    setActiveSection(section);
  };

  const menuItemsForPrimeReact = menuItems.map((item) => ({
    label: item.label,
    command: () => handleMenuChange(item.key),
    className: activeSection === item.key ? "p-menuitem-active" : "",
    disabled: item.disabled,
  }));


  return (
    <>
      <ToastAlert ref={ToastRef} />
      <div className="dashboard-agency">
        <Header1 />
        <div
          className="blank_space"
          style={{
            backgroundColor: "white",
            height: "80px",
            padding: "10px",
          }}
        >
          <BiDotsVerticalRounded onClick={() => setVisiblet(!visiblet)} />
        </div>
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <Sidebar
              visible={sidebarVisible}
              handleTopicValue={handleTopicValue}
              topicValue={activeTopic}
            />
            <div
              className="pa-content-top-level"
              style={{ padding: "0px" }}
              onClick={() => setVisiblet(true)}
            >
              <Menubar
                className={activeSection === "Overview" ? "newmenu" : ""}
                style={{ marginBottom: "initial", marginTop: "10px" }}
                model={menuItemsForPrimeReact}
              />
              {activeSection === "Overview" && (
                <ViewPropertyDetails
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                  getDocDetails={getDocDetails}
                />
              )}
              {activeSection === "Property" && (
                <PropertyDetailsAgency
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                />
              )}
              {activeSection === "Ownership" && (
                <PropertyOwnerShip
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  getDocDetails={getDocDetails}
                />
              )}
              {activeSection === "Listing" && (
                <PropertyListings
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                />
              )}
              {activeSection === "Tenants" && (
                <TenantPropertyAgency
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                />
              )}
              {activeSection === "Documents" && (
                <PropertyDocuments
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertiesAgency;
