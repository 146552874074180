import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {AgreementDocumentList,deleteagreement} from "../../../components/CommonSection/apiresources";
import {GetCookie,} from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import "../../../components/Dashboard/Dashboard-agent/AgentList.css";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { ActionStatus } from "../../../Utilities/enum";
import {addParametersForProfiles} from "../../../Utilities/profileManagement";
import "../openHome/openHome.css";
import "../Task/task.css";
import { format } from "date-fns";
import AntdTableExample from "../../input-components/dataTable";
import { Select } from 'antd';

const ListAgreementType = () => {
  const [agreementlist, setAgreementList] = useState([]);
  const [topicValue, setTopicValue] = useState("Profile");
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agentID, setAgentId] = useState("");
  const [agencyUniqueID, setAgencyUniqueId] = useState("");
  const [chartData, setChartData] = useState();
  const navigate = useNavigate();
  const ToastRef = useRef();
  const [searchText, setSearchText] = useState("");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [agentUID, setAgentUID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [displayTable, setDisplaytable] = useState(true);

  const propertyTypeitems = [
    { label: "All", value: null },
    { label: "Pending", value: false },
    { label: "Completed", value: true },
  ];
  const [ageementTypeList, setAgreementTypeList] = useState([
    { label: "ExclusiveAgencyAgreement", value: 1 },
    { label: "OpenAgencyAgreement", value: 2 },
    { label: "ConjunctionAgencyAgreement", value: 3 },
    { label: "AuctionAgencyAgreement", value: 4 },
    { label: "Section51", value: 5 },
    { label: "SalesAdvice", value: 6 },
    { label: "RentAgreement", value: 7 },
    { label: "ManagementAgreement", value: 8 },
    { label: "TenantLeaseAgreement", value: 9 },
  ]);
  const [agreementType, setAgreementType] = useState(null);
  const [propertyType, setPropertyType] = useState(null);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgentId(parsedData.agentId);
          setAgencyUniqueId(parsedData.agencyUniqueID);
          setAgentUID(parsedData.agentId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) PostApiWithSpinner();
  }, [userId, searchText, propertyType, agreementType]);

  const PostApiWithSpinner = (currentPage, totalRows) => {
    setDisplaytable(false);
    const baseUrl = AgreementDocumentList;
    let data = {
      SortBy: "CreatedDate",
      SortOrder: "Desc",
      AgencyId: agencyID,
      IsCompleted: propertyType,
      AgreementType: agreementType,
      PageNo: currentPage ? currentPage : 1,
      Search: searchText,
      RecordsPerPage: totalRows,
      
    };
    if (data.SearchDateType === "") {
      delete data.SearchDateType;
    }
    data = addParametersForProfiles(data);
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          if (response.list.length > 0) {
            setDisplaytable(true);
            let templatesData = response.list;
            let templatesList = templatesData.map((task) => ({
              Type: task.agreementType,
              AgreementId: task.agreementId,
              AgreementType: task.sAgreementType,
              Property_Address: task.propertyAddress,
              Created_Date: format(new Date( task.createdAt ), "dd/MM/yyyy hh:mm aa"),
              Status: task.isCompleted ? "Completed" : "In-Completed",
              PropertyId: task.propertyUniqueId,
            }));
            setAgreementList(templatesList);
            setTotalPages(response.totalCount);
            setChartData(response.object);
          } else {
            //ToastRef.current.notify(["warn", "User not added"]);
          }
        } else {
          // ToastRef.current.notify(["error", response.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", response.message]);
      });
  };

  const propertyTypeChangeHandler = (value) => {
    setPropertyType(value);
  };

  const onInputChange = (e) => {
    setSearchText(e);
  };

  const handlePageChange = (pageNumber, totalRows) => {
    setCurrentPage(pageNumber);
    setRows(totalRows);
    PostApiWithSpinner(pageNumber, totalRows);
  };

  const handleDelete = (e,rowData) => {
    e.stopPropagation()
    e.preventDefault()
    const baseUrl = deleteagreement;
    let data = {
      AgreementId: rowData.AgreementId,
      AgreementType: rowData.Type,
    };
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", response.message]);
          PostApiWithSpinner();
          setTimeout(() => {
            ToastRef.current.clear();
          }, 2000); // 2 seconds delay
        } else {
          ToastRef.current.notify(["error", response.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", response.message]);
      });
  };

  const handleInput = (e, label) => {
    console.log(e)
    setAgreementType(e);
  };


  const handleView = (e,data) => {
      if (data.AgreementType === "Residential Tenancy Agreement") {
        navigate(
          `/agency/tenancyagreement?propertyUID=${data.PropertyId}`
        );
      } else if (data.AgreementType === "Management Agreement") {
        navigate(
          `/agency/agreementtabs?propertyUID=${data.PropertyId}`
        );
      } else if (data.AgreementType === "Exclusive Sales Agency Agreement") {
        navigate(`/agency/SaleAgreementTabs?propertyUID=${data.PropertyId}`);
      }
  };

  const actions = [
    { icon: "/assets/images/visible.svg", onClick: (e, data) => handleView(e, data) },
    { icon: "/assets/images/trash.png", onClick: (e, data) => handleDelete(e, data) ,condition: (record) => record.status !== "Completed",},
]

  const tabColumns = {
    Status: {
      colorMap: {
        "In-Completed": "gray",
        "Completed": "green",
      },
      defaultColor: 'blue'
    }
  };

  return (
    <div>
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
        <div className="material-work-desk-new bg-white m-3">
          <div style={{ float: 'right', margin: '10px' }}>
           <Select value={propertyType} style={{ width: 120 }} options={propertyTypeitems} onChange={propertyTypeChangeHandler}/>
          </div>
          <div style={{ float: 'right', margin: '10px' }}>
           <Select value={agreementType} style={{ width: 120 }} options={ageementTypeList} onChange={handleInput}/>
          </div>
          <AntdTableExample
            label="List of Agreements"
            dataSource={agreementlist}
            globalSearch
            hideCol={"PropertyId,Type,AgreementId"}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            totalRows={rows}
            totalRecords={totalPages}
            onSearchValueChange={onInputChange}
            actions={actions}
            onRowClick={handleView}
            tagColumns={tabColumns}
          />
        </div>
      </div>
    </div>
  </div>
  );
};

export default ListAgreementType;
