import React, { useState, useEffect } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { ListPortalEnquiry } from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { Select } from "antd";
import { Calendar } from "primereact/calendar"; // PrimeReact Calendar
import { ActionStatus } from "../../../Utilities/enum";
import AntdTableExample from "../../input-components/dataTable";
import SubNav from "../../navbar/subNav";
import moment from "moment";
import { FormatDate } from "../../../components/CommonSection/Utility/Utility";

const PortalEnquiryList = () => {
  const [listPortalEnquiry, setListPortalEnquiry] = useState([]);
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [searchText, setSearchText] = useState("");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [searchValue, setSearchValues] = useState("");
  const [protalVal, setProtalVal] = useState(null);
  const [propertyTypeVal, setPropertyTypeVal] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date(moment().subtract(1, "days"))); // Yesterday's date
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const [portalArr, setPortalArr] = useState([
    { label: "All", value: "All" },
    { label: "REA", value: "REA" },
    { label: "DOMAIN", value: "DOMAIN" },
  ]);
  const [propertyTypeArr, setPropertyTypeArr] = useState([
    { label: "All", value: null },
    { label: "Sale", value: 1 },
    { label: "Rental", value: 2 },
  ]);

  const [currentPages, setCurrentPage] = useState(1);
  const [totalRow, setTotalRow] = useState(5);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
        }
      } catch (error) {
        console.error("Error retrieving data:", error);
      }
    };
    retrieveData();
  }, []);

  useEffect(() => {
    if (userId) SearchEnquiryList();
  }, [userId]);

  const SearchEnquiryList = () => {
    const baseUrl = ListPortalEnquiry;
    let data = {
      RecordsPerPage: totalRow,
      AgencyId: agencyID,
      SortBy: "receivedAt",
      SortOrder: "Desc",
      Search: searchText,
      Portals: protalVal,
      PropertyId: searchValue,
      PageNo: currentPages,
      PropertyType: propertyTypeVal,
      Sincedate: moment(selectedDate).format("YYYY-MM-DD"), // Send the formatted date
    };

    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          console.log(response.list,'response.list')
          let protalData = response.list;
          let issuePortalData = protalData.map((portalEnquiry) => ({
            id:portalEnquiry.listing?.crmuId,
            Portal: portalEnquiry.portal,
            PropertyAddress:portalEnquiry.listing?.address,
            ContactDetails: portalEnquiry.contactDetails,
            ReceivedAt: portalEnquiry.receivedAt 
              ? FormatDate(portalEnquiry.receivedAt) 
              : "N/A",
            Comments: portalEnquiry.comments,
          }));
          setTotalPages(response.totalCount);

          setListPortalEnquiry(issuePortalData);
          
          setListPortalEnquiry(issuePortalData);
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.value);
  };

  const handlePageChange = (pageNumber, totalRows) => {
    setCurrentPage(pageNumber);
    setRows(totalRows);
    SearchEnquiryList(pageNumber, totalRows);
  };

  return (
    <div>
      <div className="col-12 px-0 changedateantdstyle">
        <SubNav title={" Portal Enquiry"} goback={false} extraData={true} />
        <div className="material-work-desk-new bg-white m-3">
          <div style={{ margin: "10px" }}>
            <Select
              className="mr-2"
              style={{ width: 120 }}
              value={protalVal}
              placeholder="Portals"
              onChange={(value) => setProtalVal(value)}
              options={portalArr}
            />
            <Select
              className="mr-2"
              style={{ width: 120 }}
              value={propertyTypeVal}
              placeholder="Property Type"
              onChange={(value) => setPropertyTypeVal(value)}
              options={propertyTypeArr}
            />
            <Calendar
              style={{height:'35px', width:'150px'}}
              className="mr-2"
              value={selectedDate}
              onChange={handleDateChange}
              dateFormat="yy-mm-dd"
              placeholder="Select Date"
            />
            <button
              className="Add-Property-button mt-4 ml-3"
              type="button"
              onClick={SearchEnquiryList}
            >
              <div className="text-parent">
                <div className="text7" style={{ fontSize: "14px" }}>
                  Search
                </div>
              </div>
            </button>
          </div>
          <AntdTableExample
            label="List of Portal Enquiry"
            dataSource={listPortalEnquiry}
            globalSearch
            hideCol={"crmuId,id"}
            onPageChange={handlePageChange}
            currentPage={currentPages}
              totalRows={rows}
              totalRecords={totalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default PortalEnquiryList;
