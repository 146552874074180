import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import { SidebarProvider } from '../Utilities/sidebarcontext.jsx'
import { ReadNotificationContextProvider } from '../Utilities/readNotificationContext.jsx';
import { SpinnerProvider } from "../Utilities/SpinnerContext.jsx";

ReactDOM.createRoot(document.getElementById('root')).render(
    <SpinnerProvider>

    <SidebarProvider>
        <ReadNotificationContextProvider>
            <App />
        </ReadNotificationContextProvider>
    </SidebarProvider>
    </SpinnerProvider>

)
