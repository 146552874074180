import React, { useState, useEffect } from "react";
import { FaSearch, FaBell, FaUserCircle } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import "./navbar.css";
import { currentProfile, logout } from "../../components/CommonSection/Cookie/Session";
import { getnavbarforuser} from "../../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../Utilities/client";
import { useSpinnerContext } from "../../Utilities/SpinnerContext";
import { useSidebarContext } from "../../Utilities/sidebarcontext";

const Navbar = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const currentSideBar = currentProfile.get();
    const [agentId, setAgentId] = useState("");
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [storedUserData, setStoredUserData] = useState(
        sessionStorage.getItem("@UserData")
    );
  const { navbarData, getLoginDataforTopBar  } = useSidebarContext();

    useEffect(() => {
        const retrieveData = () => {
            try {
                const userData = sessionStorage.getItem("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setAgentId(parsedData.agentId);
                    getLoginDataforTopBar(parsedData.userId,currentSideBar)
                    setStoredUserData(userData); // Sync state with sessionStorage
                } else {
                    setStoredUserData(null); // Clear state if no data
                }
            } catch (error) {
                console.error("Error retrieving data from sessionStorage:", error);
            }
        };

        retrieveData();
    }, []); // Run once on component mount

    const menuItems = [
        { name: "Home", path: "/home" },
        { name: "Sale", path: "/listLandingPage?catId=2" },
        { name: "Lease", path: "/listLandingPage?catId=3" },
        { name: "Job Portal", path: "/JobPortal" },
    ];

    const dropdownItems = [
        {
            name:
                currentSideBar === 13
                    ? "Tenant"
                    : currentSideBar === 2
                    ? "Agency"
                    : "Agent",
            path:
                currentSideBar === 13
                    ? "/tenant/DashboardTenant"
                    : currentSideBar === 2
                    ? "/agency/Dashboard"
                    : "/agent/Profile" + "?value=" + agentId,
        },
        { name: "Settings", path: "/settings" },
        {
            name: "Logout",
            action: () => logout(),
        },
    ];

    let navData = navbarData.length > 0 ? navbarData : menuItems

    return (
        <nav className="navbar">
            <div className="menuTopBar">
                {navData.map((item, index) => (
                    <div key={index} className="nav-item-wrapper" style={{ position: "relative" }}>

                    <a
                        href={item.path}
                        key={index}
                        className={
                            location.pathname === item.path ? "active-menu-item" : ""
                        }
                    >
                        {item.name}
                        {item.hasNotification && <span className="notification-indicatorNav"></span>}

                    </a>
                    </div>
                ))}
            </div>
            <div className="actions">
                <FaSearch />
                <FaBell />
                <div
                    className="user-icon"
                    onMouseEnter={() => setShowDropdown(true)}
                    onMouseLeave={() => setShowDropdown(false)}
                >
                    <FaUserCircle />
                    {showDropdown && (
                        <div className="dropdown-menu1">
                            {storedUserData ? (
                                dropdownItems.map((item, index) => (
                                    <div
                                        key={index}
                                        className="dropdown-item1"
                                        onClick={
                                            item.action
                                                ? item.action
                                                : () => navigate(item.path)
                                        }
                                    >
                                        {item.name}
                                    </div>
                                ))
                            ) : (
                                <div
                                    className="dropdown-item1"
                                    onClick={() => navigate("/signin")}
                                >
                                    Login/Signup
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
