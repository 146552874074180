import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { updateLandlordTenantAgreementDetails } from "../../../components/CommonSection/apiresources";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus, eTenantAgreementTerm } from "../../../Utilities/enum";
import { addParametersForProfiles, dynamicUrl } from "../../../Utilities/profileManagement";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import "../openHome/openHome.css";
import AddressInput from "../../input-components/address";
import { BiEnvelope } from "react-icons/bi";
import Collapse from "../../input-components/collapse";
import { managementAgreement } from "../../../components/CommonSection/Schema/schema";
import "./tenantAgreement.css";
import RadioInput from "../../input-components/radioBtn";

dayjs.extend(customParseFormat);

const initialValues = {
    AgreementId: '',
    LandlordAgentName: '',
    LandlordBusinessAddress: '',
    LandlordAgentPostcode: '',
    LandlordAgentSuburb: '',
    LandlordAgentState: '',
    LandlordAgentContact: '',
    TenantAgentName: '',
    TenantBusinessAddress: '',
    TenantAgentPostcode: '',
    TenantAgentSuburb: '',
    TenantAgentState: '',
    TenantAgentContact: '',
    PropertySuburb: '',
    PropertyPostCode: '',
    PropertyState: '',
    PropertyAddress: '',
    OtherSpace: '',
    TermsAgreement: '',
    TermsAgreementNote: '',
    AgreementStart: '',
    AgreementEnd: '',
    agentType: '',
};


const PropertyOwners = ({ onDataRecive, getDetails, handleNextPage }) => {
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [activeTopic, setActiveTopic] = useState("AddOpenHome");
    const [topicValue, setTopicValue] = useState("AddOpenHome");
    const ToastRef = useRef();
    const [viewAddressDetails, setViewAddressDetails] = useState(false);
    const [landLordAddress, setLandlordAddress] = useState("");
    const [tenantAddress, setTenantAddress] = useState("");
    const [propertyAddress, setPropertyAddress] = useState("");
    const [secondaryOwner, setSecondaryOwner] = useState([])
    const [primaryContact, setPrimaryContact] = useState({
        address: "",
        contactEmail: "",
        fName: "",
        getFullAddress: "",
        lName: "",
        mobileNumber: "",
        ContactlistModel: []
    })
    useEffect(() => {
        if (getDetails) {
            setFieldValue("LandlordAgentName", getDetails.landlordAgentName);
            setFieldValue("LandlordBusinessAddress", getDetails.landlordBusinessAddress);
            setLandlordAddress(getDetails.landlordBusinessAddress)
            setFieldValue("LandlordAgentPostcode", getDetails.landlordAgentPostcode);
            setFieldValue("LandlordAgentState", getDetails.landlordAgentState);
            setFieldValue("LandlordAgentSuburb", getDetails.landlordAgentSuburb);
            setFieldValue("LandlordAgentPostcode", getDetails.landlordAgentPostcode);
            setFieldValue("LandlordAgentContact", getDetails.landlordAgentContact);
            setFieldValue("TenantAgentName", getDetails.tenantAgentName ? getDetails.tenantAgentName : 'Nil');
            setFieldValue("TenantAgentPostcode", getDetails.tenantAgentPostcode ? getDetails.tenantAgentPostcode : 'Nil');
            setFieldValue("TenantAgentState", getDetails.tenantAgentState ? getDetails.tenantAgentState : 'Nil')
            setFieldValue("TenantAgentSuburb", getDetails.tenantAgentSuburb ? getDetails.tenantAgentSuburb : 'Nil')
            setFieldValue("TenantBusinessAddress", getDetails.tenantBusinessAddress ? getDetails.tenantBusinessAddress : "Nil")
            setTenantAddress(getDetails.tenantBusinessAddress ? getDetails.tenantBusinessAddress :"Nil")
            setFieldValue("TenantAgentContact", getDetails.tenantAgentContact ?getDetails.tenantAgentContact : 'Nil')
            setFieldValue("PropertyAddress", getDetails.propertyAddress)
            setFieldValue("PropertySuburb", getDetails.propertyState)
            setFieldValue("PropertyPostCode", getDetails.propertyPostCode)
            setFieldValue("PropertyState", getDetails.propertyState)
            setPropertyAddress(getDetails.propertyAddress)
            setFieldValue("OtherSpace", getDetails.otherSpace)
            setFieldValue("TermsAgreement", getDetails.termsAgreement.toString())
            setFieldValue("TermsAgreementNote", getDetails.termsAgreementNote)
            setFieldValue("AgreementStart", formatDate(getDetails.agreementStart))
            setFieldValue("AgreementEnd", formatDate(getDetails.agreementEnd))
        }
    }, [getDetails]);

    function formatDate(dateString) {
        if (!dateString) {
            return "";
        }
        const [year, month, day] = dateString.split("T")[0].split("-");
        return `${year}-${month}-${day}`;
    }


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
    }, []);

    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
        validateForm,
        setFieldTouched
    } = useFormik({
        initialValues: initialValues,
        //validationSchema: managementAgreement,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });

    const handleFinalSubmit = (data) => {
        let formData = {
            AgreementId: getDetails.agreeementId,
            LandlordAgentName: values.LandlordAgentName,
            LandlordBusinessAddress: values.LandlordBusinessAddress,
            LandlordAgentPostcode: values.LandlordAgentPostcode,
            LandlordAgentSuburb: values.LandlordAgentSuburb,
            LandlordAgentState: values.LandlordAgentState,
            LandlordAgentContact: values.LandlordAgentContact,
            TenantAgentName: values.TenantAgentName,
            TenantBusinessAddress: values.TenantBusinessAddress,
            TenantAgentPostcode: values.TenantAgentPostcode,
            TenantAgentSuburb: values.TenantAgentSuburb,
            TenantAgentState: values.TenantAgentState,
            TenantAgentContact: values.TenantAgentContact,
            PropertySuburb: values.PropertySuburb,
            PropertyPostCode: values.PropertyPostCode,
            PropertyState: values.PropertyState,
            PropertyAddress: values.PropertyAddress,
            OtherSpace: values.OtherSpace,
            TermsAgreement: parseFloat(values.TermsAgreement),
            TermsAgreementNote: values.TermsAgreementNote,
            AgreementStart: values.AgreementStart,
            AgreementEnd: values.AgreementEnd,
        };
        const url = updateLandlordTenantAgreementDetails;
        formData = addParametersForProfiles(formData);
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    handleNextPage("rentDetails")
                    onDataRecive(getDetails.propertyUId)
                    ToastRef.current.notify(["success", returnData.message]);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });

        //handleNextPage("rentDetails")
    };

    const handleView = () => {
        setViewAddressDetails(!viewAddressDetails);
    };

    const handlepressSelect = async (addrs, label) => {
        if (label === "LandlordBusinessAddress") {
            setLandlordAddress(addrs);
            setFieldValue("LandlordBusinessAddress", addrs);
        } else if (label === "TenantBusinessAddress") {
            setTenantAddress(addrs);
            setFieldValue("TenantBusinessAddress", addrs);
        } else if (label === "PropertyAddress") {
            setPropertyAddress(addrs);
            setFieldValue("PropertyAddress", addrs)
        }
    };

    const onChangeSwitch = (e, label) => {
        if (label === "PropertyFurnished") {
            setFieldValue("propertyFurnished", e);
        } else if (label === "PropertyUnfurnished") {
            setFieldValue("propertyUnfurnished", e);
        }
    };


    const agentTypeOptions = [
        { label: '6 months', value: eTenantAgreementTerm.SixM },
        { label: '12 months', value: eTenantAgreementTerm.TwelveM },
        { label: '2 years', value: eTenantAgreementTerm.TwoY },
        { label: '3 years', value: eTenantAgreementTerm.ThreeY },
        { label: '5 years', value: eTenantAgreementTerm.FiveY },
        { label: 'Periodic (no end date)', value: eTenantAgreementTerm.Periodic },
        { label: 'Other (Please specify)', value: eTenantAgreementTerm.Other },
    ];

    const handleClear = () => {
        handleNextPage("")
    };

    const handleCancle = () => {
        navigate("/agency/listagreementtype");
    }

    const handleChangeDate = (e) => {
        const dateValue = e.target.value;
        setFieldValue("AgreementStart", dateValue);
        
        const startDate = new Date(dateValue);
        const endDate = new Date(startDate);
        
        // Calculate the number of weeks based on TermsAgreement
        const weeksToAdd = {
            '1': 26, // 6 months = 26 weeks
            '2': 52, // 12 months = 52 weeks
            '3': 104, // 24 months = 104 weeks
            '4': 156, // 36 months = 156 weeks
            '5': 260, // 60 months = 260 weeks
        }[values.TermsAgreement];
        
        if (weeksToAdd !== undefined) {
            // Add the calculated number of weeks to the start date
            endDate.setDate(startDate.getDate() + weeksToAdd * 7);
            
            // Format the end date as YYYY-MM-DD
            const formattedEndDate = endDate.toISOString().slice(0, 10);
            
            // Set AgreementEnd value
            setFieldValue("AgreementEnd", formattedEndDate);
        }
    }
    
    
    const handleChangeRadio =(e)=>{
        setFieldValue("TermsAgreement" , e.target.value)
        setFieldValue("AgreementStart", '');
        setFieldValue("AgreementEnd", '');
    }
    
    return (
        <>
            <div className="dashboard-agency">
                <ToastAlert ref={ToastRef} />
                <div className="continer-dbag">
                    <div className="dashboardagency-content">
                        <div style={{ width: "100%" }}>
                            <form onSubmit={handleSubmit} className="">
                                <div>
                                    <div>
                                        <Collapse
                                            id={"landLordDetails"}
                                            title={`Landlord's agent details [if applicable]`}
                                            data={
                                                <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                    <TextBox
                                                        label="Agent Name"
                                                        placeholder="Agent Name"
                                                        name={`LandlordAgentName`} // Use a dynamic name to differentiate between input fields
                                                        value={values.LandlordAgentName}
                                                        onChange={handleChange} // Pass the field name to handleChange
                                                        onBlur={handleBlur}
                                                        error={errors.LandlordAgentName}
                                                        touched={touched.LandlordAgentName}
                                                    />
                                                    <AddressInput
                                                        address={landLordAddress}
                                                        setAddress={setLandlordAddress}
                                                        handlePressSelect={(e) => handlepressSelect(e, "LandlordBusinessAddress")}
                                                        error={errors.address}
                                                        touched={touched.address}
                                                        handleView={handleView}
                                                        labelName="Business Address"
                                                    />
                                                    <TextBox
                                                        label="Suburb"
                                                        placeholder="Suburb"
                                                        name="LandlordAgentSuburb"
                                                        value={values.LandlordAgentSuburb}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.LandlordAgentSuburb}
                                                        touched={touched.LandlordAgentSuburb}
                                                    />
                                                    <TextBox
                                                        label="State"
                                                        placeholder="State"
                                                        name="LandlordAgentState"
                                                        value={values.LandlordAgentState}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.LandlordAgentState}
                                                        touched={touched.LandlordAgentState}

                                                    />
                                                    <TextBox
                                                        label="Postcode"
                                                        placeholder="Postcode"
                                                        name="LandlordAgentPostcode"
                                                        value={values.LandlordAgentPostcode}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.LandlordAgentPostcode}
                                                        touched={touched.LandlordAgentPostcode}

                                                    />
                                                    <TextBox
                                                        label="Contact Details"
                                                        placeholder="Contact Details"
                                                        name="LandlordAgentContact"
                                                        value={values.LandlordAgentContact}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.LandlordAgentContact}
                                                        touched={touched.LandlordAgentContact}
                                                        icon={
                                                            <BiEnvelope height="100%" width="100%" />
                                                        }
                                                    />
                                                </div>
                                            } />
                                        <Collapse
                                            id={"tenant"}
                                            title={`Tenant's agent details [if applicable]`}
                                            data={
                                                <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                    <TextBox
                                                        label="Agent Name"
                                                        name={`TenantAgentName`} // Use a dynamic name to differentiate between input fields
                                                        value={values.TenantAgentName}
                                                        onChange={handleChange} // Pass the field name to handleChange
                                                        onBlur={handleBlur}
                                                        error={errors.TenantAgentName}
                                                        touched={touched.TenantAgentName}
                                                        placeholder="Agent Name"
                                                    />
                                                    <AddressInput
                                                        address={tenantAddress}
                                                        setAddress={setTenantAddress}
                                                        handlePressSelect={(e) => handlepressSelect(e, "TenantBusinessAddress")}
                                                        error={errors.address}
                                                        touched={touched.address}
                                                        handleView={handleView}
                                                        labelName="Business Address"
                                                    />
                                                    <TextBox
                                                        label="Suburb"
                                                        name="TenantAgentSuburb"
                                                        value={values.TenantAgentSuburb}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.TenantAgentSuburb}
                                                        touched={touched.TenantAgentSuburb}
                                                        placeholder="Suburb"
                                                    />
                                                    <TextBox
                                                        label="State"
                                                        placeholder="State"
                                                        name="TenantAgentState"
                                                        value={values.TenantAgentState}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.TenantAgentState}
                                                        touched={touched.TenantAgentState}
                                                    />
                                                    <TextBox
                                                        label="Postcode"
                                                        placeholder="Postcode"
                                                        name="TenantAgentPostcode"
                                                        value={values.TenantAgentPostcode}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.TenantAgentPostcode}
                                                        touched={touched.TenantAgentPostcode}
                                                    />
                                                    <TextBox
                                                        label="Contact Details"
                                                        placeholder="Email"
                                                        name="TenantAgentContact"
                                                        value={values.TenantAgentContact}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.TenantAgentContact}
                                                        touched={touched.TenantAgentContact}
                                                        icon={
                                                            <BiEnvelope height="100%" width="100%" />
                                                        }
                                                    />
                                                </div>
                                            } />

                                        <Collapse
                                            id={"Agent"}
                                            title={"Property (“the Property”) Description of the Property sufficiently detailed to allow it to be readily identified."}
                                            data={<div>
                                                <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                    <AddressInput
                                                        address={propertyAddress}
                                                        setAddress={setPropertyAddress}
                                                        handlePressSelect={(e) => handlepressSelect(e, "PropertyAddress")}
                                                        error={errors.address}
                                                        touched={touched.address}
                                                        handleView={handleView}
                                                        labelName="Address"
                                                    />
                                                    <TextBox
                                                        label="Suburb"
                                                        name="PropertySuburb"
                                                        value={values.PropertySuburb}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.PropertySuburb}
                                                        touched={touched.PropertySuburb}
                                                        placeholder="Suburb"
                                                    />
                                                    <TextBox
                                                        label="State"
                                                        placeholder="State"
                                                        name="PropertyState"
                                                        value={values.PropertyState}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.mobileNumber}
                                                        touched={touched.mobileNumber}
                                                    />
                                                    <TextBox
                                                        label="Postcode"
                                                        placeholder="Postcode"
                                                        name="PropertyPostCode"
                                                        value={values.PropertyPostCode}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.PropertyPostCode}
                                                        touched={touched.PropertyPostCode}
                                                    />
                                                    <TextBox
                                                        label="The residential premises include"
                                                        placeholder="The residential premises include"
                                                        name="OtherSpace"
                                                        value={values.OtherSpace}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.OtherSpace}
                                                        touched={touched.OtherSpace}
                                                    />
                                                </div>
                                            </div>

                                            } />
                                        <Collapse
                                            id={"Agent"}
                                            title={"Term of agreement"}
                                            data={<div>
                                                <div style={{ padding: "0px 30px" }}>
                                                    <div>
                                                    <RadioInput colVal={12} label="The residential premises include" name="TermsAgreement" value={values.TermsAgreement} checked={values.TermsAgreement} onChange={(e)=>handleChangeRadio(e)} onBlur={handleBlur} error={errors.TermsAgreement} touched={touched.TermsAgreement} options={agentTypeOptions}  />
                                                   {values.TermsAgreement === '6'? <div style={{marginLeft:'350px', marginTop:'-60px'}}>
                                                    <TextBox
                                                        colVal={3}
                                                        label=""
                                                        name="TermsAgreementNote"
                                                        placeholder="Note"
                                                        value={values.TermsAgreementNote}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.TermsAgreementNote}
                                                        touched={touched.TermsAgreementNote}
                                                    />
                                                    </div> : null }
                                                    </div>
                                                    <div className="pddpn-group">
                                                        <TextBox
                                                            label="Starting On"
                                                            name={`AgreementStart`} // Use a dynamic name to differentiate between input fields
                                                            value={values.AgreementStart}
                                                            onChange={(e)=>handleChangeDate(e)} // Pass the field name to handleChange
                                                            onBlur={handleBlur}
                                                            error={errors.AgreementStart}
                                                            touched={touched.AgreementStart}
                                                            type={"date"}
                                                        />
                                                        <TextBox
                                                            label="Ending On"
                                                            name={`AgreementEnd`} // Use a dynamic name to differentiate between input fields
                                                            value={values.AgreementEnd}
                                                            onChange={handleChange} // Pass the field name to handleChange
                                                            onBlur={handleBlur}
                                                            error={errors.AgreementEnd}
                                                            touched={touched.AgreementEnd}
                                                            type={"date"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            } />
                                    </div>
                                </div>
                                <div className="property-details-buttons">
                                    <button
                                        className="pdb-save-changes"
                                        type="reset"
                                        onClick={handleCancle}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="pdb-cancel"
                                        type="reset"
                                        onClick={handleClear}
                                    >
                                        Previous
                                    </button>
                                    <button className="pdb-save-changes" type="submit">
                                        Save & Next
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PropertyOwners;
