import "./select.css";
import React from "react";
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import { Navigate, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { getPendingAgencyDetails } from "../apiresources";
import {
  CurrentProfileString,
  GetCookie,
  currentProfile,
} from "../Cookie/Session";
import ToastAlert from "../ToastAlert/ToastAlert";

import { UserRoleId } from "../../../Utilities/enum";

const ToastRef = React.createRef();

const Selection = () => {
  const [userId, setUserId] = useState();
  const [userUId, setUserUId] = useState("");
  const [multipleProfile, setMultipleProfile] = useState([]);
  const [id, setId] = useState();
  const [status, setStatus] = useState(null);
  const [pageStatus, setPageStatus] = useState("");
  const [agencyId, setAgencyId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setMultipleProfile(
            parsedData.userProfiles.map((profile) => profile.userRoleLabel)
          );
        }
      } catch (error) {
        ToastAlert.current.notify(["error", error.message]);
      }
    };

    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userUId !== "") {
      GetApi();
    }
  }, [userUId]);

  const GetApi = () => {
    const baseUrl = getPendingAgencyDetails;

    // Request body with login information

    const apiUrl = `${baseUrl}?UserUniqueId=${userUId}`;

    axios
      .get(apiUrl)

      .then((response) => {
        // Handle the response data

        if (response.data.status == 1) {
          setAgencyId(response.data.object.agencyUniqueID);
          setStatus(response.data.object.status);
          setPageStatus(response.data.object.approvalStatus);
        } else {
          // alert(`Message: ${response.data.message}`);

          console.log(response.data);
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const ForAgencyCheck = () => {
    if (status === null) {
      navigate("/agency/principaldetails");
    } else if (status == 0) {
      navigate("/agency/verifyagency");
    } else if (status == 1) {
      navigate("/agency/licensedetails");
    } else if (status == 2) {
      navigate("/agency/addcompanydetails");
    } else if (status == 3) {
      navigate("/agency/insurancedetails");
    } else if (status == 4 || status == 5) {
      navigate("/agency/paymentdetails");
    } else if (status == 6) {
      navigate("/agency/agencycompletion");
    } else if (status == 7) {
      navigate("/agency/statuspage");
    } else {
      navigate("/agency/dashboard");
    }
  };
  const allProfiles = [
    { name: "Landlord", action: () => navigate("/addproperty") },

    {
      name: "Tenant",
      //action: () => navigate("/agency/TenantPropertyDetails"),
      action: () => {
        if (multipleProfile.includes("Tenant")) {
          navigate("/tenant/DashboardTenant")
          currentProfile.set(UserRoleId.Tenant);
        }
        },

    },
    {
      name: "Trade",
      action: () => {
        if (multipleProfile.includes("Trade")) {
          navigate("/trade/TradesmanDashboard")
          currentProfile.set(UserRoleId.Trade);
        }
      },
    },
    {
      name: "Agent/Agency",
      action: () => {
        if (multipleProfile.includes("Agency")) {
          ForAgencyCheck();
          currentProfile.set(UserRoleId.Agency);
        } else if (multipleProfile.includes("Agent")) {
          // debugger;
          currentProfile.set(UserRoleId.Agent);
          navigate("/agent/dashboard");
          ToastRef.current.notify(["success", "Agent"]);
        } else {
          ForAgencyCheck();
        }
      },
    },
  ];

  // function takevalue(e) {
  //   ToastRef.current.notify(["success", e]);

  //   if (e == "landlord") {
  //     navigate("/addproperty?redirect=yes");

  //   } else if (e == "agentagency") {

  //     if (!status) {
  //       navigate("/agency/principaldetails");
  //     } else if (status == 0) {
  //       navigate("/agency/verifytenant");
  //     } else if (status == 1) {
  //       navigate("/agency/licensedetails");
  //     } else if (status == 2) {
  //       navigate("/agency/addcompanydetails");
  //     } else if (status == 3) {
  //       navigate("/agency/insurancedetails");
  //     } else if (status == 4 || status == 5) {
  //       navigate("/agency/paymentdetails");
  //     } else if (status == 6) {
  //       navigate("/agency/agencycompletion");
  //     } else if (status == 7) {
  //       navigate("/agency/statuspage");
  //     } else {
  //       console.log(status);
  //       navigate("/agency/dashboard");
  //     }
  //   }
  // }

  const handleExplore = () => {
    navigate("/home");
  }
  return (
    <div className="selectionuser-choose-profile">
      <ToastAlert ref={ToastRef} />
      {/* <Header /> */}
      <div className="containerx">
        <div className="selectionadd-profile1">Select Profile</div>
        <div className="selectionframe-parent">
          {allProfiles.map((label, index) => (
            <div key={index}>
              <div
                className="selectionframe-container"
                id={label.name}
                onClick={label.action}
              >
                {label.name == "Landlord" ? (
                  <div className="selectionrectangle-wrapper">
                    <img
                      className="selectionframe-child"
                      alt=""
                      src=".\assets\images\landlord.png"
                    />
                  </div>
                ) : label.name == "Tenant" ? (
                  <div className="selectionrectangle-wrapper">
                    <img
                      className="selectionframe-child"
                      alt=""
                      src=".\assets\images\tenent.png"
                    />
                  </div>
                ) : label.name == "Trade" ? (
                  <div className="selectionrectangle-wrapper">
                    <img
                      className="selectionframe-child"
                      alt=""
                      src=".\assets\images\trade.png"
                    />
                  </div>
                ) : label.name == "Agent/Agency" ? (
                  <div className="selectionrectangle-wrapper">
                    <img
                      className="selectionframe-child"
                      alt=""
                      src=".\assets\images\agent.png"
                    />
                  </div>
                ) : null}
                <div className="selectionlandlord">{label.name}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="selectionbutton2" onClick={handleExplore}>
          <div className="selectiontext7">Explore More</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Selection;
