import React from "react";
import { useEffect, useState, useMemo, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import "../../openHome/openHome.css";
import PlacesAutocomplete, {geocodeByAddress,} from "react-places-autocomplete";
import ShowValue from "../../../input-components/ShowValue"

dayjs.extend(customParseFormat);

const initialValues = {
  businessName: "",
  agencyLicenceNumber: "",
  agencyAbn: "",
  agencyName: "",
  agencyEmail: "",
  fax: "",
  mobile: "",
  agencyWorkPhone: "",
};

const AgentAgreement = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  reportTo,
  handleNextPage,
}) => {
  const navigate = useNavigate();
  const ToastRef = useRef();
  const [viewAddressDetails, setViewAddressDetails] = useState(false);
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setFieldValue("businessName", getDetails.companyName);
      setAddress(getDetails.agencyAddress);
    }
  }, [getDetails]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);


  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    //validationSchema: AddOpenHomeSchema,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (data) => {
    handleNextPage("ManagementAgreement");
  };

  const handleClear = () => {
    handleNextPage("");
  };

  const handleView = () => {
    setViewAddressDetails(!viewAddressDetails);
  };


  const handleCancle = () => {
    navigate("/agency/ListAgreement");
  };

  return (
    <>
      <div className="dashboard-agency">
        <ToastAlert ref={ToastRef} />
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <div style={{ width: "100%" }}>
              <form onSubmit={handleSubmit} className="details-form">
                <div className="property-details-content df-all all-input">
                  <div style={{width:'100%'}}>
                        <div className="pddpn-group2 pddpn-group3">
                        <div className="agreementLabel mb-3">{`Agent`}</div>
                           <div className={`lda-${name} `} >
                                   <div className="address-pair">
                                    <div className="labelssss">Business Name</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {values.businessName}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} `} >
                                   <div className="address-pair">
                                    <div className="labelssss">License Number</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {values.agencyLicenceNumber}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} `} >
                                   <div className="address-pair">
                                    <div className="labelssss">ABN/ACN</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {values.agencyAbn}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} `} >
                                   <div className="address-pair">
                                    <div className="labelssss">Trading as</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {values.businessName}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} `}>
                                   <div className="address-pair">
                                    <div className="labelssss">Address</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {address}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} `} >
                                   <div className="address-pair">
                                    <div className="labelssss">Phone</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {values.agencyWorkPhone}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} `}>
                                   <div className="address-pair">
                                    <div className="labelssss">PostCode</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {values.agencyPostCode}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} `} >
                                   <div className="address-pair">
                                    <div className="labelssss">Fax</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {values.fax}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} `} >
                                   <div className="address-pair">
                                    <div className="labelssss">Mobile</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {values.mobile}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} `} >
                                   <div className="address-pair">
                                    <div className="labelssss">Email Address </div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {values.agencyEmail}</div>
                                  </div>
                                </div>
                        </div>
                  </div>
                </div>
                <div className="property-details-buttons">
                  <button
                    className="pdb-cancel"
                    type="reset"
                    onClick={handleCancle}
                  >
                    Cancel
                  </button>
                  <button
                    className="pdb-save-changes"
                    type="reset"
                    onClick={handleClear}
                  >
                    Previous
                  </button>
                  <button className="pdb-save-changes" type="submit">
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentAgreement;
