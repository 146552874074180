import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { updateRentBondTenantAgreementDetails } from "../../../components/CommonSection/apiresources";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import {
  addParametersForProfiles,
  dynamicUrl,
} from "../../../Utilities/profileManagement";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import "../openHome/openHome.css";
import Collapse from "../../input-components/collapse";
import { leaserent } from "../../../components/CommonSection/Schema/schema";
import "./tenantAgreement.css";
import RadioInput from "../../input-components/radioBtn";
import SelectBox from "../../input-components/selectBoxNew";
import { useScrollToFirstError } from "../../../Utilities/ScrollToErrorField";

dayjs.extend(customParseFormat);

const initialValues = {
  AgreementId: "",
  RentAmount: "",
  Period: "",
  LeaseCommencement: "",
  BSB: "",
  AccountNo: "",
  AccountName: "",
  PaymentReference: "",
  PaymentTo: "",
  PaymentAt: "",
  PaymentFollows: "",
  BondAmount: "",
  BondAmountProvidedTo: "NSW Fair Trading through Rental Bond Online.",
};

const PropertyOwners = ({ onDataRecive, getDetails, handleNextPage }) => {
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [activeTopic, setActiveTopic] = useState("AddOpenHome");
  const [topicValue, setTopicValue] = useState("AddOpenHome");
  const ToastRef = useRef();
  const [viewAddressDetails, setViewAddressDetails] = useState(false);
  const [address, setAddress] = useState("");
  const [secondaryOwner, setSecondaryOwner] = useState([]);
  const [primaryContact, setPrimaryContact] = useState({
    address: "",
    contactEmail: "",
    fName: "",
    getFullAddress: "",
    lName: "",
    mobileNumber: "",
    ContactlistModel: [],
  });

  useEffect(() => {
    if (getDetails) {
      setFieldValue("RentAmount", getDetails.rentAmount);
      setFieldValue("Period", getDetails.period);
      setFieldValue(
        "LeaseCommencement",
        formatDate(getDetails.leaseCommencement)
      );
      setFieldValue("BSB", getDetails.bsb);
      setFieldValue("AccountNo", getDetails.accountNo);
      setFieldValue("AccountName", getDetails.accountName);
      setFieldValue(
        "PaymentReference",
        getDetails.tenants
          ? getDetails.tenants[0]?.tenantFolioDetails.tenantChecksum
          : null
      );
      setFieldValue("PaymentTo", getDetails.paymentTo);
      setFieldValue("PaymentAt", getDetails.paymentAt);
      setFieldValue("PaymentFollows", getDetails.paymentFollows);
      setFieldValue("BondAmount", getDetails.rentAmount * 4);
      setFieldValue(
        "BondAmountProvidedTo",
        getDetails.bondAmountProvidedTo
          ? getDetails.bondAmountProvidedTo
          : "NSW Fair Trading through Rental Bond Online."
      );
    }
  }, [getDetails]);

  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }
    const [year, month, day] = dateString.split("T")[0].split("-");
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);

  const {
    errors,
    values,
    touched,
    isValid,
    submitCount,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
    validateForm,
    setFieldTouched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: leaserent,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (data) => {
    let formData = {
      AgreementId: getDetails.agreeementId,
      RentAmount: values.RentAmount,
      Period: values.Period,
      LeaseCommencement: values.LeaseCommencement,
      BSB: values.BSB,
      AccountNo: values.AccountNo,
      AccountName: values.AccountName,
      PaymentReference: values.PaymentReference,
      PaymentTo: values.PaymentTo,
      PaymentAt: values.PaymentAt,
      PaymentFollows: values.PaymentFollows,
      BondAmount: values.BondAmount,
      BondAmountProvidedTo: values.BondAmountProvidedTo,
    };
    const url = updateRentBondTenantAgreementDetails;
    formData = addParametersForProfiles(formData);
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          handleNextPage("impInfo");
          onDataRecive(getDetails.propertyUId);
          ToastRef.current.notify(["success", returnData.message]);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });

    //handleNextPage("impInfo")
  };

  const handleChangePrimary = (e) => {
    const { name, value } = e.target;
    setPrimaryContact((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "contactEmail") {
      setFieldValue("contactEmail", e.target.value);
    } else if (name === "fName") {
      setFieldValue("fName", e.target.value);
    } else if (name === "lName") {
      setFieldValue("lName", e.target.value);
    } else if (name === "mobileNumber") {
      setFieldValue("mobileNumber", e.target.value);
    }
  };

  const licenseTypeOptions = [
    {
      label: "the landlord or another person, or",
      value: "the land or another person",
    },
    { label: "the landlord’s agent, or", value: "the landlord’s agent" },
    {
      label: "NSW Fair Trading through Rental Bond Online.",
      value: "NSW Fair Trading through Rental Bond Online",
    },
  ];

  const handleClear = () => {
    handleNextPage("LandlordDetails");
  };

  const handleCancle = () => {
    navigate("/agency/listagreementtype");
  };

  const periodArr = [
    { value: 1, label: "Weekly" },

    { value: 2, label: "Monthly" },
  ];

  const handleChangeRent = (e) => {
    setFieldValue("RentAmount", e.target.value);
    setFieldValue("BondAmount", e.target.value * 4);
  };
  useScrollToFirstError(isValid, errors, submitCount);
  return (
    <>
      <div className="dashboard-agency">
        <ToastAlert ref={ToastRef} />
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <div style={{ width: "100%" }}>
              <form onSubmit={handleSubmit} className="">
                <div>
                  <div>
                    <Collapse
                      id={"landLordDetails"}
                      title={`Rent`}
                      data={
                        <div>
                          <div
                            className="pddpn-group"
                            style={{ padding: "0px 30px" }}
                          >
                            <TextBox
                              isMandatory
                              label="The rent is"
                              type="number"
                              placeholder="The rent is"
                              name={`RentAmount`} // Use a dynamic name to differentiate between input fields
                              value={values.RentAmount}
                              onChange={(e) => handleChangeRent(e)} // Pass the field name to handleChange
                              onBlur={handleBlur}
                              error={errors.RentAmount}
                              touched={touched.RentAmount}
                            />

                            <SelectBox
                              isMandatory
                              label="Period"
                              name="Period"
                              options={periodArr}
                              placeholder={"Select Period"}
                              value={values.Period}
                              onChange={(val) =>
                                setFieldValue("Period", val.value)
                              }
                              error={errors.Period}
                              touched={touched.Period}
                            />

                            <TextBox
                              label="Payable in advance starting on"
                              placeholder="Payable in advance starting on"
                              name="LeaseCommencement"
                              value={values.LeaseCommencement}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.LeaseCommencement}
                              touched={touched.LeaseCommencement}
                              type={"date"}
                            />
                          </div>
                          <div
                            className="pddpn-group"
                            style={{ padding: "0px 30px" }}
                          >
                            <div className="col-lg-8 col-sm-12 mb-3">
                              The method by which the rent must be paid:
                            </div>
                            <div className="col-lg-12 col-sm-12 mb-3">
                              (a) Electronic Funds Transfer (EFT) into the
                              following account, or any other account nominated
                              by the landlord:
                            </div>
                            <TextBox
                              isMandatory
                              label="A/C Name"
                              placeholder="A/C Name"
                              name={`AccountName`} // Use a dynamic name to differentiate between input fields
                              value={values.AccountName}
                              onChange={handleChange} // Pass the field name to handleChange
                              onBlur={handleBlur}
                              error={errors.AccountName}
                              touched={touched.AccountName}
                            />

                            <TextBox
                              isMandatory
                              label="BSB"
                              placeholder="BSB"
                              name="BSB"
                              value={values.BSB}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.BSB}
                              touched={touched.BSB}
                            />
                            <TextBox
                              isMandatory
                              label="A/C Number"
                              placeholder="A/C Number"
                              name="AccountNo"
                              value={values.AccountNo}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.AccountNo}
                              touched={touched.AccountNo}
                            />
                            <TextBox
                              label="Payment Reference"
                              placeholder="Payment Reference"
                              name="PaymentReference"
                              value={values.PaymentReference}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.PaymentReference}
                              touched={touched.PaymentReference}
                            />
                          </div>
                          <div
                            className="pddpn-group"
                            style={{ padding: "0px 30px" }}
                          >
                            <div className="col-lg-8 col-sm-12">(b)</div>
                            <TextBox
                              label="to"
                              placeholder="to"
                              name={`PaymentTo`} // Use a dynamic name to differentiate between input fields
                              value={values.PaymentTo}
                              onChange={handleChange} // Pass the field name to handleChange
                              onBlur={handleBlur}
                              error={errors.PaymentTo}
                              touched={touched.PaymentTo}
                            />

                            <TextBox
                              label="at"
                              placeholder="at"
                              name="PaymentAt"
                              value={values.PaymentAt}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.PaymentAt}
                              touched={touched.PaymentAt}
                            />
                          </div>
                          <div
                            className="pddpn-group"
                            style={{ padding: "0px 30px" }}
                          >
                            <div className="col-lg-8 col-sm-12">(C)</div>
                            <TextBox
                              label="as follows"
                              placeholder="as follows"
                              name={`PaymentFollows`} // Use a dynamic name to differentiate between input fields
                              value={values.PaymentFollows}
                              onChange={handleChange} // Pass the field name to handleChange
                              onBlur={handleBlur}
                              error={errors.PaymentFollows}
                              touched={touched.PaymentFollows}
                            />
                          </div>
                        </div>
                      }
                    />
                    <Collapse
                      id={"tenant"}
                      title={`Rental Bond`}
                      data={
                        <div>
                          <div
                            className="pddpn-group"
                            style={{ padding: "0px 30px" }}
                          >
                            <TextBox
                              label="A rental bond of"
                              type="number"
                              placeholder="A rental bond of"
                              name={`BondAmount`} // Use a dynamic name to differentiate between input fields
                              value={values.BondAmount}
                              onChange={handleChange} // Pass the field name to handleChange
                              onBlur={handleBlur}
                              error={errors.BondAmount}
                              touched={touched.BondAmount}
                            />
                          </div>
                          <div
                            className="pddpn-group"
                            style={{ padding: "0px 30px" }}
                          >
                            <RadioInput
                              row={true}
                              label="The tenant provided the rental bond amount to"
                              name="BondAmountProvidedTo"
                              value={values.BondAmountProvidedTo}
                              checked={values.BondAmountProvidedTo}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.BondAmountProvidedTo}
                              touched={touched.BondAmountProvidedTo}
                              options={licenseTypeOptions}
                            />
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
                <div className="property-details-buttons">
                  <button
                    className="pdb-save-changes"
                    type="reset"
                    onClick={handleCancle}
                  >
                    Cancel
                  </button>
                  <button
                    className="pdb-cancel"
                    type="reset"
                    onClick={handleClear}
                  >
                    Previous
                  </button>
                  <button className="pdb-save-changes" type="submit">
                    Save & Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyOwners;
