import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { GetCookie, currentProfile } from "../../../../components/CommonSection/Cookie/Session";
import TextBox from "../../../input-components/textbox";
import TextArea from "../../../input-components/textarea";
import { addupdateqoute, getmaintenanceListforportal } from "../../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../Utilities/enum";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import './../maintainance.css'
import {BiDollar } from "react-icons/bi";
import SubNavbar from "../../../../components/CommonSection/Header/subHeader";
import { Checkbox } from 'antd';

const initialValues = {
  QuotePrice: "",
  CallOutCharge: "",
  ServiceCategory: "",
  ServiceDescription: "",
  TotalAmount: "",
};

const AddQuotes = () => {
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [topicValue, setTopicValue] = useState("Profile");
  const ToastRef = useRef();
  const [isChecked, setIsChecked] = useState(false);
  const [tradeId, setTradeId] = useState("");
  const currentLogin = currentProfile.get();

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setTradeId(parsedData.supplierId)
          fetchDataWithSpinner(parsedData.supplierId);
        } else {
          navigate("/signin");
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  var url = new URL(window.location.href);
  var maintainanceId = url.searchParams.get("maintainanceId");
  var detailsId = url.searchParams.get("details");

  useEffect(() => {
    var url = new URL(window.location.href);
    var serviceCategory = url.searchParams.get("serviceCategory");
    if (serviceCategory) {
      setFieldValue("ServiceCategory", serviceCategory)
    }
  }, []);


  const fetchDataWithSpinner = (id) => {
    const baseUrl = getmaintenanceListforportal;
    let data = currentLogin === 12 ? { TradeId: id } : {};
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data, null, false)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          const matchedItem = response.list.find(item =>
            item.quoteDetails &&
            item.quoteDetails.quoteId &&
            String(item.quoteDetails.quoteId) === detailsId
          );
          if (matchedItem) {
            const { quoteDetails } = matchedItem;
            setFieldValue("QuotePrice", quoteDetails.quotePrice || "");
            setFieldValue("CallOutCharge", quoteDetails.callOutCharge || "");
            setFieldValue("ServiceCategory", quoteDetails.serviceCategory || "");
            setFieldValue("ServiceDescription", quoteDetails.serviceDescription || "");
            setFieldValue("TotalAmount", quoteDetails.totalAmount || "");
          }
        } else {
          // Handle error
          console.error("Error:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });


  const handleFinalSubmit = (data) => {
    let formData = {
      MaintenanceId: parseInt(maintainanceId),
      QuoteId: detailsId != "add" ? detailsId :0,
      QuotePrice: parseInt(values.QuotePrice),
      CallOutCharge: values.CallOutCharge,
      ServiceCategory: values.ServiceCategory,
      ServiceDescription: values.ServiceDescription,
      TotalAmount: parseInt(values.TotalAmount),
      TradeId: parseInt(tradeId)
    };
    const url = addupdateqoute;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData, null, false)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          navigate("/jobPortal");
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleChangeCheck = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleChangePrice = (e) => {
    const quotePrice = parseFloat(e.target.value) || 0;
    const callOutCharge = parseFloat(values.CallOutCharge) || 0;

    if (isChecked) {
      setFieldValue("TotalAmount", quotePrice + callOutCharge);
    } else {
      setFieldValue("TotalAmount", quotePrice);
    }

    setFieldValue("QuotePrice", e.target.value);
  };

  const handleCallOutChargeChange = (e) => {
    const callOutCharge = parseFloat(e.target.value) || 0;
    const quotePrice = parseFloat(values.QuotePrice) || 0;

    if (isChecked) {
      setFieldValue("TotalAmount", quotePrice + callOutCharge);
    }

    setFieldValue("CallOutCharge", e.target.value);
  };


  return (
    <div className="dashboard-agency">
      <ToastAlert ref={ToastRef} />
      <div className="dashboardagency-content">
        <form
          onSubmit={handleSubmit}
          className="details-form"
          style={{ display: "initial", width: "100%" }}
        >
          <SubNavbar title={ detailsId != "add" ? "Update Quote":"Add Quote"} goBack={handleGoBack} actionButton={false} extraData={true} />
          <div className="property-details-content df-all all-input">
            <div className="" style={{ padding: "0px 30px", width: '45%' }}>
              <TextBox
                colVal={12}
                label="Quote Price"
                id="QuotePrice"
                name="QuotePrice"
                value={values.QuotePrice}
                onChange={handleChangePrice}
                onBlur={handleBlur}
                error={errors.QuotePrice}
                touched={touched.QuotePrice}
                icon={
                  <BiDollar height="100%" width="100%" />
                }
                extraDetails={<Checkbox checked={isChecked} onClick={handleChangeCheck}>Call out chargeable</Checkbox>}
              />


              {isChecked ?
                <TextBox
                  colVal={12}
                  label="Call Out Charge"
                  id="CallOutCharge"
                  name="CallOutCharge"
                  value={values.CallOutCharge}
                  onChange={handleCallOutChargeChange}
                  onBlur={handleBlur}
                  error={errors.CallOutCharge}
                  touched={touched.CallOutCharge}
                /> : null}

              <TextBox
                colVal={12}
                label="Total Amount"
                id="TotalAmount"
                name="TotalAmount"
                value={values.TotalAmount}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.TotalAmount}
                touched={touched.TotalAmount}
                icon={
                  <BiDollar height="100%" width="100%" />
                }
              />



            </div>
            <div className="mt-5" style={{ padding: "0px 30px", width: '45%' }}>

              <TextBox
                colVal={12}
                label="Service Category"
                id="ServiceCategory"
                name="ServiceCategory"
                value={values.ServiceCategory}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.ServiceCategory}
                touched={touched.ServiceCategory}
              />
              <TextArea
                colVal={12}
                label="Service Description"
                id="ServiceDescription"
                name="ServiceDescription"
                value={values.ServiceDescription}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.ServiceDescription}
                touched={touched.ServiceDescription}
              />

            </div>
          </div>

          <div className="property-details-buttons">
            <button className="pdb-cancel" type="reset">
              Cancel
            </button>
            <button className="pdb-save-changes" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddQuotes;
