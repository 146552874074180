import React from "react";
import { useEffect, useState, useRef } from "react";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import AddOrSelectProperty from "./addNewProperty";
import AgreementPeriod from "./agreementPeriod";
import FeesAndCharges from "./feesAndCharges";
import TribunalFees from "./TribunalFees";
import PromotionalActivities from "./PromotionalActivities";
import RepairAndMaintainace from "./RepairsAndMaintenance";
import AgreementSummary from "./summary";
import {assignedTo,getManagementAgreement,CreateManagementAgreement} from "../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import {ActionStatus,ManagementAgreementStatus} from "../../../Utilities/enum";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import axios from "axios";
import CustomModal from "../../input-components/Modal";

const AgreementTabs = ({ handleparentsuccess ,handleAddNewAgreement}) => {
  const [activeKey, setActiveKey] = useState("PropertyDetails");
  const [getDetails, setGetDetails] = useState(null);
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [dpdata, setDpdata] = useState([]);
  const [userId, setUserId] = useState("");
  const ToastRef = useRef();
  const navigate = useNavigate();
  const [agencyID, setAgencyId] = useState("");
  const [HandleNextPages, setHandleNextPages] = useState("");

  const handleDataChild = (value) => {
    GetApi(value);
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  useEffect(() => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
    var activeTab = url.searchParams.get("activeTab");
    if (activeTab) {
      setActiveKey(activeTab);
    }
    if (propertyUId) {
      GetApi();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, [activeKey]);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          reportData(parsedData.agencyUniqueID);
          setAgencyId(parsedData.agencyID);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, []);

  const GetApi = (value) => {
    var url = new URL(window.location.href);
    var propertyUId = value ? value : url.searchParams.get("propertyUID");
    const baseUrl = getManagementAgreement;
    const apiUrl = `${baseUrl}?propId=${propertyUId}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get")
      .then((response) => {
        if (response.object === null) {
          createManagementAgreement(propertyUId, agencyID);
        }
        if (response.object !== null) {
          setGetDetails(response.object);
          debugger;
          if (
            response.object.agreementStatus ===
            ManagementAgreementStatus.PropertySelected
          ) {
            setActiveKey("");
            setHandleNextPages("");
          } else if (
            response.object.agreementStatus ===
            ManagementAgreementStatus.PropertyDetails
          ) {
            setActiveKey("AgreementPeriod");
            setHandleNextPages("AgreementPeriod");
          }
          // else if (response.object.agreementStatus === ManagementAgreementStatus.AgencyDetails) {
          //   setActiveKey("AgreementPeriod")
          //   setHandleNextPages("AgreementPeriod")
          // }
          else if (
            response.object.agreementStatus ===
            ManagementAgreementStatus.AgreementPeriod
          ) {
            setActiveKey("FeesAndCharges");
            setHandleNextPages("FeesAndCharges");
          } else if (
            response.object.agreementStatus ===
            ManagementAgreementStatus.FeesCharges
          ) {
            setActiveKey("TribunalFees");
            setHandleNextPages("TribunalFees");
          } else if (
            response.object.agreementStatus ===
            ManagementAgreementStatus.TribunalInsurance
          ) {
            setActiveKey("PromotionalActivities");
            setHandleNextPages("PromotionalActivities");
          } else if (
            response.object.agreementStatus ===
              ManagementAgreementStatus.PromotionalActivity ||
            response.object.agreementStatus ===
              ManagementAgreementStatus.Repairs
          ) {
            setActiveKey("RepairAndMaintainance");
            setHandleNextPages("RepairAndMaintainance");
          } else if (
            response.object.agreementStatus === ManagementAgreementStatus.Signed
          ) {
            setActiveKey("AgreementSummary");
            setHandleNextPages("AgreementSummary");
          } else if (
            response.object.agreementStatus ===
            ManagementAgreementStatus.Completed
          ) {
            setActiveKey("AgreementSummary");
            setHandleNextPages("AgreementSummary");
          } else if (
            getDetails.agreementStatus ===
            ManagementAgreementStatus.PropertySelected
          ) {
            setActiveKey("");
            setHandleNextPages("");
          }
        }
      })
      .catch((error) => {
        //handleparentsuccess(["error", error]);
      });
  };
  const createManagementAgreement = (data, agencyID,isNew) => {
    //setHandleNextPages("")
    var urls = new URL(window.location.href);
    var Id = agencyID !== "" ? agencyID : urls.searchParams.get("agencyId");
    let formData = {
      PropertyUId: data,
      AgencyId: Id,
      isNew : isNew ? isNew : null
    };
    const url = CreateManagementAgreement;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        debugger
        if (returnData.status === ActionStatus.Successfull) {
          setGetDetails(returnData.object);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleToastMessage = (toastdata) => {
    ToastRef.current.notify(toastdata);
  };

  const reportData = (agencyUID) => {
    const apiUrl = assignedTo;
    const baseUrl = apiUrl + "?agencyUID=" + agencyUID;
    axios.get(baseUrl).then((response) => {
      if (response.data.object) setDpdata(response.data.object);
    });
  };

  const handleNextPage = (value) => {
    setHandleNextPages(value);
  };

  const closeAgreementModule = () => {
    var url = new URL(window.location.href);
    var properties = url.searchParams.get("properties");
    var propertyUId = url.searchParams.get("propertyUID");
    var viewDetails = url.searchParams.get("viewDetails");
    if(propertyUId && viewDetails){
      dynamicUrl(navigate, "viewPropertyDetails" + `?propertyUID=${propertyUId}`);
    }
    else if(properties){
      dynamicUrl(navigate, "propertiesList" + `?propertyUID=${propertyUId}&tab=OwnerShip&subTab=agreement`);
    }else{
    dynamicUrl(navigate, "listagreementtype");
    }
  };
  return (
    <>
      <ToastAlert ref={ToastRef} />
      <div className="dashboard-agency">
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <CustomModal
              title="Management Agreement"
              width={1100}
              closable={closeAgreementModule}
              visible={true}
              onCancel={closeAgreementModule}
              modalData={
                <div>
                  {HandleNextPages === "" ? (
                    <AddOrSelectProperty
                      onDataRecive={handleDataChild}
                      handleparentsuccess={handleToastMessage}
                      handleCurrentPage={handleTabChange}
                      getDetails={getDetails}
                      reportTo={dpdata}
                      handleNextPage={handleNextPage}
                    />
                  ) : null}

                  {/* {HandleNextPages === "propetyies" ? <AgentAgreement
                onDataRecive={handleDataChild}
                handleparentsuccess={handleToastMessage}
                handleCurrentPage={handleTabChange}
                getDetails={getDetails}
                reportTo={dpdata}
                handleNextPage={handleNextPage}
              /> : null} */}

                  {HandleNextPages === "AgreementPeriod" ? (
                    <AgreementPeriod
                      onDataRecive={handleDataChild}
                      handleparentsuccess={handleToastMessage}
                      handleCurrentPage={handleTabChange}
                      getDetails={getDetails}
                      reportTo={dpdata}
                      handleNextPage={handleNextPage}
                    />
                  ) : null}
                  {HandleNextPages === "FeesAndCharges" ? (
                    <FeesAndCharges
                      onDataRecive={handleDataChild}
                      handleparentsuccess={handleToastMessage}
                      handleCurrentPage={handleTabChange}
                      getDetails={getDetails}
                      reportTo={dpdata}
                      handleNextPage={handleNextPage}
                    />
                  ) : null}
                  {HandleNextPages === "TribunalFees" ? (
                    <TribunalFees
                      onDataRecive={handleDataChild}
                      handleparentsuccess={handleToastMessage}
                      handleCurrentPage={handleTabChange}
                      getDetails={getDetails}
                      reportTo={dpdata}
                      handleNextPage={handleNextPage}
                    />
                  ) : null}
                  {HandleNextPages === "PromotionalActivities" ? (
                    <PromotionalActivities
                      onDataRecive={handleDataChild}
                      handleparentsuccess={handleToastMessage}
                      handleCurrentPage={handleTabChange}
                      getDetails={getDetails}
                      reportTo={dpdata}
                      handleNextPage={handleNextPage}
                    />
                  ) : null}
                  {HandleNextPages === "RepairAndMaintainance" ? (
                    <RepairAndMaintainace
                      onDataRecive={handleDataChild}
                      handleparentsuccess={handleToastMessage}
                      handleCurrentPage={handleTabChange}
                      getDetails={getDetails}
                      reportTo={dpdata}
                      handleNextPage={handleNextPage}
                    />
                  ) : null}
                  {/* {HandleNextPages === "AgreementSignature" ?
                <AgreementSignature
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                  handleNextPage={handleNextPage}
                /> : null} */}

                  {HandleNextPages === "AgreementSummary" ? (
                    <AgreementSummary
                      onDataRecive={handleDataChild}
                      handleparentsuccess={handleToastMessage}
                      handleCurrentPage={handleTabChange}
                      getDetails={getDetails}
                      reportTo={dpdata}
                      handleNextPage={handleNextPage}
                      handleAddNewAgreement={createManagementAgreement}

                    />
                  ) : null}
                </div>
              }
              footer={[null]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AgreementTabs;
