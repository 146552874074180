import React, { useMemo, useRef, useState, useEffect } from "react";
import Header from "../../../components/CommonSection/Header/Header";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import { getinspectionbyuniqueid, generateroutineinspectionreport, generateentryexitinspectionreport,finaliseinspection } from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner, DownloadFiletWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import Sidebar from "../../../components/CommonSection/SideBar/sideBar"
import { FormatDate } from "../../../components/CommonSection/Utility/Utility";
import { Divider } from "primereact/divider";
import "../../../components/Dashboard/Agency/PropertiesAgecny/viewPropertyDetails.css";
import RatingStar from "../../input-components/ratingStar";
import moment from 'moment';
import CustomModal from "../../input-components/Modal";
import { Switch,Tag } from "antd";
import Collapse from "../../input-components/collapse";
import TextArea from "../../input-components/textarea";
import TextBox from "../../input-components/textbox";
import SubNav from "../../../components/CommonSection/Header/subHeader";
import ShowValue from "../../input-components/ShowValue";
import { currentProfile} from "../../../components/CommonSection/Cookie/Session";

const ViewInspection = () => {
    const [agentLists, setAgentLists] = useState([]);
    const [visiblet, setVisiblet] = useState(true);
    const [userId, setUserId] = useState("");
    const [agencyID, setAgencyId] = useState("");
    const navigate = useNavigate();
    const ToastRef = useRef();
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [activeTopic, setActiveTopic] = useState("ListInspection");
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [getDetails, setGetDetails] = useState({});
    const [openGenerateReport, setOpenGenerateReport] = useState(false);
    const [switchValues, setSwitchValues] = useState({
        PremisesStrctlySound: false,
        LightingInRoom: false,
        Ventilation: false,
        ElctrcityOutletsSockt: false,
        PlumbingandDrainage: false,
        SuppliedElectricty: false,
        SuppliedGas: false,
        ConnectdToWaterSupply: false,
        ContainBathrm: false,
        TenantAgreesUtilities: false,
        AdditionalUtilitiesTerms: '',
        SignsMuds: false,
        PestsVermin: false,
        Rubbish: false,
        ListedLooseFill: false,
        SmokeAlarmInstalled: false,
        SmokeAlarmWorking: false,
        SmokeAlaramLastChecked: '',
        SmokeAlarmBttryReplaced: false,
        BattryChangedDate: '',
        HaveRemovableBattry: false,
        RemovableBattryChangedDate: '',
        VisibleSIgnDamage: false,
        VisibleElectricityHazard: false,
        VisibleGasHazard: false,
        TenantAgreeSafetyIssues: false,
        AdditionalTermSafetyIssues: '',
        TelephoneConnectd: false,
        InternetConnected: false,
        SeperatedMetered: false,
        ShowerheadMaximumFlow: false,
        DualFlushToilet: false,
        ColdWatrTapsMaximumFlow: false,
        CheckedWaterLeakage: false,
        WaterEfficiencyDateChecked: '',
        WatermeterReadingStart: '',
        DateofWaterMeterReadingStart: '',
        WatermeterReadingEnd: '',
        DateOdWaterMeterReadingEnd: '',
        AdditionalComments: '',
        LastDatePaintingExternal: '',
        LastDatePaintingInternal: '',
        LastDateInstalledSmokeAlarms: '',
        LastDateFloorCleaned: '',
        LandlordAgreeToUndrtakeWork: '',
        LandLordWorkDoneBy: false,
    });
    var url = new URL(window.location.href);
    var InspectionUniqueId = url.searchParams.get("InspectionUniqueId");
    const InspectionType = url.searchParams.get("inspectionType");
    const userRoleId = currentProfile.get();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setUserId(parsedData.userId);
                    setAgencyId(parsedData.agencyID);
                }
            } catch (error) {
                console.error("Error retrieving data from AsyncStorage:", error);
            }
        };
        retrieveData();
    }, [userId]);

    useEffect(() => {
        PostApiWithSpinner(InspectionUniqueId);
    }, []);



    const PostApiWithSpinner = (InspectionUniqueId) => {
        const baseUrl = getinspectionbyuniqueid + `?InspectionUniqueId=${InspectionUniqueId}`;
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "get")
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    setGetDetails(response.object)
                } else {
                    alert(`Message: ${response.message}`);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleTopicValue = (text) => {
        dynamicUrl(navigate, text);
        setActiveTopic(text);
    };

    const renderOwners = () => {
        if (!getDetails?.contactlistModelView || getDetails.contactlistModelView.length === 0) {
            return null;
        }

        const sortedContacts = getDetails.contactlistModelView.sort((a, b) => b.isPrimary - a.isPrimary);

        return (
            <div>
                <div className="mt-2 detailsContainer">
                    <ShowValue Label="Owners" setData="" />
                    {sortedContacts.map((contact, index) => (
                        <div key={index} className="mb-3">
                            <ShowValue Label="Owner Type" setData={contact.isPrimary ? "Primary Owner" : "Secondary Owner"} />
                            <ShowValue Label="Name" setData={contact.name || "-"} />
                            <ShowValue Label="Phone" setData={contact.phone || "-"} />
                            <ShowValue Label="Email" setData={contact.contactEmail || "-"} />
                            <ShowValue Label="Address" setData={contact.address || "-"} />
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderTenantInfo = (title, tenants) => (
        tenants?.map((tenant) => (
            <div key={tenant.contactId} className="mt-2 detailsContainer mr-1">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>{title}</div>
                    <div className="editbtn" onClick={()=>handleOpenAddTenant(tenant.tenantId,'edit')}>
                        <img className="mr-1" src="/assets/images/editing.svg" width={15} alt="edit icon" />Edit
                    </div>
                </div>
                <div className="owner-details">
                    <ShowValue Label="Name" setData={tenant.getFullName || "-"} />
                    <ShowValue Label="Email" setData={tenant.tenantEmail || "-"} />
                    <ShowValue Label="Phone" setData={tenant.tenantMobile || "-"} />
                </div>
            </div>
        ))
    );



    const handleEditReport = () => {
        dynamicUrl(navigate, "EditReport" + `?id=${getDetails.inspectionId}&inspectionType=${getDetails.sInspectionType}`);
    }

    const handleGenerateReport = () => {
        if (InspectionType === 'Routine') {
            SubmitGenerateReportRoutine();
        } else {
            setOpenGenerateReport(true);
            setSwitchValues({
                PremisesStrctlySound: false,
                LightingInRoom: false,
                Ventilation: false,
                ElctrcityOutletsSockt: false,
                PlumbingandDrainage: false,
                SuppliedElectricty: false,
                SuppliedGas: false,
                ConnectdToWaterSupply: false,
                ContainBathrm: false,
                TenantAgreesUtilities: false,
                AdditionalUtilitiesTerms: '',
                SignsMuds: false,
                PestsVermin: false,
                Rubbish: false,
                ListedLooseFill: false,
                SmokeAlarmInstalled: false,
                SmokeAlarmWorking: false,
                SmokeAlaramLastChecked: '',
                SmokeAlarmBttryReplaced: false,
                BattryChangedDate: '',
                HaveRemovableBattry: false,
                RemovableBattryChangedDate: '',
                VisibleSIgnDamage: false,
                VisibleElectricityHazard: false,
                VisibleGasHazard: false,
                TenantAgreeSafetyIssues: false,
                AdditionalTermSafetyIssues: '',
                TelephoneConnectd: false,
                InternetConnected: false,
                SeperatedMetered: false,
                ShowerheadMaximumFlow: false,
                DualFlushToilet: false,
                ColdWatrTapsMaximumFlow: false,
                CheckedWaterLeakage: false,
                WaterEfficiencyDateChecked: '',
                WatermeterReadingStart: '',
                DateofWaterMeterReadingStart: '',
                WatermeterReadingEnd: '',
                DateOdWaterMeterReadingEnd: '',
                AdditionalComments: false,
                LastDatePaintingExternal: '',
                LastDatePaintingInternal: '',
                LastDateInstalledSmokeAlarms: '',
                LastDateFloorCleaned: '',
                LandlordAgreeToUndrtakeWork: '',
                LandLordWorkDoneBy: '',
            });
        }
    }

    const closeGenerateReport = () => {
        setOpenGenerateReport(false)
    }

    const onChangeSwitch = (checked, label) => {
        setSwitchValues((prevValues) => ({
            ...prevValues,
            [label]: checked
        }));
    };

    const SubmitGenerateReportEntery = () => {
        let data = {
            InspectionId: getDetails.inspectionId,
            PremisesStrctlySound: switchValues.PremisesStrctlySound ? 1 : 2,
            LightingInRoom: switchValues.LightingInRoom ? 1 : 2,
            Ventilation: switchValues.Ventilation ? 1 : 2,
            ElctrcityOutletsSockt: switchValues.ElctrcityOutletsSockt ? 1 : 2,
            PlumbingandDrainage: switchValues.PlumbingandDrainage ? 1 : 2,
            SuppliedElectricty: switchValues.SuppliedElectricty ? 1 : 2,
            SuppliedGas: switchValues.SuppliedGas ? 1 : 2,
            ConnectdToWaterSupply: switchValues.ConnectdToWaterSupply ? 1 : 2,
            ContainBathrm: switchValues.ContainBathrm ? 1 : 2,
            TenantAgreesUtilities: switchValues.TenantAgreesUtilities ? 1 : 2,
            AdditionalUtilitiesTerms: switchValues.AdditionalUtilitiesTerms,
            SignsMuds: switchValues.SignsMuds ? 1 : 2,
            PestsVermin: switchValues.PestsVermin ? 1 : 2,
            Rubbish: switchValues.Rubbish ? 1 : 2,
            ListedLooseFill: switchValues.ListedLooseFill ? 1 : 2,
            SmokeAlarmInstalled: switchValues.SmokeAlarmInstalled ? 1 : 2,
            SmokeAlarmWorking: switchValues.SmokeAlarmWorking ? 1 : 2,
            SmokeAlaramLastChecked: switchValues.SmokeAlaramLastChecked ? moment(switchValues.SmokeAlaramLastChecked).format('DD-MM-YYYY') : null,
            SmokeAlarmBttryReplaced: switchValues.SmokeAlarmBttryReplaced ? 1 : 2,
            BattryChangedDate: switchValues.BattryChangedDate ? moment(switchValues.BattryChangedDate).format('DD-MM-YYYY') : null,
            HaveRemovableBattry: switchValues.HaveRemovableBattry ? 1 : 2,
            RemovableBattryChangedDate: switchValues.RemovableBattryChangedDate ? moment(switchValues.RemovableBattryChangedDate).format('DD-MM-YYYY') : null,
            VisibleSIgnDamage: switchValues.VisibleSIgnDamage ? 1 : 2,
            VisibleElectricityHazard: switchValues.VisibleElectricityHazard ? 1 : 2,
            VisibleGasHazard: switchValues.VisibleGasHazard ? 1 : 2,
            TenantAgreeSafetyIssues: switchValues.TenantAgreeSafetyIssues ? 1 : 2,
            AdditionalTermSafetyIssues: switchValues.AdditionalTermSafetyIssues,
            TelephoneConnectd: switchValues.TelephoneConnectd ? 1 : 2,
            InternetConnected: switchValues.InternetConnected ? 1 : 2,
            SeperatedMetered: switchValues.SeperatedMetered ? 1 : 2,
            ShowerheadMaximumFlow: switchValues.ShowerheadMaximumFlow ? 1 : 2,
            DualFlushToilet: switchValues.DualFlushToilet ? 1 : 2,
            ColdWatrTapsMaximumFlow: switchValues.ColdWatrTapsMaximumFlow ? 1 : 2,
            CheckedWaterLeakage: switchValues.CheckedWaterLeakage ? 1 : 2,
            WaterEfficiencyDateChecked: switchValues.WaterEfficiencyDateChecked ? moment(switchValues.WaterEfficiencyDateChecked).format('DD-MM-YYYY') : null,
            WatermeterReadingStart: switchValues.WatermeterReadingStart,
            DateofWaterMeterReadingStart: switchValues.DateofWaterMeterReadingStart ? moment(switchValues.DateofWaterMeterReadingStart).format('DD-MM-YYYY') : null,
            WatermeterReadingEnd: switchValues.WatermeterReadingEnd,
            DateOdWaterMeterReadingEnd: switchValues.DateOdWaterMeterReadingEnd ? moment(switchValues.DateOdWaterMeterReadingEnd).format('DD-MM-YYYY') : null,
            AdditionalComments: switchValues.AdditionalComments,
            LastDatePaintingExternal: switchValues.LastDatePaintingExternal ? moment(switchValues.LastDatePaintingExternal).format('DD-MM-YYYY') : null,
            LastDatePaintingInternal: switchValues.LastDatePaintingInternal ? moment(switchValues.LastDatePaintingInternal).format('DD-MM-YYYY') : null,
            LastDateInstalledSmokeAlarms: switchValues.LastDateInstalledSmokeAlarms ? moment(switchValues.LastDateInstalledSmokeAlarms).format('DD-MM-YYYY') : null,
            LastDateFloorCleaned: switchValues.LastDateFloorCleaned ? moment(switchValues.LastDateFloorCleaned).format('DD-MM-YYYY') : null,
            LandlordAgreeToUndrtakeWork: switchValues.LandlordAgreeToUndrtakeWork,
            LandLordWorkDoneBy: switchValues.LandLordWorkDoneBy ? moment(switchValues.LandLordWorkDoneBy).format('DD-MM-YYYY') : null
        };
        const baseUrl = generateentryexitinspectionreport;
        DownloadFiletWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
            .then((response) => {
                window.open(response, "_blank");
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const SubmitGenerateReportRoutine = () => {
        const baseUrl = generateroutineinspectionreport + '?InspectionUniqueId=' + InspectionUniqueId;
        DownloadFiletWithSpinner(showSpinner, hideSpinner, baseUrl, "get")
            .then((response) => {
                window.open(response, "_blank");
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleGoBack = () => {
        dynamicUrl(navigate, "ListInspection");
    }

    const SubmitFinalise = () => {
        const baseUrl = finaliseinspection;

        let formData = {
            InspectionUniqueId : InspectionUniqueId,
            UserType : userRoleId
        }
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", formData)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", response.message]);
                    PostApiWithSpinner(InspectionUniqueId);
                } else {
                    ToastRef.current.notify(["error", response.message]);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const primarytenantDetails = getDetails?.tenantDetails?.filter((tenant) => tenant.primaryTenant);
    console.log(primarytenantDetails,'primarytenantDetails')
    const secondarytenantDetails = getDetails?.tenantDetails?.filter((tenant) => !tenant.primaryTenant);

    return (

        <div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
                    <SubNav title={"View Inspection"} goBack={handleGoBack} extraData={true} extraContent={
                        <div key="footer" style={{ display: "flex", justifyContent: "flex-end" }}>
                            {/* Render Finalise button if not finalized */}
                            {getDetails.isAgentFinalised === null && getDetails.isCompleted === false && (
                                <button
                                    style={{ marginRight: "20px" }}
                                    className="pdb-save-changes mt-2"
                                    onClick={SubmitFinalise}
                                >
                                    Finalise
                                </button>
                            )}

                            {/* Render Review Report button based on userRoleId and conditions */}
                            {getDetails.isAgentFinalised === true && (
                                <>
                                    {InspectionType === "Entry" && userRoleId === 13 && getDetails.isTenantFinalised === null && (
                                        <div className="d-flex">
                                        <button
                                    style={{ marginRight: "20px" }}
                                    className="pdb-save-changes mt-2"
                                    onClick={SubmitFinalise}
                                >
                                    Finalise
                                </button>
                                        <button
                                            style={{ marginRight: "20px" }}
                                            className="pdb-save-changes mt-2"
                                            onClick={handleEditReport}
                                        >
                                            Review Report
                                        </button>
                                        </div>
                                    )}

                                 
                                </>
                            )}

                            {getDetails.isAgentFinalised === null && (
                                <>
                                    {userRoleId !== 13 && (
                                        <button
                                            style={{ marginRight: "20px" }}
                                            className="pdb-save-changes mt-2"
                                            onClick={handleEditReport}
                                        >
                                            Review Report
                                        </button>
                                    )}
                                </>
                            )}

                            {/* Always render Generate Report button */}
                            <button
                                style={{ marginRight: "20px" }}
                                className="pdb-save-changes mt-2"
                                onClick={handleGenerateReport}
                            >
                                Generate Report
                            </button>
                        </div>

                    } />
                    <div className="material-work-desk-new bg-white m-3">
                        <ToastAlert ref={ToastRef} />

                        <div>
                            <div style={{ width: "100%" }}>
                                <div className="left-content">

                                    <div className="mt-3">
                                        <div style={{width: "100%",fontWeight: "500",cursor: "pointer"}}>
                                            <span className="left-label">
                                                {getDetails.propertyAddress}
                                            </span>

                                            <span className="ml-3 mb-3 left-label">
                                            {getDetails.isCompleted ? <Tag color="green">{"Completed"}</Tag> : null }
                                            </span>

                                        </div>
                                        <Divider style={{ fontWeight: "600" }} />

                                        <div className="mt-2 detailsContainer">
                                            <ShowValue Label='Date' setData={FormatDate(getDetails.inspectionDate)} />
                                            <ShowValue Label='Start Time' setData={moment(getDetails.startTime).format('hh:mm A')} />
                                            <ShowValue Label='End Time' setData={moment(getDetails.endTime).format('hh:mm A')} />
                                            <ShowValue Label='Inspection Type' setData={getDetails.sInspectionType} />
                                            <ShowValue Label='Assigned To' setData={getDetails.sAssignedAgent} />
                                            <ShowValue Label='Property Address' setData={getDetails.propertyAddress} />
                                            <ShowValue Label='Summary' setData={getDetails.summary} />

                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <div>
                                            {renderOwners()}
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <div>
                                        {renderTenantInfo("Primary Tenant", primarytenantDetails)}
                                        {renderTenantInfo("Secondary Tenant", secondarytenantDetails)}
                                        </div>
                                    </div>
                                    <CustomModal
                                        title="Generate Report"
                                        width={800}
                                        closable={closeGenerateReport}
                                        visible={openGenerateReport}
                                        onCancel={closeGenerateReport}
                                        modalData={
                                            <div style={{ marginTop: "30px", marginLeft: "10px", height: '70vh', overflowY: 'auto', overflowX: "hidden" }}>
                                                <Collapse
                                                    id={"tenant"}
                                                    title={`Minimum Standards`}
                                                    data={
                                                        <div style={{ padding: "0px 30px" }}>
                                                            <div >
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Are the premises structurally sound</div>
                                                                    <div><Switch checked={switchValues.PremisesStrctlySound} onChange={(e) => onChangeSwitch(e, "PremisesStrctlySound")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">(a) natural or artificial lighting in each room (excluding storage rooms or garages)?</div>
                                                                    <div><Switch checked={switchValues.LightingInRoom} onChange={(e) => onChangeSwitch(e, "LightingInRoom")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">(b) Ventilation?</div>
                                                                    <div><Switch checked={switchValues.Ventilation} onChange={(e) => onChangeSwitch(e, "Ventilation")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">A(c) electricity outlet sockets or gas outlet sockets for the supply of lighting and heating to the premises, and for the use of appliances in the premises?</div>
                                                                    <div><Switch checked={switchValues.ElctrcityOutletsSockt} onChange={(e) => onChangeSwitch(e, "ElctrcityOutletsSockt")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">(d) plumbing and drainage?</div>
                                                                    <div><Switch checked={switchValues.PlumbingandDrainage} onChange={(e) => onChangeSwitch(e, "PlumbingandDrainage")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">(a) supplied with electricity?</div>
                                                                    <div><Switch checked={switchValues.SuppliedElectricty} onChange={(e) => onChangeSwitch(e, "SuppliedElectricty")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">(a) supplied with electricity?</div>
                                                                    <div><Switch checked={switchValues.SuppliedGas} onChange={(e) => onChangeSwitch(e, "SuppliedGas")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">(c) connected to a water supply service or infrastructure that supplies water (including, but not limited to, a water bore or water tank) that is able to supply to the premises hot and cold water for drinking and ablution and cleaning activities?</div>
                                                                    <div><Switch checked={switchValues.ConnectdToWaterSupply} onChange={(e) => onChangeSwitch(e, "ConnectdToWaterSupply")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Does the premises contain bathroom facilities, including toilet and washing facilities that allow privacy for the user?</div>
                                                                    <div><Switch checked={switchValues.ContainBathrm} onChange={(e) => onChangeSwitch(e, "ContainBathrm")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Does the tenant agree with all of the above?</div>
                                                                    <div><Switch checked={switchValues.TenantAgreesUtilities} onChange={(e) => onChangeSwitch(e, "TenantAgreesUtilities")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" >
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">If no, specify which items:</div>
                                                                    <div><TextArea colVal={12} label="" name="AdditionalUtilitiesTerms" value={switchValues.AdditionalUtilitiesTerms} onChange={(e) => onChangeSwitch(e.target.value, "AdditionalUtilitiesTerms")} /></div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    } />
                                                <Collapse
                                                    id={"tenant"}
                                                    title={`Health Issues`}
                                                    data={
                                                        <div style={{ padding: "0px 30px" }}>
                                                            <div >
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">(a) Are there any signs of mould and dampness?</div>
                                                                    <div><Switch checked={switchValues.SignsMuds} onChange={(e) => onChangeSwitch(e, "SignsMuds")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">(b) Are there any pests and vermin?</div>
                                                                    <div><Switch checked={switchValues.PestsVermin} onChange={(e) => onChangeSwitch(e, "PestsVermin")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">(c) Has any rubbish been left on the premises?</div>
                                                                    <div><Switch checked={switchValues.Rubbish} onChange={(e) => onChangeSwitch(e, "Rubbish")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">(d) Are the premises listed on the Loose-Fill Asbestos Insulation Register?</div>
                                                                    <div><Switch checked={switchValues.ListedLooseFill} onChange={(e) => onChangeSwitch(e, "ListedLooseFill")} /></div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    } />
                                                <Collapse
                                                    id={"tenant"}
                                                    title={`Smoke Alarms`}
                                                    data={
                                                        <div style={{ padding: "0px 30px" }}>
                                                            <div >
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Have smoke alarms been installed in the residential premises in accordance with the Environmental Planning and Assessment Act 1979 (including any regulations made under that Act)</div>
                                                                    <div><Switch checked={switchValues.SmokeAlarmInstalled} onChange={(e) => onChangeSwitch(e, "SmokeAlarmInstalled")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Have all the smoke alarms installed on the residential premises been checked and found to be in working order?</div>
                                                                    <div><Switch checked={switchValues.SmokeAlarmWorking} onChange={(e) => onChangeSwitch(e, "SmokeAlarmWorking")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Date last checked</div>
                                                                    <div><TextBox value={switchValues.SmokeAlaramLastChecked} colVal={12} label="" type="date" name="SmokeAlaramLastChecked" onChange={(e) => onChangeSwitch(e.target.value, "SmokeAlaramLastChecked")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Have the removable batteries in all the smoke alarms been replaced within the last 12 months, except for removable lithium batteries?</div>
                                                                    <div><Switch checked={switchValues.SmokeAlarmBttryReplaced} onChange={(e) => onChangeSwitch(e, "SmokeAlarmBttryReplaced")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Date batteries were last changed:</div>
                                                                    <div><TextBox value={switchValues.BattryChangedDate} colVal={12} label="" type="date" name="BattryChangedDate" onChange={(e) => onChangeSwitch(e.target.value, "BattryChangedDate")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Have the batteries in all the smoke alarms that have a removable lithium battery been replaced in the period specified by the manufacturer of the smoke alarm?</div>
                                                                    <div><Switch checked={switchValues.HaveRemovableBattry} onChange={(e) => onChangeSwitch(e, "HaveRemovableBattry")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Date batteries were last changed:</div>
                                                                    <div><TextBox value={switchValues.RemovableBattryChangedDate} colVal={12} type="date" label="" name="RemovableBattryChangedDate" onChange={(e) => onChangeSwitch(e.target.value, "RemovableBattryChangedDate")} /></div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    } />
                                                <Collapse
                                                    id={"tenant"}
                                                    title={`Other Safety Issues`}
                                                    data={
                                                        <div style={{ padding: "0px 30px" }}>
                                                            <div >
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Are there any visible signs of damaged appliances (if appliances are included as part of the tenancy)?</div>
                                                                    <div><Switch checked={switchValues.VisibleSIgnDamage} onChange={(e) => onChangeSwitch(e, "VisibleSIgnDamage")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Are there any visible hazards relating to electricity (e.g. a loose or damaged electricity outlet socket, loose wiring or sparking power points)?</div>
                                                                    <div><Switch checked={switchValues.VisibleElectricityHazard} onChange={(e) => onChangeSwitch(e, "VisibleElectricityHazard")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Are there any visible hazards relating to gas (e.g. a loose or damaged gas outlet socket or an open-ended gas pipe or valve)?</div>
                                                                    <div><Switch checked={switchValues.VisibleGasHazard} onChange={(e) => onChangeSwitch(e, "VisibleGasHazard")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Does the tenant agree with all of the above?</div>
                                                                    <div><Switch checked={switchValues.TenantAgreeSafetyIssues} onChange={(e) => onChangeSwitch(e, "TenantAgreeSafetyIssues")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" >
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">If no, specify which items:</div>
                                                                    <div><TextArea colVal={12} label="" name="AdditionalTermSafetyIssues" value={switchValues.AdditionalTermSafetyIssues} onChange={(e) => onChangeSwitch(e.target.value, "AdditionalTermSafetyIssues")} /></div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    } />
                                                <Collapse
                                                    id={"tenant"}
                                                    title={`Communications Facilities`}
                                                    data={
                                                        <div style={{ padding: "0px 30px" }}>
                                                            <div >
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">(a) a telephone line is connected to the residential premises</div>
                                                                    <div><Switch checked={switchValues.TelephoneConnectd} onChange={(e) => onChangeSwitch(e, "TelephoneConnectd")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">(b) an internet line is connected to the residential premises</div>
                                                                    <div><Switch checked={switchValues.InternetConnected} onChange={(e) => onChangeSwitch(e, "InternetConnected")} /></div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    } />
                                                <Collapse
                                                    id={"tenant"}
                                                    title={`Communications Facilities`}
                                                    data={
                                                        <div style={{ padding: "0px 30px" }}>
                                                            <div >
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Are the residential premises separately metered?</div>
                                                                    <div><Switch checked={switchValues.SeperatedMetered} onChange={(e) => onChangeSwitch(e, "SeperatedMetered")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">(a) all showerheads have a maximum flow rate of 9 litres per minute</div>
                                                                    <div><Switch checked={switchValues.ShowerheadMaximumFlow} onChange={(e) => onChangeSwitch(e, "ShowerheadMaximumFlow")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">(b) on and from 23 March 2025, all toilets are dual flush toilets with a minimum 3 star rating in accordance with the WELS scheme</div>
                                                                    <div><Switch checked={switchValues.DualFlushToilet} onChange={(e) => onChangeSwitch(e, "DualFlushToilet")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">(c) all internal cold water taps and single mixer taps in kitchen sinks or bathroom hand basins have a maximum flow rate of 9 litres per minute</div>
                                                                    <div><Switch checked={switchValues.ColdWatrTapsMaximumFlow} onChange={(e) => onChangeSwitch(e, "ColdWatrTapsMaximumFlow")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">(d) the premises have been checked and any leaking taps or toilets on the residential premises have been fixed</div>
                                                                    <div><Switch checked={switchValues.CheckedWaterLeakage} onChange={(e) => onChangeSwitch(e, "CheckedWaterLeakage")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Date the premises were last checked to see if it is compliant with the water efficiency measures:</div>
                                                                    <div><TextBox value={switchValues.WaterEfficiencyDateChecked} colVal={12} label="" name="WaterEfficiencyDateChecked" type="date" onChange={(e) => onChangeSwitch(e.target.value, "WaterEfficiencyDateChecked")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Water meter reading at START of tenancy: Lph</div>
                                                                    <div><TextBox value={switchValues.WatermeterReadingStart} colVal={12} label="" name="WatermeterReadingStart" onChange={(e) => onChangeSwitch(e.target.value, "WatermeterReadingStart")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Date of reading:</div>
                                                                    <div><TextBox value={switchValues.DateofWaterMeterReadingStart} colVal={12} label="" type="date" name="DateofWaterMeterReadingStart" onChange={(e) => onChangeSwitch(e.target.value, "DateofWaterMeterReadingStart")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Water meter reading at END of tenancy: Lph</div>
                                                                    <div><TextBox value={switchValues.WatermeterReadingEnd} colVal={12} label="" name="WatermeterReadingEnd" onChange={(e) => onChangeSwitch(e.target.value, "WatermeterReadingEnd")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Date of reading:</div>
                                                                    <div><TextBox value={switchValues.DateOdWaterMeterReadingEnd} colVal={12} label="" type="date" name="DateOdWaterMeterReadingEnd" onChange={(e) => onChangeSwitch(e.target.value, "DateOdWaterMeterReadingEnd")} /></div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    } />
                                                <Collapse
                                                    id={"tenant"}
                                                    title={`Additional comments`}
                                                    data={
                                                        <div style={{ padding: "0px 30px" }}>
                                                            <div >
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" >
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Additional comments on minimum standards, health issues, smoke alarms, other safety issues, communication facilities, water usage charging and efficiency devices. (May be added by landlord or tenant, or both)</div>
                                                                    <div><TextArea colVal={12} label="" name="AdditionalComments" value={switchValues.AdditionalComments} onChange={(e) => onChangeSwitch(e.target.value, "AdditionalComments")} /></div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    } />
                                                <Collapse
                                                    id={"tenant"}
                                                    title={`Approximate dates when work last done on residential premises`}
                                                    data={
                                                        <div style={{ padding: "0px 30px" }}>
                                                            <div >
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Installation, repair or maintenance of smoke alarms</div>
                                                                    <div><TextBox colVal={12} type="date" name="LastDatePaintingExternal" value={switchValues.LastDatePaintingExternal} onChange={(e) => onChangeSwitch(e.target.value, "LastDatePaintingExternal")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Painting of premises (external)</div>
                                                                    <div><TextBox colVal={12} type="date" name="LastDatePaintingInternal" value={switchValues.LastDatePaintingInternal} onChange={(e) => onChangeSwitch(e.target.value, "LastDatePaintingInternal")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Painting of premises (internal)</div>
                                                                    <div><TextBox colVal={12} type="date" name="LastDateInstalledSmokeAlarms" value={switchValues.LastDateInstalledSmokeAlarms} onChange={(e) => onChangeSwitch(e.target.value, "LastDateInstalledSmokeAlarms")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">Floor laid / replaced / cleaned</div>
                                                                    <div><TextBox colVal={12} type="date" name="LastDateFloorCleaned" value={switchValues.LastDateFloorCleaned} onChange={(e) => onChangeSwitch(e.target.value, "LastDateFloorCleaned")} /></div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    } />
                                                <Collapse
                                                    id={"tenant"}
                                                    title={`Landlord's promise to undertake work: [Delete if not required]`}
                                                    data={
                                                        <div style={{ padding: "0px 30px" }}>
                                                            <div >
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" >
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">The landlord agrees to undertake the following cleaning, repairs, additions or other work during the tenancy:</div>
                                                                    <div><TextArea colVal={12} label="" name="LandlordAgreeToUndrtakeWork" value={switchValues.LandlordAgreeToUndrtakeWork} onChange={(e) => onChangeSwitch(e.target.value, "LandlordAgreeToUndrtakeWork")} /></div>
                                                                </div>
                                                                <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '100%' }} className="mr-5 s1">The landlord agrees to complete that work by:</div>
                                                                    <div><TextBox colVal={12} type="date" name="LandLordWorkDoneBy" value={switchValues.LandLordWorkDoneBy} onChange={(e) => onChangeSwitch(e.target.value, "LandLordWorkDoneBy")} /></div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    } />
                                            </div>
                                        }
                                        footer={[
                                            <div key="footer" style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <button style={{ marginRight: "20px" }} className="pdb-save-changes mt-2" onClick={SubmitGenerateReportEntery}>
                                                    Submit
                                                </button>
                                            </div>,
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewInspection;
