import React, { useEffect, useState } from "react";
import "./propertiesagency.css";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../input-components/selectBoxNew";
import { createPropertyAddress } from "../../../components/CommonSection/apiresources";
import { Dropdown } from "primereact/dropdown";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { PropertyDetailsSchema } from "../../../components/CommonSection/Schema/schema";
import AddressInput from "../../input-components/address";
import { message, Switch } from "antd";
import PropertyAttributes from "./propertyAttributes";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import ToggleTabs from "../../input-components/toggleButtonTabs";

const initialValues = {
  propertyFor: "",
  propertyType: "",
  assignedTo: "",
  address: "",
  unitNumber: "",
  streetNumber: "",
  streetName: "",
  suburb: "",
  postCode: "",
  state: "",
};
const PropertyDetailsAgency = ({
  onDataRecive,
  handleparentsuccess,
  getDetails,
  reportTo,
  handleCurrentPage,
  resetAll,
  cancel,
}) => {
  const [address, setAddress] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [propertyId, setPropertyId] = useState(0);
  const [viewAddressDetails, setViewAddressDetails] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [edit, setEdit] = useState(true);
  const [activeKey, setActiveKey] = useState("1");
  const [tabValue, setTabValue] = useState("address");
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const tabs = [
    { label: "Address", value: "address" },
    { label: "Attributes", value: "attributes" },
  ];
  const [isArchive, setIsArchive] = useState(false);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");

        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
        } else {
          navigate("/signin");
        }
      } catch (error) {
        console.error("Error retrieving data:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (getDetails) {
      setEdit(false);
      setValues({ ...values, ...getDetails });
      setFieldValue("propertyType", parseInt(getDetails?.propertyType));
      setFieldValue("address", getDetails?.getFormatedAddress);
      setLatitude(getDetails?.propertyGooglePlacesLocationLat);
      setLongitude(getDetails?.propertyGooglePlacesLocationLong);
      setSelectedCity(getDetails?.agentId?.toString());
      setPropertyId(getDetails?.propertyId);
      setFieldValue("assignedTo", getDetails?.agentId);
      setIsArchive(getDetails.isPropertyLost);
    }
  }, [getDetails, reportTo]);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: PropertyDetailsSchema,
    onSubmit: (values) => {
      handleFinalSubmit(values);
    },
  });

  useEffect(() => {
    if (getDetails) {
    } else {
      resetForm(); // Resets the form to initialValues
    }
  }, [resetAll, resetForm]);

  const propertyTypeOptions = [
    { label: "House", value: 7 },
    { label: "Apartment", value: 3 },
    { label: "Flat", value: 6 },
    { label: "Acreage/Semi-rural", value: 1 },
    { label: "Alpine", value: 2 },
    { label: "Block Of Units", value: 4 },
    { label: "Duplex/Semi-detached", value: 5 },
    { label: "Retirement Living", value: 8 },
    { label: "Serviced", value: 9 },
    { label: "Studio", value: 10 },
    { label: "Terrace", value: 11 },
    { label: "Town House", value: 12 },
    { label: "Unit", value: 13 },
    { label: "Villa", value: 14 },
    { label: "Ware House", value: 15 },
    { label: "Land", value: 16 },
    { label: "Other", value: 17 },
  ];

  const handleAddressSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const details = results[0];
      const fullFormattedAddress = details.formatted_address;
      const addressComponents = extractAddressComponents(details);

      setLatitude(details.geometry.location.lat());
      setLongitude(details.geometry.location.lng());

      setAddress(fullFormattedAddress);
      setValues({
        ...values,
        ...addressComponents,
        address: fullFormattedAddress,
      });
      setFieldValue("address", fullFormattedAddress);
    } catch (error) {
      console.error("Error fetching address details:", error);
    }
  };

  const extractAddressComponents = (details) => {
    const addressComponents = {};
    details.address_components.forEach(({ types, long_name }) => {
      debugger

      if (types.includes("subpremise"))
        addressComponents.unitNumber = long_name.replace(/Unit\s?/i, "").trim();
      if (types.includes("street_number"))
        addressComponents.streetNumber = long_name;
      if (types.includes("route")) addressComponents.streetName = long_name;
      if (types.includes("locality")) addressComponents.suburb = long_name;
      if (types.includes("postal_code")) addressComponents.postCode = long_name;
      if (types.includes("administrative_area_level_1"))
        addressComponents.state = long_name;
    });

    return addressComponents;
  };

  const handleFinalSubmit = () => {
    if (validateFields()) {
      const formData = {
        PropertyId: propertyId || 0,
        PropertySaleRental: values.propertyFor,
        PropertyType: values.propertyType,
        AgentId: parseInt(selectedCity, 10),
        AgencyId: agencyID,
        PropertyGooglePlacesLocation: values.address,
        State: values.state,
        Suburb: values.suburb,
        PostCode: values.postCode,
        UnitNumber: values.unitNumber,
        StreetNumber: values.streetNumber,
        StreetName: values.streetName,
        UserId: userId,
        PropertyGooglePlacesLocationLong: longitude,
        PropertyGooglePlacesLocationLat: latitude,
        IsPropertyLost: isArchive,
      };

      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        createPropertyAddress,
        "post",
        formData
      )
        .then((returnData) => {
          handleApiResponse(returnData);
        })
        .catch((error) => {
          message.error(error.message);
        });
    }
  };

  const validateFields = () => {
    const requiredFields = [
      { field: "streetNumber", message: "Street Number is required" },
      { field: "streetName", message: "Street Name is required" },
      { field: "suburb", message: "Suburb Name is required" },
      { field: "state", message: "State Name is required" },
      { field: "postCode", message: "PostCode Name is required" },
    ];

    for (let { field, message } of requiredFields) {
      if (values[field] === "") {
        handleparentsuccess(["error", message]);
        return false;
      }
    }
    return true;
  };

  const handleApiResponse = (returnData) => {
    debugger;
    if (returnData.status === ActionStatus.Successfull) {
      updateUrl(returnData.sUID);
      setTabValue("attributes");
      message.success(returnData.message);
    } else {
      message.error(returnData.message);
    }
  };

  const updateUrl = (newValue) => {
    const currentURL = window.location.href;
    let updatedURL;

    if (newValue) {
      if (currentURL.includes("?") || currentURL.includes("propertyUID")) {
        updatedURL = currentURL.split("=")[0] + "=" + newValue;
      } else {
        updatedURL = currentURL + "?propertyUID=" + newValue;
      }

      history.pushState(null, null, updatedURL);
    }
  };

  const handleCancel = () => {
    const url = new URL(window.location.href);
    const propertyUId = url.searchParams.get("propertyUID");

    if (propertyUId) {
      handleCurrentPage("Overview");
    } else {
      dynamicUrl(navigate, "propertiesList");
    }
  };

  const handleAssignedTo = (e) => {
    setSelectedCity(e.value);
    setFieldValue("assignedTo", e.value);
  };

  const handleTabChange = (activeTab) => {
    setTabValue(activeTab);
  };

  const handleToggle = (checked) => {
    setIsArchive(checked);
  };

  const url = new URL(window.location.href);
  const propertyUId = url.searchParams.get("propertyUID");

  return (
    <div>
      <div style={{ width: "100%" }} className="mt-2 mb-2">
        <ToggleTabs
          tabs={tabs}
          onTabChange={handleTabChange}
          externalTabValue={tabValue}
        />
      </div>
      {tabValue === "address" ? (
        <form onSubmit={handleSubmit} className="details-form">
          <div className="row">
            <SelectBox
              isMandatory
              label="Property Type"
              name="propertyType"
              options={propertyTypeOptions}
              placeholder="Property Type"
              value={values.propertyType}
              onChange={(val) => setFieldValue("propertyType", val.value)}
              error={errors.propertyType}
              touched={touched.propertyType}
            />
            <AddressInput
              labelName="Address"
              address={values.address}
              setAddress={(addr) => {
                setFieldValue("address", addr);
              }}
              handlePressSelect={handleAddressSelect}
              error={errors.address}
              touched={touched.address}
              isMandatory
              handleView={() => setViewAddressDetails(!viewAddressDetails)}
            />
            <TextBox
              label="Unit Number"
              placeholder="Unit Number"
              id="unitNumber"
              name="unitNumber"
              value={values.unitNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.unitNumber}
              touched={touched.unitNumber}
            />
            <TextBox
              label="Street Number"
              isMandatory
              placeholder="Street Number"
              id="streetNumber"
              name="streetNumber"
              value={values.streetNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.streetNumber}
              touched={touched.streetNumber}
            />
            <TextBox
              label="Street Name"
              isMandatory
              placeholder="Street Name"
              id="streetName"
              name="streetName"
              value={values.streetName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.streetName}
              touched={touched.streetName}
            />
            <TextBox
              label="Suburb"
              isMandatory
              placeholder="Suburb"
              id="suburb"
              name="suburb"
              value={values.suburb}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.suburb}
              touched={touched.suburb}
            />
            <TextBox
              label="State"
              isMandatory
              placeholder="State"
              id="state"
              name="state"
              value={values.state}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.state}
              touched={touched.state}
            />
            <TextBox
              label="Postcode"
              isMandatory
              placeholder="Postcode"
              id="postCode"
              name="postCode"
              value={values.postCode}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.postCode}
              touched={touched.postCode}
            />
            <div className="cda-reportTo col-lg-6 col-sm-12">
              <label htmlFor="trading state" className="s1 w-100">
                Assigned To
              </label>
              <Dropdown
                value={selectedCity}
                onChange={handleAssignedTo}
                options={reportTo}
                optionLabel="text"
                placeholder="Assigned To"
                style={{
                  width: "100%",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                }}
              />
              {errors.assignedTo && touched.assignedTo && (
                <p className="form-error">{errors.assignedTo}</p>
              )}
            </div>
            {propertyUId ?
            <div className="mt-3">
              <div>
                <label
                  style={{
                    marginRight: "10px",
                    fontWeight: "500",
                    color: "#616161",
                  }}
                >
                  Is Archive
                </label>
                <Switch checked={isArchive} onChange={handleToggle} />
              </div>
            </div> : null }
          </div>

          <div className="property-details-buttons">
            <button className="pdb-cancel" type="reset" onClick={cancel}>
              Cancel
            </button>
            <button className="pdb-save-changes" type="submit">
              Next
            </button>
          </div>
        </form>
      ) : null}
      {tabValue === "attributes" ? (
        <PropertyAttributes
          handleCurrentPage1={handleCurrentPage}
          onDataRecive1={onDataRecive}
          getDetails={getDetails}
          cancel={cancel}
        />
      ) : null}
    </div>
  );
};

export default PropertyDetailsAgency;
