import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { updatesalesagrmntexpenseschargedetails } from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { addParametersForProfiles, dynamicUrl, } from "../../../Utilities/profileManagement";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Switch, Checkbox } from "antd";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import "../openHome/openHome.css";
import Collapse from "../../input-components/collapse";
import { BiDollar } from "react-icons/bi";

dayjs.extend(customParseFormat);


const initialValues = {
    ExpenseANDChargesService1: '',
    ExpenseANDChargesEstimate1: '',
    ExpenseANDChargesdue1: '',
    ExpenseANDChargesService2: '',
    ExpenseANDChargesEstimate2: '',
    ExpenseANDChargesdue2: '',
    OtherServicesService1: '',
    OtherServicesGST1: '',
    OtherServicesdue1: '',
    OtherServicesService2: '',
    OtherServicesGST2: '',
    OtherServicesdue2: '',
    Gst: true,

    ExpenseANDChargesEstimateGST1: '',
    ExpenseANDChargesEstimateGST2: ''
};

const ExpensesAndCharges = ({ onDataRecive, handleparentsuccess, handleCurrentPage, getDetails, reportTo, handleNextPage }) => {
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const ToastRef = useRef();


    useEffect(() => {
        if (getDetails) {
            debugger;
            setValues({ ...values, ...getDetails });
            setFieldValue("ExpenseANDChargesService1", getDetails.expenseANDChargesService1)
            setFieldValue("ExpenseANDChargesEstimate1", getDetails.expenseANDChargesEstimate1)
            setFieldValue("ExpenseANDChargesService2", getDetails.expenseANDChargesService2)
            let gstInclusiveRemuneration = calculateGSTInclusive(getDetails.expenseANDChargesEstimate1, 10);
            let gstInclusiveRemuneration2 = calculateGSTInclusive(getDetails.expenseANDChargesEstimate2, 10);
            setFieldValue("ExpenseANDChargesEstimateGST1", gstInclusiveRemuneration);
            setFieldValue("ExpenseANDChargesEstimateGST2", gstInclusiveRemuneration2);
            setFieldValue("ExpenseANDChargesEstimate2", getDetails.expenseANDChargesEstimate2);
            setFieldValue("ExpenseANDChargesdue1", getDetails.expenseANDChargesdue1)
            setFieldValue("ExpenseANDChargesdue2", getDetails.expenseANDChargesdue2)
            setFieldValue("OtherServicesService1", getDetails.otherServicesService1)
            setFieldValue("OtherServicesGST1", getDetails.otherServicesGST1)
            setFieldValue("OtherServicesdue1", getDetails.otherServicesdue1)
            setFieldValue("OtherServicesService2", getDetails.otherServicesService2)
            setFieldValue("OtherServicesGST2", getDetails.otherServicesGST2)
            setFieldValue("OtherServicesdue2", getDetails.otherServicesdue2)
        }
    }, [getDetails, reportTo]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
    }, []);

    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
    } = useFormik({
        initialValues: initialValues,
        //validationSchema: salesExpensed,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });

    const handleFinalSubmit = (data) => {
        let formData = {
            AgreementId: getDetails.id,
            ExpenseANDChargesService1: values.ExpenseANDChargesService1,
            ExpenseANDChargesEstimate1: parseInt(values.ExpenseANDChargesEstimate1),
            ExpenseANDChargesdue1: values.ExpenseANDChargesdue1,
            ExpenseANDChargesService2: values.ExpenseANDChargesService2,
            ExpenseANDChargesEstimate2: parseInt(values.ExpenseANDChargesEstimate2),
            ExpenseANDChargesdue2: values.ExpenseANDChargesdue2,
            OtherServicesService1: values.OtherServicesService1,
            OtherServicesGST1: parseInt(values.OtherServicesGST1),
            OtherServicesdue1: values.OtherServicesdue1,
            OtherServicesService2: values.OtherServicesService2,
            OtherServicesGST2: parseInt(values.OtherServicesGST2),
            OtherServicesdue2: values.OtherServicesdue2,
            ExpenseANDChargesEstimateGST1: values.ExpenseANDChargesEstimateGST1,
            ExpenseANDChargesEstimateGST2: values.ExpenseANDChargesEstimateGST2
        };
        const url = updatesalesagrmntexpenseschargedetails;
        formData = addParametersForProfiles(formData);
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    onDataRecive(getDetails.propertyUId)
                    handleNextPage("advertisingAndPromotions")
                    ToastRef.current.notify(["success", returnData.message]);
                    //navigate(`/agency/ListOpenHome`);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const handleClear = () => {
        handleNextPage("Remunation")
    };

    const onChangeSwitch = (e, label) => {
        if (label === "Redirect") {
            setFieldValue("Redirect", e);
        }
    };

    const onChangeRadio = (e, label) => {
        if (label === "STRATA") {
            setFieldValue("STRATA", e.target.checked);
        } else if (label === "Water") {
            setFieldValue("Water", e.target.checked);
        } else if (label === "CouncilRates") {
            setFieldValue("councilRates", e.target.checked);
        } else if (label === "Insurance") {
            setFieldValue("Insurance", e.target.checked);
        }
    };

    const handleCancle = () => {
        navigate("/agency/listagreementtype");
    }

    const handleGst = (e) => {
        setFieldValue("Gst", e)
        if (e) {
            let mainValue = values.ExpenseANDChargesEstimate1
            let gstInclusiveRemuneration = calculateGSTInclusive(mainValue, 10);
            setFieldValue("ExpenseANDChargesEstimateGST1", gstInclusiveRemuneration);
        } else if (e === false) {
            setFieldValue("ExpenseANDChargesEstimateGST1", 0);
        }
    }

    function calculateGSTInclusive(originalAmount, gstRate) {
        let initialRemuneration = originalAmount;
        let gstDecimal = gstRate / 100;
        let gstAmount = initialRemuneration * gstDecimal;
        //let gstInclusiveAmount = initialRemuneration + gstAmount;

        return gstAmount.toFixed(2);
    }

    const handleChangeAmt = (e) => {
        const value = e.target.value;
        setFieldValue("ExpenseANDChargesEstimate1", value);
        if (values.Gst) {
            //let mainValue = getDetails.ExpenseANDChargesEstimate1 
            let gstInclusiveRemuneration = calculateGSTInclusive(value, 10);
            setFieldValue("ExpenseANDChargesEstimateGST1", gstInclusiveRemuneration);
        } else {
            //let mainValue = getDetails.ExpenseANDChargesEstimate1 
            let estimatePrice = (value / 100)
            setFieldValue("ExpenseANDChargesEstimateGST1", estimatePrice.toFixed(2));
        }

    }

    const handleChangeAmt2 = (e) => {
        const value = e.target.value;
        setFieldValue("ExpenseANDChargesEstimate2", value);
        if (values.Gst) {
            //let mainValue = getDetails.ExpenseANDChargesEstimate1 
            let gstInclusiveRemuneration = calculateGSTInclusive(value, 10);
            setFieldValue("ExpenseANDChargesEstimateGST2", gstInclusiveRemuneration);
        } else {
            //let mainValue = getDetails.ExpenseANDChargesEstimate1 
            let estimatePrice = (value / 100)
            setFieldValue("ExpenseANDChargesEstimateGST2", estimatePrice.toFixed(2));
        }

    }

    return (
        <>
            <div className="dashboard-agency">
                <ToastAlert ref={ToastRef} />
                <div className="continer-dbag">
                    <div className="dashboardagency-content">
                        <div style={{ width: "100%" }}>
                            <form onSubmit={handleSubmit} className="details-form">
                                <div style={{ width: '100%' }}>

                                    <div style={{ width: '100%' }}>
                                        <Collapse
                                            id={"EXPENSES AND CHARGES"}
                                            title={"EXPENSES AND CHARGES"}
                                            data={
                                                <div>
                                                    <div>
                                                        <div className="pddpn-group pd-eventDate col-lg-5 col-sm-12 mt-3 pl-5 mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <div className="s1" style={{ marginRight: '20px' }} >Include GST</div>
                                                                <div><Switch checked={values.Gst} onChange={handleGst} /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                        <TextBox
                                                            colVal={4}
                                                            label="Services"
                                                            placeholder="Services"
                                                            name="ExpenseANDChargesService1"
                                                            value={values.ExpenseANDChargesService1}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.ExpenseANDChargesService1}
                                                            touched={touched.ExpenseANDChargesService1}
                                                        />
                                                        <TextBox
                                                            colVal={4}
                                                            label="Amount $"
                                                            placeholder="Amount $"
                                                            name="ExpenseANDChargesEstimate1"
                                                            value={values.ExpenseANDChargesEstimate1}
                                                            onChange={handleChangeAmt}
                                                            onBlur={handleBlur}
                                                            error={errors.ExpenseANDChargesEstimate1}
                                                            touched={touched.ExpenseANDChargesEstimate1}
                                                            extraDetails={values.ExpenseANDChargesEstimateGST1}
                                                            gst={values.Gst}
                                                        />

                                                        <TextBox
                                                            colVal={4}
                                                            label="When due and payable"
                                                            placeholder="When due and payable"
                                                            name="ExpenseANDChargesdue1"
                                                            value={values.ExpenseANDChargesdue1}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.ExpenseANDChargesdue1}
                                                            touched={touched.ExpenseANDChargesdue1}
                                                        />
                                                        <TextBox
                                                            colVal={4}
                                                            label=""
                                                            name="ExpenseANDChargesService2"
                                                            value={values.ExpenseANDChargesService2}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.ExpenseANDChargesService2}
                                                            touched={touched.ExpenseANDChargesService2}
                                                        />
                                                        <TextBox
                                                            colVal={4}
                                                            label=""
                                                            name="ExpenseANDChargesEstimate2"
                                                            value={values.ExpenseANDChargesEstimate2}
                                                            onChange={handleChangeAmt2}
                                                            onBlur={handleBlur}
                                                            error={errors.ExpenseANDChargesEstimate2}
                                                            touched={touched.ExpenseANDChargesEstimate2}
                                                            extraDetails={values.ExpenseANDChargesEstimateGST2}
                                                            gst={values.Gst}
                                                        />
                                                        <TextBox
                                                            colVal={4}
                                                            label=""
                                                            name="ExpenseANDChargesdue2"
                                                            value={values.ExpenseANDChargesdue2}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.ExpenseANDChargesdue2}
                                                            touched={touched.ExpenseANDChargesdue2}
                                                        />

                                                    </div>
                                                </div>
                                            } />
                                        <Collapse
                                            id={"EXPENSES AND CHARGES"}
                                            title={"OTHER SERVICES [Clause 4.1]"}
                                            data={
                                                <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                    <TextBox
                                                        colVal={4}
                                                        label="Services"
                                                        placeholder="Services"
                                                        name="OtherServicesService1"
                                                        value={values.OtherServicesService1}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.OtherServicesService1}
                                                        touched={touched.OtherServicesService1}
                                                    />
                                                    <TextBox
                                                        colVal={4}
                                                        label="$ (GST Inc)"
                                                        placeholder="$ (GST Inc)"
                                                        name="OtherServicesGST1"
                                                        value={values.OtherServicesGST1}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.OtherServicesGST1}
                                                        touched={touched.OtherServicesGST1}

                                                    />
                                                    <TextBox
                                                        colVal={4}
                                                        label="When due and payable"
                                                        placeholder="When due and payable"
                                                        name="OtherServicesdue1"
                                                        value={values.OtherServicesdue1}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.OtherServicesdue1}
                                                        touched={touched.OtherServicesdue1}
                                                    />
                                                    <TextBox
                                                        colVal={4}
                                                        label=""
                                                        name="OtherServicesService2"
                                                        value={values.OtherServicesService2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.OtherServicesService2}
                                                        touched={touched.OtherServicesService2}
                                                    />
                                                    <TextBox
                                                        colVal={4}
                                                        label=""
                                                        name="OtherServicesGST2"
                                                        value={values.OtherServicesGST2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.OtherServicesGST2}
                                                        touched={touched.OtherServicesGST2}
                                                    />
                                                    <TextBox
                                                        colVal={4}
                                                        label=""
                                                        name="OtherServicesdue2"
                                                        value={values.OtherServicesdue2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.OtherServicesdue2}
                                                        touched={touched.OtherServicesdue2}
                                                    />
                                                    <div className="s1 pl-3 mt-2">Those services and amounts cannot be varied except with the agreement in writing of the Principal.</div>
                                                </div>
                                            } />
                                    </div>

                                </div>
                                <div className="property-details-buttons">
                                    <button
                                        className=" pdb-save-changes"
                                        type="reset"
                                        onClick={handleCancle}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="pdb-cancel"
                                        type="reset"
                                        onClick={handleClear}
                                    >
                                        Previous
                                    </button>
                                    <button className="pdb-save-changes" type="submit">
                                        Save & Next
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExpensesAndCharges;
