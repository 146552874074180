import React, { useState, useEffect } from 'react';
import './navbar.css';
import { Button, Dropdown, Menu, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DownOutlined, SaveOutlined } from '@ant-design/icons';

const { Option } = Select;

const SubNavbar = (props) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(props.navTabs ? props.navTabs[0] : '');

  useEffect(() => {
    if (props.navTabs) {
      setSelectedTab(props.navTabs[0]);
    }
  }, [props.navTabs]);

  const goHome = () => {
    let data = props.sideModuleData;
    data[1] = { activeSub: "Dashboard", activeMain: "Dashboard" };
    props.fetchSidebarModule(data);
    navigate('/procurement/index/DashBoard');
  };

  const addNew = () => {
    props.onClickCreate();
  };

  const goBack = () => {
    if (typeof props.goback === 'function') {
      props.goback(); // Calls the function if goback is a function
    } else {
      navigate(-1); // Default to navigating back if goback is not a function
    }
  };

  const selectTab = (module) => {
    setSelectedTab(module);
    props.onClick(module);
  };

  const selectChange = (data) => {
    props.selectOnchange(data);
  };

  const {
    title,
    actionButton,
    backButton,
    createButton: addBtn,
    saveButton,
    extraData,
    newClass,
    selectOptions,
    actionMenuOptions,
    actionButtonName,
    goback,
    extraContent
  } = props;

  const selectOptionElements = selectOptions ? selectOptions.map((data) => (
    <Option key={data.value} value={data.value}>{data.option}</Option>
  )) : [];

  const menu = actionButton ? (
    <Menu onClick={(data) => props.actionMenuClick(data)}>
      {actionMenuOptions.map((data) => (
        <Menu.Item key={data.value}>{data.option}</Menu.Item>
      ))}
    </Menu>
  ) : null;

  const tabContent = props.navTabs ? props.navTabs.map((tab) => (
    <span
      key={tab}
      className={`tabHeader weight-400 font-8rem ${selectedTab === tab ? 'tabHeader-Active' : ''}`}
      onClick={() => selectTab(tab)}
    >
      {tab}
    </span>
  )) : [];

  return (
    <div className="material-sub-header">
      <div className="subHeader-subdivision-left">
        {goback !== false && (
          <div onClick={goBack} className="backButtonNavbar pointer mr-3">
            <img src="/assets/images/left-arroww.svg" className="pointer" style={{ width: '16px' }} alt="" />
          </div>
        )}
        {title && (
          <div>
            <label className="font-1 weight-500 mb-0 mr-5" style={{ marginLeft: newClass, fontWeight:'500' , fontSize:'16px', color:'#2C3E50', marginTop:"auto" }}>
              {title}
            </label>
          </div>
        )}
        {props.navTabs && (
          <div className="sub-header-box-2">
            {tabContent}
          </div>
        )}
      </div>
      <div className="subHeader-subdivision-right">
        {backButton && (
          <Button onClick={goHome} className="backToDashboard">
            Home
          </Button>
        )}
        {actionButton && (
          <Dropdown overlay={menu} className="ml-2">
            <Button>
              {actionButtonName} <DownOutlined />
            </Button>
          </Dropdown>
        )}
        {selectOptions && (
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder={props.selectName}
            onChange={selectChange}
          >
            {selectOptionElements}
          </Select>
        )}
        {addBtn && (
          <Button onClick={addNew} className="backToDashboard">
            {addBtn}
          </Button>
        )}
        {saveButton && (
          <Button type="primary" className="ml-2" onClick={props.saveButton} icon={<SaveOutlined />} size="default">
            Save
          </Button>
        )}
        {extraData && (
          <div>{extraContent}</div>
        )}
      </div>
    </div>
  );
};

export default SubNavbar;
