import React from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from '../sidebar/sidebar';
import Topbar from '../navbar/navbar';
import './layout.css';
import { currentProfile } from "../../components/CommonSection/Cookie/Session";

const Layout = ({ children }) => {
  const location = useLocation();
  const currentSideBar = currentProfile.get();
  // Check if the URL contains "sign"
  const hideSidebar = location.pathname === '/selection' || 
  location.pathname === '/emailverificationagent' || 
  location.pathname === '/home' || 
  location.pathname === '/listLandingPage' || 
  location.pathname === '/verifyagency' || 
  location.pathname === '/landingPageDetails'||
  location.pathname === '/JobPortal' ||
  location.pathname === '/JobPortalDetails' ||
  currentSideBar === 13 ||
  currentSideBar === 12;


  return (
    <div className="layout">
      {hideSidebar === true ? null : <Sidebar />}
      <div className={hideSidebar  ? "w-100" : "main-content"}>
        {<Topbar />}
        <div className="content-wrapper">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
