import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {getmaintenanceList,reviewedbyagent,} from "../../../../components/CommonSection/apiresources";
import {GetCookie,currentProfile,} from "../../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import "../../../../components/Dashboard/Dashboard-agent/AgentList.css";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { ActionStatus, eMaintenanceStatus } from "../../../../Utilities/enum";
import { dynamicUrl } from "../../../../Utilities/profileManagement";
import "../../../Components/openHome/openHome.css";
import { FormatDate } from "../../../../components/CommonSection/Utility/Utility";
import CustomModal from "../../../input-components/Modal";
import SelectBox from "../../../input-components/selectBoxNew";
import TextArea from "../../../input-components/textarea";
import SubNav from "../../../../components/CommonSection/Header/subHeader";
import AntdTableExample from "../../../input-components/dataTable";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import { BiPlus } from "react-icons/bi";
import { useSidebarContext } from "../../../../Utilities/sidebarcontext";
import { useReadNotificationContext } from '../../../../Utilities/readNotificationContext';

const ListMaintainance = () => {
  const [maintainancelist, setMaintainanceList] = useState([]);
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agentID, setAgentId] = useState("");
  const navigate = useNavigate();
  const ToastRef = useRef();
  const [searchText, setSearchText] = useState("");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayTable, setDisplaytable] = useState(true);
  const [reason, setReason] = useState("");
  const [reviewStatus, setReviewStatus] = useState("");
  const [listCount, setListCount] = useState({});
  const [rowData, setRowData] = useState({});
  const [reviewStatusArr, setReviewStatusArr] = useState([
    { value: 2, label: "Approved" },
    { value: 3, label: "Rejected" },
  ]);
  const [openModal, setOpenModal] = useState(false);
  const [purpose, setPurpose] = useState(0);
  const { sideBarData, getLoginDataforSideBar } = useSidebarContext(); 
  const { readNotificationApi } = useReadNotificationContext();
  
  const sidebarReferenceIds = new Set(
    sideBarData.flatMap(section => 
      section.items.flatMap(item => item.referenceIds)
    )
  );

  const handleReadNotification = (referenceId) => {
    readNotificationApi(referenceId, getLoginDataforSideBar) // Pass the function as a parameter
      .then(() => {
        console.log("Notification successfully marked as read.");
      })
      .catch((error) => {
        console.error("Failed to mark notification as read:", error);
      });
  };

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgentId(parsedData.agentId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) PostApiWithSpinner();
  }, [userId, searchText,purpose]);

  const PostApiWithSpinner = (currentPage, totalRows) => {
    setDisplaytable(false);
    const baseUrl = getmaintenanceList;
    let data = {
      AgencyId: agencyID,
      Search: searchText,
      Status: purpose
    };
    //data = addParametersForProfiles(data);
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        setDisplaytable(true);
        if (response.status === ActionStatus.Successfull) {
            let issueData = response.object.maintenanceDetails;
            let issueList = issueData.map((issueDetails) => ({
              Title: issueDetails.title,
              Description: issueDetails.description,
              CreatedDate: FormatDate(issueDetails.createdAt),
              PropertyAddress: issueDetails.propertyAddress,
              maintenanceUniqueId: issueDetails.maintenanceUniqueId,
              Status: issueDetails.sStatus,
              ReportType: issueDetails.sRepairType,
              maintainanceId: issueDetails.maintenanceId,
              eStatus: issueDetails.status,
              isNew : issueDetails.isNew,
            }));
            setMaintainanceList(issueList);
            setTotalPages(response.totalCount);
            setListCount(response.object)
        } else {
          ToastRef.current.notify(["error", response.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", response.message]);
      });
  };

  const handleFinalSubmit = () => {
    let formData = {
      MaintenanceId: rowData.maintainanceId,
      AgentId: parseInt(agentID),
      ReviewStatus: parseInt(reviewStatus),
      ReasonOfRejection: reason,
    };
    const url = reviewedbyagent;
    //formData = addParametersForProfiles(formData);
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          PostApiWithSpinner();
          setOpenModal(false);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handlePageChange = (event, pageNumber) => {
    PostApiWithSpinner(event.page + 1, event.rows);
    setCurrentPage(pageNumber);
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleEditClick = (e,rowData) => {
    e.stopPropagation()
    e.preventDefault()
    setReason("");
    setReviewStatus(null);
    setRowData(rowData);
    setOpenModal(true);
  };

  const handleListClick = (e,rowData) => {
    e.stopPropagation()
    e.preventDefault()
    // navigate("/ListQuote" + `?maintainanceId=${rowData.maintenanceUniqueId}`);
    dynamicUrl(navigate,"ListQuote" + "?maintainanceId=" + rowData.maintenanceUniqueId);

  };

  const actions = [
    { icon: "/assets/images/pen.svg", onClick: (e, data) => handleEditClick(e, data),condition: (record) => eMaintenanceStatus.Approved > record.eStatus  },
    { icon: "/assets/images/list.svg", onClick: (e, data) => handleListClick(e, data) },
]

  const handleInputChange = (e, label) => {
    if (label === "reason") {
      setReason(e.target.value);
    } else if (label === "status") {
      setReviewStatus(e.value);
    }
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleRowClick = (e, rowData) => {
      e.stopPropagation()
      e.preventDefault()
      handleReadNotification(rowData.maintainanceId)
      dynamicUrl(navigate,"ViewIssueDetails" + "?maintenanceUId=" + rowData.maintenanceUniqueId + `&isNew=${rowData.isNew}&maintainanceId=${rowData.maintainanceId}`);
  };

  const tabColumns = {
    Status: {
      colorMap: {
        "Report Repair": "#3498db",
        "Repair Rejected": "#e74c3c",
        "Reschedule": "#f39c12",
        "Job Started": "#1abc9c",
        "Repair Approved": "#16a085",
        "Job Schedule": "#8e44ad",
        "Reported": "#2c3e50"
      },
      defaultColor: 'blue'
    }
};

const handleAddIssue = () => {
  dynamicUrl(navigate, "IssueDetails");
};

const handleStatClick = (selectedPurpose) => {
  setPurpose(selectedPurpose);
};

  return (
    <div>
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
            <SubNav title={"Maintenance"} goback={false} extraData={true} extraContent={
                <button
                  className="Add-Property-button"
                  type="submit"
                  onClick={handleAddIssue}
                >
                  <div className="text-parent">
                    <BiPlus style={{ width: "25px", height: "25px" }} />
                    <div className="text7" style={{ fontSize: "14px" }}>
                      Add
                    </div>
                  </div>
                </button>
              } />
            <div className="material-work-desk-new bg-white m-3">
            <ToastAlert ref={ToastRef} />
            <div style={{ borderBottom: '1px solid #e9ecef', padding: '10px' }}>
                            <div className="col-lg-8">
                            <span
                                    className={`col-lg-2 col-sm-2 stat-item ${purpose ===0 ? 'active' : ''}`}
                                    onClick={() => handleStatClick(0)}
                                >
                                    New <span className="count">{listCount.newCount}</span>
                                </span>
                                <span
                                    className={`col-lg-2 col-sm-2 stat-item ${purpose === 2 ? 'active' : ''}`}
                                    onClick={() => handleStatClick(2)}
                                >
                                    Active <span className="count">{listCount.activeCount}</span>
                                </span>
                                <span
                                    className={`col-lg-2 col-sm-2 stat-item ${purpose ===1 ? 'active' : ''}`}
                                    onClick={() => handleStatClick(1)}
                                >
                                    Rejected <span className="count">{listCount.rejectedCount}</span>
                                </span>
                                <span
                                    className={`col-lg-2 col-sm-2 stat-item ${purpose ===  10? 'active' : ''}`}
                                    onClick={() => handleStatClick(10)}
                                >
                                    Completed <span className="count">{listCount.completedCount}</span>
                                </span>
                            </div>
                        </div>
                <AntdTableExample
                    label="List Maintainance"
                    dataSource={maintainancelist}
                    globalSearch
                    hideCol={"maintainanceId,maintenanceUniqueId,ReportType,eStatus,isNew"}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                    totalRows={rows}
                    totalRecords={totalPages}
                    actions={actions}
                    onRowClick={handleRowClick}
                    tagColumns={tabColumns}
                    rowClassName={(record) => {
                      return sidebarReferenceIds.has(record.maintainanceId.toString()) ? "highlighted-row" : "";
                    }}
                />

<CustomModal
                  title="Review"
                  width={600}
                  closable={closeModal}
                  visible={openModal}
                  onCancel={closeModal}
                  modalData={
                    <div>
                      <div>
                        <SelectBox
                          colVal={12}
                          label="Review Status"
                          id="issueType"
                          name="issueType"
                          value={reviewStatus}
                          onChange={(e) => handleInputChange(e, "status")}
                          options={reviewStatusArr}
                        />
                        {reviewStatus === 3 ? (
                          <TextArea
                            colVal={12}
                            label="Reason"
                            id="Reason"
                            name="Reason"
                            value={reason}
                            onChange={(e) => handleInputChange(e, "reason")}
                          />
                        ) : null}
                      </div>
                    </div>
                  }
                  footer={[
                    <div
                      key="footer"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        style={{ marginRight: "20px" }}
                        className="pdb-save-changes mt-2"
                        onClick={handleFinalSubmit}
                      >
                        Submit
                      </button>
                    </div>,
                  ]}
                />
            </div>
        </div>
    </div>
</div>
  );
};

export default ListMaintainance;
