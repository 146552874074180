import React from "react";
import { useEffect, useState, useRef,useMemo  } from "react";
import { message, Spin,Select } from "antd";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {FilePath} from "../../../../components/CommonSection/apiresources";
import { GetCookie,currentProfile } from "../../../../components/CommonSection/Cookie/Session";
import TextBox from "../../../input-components/textbox";
import TextArea from "../../../input-components/textarea";
import {addupdatetenant,getmaintenancebyuniqueid,SearchPropertyForAddListing} from "../../../../components/CommonSection/apiresources";
import {makeRequestWithSpinner,uploadToS3WithSpinner} from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../Utilities/enum";
import { dynamicUrl } from "../../../../Utilities/profileManagement";
import './../maintainance.css'
import { TiDeleteOutline } from "react-icons/ti";
import SelectBox from "../../../input-components/selectBoxNew";
import SubNavbar from "../../../../components/CommonSection/Header/subHeader";
import debounce from "lodash/debounce";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";

const initialValues = {
  issueType: "",
  title: "",
  description: "",
  addImages: [],
  repairType: "",
  maintenanceId : 0,
};

const IssueDetails = () => {
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [isDragging, setIsDragging] = useState(false);
  const primaryDocumentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [propertyimageitems, setPropertyimageitems] = useState([]);
  const [propertyImages, setPropertyImages] = useState([]);
  const [currentProfileId, setCurrentProfileId] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [tenantId, setTenantId] = useState("");
  const ToastRef = useRef();
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null); // State to hold the selected value
  const currentUser = currentProfile.get();
  const fetchRef = useRef(0);
  const [agentId, setAgentId] = useState("");

  const [issueTypeData, setIssueTypeData] = useState([
    { value: 1, label: "Plumbing" },
    { value: 2, label: "Door/Window" },
    { value: 3, label: "Electric" },
    { value: 4, label: "Flooring/Stairs" },
    { value: 5, label: "General" },
    { value: 6, label: "Gas Plumbing" },
    { value: 7, label: "Roofing" },
    { value: 8, label: "Cleaner" },
    { value: 9, label: "Aircon repair" },
    { value: 10, label: "Painter" },
  ]);
  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          console.log(parsedData,'parsedData')
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setCurrentProfileId(parsedData.currentProfile)
          setTenantId(parsedData.tenantId)
          setPropertyId(parsedData.propertyId)
          setAgentId(parsedData.agentId);
        } else {
          navigate("/signin");
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  var url = new URL(window.location.href);
  var maintenanceUId = url.searchParams.get("maintenanceUId");

  useEffect(() => {
    var url = new URL(window.location.href);
    var maintenanceUId = url.searchParams.get("maintenanceUId");
    if(maintenanceUId){
        getIssueDetails(maintenanceUId)
    }
  }, []);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
        handleFinalSubmit();
      },
  });

  const getIssueDetails = (maintenanceUId) => {
    const url = getmaintenancebyuniqueid + `?maintenaceUId=${maintenanceUId}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "get")
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
            setFieldValue("title" , returnData.object.title),
            setFieldValue("description" , returnData.object.description)
            const propertyImageItems = returnData.object.photos?.map((src) => ({
                id:  Math.floor(Math.random() * 1000000),
                src: FilePath +  src,
                filePath : FilePath + src,
              }));
              setPropertyimageitems(propertyImageItems)
              setPropertyImages(returnData.object.photos)
              setFieldValue("addImages", returnData.object.photos)
              setFieldValue("repairType" ,returnData.object.repairType )
              setFieldValue("maintenanceId" , returnData.object.maintenanceId)
              setFieldValue("issueType" , returnData.object.issueType)
              let propertyData = {
                id:returnData.object.propertyId,
                label:returnData.object.propertyAddress
              }
              setSelectedValue(propertyData)
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleFinalSubmit = (data) => {
    console.log(agencyID,'agencyID')
    let formData = {
        MaintenanceId: values.maintenanceId,
        PropertyId: currentProfileId === 2 ? selectedValue.id : propertyId,
        AgencyId: agencyID,
        ReportedBy: currentProfileId,
        ReportedReference: currentProfileId === 13 ? tenantId :currentProfileId === 3 ? agentId : agencyID,
        IssueType: parseInt(values.issueType),
        Title: values.title,
        Description: values.description,
        Photos: propertyImages,
        CreatedBy: userId
    };
    const url = addupdatetenant;
    //formData = addParametersForProfiles(formData);
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);

          currentProfileId === 2 ? navigate(`/agency/ListMaintainanceRequest`) : navigate(`/tenant/ListIssueDetails`);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };

  const handleInputChange = (e, indexforfiles, documentType) => {
    const files = e.target.files;
    handleFileChange(files, indexforfiles, documentType);
  };

  const handleFileChange = async (files, indexforfiles, documentType) => {
    for (let i = 0; i < files.length; i++) {
      const selectedFile = files[i];
      await handleUpload(selectedFile, indexforfiles, documentType);
    }
  };

  const handleUpload = async (selectedFile) => {
    await uploadToS3WithSpinner(
      showSpinner,
      hideSpinner,
      "maintenance/",
      `maintenance`,
      selectedFile
    ).then((returnData) => {
      if (returnData.status === ActionStatus.Successfull) {
        const transformedItem = {
          id: Math.floor(Math.random() * 1000000),
          src: FilePath + returnData.Key,
          width: 1,
          height: 1,
        };
        setPropertyimageitems((prevItems) => [...prevItems, transformedItem]);
        setPropertyImages((prevDocuments) => [
          ...prevDocuments,
          returnData.Key,
        ]);
        message.success("File uploaded successfully")
        //ToastRef.current.notify(["success", "File uploaded successfully", true]);
      } else {
        message.success("Error uploading file. Please try again.")

        // ToastRef.current.notify([
        //   "error",
        //   "Error uploading file. Please try again.",
        // ]);
      }
    });
  };

  const handleFileUploader = () => {
    primaryDocumentRef.current.click();
  };

const handleDeleteImage = (id, src) => {
  // Extract the filename from the `src` URL
  const fileName = id.src.split('/').pop();
  // Update `propertyImages` by filtering out the matching filename
  setPropertyImages((prevImages) =>
    prevImages.filter((image) => !image.includes(fileName))
  );

  // Update `propertyImageItems` if needed (assuming `id` logic is separate)
  setPropertyimageitems((prevItems) =>
    prevItems.filter((item) => item.src !== id.src)
  );
};


  const handleGoBack = () => {
    navigate("/tenant/ListIssueDetails")
};

const debounceFetcher = useMemo(() => {
  const loadOptions = (value) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setOptions([]);
    setFetching(true);
    const baseUrl = SearchPropertyForAddListing;
    const data = {
      Search: value,
      RecordsPerPage: 10,
      PageNo: 1,
    };

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      baseUrl,
      "post",
      data,
      "",
      false
    )
      .then((response) => {
        if (response.status === 1) {
          let landingPageListData = response.list;
          const transformedData = landingPageListData.map((item) => ({
            value: item.id,
            label: item.name,
            id: item.id,
            isRentalListed: item.isRentalListed,
            isSaleListed: item.isSaleListed,
            propertyUId: item.propertyUId,
          }));
          if (fetchId === fetchRef.current) {
            setOptions(transformedData);
            setFetching(false);
          }
        } else {
          setFetching(false);
        }
      })
      .catch((error) => {
        setFetching(false);
      });
  };
  return debounce(loadOptions, 800);
}, []);

const handleSearchProperty = (e, value, id) => {
  debounceFetcher(value);
  console.log(value,'pp')
  setSelectedValue(value);
};

  return (
    <div className="dashboard-agency">
      <div className="dashboardagency-content">
        <form
          onSubmit={handleSubmit}
          className="details-form"
          style={{ display: "initial", width: "100%" }}
        >
          <SubNavbar title={maintenanceUId ? "Update Repair Request" : "Create Repair Request"} goBack={handleGoBack} actionButton={false} extraData={true} />
          <div className="property-details-content df-all all-input">
          <ToastAlert ref={ToastRef} />
          <div className="contacts-group" style={{ padding: "0px 30px" }}>
           {currentUser === 2 &&
            <div className="pd-title col-lg-6 col-sm-12">
            <label className="s1">Select Property</label>
                  <div style={{ display: "flex" }}>
                    <Select
                      style={{ height: "40px", width: "100%" }}
                      className="landingPageSearchs"
                      mode="single"
                      showSearch
                      placeholder="Search Property"
                      labelInValue
                      filterOption={false}
                      onSearch={debounceFetcher}
                      notFoundContent={fetching ? <Spin size="small" /> : null}
                      options={options}
                      value={selectedValue}
                      onChange={(label, value, listingId) =>
                        handleSearchProperty(label, value, listingId)
                      }
                      allowClear
                    />
                     </div>
            </div>}
              <SelectBox
                label="Issue Type"
                placeholder="Issue Type"
                id="issueType"
                name="issueType"
                value={values.issueType}
                onChange={(val) => setFieldValue("issueType", val.value)}
                onBlur={handleBlur}
                error={errors.issueType}
                touched={touched.issueType}
                options={issueTypeData}
              />
              <TextBox
                label="Title"
                placeholder="Title"
                id="title"
                name="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.title}
                touched={touched.title}
              />
              <TextArea
                label="Description Issue"
                placeholder="Description"
                id="description"
                name="description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.description}
                touched={touched.description}
              />
              <div className={`contacts-group ${propertyimageitems.length > 2 ? "col-md-10" : "col-md-6"}`}>
                <div className="s1 mb-2">Add Images</div>
                <div
                  className={`upload-section1 ${
                    isDragging ? "dragging" : ""
                  }`}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  onDragOver={handleDragOver}
                >
                  <Spin spinning={loading}>
                    <div className="upload-setion-input">
                      <div>
                        <img
                          src="../../../assets/images/upload.png"
                          alt="upload-icon"
                          className="upload-icon"
                        />
                        <span
                          className="upload-select"
                          onClick={handleFileUploader}
                        >
                          Click to upload
                        </span>
                        <input
                          ref={primaryDocumentRef}
                          type="file"
                          onChange={(e) => handleInputChange(e, 0)}
                          className="upload-input"
                          name="upload"
                          multiple
                        />
                        or drag or drop
                      </div>
                    </div>
                  </Spin>
                  <div>
                    {/* Display uploaded images */}
                    <div className="uploaded-images-container">
                      {propertyimageitems.map((item) => (
                        <div key={item.id} className="uploaded-image-wrapper">
                          <img
                            src={item.src}
                            alt={`Property Image ${item.id}`}
                            className="uploaded-image"
                          />
                          <TiDeleteOutline
                            className="delete-icon1"
                            onClick={() => handleDeleteImage(item)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="property-details-buttons">
            <button className="pdb-cancel" onClick={handleGoBack}>
              Cancel
            </button>
            <button className="pdb-save-changes" type="submit">
              Submit
            </button>
          </div>
            </div>
          </div>

         
        </form>
      </div>
    </div>
  );
};

export default IssueDetails;
