import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SubNav from "../../../../components/CommonSection/Header/subHeader";
import VerticalProgressive from "../../../input-components/verticalProgressivebar";
import { GetCookie, currentProfile } from "../../../../components/CommonSection/Cookie/Session";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { dynamicUrl } from "../../../../Utilities/profileManagement";
import { getmaintenancebyuniqueid, FilePath, addjobschedule, reviewjobschedule, completemaintenance, reviewedbyagent, addupdatemessagethread, defaultProfilePic, getsupplierslistwithtype,addgenericnotes,getgenericnotes } from "../../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner, uploadToS3WithSpinner } from "../../../../Utilities/client";
import { ActionStatus, eMaintenanceStatus } from "../../../../Utilities/enum";
import "./../maintainance.css";
import CustomModal from "../../../input-components/Modal";
import AntDatePicker from "../../../input-components/datePicker";
import moment from 'moment';
import { Table, Checkbox, Steps } from 'antd';
import { BiPencil } from "react-icons/bi";
import SelectBox from "../../../input-components/selectBoxNew";
import ImageGalleryModal from "../../../input-components/imageGallary";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import TextArea from "../../../input-components/textarea";
import DocumentUpload from "../../../input-components/documentUpload";
import { FiSend } from "react-icons/fi";
import CustomActivity from "../../../input-components/customActivity";

const ViewIssueDetails = () => {
    let selectedFile;
    const [userId, setUserId] = useState("");
    const [userUId, setUserUId] = useState("");
    const [issueDetails, setIssueDetails] = useState({});
    const [activeStep, setActiveStep] = useState(0);
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const ToastRef = useRef();
    const [openSchedule, setOpenSchedule] = useState(false);
    const [openReSchedule, setOpenReSchedule] = useState(false);
    const [date, setDate] = useState("")
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [userProfile, setUserProfile] = useState('')
    const urlParams = new URLSearchParams(window.location.search);
    const maintenanceUId = urlParams.get("maintenanceUId");
    const maintenanceId = urlParams.get("maintainanceId");
    const isNew = urlParams.get("isNew");
    const [reviewStatusArr, setReviewStatusArr] = useState([
        { value: 1, label: "Accepted" },
        { value: 5, label: "Cancelled" },
    ]);
    const [reviewStatus, setReviewStatus] = useState("");
    const [records, setRecords] = useState({})
    const [btnName, setBtnName] = useState("");
    const [galleryImages, setGallaryImages] = useState([]);
    const [galleryImagesJob, setGallaryImagesJob] = useState([]);
    const [activeTab, setActiveTab] = useState("");
    const [activeTab1, setActiveTab1] = useState("Activity");
    const [openModal, setOpenModal] = useState(false);
    const [agentId, setAgentId] = useState('');
    const [agencyId, setAgencyId] = useState('');
    const [reason, setReason] = useState("");
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const [inputMessageNote, setInputMessageNote] = useState("");
    const tabs = ["Approved", "Rejected"];
    const inputRef = useRef();
    const [propertyImages, setPropertyImages] = useState("");
    const [propertyimageitems, setPropertyimageitems] = useState([]);
    const [supplierslistwithtype, setSupplierslistwithtype] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [showSuppliers, setShowSuppliers] = useState(true);
    const [notesList, setNotesList] = useState([]);

    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    console.log
                    setUserUId(parsedData.userUnique_ID);
                    setUserId(parsedData.userId);
                    setUserProfile(parsedData.currentProfile);
                    setAgentId(parsedData.agentId);
                    setAgencyId(parsedData.agencyId);
                    getNotes(parsedData.agencyId);
                } else {
                    navigate("/signin");
                }
            } catch (error) {
                console.error("Error retrieving data from AsyncStorage:", error);
            }
        };
        retrieveData();
    }, [userId]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const maintenanceUId = urlParams.get("maintenanceUId");
        const isNew = urlParams.get("isNew");
        if (maintenanceUId) {
            getIssueDetails(maintenanceUId, isNew);
        }
        window.scrollTo(0, 0);
    }, []);


    const getIssueDetails = (maintenanceUId, isNew) => {
        const url = `${getmaintenancebyuniqueid}?maintenaceUId=${maintenanceUId}&UpdateNewFlag=${isNew == null ? false : isNew}`;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "get")
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    setIssueDetails(returnData.object);

                    let images = returnData.object?.photos?.map(item => `${FilePath}${item}`);
                    setGallaryImages(images);

                    let imagesJob = returnData.object?.jobDetails?.jobPhotos?.map(item => `${FilePath}${item}`);
                    setGallaryImagesJob(imagesJob)

                    const steps = returnData.object.trackDetails?.trackActionDetails || [];

                    // Find the first incomplete step or set to the last step if all are completed
                    const currentStep = steps.findIndex(step => !step.isCompleted);
                    setActiveStep(currentStep !== -1 ? currentStep : steps.length - 1);
                    let status = returnData.object.status
                    setBtnName(status === 9 ? "Work Completed" : '')

                    const sortedMessages = [...returnData.object?.additionNotes].sort((a, b) =>
                        new Date(a.createdAt) - new Date(b.createdAt)
                    );
                    setMessages(sortedMessages);
                } else {
                    //ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                //ToastRef.current.notify(["error", error.message]);
            });
    };

    const stepsData =
        issueDetails.trackDetails?.trackActionDetails?.map((detail) => ({
            time: `${detail.actionTime &&
                detail.actionTime !== "0001-01-01T00:00:00"
                ? new Date(detail.actionTime).toLocaleString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                })
                : ""
                }${detail.userName ? ` by ${detail.userName}` : ""}`, // Only include "by" if userName exists
            title: detail.action, // Action description
            isCompleted: detail.isCompleted, // Completion status
        })) || [];


    const currentSideBar = currentProfile.get();

    const handleGoBack = () => {
        dynamicUrl(navigate, currentSideBar === 13 ? "ListIssueDetails" : "ListMaintainanceRequest");
    };

    const closeSchedule = () => {
        setOpenSchedule(false)
        setOpenReSchedule(false)
    }

    const handleSchedule = () => {
        setOpenSchedule(true)
        setDate("")
        setStartTime("")
        setEndTime("")
        setReviewStatus("")
    }

    const handleDate = (date, dateString) => {
        setDate(date)
    }

    const handleTimeChange = (times, label) => {
        if (label === "Start") {
            setStartTime(times)
        } else if (label === "End") {
            setEndTime(times)
        }
    };

    const handleFinalSubmit = (data) => {
        let formData = {
            JobId: issueDetails.jobDetails.jobId,
            ScheduledDate: date,
            ScheduledStartTime: startTime,
            ScheduledEndTime: endTime,
            ScheduledByUserType: userProfile,
            UserUniqueId: userUId,
            LastScheduleId: records.scheduleId,
            LastScheduleStatus: reviewStatus,
        };
        if (!openReSchedule || reviewStatus !== 5) {
            delete formData.LastScheduleId;
            delete formData.LastScheduleStatus;
        }
        const url = addjobschedule;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData, null, false)
            .then((returnData) => {
                debugger
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    setOpenSchedule(false)
                    getIssueDetails(maintenanceUId);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const handleApprove = (data) => {
        let formData = {
            ScheduleId: records.scheduleId,
            Status: reviewStatus,
        };
        const url = reviewjobschedule;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData, null, false)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    setOpenSchedule(false)
                    getIssueDetails(maintenanceUId);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const handleActions = (record) => {
        setOpenReSchedule(true);
        setOpenSchedule(true);
        setDate("")
        setStartTime("")
        setEndTime("")
        setReviewStatus(null)
        setRecords(record)
    }


    const columns = [
        {
            title: 'Scheduled Date',
            dataIndex: 'scheduledDate',
            key: 'scheduledDate',
            render: (text) => moment(text).format('DD-MM-YYYY'), // Format date
        },
        {
            title: 'Start Time',
            dataIndex: 'scheduledStartTime',
            key: 'scheduledStartTime',
            render: (text) => moment(text).format('HH:mm A'), // Format start time
        },
        {
            title: 'End Time',
            dataIndex: 'scheduledEndTime',
            key: 'scheduledEndTime',
            render: (text) => moment(text).format('HH:mm A'), // Format end time
        },
        {
            title: 'Schedule By',
            dataIndex: 'sScheduleBy',
            key: 'scheduledEndTime',
        },
        {
            title: 'Schedule To',
            dataIndex: 'sScheduleTo',
            key: 'sScheduleTo',
        },
        {
            title: 'Status',
            dataIndex: 'sStatus',
            key: 'sStatus',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
                    {record.scheduledToUserType === userProfile && record.status === null ?
                        <BiPencil style={{ fontSize: '22px', cursor: 'pointer' }} onClick={() => handleActions(record)} />
                        : null}
                </span>
            ),
        },
    ];

    const handleInputChange = (e, label) => {
        setReviewStatus(e.value);
    };

    const handleJobs = (data) => {
        let formData = {
            MaintenanceId: issueDetails?.maintenanceId,
            Status: eMaintenanceStatus.WorkCompleted,
        };
        const url = completemaintenance;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData, null, false)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    getIssueDetails(maintenanceUId);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const handleApproveReject = (tab, bool) => {
        setActiveTab(tab)
        let formData = {
            MaintenanceId: maintenanceId,
            AgentId: parseInt(agentId),
            ReviewStatus: tab === "Approved" ? 2 : 3,
            ReasonOfRejection: reason,
            NotifySuppliers: showSuppliers,
            SuppliersIDs: selectedValues.length > 0 ? selectedValues : null,
        };
        const url = reviewedbyagent;
        //formData = addParametersForProfiles(formData);
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    getIssueDetails(maintenanceUId, isNew);
                    setOpenModal(false);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const handleEditClick = () => {
        setReason("");
        setOpenModal(true);
    };

    const handleReason = (e, label) => {
        setReason(e.target.value);
    };

    const closeModal = () => {
        setOpenModal(false);
    };

    const sendMessage = (data) => {
        const payload = {
            Id: 0,
            ModuleType: 2,
            ModuleReference: issueDetails.maintenanceId,
            UserType: userProfile,
            UserId: userId,
            Message: inputMessage,
            ReceipientType: userProfile === 2 ? issueDetails.reportedBy : 2,
            ReceipientReference: userProfile === 2 ? issueDetails.reportedReference : issueDetails.agencyId,
            PhotoPaths: propertyImages
        };
        const url = addupdatemessagethread;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", payload, null, false)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    setInputMessage("");
                    getIssueDetails(maintenanceUId, isNew);
                    setPropertyImages("")
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const select = (event) => {
        inputRef.current.click();
    };

    const handleUplodeImg = async (e, indexforfiles) => {
        for (let i = 0; i < e.target.files.length; i++) {
            selectedFile = e.target.files[i];
            await handleUpload();
        }
    };

    const handleUpload = async () => {
        await uploadToS3WithSpinner(
            showSpinner,
            hideSpinner,
            "messagethreads/",
            `messagethreads_${issueDetails.propertyId}`,
            selectedFile
        ).then((returnData) => {
            if (returnData.status == ActionStatus.Successfull) {
                const fileName1 = returnData.Key.split('/').pop();
                const transformedItem = {
                    id: Math.floor(Math.random() * 1000000),
                    src: FilePath + returnData.Key,
                    width: 1,
                    height: 1,
                    fileName: fileName1
                };
                setPropertyimageitems((prevItems) => [...prevItems, transformedItem]);
                setPropertyImages((prevDocuments) =>
                    prevDocuments
                        ? `${prevDocuments},${returnData.Key}` // Append new path to the existing string
                        : returnData.Key // If empty, initialize with the first file path
                );
            } else {
                // handleparentsuccess([
                //   "error",
                //   "Error uploading file. Please try again.",
                // ]);
            }
        });
    };

    const handleImagesSorted = (sortedImages) => {
        const relativePaths = sortedImages.map((image) => {
            const url = image.src; // Get the `src` property of each image object
            const parts = url.split(".com/"); // Split the URL at '.com/'
            return parts[1]; // Get the relative part after '.com/'
        });
        setPropertyImages(relativePaths)
    };

    // const handleSendNotificationClick = (e) => {
    //     setShowSuppliers(e.target.checked); // Show or hide the supplier list
    //     if (e.target.checked) {
    //         getsupplierslistDetails(); // Call the API when checked
    //     } else {
    //         setSupplierslistwithtype([]); // Clear the supplier list when unchecked
    //     }
    // };


    const getsupplierslistDetails = (maintenanceUId, isNew) => {
        let formData = {
            AgencyId: issueDetails.agencyId,
            IssueType: issueDetails.issueType
        }
        const url = getsupplierslistwithtype;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData, null, false)
            .then((returnData) => {
                debugger

                if (returnData.status === 0) {
                    setSupplierslistwithtype(returnData.list)
                } else {
                    //ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                //ToastRef.current.notify(["error", error.message]);
            });
    };


    const handleCheckboxChange = (checkedValues) => {
        setSelectedValues(checkedValues);
    };

    const tabs1 = ["Activity", "Message", "Schedule","Notes","Trades"];

    const handleTabs = (tabs) => {
        setActiveTab1(tabs);
        if(tabs === "Trades"){
            getsupplierslistDetails(); 
        }
    }

    const handleAddNotes = () => {
        let formData = {
            ModuleType: 2,
            ModuleReference: maintenanceId,
            UserId: userId,
            AgencyId: agencyId,
            Note: inputMessageNote,
        };
        const url = addgenericnotes;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    getNotes(agencyId);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const getNotes = (agencyId) => {
        let formData = {
            ModuleType: 2,
            ModuleReference: maintenanceId,
            AgencyId: agencyId,
        };
        const url = getgenericnotes;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    setNotesList(returnData.list)
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const columnsNotes = [
       
        {
          title: "Note",
          dataIndex: "note",
          key: "note",
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text) => moment(text).format("DD-MM-YYYY HH:mm:ss"), // Format the date and time
          },
      ];

        const handleEdit = (id) => {
          dynamicUrl(navigate, "IssueDetails" + "?maintenanceUId=" + id);
        };

    return (
        <div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
                    <SubNav
                        title={"View Repair Details"}
                        goBack={handleGoBack}
                        extraData={true}
                        extraContent={
                            <div>
                                {(currentSideBar === 2 || currentSideBar === 12) && btnName ? (
                                    <button
                                        style={{ marginRight: "20px" }}
                                        className="pdb-save-changes mt-2"
                                        onClick={handleJobs}
                                    >
                                        {btnName}
                                    </button>
                                ) : null}
                                {issueDetails.sStatus === "Report Repair" && currentSideBar !== 13 && (
                                    <div style={{ display: "flex", padding: "5px", background: '#eef2ff', margin: '5px 15px' }}>
                                        {tabs.map((tab) => (
                                            <div
                                                key={tab}
                                                className={`mr-1 button ${activeTab === tab ? "active-tab" : ""}`}
                                                onClick={() =>
                                                    tab === "Approved" ? handleApproveReject(tab) : handleEditClick(tab)
                                                }
                                            >
                                                {tab}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        }
                    />
                    <div className="material-work-desk-new bg-white m-3">
                        <ToastAlert ref={ToastRef} />
                        <div className="content-section d-flex flex-column">
                            <div className="main-view-label">
                                <div className="pl-2 d-flex align-items-center">
                                    <div className="labelMaintainance mr-2" style={{ color: '#70c1d0' }}>
                                        Job No:
                                    </div>
                                    <div>
                                        {issueDetails.jobTrackingId}
                                    </div>
                                </div>
                                <div className="pl-2 d-flex align-items-center">
                                <div className="labelMaintainance mr-2" style={{ color: '#70c1d0' }}>
                                        Issue Type:
                                    </div>
                                    <div>
                                        {issueDetails.sIssueType}
                                    </div>
                                </div>
                                <div className="pl-2 d-flex align-items-center">
                                <div className="labelMaintainance mr-2" style={{ color: '#70c1d0' }}>
                                        Status:
                                    </div>
                                    <div>
                                        {issueDetails.sStatus}
                                    </div>
                                </div>
                                <div className="pl-2 d-flex align-items-center">
                                    <div>
                                        {issueDetails.propertyAddress}
                                    </div>
                                </div>
                                <BiPencil title="Edit" style={{ fontSize: '22px', cursor: 'pointer' }} onClick={()=>handleEdit(issueDetails.maintenanceUniqueId)}/>
                            </div>
                            <div className="main-section d-flex">
                                <div className="section-left pl-5 pr-5 pb-5 pt-1">
                                    <div className="pl-2 mb-4 d-flex">
                                        <div className="w-50 mr-3">
                                            <div className="labelMaintainance mb-3" style={{ fontSize: '20px' }}>{issueDetails.title}</div>
                                            <div >{issueDetails.description}</div>
                                        </div>
                                        <div className="section-right">
                                        {galleryImages?.length > 0 ? 
                                            <div className="image-container">
                                                {/* Job Images Section */}
                                                <div className="image-label">Images</div>
                                                <div className="uploaded-images-container">
                                                    <ImageGalleryModal images={galleryImages } />
                                                </div>
                                            </div>:null}
                                           {galleryImagesJob?.length > 0 ? 
                                            <div className="image-container">
                                                {/* Job Images Section */}
                                                <div className="image-label">Job Images</div>
                                                <div className="uploaded-images-container">
                                                    <ImageGalleryModal images={galleryImagesJob } />
                                                </div>
                                            </div>:null}
                                        </div>

                                    </div>
                                    {/* Additional Left Section Content */}
                                    {issueDetails.sStatus === "Repair Rejected" && (
                                        <div className="pl-2 d-flex align-items-center">
                                            <div className="col-lg-2 col-sm-6 labelMaintainance mb-3 pl-0">
                                                Rejected Reason
                                            </div>
                                            <div
                                                className="col-lg-6 col-sm-10 mb-3 pl-0"
                                                style={{ wordBreak: "break-word" }}
                                            >
                                                {issueDetails.reasonOfRejection}
                                            </div>
                                        </div>
                                    )}
                                    {issueDetails.status >= 3 && (
                                        <>
                                            <div className="pl-2 d-flex align-items-center">
                                                <div className="col-lg-2 col-sm-6 labelMaintainance mb-3 pl-0">
                                                    Assign to
                                                </div>
                                                <div className="col-lg-6 col-sm-10 mb-3 pl-0">
                                                    {issueDetails.tradesmanName}
                                                </div>
                                            </div>
                                            <div className="pl-2 d-flex align-items-center">
                                                <div className="col-lg-2 col-sm-4 labelMaintainance mb-3 pl-0">
                                                    Contact Number
                                                </div>
                                                <div className="col-lg-6 col-sm-10 mb-3 pl-0">
                                                    {issueDetails.tradesmanContact}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                  
                                </div>


                            </div>

                            <div className="w-75 pl-4">

                                <div style={{ display: "flex", padding: "5px", background: '#f8f9fa', margin: '5px 25px' }}>
                                    {tabs1.map((tab) => (
                                        <div
                                            key={tab}
                                            className={`button ${activeTab1 === tab ? "active-tab" : ""}`}
                                            onClick={() => handleTabs(tab)}
                                        >
                                            {tab}
                                        </div>
                                    ))}
                                </div>
                                {activeTab1 === "Activity" && (
                                    <div className="p-5">
                                        <CustomActivity ModuleType={5} RecordId={maintenanceId}/>
                                    </div>)}
                                {activeTab1 === "Message" && (
                                    <div className="message-tab-container mr-5 ml-5 mt-2">
                                        {/* Message Display Section */}
                                        <div className="message-display-container">
                                            {messages.length > 0 ? (
                                                <div className="messages-list">
                                                    {messages.map((msg) => (
                                                        <div
                                                            key={msg.id}
                                                            className={`message-item ${msg.isSender ? "sent-message" : "received-message"}`}
                                                        >
                                                            {/* Sender Details */}
                                                            <div className="sender-info">
                                                                <img
                                                                    src={msg.profilePic ? `${FilePath}${msg.profilePic}` : `${FilePath}${defaultProfilePic}`}
                                                                    alt="User"
                                                                    className="sender-avatar"
                                                                />
                                                                <div className="sender-details">
                                                                    <span className="sender-name">{msg.createdBy}</span>
                                                                    <span className="message-timestamp">
                                                                        {new Date(msg.createdAt).toLocaleString("en-GB", {
                                                                            weekday: "short",
                                                                            day: "numeric",
                                                                            month: "short",
                                                                            year: "numeric",
                                                                            hour: "2-digit",
                                                                            minute: "2-digit",
                                                                            second: "2-digit",
                                                                            timeZoneName: "short",
                                                                        })}
                                                                    </span>
                                                                    <div className="message-text">{msg.message}</div>

                                                                </div>

                                                            </div>

                                                            {/* Message Text */}

                                                            {/* Attachments */}
                                                            {msg.photoPaths?.length > 0 && (
                                                                <div className="message-attachments">
                                                                    {msg.photoPaths.split(",").map((photoPath, index) => (
                                                                        <img
                                                                            key={index}
                                                                            src={`${FilePath}${photoPath.trim()}`}
                                                                            alt={`attachment-${index}`}
                                                                            className="attachment-thumbnail"
                                                                        />
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div className="no-messages">No messages to display</div>
                                            )}
                                        </div>

                                        {/* Input Section */}
                                        <div className="message-input-section">
                                            <div className="message-input-container input-wrapper">
                                                <TextArea
                                                    colVal={12}
                                                    className="message-input"
                                                    placeholder="Type your message..."
                                                    value={inputMessage}
                                                    onChange={(e) => setInputMessage(e.target.value)}
                                                />
                                                <DocumentUpload
                                                    label=""
                                                    inputRef={inputRef}
                                                    onClickHandler={select}
                                                    handleFileChange={handleUplodeImg}
                                                    images={propertyimageitems}
                                                    setImages={setPropertyimageitems}
                                                    onImagesSorted={handleImagesSorted}
                                                    attachment={true}
                                                />
                                            </div>
                                            <button className="send-button" >
                                                <FiSend onClick={sendMessage} />
                                            </button>
                                        </div>

                                        {/* Supplier Approval Section */}
                                        {issueDetails.sStatus !== "Repair Approved" && currentSideBar !== 13 && (
                                            <div className="supplier-approval-section">
                                                <Checkbox
                                                    className="approval-checkbox"
                                                    style={{ color: "cadetblue", fontWeight: "600" }}
                                                >
                                                    Approve & Send Notification to Supplier
                                                </Checkbox>

                                                {showSuppliers && (
                                                    <div className="supplier-list">
                                                        <Checkbox.Group
                                                            value={selectedValues}
                                                            onChange={handleCheckboxChange}
                                                            className="supplier-checkbox-group"
                                                        >
                                                            {supplierslistwithtype.map((item) => (
                                                                <Checkbox key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </Checkbox>
                                                            ))}
                                                        </Checkbox.Group>
                                                        <button
                                                            className="approve-send-button"
                                                            onClick={() => handleApproveReject("Approved", true)}
                                                        >
                                                            Approve & Send
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}

                                {activeTab1 === "Schedule" && (
                                    <div className="table-section ml-4 mr-4">
                                        {issueDetails?.jobSchedules?.length > 0 && (
                                            <Table
                                                dataSource={issueDetails.jobSchedules}
                                                columns={columns}
                                                pagination={false} // Disable pagination if only showing one row
                                                scroll={{
                                                    x: "max-content",
                                                }}
                                            />
                                        )}
                                    </div>)}
                                {activeTab1 === "Notes" && (
                                    <div className="pl-5 pt-3 pr-5">
                                        <TextArea
                                            label="Add Note"
                                            colVal={12}
                                            className="message-input"
                                            placeholder="Type your message..."
                                            value={inputMessageNote}
                                            onChange={(e) => setInputMessageNote(e.target.value)}
                                        />
                                        <div className="mb-3" style={{ width: '100px', float: 'right', marginRight: '10px' }} >
                                            <button className="send-buttons" onClick={() => handleAddNotes()}>
                                                Send
                                            </button>
                                        </div>

                                       {notesList.length > 0 ?<Table dataSource={notesList} columns={columnsNotes} rowKey={(record, index) => index} pagination={false} /> : null }
                                    </div>
                                )}
                                    {activeTab1 === "Trades" && (
                                    <div className="pl-5 pt-3 pr-5">
                                        {issueDetails.sStatus !== "Repair Approved" && (
                                            <div >
                                                {currentSideBar !== 13 && (<div style={{ color: "cadetblue", fontWeight: '600' }}>Approve & Send Notification to Supplier</div>)}

                                               
                                                    <Checkbox.Group
                                                        style={{ display: "flex", flexDirection: "column", marginTop: "15px" }}
                                                        value={selectedValues}
                                                        onChange={handleCheckboxChange}
                                                    >
                                                        {supplierslistwithtype.map((item) => (
                                                            <Checkbox key={item.id} value={item.id}>
                                                                {item.name}
                                                            </Checkbox>
                                                        ))}
                                                    </Checkbox.Group>
                                                

                                                    <div style={{ width: '100px', float: 'right' }} >
                                                        <button className="send-buttons" onClick={() => handleApproveReject("Approved", true)}>
                                                            Send
                                                        </button>
                                                    </div>

                                            </div>)}
                                    </div>
                                   )}
                            </div>

                            <CustomModal
                                title="Review"
                                width={600}
                                closable={closeModal}
                                visible={openModal}
                                onCancel={closeModal}
                                modalData={
                                    <div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <TextArea
                                                colVal={12} // Ensure this prop is used in the TextArea component
                                                label="Reason"
                                                id="Reason"
                                                name="Reason"
                                                value={reason}
                                                onChange={(e) => handleReason(e, "reason")}// Ensure proper value is passed
                                                placeholder="Enter reason for review..." // Optional: Add a placeholder for clarity
                                            />
                                        </div>
                                    </div>
                                }
                                footer={[
                                    <div
                                        key="footer"
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <button
                                            style={{ marginRight: "20px" }}
                                            className="pdb-save-changes mt-2"
                                            onClick={() => handleApproveReject("Rejected")}
                                        >
                                            Submit
                                        </button>
                                    </div>,
                                ]}
                            />

                            <CustomModal
                                title={reviewStatus === 5 ? "Re-Schedule" : "Schedule"}
                                width={600}
                                closable={closeSchedule}
                                visible={openSchedule}
                                onCancel={closeSchedule}
                                modalData={
                                    <div className="row" style={{ marginLeft: "10px" }}>
                                        {openReSchedule && (
                                            <div
                                                className="col-lg-12 col-xl-12"
                                                style={{ padding: "0px 40px 0px 35px", marginBottom: "-20px" }}
                                            >
                                                <SelectBox
                                                    colVal={12}
                                                    label="Review Status"
                                                    id="issueType"
                                                    name="issueType"
                                                    value={reviewStatus}
                                                    onChange={(e) => handleInputChange(e, "status")}
                                                    options={reviewStatusArr}
                                                />
                                            </div>
                                        )}
                                        {(reviewStatus === 5 || !openReSchedule) && (
                                            <>
                                                <AntDatePicker
                                                    colvalue={12}
                                                    label="Schedule Date"
                                                    value={date}
                                                    customeDateFormatDate="YYYY-MM-DD"
                                                    onChange={(date, dateString) => handleDate(date, dateString)}
                                                    disabledDate={(current) =>
                                                        current && current < moment().startOf("day")
                                                    }
                                                />
                                                <AntDatePicker
                                                    colvalue={12}
                                                    label="Start Time"
                                                    showTime
                                                    value={startTime}
                                                    onChange={(time, timeString) => handleTimeChange(time, "Start")}
                                                    disabledTime={() => ({
                                                        disabledHours: () => [
                                                            ...Array(8).keys(), // Disable hours before 8 AM
                                                            ...Array.from({ length: 24 - 18 }, (_, i) => i + 18), // Disable hours after 6 PM
                                                        ],
                                                        disabledMinutes: () => [], // Enable all minutes
                                                        disabledSeconds: () => [], // Enable all seconds
                                                    })}
                                                />
                                                <AntDatePicker
                                                    colvalue={12}
                                                    label="End Time"
                                                    showTime
                                                    value={endTime}
                                                    onChange={(time, timeString) => handleTimeChange(time, "End")}
                                                    disabledTime={() => ({
                                                        disabledHours: () => [
                                                            ...Array(8).keys(), // Disable hours before 8 AM
                                                            ...Array.from({ length: 24 - 18 }, (_, i) => i + 18), // Disable hours after 6 PM
                                                        ],
                                                        disabledMinutes: () => [], // Enable all minutes
                                                        disabledSeconds: () => [], // Enable all seconds
                                                    })}
                                                />
                                            </>
                                        )}
                                    </div>
                                }
                                footer={
                                    <div
                                        key="footer"
                                        style={{ display: "flex", justifyContent: "flex-end" }}
                                    >
                                        <button
                                            style={{ marginRight: "20px" }}
                                            className="pdb-save-changes mt-2"
                                            onClick={reviewStatus === 1 ? handleApprove : handleFinalSubmit}
                                        >
                                            {reviewStatus === 5 ? "Update" : "Submit"}
                                        </button>
                                    </div>
                                }
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ViewIssueDetails;
