import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SubNav from "../../navbar/subNav";
import "../PropertiesAgency/ViewPropertyDetails.css";
import { addrentreceipt, propertyDetails } from "../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import ListActivies from "./listActivities"
import ListPropertiesActivity from "./listPropertyActivities"

const Activities = ({ }) => {
    const [userId, setUserId] = useState("");
    const [userUId, setUserUId] = useState("");
    const [agencyID, setAgencyId] = useState("");
    const [agencyUID, setAgencyUID] = useState("");
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("Contact Activities");
    const tabs = ["Contact Activities", "Property Activities"];



    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setUserUId(parsedData.userUnique_ID);
                    setUserId(parsedData.userId);
                    setAgencyId(parsedData.agencyID);
                    setAgencyUID(parsedData.agencyUniqueID);
                } else {
                    navigate("/signin");
                }
            } catch (error) {
                console.error("Error retrieving data:", error);
            }
        };
        retrieveData();
    }, [userId]);

    return (
        <div>
            <div className="col-12 px-0 changedateantdstyle">
                <div className="col-12 px-0 daily-date-style">
                    <SubNav title={"Activities"} goback={true} extraData={true} />
                    <div style={{ display: "flex", padding: "5px", background: 'white', margin: '5px 15px' }}>
                        {tabs.map((tab) => (
                            <div key={tab} className={`button ${activeTab === tab ? "active-tab" : ""}`} onClick={() => setActiveTab(tab)}>
                                {tab}
                            </div>
                        ))}
                    </div>
                    <div className="material-work-desk-new bg-white m-3">
                        {activeTab === "Contact Activities" ? <ListActivies/> : <ListPropertiesActivity/>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Activities;
