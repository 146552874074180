import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SubNav from "../../navbar/subNav";
import "./ViewPropertyDetails.css";
import CustomModal from "../../input-components/Modal";
import ShowValue from "../../input-components/ShowValue";
import TextBox from "../../input-components/textbox";
import SelectBox from "../../input-components/selectBoxNew";
import { addrentreceipt, propertyDetails, getAgreementDocumentListForProperty, generatePdfReport, generatetenantcheckreport, generatesalesagreementPdfReport, generateTenantAgreementPdfReport, FilePath,assignedTo,getmaintenanceListforportalforproperty,deletepropertylisting } from "../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner, DownloadFiletWithSpinner } from "../../../Utilities/client";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus, isManualFilePath, eAgreementTemplateType } from "../../../Utilities/enum";
import AddTenant from "./TenantPropertyAgency";
import AddTenantFolio from "./TenantFolio"
import { BiBath, BiBed, BiCar } from "react-icons/bi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import { Table, Button } from "antd";
import { DownloadOutlined, PlusOutlined,UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import ImageGalleryModal from "../../input-components/imageGallary";
import MainPropertyComponent from "./propertyModal"
import axios from "axios";
import PropertyDocumentsAgency from "./propertyDocuments";
import AntdTableExample  from "../../input-components/dataTable";
import { FormatDate } from "../../../components/CommonSection/Utility/Utility";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";

const ViewPropertDetails = ({ }) => {
    const [userId, setUserId] = useState("");
    const [userUId, setUserUId] = useState("");
    const [agencyID, setAgencyId] = useState("");
    const [agencyUID, setAgencyUID] = useState("");
    const navigate = useNavigate();
    const [ppenAddNewReceipt, setOpenAddNewReceipt] = useState(false);
    const [activeTab, setActiveTab] = useState("Overview");
    const [activeTabTenant, setActiveTabTenant] = useState("Tenant");
    const [rentData, setRentData] = useState([
        { allocation: "Rent", due: "21 Sep 2024", description: "Paid to 21/09/2024", amountDue: "13 days", amount: "" }
    ]);
    const [depositData, setDepositData] = useState([
        { description: "", amount: "" }
    ]);
    const [openAddNewtenant, setOpenAddNewtenant] = useState(false);

    const [date, setDate] = useState("");
    const [selectedPaymentMode, setSelectedPaymentMode] = useState("");
    const [amount, setAmount] = useState("");
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [getDetails, setGetDetails] = useState(null);
    const [docfile,setDocfile] = useState(null);
    const [ownerCollapse, setOwnerCollapse] = useState(true);
    const [listingCollapse, setListingCollapse] = useState(true);
    const [agreementCollapse, setAgreementCollapse] = useState(true);
    const [tenantCollapse, setTenantCollapse] = useState(true);
    const [galleryPropertyImages, setGallaryPropertyImages] = useState([]);
    const [galleryFloorImages, setGallaryFloorImages] = useState([])
    const [photosCollapse, setPhotosCollapse] = useState(true);
    const [legalCollapse, setLegalCollapse] = useState(true);
    const [maintainanceStatus,setMaintainanceStatus]  = useState(0);
    const [maintainanceList,setMaintainanceList]  = useState([]);
    const ePaymentModes = [
        { value: 1, label: "Cash" },
        { value: 2, label: "Cheque" },
        { value: 3, label: "Card" },
        { value: 4, label: "EFT" }
    ];
    const [listCount , setListCount] = useState({})
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
    var step = url.searchParams.get("step");

    const ToastRef = useRef();
    const [getDocDetails, setGetDocDetails] = useState(null);

    const [openAddProperty, setOpenAddProperty] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [resetAllPage, setResetAllPage] = useState(false);
    const [dpdata, setDpdata] = useState([]);
    const [tenantId , setTenantId] = useState(null);
    const [tenantEdit,setTenantEdit] = useState(false)
    const [tenantFolipCollapse,setTenantFolipCollapse]  = useState(true);
    const [listingType,setListingType]  = useState("");
    const [agentId,setAgentId]  = useState("");
    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setUserUId(parsedData.userUnique_ID);
                    setUserId(parsedData.userId);
                    setAgencyId(parsedData.agencyID);
                    setAgencyUID(parsedData.agencyUniqueID);
                    reportData(parsedData.agencyUniqueID);
                } else {
                    navigate("/signin");
                }
            } catch (error) {
                console.error("Error retrieving data:", error);
            }
        };
        retrieveData();
    }, [userId]);

    const reportData = (agencyUID) => {
        const apiUrl = assignedTo;
        const baseUrl = apiUrl + "?agencyUID=" + agencyUID;
        axios.get(baseUrl).then((response) => {
          if (response.data.object) setDpdata(response.data.object);
        });
      };


    useEffect(() => {
        getPropertyDetails(propertyUId);
        if(step){
            setOpenAddProperty(true);
            setCurrentStep(parseInt(step))
        }
    }, [])

    const getPropertyDetails = (value) => {
        const baseUrl = propertyDetails;
        const apiUrl = `${baseUrl}?propertyID=${value}`;
        makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get")
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    setGetDetails(response.object);
                    let PropertyImages = response.object?.propertyPics?.map(item => `${FilePath}${item}`);
                    let FloorImages = response.object?.floorPics?.map(item => `${FilePath}${item}`);
                    setGallaryPropertyImages(PropertyImages);
                    setGallaryFloorImages(FloorImages);
                    GetDocumentsApi(response.object)
                } else {
                    //handleparentsuccess(["warn", response.data.message]);
                }
            })
            .catch((error) => {
                //handleparentsuccess(["error", error]);
            });
    };

    const GetDocumentsApi = (data) => {
        const formData = {
            PropertyId: data.propertyId,
            AgencyId: data.agencyId
        }
        const baseUrl = getAgreementDocumentListForProperty;
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", formData)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    setGetDocDetails(response.list);
                } else {
                    //handleparentsuccess(["warn", response.data.message]);
                }
            })
            .catch((error) => {
                handleparentsuccess(["error", error]);
            });
    };


    const handleFinalSubmit = () => {
        const depositDescription = depositData.map(item => item.description).join(", ");
        const formData = {
            Id: 0,
            Type: 1,
            Date: date,
            PaymentMode: selectedPaymentMode ? selectedPaymentMode : "",
            Description: depositDescription,
            Amount: rentData.concat(depositData).reduce((total, item) => total + (Number(item.amount) || 0), 0),
            PropertyId: getDetails.PropertyId,
            AgencyId: agencyID,
            UserId: userId,
        };

        const url = addrentreceipt;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    //handleparentsuccess(["success", returnData.message]);
                } else {
                    //handleparentsuccess(["error", returnData.message]);
                }
            })
            .catch((error) => {
                //handleparentsuccess(["error", error.message]);
            });
    };

    const handleOpenReceipt = () => {
        setOpenAddNewReceipt(true);
    };

    const closeReceipt = () => {
        setOpenAddNewReceipt(false);
    };

    const handleInputChange = (index, field, value, type) => {
        const updatedData = type === "rent" ? [...rentData] : [...depositData];
        updatedData[index][field] = value;
        type === "rent" ? setRentData(updatedData) : setDepositData(updatedData);
    };

    const closeTenant = () => {
        setOpenAddNewtenant(false);
        getPropertyDetails(propertyUId);
        setActiveTabTenant("Tenant")
        setTenantEdit(false)
    }

    const handleOpenAddTenant = (id,edit) => {
        setTenantId(id)
        setOpenAddNewtenant(true)
        if(edit){
            setTenantEdit(true)
        }
    }


    const getTrueProperties = (details) => {
        if (!details || typeof details !== "object") {
            return "";
        }
        const trueProps = [];

        for (const [key, value] of Object.entries(details)) {
            if (value === true) {
                // Convert camelCase to human-readable format
                const formattedKey = key
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase());
                trueProps.push(formattedKey);
            }
        }

        return trueProps.join(", ");
    };

    const tabs = ["Overview", "Activity","Documents","Maintainance"];
    const tenantTabs = ["Tenant","Rent Details"]
    const primaryOwners = getDetails?.contactlistModel.filter((contact) => contact.isPrimary);
    const secondaryOwners = getDetails?.contactlistModel.filter((contact) => !contact.isPrimary);
    const primarytenantDetails = getDetails?.tenantDetails?.filter((tenant) => tenant.primaryTenant);
    const secondarytenantDetails = getDetails?.tenantDetails?.filter((tenant) => !tenant.primaryTenant);
    const tenantPortfolioDetails = getDetails?.tenantDetails?.[0]?.tenantFolioDetails;

    const saleListing = getDetails?.listingDetails.filter((listing) => listing.propertySaleRental === 1);
    const rentListing = getDetails?.listingDetails.filter((listing) => listing.propertySaleRental === 2);


    const handleOwner = () => {
        setOwnerCollapse(!ownerCollapse)
    }

    const handleAgreement = () => {
        setAgreementCollapse(!agreementCollapse)
    }

    const handleListings = () => {
        setListingCollapse(!listingCollapse)
    }

    const handleTenant = () => {
        setTenantCollapse(!tenantCollapse)
    }

    const handleTenantFolid = () => {
        setTenantFolipCollapse(!tenantFolipCollapse)
    }

    const handlePhotos = () => {
        setPhotosCollapse(!photosCollapse)
    }

    const handleLegal = () => {
        setLegalCollapse(!legalCollapse)
    }

    const handleDownload = (record) => {
        if (record.docType === eAgreementTemplateType.ManagementAgreement) {
            if (record.isManuallyAdded) {
                const fileUrl = `${FilePath}${record.agreementFilePath}`;
                window.open(fileUrl, "_blank");
            } else {
                handleAgreementDownload(record);
            }
        } else if (record.docType === eAgreementTemplateType.TenantCheck) {
            if (record.isManuallyAdded) {
                const fileUrl = `${FilePath}${record.agreementFilePath}`;
                window.open(fileUrl, "_blank");
            } else {
                handleTenantDownload(record, 10);
            }
        }
        else if (record.docType === eAgreementTemplateType.OwnerCheck) {
            if (record.isManuallyAdded) {
                const fileUrl = `${FilePath}${record.agreementFilePath}`;
                window.open(fileUrl, "_blank");
            } else {
                handleTenantDownload(record, 11);
            }
        } else if (record.docType === eAgreementTemplateType.ExclusiveAgencyAgreement) {
            if (record.isManuallyAdded) {
                const fileUrl = `${FilePath}${record.agreementFilePath}`;
                window.open(fileUrl, "_blank");
            } else {
                handleSaleAgreementDownload(record);
            }
        } else if (record.docType === eAgreementTemplateType.TenantLeaseAgreement) {
            if (record.isManuallyAdded) {
                const fileUrl = `${FilePath}${record.agreementFilePath}`;
                window.open(fileUrl, "_blank");
            } else {
                handleTenantAgreementDownload(record);
            }
        }
    };

    const handleTenantDownload = (records, id) => {
        const formData = {
            AgencyId: getDetails.agencyId,
            UserId: records.userId,
            ReportType: id
        };
        DownloadFiletWithSpinner(
            showSpinner,
            hideSpinner,
            generatetenantcheckreport,
            "post",
            formData,
            null,
            false
        )
            .then((returnData) => {
                if (returnData != null) {
                    window.open(returnData, "_blank");
                } else {
                    ToastRef.current.notify(["error", "Internal Server Error"]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };


    const handleCreateAgreement = (rowData) => {
        if(getDetails.agentId){
            if (rowData.title === "Management Agreement") {
                dynamicUrl(navigate, "agreementtabs" + `?propertyUID=${propertyUId}&agencyId=${getDetails.agencyId}&properties=true&viewDetails=true`);
            }
            if (rowData.title === "Residential Tenancy Agreement") {
                dynamicUrl(navigate, "tenancyagreement" + `?propertyUID=${propertyUId}&agencyId=${getDetails.agencyId}&properties=true&viewDetails=true`);
            }
            if (rowData.title === "Exclusive Sales Agency Agreement") {
                dynamicUrl(navigate, "SaleAgreementTabs" + `?propertyUID=${propertyUId}&agencyId=${getDetails.agencyId}&properties=true&viewDetails=true`);
            }
        }else{
            ToastRef.current.notify(["error", "Agent is required!"]);
            handleOpenProperty(1)
    }
    }

    const handleAgreementDownload = (record) => {
        const formData = {
            PropertyUId: record.propertyUniqueId,
        };
        DownloadFiletWithSpinner(
            showSpinner,
            hideSpinner,
            generatePdfReport,
            "post",
            formData,
            null,
            false
        )
            .then((returnData) => {
                if (returnData != null) {
                    window.open(returnData, "_blank");
                } else {
                    ToastRef.current.notify(["error", "Internal Server Error"]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const handleTenantAgreementDownload = (record) => {
        const formData = {
            PropertyUId: record.propertyUniqueId,
        };
        DownloadFiletWithSpinner(
            showSpinner,
            hideSpinner,
            generateTenantAgreementPdfReport,
            "post",
            formData,
            null,
            false
        )
            .then((returnData) => {
                if (returnData != null) {
                    window.open(returnData, "_blank");
                } else {
                    ToastRef.current.notify(["error", "Internal Server Error"]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const handleSaleAgreementDownload = (record) => {
        const formData = {
            PropertyUId: record.propertyUniqueId,
        };
        DownloadFiletWithSpinner(
            showSpinner,
            hideSpinner,
            generatesalesagreementPdfReport,
            "post",
            formData,
            null,
            false
        )
            .then((returnData) => {
                if (returnData != null) {
                    window.open(returnData, "_blank");
                } else {
                    ToastRef.current.notify(["error", "Internal Server Error"]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const columns = [
        {
            title: "Agreement Type",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text) => text ? moment(text).format("DD/MM/YYYY") : "-", // Display "-" if no createdAt date
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text) => (text ? text : "-"),
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => (
                <div>
                    {record.isCompleted  ? (
                        <DownloadOutlined title="Download Agreement" style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => handleDownload(record)} />
                    ) :record.status   ?  (
                        <img src="/assets/images/view.svg" alt="View Agreement" title="View Agreement"  style={{cursor: "pointer", width:'20px' }} onClick={() => handleCreateAgreement(record)}/>                    ): (
                        <PlusOutlined title="Add Agreement" style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => handleCreateAgreement(record)} />
                    )
                    }
                        <UploadOutlined title="Upload Agreement" style={{fontSize: "20px",marginLeft: "10px",cursor: "pointer"}} onClick={() => handleUploadAgreement(record)}
                />
                </div>
            ),
        },
    ];

    const renderListingDetails = (listingType, listings) => (
        listings?.map((listing) => (
            <div key={listing.listingId} className={saleListing.length > 0 && rentListing.length > 0 ? "col-lg-6" : "col-lg-12"}>
                <div className="col-lg-12 mt-2 detailsContainer">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>{listingType}</div>
                        <div className="d-flex">
                        <div className="editbtn mr-4" onClick={() => handleOpenProperty(3,listingType)}>
                            <img className="mr-1" src="/assets/images/editing.svg" width={15} alt="edit icon" />Edit
                        </div>
                        <div className="editbtn" onClick={()=>handleDelete(listings)}>
                        <img className="mr-1" src="/assets/images/trash.png" width={15} alt="edit icon" />Delete
                        </div>
                        </div>
                    </div>
                    <div className="owner-details">
                        <ShowValue Label="Property For" setData={listing.propertySaleRental === 1 ? "Sale" : "Rent"} />
                        <ShowValue active={listing.sListingStatus === "Active"} Label="Listing Status" setData={listing.sListingStatus || "-"} />
                        <ShowValue Label="Is Commercial" setData={listing.isCommercial ? "Yes" : "No"} />
                        <ShowValue Label="New or Established" setData={listing.isNew ? "Yes" : "No"} />
                        {listingType === "Sale Listing" ? <ShowValue Label="Agreement Type" setData={listing.propertyAgreementType || "-"} /> : null}
                        {listingType === "Rent Listing" ? <ShowValue Label="Rent Inverval" setData={getDetails.rentTenure === 1 ? "Weekly" : "Monthly" || "-"} /> : null}
                        {listingType === "Sale Listing" ? <ShowValue Label="Property Price" setData={"$" + listing.propertyPrice || "-"} /> : null}
                        {listingType === "Rent Listing" ? <ShowValue Label="Rental Price" setData={"$" + listing.rent|| "-"} /> : null}
                        <ShowValue Label="Listing Agent" setData={listing.listingAgentName || "-"} />
                        <ShowValue Label="Dual Agent" setData={listing.dualAgentName || "-"} />
                        {listingType === "Sale Listing" ? <ShowValue Label="Show Text" setData={listing.showText || "-"} /> : null}
                        {listingType === "Rent Listing" ? <ShowValue Label="Date Available" setData={moment(listing.dateAvailable).format("DD/MM/YYYY") || "-"} /> : null}
                    </div>
                </div>
            </div>
        ))
    );


    const renderOwnerDetails = (title, owners) => (
        owners?.map((owner) => (
            <div key={owner.contactId} style={{ width: '50%' }}>
                <div className="col-lg-12 mt-2 detailsContainer">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>{title}</div>
                        <div className="editbtn" onClick={() => handleOpenProperty(2)}>
                            <img className="mr-1" src="/assets/images/editing.svg" width={15} alt="edit icon" />Edit
                        </div>
                    </div>
                    <div className="owner-details">
                        {title === "Primary Owner" && (
                            <ShowValue Label="Owner Folio" setData={getDetails.ownerAccountFolio || "-"} />
                        )}
                        <ShowValue Label="Name" setData={owner.name || "-"} />
                        <ShowValue Label="Email" setData={owner.contactEmail || "-"} />
                        <ShowValue Label="Phone" setData={owner.phone || "-"} />
                    </div>
                </div>
            </div>
        ))
    );


    const renderTenantPortfolioDetails = () => {
        if (!tenantPortfolioDetails) return null;

        return (
            <div className="mt-2 detailsContainer mr-1 d-flex">
                <div className="owner-details">
                    <ShowValue Label="Tenant Account Folio" setData={tenantPortfolioDetails.tenantAccountFolio || "-"} />
                    <ShowValue Label="Tenant Checksum" setData={tenantPortfolioDetails.tenantChecksum || "-"} />
                    <ShowValue Label="Rent Review Frequency" setData={tenantPortfolioDetails.rentReviewFrequency || "-"} />
                    <ShowValue Label="Rent Include Tax" setData={tenantPortfolioDetails.rentIncludeTax ? "Yes" : "No"} />
                    <ShowValue Label="Rent" setData={tenantPortfolioDetails.rent || "-"} />
                    <ShowValue Label="Period" setData={tenantPortfolioDetails.period || "-"} />
                </div>
                <div className="owner-details">
                    <ShowValue Label="Agreement Start" setData={tenantPortfolioDetails.agreementStart || "-"} />
                    <ShowValue Label="Agreement End" setData={tenantPortfolioDetails.agreementEnd || "-"} />
                    <ShowValue Label="Abn" setData={tenantPortfolioDetails.abn || "-"} />
                    <ShowValue Label="Balance" setData={tenantPortfolioDetails.balance || "-"} />
                    <ShowValue Label="Bond Amount" setData={tenantPortfolioDetails.bondAmount || "-"} />
                    <ShowValue Label="Bond In-Trust" setData={tenantPortfolioDetails.bondInTrust || "-"} />
                </div>
            </div>
        );
    };


    const renderTenantInfo = (title, tenants) => (
        tenants?.map((tenant) => (
            <div key={tenant.contactId} className="mt-2 detailsContainer mr-1">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>{title}</div>
                    <div className="editbtn" onClick={()=>handleOpenAddTenant(tenant.tenantId,'edit')}>
                        <img className="mr-1" src="/assets/images/editing.svg" width={15} alt="edit icon" />Edit
                    </div>
                </div>
                <div className="owner-details">
                    <ShowValue Label="Name" setData={tenant.getFullName || "-"} />
                    <ShowValue Label="Email" setData={tenant.tenantEmail || "-"} />
                    <ShowValue Label="Phone" setData={tenant.tenantMobile || "-"} />
                </div>
            </div>
        ))
    );

    const renderLegalDetails = (legalType, listings) => (
        listings?.map((listing) => (
            <div key={listing.listingId} className={getDetails?.propertySellerConvyencor && getDetails?.propertyBuyerConvyencor ? "col-lg-6" : "col-lg-12"}>
                <div className="col-lg-12 mt-2 detailsContainer">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>{legalType}</div>

                    </div>
                    <div className="owner-details">
                        <ShowValue Label="Name" setData={listing.firmName || "-"} />
                        <ShowValue Label="Principal Name" setData={listing.principalName || "-"} />
                        <ShowValue Label="Email" setData={listing.email || "-"} />
                        <ShowValue Label="Phone" setData={listing.phone || "-"} />
                        <ShowValue Label="Address" setData={listing.address || "-"} />
                    </div>
                </div>
            </div>
        ))
    );

    const documeyArr = [
        { key: "1", title: "Management Agreement", createdAt: "", docType: eAgreementTemplateType.ManagementAgreement },
        { key: "2", title: "Residential Tenancy Agreement", createdAt: "", docType: eAgreementTemplateType.TenantLeaseAgreement },
        { key: "3", title: "Exclusive Sales Agency Agreement", createdAt: "", docType: eAgreementTemplateType.ExclusiveAgencyAgreement },
    ];

    const enhancedData = documeyArr.map((doc) => {
        const match = getDocDetails?.find((main) => main.agreementType === doc.docType);
        console.log(match)
        if (match) {
            return {
                ...doc,
                createdAt: match.createdAt,
                isCompleted: match.isCompleted,
                agreementFilePath: match.agreementFilePath,
                isManuallyAdded: match.isManuallyAdded,
                userId: match.userId,
                status: match.status,
                propertyUniqueId:match.propertyUniqueId
            };
        }

        return doc;
    });

    const handleGodBack = () => {
        dynamicUrl(navigate, "propertiesList");
    }

    const closeProperty = () => {
        //resetUrl();
        setOpenAddProperty(false);
        setResetAllPage(false);
        getPropertyDetails(propertyUId);

      }

      const handleOpenProperty = (step,listingType) => {
        setOpenAddProperty(true);
        setResetAllPage(true);
        setCurrentStep(step);
        let type = listingType==="Sale Listing" ? 1 : listingType==="Rent Listing" ? 2 : null;
        setListingType(type)
      }


    const handleTabChange = (key) => {
        if(key === "OwnerShip"){
          setCurrentStep(2)
        }else if (key === "Listings"){
          setCurrentStep(3)
        }
    }

    const handleTabs = (tabs) => {
        setActiveTab(tabs);
        tabs === "Maintainance"? getmaintainanceDetsils() : null
    }

    const getmaintainanceDetsils = (record) => {
        const formData = {
            PropertyId: getDetails.propertyId,
            Status : record ? record : 0
        };
        makeRequestWithSpinner(
            showSpinner,
            hideSpinner,
            getmaintenanceListforportalforproperty,
            "post",
            formData,
            null,
            false
        )
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                      let issueData = returnData.object.maintenanceDetails;
                      let issueList = issueData.map((issueDetails) => ({
                        Title: issueDetails.title,
                        Description: issueDetails.description,
                        CreatedDate: FormatDate(issueDetails.createdAt),
                        maintenanceUniqueId: issueDetails.maintenanceUniqueId,
                        IssueType: issueDetails.sIssueType,
                        Tenant : issueDetails.tenantName,
                        Supplier : issueDetails.tradesmanName,
                        Status: issueDetails.sStatus,
                      }));
                      setMaintainanceList(issueList);
                      setListCount(returnData.object)
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const tabColumns = {
        Status: {
          colorMap: {
            "Report Repair": "#3498db",
            "Repair Rejected": "#e74c3c",
            "Reschedule": "#f39c12",
            "Job Started": "#1abc9c",
            "Repair Approved": "#16a085",
            "Job Schedule": "#8e44ad"
          },
          defaultColor: 'blue'
        }
    };

    const handleStatClick = (selectedPurpose) => {
        setMaintainanceStatus(selectedPurpose);
        getmaintainanceDetsils(selectedPurpose)
      };

      const recallApi = () => {
        getPropertyDetails(propertyUId)
      }

const handleDelete = (data) => {
    const baseUrl = deletepropertylisting;
    let formdata = {
      ListingId : data[0].listingId,
      AgencyId : agencyID,
    };
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", formdata)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
         ToastRef.current.notify(["success", response.message]);
         getPropertyDetails(propertyUId);
        } else {
          ToastRef.current.notify(["error", response.message]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleUploadAgreement = (rowData) => {
      if (rowData.title === "Management Agreement") {
        dynamicUrl(
          navigate,
          "UploadAgreementManagement" +
            `?propertyUID=${propertyUId}&propertyId=${getDetails.propertyId}`
        );
      } else if (rowData.title === "Residential Tenancy Agreement") {
        dynamicUrl(
          navigate,
          "UploadAgreementTenant" +
            `?propertyUID=${propertyUId}&propertyId=${getDetails.propertyId}`
        );
      } else {
        dynamicUrl(
          navigate,
          "UploadAgreement" +
            `?propertyUID=${propertyUId}&propertyId=${getDetails.propertyId}`
        );
      }
    };

      return (
        <div>
            <div className="col-12 px-0 changedateantdstyle">
                <div className="col-12 px-0 daily-date-style">
                    <SubNav
                        title={"Property Details"}
                        goback={handleGodBack}
                        extraData={true}
                    />
                          <ToastAlert ref={ToastRef} />

                    <div style={{ display: "flex", padding: "5px", background: 'white', margin: '5px 15px' }}>
                        {tabs.map((tab) => (
                            <div
                                key={tab}
                                className={`button ${activeTab === tab ? "active-tab" : ""}`}
                                onClick={() => handleTabs(tab)}
                            >
                                {tab}
                            </div>
                        ))}
                    </div>
                   {activeTab === "Overview"? 
                    <div className="material-work-desk-new bg-white m-3" style={{fontFamily:'Inter'}}>
                        <div className="p-3">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="detailsLabel">Property</div>
                                <div className="editbtn" onClick={() => handleOpenProperty(1)}> <img className="mr-1" src="/assets/images/editing.svg" width={15} /> Edit</div>
                            </div>

                            <div className="mt-2 detailsContainer">
                                <ShowValue Label='Address' setData={getDetails?.getFormatedAddress} />
                                <ShowValue Label='Type' setData={getDetails?.sPropertyType} />
                                <ShowValue Label='Attributes' setData={<>
                                    <BiBed style={{ fontSize: '20px' }} />{"\u00A0"}{getDetails?.countBedrooms + getDetails?.countToilets}
                                    {"\u00A0\u00A0\u00A0"}
                                    <BiBath style={{ fontSize: '20px' }} />{"\u00A0"}{getDetails?.countBathrooms}
                                    {"\u00A0\u00A0\u00A0"}
                                    <BiCar style={{ fontSize: '20px' }} />{"\u00A0"}{getDetails?.countCarParking + getDetails?.countGarageSpace}
                                </>} />
                                <ShowValue Label='Assigned To' setData={getDetails?.agent_Name ? getDetails?.agent_Name : "-" } />
                                <ShowValue Label='Features' setData={getDetails?.addUpdatePropertyAttributesModel ? getTrueProperties(getDetails?.addUpdatePropertyAttributesModel) : "-"} />
                            </div>
                        </div>

                        <div className="p-3">
              
                                <>
                                    {getDetails?.contactlistModel?.length > 0 ?
                                        <div>
                                            <div className="detailsLabel">Owner</div>
                                            <div className="detailsCollapse mt-2 d-flex justify-content-between">
                                                <div onClick={handleOwner}>
                                                    {ownerCollapse ? <IoIosArrowDown style={{ fontSize: "20px" }} /> : <IoIosArrowUp style={{ fontSize: "20px" }} />} Owner Details
                                                </div>
                                                <div className="d-flex align-items-center" onClick={() => handleOpenProperty(2)}>
                                                    <img src="/assets/images/add.png" width={25} alt="add icon" /> Add Owner
                                                </div>
                                            </div>
                                        </div> : <div className="detailsCollapse d-flex align-items-center" onClick={() => handleOpenProperty(2)}>
                                            <img src="/assets/images/add.png" width={25} alt="add icon" /> Add Owner
                                        </div>}
                                        {ownerCollapse && (
                                    <div className="row">
                                        {renderOwnerDetails("Primary Owner", primaryOwners)}
                                        {renderOwnerDetails("Secondary Owner", secondaryOwners)}
                                    </div>   )}

                                </>
                         
                        </div>

                        <div className="p-3">
                         
                                <>
                                    {getDetails?.tenantDetails?.length > 0 ?
                                        <div>
                                            <div className="d-flex justify-content-between">
                                                <div className="detailsLabel">Tenant</div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="detailsCollapse mt-2" onClick={handleTenantFolid}>
                                                    {tenantFolipCollapse ? <IoIosArrowDown style={{ fontSize: "20px" }} /> : <IoIosArrowUp style={{ fontSize: "20px" }} />} Tenant Account Folio
                                                </div>
                                                <div className="editbtn">
                                                    <div className="editbtn mr-3" onClick={handleOpenReceipt}>
                                                        <img className="mr-" src="/assets/images/editing.svg" width={15} alt="edit icon" />Add Receipt
                                                    </div>
                                                    <div className="editbtn mr-3" onClick={()=>handleOpenAddTenant("","edit")}>
                                                    <img className="mr-1" src="/assets/images/editing.svg" width={15} alt="edit icon" />Edit
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : <div className="d-flex align-items-center detailsCollapse" onClick={handleOpenAddTenant}><img src="/assets/images/add.png" width={25} /> Add Tenants</div>}
                                       {tenantFolipCollapse ? renderTenantPortfolioDetails() : null}
                                    {getDetails?.tenantDetails?.length > 0 ?
                                        <div className="d-flex justify-content-between detailsCollapse mt-2" onClick={handleTenant}>
                                            <div>{tenantCollapse ? <IoIosArrowDown style={{ fontSize: "20px" }} /> : <IoIosArrowUp style={{ fontSize: "20px" }} />} Tenant Details</div>
                                                <div className="d-flex align-items-center detailsCollapse" onClick={handleOpenAddTenant}><img src="/assets/images/add.png" width={25} /> Add Tenants</div>
                                            
                                        </div> : null}
                                        {tenantCollapse && (
                                    <div className="d-flex">
                                        {renderTenantInfo("Primary Tenant", primarytenantDetails)}
                                        {renderTenantInfo("Secondary Tenant", secondarytenantDetails)}
                                    </div> )}
                                </>
                          
                        </div>

                            <div className="p-3">
                                <div className="detailsCollapse mt-2" onClick={handleAgreement}>{agreementCollapse ? <IoIosArrowDown style={{ fontSize: "20px" }} /> : <IoIosArrowUp style={{ fontSize: "20px" }} />} Agreement</div>
                                {agreementCollapse ?
                                    <div className="d-flex">
                                        <div className="mt-2 detailsContainer mr-1">
                                            <Table dataSource={enhancedData} columns={columns} rowKey="key" pagination={false} />

                                        </div>
                                    </div> : null}
                            </div>

                        <div className="p-3">
                         
                                <>
                                    {getDetails?.listingDetails?.length > 0 ?
                                        <div>
                                            <div className="detailsLabel">Listings</div>
                                            <div className="detailsCollapse mt-2 d-flex justify-content-between">
                                                <div onClick={handleListings}>
                                                    {listingCollapse ? <IoIosArrowDown style={{ fontSize: "20px" }} /> : <IoIosArrowUp style={{ fontSize: "20px" }} />} Property Listings
                                                </div>
                                                <div className="d-flex align-items-center" onClick={() => handleOpenProperty(3)}>
                                                    <img src="/assets/images/add.png" width={25} alt="add icon" /> Add Property Listings
                                                </div>
                                            </div>
                                        </div> : <div className="detailsCollapse d-flex align-items-center" onClick={() => handleOpenProperty(3)}>
                                            <img src="/assets/images/add.png" width={25} alt="add icon" /> Add Property Listings
                                        </div>}
                                        {listingCollapse && (
                                    <div className="row">
                                        {renderListingDetails("Sale Listing", saleListing)}
                                        {renderListingDetails("Rent Listing", rentListing)}
                                    </div>       )}
                                </>
                     
                        </div>

                        {getDetails?.propertyHeading ? (
                            <div className="p-3">
                                <div className="d-flex justify-content-between">
                                    <div className="detailsCollapse mt-2" onClick={handlePhotos}>{photosCollapse ? <IoIosArrowDown style={{ fontSize: "20px" }} /> : <IoIosArrowUp style={{ fontSize: "20px" }} />} Photos</div>
                                    <div className="editbtn" onClick={() => handleOpenProperty(3)}>
                                        <img className="mr-1" src="/assets/images/editing.svg" width={15} alt="edit icon" />Edit
                                    </div>
                                </div>
                                {photosCollapse ?
                                    <div className="d-flex">
                                        <div className="mt-2 detailsContainer mr-1">
                                            <span style={{ fontWeight: '500', fontSize: '16px' }}>{getDetails.propertyHeading}</span>
                                            <div style={{ fontSize: '14px', color: '#818584' }}><br />
                                                {getDetails.propertyDescription}
                                            </div><br />
                                            <div className="d-flex">
                                               {galleryPropertyImages.length > 0 ? <div className="col-lg-6">
                                                    <div className="detailsCollapse mt-2 mb-2">Property Images</div>
                                                    <ImageGalleryModal filePath={false} images={galleryPropertyImages.length > 0 ? galleryPropertyImages : []} />
                                                </div> : null }
                                                {galleryFloorImages.length > 0 ?
                                                <div className="col-lg-6">
                                                    <div className="detailsCollapse mt-2 mb-2">Property Floor Plan</div>
                                                    <ImageGalleryModal filePath={false}  images={galleryFloorImages.length > 0 ? galleryFloorImages : []} />
                                                </div> : null }
                                            </div>
                                        </div>

                                    </div> : null}
                            </div>) : ''}

                        {getDetails?.propertySellerConvyencor || getDetails?.propertyBuyerConvyencor ? (
                            <div className="p-3">
                                <div className="d-flex justify-content-between">
                                    <div className="detailsCollapse mt-2" onClick={handleLegal}>{legalCollapse ? <IoIosArrowDown style={{ fontSize: "20px" }} /> : <IoIosArrowUp style={{ fontSize: "20px" }} />} Legals</div>
                                    <div className="editbtn" onClick={() => handleOpenProperty(3)}>
                                        <img className="mr-1" src="/assets/images/editing.svg" width={15} alt="edit icon" />Edit
                                    </div>
                                </div>
                                {legalCollapse ?
                                    <div className="row">
                                        {renderLegalDetails("Seller Solicitor/Conveyancer Details", getDetails?.propertySellerConvyencor ? [getDetails?.propertySellerConvyencor] : null)}
                                        {renderLegalDetails("Buyer Solicitor/Conveyancer Details", getDetails?.propertyBuyerConvyencor ? [getDetails?.propertyBuyerConvyencor] : null)}
                                    </div> : null}
                            </div>) : ''}

                        <CustomModal
                            title="New Tenant Receipt"
                            width={700}
                            closable={closeReceipt}
                            visible={ppenAddNewReceipt}
                            onCancel={closeReceipt}
                            modalData={
                                <div className="details-form p-3">
                                    {/* Details Section */}
                                    <div className="contacts-group">
                                        <ShowValue Label="Tenant" setData={primarytenantDetails?.[0]?.getFullName + " " + `(${primarytenantDetails?.[0]?.tenantFolioDetails.tenantAccountFolio})`} />
                                        <ShowValue Label="Owner" setData={primaryOwners?.[0]?.name + " " + `(${getDetails?.ownerAccountFolio})`} />
                                        <ShowValue Label="Property" setData={getDetails?.getFormatedAddress} />
                                        <ShowValue Label="Details" setData={"$400.00 weekly"} />
                                        <ShowValue Label="Deposit" setData={"$250"} />
                                    </div>

                                    {/* Payment Info Section */}
                                    <div className="contacts-group row">
                                        <TextBox label="Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                                        <SelectBox label="Payment Method" options={ePaymentModes} onChange={(value) => setSelectedPaymentMode(value.value)} />
                                        <TextBox label="Date" type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                                    </div>

                                    {/* Rent Table Section */}
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Allocation</th>
                                                <th>Due</th>
                                                <th>Description</th>
                                                <th>Amount Due</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rentData.map((row, index) => (
                                                <tr key={index}>
                                                    <td>{row.allocation}</td>
                                                    <td>{row.due}</td>
                                                    <td>{row.description}</td>
                                                    <td>{row.amountDue}</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            value={row.amount}
                                                            onChange={(e) =>
                                                                handleInputChange(index, "amount", e.target.value, "rent")
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    {/* Deposit Table Section */}
                                    <table className="table">

                                        <tbody>
                                            <tr >

                                            </tr>
                                            {depositData.map((row, index) => (
                                                <tr key={index}>
                                                    <td>Deposite</td>
                                                    <td style={{ width: "12%" }}></td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            value={row.description}
                                                            onChange={(e) =>
                                                                handleInputChange(index, "description", e.target.value, "deposit")
                                                            }
                                                        />
                                                    </td>
                                                    <td style={{ width: "15%" }}></td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            value={row.amount}
                                                            onChange={(e) =>
                                                                handleInputChange(index, "amount", e.target.value, "deposit")
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            }
                            footer={[
                                <div
                                    key="footer"
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <button
                                        style={{ marginRight: "20px" }}
                                        className="pdb-save-changes mt-2"
                                        onClick={handleFinalSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            ]}
                        />
                        <CustomModal
                            title={tenantEdit ? "Update Tenant" : "Add Tenant"}
                            width={700}
                            closable={closeTenant}
                            visible={openAddNewtenant}
                            onCancel={closeTenant}
                            modalData={
                                <div>
                                    <div style={{ display: "flex", padding: "5px", background: 'white'}}>
                                        {tenantTabs.map((tab) => (
                                            <div
                                                key={tab}
                                                className={`button ${activeTabTenant === tab ? "active-tab" : ""}`}
                                                onClick={() => setActiveTabTenant(tab)}
                                            >
                                                {tab}
                                            </div>
                                        ))}
                                    </div>
                                    {activeTabTenant === "Tenant" ?
                                    <AddTenant tenantId={tenantId} cancel={closeTenant} getDetails={getDetails} />:
                                    <AddTenantFolio ancel={closeTenant} getDetails={getDetails}/>}
                                </div>
                            }
                            footer={null}
                        />

                        <MainPropertyComponent
                            isVisible={openAddProperty}
                            onClose={closeProperty}
                            propertyUID={propertyUId}
                            currentStep={currentStep}
                            setCurrentStep={setCurrentStep}
                            dpdata={dpdata}
                            getDetails={getDetails}
                            getDocDetails={getDocDetails}
                            resetAllPage={resetAllPage}
                            handleTabChange={handleTabChange}
                            listingType={listingType}
                        />

                    </div> :null}
                    {activeTab === "Documents"? 
                    <div className="material-work-desk-new bg-white m-3" style={{fontFamily:'Inter'}}>
                        <PropertyDocumentsAgency getDetails={getDetails} docfile={getDocDetails} recallApi={recallApi}/>
                    </div>: null}
                    {activeTab === "Maintainance" ? 
                        <div className="material-work-desk-new bg-white m-3">
                            <div style={{ borderBottom: '1px solid #e9ecef', padding: '10px' }}>
                                <div className="col-lg-8">
                                    <span
                                        className={`col-lg-2 col-sm-2 stat-item ${maintainanceStatus === 0 ? 'active' : ''}`}
                                        onClick={() => handleStatClick(0)}
                                    >
                                        New <span className="count">{listCount.newCount}</span>
                                    </span>
                                    <span
                                        className={`col-lg-2 col-sm-2 stat-item ${maintainanceStatus === 2 ? 'active' : ''}`}
                                        onClick={() => handleStatClick(2)}
                                    >
                                        Active <span className="count">{listCount.activeCount}</span>
                                    </span>
                                    <span
                                        className={`col-lg-2 col-sm-2 stat-item ${maintainanceStatus === 1 ? 'active' : ''}`}
                                        onClick={() => handleStatClick(1)}
                                    >
                                        Rejected <span className="count">{listCount.rejectedCount}</span>
                                    </span>
                                    <span
                                        className={`col-lg-2 col-sm-2 stat-item ${maintainanceStatus === 10 ? 'active' : ''}`}
                                        onClick={() => handleStatClick(10)}
                                    >
                                        Completed <span className="count">{listCount.completedCount}</span>
                                    </span>
                                   
                                </div>
                            </div>
                            <AntdTableExample
                                label="List of Maintainance"
                                dataSource={maintainanceList}
                                globalSearch
                                hideCol={"maintenanceUniqueId"}
                                tagColumns={tabColumns}
                            />
                        </div>
                    : null}
                </div>
            </div>
        </div>
    );
};

export default ViewPropertDetails;
