import React from 'react'
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSpinnerContext } from '../../../../Utilities/SpinnerContext';
import { getAgentDetails } from '../../../../components/CommonSection/apiresources';
import Header from '../../../../components/CommonSection/Header/Header';
import ToastAlert from '../../../../components/CommonSection/ToastAlert/ToastAlert';
import PersonalDetailsAgent from '../../../../components/Dashboard/Dashboard-agent/PersonalDetailsAgent';
import LicensingDetailsAgent from '../../../../components/Dashboard/Dashboard-agent/LicensingDetailsAgent';
import ExperienceAgent from '../../../../components/Dashboard/Dashboard-agent/ExperienceAgent';
import ContactDetailsAgent from '../../../../components/Dashboard/Dashboard-agent/ContactDetailsAgent';
import RemunerationDetailsAgent from '../../../../components/Dashboard/Dashboard-agent/RemunerationDetailsAgent';
import { BiChevronLeft, BiDotsVerticalRounded } from 'react-icons/bi';
import { Tab, Tabs } from 'react-bootstrap';
import { makeRequestWithSpinner } from '../../../../Utilities/client';
import { ActionStatus } from '../../../../Utilities/enum';
import Sidebar from '../../../../components/CommonSection/SideBar/sideBar';
import { currentProfile } from '../../../../components/CommonSection/Cookie/Session';

const AgentProfile = ({ handleparentsuccess }) => {
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [activeTopic, setActiveTopic] = useState("Dashboard");

    const [visiblet, setVisiblet] = useState(true);
    const [topicValue, setTopicValue] = useState("Agent");
    const [activeKey, setActiveKey] = useState("PersonalDetail");
    const [getDetails, setGetDetails] = useState(null);
    const { showSpinner, hideSpinner } = useSpinnerContext();
    var agentId = 0;
    const navigate = useNavigate();
    const ToastRef = useRef();

    useEffect(() => {
        var url = new URL(window.location.href);
        var agentId = url.searchParams.get("value");
        var activeTab = url.searchParams.get("activeTab");
        if (activeTab) {
            setActiveKey(activeTab)
        }
        if (agentId) {
            getAgencyDetails()
        }
    }, [])

    const getAgencyDetails = (value) => {
        var url = new URL(window.location.href);
        var agentId = value ? value : url.searchParams.get("value");
        const baseUrl = getAgentDetails;
        const apiUrl = `${baseUrl}?agentID=${agentId}`;
        makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get").then((response) => {
            if (response.status === ActionStatus.Successfull) {
                // debugger;
                console.log(response.object,'response.object')
                setGetDetails(response.object)
            } else {
                handleparentsuccess(['warn', response.data.message])
            }
        })
            .catch(error => {
                handleparentsuccess(['error', error]);
            });
    };
    console.log('Get Details ' + getDetails)

    const handleDataChild = (value) => {
        // agentId = value;
        getAgencyDetails(value)
    }

    const handleTabChange = (key) => {
        setActiveKey(key);
    };

    const toastMessageHandler = (toastdata) => {
        ToastRef.current.notify(toastdata);
    }

    const currentSideBar = currentProfile.get()
    const handleTopicValue = (text) => {
        setTopicValue(text);
        navigate(currentSideBar === 2 ? `/agency/` + text : `/agent/` + text);
        setActiveTopic(text);
    }

    return (
        <div className="dashboard-agency">
            <ToastAlert ref={ToastRef} />
            <div className='continer-dbag'>
                <div className="dashboardagency-content">

                    <div className="pa-content-top-level" onClick={() => setVisiblet(true)}>
                      
                        {/* <Tabs defaultActiveKey="PersonalDetail" activeKey={activeKey} id="fill-tab-example" className="mb-3 properties-nav-bar" onSelect={handleTabChange} fill >
                            <Tab eventKey="PersonalDetail" title="Personal Details" >
                                <PersonalDetailsAgent onDataRecive={handleDataChild} handleparentsuccess={toastMessageHandler} handleCurrentPage={handleTabChange} getDetails={getDetails} />
                            </Tab>
                            <Tab eventKey="LicensingDetailsAgent" title={<span style={{ color: getDetails === null ? 'gray' : '#036475' }}>Licensing Details</span>} disabled={getDetails === null}>
                                <LicensingDetailsAgent onDataRecive={handleDataChild} handleparentsuccess={toastMessageHandler} handleCurrentPage={handleTabChange} getDetails={getDetails} />
                            </Tab>
                            <Tab eventKey="Experience" title={<span style={{ color: getDetails === null ? 'gray' : '#036475' }}>Experience</span>} disabled={getDetails === null} >
                                <ExperienceAgent onDataRecive={handleDataChild} handleparentsuccess={toastMessageHandler} handleCurrentPage={handleTabChange} getDetails={getDetails} />
                            </Tab>
                            <Tab eventKey="ContactDetails" title={<span style={{ color: getDetails === null ? 'gray' : '#036475' }}>Contact Details</span>} disabled={getDetails === null} >
                                <ContactDetailsAgent onDataRecive={handleDataChild} handleparentsuccess={toastMessageHandler} handleCurrentPage={handleTabChange} getDetails={getDetails} />
                            </Tab >
                            <Tab eventKey="RemunerationDetailsAgent" title={<span style={{ color: getDetails === null ? 'gray' : '#036475' }}>Remuneration Details</span>} disabled={getDetails === null} >
                                <RemunerationDetailsAgent onDataRecive={handleDataChild} handleparentsuccess={toastMessageHandler} handleCurrentPage={handleTabChange} getDetails={getDetails} />
                            </Tab>
                        </Tabs> */}
                                <PersonalDetailsAgent onDataRecive={handleDataChild} handleparentsuccess={toastMessageHandler} handleCurrentPage={handleTabChange} getDetails={getDetails} />
                                <LicensingDetailsAgent onDataRecive={handleDataChild} handleparentsuccess={toastMessageHandler} handleCurrentPage={handleTabChange} getDetails={getDetails} />
                                <ExperienceAgent onDataRecive={handleDataChild} handleparentsuccess={toastMessageHandler} handleCurrentPage={handleTabChange} getDetails={getDetails} />
                                <ContactDetailsAgent onDataRecive={handleDataChild} handleparentsuccess={toastMessageHandler} handleCurrentPage={handleTabChange} getDetails={getDetails} />
                                <RemunerationDetailsAgent onDataRecive={handleDataChild} handleparentsuccess={toastMessageHandler} handleCurrentPage={handleTabChange} getDetails={getDetails} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgentProfile

