import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getbillslist} from "../../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import "../../../../components/Dashboard/Dashboard-agent/AgentList.css";
import { ActionStatus } from "../../../../Utilities/enum";
import { addParametersForProfiles } from "../../../../Utilities/profileManagement";
import SubNav from "../../../navbar/subNav";
import AntdTableExample from "../../../input-components/dataTable";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { Steps, DatePicker, message } from 'antd';

const ListInvoice = () => {
    const [invoiceLists, setInvoiceLists] = useState([]);
    const [userId, setUserId] = useState("");
    const [agencyID, setAgencyId] = useState("");
    const navigate = useNavigate();
    const [propertyType, setPropertyType] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [purpose, setPurpose] = useState("");
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [rows, setRows] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [openAddProperty, setOpenAddProperty] = useState(false);
    const [getDetails, setGetDetails] = useState(null);
    const [addNew, setAddNew] = useState(false);
    const [edit, setEdit] = useState(false);
    const [view, setView] = useState(false);
    const [propertyTypeVal, setPropertyTypeVal] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [agentId, setAgentId] = useState("");
    const [totalRow, setTotalRow] = useState(10);



    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setAgentId(parsedData.agentId)
                    setUserId(parsedData.userId);
                    setAgencyId(parsedData.agencyID);
                }
            } catch (error) {
                console.error("Error retrieving data from AsyncStorage:", error);
            }
        };
        retrieveData();
    }, [userId]);

    useEffect(() => {
        if (userId) getSupportList(1, 10);
    }, [userId, searchText, purpose, propertyType, propertyTypeVal, fromDate, toDate]);
    

    const getSupportList = (currentPage, totalRows) => {
        const baseUrl = getbillslist;
        let data = {
            AgencyId: agencyID,
              SortBy:"AddedOn",
              SortOrder:"DESC"
        };
        data = addParametersForProfiles(data);
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    let invoiceData = response.list;
                    let invoiceList = invoiceData
                        .map((inv) => ({
                            Invoice: inv.id,
                            Tenant: inv.reference,
                            Property: inv.propertyName,
                            FolioBalance: inv.folio,
                            Account: inv.accountName,
                            Details: inv.details,
                            Amount: inv.amount,
                            Paid: inv.isPaid ? "Yes" : "No",
                            Sent: inv.isenable ? "Yes": "No",
                            id: inv.uid
                        }));
                    setTotalPages(response.totalCount)
                    setInvoiceLists(invoiceList);
                } else {
                    alert(`Message: ${response.message}`);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    
    // Handler for page change
    const handlePageChange = (pageNumber, totalRows) => {
        setCurrentPage(pageNumber);
        setRows(totalRows);
        getSupportList(pageNumber, totalRows);
    };

    const tabColumns = {
        Status: {
            colorMap: {
                "Resolved": "#00A5A2",
                "Created": "#E5A000",
            },
            defaultColor: 'blue'
        }
    };

   
    const handleSupportTickets = () => {
        setOpenAddProperty(true);
        setEdit(false);
        setView(false);
        setGetDetails(null);
        setAddNew(true);
    }

   
    const actions = [
        { icon: "/assets/images/dots.svg",key:"View", onClick: (e, data) => handleView(e, data) },
        // { icon: "/assets/images/editing.svg", onClick: (e, data) => handleEdit(e, data) },
    ]

    return (
        <div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
                    <SubNav title={"Bills"} goback={false} extraData={true} extraContent={
                        <div className="property-details-buttons">
                           
                        </div>
                    } />
                    <div className="material-work-desk-new bg-white m-3">
                        <div style={{ margin: '10px' }}>
                           
                        </div>
                        <AntdTableExample
                            showCheckbox
                            label="List Bills"
                            dataSource={invoiceLists}
                            globalSearch
                            hideCol={"id"}
                            onPageChange={handlePageChange}
                            currentPage={currentPage}
                            totalRows={rows}
                            totalRecords={totalPages}
                            tagColumns={tabColumns}
                            actions={actions}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListInvoice;
