import React from 'react'
import { useEffect, useState, useRef } from 'react'
import Header from '../../CommonSection/Header/Header'
import { BiChevronLeft, BiDotsVerticalRounded } from 'react-icons/bi'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ToastAlert from '../../CommonSection/ToastAlert/ToastAlert'
import { useNavigate } from 'react-router-dom'
import { getAgentDetails } from "../../CommonSection/apiresources";
import PersonalDetailsAgent from './PersonalDetailsAgent'
import ContactDetailsAgent from './ContactDetailsAgent'
import RemunerationDetailsAgent from './RemunerationDetailsAgent'
import LicensingDetailsAgent from './LicensingDetailsAgent'
import ExperienceAgent from './ExperienceAgent';
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import SubNav from '../../../src/navbar/subNav';

const AddAgent = ({ handleparentsuccess }) => {
    const [visiblet, setVisiblet] = useState(true);
    const [topicValue, setTopicValue] = useState("Agent");
    const [activeKey, setActiveKey] = useState("PersonalDetail");
    const [getDetails, setGetDetails] = useState(null);
    const { showSpinner, hideSpinner } = useSpinnerContext();
    var agentId = 0;
    const navigate = useNavigate();
    const ToastRef = useRef();
    var url = new URL(window.location.href);
    var userId = url.searchParams.get("userId");

    useEffect(() => {
        var url = new URL(window.location.href);
        var agentId = url.searchParams.get("value");
        var activeTab = url.searchParams.get("activeTab");
        var userId = url.searchParams.get("userId");
        if (activeTab) {
            setActiveKey(activeTab)
        }
        if (agentId) {
            getAgencyDetails()
        }
    }, [])

    const getAgencyDetails = (value) => {
        var url = new URL(window.location.href);
        var agentId = value ? value : url.searchParams.get("value");
        const baseUrl = getAgentDetails;
        const apiUrl = `${baseUrl}?agentID=${agentId}`;
        makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get").then((response) => {
            if (response.status === ActionStatus.Successfull) {
                setGetDetails(response.object)
            } else {
                handleparentsuccess(['warn', response.data.message])
            }
        })
            .catch(error => {
                handleparentsuccess(['error', error]);
            });
    };

   
    const handleTopicValue = (text) => {
        setTopicValue(text);
        navigate('/agency/' + text);
    }

    const handleDataChild = (value) => {
        // agentId = value;
        getAgencyDetails(value)
    }

    const handleTabChange = (key) => {
        setActiveKey(key);
    };

    const toastMessageHandler = (toastdata) => {
        ToastRef.current.notify(toastdata);
    }

    const handleAgentList = () => {
        navigate("/agency/agentlist");
      };

      const handlePermmission = () => {
        navigate(`/agency/Permission?userId=${userId}`);
    }

    return (
        <div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
                <SubNav title={"Profile"} goback={true} extraData={true} extraContent={
                    <div className="property-details-buttons" >
                     <button onClick={handlePermmission} className="subnav-btn" type="submit">
                                                         Permission
                                                      </button>
                        <button onClick={handleAgentList} className="subnav-btn" type="submit">
                            Agent List
                        </button>
                    </div>
                } />
                <div className="material-work-desk-new bg-white m-3">
                <ToastAlert  ref={ToastRef}/>
                <Tabs defaultActiveKey="PersonalDetail" activeKey={activeKey} id="fill-tab-example" className="mb-3 properties-nav-bar" onSelect={handleTabChange} fill >
                             <Tab eventKey="PersonalDetail" title="Personal Details" >
                                 <PersonalDetailsAgent onDataRecive={handleDataChild} handleparentsuccess={toastMessageHandler} handleCurrentPage={handleTabChange} getDetails={getDetails} />
                             </Tab>
                             <Tab eventKey="LicensingDetailsAgent"  title={<span style={{color: getDetails === null ? 'gray' : '#036475'}}>Licensing Details</span>} disabled={getDetails === null}>
                                 <LicensingDetailsAgent  onDataRecive={handleDataChild} handleparentsuccess={toastMessageHandler}  handleCurrentPage={handleTabChange} getDetails={getDetails} />
                             </Tab> 
                             <Tab eventKey="Experience" title={<span style={{color: getDetails === null ? 'gray' : '#036475'}}>Experience</span>} disabled={getDetails === null} >
                                 <ExperienceAgent  onDataRecive={handleDataChild} handleparentsuccess={toastMessageHandler}  handleCurrentPage={handleTabChange} getDetails={getDetails} />
                             </Tab>
                             <Tab eventKey="ContactDetails" title={<span style={{color: getDetails === null ? 'gray' : '#036475'}}>Contact Details</span>} disabled={getDetails === null} >
                                 <ContactDetailsAgent  onDataRecive={handleDataChild} handleparentsuccess={toastMessageHandler}  handleCurrentPage={handleTabChange} getDetails={getDetails} />
                             </Tab>
                             {/* <Tab eventKey="RemunerationDetailsAgent"  title={<span style={{color: getDetails === null ? 'gray' : '#036475'}}>Remuneration Details</span>} disabled={getDetails === null} >
                                 <RemunerationDetailsAgent  onDataRecive={handleDataChild} handleparentsuccess={toastMessageHandler}  handleCurrentPage={handleTabChange} getDetails={getDetails} />
                             </Tab> */}
                         </Tabs>
                </div>
            </div>
        </div>
    </div>

    )
}

export default AddAgent