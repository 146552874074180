import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getquotepaginglistbymaintenanceuid,quotereviewedbyagent } from "../../../../components/CommonSection/apiresources";
import { GetCookie, currentProfile, } from "../../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import "../../../../components/Dashboard/Dashboard-agent/AgentList.css";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { ActionStatus, eMaintenanceStatus } from "../../../../Utilities/enum";
import { dynamicUrl } from "../../../../Utilities/profileManagement";
import "../../../Components/openHome/openHome.css";
import CustomModal from "../../../input-components/Modal";
import SelectBox from "../../../input-components/selectBoxNew";
import SubNav from "../../../../components/CommonSection/Header/subHeader";
import AntdTableExample from "../../../input-components/dataTable";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";

const ListIssue = () => {
  const [quotelist, setQuoteList] = useState([]);
  const [topicValue, setTopicValue] = useState("ListIssueDetails");
  const [userId, setUserId] = useState("");
  const [agentID, setAgentId] = useState("");
  const navigate = useNavigate();
  const ToastRef = useRef();
  const [searchText, setSearchText] = useState("");
  const [activeTopic, setActiveTopic] = useState("ListIssueDetails");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayTable, setDisplaytable] = useState(true);
  const [reviewStatus, setReviewStatus] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [reviewStatusArr, setReviewStatusArr] = useState([
    { value: 2, label: "Approved" },
    { value: 3, label: "Rejected" },
  ]);
  var url = new URL(window.location.href);
  var maintainanceId = url.searchParams.get("maintainanceId");

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgentId(parsedData.agentId);

        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) PostApiWithSpinner(maintainanceId);
  }, [userId, searchText]);

  const PostApiWithSpinner = (maintainanceId) => {
    setDisplaytable(false)
    const baseUrl = getquotepaginglistbymaintenanceuid;
    let data = {
      MaintenanceUniqueId: maintainanceId,
    };
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        setDisplaytable(true)
        if (response.status === ActionStatus.Successfull) {
          if (response.list.length > 0) {
            let quoreData = response.list;
            let quoteList = quoreData.map((quote) => ({
              QuotePrice: quote.quotePrice,
              callOutCharge: quote.callOutCharge,
              serviceCategory: quote.serviceCategory,
              totalAmount: quote.totalAmount,
              tradesmanName: quote.tradesmanName,
              tradesmanEmail: quote.tradesmanEmail,
              tradesmanContact: quote.tradesmanContact,
              tradesmanCompany: quote.tradesmanCompany,
              serviceDescription: quote.serviceDescription,
              quoteId : quote.quoteId,
              Status : quote.isAgentReviewed ? quote.isAccepted ? "Accepted" : "Not accepted" :"Not reviewed",
              maintainanceStatus : quote.maintenanceDetails.status,
            }));
            setQuoteList(quoteList);
          } else {
            //ToastRef.current.notify(["warn", "User not added"]);
          }
        } else {
          ToastRef.current.notify(["error", response.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", response.message]);
      });
  };

  const onInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const currentSideBar = currentProfile.get();

  const handleTopicValue = (text) => {
    setTopicValue(text);
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const handleAddIssue = () => {
    dynamicUrl(navigate, "IssueDetails");
  };


  const handlePageChange = (event, pageNumber) => {
    PostApiWithSpinner(event.page + 1, event.rows)
    setCurrentPage(pageNumber);
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleEditClick = (e,rowData) => {
    setReviewStatus(null)
    setRowData(rowData)
    setOpenModal(true)
    };

  const actions = [
    { icon: "/assets/images/pen.svg", onClick: (e, data) => handleEditClick(e, data),condition: (record) => eMaintenanceStatus.Approved === record.maintainanceStatus  },
]

  const closeModal = () => {
    setOpenModal(false)
  }

  const handleInputChange = (e, label) => {
      setReviewStatus(e.value)
  }

  const handleFinalSubmit = () => {
    let formData = {
      QuoteId: parseInt(rowData.quoteId),
      AgentId: parseInt(agentID),
      ReviewStatus: parseInt(reviewStatus),
    };
    const url = quotereviewedbyagent;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          PostApiWithSpinner(maintainanceId);
          setOpenModal(false);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleRowClick = (event, rowData) => {
    const clickedElement = event.originalEvent.target;
    if (clickedElement.className.includes("action-column")) {
      return;
    } else {
      dynamicUrl(navigate, "ViewQuoteDetails" + "?QuoteId=" + rowData.quoteId);
    }
  };

  const handleGoBack = () => {
    dynamicUrl(navigate,"ListMaintainanceRequest")
}

  return (
    <div>
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
            <SubNav title={"Quote"} goBack={handleGoBack}  extraData={true} extraContent={
                <div className="property-details-buttons">
                   
                </div>
            } />
            <div className="material-work-desk-new bg-white m-3">
            <ToastAlert ref={ToastRef} />

                <AntdTableExample
                    label="List of Quote"
                    dataSource={quotelist}
                    globalSearch
                    hideCol={"callOutCharge,serviceCategory,tradesmanEmail,tradesmanContact,serviceDescription,quoteId,maintainanceStatus"}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                    totalRows={rows}
                    totalRecords={totalPages}
                    actions={actions}
                    onRowClick={handleRowClick}
                />

<CustomModal
        title="Actions"
        width={600}
        closable={closeModal}
        visible={openModal}
        onCancel={closeModal}
        modalData={
          <div style={{ marginTop: "30px", marginLeft: "10px" }}>
            <SelectBox
            colVal={12}
                label="Review Status"
                id="issueType"
                name="issueType"
                value={reviewStatus}
                onChange={(e)=>handleInputChange(e,"status")}
                options={reviewStatusArr}
              />
          </div>
        }
        footer={[
          <div
            key="footer"
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              style={{ marginRight: "20px" }}
              className="pdb-save-changes mt-2"
              onClick={handleFinalSubmit}
            >
              Submit
            </button>
          </div>,
        ]}
      />
            </div>
        </div>
    </div>
</div>
  );
};

export default ListIssue;
