import OpenAI from "openai";
import { zodResponseFormat } from "openai/helpers/zod";
import { z } from "zod";
import { PROPERTY_HEADING_COMMAND } from "./ai-commands";

const PropertyDetails = z.object({
  heading: z.string(),
  description: z.string(),
});

// Centralized OpenAI API Handler
export async function callOpenAI(model, messages, responseSchema = null) {
  const openai = new OpenAI({
    apiKey: import.meta.env.VITE_OPENAI_KEY,
    dangerouslyAllowBrowser: true,
  });

  const completion = await openai.chat.completions.create({
    model,
    messages,
    response_format: responseSchema
      ? zodResponseFormat(responseSchema, "property")
      : undefined,
  });

  const content = completion.choices[0].message.content;
  return JSON.parse(content);
}

export async function GeneratePropertyHeadingWithAI(
  showSpinner,
  hideSpinner,
  input,
  imageurls
) {
  showSpinner();

  const inputCommand = input + " " + PROPERTY_HEADING_COMMAND;
  const model = imageurls && imageurls.length > 0 ? "gpt-4o" : "gpt-4o-mini";

  const messages = [
    { role: "system", content: "You are a helpful assistant." },
    {
      role: "user",
      content:
        imageurls && imageurls.length > 0
          ? [
              { type: "text", text: inputCommand },
              ...imageurls.map((url) => ({
                type: "image_url",
                image_url: { url },
              })),
            ]
          : [{ type: "text", text: inputCommand }],
    },
  ];

  try {
    const parsedResponse = await callOpenAI(model, messages, PropertyDetails);
    hideSpinner();
    return parsedResponse;
  } catch (error) {
    hideSpinner();
    console.error("Error calling OpenAI API:", error);
    throw error;
  }
}
