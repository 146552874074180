import React, { useRef, useState, useEffect } from "react";
import "./propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { uploadmanagementagreementfromproperty, FilePath } from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner,uploadToS3WithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import Header from "../../../components/CommonSection/Header/Header";
import Sidebar from "../../../components/CommonSection/SideBar/sideBar";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import { Spin } from "antd";
import Collapse from "../../input-components/collapse";
import { AiOutlineDelete } from "react-icons/ai";
import { uplodeFileManagement } from "../../../components/CommonSection/Schema/schema";
import SubNav from "../../navbar/subNav";

const initialValues = {
  AgreementDate: "",
  ManagementFee: "",
  MarketingFee: "",
  AdministrationFee: "",
  InitialWeekRent: "",
  ApplicationFeesForUtilites: "",
  AgreementFilePath: "",
};

const UploadAgreementManagement = () => {
  let selectedFile
  const [userId, setUserId] = useState("");
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("propertiesList");
  const [isDragging, setIsDragging] = useState(false);
  const [getDetails, setGetDetails] = useState(null);
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  var url = new URL(window.location.href);
  const ToastRef = useRef();
  const primaryDocumentRef = useRef(null);
  const [loading, setLoading] = useState(false); // Added loading state
  const [fileName, setFileName] = useState("");


  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");

        if (userData) {
          const parsedData = JSON.parse(userData);

          setUserId(parsedData.userId);

        } else {

          navigate("/signin");
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };

    retrieveData();
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
  }, [userId]);


  useEffect(() => {
    if (getDetails) {
      setFieldValue("PropertyPrice", getDetails.propertyPrice)
    }
  }, [getDetails]);

  const handleTopicValue = (text) => {
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };


  const {
    errors,

    values,

    touched,

    handleBlur,

    handleChange,

    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: uplodeFileManagement,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });
  const handleFinalSubmit = () => {
    var urls = new URL(window.location.href);
    var propertyId = urls.searchParams.get("propertyId");
    const formData = {
      PropertyId: propertyId,

      AgreementDate: values.AgreementDate,

      ManagementFee: values.ManagementFee,

      MarketingFee: values.MarketingFee,

      AdministrationFee: values.AdministrationFee,

      InitialWeekRent: values.InitialWeekRent,

      ApplicationFeesForUtilites: values.ApplicationFeesForUtilites,

      AgreementFilePath: values.AgreementFilePath,
    };

    const url = uploadmanagementagreementfromproperty;

    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          var url = new URL(window.location.href);
          var propertyUId = url.searchParams.get("propertyUID");
          dynamicUrl(navigate, "viewPropertyDetails" + `?propertyUID=${propertyUId}`);


        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })

      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };

  const handleFileChange = async (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      selectedFile = e.target.files[i];
      await handleUpload();
    }
  };

    const handleUpload = async () => {
        await uploadToS3WithSpinner(
          showSpinner,
          hideSpinner,
          "propertyagreement/",
          `propertyagreement`,
          selectedFile
        ).then((returnData) => {
          if (returnData.status == ActionStatus.Successfull) {
            const fileName1 = returnData.Key.split("/").pop();
            const transformedItem = {
              id: Math.floor(Math.random() * 1000000),
              src: FilePath + returnData.Key,
              width: 1,
              height: 1,
              fileName: fileName1,
            };
            setFileName(transformedItem.fileName)
            setFieldValue("AgreementFilePath", 
              returnData.Key,
            );
           
          } else {
            // handleparentsuccess([
            //   "error",
            //   "Error uploading file. Please try again.",
            // ]);
          }
        });
      };

  const handleFileUploader = (documentType) => {
    primaryDocumentRef.current.click();
  };

  const handlecancel = () => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
    navigate(`/agency/viewPropertyDetails?propertyUID=${propertyUId}`);
  }

  const handledeletefile = () => {
    setFileName('')
  }


  return (
  <div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
          <SubNav title={"Upload Agreements"} goback={true} extraData={true} />
          <ToastAlert ref={ToastRef} />
          <div className="details-form p-3" style={{background:'#fff'}}>
          <form onSubmit={handleSubmit} className="details-form" style={{ display: 'initial', width: '100%' }}>
          <Collapse
              id={"Agent"}
              title={"Upload Management Agreement"}
              data={<div>
                <div className="p-2">
                <div className="contacts-group row">
                  <TextBox isMandatory type={"date"} placeholder="Agreement Start Date" label="Agreement Start Date" name="AgreementDate" value={values.AgreementDate} onChange={handleChange} onBlur={handleBlur} error={errors.AgreementDate} touched={touched.AgreementDate} />
                  <TextBox isMandatory label="Management Fee" placeholder="Management Fee"  name="ManagementFee" value={values.ManagementFee} onChange={handleChange} onBlur={handleBlur} error={errors.ManagementFee} touched={touched.ManagementFee} />
                  <TextBox isMandatory label="Marketing Fee" placeholder="Marketing Fee"  name="MarketingFee" value={values.MarketingFee} onChange={handleChange} onBlur={handleBlur} error={errors.MarketingFee} touched={touched.MarketingFee} />
                  <TextBox isMandatory label="Letting Fee" placeholder="Letting Fee"  name="AdministrationFee" value={values.AdministrationFee} onChange={handleChange} onBlur={handleBlur} error={errors.AdministrationFee} touched={touched.AdministrationFee} />
                  <TextBox isMandatory label="NCAT Attendance Fee" placeholder="NCAT Attendance Fee"  name="InitialWeekRent" value={values.InitialWeekRent} onChange={handleChange} onBlur={handleBlur} error={errors.InitialWeekRent} touched={touched.InitialWeekRent} />
                  <TextBox isMandatory label="Admin Fee" placeholder="Admin Fee" name="ApplicationFeesForUtilites" value={values.ApplicationFeesForUtilites} onChange={handleChange} onBlur={handleBlur} error={errors.ApplicationFeesForUtilites} touched={touched.ApplicationFeesForUtilites} />
                  <div className="contacts-group col-md-6">
                    <div className="s1 mb-2">Upload Agreement <span style={{color:'red'}}>*</span></div>
                    <div className={`upload-section ${isDragging ? "dragging" : ""}`} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDragOver={handleDragOver}>
                      <Spin spinning={loading}>
                        <div className="upload-setion-input">
                          {fileName === '' ? <div>
                            <img src="../../../assets/images/upload.png" alt="upload-icon" className="upload-icon" />
                            <span className="upload-select" onClick={() => handleFileUploader()}>Click to upload</span>
                            <input ref={primaryDocumentRef} type="file" onChange={(e) => handleFileChange(e, 0)} className="upload-input" name="upload" multiple />
                            or drag or drop
                          </div> : <div className="mt-3"><span className="upload-select">{fileName}</span><AiOutlineDelete onClick={handledeletefile} style={{ fontSize: '18px', cursor: 'pointer' }} /></div>}
                        </div>
                      </Spin>
                    </div>
                  </div>
                  {errors.AgreementFilePath && touched.AgreementFilePath ? <p className="form-error">{errors.AgreementFilePath}</p> : null}

                </div>  <div className="property-details-buttons">
            <button
              className="pdb-cancel"
              type="reset"
              onClick={handlecancel}
            >
              Cancel
            </button>

            <button className="pdb-save-changes" type="submit">
              Save
            </button>
          </div>

              </div>  
               </div>
               } />
               </form>
              </div>
        </div>
      </div>
    </div>
  );
};

export default UploadAgreementManagement;
