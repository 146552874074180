import React from "react";
import { useEffect, useState, useRef } from "react";
import Header from "../../../components/CommonSection/Header/Header";
import { BiSearch, BiPlus} from "react-icons/bi";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import {AgreementTemplate} from "../../../components/CommonSection/apiresources";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {GetCookie,currentProfile,} from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import "../../../components/Dashboard/Dashboard-agent/AgentList.css";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import {ActionStatus} from "../../../Utilities/enum";
import { addParametersForProfiles ,dynamicUrl} from "../../../Utilities/profileManagement";
import Sidebar from "../../../components/CommonSection/SideBar/sideBar"
import { Paginator } from 'primereact/paginator';
import "../openHome/openHome.css"
import "../Task/task.css"
import { format } from 'date-fns';
import AntdTableExample from "../../input-components/dataTable"
import SubNav from "../../navbar/subNav";

const ListAgreementType = () => {
  const [agreementlist, setAgreementList] = useState([]);
  const [visiblet, setVisiblet] = useState(true);
  const [topicValue, setTopicValue] = useState("Profile");
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agentID, setAgentId] = useState("");
  const [agencyUniqueID, setAgencyUniqueId] = useState("");
  const [chartData, setChartData] = useState();
  const navigate = useNavigate();
  const ToastRef = useRef();
  const [searchText, setSearchText] = useState("");
  const dataTableRef = useRef(null);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("ListAgreement");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [agentUID, setAgentUID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [displayTable, setDisplaytable] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);
  
  
  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgentId(parsedData.agentId);
          setAgencyUniqueId(parsedData.agencyUniqueID   );
          setAgentUID(parsedData.agentId)
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) PostApiWithSpinner();
  }, [userId, searchText]);

  const PostApiWithSpinner = (currentPage,totalRows) => {
    setDisplaytable(false)
    const baseUrl = AgreementTemplate;
    let data = {
      SortBy: "AddedOn",
      SortOrder: "Desc",
      RecordsPerPage: rows,
      AgencyId: agencyID,
      Search: searchText,
      PageNo: currentPage ,
      AgencyUniqueId : agencyUniqueID
    };
    if (data.SearchDateType === "") {
      delete data.SearchDateType;
    }
    data = addParametersForProfiles(data);
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        setDisplaytable(true)
        if (response.status === ActionStatus.Successfull) {
          if(response.list.length > 0){
          let templatesData = response.list;
          let templatesList = templatesData.map((task) => ({
            Title: task.title,
            Content: task.content,
            CreatedDate: format(new Date(task.addedOn), "dd/MM/yyyy hh:mm aa"),
          }));
          setAgreementList(templatesList);
          setTotalPages(response.totalCount)
          setChartData(response.object);
        }else{
          //ToastRef.current.notify(["warn", "User not added"]);
        }
        } else {
          ToastRef.current.notify(["error", response.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", response.message]);
      });
  };

  const onInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const currentSideBar = currentProfile.get();

  const handleTopicValue = (text) => {
    setTopicValue(text);
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const handleAddTask = () => {
    dynamicUrl(navigate, "AddTask");
  };


  const handlePageChange = (event , pageNumber) => {
    PostApiWithSpinner(event.page + 1, event.rows)
    setCurrentPage(pageNumber);
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleAgreement = (e,rowData) => {
    console.log(e,rowData)
    if(rowData.Title === "Management Agreement"){
        dynamicUrl(navigate, "agreementTabs");
    }
    if(rowData.Title === "Residential Tenancy Agreement"){
      dynamicUrl(navigate, "tenancyagreement");
  }
  if(rowData.Title === "Exclusive Sales Agency Agreement"){
    dynamicUrl(navigate, "SaleAgreementTabs");
}
  }

  const actions = [
    { icon: "/assets/images/Add.png", onClick: (e, data) => handleAgreement(e, data), iconSize: "30px", },
]

const [activeTab, setActiveTab] = useState("Agreement Type List");
const tabs = ["Agreement Type List", "Agreement List"];

  return (
   
    <div>
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
            <SubNav title={"Agreement Type List"} goback={false} extraData={true} extraContent={
                <div className="property-details-buttons">
                   
                </div>
            } />
             <div style={{ display: "flex", padding: "5px", background: 'white', margin: '5px 15px' }}>
                        {tabs.map((tab) => (
                            <div key={tab} className={`button ${activeTab === tab ? "active-tab" : ""}`} onClick={() => setActiveTab(tab)}>
                                {tab}
                            </div>
                        ))}
                    </div>
            <div className="material-work-desk-new bg-white m-3">
                <div style={{ margin: '10px' }}>
                   
                </div>
                <AntdTableExample
                    label="List Of Agreement Templates"
                    dataSource={agreementlist}
                    globalSearch
                    hideCol={"id"}
                    onRowClick={handleAgreement}
                    currentPage={currentPage}
                    totalRows={rows}
                    totalRecords={totalPages}
                    actions={actions}
                />
            </div>
        </div>
    </div>
</div>
  );
};

export default ListAgreementType;
