import React, { useEffect, useState, useRef } from "react";
import "./detailsPage.css";
import { useFormik } from "formik";
import { BiBath, BiBed, BiCar } from "react-icons/bi";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { Checkbox,Modal,Carousel  } from "antd";
import {LandingPageDetails,FilePath,AddEnquiry} from "../../CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus, enquiryType } from "../../../Utilities/enum";
import FooterNew from "../FooterNew/FooterNew";
import Topbar from '../../../src/navbar/navbar';
import CustomModal from '../../../src/input-components/Modal'

const initialValues = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  message: "",
  userType: "",
  schedulingInsp: false,
  priceInfo: false,
  ratesFee: false,
  similarProperties: false,
};

const LandingDetailsPage = () => {
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [propertyImg, setPropertyImg] = useState([]);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [basicDetails, setBasicDetails] = useState([]);
  const [displayMarker, setDisplayMarker] = useState(false);
  const [displayImg, setDisplayImg] = useState(false);
  const [center, setCenter] = useState({ lat: 12.971599, lng: 77.594566 });
  const [map, setMap] = React.useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBlCoMA7GMoWtsv6mmdz2Q2AFopWV5PRZY",
  });
  const [agentPic, setAgentPic] = useState("");
  const [userType, setUserType] = useState([
    { label: "Seller", value: 1 },
    { label: "Buyer", value: 2 },
    { label: "Investor", value: 3 },
    { label: "Tenant", value: 4 },
    { label: "Landlord", value: 5 },
    { label: "Developer", value: 5 },
    { label: "Supplier", value: 5 },
  ]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const ToastRef = useRef();

  useEffect(() => {
    var url = new URL(window.location.href);
    var propertyUniqueId = url.searchParams.get("propertyUniqueId");
    PostApiWithSpinner(propertyUniqueId);
  }, []);

  useEffect(() => {
    const storedDisplayMarker = JSON.parse(
      localStorage.getItem("displayMarker")
    );
    if (storedDisplayMarker !== null) {
      setDisplayMarker(storedDisplayMarker);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("displayMarker", JSON.stringify(displayMarker));
  }, [displayMarker]);

  const geocodeAddress = async (address) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=AIzaSyBlCoMA7GMoWtsv6mmdz2Q2AFopWV5PRZY`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        return { lat: location.lat, lng: location.lng };
      }
      return null;
    } catch (error) {
      console.error("Error during geocoding:", error);
      return null;
    }
  };
  

  const PostApiWithSpinner = async (propertyUniqueId) => {
    setDisplayMarker(true);
    const baseUrl = LandingPageDetails;
    const data = {
      PropertyUniqueId: propertyUniqueId,
    };
  
    try {
      const response = await makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        null,
        false
      );
  
      if (response.status === 1) {
        const detailsData = response.object;
        setPropertyDetails(detailsData);
  
        const convertedImgData = detailsData.propertyPictures.map((item) => ({
          src: FilePath + item.picPath,
          width: 10,
          height: 10,
        }));
        setPropertyImg(convertedImgData);
  
        const checkoutDetails = {
          LandArea: detailsData.propertyLandAreaL,
          PropertyAgent: detailsData.agent_Name,
          Project: detailsData.agencyName,
          Status: detailsData.propertyStatus,
        };
        setBasicDetails(checkoutDetails);
  
        const agentPic = detailsData.agent_Photo_Path
          ? FilePath + detailsData.agent_Photo_Path
          : FilePath + "profile/default.png";
        setAgentPic(agentPic);
  
        setDisplayImg(true);
  
        let lat = parseFloat(detailsData.propertyGooglePlacesLocationLat);
        let lng = parseFloat(detailsData.propertyGooglePlacesLocationLong);
  
        if (!lat || !lng) {
          const geocodedLocation = await geocodeAddress(detailsData.getFormatedAddress);
          if (geocodedLocation) {
            lat = geocodedLocation.lat;
            lng = geocodedLocation.lng;
          } else {
            lat = 12.971599; // Default latitude
            lng = 77.594566; // Default longitude
          }
        }
  
        setCenter({ lat, lng });
        setDisplayMarker(true);
      } else {
        alert(`Message: ${response.message}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds();
      if (displayMarker) {
        map.setZoom(18);
        bounds.extend(center);
      } else {
        map.setCenter(center);
        map.setZoom(18);
      }
      setMap(map);
    },
    [center, displayMarker]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = () => {
    var urls = new URL(window.location.href);
    var propertyId = urls.searchParams.get("propertyId");
    const formData = {
      EnquiryDetails: values.message,
      EnquiryType: enquiryType.property,
      PropertyId: propertyId,
      PriceInformation: values.priceInfo,
      ScheduleAnInspection: values.schedulingInsp,
      RatesFees: values.ratesFee,
      SimilarProperties: values.similarProperties,
      UserName: values.firstName + " " + values.lastName,
      UserEmail: values.email,
      UserPhone: values.phone,
      UserType: values.userType,
    };
    const url = AddEnquiry;
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      url,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", returnData.message]);
      });
  };

  const onChange = (e, label) => {
    if (label === "Scheduling") {
      setFieldValue("schedulingInsp", e.target.checked);
    } else if (label === "Price") {
      setFieldValue("priceInfo", e.target.checked);
    } else if (label === "Rates") {
      setFieldValue("ratesFee", e.target.checked);
    } else if (label === "Properties") {
      setFieldValue("similarProperties", e.target.checked);
    }
  };

  const showModal = (index) => {
    setCurrentIndex(index);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    debugger
    setIsModalVisible(false);
  };

  return (
    <div>
     <Topbar/>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style" style={{background:'#f9f9f9'}}>
          <div className="property-container">
            <div className="property-header-container">
              <div className="header-left">
                <h1> {propertyDetails.getFormatedAddress}</h1>
                <p>Just Listed !!</p>
                <div className="property-info2">
                {propertyDetails.countBathrooms ?
                  <span>
                    <BiBed style={{ marginRight: "5px" }} />  {propertyDetails.countBathrooms}{" "} Beds
                  </span> : null }
                  {propertyDetails.countBathrooms ?
                  <span>
                    <BiBath style={{ marginRight: "5px" }} />  {propertyDetails.countBathrooms}{" "} Bath
                  </span> : null }
                  {propertyDetails.countCarParking ||
                    propertyDetails.countGarageSpace ?
                  <span>
                    <BiCar style={{ marginRight: "5px" }} />  {propertyDetails.countCarParking ||
                 propertyDetails.countGarageSpace}{" "} Garage
                  </span> : null }
                </div>
              </div>
              {/* <div className="header-right">
                <button className="tag-button">FOR SALE</button>
                <button className="tag-button">FEATURED</button>
                <button className="tag-button">Hot Offer</button>
              </div> */}
            </div>
            <div className="property-images" onClick={() => showModal(0)}>
              {/* Main Image */}
              <div className="main-image">
                <img
                  src={propertyImg[0]?.src} // First image as the main image
                  alt="Main property"
                />
              </div>

              {/* Image Grid */}
              <div className="image-grid1">
                {propertyImg?.slice(1, 5).map((image, index) => (
                  <div className="image-wrapper" key={index}>

                  <img
                    key={index}
                    src={image.src} // Use the source from the array
                    alt={`Sub image ${index + 1}`}
                  />
                  {index === 3 && propertyImg.length > 5 && (
        <div className="show-more-overlay" onClick={() => showModal(4)}>
          Show More
        </div>
      )}
      </div>
                ))}
              </div>
              
            </div>
            <CustomModal
        visible={isModalVisible}
        onCancel={handleCancel}
        width={800}
        modalData={
          <div>
        <Carousel arrows initialSlide={currentIndex} dotPosition="bottom">
          {propertyImg?.map((image, index) => (
            <div key={index}>
              <img
                src={image.src}
                alt={`Property image ${index + 1}`}
                style={{ width: "100%", maxHeight: "500px", objectFit: "contain" }}
              />
            </div>
          ))}
        </Carousel>
        </div>
        }
      />
            <div className="details-section">
              <div className="details-left">
                {/* Property Details */}
                <div className="property-details-section">
                  <h3>Property Details</h3>
                  <div className="details-grid">
                  {basicDetails.LandArea ?
                    <div className="details-item">
                      <span className="details-label">Land Area</span>
                      <span className="details-value">{basicDetails.LandArea}</span>
                    </div> : null }
                    {basicDetails.PropertyAgent ?
                    <div className="details-item">
                      <span className="details-label">Property Agent</span>
                      <span className="details-value">{basicDetails.PropertyAgent}</span>
                    </div> : null }
                    {/* <div className="details-item">
                      <span className="details-label">Project</span>
                      <span className="details-value">{basicDetails.Project}</span>
                    </div>
                    <div className="details-item">
                      <span className="details-label">Status</span>
                      <span className="details-value">{basicDetails.Status}</span>
                    </div> */}
                  </div>
                </div>

                {/* Why Buy Section */}
                <div className="why-buy-section">
                  {/* Why Buy Section */}
                  <div className="why-buy">
                    <h3>Why buy in this property?</h3>
                    <p>
                    <pre className="customPre">{propertyDetails.propertyDescription} </pre>
                    </p>
                    {/* <button className="read-more">Show More</button> */}
                  </div>

                  {/* Features Section */}
                  {/* <div className="features">
                    <h3>Features</h3>
                    <ul>
                      {features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                      ))}
                    </ul>
                  </div> */}

                  {/* Location Benefits Section */}
                  {/* <div className="location-benefits">
                    <h3>Location Benefits</h3>
                    <div className="location-info">
                      <p>
                        <strong>Family-friendly:</strong> Close to parks and recreational
                        facilities.
                      </p>
                      <p>
                        <strong>Education:</strong> Near quality schools and childcare
                        centers.
                      </p>
                      <p>
                        <strong>Transport:</strong> Easy access to public transport and
                        highways.
                      </p>
                    </div>
                    <p>
                      This brand new home in Austral offers an exceptional lifestyle in a
                      prime location. Don’t miss the opportunity to own this stunning
                      property. Contact Ravinder 0430481530 or Simmi 0406339149 today to
                      arrange a viewing!
                    </p>
                    <p>
                      <strong>Disclaimer:</strong> All information contained herein is
                      provided by third-party sources including but not limited to the
                      owners/developers, valuers, and solicitors. Consequently, we cannot
                      guarantee its accuracy. Any person using this information should rely
                      on their own inquiries and verify all relevant details for their
                      accuracy, effect, and currency.
                    </p>
                  </div> */}
                </div>


              </div>

              {/* Property Agent Section */}
              <div className="details-right">
                <div className="agent-card">
                  <div className="agent-name">{propertyDetails.agencyName}</div>
                  <img
                    src={agentPic}
                    alt="Agent"
                    className="agent-image"
                  />
                  <h5> {propertyDetails.agent_Name}</h5>
                  {/* <p className="agent-rating">4.7 ★★★★☆</p>
                  <div className="agent-review">3 Reviews</div> */}
                  <button className="get-in-touch">Get in Touch</button>
                  <button className="save-property">Save Property</button>
                </div>
              </div>


            </div>
          </div>
          <div className="enquiry-section">
            <div className="enquiry-container">
              {/* Form Section */}
              <div className="enquiry-form">
                <h3>Send Your Enquiry</h3>
                <form onSubmit={handleSubmit}>
                  <div className="checkbox-group">
                    <Checkbox
                      checked={values.schedulingInsp}
                      onChange={(e) => onChange(e, "Scheduling")}
                    >
                      Scheduling an inspection
                    </Checkbox>
                    <Checkbox
                      checked={values.priceInfo}
                      onChange={(e) => onChange(e, "Price")}
                    >
                      Price information
                    </Checkbox>
                    <Checkbox
                      checked={values.ratesFee}
                      onChange={(e) => onChange(e, "Rates")}
                    >
                      Rates & Fees
                    </Checkbox>
                    <Checkbox
                      checked={values.similarProperties}
                      onChange={(e) => onChange(e, "Properties")}
                    >
                      Similar Properties
                    </Checkbox>
                  </div>
                  <div className="form-container">
                    <div className="form-row">
                      {/* <div className="form-group">
                        <label>User Type</label>
                        <select
                          name="userType"
                          value={values.userType}
                          onChange={(e) => setFieldValue("userType", e.target.value)}
                        >
                          <option value="">Select</option>
                          {userType.map((type) => (
                            <option key={type.value} value={type.value}>
                              {type.label}
                            </option>
                          ))}
                        </select>
                        {touched.userType && errors.userType && (
                          <span className="error">{errors.userType}</span>
                        )}
                      </div> */}
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          type="text"
                          name="firstName"
                          placeholder="Enter first name"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.firstName && errors.firstName && (
                          <span className="error">{errors.firstName}</span>
                        )}
                      </div>
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          type="text"
                          name="lastName"
                          placeholder="Enter last name"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.lastName && errors.lastName && (
                          <span className="error">{errors.lastName}</span>
                        )}
                      </div>
                    </div>

                    <div className="form-row">
                    
                      <div className="form-group">
                        <label>Phone</label>
                        <input
                          type="text"
                          name="phone"
                          placeholder="Enter phone number"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.phone && errors.phone && (
                          <span className="error">{errors.phone}</span>
                        )}
                      </div>
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Enter email address"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.email && errors.email && (
                          <span className="error">{errors.email}</span>
                        )}
                      </div>
                    </div>

                    <div className="form-row">
                    
                      <div className="form-group">
                        <label>Message</label>
                        <textarea
                          name="message"
                          placeholder="Enter message"
                          value={values.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.message && errors.message && (
                          <span className="error">{errors.message}</span>
                        )}
                      </div>
                    </div>
                  </div>

                  <button type="submit" className="submit-btn">
                    Send Enquiry
                  </button>
                </form>
              </div>

              <div className="enquiry-map">
                {isLoaded ? (
                  <GoogleMap
                    mapContainerClassName="google-map-container"
                    center={center}
                    zoom={15}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                  >
                    {displayMarker && <Marker position={center} />}
                  </GoogleMap>
                ) : (
                  <p>Loading map...</p>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
      <FooterNew/>
    </div>
  );
};

export default LandingDetailsPage;
