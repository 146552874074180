import React from "react";
import { useEffect, useState, useRef } from "react";
import { BiPencil } from "react-icons/bi";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import { PropertyList,assignedTo,propertyDetails,getAgreementDocumentListForProperty } from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import "../../../components/Dashboard/Dashboard-agent/AgentList.css";
import "./propertiesagency.css";
import { ActionStatus, ePropertyPurpose } from "../../../Utilities/enum";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import SubNav from "../../navbar/subNav";
import AntdTableExample from "../../input-components/dataTable";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { Select, Steps } from 'antd';
import CustomModal from "../../input-components/Modal";
import { ApartmentOutlined, KeyOutlined, UnorderedListOutlined } from '@ant-design/icons';
import PropertyDetailsAgency from "./PropertyDetailsAgency";
import axios from "axios";
import PropertyOwnerShip from "./propertyOwnerShip";
import PropertyListings from "./propertyListings"
import MainPropertyComponent from "./propertyModal"

const AgentList = () => {
  const [agentLists, setAgentLists] = useState([]);
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [chartData, setChartData] = useState();
  const navigate = useNavigate();
  const ToastRef = useRef();
  const [propertyType, setPropertyType] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [purpose, setPurpose] = useState("");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [openAddProperty, setOpenAddProperty] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [resetAllPage, setResetAllPage] = useState(false);
  const [getDetails, setGetDetails] = useState(null);
  const [dpdata, setDpdata] = useState([]);
  const [getDocDetails, setGetDocDetails] = useState(null);
  var url = new URL(window.location.href);
  var propertyUID = url.searchParams.get("propertyUID");
  var tab = url.searchParams.get("tab"); 

  const propertyTypeitems = [
    { label: "All", value: 0 },
    { label: "Sale", value: 1 },
    { label: "Rental", value: 2 },
    { label: "Lost", value: -1 },
  ];
  const propertyTypeChangeHandler = (value) => {
    setPropertyType(value);
  };
  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          reportData(parsedData.agencyUniqueID);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) PostApiWithSpinner();
  }, [userId, searchText, purpose, propertyType,propertyUID,tab]);

  const PostApiWithSpinner = (currentPage, totalRows) => {
    const baseUrl = PropertyList;
    let data = {
      SortBy: "CreatedDate",
      SortOrder: "Desc",
      RecordsPerPage: totalRows,
      AgencyId: agencyID,
      Search: searchText,
      PropType: propertyType,
      Purpose: purpose,
      PageNo: currentPage,
    };
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          let propertyData = response.object.propertyListing;
          let propertyList = propertyData.map((property) => ({
            Address: property.getFormatedAddress,
            Type: property.sPropertyType,
            Owner: property.primaryContactName,
            Agent: property.agentName,
            Status: property.sPropertyStatus,
            guid: property.propertyUniqueID,
            propertyStatusColor: property.propertyStatusColor
          }));
          setAgentLists(propertyList);
          setTotalPages(response.totalCount)
          setChartData(response.object);
          if(propertyUID){
            GetApi(propertyUID)
            setOpenAddProperty(true)
            if(tab){
            tab === "Property" ? setCurrentStep(1) : tab === "OwnerShip" ? setCurrentStep(2) :  tab === "propertyListing" ||"photos" || "legal" ? setCurrentStep(3): null
            }
          }
        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSearchChange = (searchValue) => {
    setSearchText(searchValue);
  };

  // Handler for page change
  const handlePageChange = (pageNumber, totalRows) => {
    setCurrentPage(pageNumber);
    setRows(totalRows);
    PostApiWithSpinner(pageNumber, totalRows);
  };


  const handleAddAgentClick = () => {
    dynamicUrl(navigate, "properties");
  };

  const tabColumns = {
    Status: {
      colorMap: {
        "Prospect": "gray",
        "Listed": "green",
      },
      defaultColor: 'blue'
    }
  };

  const handleStatClick = (selectedPurpose) => {
    setPurpose(selectedPurpose);
  };

  const handleSearchValueChange = (value) => {
    setSearchText(value);
  };

  const handleOpenProperty = () => {
    resetUrl();
    setOpenAddProperty(true);
    setResetAllPage(true);
    setCurrentStep(1)
  }

  const closeProperty = () => {
    if(tab){
      dynamicUrl(navigate, "viewPropertyDetails" + `?propertyUID=${propertyUID}`);
    }else{
    resetUrl();
    setOpenAddProperty(false);
    setResetAllPage(false);
    setGetDetails(null)
    }
  }

const resetUrl = () => {
  const url = new URL(window.location);
  url.searchParams.delete('propertyUID');
  url.searchParams.delete('tab');
  window.history.replaceState({}, '', url);
}
  const GetApi = (value) => {
    const baseUrl = propertyDetails;
    const apiUrl = `${baseUrl}?propertyID=${value}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get")
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          setGetDetails(response.object);
          GetDocumentsApi(response.object)
          var switchTab = url.searchParams.get("switchTab");
          if (switchTab) {
            setActiveSection(switchTab);
          }
        } else {
          //handleparentsuccess(["warn", response.data.message]);
        }
      })
      .catch((error) => {
        //handleparentsuccess(["error", error]);
      });
  };

  const GetDocumentsApi = (data) => {
    const formData = {
      PropertyId: data.propertyId,
      AgencyId: data.agencyId
    }
    const baseUrl = getAgreementDocumentListForProperty;
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", formData)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          setGetDocDetails(response.list);
        } else {
          handleparentsuccess(["warn", response.data.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error]);
      });
  };

  const reportData = (agencyUID) => {
    const apiUrl = assignedTo;
    const baseUrl = apiUrl + "?agencyUID=" + agencyUID;
    axios.get(baseUrl).then((response) => {
      if (response.data.object) setDpdata(response.data.object);
    });
  };

  const handleTabChange = (key) => {
    debugger;
    if(key === "OwnerShip"){
      setCurrentStep(2)
    }else if (key === "Listings"){
      setCurrentStep(3)
    }
}

  const handleView = (e,data) => {
    e.stopPropagation()
    e.preventDefault()
    dynamicUrl(navigate, "viewPropertyDetails" + `?propertyUID=${data.guid}`);
  }

  const actions = [
    { icon: "/assets/images/dots.svg",key:"View", onClick: (e, data) => handleView(e, data) },
    // { icon: "/assets/images/editing.svg", onClick: (e, data) => handleEdit(e, data) },
]


  return (
    <div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
          <SubNav title={"Properties"} goback={false} extraData={true} extraContent={
            <div className="property-details-buttons">
              <button onClick={handleOpenProperty} className="subnav-btn" type="submit">
                <HiOutlinePlusSmall style={{ fontSize: '24px' }} /> Add
              </button>
            </div>
          } />
          <div className="material-work-desk-new bg-white m-3">
            {chartData != null ?
              <div style={{ borderBottom: '1px solid #e9ecef', padding: '10px' }}>
                <div className="col-lg-8">
                  <span
                    className={`col-lg-2 col-sm-2 stat-item ${purpose === "" ? 'active' : ''}`}
                    onClick={() => handleStatClick("")}
                  >
                    All <span className="count">{chartData.all}</span>
                  </span>
                  <span
                    className={`col-lg-2 col-sm-2 stat-item ${purpose === ePropertyPurpose.Appraisal ? 'active' : ''}`}
                    onClick={() => handleStatClick(ePropertyPurpose.Appraisal)}
                  >
                    Appraisal <span className="count">{chartData.appraisal}</span>
                  </span>
                  <span
                    className={`col-lg-2 col-sm-2 stat-item ${purpose === ePropertyPurpose.Listed ? 'active' : ''}`}
                    onClick={() => handleStatClick(ePropertyPurpose.EnlistForSale)}
                  >
                    Listed <span className="count">{chartData.enlisted}</span>
                  </span>
                  <span
                    className={`col-lg-2 col-sm-2 stat-item ${purpose === ePropertyPurpose.Contract ? 'active' : ''}`}
                    onClick={() => handleStatClick(ePropertyPurpose.Contract)}
                  >
                    Contract <span className="count">{chartData.contract}</span>
                  </span>
                  <span
                    className={`col-lg-3 col-sm-2 stat-item ${purpose === ePropertyPurpose.Prospect ? 'active' : ''}`}
                    onClick={() => handleStatClick(ePropertyPurpose.Prospect)}
                  >
                    Prospect <span className="count">{chartData.prospect}</span>
                  </span>
                </div>
              </div> : null}
            <div style={{ float: 'right', margin: '10px' }}><Select value={propertyType} style={{ width: 120 }} options={propertyTypeitems} onChange={propertyTypeChangeHandler}
            /></div>
            <AntdTableExample
              label="List of Properties1"
              dataSource={agentLists}
              globalSearch
              hideCol={"propertyStatusColor"}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              totalRows={rows}
              totalRecords={totalPages}
              tagColumns={tabColumns}
              onSearchValueChange={handleSearchValueChange}
              actions={actions}
              onRowClick={handleView}
            />
          </div>
          <MainPropertyComponent
            isVisible={openAddProperty}
            onClose={closeProperty}
            propertyUID={propertyUID}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            dpdata={dpdata}
            getDetails={getDetails}
            getDocDetails={getDocDetails}
            resetAllPage={resetAllPage}
            handleTabChange={handleTabChange}
          />
        </div>
      </div>
    </div>
  );
};

export default AgentList;
