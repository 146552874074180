import React, { useState } from "react";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { useEffect } from "react";
import { useFormik } from "formik";
import TextBox from "../../input-components/textbox";
import {ABNLookUpGuid,ABNLookUpbaseURL,ContactCompanyUrl} from "../../../components/CommonSection/apiresources";
import { isNullOrEmpty } from "../../../components/CommonSection/Utility/ComponentFunction";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { ActionStatus } from "../../../Utilities/enum";


const CompanyDetails = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  closeModal
}) => {
  const { showSpinner, hideSpinner } = useSpinnerContext();

  const initialValues = {
    companyName: "",
    abn: "",
    acn: "",
  };

  const [edit, setEdit] = useState(true);


  const handleABNLoopup = (event) => {
    const abnNumber = event.target.value;

    handleBlur(event);
    if (!isNullOrEmpty(abnNumber)) {
      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        `${ABNLookUpbaseURL}/AbnDetails.aspx?abn=${abnNumber}&guid=${ABNLookUpGuid}`,
        "get"
      ).then((returnData) => {
        debugger;
        const jsonString = returnData.match(/\{.*\}/)[0];
        const parsedData = JSON.parse(
          jsonString.slice(jsonString.indexOf("{"))
        );
        if (!isNullOrEmpty(parsedData.Abn)) {
          setFieldError("abn", "");
          setFieldValue("companyName", parsedData.EntityName);
          setFieldValue("acn", parsedData.Acn);
        } else {
          setFieldError("abn", "Invalid ABN number");
          setFieldValue("companyName", "");
          setFieldValue("acn", "");
          handleparentsuccess(["error", "Invalid ABN "]);
        }
      });
    }
  };

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      submitContactDetails();
    },
  });
  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setEdit(false)
    }
  }, [getDetails]);

  const submitContactDetails = () => {
    const formData = {
      ContactUniqueId: getDetails.contactUniqueId,
      CompanyName: values.companyName,
      ABN: values.abn,
      ACN: values.acn,
    };
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      ContactCompanyUrl,
      "post",
      formData
    )
      .then((returnData) => {
        debugger;
        if (returnData.status == ActionStatus.Successfull) {
          //handleparentsuccess(["success", returnData.message]);
          handleCurrentPage(3);
        } else {
          //handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        //handleparentsuccess(["error", error.message]);
        console.error("Error:", error);
      });
  };

  const handleEdit = () => {
    setEdit(!edit)
  }


  const handlecancel = () => {
    closeModal();
  };

  return (
    <div>
          <form action="none" onSubmit={handleSubmit}>
            <div className="p-3">
              <div className="row contacts-group">
                <TextBox
                  label="ABN"
                  placeholder="ABN Number"
                  name="abn"
                  value={values.abn}
                  onChange={handleChange}
                  onBlur={handleABNLoopup}
                  error={errors.abn}
                  touched={touched.abn}
                />
                <TextBox
                  label="ACN"
                  placeholder="ACN "
                  name="acn"
                  value={values.acn}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.acn}
                  touched={touched.acn}
                  readOnly={true}
                />
              </div>
              <div style={{marginLeft:'-12px'}} className="contacts-group pd-address">
                <TextBox
                  colVal={12}
                  label="Company Name"
                  placeholder="Company Name"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.companyName}
                  touched={touched.companyName}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="property-details-buttons">
              <button
                className="pdb-cancel"
                type="reset"
                onClick={() =>handlecancel()}
              >
                Cancel
              </button>
              <button className="pdb-save-changes" type="submit">
                Save
              </button>
            </div>
          </form>
    </div>
  );
};

export default CompanyDetails;
