import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {updatemangmntagrmntrepairsdetails,generatePdfReport} from "../../../components/CommonSection/apiresources";
import TextBox from "../../input-components/textbox";
import {makeRequestWithSpinner,DownloadFiletWithSpinner} from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Switch } from "antd";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import "../openHome/openHome.css";
import Collapse from "../../input-components/collapse";
import { BiDollar } from "react-icons/bi";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { repairsandmaintainance } from "../../../components/CommonSection/Schema/schema";

dayjs.extend(customParseFormat);

const initialValues = {
  TradesmanPayment: "",
  SupplyUrgentRepairToTenant: false,
  LooseFillAsbestosInstallation: false,
  SwimmingPoolRegistered: false,
  SmokeAlarm: false,
  HealthIssues: false,
  Flooding: false,
  Bushfire: false,
  SeriousViolentCrime: false,
  ParkingRestriction: false,
  ShareDriveway: false,
  AffectdFlooding: false,
  AffectdBushfire: false,
  AffectdSeriousViolent: false,
  IsContractForSalePremises: false,
  IsProposelToSell: false,
  HasMortgageeCommenced: false,
  isMortgageeTakgActnPossssion: false,
  WaterEfficiencyByNSW: false,
  PrincipalWarrantsSmokeAlarm: false,
};

const RepairsAndMaintainance = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  reportTo,
  handleNextPage,
}) => {
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");

        if (userData) {
          const parsedData = JSON.parse(userData);
          console.log(parsedData, "parsedData");
          setUserId(parsedData.userId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };

    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setFieldValue("TradesmanPayment", getDetails.tradesmanPayment);
      setFieldValue(
        "SupplyUrgentRepairToTenant",
        getDetails.supplyUrgentRepairToTenant
      );
      setFieldValue(
        "LooseFillAsbestosInstallation",
        getDetails.looseFillAsbestosInstallation
      );
      setFieldValue(
        "SwimmingPoolRegistered",
        getDetails.swimmingPoolRegistered
      );
      setFieldValue("SmokeAlarm", getDetails.smokeAlarm);
      setFieldValue("HealthIssues", getDetails.healthIssues);
      setFieldValue("Flooding", getDetails.flooding);
      setFieldValue("Bushfire", getDetails.bushfire);
      setFieldValue("SeriousViolentCrime", getDetails.seriousViolentCrime);
      setFieldValue("ParkingRestriction", getDetails.parkingRestriction);
      setFieldValue("ShareDriveway", getDetails.shareDriveway);
      setFieldValue("AffectdFlooding", getDetails.affectdFlooding);
      setFieldValue("AffectdBushfire", getDetails.AffectdBushfire);
      setFieldValue("AffectdSeriousViolent", getDetails.affectdSeriousViolent);
      setFieldValue(
        "IsContractForSalePremises",
        getDetails.isContractForSalePremises
      );
      setFieldValue("IsProposelToSell", getDetails.isProposelToSell);
      setFieldValue("HasMortgageeCommenced", getDetails.hasMortgageeCommenced);
      setFieldValue(
        "isMortgageeTakgActnPossssion",
        getDetails.isMortgageeTakgActnPossssion
      );
      setFieldValue("WaterEfficiencyByNSW", getDetails.waterEfficiencyByNSW);
      setFieldValue(
        "PrincipalWarrantsSmokeAlarm",
        getDetails.principalWarrantsSmokeAlarm
      );
    }
  }, [getDetails, reportTo]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: repairsandmaintainance,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (data) => {
    let formData = {
      AgreementId: getDetails.id,
      TradesmanPayment: values.TradesmanPayment,
      SupplyUrgentRepairToTenant: values.SupplyUrgentRepairToTenant,
      LooseFillAsbestosInstallation: values.LooseFillAsbestosInstallation,
      SwimmingPoolRegistered: values.SwimmingPoolRegistered,
      SmokeAlarm: values.SmokeAlarm,
      HealthIssues: values.HealthIssues,
      Flooding: values.Flooding,
      Bushfire: values.Bushfire,
      SeriousViolentCrime: values.SeriousViolentCrime,
      ParkingRestriction: values.ParkingRestriction,
      ShareDriveway: values.ShareDriveway,
      AffectdFlooding: values.AffectdFlooding,
      AffectdBushfire: values.AffectdBushfire,
      AffectdSeriousViolent: values.AffectdSeriousViolent,
      IsContractForSalePremises: values.IsContractForSalePremises,
      IsProposelToSell: values.IsProposelToSell,
      HasMortgageeCommenced: values.HasMortgageeCommenced,
      isMortgageeTakgActnPossssion: values.isMortgageeTakgActnPossssion,
      WaterEfficiencyByNSW: values.WaterEfficiencyByNSW,
      PrincipalWarrantsSmokeAlarm: values.PrincipalWarrantsSmokeAlarm,
      AgencyId: getDetails.agencyId,
      UserId: userId,
    };
    const url = updatemangmntagrmntrepairsdetails;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          onDataRecive(getDetails.propertyUId);
          ToastRef.current.notify(["success", returnData.message]);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleClear = () => {
    handleNextPage("PromotionalActivities");
  };

  const onChangeSwitch = (e, label) => {
    if (label === "TradesmanPayment") {
      setFieldValue("TradesmanPayment", e);
    } else if (label === "SupplyUrgentRepairToTenant") {
      setFieldValue("SupplyUrgentRepairToTenant", e);
    } else if (label === "LooseFillAsbestosInstallation") {
      setFieldValue("LooseFillAsbestosInstallation", e);
    } else if (label === "SwimmingPoolRegistered") {
      setFieldValue("SwimmingPoolRegistered", e);
    } else if (label === "SmokeAlarm") {
      setFieldValue("SmokeAlarm", e);
    } else if (label === "HealthIssues") {
      setFieldValue("HealthIssues", e);
    } else if (label === "Flooding") {
      setFieldValue("Flooding", e);
    } else if (label === "Bushfire") {
      setFieldValue("Bushfire", e);
    } else if (label === "SeriousViolentCrime") {
      setFieldValue("SeriousViolentCrime", e);
    } else if (label === "ParkingRestriction") {
      setFieldValue("ParkingRestriction", e);
    } else if (label === "ShareDriveway") {
      setFieldValue("ShareDriveway", e);
    } else if (label === "AffectdFlooding") {
      setFieldValue("AffectdFlooding", e);
    } else if (label === "AffectdBushfire") {
      setFieldValue("AffectdBushfire", e);
    } else if (label === "AffectdSeriousViolent") {
      setFieldValue("AffectdSeriousViolent", e);
    } else if (label === "IsContractForSalePremises") {
      setFieldValue("IsContractForSalePremises", e);
    } else if (label === "IsProposelToSell") {
      setFieldValue("IsProposelToSell", e);
    } else if (label === "HasMortgageeCommenced") {
      setFieldValue("HasMortgageeCommenced", e);
    } else if (label === "isMortgageeTakgActnPossssion") {
      setFieldValue("isMortgageeTakgActnPossssion", e);
    } else if (label === "WaterEfficiencyByNSW") {
      setFieldValue("WaterEfficiencyByNSW", e);
    } else if (label === "PrincipalWarrantsSmokeAlarm") {
      setFieldValue("PrincipalWarrantsSmokeAlarm", e);
    }
  };

  const handleCancle = () => {
    navigate("/agency/listagreementtype");
  };

  const handleAgreementDownload = () => {
    debugger;
    const formData = {
      PropertyUId: getDetails.propertyUId,
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generatePdfReport,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData != null) {
          window.open(returnData, "_blank");
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  return (
    <>
      <div className="dashboard-agency">
        <ToastAlert ref={ToastRef} />
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <div style={{ width: "100%" }}>
              <form onSubmit={handleSubmit} className="details-form">
                <div>
                  <div>
                    <Collapse
                      id={
                        "The Agent shall be entitled to the following ongoing fees"
                      }
                      title={"Repairs and Maintenance"}
                      data={
                        <div>
                          {/* <div className="s1" style={{ padding: "0px 30px" }}>The agent is authorised to engage a suitably qualified tradesperson to undertake repairs up to an amount of</div> */}
                          <div
                            className="pddpn-group"
                            style={{ padding: "0px 30px" }}
                          >
                            <TextBox
                              isMandatory
                              label="Payment"
                               placeholder="Payment"
                              name="TradesmanPayment"
                              value={values.TradesmanPayment}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.TradesmanPayment}
                              touched={touched.TradesmanPayment}
                              icon={<BiDollar height="100%" width="100%" />}
                            />
                          </div>
                        </div>
                      }
                    />
                    <Collapse
                      id={"Agreement-Authority"}
                      title={"Material Facts"}
                      data={
                        <div style={{ padding: "0px 30px" }}>
                          <div className="pddpn-group">
                            <div
                              className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%" }}
                                className="mr-5 s1"
                              >
                                Loose Fill Asbestos Installation
                              </div>
                              <div>
                                <Switch
                                  checked={values.LooseFillAsbestosInstallation}
                                  onChange={(e) =>
                                    onChangeSwitch(
                                      e,
                                      "LooseFillAsbestosInstallation"
                                    )
                                  }
                                />
                              </div>
                            </div>

                            <div
                              className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%" }}
                                className="mr-3 s1"
                              >
                                Smoke alarm
                              </div>
                              <div>
                                <Switch
                                  checked={values.SmokeAlarm}
                                  onChange={(e) =>
                                    onChangeSwitch(e, "SmokeAlarm")
                                  }
                                />
                              </div>
                            </div>

                            <div
                              className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%" }}
                                className="mr-5 s1"
                              >
                                Swimming pool registered
                              </div>
                              <div>
                                <Switch
                                  checked={values.SwimmingPoolRegistered}
                                  onChange={(e) =>
                                    onChangeSwitch(e, "SwimmingPoolRegistered")
                                  }
                                />
                              </div>
                            </div>

                            <div
                              className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%" }}
                                className="mr-5 s1"
                              >
                                Health issues
                              </div>
                              <div>
                                <Switch
                                  checked={values.HealthIssues}
                                  onChange={(e) =>
                                    onChangeSwitch(e, "HealthIssues")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    />
                    <Collapse
                      id={"Agreement-Authority"}
                      title={
                        "In The Last 5 Years Has The Property Been Subject To"
                      }
                      data={
                        <div style={{ padding: "0px 30px" }}>
                          <div className="pddpn-group">
                            <div
                              className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%" }}
                                className="mr-5 s1"
                              >
                                Flooding
                              </div>
                              <div>
                                <Switch
                                  checked={values.Flooding}
                                  onChange={(e) =>
                                    onChangeSwitch(e, "Flooding")
                                  }
                                />
                              </div>
                            </div>

                            <div
                              className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%" }}
                                className="mr-3 s1"
                              >
                                Bushfire
                              </div>
                              <div>
                                <Switch
                                  checked={values.Bushfire}
                                  onChange={(e) =>
                                    onChangeSwitch(e, "Bushfire")
                                  }
                                />
                              </div>
                            </div>

                            <div
                              className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%" }}
                                className="mr-5 s1"
                              >
                                Serious violent crime
                              </div>
                              <div>
                                <Switch
                                  checked={values.SeriousViolentCrime}
                                  onChange={(e) =>
                                    onChangeSwitch(e, "SeriousViolentCrime")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    />
                    <Collapse
                      id={"Agreement-Authority"}
                      title={"Affected by"}
                      data={
                        <div style={{ padding: "0px 30px" }}>
                          <div className="pddpn-group">
                            <div
                              className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%" }}
                                className="mr-5 s1"
                              >
                                Parking Restrictions
                              </div>
                              <div>
                                <Switch
                                  checked={values.ParkingRestriction}
                                  onChange={(e) =>
                                    onChangeSwitch(e, "ParkingRestriction")
                                  }
                                />
                              </div>
                            </div>

                            <div
                              className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%" }}
                                className="mr-3 s1"
                              >
                                Share driveways
                              </div>
                              <div>
                                <Switch
                                  checked={values.ShareDriveway}
                                  onChange={(e) =>
                                    onChangeSwitch(e, "ShareDriveway")
                                  }
                                />
                              </div>
                            </div>

                            <div
                              className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%" }}
                                className="mr-5 s1"
                              >
                                Serious violent crime
                              </div>
                              <div>
                                <Switch
                                  checked={values.AffectdSeriousViolent}
                                  onChange={(e) =>
                                    onChangeSwitch(e, "AffectdSeriousViolent")
                                  }
                                />
                              </div>
                            </div>

                            <div
                              className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%" }}
                                className="mr-5 s1"
                              >
                                Flooding
                              </div>
                              <div>
                                <Switch
                                  checked={values.AffectdFlooding}
                                  onChange={(e) =>
                                    onChangeSwitch(e, "AffectdFlooding")
                                  }
                                />
                              </div>
                            </div>

                            <div
                              className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%" }}
                                className="mr-5 s1"
                              >
                                Bushfire
                              </div>
                              <div>
                                <Switch
                                  checked={values.AffectdBushfire}
                                  onChange={(e) =>
                                    onChangeSwitch(e, "AffectdBushfire")
                                  }
                                />
                              </div>
                            </div>

                            <div
                              className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%" }}
                                className="mr-5 s1"
                              >
                                Is there a contract for the sale of the premises
                              </div>
                              <div>
                                <Switch
                                  checked={values.IsContractForSalePremises}
                                  onChange={(e) =>
                                    onChangeSwitch(
                                      e,
                                      "IsContractForSalePremises"
                                    )
                                  }
                                />
                              </div>
                            </div>

                            <div
                              className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%" }}
                                className="mr-5 s1"
                              >
                                Is there a proposal to sell the premises
                              </div>
                              <div>
                                <Switch
                                  checked={values.IsProposelToSell}
                                  onChange={(e) =>
                                    onChangeSwitch(e, "IsProposelToSell")
                                  }
                                />
                              </div>
                            </div>

                            <div
                              className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%" }}
                                className="mr-5 s1"
                              >
                                Has a mortgagee commenced proceedings in court
                                to enforce a mortgage over the premises
                              </div>
                              <div>
                                <Switch
                                  checked={values.HasMortgageeCommenced}
                                  onChange={(e) =>
                                    onChangeSwitch(e, "HasMortgageeCommenced")
                                  }
                                />
                              </div>
                            </div>

                            <div
                              className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%" }}
                                className="mr-5 s1"
                              >
                                Is the mortgagee taking action for possession of
                                the property
                              </div>
                              <div>
                                <Switch
                                  checked={values.isMortgageeTakgActnPossssion}
                                  onChange={(e) =>
                                    onChangeSwitch(
                                      e,
                                      "isMortgageeTakgActnPossssion"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    />

                    <Collapse
                      id={"Agreement-Authority"}
                      title={"Water Efficiency Devises"}
                      data={
                        <div style={{ padding: "0px 30px" }}>
                          <div className="s1 pl-3">
                            The agent is authorized to:
                          </div>
                          <div>
                            <div
                              className="pd-eventDate col-lg-12 col-sm-12 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%" }}
                                className="mr-5 s1"
                              >
                                The premises contain the water efficiency
                                measures prescribed by the Residential Tenancies
                                Act 2010 (NSW)
                              </div>
                              <div>
                                <Switch
                                  checked={values.WaterEfficiencyByNSW}
                                  onChange={(e) =>
                                    onChangeSwitch(e, "WaterEfficiencyByNSW")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    />

                    <Collapse
                      id={"Agreement-Authority"}
                      title={"Smoke Alarms"}
                      data={
                        <div style={{ padding: "0px 30px" }}>
                          <div>
                            <div
                              className="pd-eventDate col-lg-12 col-sm-12 mt-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%" }}
                                className="mr-5 s1"
                              >
                                The Principal warrants that the Premises comply
                                with the Environmental Planning and Assessment
                                Amendment (Smoke Alarms) Regulation 2006 (NSW){" "}
                              </div>
                              <div>
                                <Switch
                                  checked={values.PrincipalWarrantsSmokeAlarm}
                                  onChange={(e) =>
                                    onChangeSwitch(
                                      e,
                                      "PrincipalWarrantsSmokeAlarm"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
                <div className="property-details-buttons">
                  <button
                    className="pdb-save-changes"
                    type="reset"
                    onClick={handleCancle}
                  >
                    Cancel
                  </button>
                  <button
                    className="pdb-cancel"
                    type="reset"
                    onClick={handleClear}
                  >
                    Previous
                  </button>
                  <button
                    className="pdb-save-changes"
                    type="button"
                    onClick={handleAgreementDownload}
                  >
                    Preview
                  </button>
                  <button
                    className="pdb-save-changes"
                    type="button"
                    onClick={handleFinalSubmit}
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RepairsAndMaintainance;
