import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import "../../openHome/openHome.css";
import ShowValue from "../../../input-components/ShowValue"
import { FormatDateView } from "../../../../components/CommonSection/Utility/Utility";
import { MdPercent } from "react-icons/md";
import TextBox from "../../../input-components/textbox";
import { updatesalesagrmntremunerationdetails, FilePath, salesagreementsignature, generatesalesagreementPdfReport,getsalesagreementforsign } from "../../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner, uploadToS3WithSpinner, DownloadFiletWithSpinner, uploadSignToS3WithSpinner, } from "../../../../Utilities/client";
import { ActionStatus, signatureStatus } from "../../../../Utilities/enum";
import SignatureCanvas from "react-signature-canvas";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import TextArea from "../../../input-components/textarea";
import { DatePicker } from "antd";

dayjs.extend(customParseFormat);

const initialValues = {
  rent: "",
  bondAmount: "",
  propertyFurnished: false,
  propertyUnfurnished: false,
  fName: "",
  businessName: "",
  agencyLicenceNumber: "",
  agencyAbn: "",
  agencyName: "",
  agencyEmail: "",
  fax: "",
  mobile: "",
  agencyWorkPhone: "",
  solicitorFirmName: "",
  SalePriceFlatFee: '',
  LicenceeEstimateSalePrice: false,
  GSTInclusiveRenumeration: false,
  IsEFT: '',
  PAccountName: '',
  PBSB: '',
  PAccountNo: '',

  AgreementDate: "",
  LeasePremisesAtMarketRent: "",
  ReferToPrincipalForReLease: "",
  ReviewRentBfrRLease: "",
  PropertyImages: [],
  SignedDate: "",
  AgentSignedDate: "",
};

const SalesViewPage = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  reportTo,
  closeModal,
  handleNextPage1,
}) => {
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();
  const [viewAddressDetails, setViewAddressDetails] = useState(false);
  const [address, setAddress] = useState("");
  const [secondaryOwner, setSecondaryOwner] = useState([]);
  const [primaryContact, setPrimaryContact] = useState({});

  const [propertyimageitems, setPrincipleimageitems] = useState([]);
  const [principleImages, setPrincipleImages] = useState();
  const inputRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const signatureRef = useRef(null);
  const [sign, setSign] = useState(null);
  const [IsSigned, setIsSigned] = useState(null);
  const [date, setDate] = useState("");
  const [verified, setVerified] = useState(false);
  const [signatureText, setSignatureText] = useState("");
  const [getDetails, setGetDetails] = useState(null);

  useEffect(() => {
    if (getDetails) {
     
    }
  }, [getDetails]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);

  useEffect(() => {
    var url = new URL(window.location.href);
    var did = url.searchParams.get("did");
    var utype = url.searchParams.get("utype");
    var ref = url.searchParams.get("ref");
    var activeTab = url.searchParams.get("activeTab");
    GetApi(did, utype, ref);
  }, []);

  const GetApi = (did, utype, ref) => {
    let formData = {
      DocumentId: did,
      UserType: utype,
      Reference: ref,
    };
    const url = getsalesagreementforsign;
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      url,
      "post",
      formData,
      null,
      false
    )
      .then((response) => {
        if (response.object !== null) {
          setGetDetails(response.object);
          const refInt = parseInt(ref);
          const document = response.object?.documentReceipients?.find(
            (doc) => utype === '3' ? doc.agentId : doc.contactId === refInt
          );
          if (document) {
            if (document.isSigned) {
              //setHandleNextPages("AgreementSignature");
            }
          }
          let getDetails = response.object

          setValues({ ...values, ...getDetails });
          setFieldValue("rent", getDetails.rent);
          setFieldValue("bondAmount", getDetails.bondAmount);
          setFieldValue("propertyFurnished", getDetails.propertyFurnished);
          setFieldValue("propertyUnfurnished", getDetails.propertyUnfurnished);
          setSecondaryOwner(getDetails.contactlistModel);
          setPrimaryContact(getDetails.primarycontactModel);
          setAddress(getDetails.primarycontactModel.getFullAddress)
          setFieldValue("businessName", getDetails.companyName);
          setAddress(getDetails.agencyAddress);
    
          setFieldValue("SalePriceFlatFee", getDetails.salePriceFlatFee)
          setFieldValue("LicenceeEstimateSalePrice", getDetails.licenceeEstimateSalePrice)
          setFieldValue("GSTInclusiveRenumeration", getDetails.gstInclusiveRenumeration)
          setFieldValue("IsEFT", getDetails.isEFT)
          setFieldValue("PAccountName", getDetails.pAccountName)
          setFieldValue("PBSB", getDetails.pbsb)
          setFieldValue("PAccountNo", getDetails.pAccountNo)
        }
      })
      .catch((error) => {
        //handleparentsuccess(["error", error]);
      });
  };

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (data) => {
    let formData = {
      AgreementId: getDetails.id,
      SalePriceFlatFee: parseInt(getDetails.salePriceFlatFee),
      LicenceeEstimateSalePrice: parseInt(getDetails.licenceeEstimateSalePrice),
      GSTInclusiveRenumeration: parseInt(getDetails.gstInclusiveRenumeration),
      IsEFT: getDetails.isEFT ? 1:2,
      PAccountName: values.PAccountName ?  values.PAccountName : getDetails.pAccountName,
      PBSB: values.PBSB ? values.PBSB :  getDetails.pbsb,
      PAccountNo: values.PAccountNo ? values.PAccountNo:  getDetails.pAccountNo,
    };
    const url = updatesalesagrmntremunerationdetails;

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      url,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          getSignatureImage()
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleView = () => {
    setViewAddressDetails(!viewAddressDetails);
  };

  const handlepressSelect = async (addrs) => {
    setAddress(addrs);
    setPrimaryContact((prevState) => ({
      ...prevState,
      address: addrs,
      getFullAddress: addrs,
    }));
  };


  const clearSignature = () => {
    signatureRef.current.clear();
  };

  // Function to get the signature data as an image URL
  const getSignatureImage = () => {
    if (activeIndex === 1) {
      handleFinalSubmitSign();
    } else {
      setSign(signatureRef.current.toDataURL());
      handleSignUpload(signatureRef.current.toDataURL());
      return signatureRef.current.toDataURL();
    }
  };

  useEffect(() => {
    // Set default value after component mounts
    const matchedObject = getDetails?.documentReceipients?.filter((doc) =>
      utype === "3" ? doc.agentId : doc.contactId === parseInt(ref)
    );
    setIsSigned(matchedObject?.length > 0 ? matchedObject[0].isSigned : false);
    if (signatureRef.current && matchedObject[0].signValue) {
      signatureRef.current.clear(); // Clear the canvas
      // Draw the saved signature
      const ctx = signatureRef.current.getCanvas().getContext("2d");
      const image = new Image();
      image.src = matchedObject[0]?.signValue;
      image.onload = () => {
        ctx.drawImage(image, 0, 0);
      };
    }
  }, [activeIndex]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);

  useEffect(() => {
    const today = new Date();
    const formattedDate = `${today.getDate().toString().padStart(2, "0")}/${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${today.getFullYear()}`;
    setDate(formattedDate); // Initialize the date as dd/mm/yyyy
}, []);



  useEffect(() => {
    const url = new URL(window.location.href);
    let ref = url.searchParams.get("ref");
    let utype = url.searchParams.get("utype");
    setVerified(utype === "3" ? true : false);
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setFieldValue("AgreementDate", formatDate(getDetails.agreementDate));
      setFieldValue(
        "ReferToPrincipalForReLease",
        getDetails.referToPrincipalForReLease
      );
      setFieldValue("ReviewRentBfrRLease", getDetails.reviewRentBfrRLease);
      setFieldValue(
        "LeasePremisesAtMarketRent",
        getDetails.leasePremisesAtMarketRent
      );
      setSecondaryOwner(getDetails.contactlistModel);
      const matchedObject = getDetails?.documentReceipients?.filter(
        (doc) => doc.contactId === parseInt(ref)
      );
      setFieldValue(
        "SignedDate",
        formatDate(matchedObject?.length > 0 ? matchedObject[0].signedDate : "")
      );
      function formatDate(dateString) {
        if (!dateString) {
          return "";
        }
        const [year, month, day] = dateString.split("T")[0].split("-");
        return `${day}-${month}-${year}`;
      }
      let contacts = getDetails.contactlistModel
        ? getDetails.contactlistModel
        : [];
      const matchedObjects =
        contacts.length === 0
          ? []
          : getDetails.contactlistModel.filter(
            (doc) => doc.contactId === parseInt(ref)
          );
      if (getDetails.primarycontactModel?.contactId === parseInt(ref)) {
        setVerified(getDetails.primarycontactModel.isUserVerified);
      } else if (contacts.length != 0) {
        if (
          matchedObjects[0] != null &&
          matchedObjects[0].contactId === parseInt(ref)
        ) {
          setVerified(matchedObjects[0].isUserVerified);
        }
      }
    }
  }, [getDetails, reportTo]);

  const isVerified = () => {
    setVerified(true);
  };


  const handleFinalSubmitSign = (principleImages) => {
    const urls = new URL(window.location.href);
    let ref = urls.searchParams.get("ref");
    let signMethods =
      activeIndex + 1 === signatureStatus.Image
        ? 1
        : activeIndex + 1 === signatureStatus.Type
          ? 2
          : activeIndex + 1 === signatureStatus.Draw
            ? 3
            : "";
    let formData = {
      DocumentId: did,
      ContactId: utype === "10" ? ref : null,
      AgentId: utype === "3" ? ref : null,
      SignedDate: moment(),
      SignMethod: signMethods,
      SignPicturePath: principleImages ? principleImages : null,
      SignValue: activeIndex === 0 ? sign : activeIndex === 1 ? signatureText : null,
    };
    const url = salesagreementsignature;
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      url,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          setIsSigned(true);
          ToastRef.current.notify(["success", returnData.message]);
          setIsSigned(true);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleClear = () => {
    handleNextPage("AdvertisingAndPromotions");
  };

  const textWithBullet = "\u2022 ";

  const select = (event) => {
    inputRef.current.click();
  };
  const handleSignUpload = async (imgByte) => {
    const url = new URL(window.location.href);
    let ref = url.searchParams.get("ref");
    await uploadSignToS3WithSpinner(
      showSpinner,
      hideSpinner,
      "signatures/",
      `contact_${ref}`,
      imgByte
    ).then((returnData) => {
      if (returnData.status == ActionStatus.Successfull) {
        const transformedItem = {
          id: Math.floor(Math.random() * 1000000),
          src: FilePath + returnData.Key,
          width: 1,
          height: 1,
        };
        setPrincipleimageitems((prevItems) => [...prevItems, transformedItem]);
        setPrincipleImages(returnData.Key);
        handleFinalSubmitSign(returnData.Key);
        handleparentsuccess(["success", "Sign uploaded successfully", true]);
      } else {
        handleparentsuccess([
          "error",
          "Error uploading file. Please try again.",
        ]);
      }
    });
  };
  const handleUplodeImg = async (e, indexforfiles) => {
    for (let i = 0; i < e.target.files.length; i++) {
      selectedFile = e.target.files[i];
      await handleUpload();
    }
  };

  const handleUpload = async () => {
    const url = new URL(window.location.href);
    let ref = url.searchParams.get("ref");
    await uploadToS3WithSpinner(
      showSpinner,
      hideSpinner,
      "signatures/",
      `contact_${ref}`,
      selectedFile
    ).then((returnData) => {
      if (returnData.status == ActionStatus.Successfull) {
        const transformedItem = {
          id: Math.floor(Math.random() * 1000000),
          src: FilePath + returnData.Key,
          width: 1,
          height: 1,
        };
        setPrincipleimageitems((prevItems) => [...prevItems, transformedItem]);
        setPrincipleImages(returnData.Key);
        handleparentsuccess(["success", "File uploaded successfully", true]);
        handleFinalSubmitSign(returnData.Key);
      } else {
        handleparentsuccess([
          "error",
          "Error uploading file. Please try again.",
        ]);
      }
    });
  };

  const url = new URL(window.location.href);
  let ref = url.searchParams.get("ref");
  let utype = url.searchParams.get("utype");
  let did = url.searchParams.get("did");

  const isRefMatched = getDetails?.documentReceipients?.some(
    (doc) => doc.contactId === parseInt(ref)
  );
  const matchedDocumentIds = getDetails?.documentReceipients
    ?.filter((doc) => doc.contactId === parseInt(ref))
    .map((doc) => doc.documentId);

  const matchedObject = getDetails?.documentReceipients?.filter(
    (doc) => doc.contactId === parseInt(ref)
  );

  const handleAgreementDownload = () => {
    const formData = {
      PropertyUId: getDetails.propertyUId,
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generatesalesagreementPdfReport,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData != null) {
          window.open(returnData, "_blank");
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handlePrevious = () => {
    handleNextPage("RepairAndMaintainance");
  };

  const handleSignature = (e) => {
    setSignatureText(e.target.value)
  }


  const handleChangeDate = (date, dateString) => {
    setDate(dateString); // `dateString` is already in the desired format
  };


  return (
    <>
      <div className="dashboard-agency">
        <ToastAlert ref={ToastRef} />
        <div className="continer-dbag">
          <div className="dashboardagency-content" style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <form onSubmit={handleSubmit} className="">
                <div className="property-details-content  all-input" style={{ justifyContent: 'center' }}>
                  <div>
                    <div>
                      {getDetails ? <div
                        className="pddpn-group pddpn-group2 pddpn-group3"
                      >
                        {!IsSigned ?
                          <div>
                            <div className="agreementLabel mt-4" style={{ width: '100%' }}>{`Primary Property Owner(s)`}</div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Name</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {primaryContact.fName + " " + primaryContact.lName}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Email</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {primaryContact.contactEmail}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Mobile Number</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {primaryContact.mobileNumber}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Address</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {primaryContact.address}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Registered for GST</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {getDetails.contactGstRegistered === 1 ? "Yes" : "No"}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">ABN/ACN</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {getDetails ? getDetails.primarycontactModel ? getDetails.primarycontactModel.abn !== null ? getDetails.primarycontactModel.abn : "Nill" : "Nill" : "Nill"}</div>
                              </div>
                            </div>
                            {secondaryOwner
                              ? secondaryOwner.map((contact, index) => (
                                <div style={{ width: '100%' }} >
                                  <div className="agreementLabel">{`Secondary Owner (${index + 1})`}</div>
                                  <div className={`lda-${name} `} >
                                    <div className="address-pair">
                                      <div className="labelssss">Name</div>
                                      <div style={{ marginTop: '3px' }}>:</div>
                                      <div className="valuessss"> {contact.fName + " " + contact.lName}</div>
                                    </div>
                                  </div>
                                  <div className={`lda-${name} `} >
                                    <div className="address-pair">
                                      <div className="labelssss">Email</div>
                                      <div style={{ marginTop: '3px' }}>:</div>
                                      <div className="valuessss"> {contact.contactEmail}</div>
                                    </div>
                                  </div>
                                  <div className={`lda-${name} `} >
                                    <div className="address-pair">
                                      <div className="labelssss">Mobile Number</div>
                                      <div style={{ marginTop: '3px' }}>:</div>
                                      <div className="valuessss"> {contact.mobileNumber}</div>
                                    </div>
                                  </div>
                                  <div className={`lda-${name} `} >
                                    <div className="address-pair">
                                      <div className="labelssss">Address</div>
                                      <div style={{ marginTop: '3px' }}>:</div>
                                      <div className="valuessss"> {contact.getFullAddress}</div>
                                    </div>
                                  </div>
                                </div>
                              ))
                              : null}
                            <div className="agreementLabel mt-4" style={{ width: '100%' }}>{`Agent`}</div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Business Name</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {getDetails.companyName}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">License Number</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {values.agencyLicenceNumber}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">ABN/ACN</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {values.agencyAbn}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Trading as</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {values.businessName}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Address</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {address}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Phone</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {values.agencyWorkPhone}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">PostCode</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {values.agencyPostCode}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Fax</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {values.fax}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Mobile</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {values.mobile}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Email address </div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {values.agencyEmail}</div>
                              </div>
                            </div>
                            <div className="agreementLabel mb-3 mt-4" style={{ width: '100%' }}>{`PRINCIPAL’S SOLICITOR / LICENSED CONVEYANCER`}</div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Name</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {getDetails.solicitorFirmName ? getDetails.solicitorFirmName : ""}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Principal Name</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {getDetails.solicitorPrincipalName ? getDetails.solicitorPrincipalName : " "}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Address</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {getDetails.solicitorAddress ? getDetails.solicitorAddress : ""}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Phone</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {getDetails.solicitorPhone ? getDetails.solicitorPhone : ""}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Fax</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {getDetails.solicitorFax ? getDetails.solicitorFax : ""}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} `} style={{ width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Email address</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {getDetails.solicitorEmail ? getDetails.solicitorEmail : ""}</div>
                              </div>
                            </div>
                            <div className="agreementLabel mb-3 mt-4" style={{ width: '100%' }}>{`Agreement Period`}</div>

                            <div style={{ display: 'flex', width: '100%' }} >
                              <div className={`lda-${name} `} style={{ width: '100%' }}>
                                <ShowValue
                                  Label="From Date"
                                  setData={FormatDateView(getDetails.agreementStartDate)}
                                />
                              </div>
                              <div className={`lda-${name} `} style={{ width: '100%' }}>
                                <ShowValue
                                  Label="To Date"
                                  setData={FormatDateView(getDetails.agreementEndDate)}
                                />
                              </div>
                            </div>
                            <div className="s1 ">Inclusive now called the 'Exclusive Agency Period'</div>
                            <div className=" agreementLabel mb-3 mt-4 " style={{ width: '100%' }}>{`PROPERTY (“the Property”) Description of the Property sufficiently detailed to allow it to be readily identified`}</div>
                            <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }} >
                              <div className="address-pair ">
                                <div className="labelssss">Property Address</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {values.propertyAddress}</div>
                              </div>
                            </div>

                            <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                              <div className="address-pair ">
                                <div className="labelssss">Items (Fixture/Fittings) to be included</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {getDetails.fixFitIncluded}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                              <div className="address-pair ">
                                <div className="labelssss">Refer to the contract when available Items (Fixture/Fittings) to be excluded</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {getDetails.fixFitExcluded}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                              <div className="address-pair ">
                                <div className="labelssss">Easements/Covenants/Defects/Local Government Notices/Orders affecting the Property known to the Licensee</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {getDetails.easementConvenantDefects}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                              <div className="address-pair ">
                                <div className="labelssss">Occupation</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {getDetails.propOccupation === 1 ? 'Tenented' : 'Vacated'}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                              <div className="address-pair ">
                                <div className="labelssss">Terms and Conditions of Sale Known to the Licensee</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {getDetails.termsAndConditionsOfSale}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                              <div className="address-pair ">
                                <div className="labelssss">Special Instructions about the Marketing and Showing of the Property Price</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> $ {getDetails.splInstructionAboutMarketing}</div>
                              </div>
                            </div>


                            <div className=" agreementLabel mb-3 mt-4" style={{ width: '100%' }}>{`Price`}</div>
                            <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                              <div className="address-pair ">
                                <div className="labelssss">Price at which the Property is to be offered (including GST if any)</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> $ {getDetails.sellingFixedPrice}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                              <div className="address-pair ">
                                <div className="labelssss">Licensee’s Recommendation as to the Most Suitable Method of Sale</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> {getDetails.licenceeRecomendMethodOfSale === 1 ? "Private Treaty" : getDetails.licenceeRecomendMethodOfSale === 2 ? "Auction" : getDetails.licenceeRecomendMethodOfSale === 2 ? "Sale By Negotiation" : ''}</div>
                              </div>
                            </div>

                            <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                              <div className="address-pair ">
                                <div className="labelssss">Selling Price From</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> $ {getDetails.sellingPriceFrom}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                              <div className="address-pair ">
                                <div className="labelssss">Selling Price To</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> $ {getDetails.sellingPriceTo}</div>
                              </div>
                            </div>
                            <div className="col-lg-12 col-sm-12 agreementLabel mb-3 mt-4" style={{ paddingLeft: '0px', width: '100%' }} >{`REMUNERATION`}</div>
                            <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">(e.g. % of sale price; flat fee; formula e.g. a combination of % and flat fee) </div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> <MdPercent style={{ marginTop: "-5px" }} /> {getDetails.salePriceFlatFee}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">e.g. in relation to the Licensee’s ESTIMATE of the sale price of</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> $ {getDetails.licenceeEstimateSalePrice}</div>
                              </div>
                            </div>
                            <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">the GST inclusive renumeration would be</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss"> $ {getDetails.gstInclusiveRenumeration}</div>
                              </div>
                            </div>
                            <div className="col-lg-12 col-sm-12 agreementLabel mb-3 mt-4" style={{ paddingLeft: '0px', width: '100%' }} >{`Payment to Principal`}</div>
                            <div className="s1 ">If money held by the Agent becomes due to the principal,then the Principal directs the Agent to pay that money by</div>
                            <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                              <div className="address-pair">
                                <div className="labelssss">Electronic Funds Transfer (EFT)</div>
                                <div style={{ marginTop: '3px' }}>:</div>
                                <div className="valuessss">  {getDetails.isEFT ? 'Yes' : 'No'}</div>
                              </div>
                            </div>
                          </div>
                          : null}
                        <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                          {!IsSigned ?
                            <div >
                              {utype !== '3' ? <div
                                className=""
                              >
                                <TextBox
                                  label="A/C Name"
                                  name="PAccountName"
                                  value={values.PAccountName ? values.PAccountName : getDetails.pAccountName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.PAccountName}
                                  touched={touched.PAccountName}
                                />
                                <TextBox
                                  label="BSB"
                                  name="PBSB"
                                  value={values.PBSB ? values.PBSB : getDetails.pbsb}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.PBSB}
                                  touched={touched.PBSB}
                                />
                                <TextBox
                                  label="A/C No"
                                  name="PAccountNo"
                                  value={values.PAccountNo ? values.PAccountNo : getDetails.pAccountNo}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.PAccountNo}
                                  touched={touched.PAccountNo}
                                />

                              </div> : <div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                                  <div className="address-pair">
                                    <div className="labelssss">A/C Name</div>
                                    <div className="valuessss">: {getDetails.pAccountName}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                                  <div className="address-pair">
                                    <div className="labelssss">BSB</div>
                                    <div className="valuessss">: {getDetails.pbsb}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                                  <div className="address-pair">
                                    <div className="labelssss">A/C No</div>
                                    <div className="valuessss">: {getDetails.pAccountNo}</div>
                                  </div>
                                </div>
                              </div>}
                              <div className=" agreementLabel mb-3 mt-4" style={{ paddingLeft: '0px', width: '100%' }} >{`EXPENSES AND CHARGES `}</div>

                              <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                <div className="address-pair">
                                  <div className="labelssss ">Services</div>
                                  <div style={{ marginTop: '3px' }}>:</div>
                                  <div className="valuessss"> {getDetails.expenseANDChargesService1}</div>
                                </div>
                              </div>
                              <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                <div className="address-pair">
                                  <div className="labelssss ">Expense AND Charges Service </div>
                                  <div style={{ marginTop: '3px' }}>:</div>
                                  <div className="valuessss"> {getDetails.expenseANDChargesService2}</div>
                                </div>
                              </div>
                              <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                <div className="address-pair">
                                  <div className="labelssss ">Amount $</div>
                                  <div style={{ marginTop: '3px' }}>:</div>
                                  <div className="valuessss d-flex align-items-center">$ {getDetails.expenseANDChargesEstimate1}</div>
                                </div>
                              </div>
                              <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                <div className="address-pair">
                                  <div className="labelssss ">GST </div>
                                  <div style={{ marginTop: '3px' }}>:</div>
                                  <div className="valuessss d-flex align-items-center">$ {getDetails.expenseANDChargesEstimateGST1}</div>
                                </div>
                              </div>
                              <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                <div className="address-pair">
                                  <div className="labelssss ">When due and payable</div>
                                  <div style={{ marginTop: '3px' }}>:</div>
                                  <div className="valuessss"> {getDetails.expenseANDChargesdue1}</div>
                                </div>
                              </div>
                              <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                <div className="address-pair">
                                  <div className="labelssss">Expense AND Charges Estimate</div>
                                  <div style={{ marginTop: '3px' }}>:</div>
                                  <div className="valuessss"> $ {getDetails.expenseANDChargesEstimate2}</div>
                                </div>
                              </div>
                              <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                <div className="address-pair">
                                  <div className="labelssss ">GST </div>
                                  <div style={{ marginTop: '3px' }}>:</div>
                                  <div className="valuessss d-flex align-items-center">$ {getDetails.expenseANDChargesEstimateGST2}</div>
                                </div>
                              </div>
                              <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                <div className="address-pair">
                                  <div className="labelssss">Expense AND Charges due</div>
                                  <div style={{ marginTop: '3px' }}>:</div>
                                  <div className="valuessss">$ {getDetails.expenseANDChargesdue2}</div>
                                </div>
                              </div>

                              <div className="agreementLabel mb-3 mt-4" style={{ paddingLeft: '0px', width: '100%' }} >{`OTHER SERVICES [Clause 4.1]`}</div>
                              <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                <div className="s1">
                                  Those services and amounts cannot be varied except with the agreement in writing of the Principal.                        </div>
                                <div className="address-pair">
                                  <div className="labelssss">Services</div>
                                  <div style={{ marginTop: '3px' }}>:</div>
                                  <div className="valuessss"> {getDetails.otherServicesService1}</div>
                                </div>
                              </div>
                              <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                <div className="address-pair">
                                  <div className="labelssss">Other Services Charges</div>
                                  <div style={{ marginTop: '3px' }}>:</div>
                                  <div className="valuessss"> {getDetails.otherServicesService2}</div>
                                </div>
                              </div>

                              <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                <div className="address-pair">
                                  <div className="labelssss">$ (GST Inc)</div>
                                  <div style={{ marginTop: '3px' }}>:</div>
                                  <div className="valuessss"> {getDetails.otherServicesGST1}</div>
                                </div>
                              </div>
                              <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                <div className="address-pair">
                                  <div className="labelssss">Other Services GST</div>
                                  <div style={{ marginTop: '3px' }}>:</div>
                                  <div className="valuessss"> {getDetails.otherServicesGST2}</div>
                                </div>
                              </div>
                              <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                <div className="address-pair">
                                  <div className="labelssss">When due and payable</div>
                                  <div style={{ marginTop: '3px' }}>:</div>
                                  <div className="valuessss"> {getDetails.otherServicesdue1}</div>
                                </div>
                              </div>
                              <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                <div className="address-pair">
                                  <div className="labelssss">Other Services Due</div>
                                  <div style={{ marginTop: '3px' }}>:</div>
                                  <div className="valuessss"> {getDetails.otherServicesdue2}</div>
                                </div>
                              </div>

                              <div className="agreementLabel mb-3 mt-4" style={{ paddingLeft: '0px', width: '100%' }} >{`ADVERTISING AND PROMOTION`}</div>

                              <div className="s1">
                                Manner of Advertising and Promotion:
                              </div>
                            </div>
                            : null}
                          <div className="pddpn-group">
                            {!IsSigned ?
                              <div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                  <div className="address-pair">
                                    <div className="labelssss">Property Photos</div>
                                    <div style={{ marginTop: '3px' }}>:</div>
                                    <div className="valuessss"> {getDetails.isListedOnREA ? 'Yes' : 'No'}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>

                                  <div className="address-pair">
                                    <div className="labelssss">Social Media Campaign</div>
                                    <div style={{ marginTop: '3px' }}>:</div>
                                    <div className="valuessss"> {getDetails.socialMediaCampaign ? 'Yes' : 'No'}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>

                                  <div className="address-pair">
                                    <div className="labelssss">Listing on Domain.com.au</div>
                                    <div style={{ marginTop: '3px' }}>:</div>
                                    <div className="valuessss"> {getDetails.isListedOnDomain ? 'Yes' : 'No'}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>

                                  <div className="address-pair">
                                    <div className="labelssss">Floor Plans</div>
                                    <div style={{ marginTop: '3px' }}>:</div>
                                    <div className="valuessss"> {getDetails.floorPlans ? 'Yes' : 'No'}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>

                                  <div className="address-pair">
                                    <div className="labelssss">Listing on Realestate.com.au</div>
                                    <div style={{ marginTop: '3px' }}>:</div>
                                    <div className="valuessss"> {getDetails.isListedOnREA ? 'Yes' : 'No'}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>

                                  <div className="address-pair">
                                    <div className="labelssss">Signboard Outside the property</div>
                                    <div style={{ marginTop: '3px' }}>:</div>
                                    <div className="valuessss"> {getDetails.signboardOutsideProperty ? 'Yes' : 'No'}</div>
                                  </div>
                                </div>
                                <div className="agreementLabel mb-3 tribulanceBreak" style={{ width: '100%' }}>{`INSPECTION OF PROPERTY`}</div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                  <div className="address-pair">
                                    <div className="labelssss">Inspection may be conducted in the following circumstances</div>
                                    <div style={{ marginTop: '3px' }}>:</div>
                                    <div className="valuessss"> {getDetails.inspection}</div>
                                  </div>
                                </div>
                                <div className="s1">The Principal acknowledges, so far as reasonably practicable, that the principal has thoroughly inspected the Property prior to sale and that the Property is</div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>

                                  <div className="address-pair">
                                    <div className="labelssss">Without risk to health and safety</div>
                                    <div style={{ marginTop: '3px' }}>:</div>
                                    <div className="valuessss"> {getDetails.withoutRisk === 1 ? "Yes" : "No"}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>

                                  <div className="address-pair">
                                    <div className="labelssss">Subject to the defects and risks outlined in the contract for sale</div>
                                    <div style={{ marginTop: '3px' }}>:</div>
                                    <div className="valuessss"> {getDetails.withDefects === 1 ? "Yes" : "No"}</div>
                                  </div>
                                </div>

                                <div className="agreementLabel mb-3 mt-4" style={{ paddingLeft: '0px', width: '100%' }} >{`DISCLOSURE OF REBATES, DISCOUNTS AND COMMISSIONS`}</div>
                                <div className="s1">The agent is authorized to:</div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                  <div className="address-pair">
                                    <div className="labelssss">Name of Source of Rebate</div>
                                    <div style={{ marginTop: '3px' }}>:</div>
                                    <div className="valuessss"> {values.ObtainReferenceFromTenant ? 'Yes' : 'No'}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                  <div className="address-pair">
                                    <div className="labelssss">Estimated Amount of Rebates, Discounts or Commissions</div>
                                    <div style={{ marginTop: '3px' }}>:</div>
                                    <div className="valuessss"> {getDetails.disclosureEstimateRebate1 ? 'Yes' : 'No'}</div>
                                  </div>
                                </div>
                                <div className="s1">COOLING-OFF PERIOD: You (the vendor) have a cooling-off period for this agreement. If you do not wish to continue with this agreement you can cancel it until 5pm on the next business day or Saturday. How to do this, you should refer to the guide given to you before signing the Agreement.</div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                  <div className="address-pair">
                                    <div className="labelssss">WAIVER OF COOLING OFF PERIOD</div>
                                    <div style={{ marginTop: '3px' }}>:</div>
                                    <div className="valuessss"> {getDetails.waiverOfCoolinOffPeriod === 1 ? "Yes" : "No"}</div>
                                  </div>
                                </div>
                                <div className="s1">The licensee and the principal/s acknowledge and confirm that before signing this agreement the licensee and The principal/s have read and understood and agree to the terms and conditions in part 3 on the reverse side of this agreement.</div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                  <div className="address-pair">
                                    <div className="labelssss">The approved guide “Agency Agreement for the Sale of Residential Property” was provided to the Principal</div>
                                    <div style={{ marginTop: '3px' }}>:</div>
                                    <div className="valuessss"> {getDetails.wasAgreementProvidedPrincipal === 1 ? "Yes" : "No"}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px', width: '100%' }}>
                                  <div className="address-pair">
                                    <div className="labelssss">Date Provided</div>
                                    <div style={{ marginTop: '3px' }}>:</div>
                                    <div className="valuessss"> {FormatDateView(getDetails.dateProvided)}</div>
                                  </div>
                                </div>

                              </div>
                              : null}
                            <div style={{ width: '100%' }}>
                              <div style={{ width: "100%" }}>
                                {verified ? (
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {" "}
                                    {/* <Verify getDetails={getDetails} isVerified={isVerified} verificationType={2} /> */}
                                  </div>
                                ) : null}
                                {!IsSigned ? (
                                  <div className="">

                                    <div className="">
                                    <div className="agreementLabel mb-3 mt-4" style={{ paddingLeft: '0px', width: '100%' }} >{`Repairs and Maintenance`}</div>
                                          <div >
                                            <div className="s1">
                                              {textWithBullet}The Agent will be indemnified
                                              from any claims or actions, suits, legal costs
                                              or demands taken against the Principal(s) named
                                              in this agreement and the Agent will not be
                                              liable for any claims made against the
                                              Principal(s) due to any actions, omissions or
                                              statements made or not made by the Principal(s).
                                            </div>
                                            <div className="s1 mt-3">
                                              {textWithBullet}The Principal acknowledges that
                                              the Agent is not a Financial and Investment
                                              Advisor and any financial advice would be
                                              general advice and the Principal should seek
                                              their own financial advice.
                                            </div>
                                            <div className="s1 mt-3">
                                              {textWithBullet}The Principal acknowledges that
                                              the Property Stock and Business Agents Act
                                              requires the Agent to disclose all Material
                                              Facts relating to the property throughout the
                                              transaction and warrants that all materials
                                              facts have been disclosed to the best of their
                                              knowledge.
                                            </div>
                                            <div className="s1 mt-3">
                                              {textWithBullet}The Principal acknowledges that
                                              at all times the Principal has sole control of
                                              the property and that the Agent acts under the
                                              instructions of the Principal during the sale of
                                              the property. The Principal is responsible for
                                              the Work Health and Safety requirements of the
                                              property.
                                            </div>
                                            <div className="s1 mt-3">
                                              {textWithBullet}The Principal acknowledges that
                                              by signing this agreement the Principal is
                                              denied the right to lease the Property without
                                              paying a fee
                                            </div>
                                          </div>
                                          <div className="agreementLabel mb-3 mt-4" style={{ paddingLeft: '0px', width: '100%' }} >{`Privacy Statement`}</div>
                                          <div>
                                            <div >
                                              <div
                                                className="s1"
                                                style={{ fontWeight: "600" }}
                                              >
                                                All information collected by the Agent is
                                                done so with respect of the Privacy Act 1998
                                                and the Privacy Principals.
                                              </div>
                                              <div className="s1 mt-3">
                                                {textWithBullet}Information collected is
                                                necessary for one or more of the activities
                                                associated with this agreement.
                                              </div>
                                              <div className="s1 mt-3">
                                                {textWithBullet}The Agent will not use or
                                                disclose your personal information for
                                                another purpose, unless it would be
                                                reasonable to expect such disclosure is
                                                necessary for the completion of activities
                                                associated with this agreement.
                                              </div>
                                              <div className="s1 mt-3">
                                                {textWithBullet}The Agent will take all
                                                reasonable steps to ensure that the personal
                                                information collected, used or disclosed is
                                                accurate, complete and up-to-date.
                                              </div>
                                              <div className="s1 mt-3">
                                                {textWithBullet}The Agent will take
                                                reasonable steps to protect the personal
                                                information it holds from misuse and loss
                                                and from unauthorised access, modification
                                                or disclosure and also take reasonable steps
                                                to destroy or permanently de-identify
                                                personal information it no longer needs.
                                              </div>
                                            </div>
                                          </div>
                                       
                                      {isRefMatched || utype === "3" ? (
                                        <div>
                                          <div className="agreementLabel mb-3 mt-4" style={{ paddingLeft: '0px', width: '100%' }} >{`Signature of Principal(s)`}</div>
                                          <div>
                                            <div
                                              className="mt-2"
                                              style={{
                                                padding: "0px 30px",
                                                display: "flex",
                                              }}
                                            >
                                              <div
                                                className="card"
                                                style={{ display: "flex" }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      padding: "10px 10px 0px 20px",
                                                    }}
                                                    className="s1"
                                                    htmlFor="name"
                                                  >
                                                    Signature{" "}
                                                  </div>
                                                  <DatePicker
                                                    id="SignedDate"
                                                    format="DD/MM/YYYY" // Display and output format
                                                    value={date ? moment(date, "DD/MM/YYYY") : null} // Set the value for DatePicker
                                                    onChange={handleChangeDate}
                                                    placeholder="dd/mm/yyyy"
                                                  />
                                                </div>

                                                <div>
                                                  <TabView
                                                    activeIndex={activeIndex}
                                                    onTabChange={(e) =>
                                                      setActiveIndex(e.index)
                                                    }
                                                  >
                                                    <TabPanel header="Draw">
                                                      <div className="tc-sign ">
                                                        <div className="sign-tc">
                                                          <SignatureCanvas
                                                            penColor="black"
                                                            ref={signatureRef}
                                                            canvasProps={{
                                                              width: "300",
                                                              height: 75,
                                                              className: "signature-canvas",
                                                            }}
                                                          />
                                                        </div>
                                                        <div
                                                          onClick={clearSignature}
                                                          className="tc-b3"
                                                        >
                                                          Clear
                                                        </div>
                                                      </div>
                                                    </TabPanel>
                                                    <TabPanel header="Text">
                                                      <div className="tc-sign ">
                                                        <TextArea colVal={12} label={"Signature"} value={signatureText} onChange={handleSignature} />
                                                        <div
                                                          onClick={clearSignature}
                                                          className="tc-b3 ml-3"
                                                        >
                                                          Clear
                                                        </div>
                                                      </div>
                                                    </TabPanel>
                                                  </TabView>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}


                                    </div>

                                  </div>
                                ) : null}
                                {IsSigned ? (
                                  <div className="signTxt df-all" >
                                    <div>Thank you</div>
                                    <div>your document has been signed</div>
                                  </div>
                                ) : null}
                                <div className="property-details-buttons">
                                  {!IsSigned ? (
                                    <>
                                      {/* <button
                        className="pdb-save-changes"
                        type="reset"
                        onClick={handleClear}
                      >
                        Cancel
                      </button>
                      <button
                        className="pdb-cancel"
                        type="reset"
                        onClick={handlePrevious}
                      >
                        Previous
                      </button> */}
                                      <button
                                        className="pdb-save-changes"
                                        type="submit"
                                      >
                                        {"Submit"}
                                      </button>
                                    </>
                                  ) : null}

                                  {IsSigned ? <button
                                    className="pdb-cancel"
                                    type="button"
                                    onClick={handleAgreementDownload}
                                  >
                                    {"Download Agreement"}
                                  </button> : null}
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div> : null}
                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesViewPage;
