import React from 'react'
import { useEffect, useState, useRef } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import axios from 'axios';
import { useFormik } from "formik";
import { useNavigate } from 'react-router-dom'
import { addContactAgentDetails, getReportingAgent } from "../../CommonSection/apiresources";
import { GetCookie } from '../../CommonSection/Cookie/Session';
import { Dropdown } from 'primereact/dropdown';
import TextBox from "../../../src/input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { AgentContactDetailsSchema } from "../../CommonSection/Schema/schema";
import ShowValue from '../../../src/input-components/ShowValue';
import { FiEdit } from "react-icons/fi";

const initialValues = {
    emergencyContactName: "",
    emergencyContactNumber: "",
    addressOA: "",
    addressAA: "",
    reportTo: "",
}

const ContactDetailsAgent = ({ handleparentsuccess, handleCurrentPage, getDetails }) => {
    var url = new URL(window.location.href);
    var agentId = url.searchParams.get("value");
    var edit = url.searchParams.get("edit");
    const [dpdata, setDpdata] = useState([]);
    const [addressAA, setAddressAA] = useState("");
    const [addressOA, setAddressOA] = useState("");
    const [userId, setUserId] = useState('')
    const [userUId, setUserUId] = useState('')
    const [agencyID, setAgencyId] = useState('');
    const [agencyUID, setAgencyUID] = useState('');
    const [selectedCity, setSelectedCity] = useState(null);
    let toastdata = [];
    const ToastRef = useRef();
    const inputRef = useRef();
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [view, setView] = useState(true)

    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie('@UserData');
                if (userData) {
                    const parsedData = JSON.parse(userData)
                    setUserUId(parsedData.userUnique_ID);
                    setUserId(parsedData.userId);
                    setAgencyId(parsedData.agencyID);
                    setAgencyUID(parsedData.agencyUniqueID)
                    reportData(parsedData.agencyUniqueID)
                }
            } catch (error) {
                console.error('Error retrieving data from AsyncStorage:', error);
            }
        };
        retrieveData();
    }, [userId]);

    useEffect(() => {
        if (getDetails) {
            setAddressOA(getDetails.address)
            setAddressAA(getDetails.residentialAddress);
            setFieldValue('emergencyContactNumber', getDetails.emergencyContactDetails);
            setFieldValue('emergencyContactName', getDetails.emergencyContactName);
            setFieldValue('addressOA', getDetails.address);
            setFieldValue('addressAA', getDetails.residentialAddress);
            const matchingGroup = dpdata.find(group => {
                return group.itemList.some(item => parseInt(item.value, 10) === getDetails.reportingManager);
            });
            if (matchingGroup) {
                const matchingItem = matchingGroup.itemList.find(item => item.text);
                setSelectedCity(matchingItem.value);
                setFieldValue('reportTo', matchingItem.value)
            }
        }
    }, [getDetails]);

    const reportData = (agencyUID) => {
        const apiUrl = getReportingAgent;
        const baseUrl = apiUrl + '?agencyUID=' + agencyUID;
        axios.get(baseUrl).then(response => {
            if (response.data.object)
                setDpdata(response.data.object);
        });
    }

    const { errors, values, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: AgentContactDetailsSchema,
        onSubmit: (values, action) => {
            values.reportTo = selectedCity;
            values.addressAA = addressAA;
            values.addressOA = addressOA;
            handleFinalSubmit()
        }
    });

    const handleFinalSubmit = () => {
        const formData = {
            AgencyId: agencyID,
            AgentId: agentId,
            ResidentialAddress: values.addressAA,
            ReportingManager: values.reportTo,
            EmergencyContactName: values.emergencyContactName,
            EmergencyContactDetails: values.emergencyContactNumber,
            Address: values.addressOA,
        };
        const url = addContactAgentDetails;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, 'post', formData).then((returnData) => {
            if (returnData.status === ActionStatus.Successfull) {
                handleparentsuccess(['success', returnData.message]);
                handleCurrentPage("RemunerationDetailsAgent");
            } else {
                handleparentsuccess(['error', returnData.message]);
            }
        })
            .catch((error) => {
                handleparentsuccess(['error', error.message]);
            });
    };

    const groupedItemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.groupName} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.groupName.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.groupName}</div>
            </div>
        );
    }
    const handleReportTo = (e) => {
        setSelectedCity(e.value)
        setFieldValue('reportTo', e.value)
    }

    const handleView = () => {
        setView(!view)
    }
    return (
        // <div>
        //     <form action="none" onSubmit={handleSubmit} className='details-form'>
        //         <div className="df-all">
        //             <div className="all-input" style={{display:'initial'}}>
        //                 <div className="cda-AgentAddress col-lg-5 col-sm-12">
        //                     <label htmlFor="AgentAddress" className='s1'>Agent Address</label>
        //                     <div className="input-group mb-3">
        //                         <PlacesAutocomplete
        //                             value={addressAA}
        //                             onSelect={setAddressAA}
        //                             onChange={setAddressAA}
        //                             className=""
        //                             searchOptions={{
        //                                 types: ['geocode'], // Restrict to addresses
        //                                 componentRestrictions: { country: 'AU' }, // Restrict to Australia
        //                             }}
        //                         >
        //                             {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        //                                 <div className='input-group adpa-input'>
        //                                     <input
        //                                         {...getInputProps({
        //                                             placeholder: 'Address',
        //                                             className: 'input-address mb-3',

        //                                         })}
        //                                     />
        //                                     <div className="autocomplete-dropdown-container">
        //                                         {loading && <div>Loading...</div>}
        //                                         {suggestions.map((suggestion) => {
        //                                             const className = suggestion.active
        //                                                 ? 'input-address-suggestion  mb-3'
        //                                                 : 'input-address-suggestion mb-3';
        //                                             return (
        //                                                 <div
        //                                                     {...getSuggestionItemProps(suggestion, {
        //                                                         className,
        //                                                     })}
        //                                                 >
        //                                                     <span>{suggestion.description}</span>
        //                                                 </div>
        //                                             );
        //                                         })}
        //                                     </div>
        //                                 </div>
        //                             )}
        //                         </PlacesAutocomplete>
        //                     </div>
        //                     {errors.companyaddress && touched.companyaddress ? <p className="form-error">{errors.companyaddress}</p> : null}
        //                 </div>
        //                 <div className="cda-reportTo col-lg-5 col-sm-12">
        //                     <label htmlFor="trading state" className='s1 w-100'>Report To<span style={{ color: 'red' }}>*</span></label>
        //                     <Dropdown value={selectedCity} onChange={handleReportTo} options={dpdata} optionLabel="text"
        //                         optionGroupLabel="groupName" optionGroupChildren="itemList" optionGroupTemplate={groupedItemTemplate} className="w-100 md:w-14rem" placeholder="Select..." />

        //                     {errors.reportTo && touched.reportTo ? <p className="form-error">{errors.reportTo}</p> : null}
        //                 </div>
        //                 <TextBox label="Emergency Contact Name" placeholder="Name" name="emergencyContactName" value={values.emergencyContactName} onChange={handleChange} onBlur={handleBlur} error={errors.emergencyContactName} touched={touched.emergencyContactName} />
        //                 <TextBox  label="Emergency Contact Number" placeholder="Number" name="emergencyContactNumber" value={values.emergencyContactNumber} onChange={handleChange} onBlur={handleBlur} error={errors.emergencyContactNumber} touched={touched.emergencyContactNumber} />
        //                 <div className="cda-companyaddress col-lg-5 col-sm-12">
        //                     <label htmlFor="fname" className='s1'>Office Address</label>
        //                     <div className="input-group mb-3">
        //                         <PlacesAutocomplete
        //                             value={addressOA}
        //                             onSelect={setAddressOA}
        //                             onChange={setAddressOA}
        //                             className=""
        //                             searchOptions={{
        //                                 types: ['geocode'], // Restrict to addresses
        //                                 componentRestrictions: { country: 'AU' }, // Restrict to Australia
        //                             }}
        //                         >
        //                             {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        //                                 <div className='input-group adpa-input'>
        //                                     <input
        //                                         {...getInputProps({
        //                                             placeholder: 'Address',
        //                                             className: 'input-address mb-3',

        //                                         })}
        //                                     />
        //                                     <div className="autocomplete-dropdown-container">
        //                                         {loading && <div>Loading...</div>}
        //                                         {suggestions.map((suggestion) => {
        //                                             const className = suggestion.active
        //                                                 ? 'input-address-suggestion  mb-3'
        //                                                 : 'input-address-suggestion mb-3';

        //                                             return (
        //                                                 <div
        //                                                     {...getSuggestionItemProps(suggestion, {
        //                                                         className,
        //                                                     })}
        //                                                 >
        //                                                     <span>{suggestion.description}</span>
        //                                                 </div>
        //                                             );
        //                                         })}
        //                                     </div>
        //                                 </div>
        //                             )}
        //                         </PlacesAutocomplete>
        //                     </div>
        //                     {errors.companyaddress && touched.companyaddress ? <p className="form-error">{errors.companyaddress}</p> : null}
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="property-details-buttons" >
        //             <button className="pdb-cancel" type='reset' onClick={() => handleparentsuccess(['warn', 'Changes are cancelled'])}>
        //                 Cancel
        //             </button>
        //             <button className="pdb-save-changes" type='submit' >
        //                 Save Changes
        //             </button>
        //         </div>
        //     </form>
        // </div>

        <div style={{ background: '#fff' }}>
            <div className='pl-4 pr-4 pt-4 mainLabel'>
                <div>Contact Details</div>
                {edit && view ?  <div className='edit' onClick={handleView}><FiEdit /> Edit </div> : null }
            </div>
            {edit && view ? <div className="all-input pl-4 pr-4 pb-2">
                <div className="details-form w-100">
                    <div className="contacts-group d-flex">
                        <div className='col-lg-4'>
                            <ShowValue displayrow={false} Label='Agent Address' setData={addressAA} />
                        </div>
                        <div className='col-lg-4'>
                            <ShowValue displayrow={false} Label='Report To' setData={selectedCity} />
                        </div>
                        <div className='col-lg-4'>
                            <ShowValue displayrow={false} Label='Emergency Contact Name' setData={`${getDetails?.emergencyContactName}`} />
                        </div>
                        <div className='col-lg-4'>
                            <ShowValue displayrow={false} Label='Office Address' setData={addressOA} />
                        </div>
                        <div className='col-lg-4'>
                            <ShowValue displayrow={false} Label='Emergency Contact Number' setData={`${getDetails?.emergencyContactNumber}`} />
                        </div>
                    </div>
                </div>
            </div> :
                <form onSubmit={handleSubmit} className="details-form" style={{ display: "initial", width: "100%" }}>
                    <div className="p-4 contacts-group">
                        <div className="cda-AgentAddress col-lg-6 col-sm-12">
                            <label htmlFor="AgentAddress" className='s1'>Agent Address</label>
                            <div className="input-group mb-3">
                                <PlacesAutocomplete
                                    value={addressAA}
                                    onSelect={setAddressAA}
                                    onChange={setAddressAA}
                                    className=""
                                    searchOptions={{
                                        types: ['geocode'], // Restrict to addresses
                                        componentRestrictions: { country: 'AU' }, // Restrict to Australia
                                    }}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div className='input-group adpa-input'>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Address',
                                                    className: 'input-address mb-3',

                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion) => {
                                                    const className = suggestion.active
                                                        ? 'input-address-suggestion  mb-3'
                                                        : 'input-address-suggestion mb-3';
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                            })}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                            {errors.companyaddress && touched.companyaddress ? <p className="form-error">{errors.companyaddress}</p> : null}
                        </div>
                        <div className="cda-reportTo col-lg-6 col-sm-12">
                            <label htmlFor="trading state" className='s1 w-100'>Report To<span style={{ color: 'red' }}>*</span></label>
                            <Dropdown value={selectedCity} onChange={handleReportTo} options={dpdata} optionLabel="text"
                                optionGroupLabel="groupName" optionGroupChildren="itemList" optionGroupTemplate={groupedItemTemplate} className="w-100 md:w-14rem" placeholder="Select..." />

                            {errors.reportTo && touched.reportTo ? <p className="form-error">{errors.reportTo}</p> : null}
                        </div>
                        <TextBox label="Emergency Contact Name" placeholder="Name" name="emergencyContactName" value={values.emergencyContactName} onChange={handleChange} onBlur={handleBlur} error={errors.emergencyContactName} touched={touched.emergencyContactName} />
                        <TextBox label="Emergency Contact Number" placeholder="Number" name="emergencyContactNumber" value={values.emergencyContactNumber} onChange={handleChange} onBlur={handleBlur} error={errors.emergencyContactNumber} touched={touched.emergencyContactNumber} />
                        <div className="cda-companyaddress col-lg-6 col-sm-12">
                            <label htmlFor="fname" className='s1'>Office Address</label>
                            <div className="input-group mb-3">
                                <PlacesAutocomplete
                                    value={addressOA}
                                    onSelect={setAddressOA}
                                    onChange={setAddressOA}
                                    className=""
                                    searchOptions={{
                                        types: ['geocode'], // Restrict to addresses
                                        componentRestrictions: { country: 'AU' }, // Restrict to Australia
                                    }}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div className='input-group adpa-input'>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Address',
                                                    className: 'input-address mb-3',

                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion) => {
                                                    const className = suggestion.active
                                                        ? 'input-address-suggestion  mb-3'
                                                        : 'input-address-suggestion mb-3';

                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                            })}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                            {errors.companyaddress && touched.companyaddress ? <p className="form-error">{errors.companyaddress}</p> : null}
                        </div>
                    </div>
                    <div className="property-details-buttons">
                        <button className="pdb-cancel" type="reset">
                            Cancel
                        </button>
                        <button className="pdb-save-changes" type="submit">
                            Save
                        </button>
                    </div>
                </form>}

        </div>
    )
}

export default ContactDetailsAgent