import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import "../../openHome/openHome.css";
import { agreementPeriodValid } from "../../../../components/CommonSection/Schema/schema";
import ShowValue from "../../../input-components/ShowValue"
import "../agreement.css";

dayjs.extend(customParseFormat);

const initialValues = {
  AgreementDate: "",
  LeasePremisesAtMarketRent: "",
  ReferToPrincipalForReLease: "",
  ReviewRentBfrRLease: "",
};

const AgreementPeriod = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  reportTo,
  handleNextPage,
}) => {
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();

  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setFieldValue("AgreementDate", formatDate(getDetails.agreementDate));
      setFieldValue(
        "ReferToPrincipalForReLease",
        getDetails.referToPrincipalForReLease
      );
      setFieldValue("ReviewRentBfrRLease", getDetails.reviewRentBfrRLease);
      setFieldValue(
        "LeasePremisesAtMarketRent",
        getDetails.leasePremisesAtMarketRent
      );
    }
  }, [getDetails, reportTo]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);


  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }
    const [year, month, day] = dateString.split("T")[0].split("-");
    return `${day}-${month}-${year}`;
  }

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: agreementPeriodValid,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (data) => {
    handleNextPage("Remunation");
  };

  const handleClear = () => {
    handleNextPage("propetyies");
  };

  const handleCancle = () => {
    navigate("/agency/ListAgreement");
  };


  return (
    <>
      <div className="dashboard-agency">
        <ToastAlert ref={ToastRef} />
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <div style={{ width: "100%", marginLeft:'60px' }}>
              <form onSubmit={handleSubmit} className="details-form">
                <div className="property-details-content df-all all-input">
                  <div>
                        <div className="agreementPeriod">
                        <div className=" agreementLabel mb-3 " >{`Agreement Period`}</div>

                    <div style={{display:'flex' }}>
                    <div className={`lda-${name} `} style={{width:'50%'}}>
                        <ShowValue
                          Label="From Date"
                          setData={formatDate(getDetails.agreementStartDate)}
                        />
                      </div>
                      <div className={`lda-${name} `} style={{width:'50%'}}>
                        <ShowValue
                          Label="To Date"
                          setData={formatDate(getDetails.agreementEndDate)}
                        />
                      </div>
                    </div>
                                <div className="s1 ">Inclusive now called the 'Exclusive Agency Period'</div>
                
                        </div>
                        <div className="agreementPeriod mt-3">
                        <div className=" agreementLabel mb-3 " >{`PROPERTY (“the Property”) Description of the Property sufficiently detailed to allow it to be readily identified`}</div>
                          <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                   <div className="address-pair ">
                                    <div className="labelssss">Property Address</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {values.propertyAddress}</div>
                                  </div>
                                </div>
      
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                   <div className="address-pair ">
                                    <div className="labelssss">Items (Fixture/Fittings) to be included</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {getDetails.fixFitIncluded}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                   <div className="address-pair ">
                                    <div className="labelssss">Refer to the contract when available Items (Fixture/Fittings) to be excluded</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {getDetails.fixFitExcluded}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                   <div className="address-pair ">
                                    <div className="labelssss">Easements/Covenants/Defects/Local Government Notices/Orders affecting the Property known to the Licensee</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {getDetails.easementConvenantDefects}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                   <div className="address-pair ">
                                    <div className="labelssss">Occupation</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {getDetails.propOccupation === 1 ? 'Tenanted' : 'Vacated'}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                   <div className="address-pair ">
                                    <div className="labelssss">Terms and Conditions of Sale Known to the Licensee</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {getDetails.termsAndConditionsOfSale}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                   <div className="address-pair ">
                                    <div className="labelssss">Special Instructions about the Marketing and Showing of the Property Price</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> $ {getDetails.splInstructionAboutMarketing}</div>
                                  </div>
                                </div>
                        </div>
                        <div className="agreementPeriod mt-3">
                        <div className=" agreementLabel mb-3 " >{`Price`}</div>                              
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                   <div className="address-pair ">
                                    <div className="labelssss">Price at which the Property is to be offered (including GST if any)</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> $ {getDetails.sellingFixedPrice}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                   <div className="address-pair ">
                                    <div className="labelssss">Licensee’s Recommendation as to the Most Suitable Method of Sale</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {getDetails.licenceeRecomendMethodOfSale === 1 ? "Private Treaty" : getDetails.licenceeRecomendMethodOfSale === 2 ? "Auction" : getDetails.licenceeRecomendMethodOfSale === 2 ? "Sale By Negotiation" : ''}</div>
                                  </div>
                                </div>
                               
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                   <div className="address-pair ">
                                    <div className="labelssss">Selling Price From</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> $ {getDetails.sellingPriceFrom}</div>
                                  </div>
                                </div> 
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                   <div className="address-pair ">
                                    <div className="labelssss">Selling Price To</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> $ {getDetails.sellingPriceTo}</div>
                                  </div>
                                </div>
                              
                        </div>
                    
                  </div>
                </div>
             
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgreementPeriod;
