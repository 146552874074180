import React from "react";
import { useEffect, useState, useRef, useMemo } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  PropertyAddOwnerShipUrl,
  PropertySetPrimaryUrl,
  SearchContacts,
  AddExistingContact,
} from "../../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../../components/CommonSection/Cookie/Session";
import TextBox from "../../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../Utilities/enum";
import AddressInput from "../../../input-components/address";
import { BiEnvelope, BiPhone } from "react-icons/bi";
import Collapse from "../../../input-components/collapse";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import RatingStar from "../../../input-components/ratingStar";
import { OwnershipSchema } from "../../../../components/CommonSection/Schema/schema";
import { SelectButton } from "primereact/selectbutton";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import {
  addParametersForProfiles,
  dynamicUrl,
} from "../../../../Utilities/profileManagement";
import { IoPersonAddOutline } from "react-icons/io5";

const initialValues = {
  type: null,
  title: null,
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  ownerShipAddress: "",
};

const PropertyOwnerShip = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  closeModal
}) => {
  const [ownerShipAddress, setOwnerShipAddress] = useState("");
  const [address, setAddress] = useState("");
  let toastdata = [];
  const [titleValue, setTitleValue] = useState(null);
  const [typevalue, setTypeValue] = useState(null);
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agencyUID, setAgencyUID] = useState("");
  const [agentId, setAgentId] = useState("");
  const [ownerShipLists, setOwnerShipLists] = useState([]);
  const [displayAddOwnerShip, setDisplayAddOwnerShip] = useState(false);
  const [contactUniqueId, setContactUniqueId] = useState("");
  const [renderFields, 
  ] = useState(true);
  const [typeData, setTypeData] = useState([
    { value: 1, label: "Individual" },
    { value: 2, label: "Company" },
  ]);
  const [titleData, setTitleData] = useState([
    { value: 1, label: "Mr." },
    { value: 2, label: "Miss." },
    { value: 3, label: "Mrs." },
  ]);
  const [updateTable, setUpdateTable] = useState(true);
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  var url = new URL(window.location.href);
  var propertyUId = url.searchParams.get("propertyUID");

  const [fetching, setFetching] = useState(false);
  const [option, setOption] = useState([]);
  const fetchRef = useRef(0);
  const [searchValue, setSearchValues] = useState("");
  const ToastRef = useRef();
  const [selectedValues, setSelectedValues] = useState([]);
  const [showSelect, setShowSelect] = useState(true);
  const [displayAddContacts, setDisplayAddContacts] = useState(false);
  const [submitExistigContact, setSubitExisitingContact] = useState(false);
  const [contactId, setContactId] = useState(0);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgencyUID(parsedData.agencyUniqueID);
          setAgentId(parsedData.agentId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);
  
  useEffect(() => {
    if (getDetails) {
      let ownerListData = getDetails.contactlistModel;
      let ownerList = ownerListData.map((data) => ({
        Name: data.name,
        Email: data.contactEmail,
        Phone: data.phone,
        isPrimary: data.isPrimary,
        ownerId: data.ownerId,
      }));
      setOwnerShipLists(ownerList);
    }
  }, [getDetails]);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: OwnershipSchema,
    onSubmit: (values, action) => {
      submitExistigContact ? handleExisitingContact() : handleFinalSubmit();
    },
  });

  const handleFinalSubmit = () => {
    const formData = {
      AgentId: getDetails ? getDetails.agentId : agentId,
      AgencyId: agencyID,
      Address: ownerShipAddress,
      userRoleID: 10,
      PropertyUniqueId: propertyUId ? propertyUId : 0,
      FirstName: values.firstName,
      LastName: values.lastName,
      Email: values.email,
      MobileNumber: values.phone,
      ContactType: typevalue ? typevalue : "",
      Title: titleValue ? titleValue : "",
      ContactUniqueId: contactUniqueId ? contactUniqueId : "",
    };
    const url = PropertyAddOwnerShipUrl;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData, null , false)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          onDataRecive(propertyUId);
          //handleparentsuccess(["success", returnData.message]);
          handleCurrentPage("Listing");
          setDisplayAddOwnerShip(false);
          closeModal(true)
        } else {
          //handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        //handleparentsuccess(["error", error.message]);
      });
  };

  const handleExisitingContact = () => {
    const formData = {
      PropertyUniqueId: propertyUId ? propertyUId : 0,
      PropertyContactName: contactId,
      isUpdateOwner: true,
    };
    const url = AddExistingContact;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData,null , false)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          onDataRecive(propertyUId);
          //handleparentsuccess(["success", returnData.message]);
          handleCurrentPage("Listing");
          setDisplayAddOwnerShip(false);
        } else {
          //handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        //handleparentsuccess(["error", error.message]);
      });
  };

  const handlePropertyTitle = (selectedValue) => {
    setTitleValue(selectedValue.value);
    setFieldValue("title", selectedValue);
  };

  const handlePropertyType = (selectedValue) => {
    setTypeValue(selectedValue.value);
    setFieldValue("type", selectedValue);
  };


  const handleSellerAddress = (selectedAddress) => {
    setOwnerShipAddress(selectedAddress);
  };

  const getTypeData = (selectedValue) => {
    return typeData.find((item) => item.value === selectedValue);
  };


  const handleRatingChange = (newRating, ownerId) => {
    getAgencyCompanyDetails(ownerId);
    setUpdateTable(false);
  };

  const getAgencyCompanyDetails = (ownerId) => {
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      `${PropertySetPrimaryUrl}?OwnerId=${ownerId}`,
      "get",
      null , false
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          onDataRecive(propertyUId);
          setUpdateTable(true);
          setDisplayAddOwnerShip(false);
          handleparentsuccess(["success", returnData.message]);
        } else {
          handleparentsuccess(["error", response.data.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", response.data.message]);
      });
  };

  const handleCancel = () => {
    setDisplayAddOwnerShip(false);
  };
  useEffect(() => {
    agentUIDRef.current = agentId;
  }, [agentId]);

  const agentUIDRef = useRef(agentId);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      const agentId = agentUIDRef.current;
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOption([]);
      setFetching(true);
      const baseUrl = SearchContacts;
      let data = {
        Search: value,
        RecordsPerPage: 10,
        PageNo: 1,
        AgentID: agentId,
      };
      data = addParametersForProfiles(data);
      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            if (fetchId === fetchRef.current) {
              setOption(transformedData);
              setFetching(false);
            }
          } else {
            setFetching(false);
          }
        })
        .catch((error) => {
          setFetching(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);

  const handleSearchChange = (e, value) => {
    const label = value.map((item) => item.label).join(",");
    const values = value.map((item) => item.value).join(",");
    setSearchValues(values);
    setFieldValue("contactId", values);
    setFetching("propertyName", label);
    setContactId(values);
    debounceFetcher(value);
    setSubitExisitingContact(true);
  };

  const handleAddContacts = () => {
    setSubitExisitingContact(false);
    setDisplayAddContacts(!displayAddContacts);
  };

  
  return (
    <div>

      {!displayAddOwnerShip ? (
        <div style={{ paddingTop: "10px" }}>
          {
            ownerShipLists.length > 0 ? (
              <div className="property-details-content df-all ownerShipList">
                <div
                  className="ownerShipList"
                  style={{ borderBottom: "2px solid #dee2e6" }}
                >
                  <div
                    style={{
                      padding: "0px 40px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div className="mail-label-name">{"Name"}</div>
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "0px 60px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        className="main-label-no"
                        style={{ fontWeight: "500" }}
                      >
                        {"Action"}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="property-details-content df-all all-input">
                  <div className="pddpn-group pd-address">
                    <Collapse
                      id={"Personal-Details"}
                      title={"Personal-Details"}
                      data={
                        <div>
                          {renderFields ? (
                            <div>

                                <div className="contacts-group">
                                  <label htmlFor="Type" className="s1 ml-3">
                                    Type
                                  </label>

                                  <SelectButton
                                  style={{display:'flex'}}
                                    className="ml-3"
                                    optionLabel="label"
                                    options={typeData}
                                    onChange={handlePropertyType}
                                    value={typevalue}
                                  />
                                  <label htmlFor="Title" className="s1 ml-3">
                                    Title
                                  </label>

                                  <SelectButton
                                   style={{display:'flex'}}
                                    className="ml-3  mb-3 "
                                    optionLabel="label"
                                    options={titleData}
                                    onChange={handlePropertyTitle}
                                    value={titleValue}
                                  />
                                  <TextBox
                                    label="First Name"
                                    placeholder="First name"
                                    id="Unit"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.firstName}
                                    touched={touched.firstName}
                                  />
                                  <TextBox
                                    label="Last Name"
                                    placeholder="Last name"
                                    id="lastName"
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.lastName}
                                    touched={touched.lastName}
                                  />
                                  <TextBox
                                    isMandatory
                                    label="Phone"
                                    placeholder="Phone"
                                    name="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.phone}
                                    touched={touched.phone}
                                    icon={
                                      <BiPhone height="100%" width="100%" />
                                    }
                                  />
                                  <TextBox
                                    label="Email Address"
                                    placeholder="Email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.email}
                                    touched={touched.email}
                                    icon={
                                      <BiEnvelope height="100%" width="100%" />
                                    }
                                  />
                                  <AddressInput
                                    address={ownerShipAddress}
                                    setAddress={setOwnerShipAddress}
                                    handlePressSelect={handleSellerAddress}
                                    error={errors.ownerShipAddress}
                                    touched={touched.ownerShipAddress}
                                  />
                                </div>

                            </div>
                          ) : null}
                        </div>
                      }
                    />
                  </div>
                </div>
                <div className="property-details-buttons">
                  <button
                    className="pdb-cancel"
                    type="reset"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button className="pdb-save-changes" type="submit">
                    Save
                  </button>
                </div>
              </form>
            )
          }
        </div>
      ) : null}

      {displayAddOwnerShip ? (
        <form onSubmit={handleSubmit} className="details-form">
          <div className="property-details-content df-all all-input">
            <div className="pddpn-group pd-address">
              <div style={{ width: "100%" }}>
                {updateTable && ownerShipLists.length != 0 ? (
                  <DataTable
                    value={ownerShipLists}
                    size={"normal"}
                    tableStyle={{ minWidth: "20rem" }}
                    dataKey=""
                    rowClassName="custom-row"
                  >
                    <Column
                      field="Name"
                      header="Name"
                      className="common-table-style"
                      headerClassName="centered-header"
                    ></Column>
                    <Column
                      field="Email"
                      header="Email"
                      className="common-table-style"
                      headerClassName="centered-header"
                    ></Column>
                    <Column
                      field="Phone"
                      header="Phone"
                      className="common-table-style"
                      headerClassName="centered-header"
                    ></Column>
                    <Column
                      field="isPrimary"
                      header="Primary/Secondary"
                      className="common-table-style"
                      headerClassName="centered-header"
                      body={(rowData) => (
                        <RatingStar
                          totalStars={1}
                          initialRating={rowData.isPrimary ? 1 : 0}
                          onRatingChange={(newRating) =>
                            handleRatingChange(newRating, rowData.ownerId)
                          }
                        />
                      )}
                    ></Column>
                  </DataTable>
                ) : (
                  <></>
                )}
              </div>
              <Collapse
                id={"Personal-Details"}
                title={"Personal-Details"}
                data={
                  <div>
                    {renderFields ? (
                      <div>
                            {!displayAddContacts ? (
                                <div className="contacts-group">
                                  <div className={`lda col-lg-5 col-sm-12`}>
                                    <label className="s1">Contacts </label>
                                    <div style={{ display: "flex" }}>
                                      <Select
                                        style={{
                                          width: "100%",
                                          height: "40px",
                                        }}
                                        mode="multiple"
                                        placeholder="Search for contacts"
                                        labelInValue
                                        filterOption={false}
                                        onSearch={debounceFetcher}
                                        notFoundContent={
                                          fetching ? (
                                            <Spin size="small" />
                                          ) : null
                                        }
                                        options={option}
                                        onChange={(label, value) =>
                                          handleSearchChange(label, value)
                                        }
                                        defaultValue={selectedValues}
                                      />
                                      {/* {errors.property && touched.property ? (<p className="form-error">{errors.property}</p>) : null} */}
                                      <IoPersonAddOutline
                                        style={{
                                          marginLeft: "10px",
                                          fontSize: "35px",
                                          marginRight: "5px",
                                        }}
                                        onClick={handleAddContacts}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                     {displayAddContacts ? <div
                        className="contacts-group"
                        style={{ padding: "0px 30px" }}
                      >
                        <label htmlFor="address" className="s1 ml-3">
                          Type
                        </label>

                        <SelectButton
                          className="ml-3"
                          optionLabel="label"
                          options={typeData}
                          onChange={handlePropertyType}
                          value={typevalue}
                        />
                        <label htmlFor="address" className="s1 ml-3">
                          Title
                        </label>
                        <SelectButton
                          className="ml-3  mb-3"
                          optionLabel="label"
                          options={titleData}
                          onChange={handlePropertyTitle}
                          value={titleValue}
                        />
                        <TextBox
                          label="First Name"
                          placeholder="First name"
                          id="Unit"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.firstName}
                          touched={touched.firstName}
                        />
                        <TextBox
                          label="Last Name"
                          placeholder="Last name"
                          id="lastName"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.lastName}
                          touched={touched.lastName}
                        />
                        <TextBox
                          isMandatory
                          label="Phone"
                          placeholder="Phone"
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.phone}
                          touched={touched.phone}
                          icon={<BiPhone height="100%" width="100%" />}
                        />
                        <TextBox
                          label="Email Address"
                          placeholder="Email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.email}
                          touched={touched.email}
                          icon={<BiEnvelope height="100%" width="100%" />}
                        />
                        <AddressInput
                          address={ownerShipAddress}
                          setAddress={setOwnerShipAddress}
                          handlePressSelect={handleSellerAddress}
                          error={errors.ownerShipAddress}
                          touched={touched.ownerShipAddress}
                        />
                      </div> : null}
                      </div>
                    ) : null}
                  </div>
                }
              />
            </div>
          </div>
          <div className="property-details-buttons">
            <button className="pdb-cancel" type="reset" onClick={handleCancel}>
              Cancel
            </button>
            <button className="pdb-save-changes" type="submit">
              Save
            </button>
          </div>
        </form>
      ) : null}
    </div>
  );
};

export default PropertyOwnerShip;
