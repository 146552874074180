import React, { createContext, useContext, useState } from "react";
import { readnotification } from "../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../Utilities/client";
import { currentProfile } from "../components/CommonSection/Cookie/Session";
import { ActionStatus } from "../Utilities/enum";
import { useSpinnerContext } from "./SpinnerContext";

const ReadNotificationContext = createContext();

export const useReadNotificationContext = () => useContext(ReadNotificationContext);

export const ReadNotificationContextProvider = ({ children }) => {
  const [sideBarData, setSideBarData] = useState([]);
  const { showSpinner, hideSpinner } = useSpinnerContext();

  const readNotificationApi = (referenceId, getLoginDataforSideBar) => {
    const userData = JSON.parse(sessionStorage.getItem("@UserData"));
  
    if (!userData) {
      console.error("User data not found in session storage.");
      return Promise.reject("User data not available.");
    }
  
    const baseUrl = readnotification;
    const formData = {
      UserType: currentProfile.get(),
      UserId: userData.userId,
      ReferenceId: referenceId,
    };
  
    return makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", formData)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          getLoginDataforSideBar(userData.userId, currentProfile.get()); // Trigger sidebar update
          return response;
        } else {
          alert(`Message: ${response.message}`);
          return Promise.reject(response.message);
        }
      })
      .catch((error) => {
        console.error("Error marking notification as read:", error);
        return Promise.reject(error);
      });
  };
  
  

  return (
    <ReadNotificationContext.Provider
      value={{
        sideBarData,
        setSideBarData,
        readNotificationApi, // Ensure this is the correct name
      }}
    >
      {children}
    </ReadNotificationContext.Provider>
  );
  
};
