import React from "react";
import { useState, useRef, useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  BucketName,
  Region,
  AccessKey,
  Keyid,
  getProfileDetails,
  addPersonalDetailsProfile,
  FilePath,
  LicenseTokenUrl,
  LicenseVerifyUrl,
  LicenseApiKey,
  LicenceNumberBaseKey,
} from "../../CommonSection/apiresources";
import { BiEnvelope, BiPhone } from "react-icons/bi";
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import axios from "axios";
import { dateChnager } from "../../CommonSection/Utility/Utility";
import {
  makeRequestWithSpinner,
  uploadToS3,
  uploadToS3WithSpinner,
} from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { AgencyProfilePersonalDetailsSchema } from "../../CommonSection/Schema/schema";
import DatePicker from "react-datepicker";
import {
  getDefaultDateValue,
  parseToDate,
} from "../../CommonSection/Utility/ComponentFunction";
import ProfileImage from "../../CommonSection/ProfileImage/ProfileImage";
import TextBox from "../../../src/input-components/textbox";
import AddressInput from "../../../src/input-components/address";

const initialValues = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  jobTitle: "",
  address: "",
  license: "",
  expiryDate: "",
  reaAgentName: "",
  domainAgentFName: "",
  domainAgentLName: "",
  photo_Path: "",
};

const PersonalDetailsProfile = ({ handleToastMessage, handleCurrentPage }) => {
  const { showSpinner, hideSpinner } = useSpinnerContext();

  const [licenseToken, setLicenseToken] = useState(null);

  const ToastRef = useRef();
  const inputRef = useRef();
  const navigate = useNavigate();
  let selectedFile;
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyUniqueID, setAgencyUniqueId] = useState("");
  const [address,setAddress] = useState("");

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyUniqueId(parsedData.agencyUniqueID);
        }
      } catch (error) {
        ToastRef.current.notify([
          "error",
          "Error retrieving data from AsyncStorage",
        ]);
      }
    };

    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userUId !== "") {
      GetAgencyDetails();
    }
  }, [userUId]);

  const GetAgencyDetails = () => {
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      `${getProfileDetails}?agencyUID=${agencyUniqueID}`,
      "get"
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          let agencyDetails = returnData.object;
          setValues({ ...values, ...agencyDetails });
          setAddress(agencyDetails.address)
        } else {
          handleToastMessage(["error", response.data.message]);
        }
      })
      .catch((error) => {
        handleToastMessage(["error", error]);
      });
  };

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    action,
    setValues,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: AgencyProfilePersonalDetailsSchema,
    onSubmit: (values, action) => {
      submitPersonalDetails();
    },
  });

  const select = (event) => {
    inputRef.current.click();
  };
  const handleFileChange = async (e, indexforfiles) => {
    for (let i = 0; i < e.target.files.length; i++) {
      selectedFile = e.target.files[i];
      await handleUpload(indexforfiles);
    }
  };
  const handleUpload = async (indexforfiles) => {
    await uploadToS3WithSpinner(
      showSpinner,
      hideSpinner,
      "profile/",
      "profilepic",
      selectedFile
    ).then((returnData) => {
      debugger;
      if (returnData.status == ActionStatus.Successfull) {
        setFieldValue("photo_Path", returnData.Key);
        handleToastMessage(["success", "File uploaded successfully"]);
      } else {
        handleToastMessage([
          "error",
          "Error uploading file. Please try again.",
        ]);
      }
    });
  };
  const submitPersonalDetails = () => {
    const formDate = {
      AgencyUID: agencyUniqueID,
      Photo_Path: values.photo_Path,
      FirstName: values.firstName,
      LastName: values.lastName,
      Phone: values.phone,
      Email: values.email,
      JobTitle: values.jobTitle,
      Address: values.address,
      ExpiryDate: values.expiryDate,
      License: values.license,
      ReaAgentName: values.reaAgentName,
      DomainAgentFName: values.domainAgentFName,
      DomainAgentLName: values.domainAgentLName,
    };

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      addPersonalDetailsProfile,
      "post",
      formDate
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          handleToastMessage(["success", returnData.message]);
          handleCurrentPage("CompanyDetails");
        } else {
          handleToastMessage(["error", returnData.message]);

        }
      })
      .catch((error) => {
        ToastRef.current.notify();
        handleToastMessage(["error", error]);
      });
  };

  const handleLicenseNum = async (event) => {
    let licenseNumberValue = event.target.value;
    let bearerToken = licenseToken;
    if (bearerToken == null) {
      await makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        LicenseTokenUrl,
        "get",
        null,
        {
          Authorization: LicenceNumberBaseKey,
        }
      ).then((returnData) => {
        if (returnData.access_token != null) {
          bearerToken = returnData.access_token;
          setLicenseToken(bearerToken);
        } else {
          handleToastMessage(["error", "Authentication Failed"]);
        }
      });
    }

    if (bearerToken != null) {
      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        `${LicenseVerifyUrl}?licenceNumber=${licenseNumberValue}`,
        "get",
        null,
        {
          Authorization: `Bearer ${bearerToken}`,
          apiKey: LicenseApiKey,
        }
      ).then((returnData) => {
        if (returnData[0] != null) {
          handleToastMessage(["success", `Valid License Number ${licenseNumberValue}`]);
          setFieldValue("expiryDate", parseToDate(returnData[0].expiryDate));
        } else {
          handleToastMessage(["error", "Invalid License Number"]);
        }
      });
    }
  };

  const handleAddress = (selectedAddress) => {
    setAddress(selectedAddress);
    setFieldValue("address", selectedAddress);
  };

  return (
    <div>
      <form action="none" onSubmit={handleSubmit} className="details-form">
        <div className="df-all-agent">


          <div className="all-input"  style={{display:'initial'}}>

            <TextBox colVal={8} label="First Name" placeholder="First name" name="firstName" value={values.firstName} onChange={handleChange} onBlur={handleBlur} error={errors.firstName} touched={touched.firstName} />
            <TextBox colVal={8} label="Last name" placeholder="Last name" name="lastName" value={values.lastName} onChange={handleChange} onBlur={handleBlur} error={errors.lastName} touched={touched.lastName} />
            <TextBox colVal={8} label="Phone" placeholder="Phone" name="phone" value={values.phone} onChange={handleChange} onBlur={handleBlur} error={errors.phone} touched={touched.phone} icon={<BiPhone height="100%" width="100%" />} />
            <TextBox colVal={8} label="Email address" placeholder="Email" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} error={errors.email} touched={touched.email} icon={<BiEnvelope height="100%" width="100%" />} />
          </div>
          <div className="agent-detial-dp">
            <ProfileImage handleFileChange={handleFileChange} inputRef={inputRef} imageSrc={values.photo_Path} onClickHandler={select} isCircle={false} isPersonalImage={true} />
          </div>
        </div>
        <div className="all-input pl-2">
          <div className="df-group" style={{display:'initial'}}>
            <TextBox colVal={4} label="Job Title" placeholder="Job title" name="jobTitle" value={values.jobTitle} onChange={handleChange} onBlur={handleBlur} error={errors.jobTitle} touched={touched.jobTitle} />

            <AddressInput
              colVal={4}
              labelName={"Address"}
              address={address}
              setAddress={setAddress}
              handlePressSelect={handleAddress}
              error={errors.address}
              touched={touched.address}
            />
            <div className="pd-licensenumber col-lg-4 col-sm-12">
              <label htmlFor="lnumber" className="s1">
                License Number
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control tenant-date"
                  placeholder="License Number"
                  value={values.license}
                  onChange={handleChange}
                  onBlur={handleLicenseNum}
                  name="license"
                  aria-describedby="basic-addon1"
                />
              </div>
              {errors.license && touched.license ? (
                <p className="form-error">{errors.license}</p>
              ) : null}
            </div>


            <div className="pd-licenseexpiry col-lg-4 col-sm-12">
              <div className="input-group-prepend">

              </div>
              <label htmlFor="expiryDate" className="s1">
                Expiry Date
              </label>
             
              <div className="input-group mb-3">
            
                <DatePicker
                  className="form-control"
                  placeholder="dd/mm/yyyy"
                  selected={getDefaultDateValue(values.expiryDate)}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => setFieldValue("expiryDate", date)}
                />
              </div>
              {errors.expiryDate && touched.expiryDate ? (
                <p className="form-error">{errors.expiryDate}</p>
              ) : null}
            </div>


            <div className="pd-reaportalname col-lg-4 col-sm-12">
              <label htmlFor="fname" className="s1">
                Rea Portal Name
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control tenant-date"
                  placeholder="Rea Portal Name"
                  value={values.reaAgentName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="reaportalname"
                  aria-describedby="basic-addon1"
                />
              </div>
              {errors.reaAgentName && touched.reaAgentName ? (
                <p className="form-error">{errors.reaAgentName}</p>
              ) : null}
            </div>
            <div className="pd-domainportalname col-lg-4 col-sm-12">
              <label htmlFor="fname" className="s1">
                Domain Portal Name
              </label>
              <div className="pddpn-group">
                <div className="mb-3 pddpng1">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder="First Name"
                    value={values.domainAgentFName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="dpn1"
                    aria-describedby="basic-addon1"
                  />
                  {errors.domainAgentFName && touched.domainAgentFName ? (
                    <p className="form-error">{errors.domainAgentFName}</p>
                  ) : null}
                </div>
                <div className="mb-3 pddpng1">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder="Last Name"
                    value={values.domainAgentLName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="dpn2"
                    aria-describedby="basic-addon1"
                  />
                  {errors.domainAgentLName && touched.dpdomainAgentLNamen2 ? (
                    <p className="form-error">{errors.domainAgentLName}</p>
                  ) : null}
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="property-details-buttons">
          <button
            className="pdb-cancel"
            type="reset"
            onClick={() => handleToastMessage(["warn", "cancelled"])}
          >
            Cancel
          </button>
          <button className="pdb-save-changes" type="submit">
            Save
          </button>
        </div>

      </form>
    </div>
  );
};

export default PersonalDetailsProfile;
