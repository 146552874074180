import React, { useEffect, useState, useRef } from "react";
import { Checkbox, Spin } from "antd";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import SubNav from "../../navbar/subNav";
import "./permission.css"; // Custom CSS for better UI styling
import { currentProfile } from "../../../components/CommonSection/Cookie/Session";
import { getmodulesfortheUser, submitpermissionsforuser } from "../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";

const Permission = ({ handleparentsuccess }) => {
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [modules, setModules] = useState([]);
  const currentLogin = currentProfile.get();
  const ToastRef = useRef();
  const url = new URL(window.location.href);
  const mainuserId = url.searchParams.get("userId");

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
        }
      } catch (error) {
        console.error("Error retrieving data:", error);
      }
    };
    retrieveData();
  }, []);

  useEffect(() => {
    if (userId) getPermissionData(mainuserId, currentLogin);
  }, [userId]);

  const getPermissionData = (id, type) => {
    const baseUrl = getmodulesfortheUser;
    const data = {
      UserType: 3,
      UserId: id,
    };
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          const contactData = response.object.moduleDetails;
          setModules(contactData);
        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const submitPermissions = () => {
    const baseUrl = submitpermissionsforuser;
    const selectedModules = modules
      .filter((module) => module.isSelected)
      .map((module) => module.id);

    const data = {
      Modules: selectedModules,
      UserId: parseInt(mainuserId),
      UserType: 3,

    };

    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", response.message]);
          getPermissionData(mainuserId, currentLogin);
        } else {
          ToastRef.current.notify(["error", response.message]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        ToastRef.current.notify(["error", "An error occurred while submitting permissions."]);
      });
  };

  const handleCheckboxChange = (id, checked) => {
    setModules((prevModules) =>
      prevModules.map((module) =>
        module.id === id ? { ...module, isSelected: checked } : module
      )
    );
  };

  return (
    <div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
          <SubNav title={"Permission"} goback={true} extraData={true} />
          <div className="material-work-desk-new bg-white m-3">
            <ToastAlert ref={ToastRef} />
            <div className="permission-container">
              <div className="permission-list-container">
                {modules.length > 0 ? (
                  <>
                    {modules.map((module) => (
                      <div className="permission-item" key={module.id}>
                        <Checkbox
                          checked={module.isSelected}
                          onChange={(e) => handleCheckboxChange(module.id, e.target.checked)}
                        >
                          {module.label}
                        </Checkbox>
                      </div>
                    ))}
                   
                  </>
                ) : (
                  <Spin tip="Loading Permissions..." />
                )}
              </div>
              <div className="mr-5 pr-5" style={{float:'right'}}>
                      <button onClick={submitPermissions} className="pdb-save-changes"  type="submit">
                        Submit
                      </button>
                    </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Permission;
