import React from "react";
import { useEffect, useState, useRef } from "react";
import "./propertiesagency.css";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { addPropertyAttributes } from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import Collapse from "../../input-components/collapse";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import { Checkbox } from 'primereact/checkbox';  // Checkbox component

const initialValues = {
  PropertyLandAreaW: null,
  PropertyLandAreaL: '',
  countBedrooms: 0,
  countBathrooms: 0,
  countCarParking: 0,
  countEnsuites: 0,
  countGarageSpace: 0,
  countLivingAreas: 0,
  countToilets: 0,
  coutryard: 0,
  balcony: false,
  swimmmingPoolInGround: false,
  deck: false, // Add other features as needed
  fullyFenced: false,
  outdoorEntertainmentArea: false,
  outsideSpa: false,
  remoteGarage: false,
  secureParking: false,
  shed: false,
  swimmmingPoolAboveGround: false,
  tennisCourt: false,
  alarmSystem: false,
  builtInWardrobes: false,
  ductedVacuumSystem: false,
  gym: false,
  intercom: false,
  rumpusRoom: false,
  workshop: false,
  broadbandInternetAvailable: false,
  dishwasher: false,
  floorboards: false,
  insideSpa: false,
  payTVAccess: false,
  study: false,
  airConditioning: false,
  ductedHeating: false,
  gasHeating: false,
  openFireplace: false,
  splitSystemAirConditioning: false,
  ductedCooling: false,
  evaporativeCooling: false,
  hydraulicHeating: false,
  reverseCycleAirConditioning: false,
  splitSystemHeating: false,
  waterTank: false,
  greyWaterSystem: false,
  solarPanels: false,
  solarHotWater: false,
};

const PropertyAttributes = ({ handleparentsuccess, handleCurrentPage1, onDataRecive1, getDetails , cancel}) => {
  const [userId, setUserId] = useState("");
  const ToastRef = useRef();
  const [selectedUnit, setSelectedUnit] = useState(1);

  const [land, setLand] = useState([
    { Id: 1, label: "Feet", value: 1 },
    { Id: 2, label: "SQM's", value: 2 },
    { Id: 3, label: "Acres", value: 3 },
    { Id: 4, label: "Hectares", value: 4 },
  ]);

  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const [outdoorFeatures, setOutdoorFeatures] = useState([
    { label: "Balcony", value: false, name: "balcony" },
    { label: "Swimming Pool - In Ground", value: false, name: "swimmmingPoolInGround" },
    { label: "Deck", value: false, name: "deck" },
    { label: "Fully Fenced", value: false, name: "fullyFenced" },
    { label: "Outdoor Entertainment Area",value: false,name: "outdoorEntertainmentArea",},
    { label: "Outside Spa", value: false, name: "outsideSpa" },
    { label: "Remote Garage", value: false, name: "remoteGarage" },
    { label: "Secure Parking", value: false, name: "secureParking" },
    { label: "Shed", value: false, name: "shed" },
    { label: "Swimming Pool - Above Ground",value: false,name: "swimmmingPoolAboveGround",},
    { label: "Tennis Court", value: false, name: "tennisCourt" },
    { label: "Coutryard", value: false, name: "coutryard" },
  ]);

  const [indoorFeatures, setIndoorFeatures] = useState([
    { label: "Alarm System", value: false, name: "alarmSystem" },
    { label: "Workshop", value: false, name: "workshop" },
    { label: "Build-in Wardrobes", value: false, name: "builtInWardrobes" },
    { label: "Boardband Internet Available",value: false,name: "broadbandInternetAvailable"},
    { label: "Ducted Vaccum System", value: false, name: "ductedVacuumSystem" },
    { label: "Dishwasher", value: false, name: "dishwasher" },
    { label: "Gym", value: false, name: "gym" },
    { label: "Floorboards", value: false, name: "floorboards" },
    { label: "Intercom", value: false, name: "intercom" },
    { label: "Inside Spa", value: false, name: "insideSpa" },
    { label: "Rumpus Room", value: false, name: "rumpusRoom" },
    { label: "Pay TV Access", value: false, name: "payTVAccess" },
    { label: "Study", value: false, name: "study" },
  ]);

  const [heatCoolingFeatures, setHeatCoolingFeatures] = useState([
    { label: "Air Conditioning", value: false, name: "airConditioning" },
    { label: "Ducted Cooling", value: false, name: "ductedCooling" },
    { label: "Ducted Heating", value: false, name: "ductedHeating" },
    { label: "Evaporative Cooling", value: false, name: "evaporativeCooling" },
    { label: "Gas Heating", value: false, name: "gasHeating" },
    { label: "Hydraulic Heating", value: false, name: "hydraulicHeating" },
    { label: "Open FirePlace", value: false, name: "openFireplace" },
    { label: "Reverse Cycle Air Conditioning",value: false,name: "reverseCycleAirConditioning"},
    { label: "Split System Air Conditioning",value: false,name: "splitSystemAirConditioning"},
    { label: "Split System Heating", value: false, name: "splitSystemHeating" },
  ]);

  const [ecoFriendlyFeatures, setEcoFriendlyFeatures] = useState([
    { label: "Grey Water System", value: false, name: "greyWaterSystem" },
    { label: "Solar Hot Water", value: false, name: "solarHotWater" },
    { label: "Solar Panels", value: false, name: "solarPanels" },
    { label: "Water Tank", value: false, name: "waterTank" },
  ]);

  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      SetInitialValuesToToggle(getDetails);
      const getFilteredData1 = (selectedValue) => {
        return land.find((item) => item.value === selectedValue);
      };
      const selectedValue1 = getDetails.propertyLandAreaW != null
        ? getDetails.propertyLandAreaW
        : 1;
      const filteredData1 = getFilteredData1(selectedValue1);
      setSelectedUnit(selectedValue1)
      setFieldValue("PropertyLandAreaL", getDetails.propertyLandAreaL);
      setFieldValue("PropertyLandAreaW", filteredData1 != undefined ? filteredData1.value : "");
    }
  }, [getDetails]);

  const SetInitialValuesToToggle = (attributeDetails) => {
    const updatedOutdoorFeatures = outdoorFeatures.map((feature) => ({
      ...feature,
      value: attributeDetails[feature.name],
    }));
    setOutdoorFeatures(updatedOutdoorFeatures);

    const updatedIndoorFeatures = indoorFeatures.map((feature) => ({
      ...feature,
      value: attributeDetails[feature.name],
    }));
    setIndoorFeatures(updatedIndoorFeatures);

    const updatedheatCoolingFeatures = heatCoolingFeatures.map((feature) => ({
      ...feature,
      value: attributeDetails[feature.name],
    }));
    setHeatCoolingFeatures(updatedheatCoolingFeatures);

    const updatedecoFriendlyFeatures = ecoFriendlyFeatures.map((feature) => ({
      ...feature,
      value: attributeDetails[feature.name],
    }));
    setEcoFriendlyFeatures(updatedecoFriendlyFeatures);
  };

  const handleFinalSubmit = () => {
    const formData = {
      PropertyId: getDetails.propertyId,
      UserId: userId,
      PropertyAttibuteDetails: {
        PropertyLandAreaL: values.PropertyLandAreaL,
        PropertyLandAreaW: parseInt(selectedUnit),
        CountBedrooms: values.countBedrooms,
        CountBathrooms: values.countBathrooms,
        CountCarParking: values.countCarParking,
        CountEnsuites: values.countEnsuites,
        CountGarageSpace: values.countGarageSpace,
        CountLivingAreas: values.countLivingAreas,
        CountToilets: values.countToilets,
        Coutryard: values.coutryard,
      },

      PropertyFeaturesOutdoorDetails: {
        Balcony: values.balcony,
        Deck: values.deck,
        OutdoorEntertainmentArea: values.outdoorEntertainmentArea,
        RemoteGarage: values.remoteGarage,
        Shed: values.shed,
        SwimmmingPoolInGround: values.swimmmingPoolInGround,
        FullyFenced: values.fullyFenced,
        OutsideSpa: values.outsideSpa,
        SecureParking: values.secureParking,
        SwimmmingPoolAboveGround: values.swimmmingPoolAboveGround,
        TennisCourt: values.tennisCourt,
      },

      PropertyFeaturesIndoorDetails: {
        AlarmSystem: values.alarmSystem,
        BuiltInWardrobes: values.builtInWardrobes,
        DuctedVacuumSystem: values.ductedVacuumSystem,
        Gym: values.gym,
        Intercom: values.intercom,
        RumpusRoom: values.rumpusRoom,
        Workshop: values.workshop,
        BroadbandInternetAvailable: values.broadbandInternetAvailable,
        Dishwasher: values.dishwasher,
        Floorboards: values.floorboards,
        InsideSpa: values.insideSpa,
        PayTvaccess: values.payTVAccess,
        Study: values.study,
      },

      PropertyFeaturesHeatCoolDetails: {
        AirConditioning: values.airConditioning,
        DuctedHeating: values.ductedHeating,
        GasHeating: values.gasHeating,
        OpenFireplace: values.openFireplace,
        SplitSystemAirConditioning: values.splitSystemAirConditioning,
        DuctedCooling: values.ductedCooling,
        EvaporativeCooling: values.evaporativeCooling,
        HydraulicHeating: values.hydraulicHeating,
        ReverseCycleAirConditioning: values.reverseCycleAirConditioning,
        SplitSystemHeating: values.splitSystemHeating,
      },

      PropertyEcoFriendlyFeaturesDetails: {
        WaterTank: values.waterTank,
        GreyWaterSystem: values.greyWaterSystem,
        SolarPanels: values.solarPanels,
        SolarHotWater: values.solarHotWater,
      },
    };
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      addPropertyAttributes,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          handleCurrentPage1("OwnerShip")
        }
      })
      .catch((error) => {
        //handleparentsuccess(["error", error.message]);
      });
  };

  const handleOutdoorChange = (index) => {
    const updatedOutdoorValues = [...outdoorFeatures];
    updatedOutdoorValues[index].value = !updatedOutdoorValues[index].value;
    setOutdoorFeatures(updatedOutdoorValues);
    setFieldValue(
      updatedOutdoorValues[index].name,
      updatedOutdoorValues[index].value
    );
  };

  const handleIndoorChange = (index) => {
    const updatedIndoorValues = [...indoorFeatures];
    updatedIndoorValues[index].value = !updatedIndoorValues[index].value;
    setIndoorFeatures(updatedIndoorValues);
    setFieldValue(
      updatedIndoorValues[index].name,
      updatedIndoorValues[index].value
    );
  };

  const handleheatCoolingChange = (index) => {
    const updatedheatCoolingValues = [...heatCoolingFeatures];
    updatedheatCoolingValues[index].value =
      !updatedheatCoolingValues[index].value;
    setHeatCoolingFeatures(updatedheatCoolingValues);
    setFieldValue(
      updatedheatCoolingValues[index].name,
      updatedheatCoolingValues[index].value
    );
  };

  const handleEcoFriendlyChange = (index) => {
    const updatedEcoFrndlyValues = [...ecoFriendlyFeatures];
    updatedEcoFrndlyValues[index].value = !updatedEcoFrndlyValues[index].value;
    setEcoFriendlyFeatures(updatedEcoFrndlyValues);
    setFieldValue(
      updatedEcoFrndlyValues[index].name,
      updatedEcoFrndlyValues[index].value
    );
  };

  const handleCancel = () => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
    if (propertyUId) {
      dynamicUrl(navigate, "ViewPropertyDetails" + "?propertyUID=" + propertyUId);
    } else {
      dynamicUrl(navigate, "propertiesList");
    }
  };

  const handleUnitChange = (e) => {
    console.log(e.target.value)
    setSelectedUnit(e.target.value);
  };


  return (
    <div>
      <ToastAlert ref={ToastRef} />
      <form onSubmit={handleSubmit} className="details-subform">
        <div style={{ width: '100%' }}>
          <Collapse
            id={"Basic-Details"}
            title={"Basic Details"}
            data={
              <div className="pddpn-group">

                <TextBox
                  label="Beds"
                  placeholder="Beds"
                  name="countBedrooms"
                  value={values.countBedrooms}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.countBedrooms}
                  touched={touched.countBedrooms}
                  type={'number'}
                />
                <TextBox
                  label="Baths"
                  placeholder="Baths"
                  name="countBathrooms"
                  value={values.countBathrooms}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.countBathrooms}
                  touched={touched.countBathrooms}
                  type={'number'}

                />
                <TextBox
                  label="Garage Space"
                  placeholder="Garage Space"
                  name="countGarageSpace"
                  value={values.countGarageSpace}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.countGarageSpace}
                  touched={touched.countGarageSpace}
                  type={'number'}

                />
                <TextBox
                  label="Toilets"
                  placeholder="Toilets"
                  name="countToilets"
                  value={values.countToilets}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.countToilets}
                  touched={touched.countToilets}
                  type={'number'}

                />

                <TextBox
                  label="Ensuites"
                  placeholder="Ensuites"
                  name="countEnsuites"
                  value={values.countEnsuites}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.countEnsuites}
                  touched={touched.countEnsuites}
                  type={'number'}

                />
                <TextBox
                  label="Living Areas"
                  placeholder="Living Areas"
                  name="countLivingAreas"
                  value={values.countLivingAreas}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.countLivingAreas}
                  touched={touched.countLivingAreas}
                  type={'number'}

                />

                <TextBox
                  label="Car Ports"
                  placeholder="Car Ports"
                  name="countCarParking"
                  value={values.countCarParking}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.countCarParking}
                  touched={touched.countCarParking}
                  type={'number'}

                />


                <TextBox
                  label="Land Area"
                  placeholder="Land Area"
                  id="Unit"
                  name="PropertyLandAreaL"
                  value={values.PropertyLandAreaL}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.PropertyLandAreaL}
                  touched={touched.PropertyLandAreaL}
                  unitOptions={land}
                  selectedUnit={selectedUnit}
                  onUnitChange={handleUnitChange}
                />
              </div>
            }
          />
          <Collapse
            id={"Optional-Features"}
            title={"Optional Features"}
            data={
              <div className="row">
                <div className="mb-2" style={{fontWeight:'500'}}>Outdoor Feature</div>
                {outdoorFeatures.map((feature, index) => (
                  <div className="col-lg-4 mb-3 d-flex" key={index}>
                    <Checkbox
                      inputId={`outdoor-${index}`}
                      checked={feature.value}
                      onChange={() => handleOutdoorChange(index)}
                    />
                    <div className="ml-2" htmlFor={`outdoor-${index}`}>{feature.label}</div>
                  </div>
                ))}
                <div className="mb-2" style={{fontWeight:'500'}}>Indoor Feature</div>
                {indoorFeatures.map((feature, index) => (
                  <div className="col-lg-4 mb-3 d-flex" key={index}>
                    <Checkbox
                      inputId={`indoor-${index}`}
                      checked={feature.value}
                      onChange={() => handleIndoorChange(index)}
                    />
                    <div className="ml-2" htmlFor={`indoor-${index}`}>{feature.label}</div>
                  </div>
                ))}
                <div className="mb-2" style={{fontWeight:'500'}}>Heating / Cooling</div>
                {heatCoolingFeatures.map((feature, index) => (
                  <div className="col-lg-4 mb-3 d-flex" key={index}>
                  <Checkbox
                    inputId={`heatcool-${index}`}
                    checked={feature.value}
                    onChange={() => handleheatCoolingChange(index)}
                  />
                  <div className="ml-2" htmlFor={`heatcool-${index}`}>{feature.label}</div>
                </div>
              ))}
              <div className="mb-2" style={{fontWeight:'500'}}>Eco Friendly Feature</div>
              {ecoFriendlyFeatures.map((feature, index) => (
                <div className="col-lg-4 mb-3 d-flex" key={index}>
                <Checkbox
                    inputId={`eco-${index}`}
                    checked={feature.value}
                    onChange={() => handleEcoFriendlyChange(index)}
                  />
                  <div className="ml-2"  htmlFor={`eco-${index}`}>{feature.label}</div>
                </div>
              ))}
              </div>
            }
          />
        </div>
        <div className="property-details-buttons">
          <button
            className="pdb-cancel"
            type="reset"
            onClick={cancel}
          >
            Cancel
          </button>
          <button className="pdb-save-changes" type="submit">
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default PropertyAttributes;
