import React from "react";
import "./signinclass.css";
import { useFormik } from "formik";
import { signInSchema } from "../Schema/schema";
import { useNavigate } from "react-router-dom";
import {
  SetCookie,
  currentProfile,
} from "../Cookie/Session";
import Footer from "../Footer/Footer";
import { userLogin } from "../apiresources";
import ToastAlert from "../ToastAlert/ToastAlert";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { ActionStatus, UserRoleId } from "../../../Utilities/enum";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";

const ToastRef = React.createRef();

const initialValues = {
  email: "",
  password: "",
  rem: false,
};

const LogIn = () => {
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const navigate = useNavigate();

  // Formik for form handling
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: signInSchema,
      onSubmit: (values, { resetForm }) => {
        handleLogin(values);
        resetForm();
      },
    });

  // Handle login logic
  const handleLogin = (values) => {
    const loginData = {
      UserName: values.email,
      Password: values.password,
    };

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      userLogin,
      "post",
      loginData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          handleUserProfiles(returnData.object, values.rem);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        console.error("Login Error:", error);
        ToastRef.current.notify(["error", "An error occurred while logging in."]);
      });
  };

  // Handle user profiles and navigation
  const handleUserProfiles = (userData, rememberMe) => {
    sessionStorage.setItem("@UserData", JSON.stringify(userData));
    SetCookie("@UserData", JSON.stringify(userData), rememberMe);

    const { hasMultipleProfiles, userProfiles } = userData;
debugger
    if (hasMultipleProfiles) {
      navigate("/selection");
      return;
    }

    // Filter valid profiles
    const validProfiles = userProfiles.filter(
      (profile) => profile.userRoleId !== UserRoleId.Contact
    );

    if (!validProfiles.length) {
      navigate("/selection");
      return;
    }

    // Navigate based on profile type
    navigateBasedOnProfile(validProfiles);
  };

  // Navigation based on user role
  const navigateBasedOnProfile = (profiles) => {
    debugger
    const roles = {
      landlord: profiles.filter((profile) => profile.userRoleId === UserRoleId.Landlord),
      agent: profiles.filter((profile) => profile.userRoleId === UserRoleId.Agent),
      agency: profiles.filter((profile) => profile.userRoleId === UserRoleId.Agency),
      tenant: profiles.filter((profile) => profile.userRoleId === UserRoleId.Tenant),
      trade: profiles.filter((profile) => profile.userRoleId === UserRoleId.Trade),
    };

    if (roles.landlord.length === 1) {
      navigate("/selection");
    } else if (roles.agent.length === 1) {
      currentProfile.set(UserRoleId.Agent);
      navigate("/agent/dashboard");
    } else if (roles.agency.length === 1) {
      currentProfile.set(UserRoleId.Agency);
      navigate("/agency/dashboard");
    } else if (roles.tenant.length === 1) {
      currentProfile.set(UserRoleId.Tenant);
      navigate("/tenant/DashboardTenant");
    } else if (roles.trade.length === 1) {
      currentProfile.set(UserRoleId.Trade);
      navigate("/trade/TradesmanDashboard");
    } else {
      navigate("/selection");
    }
  };

  const handleHome = () => {
    navigate("/home");
  }

  return (
    <div className="col-12 section">
      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-3 container">
        <div className="header-si" style={{cursor:'pointer'}} onClick={handleHome}>
          <img
            className="logo-forealproperty-one-color2"
            alt="Logo"
            src="./assets/images/mainLogo.png"
          />
          <ToastAlert ref={ToastRef} />
        </div>
        <div className="content-signin">
          <div className="content2">
            <div className="text-and-supporting-text-su">
              <div className="text-si">Log in</div>
            </div>
          </div>
          <div className="content1">
            <form className="form" onSubmit={handleSubmit}>
              <div className="input-field">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="email primary"
                  placeholder="Enter your email"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email && (
                  <p className="form-error">{errors.email}</p>
                )}
                <label htmlFor="password">Password</label>
                <input
                  className="password primary"
                  type="password"
                  name="password"
                  placeholder="Enter the password"
                  id="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.password && touched.password && (
                  <p className="form-error">{errors.password}</p>
                )}
              </div>
              <div className="input-field2-signin">
                <div className="input2">
                  <input
                    type="checkbox"
                    name="rem"
                    id="rem"
                    className="checkbox"
                    checked={values.rem}
                    onChange={handleChange}
                  />
                  <div className="text3">&nbsp;Remember me</div>
                </div>
                <div className="button">
                  <a href="/forgot_password" className="a">
                    Forgot Password
                  </a>
                </div>
              </div>
              <div>
                <button type="submit" className="button1">
                  Sign in
                </button>
              </div>
              <div className="signup">
                <div className="promt">Don’t have an account? &nbsp;</div>
                <div className="submitbutton">
                  <a href="/signup" className="a">
                    Signup
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LogIn;
