import React from "react";
import { useEffect, useState, useRef } from "react";
import "./propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../input-components/selectBoxNew";
import {
  addPropertyListings,
  ReaUplode,
} from "../../../components/CommonSection/apiresources";
import PhotoPage from "./propertyPhoto";
import LegalPage from "./propertyLegal";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus, ListingType } from "../../../Utilities/enum";
import { Dropdown as AntDropdown, Checkbox, Tabs, message } from "antd";
import CustomModal from "../../input-components/Modal";

import {
  FormatDate,
  FormatDateView,
} from "../../../components/CommonSection/Utility/Utility";
import { parseToDate } from "../../../components/CommonSection/Utility/ComponentFunction";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { BiDollar } from "react-icons/bi";
import ToggleTabs from "../../input-components/toggleButtonTabs";
import { Radio } from "antd";
import {
  propertyListingRental,
  propertyListingSale,
} from "../../../components/CommonSection/Schema/schema";

const { TabPane } = Tabs;

const initialValues = {
  listingStatus: "",
  propertyAgreementType: "",
  rentTenure: "",
  propertyPrice: "",
  rentPrice: "",
  isCommercial: "",
  isNew: "",
  dualAgent: "",
  leadAgent: "",
  isDualAgent: "",
  isShowPrice: 2,
  showText: "",
  reaUploadId: "",
  propertyFor: null,
  listingId: 0,
  dateAvailable: null,
};

const PropertyListing = ({
  onDataRecive,
  getDetails,
  reportTo,
  handleCurrentPage,
  cancel,
  listingType,
}) => {
  const [userId, setUserId] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [leadAgent, setLeadAgent] = useState(null);
  const [dualAgent, setDualAgent] = useState(null);
  const [statusSaleData, setStatusSaleData] = useState([
    { label: "Active", value: 1 },
    { label: "Deleted", value: 2 },
    { label: "Off Market", value: 3 },
    { label: "Sold", value: 4 },
    { label: "Under Offer", value: 5 },
  ]);
  const [statusRentData, setStatusRentData] = useState([
    { label: "Active", value: 1 },
    { label: "Deposit Taken", value: 2 },
    { label: "Deleted", value: 3 },
    { label: "Off Market", value: 4 },
    { label: "Leased", value: 5 },
  ]);

  const [agreeData, setAgreeData] = useState([
    { label: "Auction", value: 1 },
    { label: "Exclusive", value: 2 },
    { label: "Multi List", value: 3 },
    { label: "Conjunctional", value: 4 },
    { label: "Open", value: 5 },
    { label: "Sale by Negotiation", value: 6 },
    { label: "Set Sale", value: 7 },
    { label: "Management", value: 8 },
    { label: "Tenant Lease", value: 8 },
  ]);

  const [rentData, setRentData] = useState([
    { label: "Weekly", value: 1 },
    { label: "Monthly", value: 2 },
  ]);

  const [isCommercialData, setIsCommercialData] = useState([
    { label: "Yes", value: 1 },
    { label: "No", value: 2 },
  ]);

  const [isNewData, setIsNewData] = useState([
    { label: "Yes", value: 1 },
    { label: "No", value: 2 },
  ]);

  const [showPriceData, setShowPriceData] = useState([
    { label: "Yes", value: 1 },
    { label: "No", value: 2 },
  ]);
  const [activeTab, setActiveTab] = useState("1");
  const navigate = useNavigate();
  const ToastRef = useRef();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [selectedLeadAgents, setSelectedLeadAgents] = useState([]);
  const [isReaUploaded, setIsReaUploaded] = useState(null);
  const [isDomainUploaded, setIsDomainUploaded] = useState(null);
  const [listStatusOptions, setListStatusOptions] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [reaUplode, setReaUplode] = useState(false);
  const [domainUplode, setDomainUplode] = useState(false);
  const [listingId, setListingId] = useState(null);

  const finalReportToObject = [
    ...reportTo,
    {
      disabled: false,
      group: null,
      selected: false,
      text: "--Select--",
      value: "Select",
    },
  ];
  const [tabValue, setTabValue] = useState("photos");

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId, propertyType]);

  useEffect(() => {
    if (getDetails) {
      handleDisplaySaleOrRental(listingType);
    }
  }, [getDetails, reportTo]);

  useEffect(() => {
    var url = new URL(window.location.href);
    var tab = url.searchParams.get("tab");
    if (tab) {
      setTabValue(tab);
    }
  }, []);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: () =>
      values.propertyFor === ListingType.Rental
        ? propertyListingRental
        : propertyListingSale,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (UploadPortal = false) => {
    let dualAgent = parseInt(values.dualAgent, 10);
    let leadAgent = parseInt(values.leadAgent, 10);
    const formData = {
      ListingId: values.listingId ? values.listingId : 0,
      ListingAgentId: leadAgent,
      PropertyId: getDetails ? getDetails.propertyId : 0,
      ListingStatus: values.listingStatus,
      PropertyAgreementType: values.propertyAgreementType,
      RentTenure: values.rentTenure,
      PropertyPrice: values.propertyPrice,
      RentPrice: values.rentPrice,
      IsCommercial: values.isCommercial === 1 ? true : false,
      IsNew: values.isNew,
      DualAgent: dualAgent,
      IsDualAgent: dualAgent ? true : dualAgent === "Select" ? null : false,
      IsShowPrice: values.isShowPrice === 1 ? true : false,
      ShowText: values.showText,
      ReaUploadId: values.reaUploadId,
      PropertySaleRental: values.propertyFor,
      DateAvailable: values.dateAvailable ? values.dateAvailable : null,
      UserId: userId,
    };
    if (UploadPortal) {
      formData.UploadPortal = true;
      formData.ReaUpload = reaUplode;
      formData.DomainUpload = domainUplode;
    }
    const url = addPropertyListings;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          message.success(returnData.message);
          cancel();
          //onDataRecive(getDetails.propertyUniqueID);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleLeadAgent = (e) => {
    const selectedLeadAgentValue = e.value;
    setLeadAgent(e.value);
    setFieldValue("leadAgent", e.value);
    setSelectedLeadAgents(selectedLeadAgentValue);
  };

  const updatedReportToWithoutSelectedLead = finalReportToObject.filter(
    (agent) => !selectedLeadAgents.includes(agent.value)
  );

  const handleDualAgent = (e) => {
    setDualAgent(e.value);
    setFieldValue("dualAgent", e.value);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleItemClickInPropertyListings = (e) => {
    setOpenUpload(true);
  };

  const handleDisplaySaleOrRental = (listingType) => {
    setFieldValue("propertyFor", listingType);
    const isSale = listingType === ListingType.Sales;
    setListStatusOptions(isSale ? statusSaleData : statusRentData);
    const listing = getDetails.listingDetails
      ? getDetails.listingDetails.find(
          (item) => item.propertySaleRental === (isSale ? 1 : 2)
        )
      : null;

    if (listing) {
      setCommonFields(listing);
      if (isSale) {
        setSaleFields(listing);
      } else {
        setRentalFields(listing);
      }
      setAgentFields(listing.listingAgentId, listing.dualAgent);
    } else {
      setFieldValue("listingId", 0);
    }
  };

  const setCommonFields = (listing) => {
    setFieldValue("listingId", listing.listingId);
    setFieldValue("listingStatus", listing.listingStatus);
    setFieldValue("isNew", listing.isNew ? 1 : 2);
    setFieldValue("isCommercial", listing.isCommercial ? 1 : 2);
    setFieldValue("showText", listing.showText);
    setFieldValue("isShowPrice", listing.isShowPrice ? 1 : 2);
  };

  const setSaleFields = (listing) => {
    setFieldValue("propertyAgreementType", listing.propertyAgreementType);
    setFieldValue("propertyPrice", listing.propertyPrice);
  };

  const setRentalFields = (listing) => {
    setFieldValue("dateAvailable", parseToDate(listing.dateAvailable));
    setFieldValue("rentTenure", getDetails.rentTenure);
    setFieldValue("rentPrice", getDetails.rentPrice);
  };

  const setAgentFields = (listingAgentId, dualAgentId) => {
    const leadAgent = reportTo.find(
      (item) => parseInt(item.value, 10) === listingAgentId
    );
    if (leadAgent) {
      setLeadAgent(leadAgent.value);
      setFieldValue("leadAgent", leadAgent.value);
    }

    const dualAgent = reportTo.find(
      (item) => parseInt(item.value, 10) === dualAgentId
    );
    if (dualAgent) {
      setDualAgent(dualAgent.value);
      setFieldValue("dualAgent", dualAgent.value);
    }
  };

  const handleCancel = () => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
    if (propertyUId) {
      handleCurrentPage("Overview");
    } else {
      dynamicUrl(navigate, "propertiesList");
    }
  };

  const handleUpload = () => {
    setOpenUpload(true);
  };

  const onChangeUpload = (type) => {
    if (type === "rea") {
      setReaUplode(!reaUplode);
    } else if (type === "domain") {
      setDomainUplode(!domainUplode);
    }
  };

  const items = [
    {
      key: "2",
      label: <a onClick={handleUpload}>Upload</a>,
    },
  ];
  const closeUpload = () => {
    setOpenUpload(false);
  };

  const handlePage = (data) => {
    handleCurrentPage(data);
  };

  const handleReaSubmit = () => {
    if (getDetails.propertyPictures.length === 0) {
      ToastRef.current.notify(["error", "Property Images are required."]);
    } else if (
      getDetails.propertyType != "16" &&
      !getDetails.countBedrooms === null
    ) {
      ToastRef.current.notify([
        "error",
        "A 'number of bedrooms' has not been provided for this property.",
      ]);
    } else if (
      getDetails.propertyType != "16" &&
      !getDetails.countBathrooms === null
    ) {
      ToastRef.current.notify([
        "error",
        "A 'number of bathrooms' has not been provided for this property.",
      ]);
    } else if (
      getDetails.propertyDescription === null ||
      getDetails.propertyDescription === ""
    ) {
      ToastRef.current.notify(["error", "Please enter description for property"]);
    } else if (getDetails.propertyHeading === null) {
      ToastRef.current.notify(["error", "Please enter Heading for property"]);
    } else {
      const data = {
        propertyID: getDetails.propertyUniqueID,
        isrea: reaUplode,
        isdomain: domainUplode,
        isPrincipleLogged: true,
        ListingId: values.listingId,
      };

      makeRequestWithSpinner(showSpinner, hideSpinner, addPropertyListings, "post", data)
        .then((returnData) => {
          if (returnData.status === ActionStatus.Successfull) {
            setOpenUpload(false);
            ToastRef.current.notify(["success",returnData.message]);
          } else {
            ToastRef.current.notify(["error",returnData.message]);

          }
        })
        .catch((error) => {
          ToastRef.current.notify(["error",returnData.message]);

        });
    }
  };
  const handleItemClick = (e, listingId) => {
    e.preventDefault();
    setListingId(listingId);
  };

  const tabs = [
    { label: "Photos", value: "photos" },
    { label: "Legal", value: "legal" },
    { label: "Property Listing", value: "propertyListing" },
  ];

  const handleTabChange1 = (activeTab) => {
    // Update your state or logic based on the active tab
    setTabValue(activeTab);
  };

  return (
    <div>
      <ToastAlert ref={ToastRef} />
      <ToggleTabs
        tabs={tabs}
        onTabChange={handleTabChange1}
        externalTabValue={tabValue}
      />

      {tabValue === "photos" ? (
        <div>
          <PhotoPage
            //ToastRef.current.notify={ToastRef.current.notify}
            getDetails={getDetails}
            handleCurrentPage1={handleTabChange1}
            cancel={cancel}
          />
        </div>
      ) : null}
      {tabValue === "legal" ? (
        <div>
          <LegalPage
            //ToastRef.current.notify={ToastRef.current.notify}
            getDetails={getDetails}
            handleCurrentPage1={handleTabChange1}
            cancel={cancel}
          />
        </div>
      ) : null}
      {tabValue === "propertyListing" ? (
        <div>
          <div>
            <CustomModal
              title="Upload property to"
              width={500}
              closable={closeUpload}
              visible={openUpload}
              onCancel={closeUpload}
              modalData={
                <div
                  className="row"
                  style={{ marginTop: "30px", marginLeft: "10px" }}
                >
                  <div className="col-lg-5 col-sm-12">
                    <Checkbox
                      checked={reaUplode}
                      onChange={() => onChangeUpload("rea")}
                    >
                      REA {isReaUploaded ? "(Update)" : null}
                    </Checkbox>
                  </div>
                  <div className="col-lg-5 col-sm-12">
                    <Checkbox
                      checked={domainUplode}
                      onChange={() => onChangeUpload("domain")}
                    >
                      Domain {isDomainUploaded ? "(Update)" : null}
                    </Checkbox>
                  </div>
                </div>
              }
              footer={[
                <div
                  key="footer"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    style={{ marginRight: "20px" }}
                    className="pdb-save-changes mt-2"
                    onClick={() => handleFinalSubmit(true)}
                  >
                    Submit
                  </button>
                </div>,
              ]}
            />
            <form onSubmit={handleSubmit}>
              <div className="all-input">
                <div className="row contacts-group">
                  <div className="mt-3">
                    <Radio.Group
                      onChange={(e) =>
                        handleDisplaySaleOrRental(e.target.value)
                      }
                      value={values.propertyFor}
                    >
                      <Radio value={ListingType.Sales}>Sales</Radio>
                      <Radio value={ListingType.Rental}>Rent</Radio>
                    </Radio.Group>
                  </div>
                  {values.propertyFor ?
                 <div className="row contacts-group">
                 <SelectBox
                    label="Listing Status"
                    isMandatory
                    placeholder="Listing Status"
                    name="listingStatus"
                    options={listStatusOptions}
                    value={values.listingStatus}
                    onChange={(val) =>
                      setFieldValue("listingStatus", val.value)
                    }
                    error={errors.listingStatus}
                    touched={touched.listingStatus}
                  />

                  {values.propertyFor == ListingType.Sales ? (
                    <SelectBox
                      label="Agreement Type"
                      placeholder="Agreement Type"
                      name="propertyAgreementType"
                      options={agreeData}
                      value={values.propertyAgreementType}
                      onChange={(val) =>
                        setFieldValue("propertyAgreementType", val.value)
                      }
                      error={errors.propertyAgreementType}
                      touched={touched.propertyAgreementType}
                    />
                  ) : null}
                  {values.propertyFor == ListingType.Sales ? (
                    <TextBox
                      isMandatory
                      label="Property Price"
                      placeholder="Property Price"
                      name="propertyPrice"
                      value={values.propertyPrice}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.propertyPrice}
                      touched={touched.propertyPrice}
                      icon={<BiDollar height="100%" width="100%" />}
                    />
                  ) : null}
                  {values.propertyFor == ListingType.Rental ? (
                    <TextBox
                      isMandatory
                      label="Rental Price"
                      placeholder="Rental Price"
                      name="rentPrice"
                      value={values.rentPrice}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.rentPrice}
                      touched={touched.rentPrice}
                    />
                  ) : null}
                  {values.propertyFor == ListingType.Rental ? (
                    <SelectBox
                      isMandatory
                      label="Rental Intervals"
                      placeholder="Rental Intervals"
                      name="rentTenure"
                      options={rentData}
                      value={values.rentTenure}
                      onChange={(val) => setFieldValue("rentTenure", val.value)}
                      error={errors.rentTenure}
                      touched={touched.rentTenure}
                    />
                  ) : null}
                  <SelectBox
                    label="Commercial"
                    placeholder="Is Commercial"
                    name="isCommercial"
                    options={isCommercialData}
                    value={values.isCommercial}
                    onChange={(val) => setFieldValue("isCommercial", val.value)}
                    error={errors.isCommercial}
                    touched={touched.isCommercial}
                  />

                  <SelectBox
                    label="New or Established"
                    placeholder="New or Established"
                    name="isNew"
                    options={isNewData}
                    value={values.isNew}
                    onChange={(val) => setFieldValue("isNew", val.value)}
                    error={errors.isNew}
                    touched={touched.isNew}
                  />

                  <div className="cda-reportTo col-lg-6 col-sm-12">
                    <label htmlFor="trading state" className="s1 w-100">
                      Listing Agent <span style={{ color: "red" }}>*</span>
                    </label>
                    <Dropdown
                      value={leadAgent}
                      onChange={handleLeadAgent}
                      options={finalReportToObject}
                      optionLabel="text"
                      className="w-100 md:w-14rem"
                      placeholder="Listing Agent"
                      style={{
                        width: "100%",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    />

                    {errors.leadAgent && touched.leadAgent ? (
                      <p className="form-error">{errors.leadAgent}</p>
                    ) : null}
                  </div>
                  <div className="cda-reportTo col-lg-6 col-sm-12">
                    <label htmlFor="trading state" className="s1 w-100">
                      Dual Agent
                    </label>
                    <Dropdown
                      value={dualAgent}
                      onChange={handleDualAgent}
                      options={updatedReportToWithoutSelectedLead}
                      optionLabel="text"
                      className="w-100 md:w-14rem "
                      placeholder="Select Dual Agent"
                      style={{
                        width: "100%",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    />

                    {errors.dualAgent && touched.dualAgent ? (
                      <p className="form-error">{errors.dualAgent}</p>
                    ) : null}
                  </div>

                  {values.propertyFor == ListingType.Sales ? (
                    <SelectBox
                      label="Show Price"
                      placeholder=""
                      name="isShowPrice"
                      options={showPriceData}
                      value={values.isShowPrice}
                      onChange={(val) =>
                        setFieldValue("isShowPrice", val.value)
                      }
                      error={errors.isShowPrice}
                      touched={touched.isShowPrice}
                    />
                  ) : null}

                  {values.propertyFor == ListingType.Sales &&
                    values.isShowPrice === 2 && (
                      <TextBox
                        label="Show Text"
                        isMandatory
                        placeholder="Show Text"
                        name="showText"
                        value={values.showText}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.showText}
                        touched={touched.showText}
                      />
                    )}
                  {values.propertyFor == ListingType.Rental ? (
                    <TextBox
                      label="Date Available"
                      type="date"
                      name="dateAvailable"
                      placeholder="dd/mm/yyyy"
                      value={FormatDate(values.dateAvailable)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.dateAvailable}
                      touched={touched.dateAvailable}
                    />
                  ) : null}
                 </div>: null }
                </div>
              </div>
              <div className="property-details-buttons">
                <button className="pdb-cancel" type="reset" onClick={cancel}>
                  Cancel
                </button>
                <button className="pdb-save-changes" type="submit">
                  Save
                </button>
                <button
                  className="pdb-cancel"
                  type="button"
                  onClick={handleItemClickInPropertyListings}
                >
                  Save & Publish
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PropertyListing;
