import React, { useMemo, useRef, useState, useEffect } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import debounce from "lodash/debounce";
import { getContactActivitiesList, SearchProperty, searchAreaOfOperation } from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { Select, Spin, Input } from "antd";
import exportToExcel from "../../input-components/excel";
import { getActivitiesDescriptionFromValue, getDescriptionFromValue } from "../../../Utilities/helper";
import { ActionStatus, eContactActivities } from "../../../Utilities/enum";
import "./activities.css"
import AntdTableExample from "../../input-components/dataTable";
import SubNav from "../../navbar/subNav";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import { useNavigate } from "react-router-dom";

const ContactActivitiesList = ({ handleparentsuccess }) => {
  const [activitiesList, setActivitiesList] = useState([]);
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const dataTableRef = useRef(null);
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [options1, setOptions1] = useState([]);
  const fetchRef = useRef(0);
  const fetchRef1 = useRef(0);
  const [searchValue, setSearchValues] = useState("");
  const [searchValue1, setSearchValues1] = useState("");
  const [postcode, setPostcode] = useState("");
  const [propertyActivities, setActivities] = useState("");
  const [propertyTypeVal, setPropertyTypeVal] = useState("");
  const [leadType, setLeadType] = useState("");
  const [date, setDate] = useState("");
  const [leadTypeArr, setLeadTypeArr] = useState([
    { label: "Select", value: null },
    { label: "Hot", value: 5 },
    { label: "Warm", value: 4 },
    { label: "Cold", value: 3 },
  ]);
  const [propertyTypeArr, setPropertyTypeArr] = useState([
    { label: "All", value: null },
    { label: "Sale", value: "1" },
    { label: "Rental", value: "2" },
  ]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [budgetVal, setBudgetVal] = useState('');
  const [contactVal, setContactVal] = useState('');
  const [comments,setComments] = useState('');
  const navigate = useNavigate();
  const contactActivitiesArr = Object.entries(eContactActivities).map(([value, label]) => ({
    value: parseInt(value), // Convert key to integer
    label: label,
  }));
  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) ListContactActivities(1, 10);
  }, [userId]);

  useEffect(() => {
    const yesterday = new Date(
      new Date().setDate(new Date().getDate() - 1)
    ).toISOString();
    setDate(yesterday);
  }, []);

  const ListContactActivities = (currentPage, totalRows) => {
    const baseUrl = getContactActivitiesList;
    let data = {
      sortby: "CreatedAt",
      SortOrder: "Desc",
      RecordsPerPage: totalRows,
      AgencyId: agencyID,
      PropertyType: propertyTypeVal,
      PropertyAddress: searchValue,
      Suburb: parseInt(searchValue1),
      LeadType: leadType,
      Budget: budgetVal,
      ContactName: contactVal,
      Activity: propertyActivities === 0 ? null : propertyActivities,
      PageNo: currentPage,
      Comments: comments,
      PostCode: postcode
    };
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
            let contactData = response.list;
            let contactList = contactData
              .map((conatact) => ({
                Name: `${conatact.firstName} ${conatact.lastName}`,
                MobileNo: conatact.phoneNumber,
                Email:conatact.emailAddress,
                Property: conatact.property,
                Activity: getActivitiesDescriptionFromValue(conatact.activity),
                LeadType: conatact.leadType,
                Comments: conatact.comments,
                guid: conatact.propertyUId
              }));
            setActivitiesList(contactList);
            setTotalPages(response.totalCount);
        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handlePageChange = (event, pageNumber) => {
    ListContactActivities(event , event);
    setFirst(event.first);
    setRows(event.rows);
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      const baseUrl = SearchProperty;
      const data = {
        Search: value,
        RecordsPerPage: 10,
        PageNo: 1,
      };

      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;

            // Transform data to match Select options
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name,
            }));

            // Update options and fetching state
            if (fetchId === fetchRef.current) {
              setOptions(transformedData);
              setFetching(false);
            }
          } else {
            setFetching(false);
          }
        })
        .catch((error) => {
          setFetching(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);

  const handleSearchProperty = (value) => {
    debounceFetcher(value);
    setSearchValues(value.value);
  };

  const debounceFetcher1 = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef1.current += 1;
      const fetchId = fetchRef1.current;
      setOptions1([]);
      const baseUrl = searchAreaOfOperation;
      const data = {
        Search: value,
        RecordsPerPage: 10,
        PageNo: 1,
      };

      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;

            // Transform data to match Select options
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name,
            }));

            // Update options and fetching state
            if (fetchId === fetchRef1.current) {
              setOptions1(transformedData);
            }
          }
        })
        .catch((error) => {
          //setFetching1(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);

  const handlePostCode = (value) => {
    const numericPart = value.label.split(',').pop().trim();
    setSearchValues1(value.value);  // Set search value ID
  };

  const handleProtalEnquiryList = () => {
    setActivitiesList([]);
    ListContactActivities();
  };

  const handleInput = (e, label) => {
    if (label === "Activities") {
      setActivities(e.value);
    } else if (label === "propertyType") {
      setPropertyTypeVal(e.value);
    } else if (label === "leadType") {
      setLeadType(e.value)
    }
  };

  const handleChangeText = (e, label) => {
    setBudgetVal(e.target.value)
  }

  const handleChangeText1 = (e, label) => {
    if(label === "Contact"){
      setContactVal(e.target.value)
    }else if (label === "Comments"){
      setComments(e.target.value)
    }else if (label === "Postcode"){
      setPostcode(e.target.value)
    }
  }

  const handleClearProperty = () => {
    setSearchValues("");
  };

  const handleClearPostCode = () => {
    setSearchValues1("");
  };

  useEffect(() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    setDate(yesterday.toISOString().split("T")[0]); // Set the default value to yesterday's date
  }, []);


  const labelMapping1 = {
    "First Name": "firstName",
    "Last Name": "lastName",
    Number: "phoneNumber",
    Property: "property",
    Type: "getListingType",
    Budget: "budget",
    "Activity Date": "createdAt",
    Activity: "activity",
    "Post Code": "postcode",
  };

  const handleExportToExcel = () => {
    exportToExcel(selectedRows, "exportata.xlsx", labelMapping1);
  };

  const tabColumns = {
    LeadType: {
      colorMap: {
        "Hot": "gray",
        "Warm": "orange",
        "Cold" : "blue"
      },
      defaultColor: 'blue'
    }
  };

  // case "Hot":
  //   return "lead-type-hot";
  // case "Warm":
  //   return "lead-type-warm";
  // case "Cold":
  //   return "lead-type-cold";

  


  return (
    <div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
          <SubNav title={"List Contact Activities"} goback={false} extraData={true} />
          <div className="material-work-desk-new bg-white m-3">
            <div style={{ margin: '10px' }}>
              <Input className="mr-2 " style={{ width: 200 }} placeholder="Contact" value={contactVal} onChange={(e) => handleChangeText1(e,"Contact")} />
              <Select style={{ width: 200 }} className="landingPageSearchs mr-2" mode="single" showSearch placeholder="Search for Property" labelInValue filterOption={false} onSearch={debounceFetcher} notFoundContent={fetching ? <Spin size="small" /> : null} options={options} onChange={handleSearchProperty} allowClear onClear={handleClearProperty} />
              <Select style={{ width: 200 }} className="landingPageSearchs mr-2" mode="single" showSearch placeholder="Search for Subrub" labelInValue filterOption={false} onSearch={(value) => debounceFetcher1(value)}  notFoundContent={fetching ? <Spin size="small" /> : null} options={options1} onChange={handlePostCode} allowClear onClear={handleClearPostCode}/>   
              <Input className="mr-2 " type="number" style={{ width: 200 }} placeholder="Postcode" value={postcode} onChange={(e) => handleChangeText1(e,"Postcode")} />           
              <Input className="mr-2" style={{ width: 200 }} placeholder="Budget" value={budgetVal} onChange={(e) => handleChangeText(e)} />
              <Select className="mr-2" style={{ width: 200 }} placeholder="Activities" options={contactActivitiesArr} value={propertyActivities || undefined} onChange={(value) => handleInput({ value }, "Activities")} />
              <Select className="mr-2" style={{ width: 200 }} placeholder="Lead Type" options={leadTypeArr} value={leadType || undefined} onChange={(value) => handleInput({ value }, "leadType")} />
              <Select className="mr-2" style={{ width: 200 }} placeholder="Property Type" options={propertyTypeArr} value={propertyTypeVal || undefined} onChange={(value) => handleInput({ value }, "propertyType")} />
              <Input className="mr-2 " style={{ width: 200 }} placeholder="Comments" value={comments} onChange={(e) => handleChangeText1(e,"Comments")} />

              <button className="Add-Property-button mt-4 ml-3" type="button" onClick={handleProtalEnquiryList}>
                <div className="text-parent">
                  <div className="text7" style={{ fontSize: "14px" }}>
                    Search
                  </div>
                </div>
              </button>
            </div>
            <AntdTableExample
              dataSource={activitiesList}
              globalSearch
              hideCol={"AgencyId,guid"}
              onPageChange={handlePageChange}
              totalRows={rows}
              totalRecords={totalPages}
              tagColumns={tabColumns}
              displayDoc
              showCheckbox
              />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactActivitiesList;
