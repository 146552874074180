import React from "react";
import { useEffect, useState } from "react";
import "../PropertiesAgency/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../input-components/selectBoxNew";
import {addupdatecontact,ContactPersonalApi} from "../../../components/CommonSection/apiresources";
import {GetCookie} from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { ContactDetailsSchema } from "../../../components/CommonSection/Schema/schema";
import AddressInput from "../../input-components/address";
import { BiEnvelope, BiPhone } from "react-icons/bi";
import { Checkbox } from "antd";
import { addParametersForProfiles } from "../../../Utilities/profileManagement";

const initialValues = {
  contactType: null,
  title: null,
  firstName: "",
  lastName: "",
  mobileNumber: "",
  email: "",
  address: "",
  typeIAM: null,
  viaPhone: false,
  viaMail: false,
  viaNotice: false,
};

const ContactDetails = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  closeModal
}) => {
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [typeData, setTypeData] = useState([
    { value: 1, label: "Individual" },
    { value: 2, label: "Company" },
  ]);
  const [titleData, setTitleData] = useState([
    { value: 1, label: "Mr." },
    { value: 2, label: "Miss." },
    { value: 3, label: "Mrs." },
  ]);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
        } else {
          navigate("/signin");
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (!getDetails) {
      // Reset form fields if getDetails is null
      resetForm();
  }
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setFieldValue("viaPhone", getDetails.isPhone);
      setFieldValue("viaMail", getDetails.isEmail);
      setFieldValue("viaNotice", getDetails.isNotice);
    }
  }, [getDetails]);
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm
  } = useFormik({
    initialValues: initialValues,
    validationSchema: ContactDetailsSchema,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = () => {
    const commaSeparatedString =
      values.typeIAM && Array.isArray(values.typeIAM)
        ? values.typeIAM.map((obj) => obj.value).join(",")
        : values.typeIAM;
    let formData = {
      ContactType: values.contactType,
      Title: values.title,
      FirstName: values.firstName,
      LastName: values.lastName,
      MobileNumber: values.mobileNumber,
      Email: values.email,
      Address: values.address,
      TypeIAM: commaSeparatedString,
      ContactUniqueId: getDetails != null ? getDetails.contactUniqueId : null,
      isPhone: values.viaPhone,
      isEmail: values.viaMail,
      isNotice: values.viaNotice,
      AgencyID: agencyID,
    };
    const url =
      getDetails != null ? ContactPersonalApi : addupdatecontact;
    formData = addParametersForProfiles(formData);
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          onDataRecive(returnData.sUID);
          if (getDetails == null) {
            var currentURL = window.location.href;
            var newValue = returnData.sUID;
            var updatedURL;
            if (newValue != null) {
              if (
                currentURL.includes("?") ||
                currentURL.includes("contactUID")
              ) {
                var split_string = currentURL.split("=");
                updatedURL = split_string[0] + "=" + newValue;
              } else {
                updatedURL = currentURL + "?contactUID=" + newValue;
              }
            }
            history.pushState(null, null, updatedURL);
          }
          //handleCurrentPage(2);
        } else {
          //handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        //handleparentsuccess(["error", error.message]);
      });
  };

  const options = [
    { value: 1, label: "Seller" },
    { value: 2, label: "Buyer" },
    { value: 3, label: "Investor" },
    { value: 4, label: "Tenant" },
    { value: 5, label: "Landlord" },
    { value: 6, label: "Developer" },
    { value: 7, label: "Supplier" },
  ];

  const handleSelectChange = (selectedValues) => {
    setFieldValue("typeIAM", selectedValues);
  };

  const onChange = (e, name) => {
    if (name === "viaPhone") {
      setFieldValue("viaPhone", e.target.checked);
    } else if (name === "viaMail") {
      setFieldValue("viaMail", e.target.checked);
    } else {
      setFieldValue("viaNotice", e.target.checked);
    }
  };

  const commaSeparatedString =
    values.typeIAM && typeof values.typeIAM === "string"
      ? values.typeIAM
        .split(",")
        .map((value) => {
          const option = options.find((opt) => opt.value === parseInt(value));
          return option ? option.label : null;
        })
        .filter((label) => label !== null)
        .join(", ")
      : null;

      const handlecancel = () => {
        closeModal();
      };

  return (
    <div>
          <form onSubmit={handleSubmit} >
            <div className="p-3">
              <div className="row pddpn-group">
              <SelectBox
                  label="Contact Type"
                  defaultValue={""}
                  name="contactType"
                  options={typeData}
                  placeholder={"Select Type"}
                  value={values.contactType}
                  onChange={(val) => setFieldValue("contactType", val.value)}
                  error={errors.contactType}
                  touched={touched.contactType}
                />
                <SelectBox
                  label="Title"
                  defaultValue={""}
                  name="title"
                  options={titleData}
                  placeholder={"Select Title"}
                  value={values.title}
                  onChange={(val) => setFieldValue("title", val.value)}
                  error={errors.title}
                  touched={touched.title}
                />
                <SelectBox
                  label="Type (I am)"
                  name="typeIAM"
                  options={options}
                  placeholder={
                    values.typeIAM ? values.typeIAM.label : "Select Type (I am)"
                  }
                  value={values.typeIAM}
                  onChange={handleSelectChange}
                  error={errors.typeIAM}
                  touched={touched.typeIAM}
                  isMulti={true}
                />
                <TextBox
                  isMandatory
                  label="First Name"
                  placeholder="First Name"
                  id="Unit"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.firstName}
                  touched={touched.firstName}
                />
                <TextBox
                  label="Last Name"
                  placeholder="Last Name"
                  id="lastName"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.lastName}
                  touched={touched.lastName}
                />
                <TextBox
                  label="Phone"
                  placeholder="Phone"
                  name="mobileNumber"
                  value={values.mobileNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.mobileNumber}
                  touched={touched.mobileNumber}
                  icon={<BiPhone height="100%" width="100%" />}
                />
                <TextBox
                  label="Email Address"
                  placeholder="Email Address"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email}
                  touched={touched.email}
                  icon={<BiEnvelope height="100%" width="100%" />}
                />
                <AddressInput
                  labelName="Address"
                  address={values.address}
                  setAddress={(address) => setFieldValue("address", address)}
                  handlePressSelect={(address) => setFieldValue("address", address)}
                  error={errors.address}
                  touched={touched.address}
                />
                <div className={`lda-${name} col-lg-5 col-sm-12`}>
                  <label className="s1">Communication Mode</label>
                  <div className="checkbox-section">
                    <Checkbox
                      checked={values.viaPhone}
                      onChange={(e) => onChange(e, "viaPhone")}
                    >
                      Via Phone
                    </Checkbox>
                    <Checkbox
                      checked={values.viaMail}
                      onChange={(e) => onChange(e, "viaMail")}
                    >
                      Via Mail
                    </Checkbox>
                    <Checkbox
                      checked={values.viaNotice}
                      onChange={(e) => onChange(e, "viaNotice")}
                    >
                      Via Notice
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>

            <div className="property-details-buttons">
              <button
                className="pdb-cancel"
                type="reset"
                onClick={()=> handlecancel()}>
                Cancel
              </button>
              <button className="pdb-save-changes" type="submit">
                Save
              </button>
            </div>
          </form>
    </div>
  );
};

export default ContactDetails;
