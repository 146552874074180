import React from "react";
import { useEffect, useState, useRef } from "react";
import "./propertiesagency.css";
import { useFormik } from "formik";
import {
  propertyPhotos,
  FilePath,
} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import TextArea from "../../input-components/textarea";
import {
  makeRequestWithSpinner,
  uploadToS3WithSpinner,
} from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus, ePropertyPhototype } from "../../../Utilities/enum";
import DocumentUpload from "../../input-components/documentUpload";
import "react-quill/dist/quill.snow.css";
import { photosValidationSchema } from "../../../components/CommonSection/Schema/schema";
import { GeneratePropertyHeadingWithAI } from "../../../Utilities/openai/ai-helper";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";

const initialValues = {
  propertyHeading: "",
  propertyDescription: "",
  propertyBuildingTypeZoning: null,
  PropertyLandAreaL: "",
  PropertyLandAreaW: null,
  PropertyImages: [],
  FloorImages: [],
};

const PropertyPhoto = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage1,
  getDetails,
  cancel,
}) => {
  let selectedFile;
  const [value1, setValue1] = useState(null);
  const [userId, setUserId] = useState("");
  const [propertyId, setPropertyId] = useState(0);
  const [propertyImages, setPropertyImages] = useState([]);
  const [propertyFloorImages, setPropertyFloorImages] = useState([]);
  const [propertyimageitems, setPropertyimageitems] = useState([]);
  const [propertyflooritems, setPropertyflooritems] = useState([]);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([
    { Id: 1, label: "A1", value: 1 },
    { Id: 2, label: "A2", value: 2 },
    { Id: 3, label: "A3", value: 3 },
  ]);

  const { showSpinner, hideSpinner } = useSpinnerContext();
  var url = new URL(window.location.href);
  var propertyUID = url.searchParams.get("propertyUID");
  const inputRef = useRef();
  const inputRef1 = useRef();
  const ToastRef = useRef();

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (getDetails) {
      debugger
      const getFilteredData = (selectedValue) => {
        return data.find((item) => item.value === selectedValue);
      };
      const selectedValue = getDetails.propertyBuildingTypeZoning
        ? +getDetails.propertyBuildingTypeZoning
        : "";
      const filteredData = getFilteredData(selectedValue);
      setFieldValue("propertyBuildingTypeZoning", filteredData);
      setValue1(filteredData ? filteredData.value : "");
      setPropertyId(getDetails.propertyId);
      setFieldValue("propertyHeading", getDetails.propertyHeading);
      setFieldValue("propertyDescription", getDetails.propertyDescription);
      if (getDetails.propertyHeading === null) {
        setEdit(true);
      }
    }
    const transformedArray =
      getDetails && getDetails.propertyPics
        ? getDetails.propertyPics.map((item) => ({
            src: FilePath + item,
            width: 1,
            height: 1,
          }))
        : [];
    setPropertyimageitems(transformedArray);
    setPropertyImages(getDetails ? getDetails.propertyPics : []);
    const transformedFloorArray =
      getDetails && getDetails.floorPics
        ? getDetails.floorPics.map((item) => ({
            src: FilePath + item,
            width: 1,
            height: 1,
          }))
        : [];
    setPropertyflooritems(transformedFloorArray);
    setPropertyFloorImages(getDetails ? getDetails.floorPics : []);
  }, [getDetails]);
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: photosValidationSchema,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = () => {
    const formData = {
      PropertyId: propertyId ? propertyId : 0,
      PropertyHeading: values.propertyHeading,
      PropertyDescription: values.propertyDescription,
      PropertyBuildingTypeZoning: value1 ? value1 : "",
      FloorImages: propertyFloorImages,
      UserId: userId,
      PropertyImages: propertyImages,
    };
    const url = propertyPhotos;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          handleCurrentPage1("legal");
          // handleparentsuccess(["success", returnData.message]);
        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]);
      });
  };

  const select = (event) => {
    inputRef.current.click();
  };

  const select1 = (event) => {
    inputRef1.current.click();
  };

  const SUPPORTED_IMAGE_TYPES = ["jpeg", "png", "svg", "jpg"];

  const isValidFileType = (file) => {
    // Extract the file extension and convert it to lowercase
    const extension = file.name.split(".").pop().toLowerCase();

    // Check if the extension is in the list of supported types
    return SUPPORTED_IMAGE_TYPES.includes(extension);
  };

  const handleUplodeImg = async (e, indexforfiles) => {
    debugger;
    for (let i = 0; i < e.target.files.length; i++) {
      selectedFile = e.target.files[i];

      if (!isValidFileType(selectedFile)) {
        ToastRef.current.notify([
          "error",
          `Unsupported file type: ${selectedFile.name}. Please upload a valid image.`,
        ]);
        continue;
      }

      await handleUpload();
    }
  };

  const handleUpload = async () => {
    await uploadToS3WithSpinner(
      showSpinner,
      hideSpinner,
      "property/",
      `property_${getDetails.propertyId}`,
      selectedFile
    ).then((returnData) => {
      if (returnData.status == ActionStatus.Successfull) {
        const fileName1 = returnData.Key.split("/").pop();
        const transformedItem = {
          id: Math.floor(Math.random() * 1000000),
          src: FilePath + returnData.Key,
          width: 1,
          height: 1,
          fileName: fileName1,
        };
        setPropertyimageitems((prevItems) => [...prevItems, transformedItem]);
        setFieldValue("PropertyImages", [
          ...values.PropertyImages,
          returnData.Key,
        ]);
        setPropertyImages((prevDocuments) => [
          ...prevDocuments,
          returnData.Key,
        ]);
        //handleparentsuccess(["success", "File uploaded successfully", true]);
      } else {
        // handleparentsuccess([
        //   "error",
        //   "Error uploading file. Please try again.",
        // ]);
      }
    });
  };

  const handleFloorImg = async (e, indexforfiles) => {
    for (let i = 0; i < e.target.files.length; i++) {
      selectedFile = e.target.files[i];
      await handleUploadFloorImg();
    }
  };

  const handleUploadFloorImg = async () => {
    await uploadToS3WithSpinner(
      showSpinner,
      hideSpinner,
      "property/",
      `propertyF_${getDetails.propertyId}`,
      selectedFile
    ).then((returnData) => {
      if (returnData.status == ActionStatus.Successfull) {
        const fileName1 = returnData.Key.split("/").pop();
        const transformedItem = {
          id: Math.floor(Math.random() * 1000000),
          src: FilePath + returnData.Key,
          width: 1,
          height: 1,
          fileName: fileName1,
        };
        setPropertyflooritems((prevItems) => [...prevItems, transformedItem]);
        setFieldValue("FloorImages", [...values.FloorImages, returnData.Key]);
        setPropertyFloorImages((prevDocuments) => [
          ...prevDocuments,
          returnData.Key,
        ]);
        //handleparentsuccess(["success", "File uploaded successfully", true]);
      } else {
        // handleparentsuccess([
        //   "error",
        //   "Error uploading file. Please try again.",
        // ]);
      }
    });
  };

  const basePath = (() => {
    switch (import.meta.env.MODE) {
        case "staging":
            return ".com/staging/";
        case "dev":
            return ".com/dev/";
        case "production":
            return ".com/production/";
        default:
            return ""; // Fallback if MODE doesn't match any case
    }
})();

  const handleImagesSorted = (sortedImages) => {
    const relativePaths = sortedImages.map((image) => {
      const url = image.src;
      const parts = url.split(basePath);
      return parts[1];
    });
    setPropertyImages(relativePaths);
  };

  const handleImagesSortedFloor = (sortedImages) => {
    const relativePaths = sortedImages.map((image) => {
      const url = image.src; // Get the `src` property of each image object
      const parts = url.split(".com/"); // Split the URL at '.com/'
      return parts[1]; // Get the relative part after '.com/'
    });
    setPropertyFloorImages(relativePaths);
  };

  const featuresList = [
    { key: "remoteGarage", label: "Remote Garage" },
    { key: "coutryard", label: "Coutryard" },
    { key: "fullyFenced", label: "Fully Fenced" },
    { key: "secureParking", label: "Secure Parking" },
    { key: "alarmSystem", label: "Alarm System" },
    { key: "intercom", label: "Intercom" },
    { key: "dishwasher", label: "Dishwasher" },
    { key: "floorboards", label: "Floorboards" },
    { key: "airConditioning", label: "Air Conditioning" },
    { key: "ductedHeating", label: "Ducted Heating" },
    { key: "ductedCooling", label: "Ducted Cooling" },
    { key: "waterTank", label: "Water Tank" },
  ];

  const handleButtonClick = async () => {
    // Construct the address string
    const address = `${getDetails.streetNumber} ${getDetails.streetName}, ${getDetails.suburb}, ${getDetails.postCode}.`;

    // Construct attributes
    const attributes = `bedroom ${getDetails.countBedrooms}, bathroom ${getDetails.countBathrooms}, garage: ${getDetails.countGarageSpace}`;

    // Collect features where the flag is true
    const selectedFeatures = featuresList
      .filter((feature) => getDetails[feature.key])
      .map((feature) => feature.label)
      .join(", ");

    // Construct the final string
    const finalString = `
      Address: ${address}
      Attributes: ${attributes}
      Features: ${selectedFeatures}
      Property Type: ${getDetails.sPropertyType || "N/A"}
    `;

    let imageUrls = (propertyimageitems || []).map((item) => item.src);
    const floorImageUrls = (propertyflooritems || []).map((item) => item.src);
    imageUrls = [...imageUrls, ...floorImageUrls];

    const responseData = await GeneratePropertyHeadingWithAI(
      showSpinner,
      hideSpinner,
      finalString,
      imageUrls
    );
    setFieldValue("propertyDescription", responseData.description);
    setFieldValue("propertyHeading", responseData.heading);
    // Update the state
  };

  return (
    <div>
      <ToastAlert ref={ToastRef} />

      <div className="handle-Editpart"></div>
      <div className="mt-2 mr-3" style={{ float: "right", cursor: "pointer" }}>
        <button className="ai-btn " onClick={handleButtonClick}>
          <img
            style={{ width: "20px" }}
            src="../../../assets/images/ai.png"
            alt="upload-icon"
          />{" "}
          Generate with AI
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="all-input">
          <div className="contacts-group pd-address">
            <TextBox
              colVal={12}
              placeholder="Heading"
              id="Unit"
              name="propertyHeading"
              value={values.propertyHeading}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.propertyHeading}
              touched={touched.propertyHeading}
            />
            <TextArea
              colVal={12}
              placeholder="Description"
              id="Unit"
              name="propertyDescription"
              value={values.propertyDescription}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.propertyDescription}
              touched={touched.propertyDescription}
            />
            <div className="pl-1 pr-4">
              <DocumentUpload
                label="Upload Images"
                inputRef={inputRef}
                onClickHandler={select}
                handleFileChange={handleUplodeImg}
                images={propertyimageitems}
                setImages={setPropertyimageitems}
                onImagesSorted={handleImagesSorted} // Pass the callback
              />
              <DocumentUpload
                label="Upload Floor Plans"
                inputRef={inputRef1}
                onClickHandler={select1}
                handleFileChange={handleFloorImg}
                images={propertyflooritems}
                setImages={setPropertyFloorImages}
                onImagesSorted={handleImagesSortedFloor} // Pass the callback
              />
            </div>
          </div>
        </div>
        <div className="property-details-buttons">
          <button className="pdb-cancel" type="reset" onClick={cancel}>
            Cancel
          </button>
          <button className="pdb-save-changes" type="submit">
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default PropertyPhoto;
