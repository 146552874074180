import React from "react";
import { useEffect, useState, useRef, useMemo } from "react";
import "./propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../input-components/selectBoxNew";
import {PropertyAddOwnerShipUrl,SearchContacts,ContactDetailsApi,generatesalesagreementPdfReport,generatePdfReport,generatetenantcheckreport,generateTenantAgreementPdfReport,ABNLookUpGuid,ABNLookUpbaseURL} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import {makeRequestWithSpinner,DownloadFiletWithSpinner} from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus, isManualFilePath } from "../../../Utilities/enum";
import AddressInput from "../../input-components/address";
import Collapse from "../../input-components/collapse";
import { MultiOwnershipSchema } from "../../../components/CommonSection/Schema/schema";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import {dynamicUrl} from "../../../Utilities/profileManagement";
import { Table } from "antd";
import ToggleTabs from "../../input-components/toggleButtonTabs";
import { Checkbox } from "primereact/checkbox"; // Checkbox component
import {DownloadOutlined,PlusOutlined,UploadOutlined,EyeOutlined} from "@ant-design/icons";
import CustomModal from "../../input-components/Modal";
import { FormatDateView } from "../../../components/CommonSection/Utility/Utility";
import ShowValue from "../../input-components/ShowValue";
import { BiEnvelope, BiPhone } from "react-icons/bi";
import { DeleteOutlined } from "@ant-design/icons";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { isNullOrEmpty } from "../../../components/CommonSection/Utility/ComponentFunction";

const initialValues = {
  owners: [
    {
      type: null,
      title: null,
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      ownerShipAddress: "",
      isPrimary: true,
      contactUniqueId: "",
      abn:"",
      companyName:""
    },
  ],
};

const PropertyOwnerShip = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  getDocDetails,
  cancel,
}) => {
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agentId, setAgentId] = useState("");
  const [typeData, setTypeData] = useState([
    { value: 1, label: "Individual" },
    { value: 2, label: "Company" },
  ]);
  const [titleData, setTitleData] = useState([
    { value: 1, label: "Mr." },
    { value: 2, label: "Miss." },
    { value: 3, label: "Mrs." },
  ]);

  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  var url = new URL(window.location.href);
  var propertyUId = url.searchParams.get("propertyUID");
  var subTab = url.searchParams.get("subTab");

  const [fetching, setFetching] = useState(false);
  const [option, setOption] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const [contactId, setContactId] = useState(0);
  const [tabValue, setTabValue] = useState("ownership");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [documentUrl, setDocumentUrl] = useState("");
  const ToastRef = useRef();

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgentId(parsedData.agentId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (getDetails) {
      if (getDetails?.contactlistModel?.length > 0) {
        const mappedValues = {
          owners: getDetails?.contactlistModel.map((item) => ({
            type: item.contactType || null,
            title: item.title || null,
            firstName: item.firstName || "",
            lastName: item.lastName || "",
            phone: item.phone || "",
            email: item.contactEmail || "",
            ownerShipAddress: item.address || "",
            isPrimary: item.isPrimary || false,
            contactUniqueId: item.contactUId || "",
            abn: item.companyName || "",
            companyName: item.companyName || ""
          })),
        };
        setValues(mappedValues);
      }
    }
  }, [getDetails]);

  useEffect(() => {
    if (subTab) {
      setTabValue("agreement");
    }
  }, []);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: MultiOwnershipSchema,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = () => {
    const contacts = values.owners.map((owner, index) => {
      const contact = {
        Address: owner.ownerShipAddress,
        FirstName: owner.firstName,
        LastName: owner.lastName,
        Email: owner.email,
        MobileNumber: owner.phone,
        ContactType: owner.type,
        Title: owner.title,
        ContactUniqueId: owner.contactUniqueId,
        IsPrimary: owner.isPrimary || false,
        ABN: owner.abn,
        CompanyName: owner.companyName
      };
      return contact;
    });

    const formData = {
      AgencyId: agencyID,
      AgentId: getDetails?.agentId !== null ? getDetails?.agentId : agentId,
      PropertyUniqueId: propertyUId ? propertyUId : 0,
      UserId: userId,
      Contacts: contacts,
    };

    const url = PropertyAddOwnerShipUrl;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          setTabValue("agreement");
        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]);
      });
  };

  const getTypeData = (selectedValue) => {
    return typeData.find((item) => item.value === selectedValue);
  };

  const handleCancel = () => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
    if (propertyUId) {
      handleCurrentPage("Overview");
    } else {
      dynamicUrl(navigate, "propertiesList");
    }
  };
  useEffect(() => {
    agentUIDRef.current = agentId;
  }, [agentId]);

  const agentUIDRef = useRef(agentId);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      setOption([]);
      setFetching(true);
      let data = {
        Search: value,
        RecordsPerPage: 10,
        PageNo: 1,
        AgentID: agentUIDRef.current,
      };

      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        SearchContacts,
        "post",
        data
      )
        .then((response) => {
          const transformedData = response.list.map((item) => ({
            value: item.id,
            label: item.name,
            uid: item.uid,
          }));
          setOption(transformedData);
          setFetching(false);
        })
        .catch((error) => setFetching(false));
    };

    return debounce(loadOptions, 800); // Debounce time of 800ms
  }, []);

  const handleSearchChange = (e, value, index) => {
    console.log(value, index,'value, index')
    if (value.length > 0) {
      const label = value.map((item) => item.label).join(",");
      const values = value.map((item) => item.value).join(",");
      const uid = value[0].uid;
      GetContactDetails(uid, index);
      setFieldValue("contactId", values);
      setFetching("propertyName", label);
      setContactId(values);
      debounceFetcher(value);
    }
  };

  const GetContactDetails = (contactUniqueId, ownerIndex) => {
    const baseUrl = ContactDetailsApi;
    const apiUrl = `${baseUrl}?contactUniqueId=${contactUniqueId}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get")
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          let data = response.object;
          setFieldValue(`owners[${ownerIndex}].firstName`, data.firstName);
          setFieldValue(`owners[${ownerIndex}].lastName`, data.lastName);
          setFieldValue(`owners[${ownerIndex}].phone`, data.mobileNumber);
          setFieldValue(`owners[${ownerIndex}].email`, data.email);
          setFieldValue(`owners[${ownerIndex}].type`, data.contactType);
          setFieldValue(`owners[${ownerIndex}].title`, data.title);
          setFieldValue(
            `owners[${ownerIndex}].contactUniqueId`,
            data.contactUniqueId
          );
        } else {
          handleparentsuccess(["warn", response.data.message]); // Show warning if response is not successful
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]); // Handle any errors during the request
      });
  };

  const tabs = [
    { label: "Ownership", value: "ownership" },
    { label: "Agreement", value: "agreement" },
    { label: "Fees", value: "fees" },
  ];

  const handleTabChange1 = (activeTab) => {
    setTabValue(activeTab);
  };

  const handleAddOwner1 = () => {
    const newOwner = {
      type: null,
      title: null,
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      ownerShipAddress: "",
      isPrimary: false,
      contactUniqueId: "",
      abn:"",
      companyName:""
    };

    setFieldValue("owners", [...values.owners, newOwner]);
  };

  const handleOwnerChange = (index, field, value) => {
    const ownersArray = values.owners || [];
    while (ownersArray.length <= index) {
      ownersArray.push({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        isPrimary: false,
        contactUniqueId: "",
        ownerShipAddress: "",
      });
    }

    if (field === "isPrimary") {
      if (!value) {
        // Prevent unchecking the only primary owner
        const primaryCount = ownersArray.filter((owner) => owner.isPrimary).length;
        if (primaryCount === 1) {
          ToastRef.current.notify(["error", "At least one owner must be set as primary."]);
          return;
        }
      }

      ownersArray.forEach((owner, i) => {
        owner.isPrimary = i === index ? value : false;
      });
    } else {
      ownersArray[index][field] = value; // Update specific field
    }
    setFieldValue("owners", ownersArray);
  };

  const recentSalesData = [
    { key: "1", title: "Management Agreement", createdAt: "1/15/12" },
    { key: "2", title: "Residential Tenancy Agreement", createdAt: "5/7/16" },
    {
      key: "3",
      title: "Exclusive Sales Agency Agreement",
      createdAt: "1/15/12",
    },
  ];

  const handleDownload = (record, preview, getDocDetails) => {
    const matchedDocument = getDocDetails.find(
      (doc) => doc.sAgreementType === record.title
    );
    if (record.title === "Management Agreement") {
      if (matchedDocument.isManuallyAdded) {
        const fileUrl = `${isManualFilePath}${matchedDocument.agreementFilePath}`;
        window.open(fileUrl, "_blank");
      } else {
        handleAgreementDownload(propertyUId, preview);
      }
    } else if (record.title === "Tenant check") {
      if (matchedDocument.isManuallyAdded) {
        const fileUrl = `${isManualFilePath}${matchedDocument.agreementFilePath}`;
        window.open(fileUrl, "_blank");
      } else {
        handleTenantDownload(propertyUId);
      }
    } else if (record.title === "Residential Tenancy Agreement") {
      if (matchedDocument.isManuallyAdded) {
        const fileUrl = `${isManualFilePath}${matchedDocument.agreementFilePath}`;
        window.open(fileUrl, "_blank");
      } else {
        handleTenantAgreementDownload(propertyUId, preview);
      }
    } else if (record.title === "Exclusive Sales Agency Agreement") {
      if (matchedDocument.isManuallyAdded) {
        const fileUrl = `${isManualFilePath}${matchedDocument.agreementFilePath}`;
        window.open(fileUrl, "_blank");
      } else {
        handleSaleAgreementDownload(propertyUId, preview);
      }
    }
  };

  const handleTenantAgreementDownload = (record, preview) => {
    const formData = {
      PropertyUId: record,
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generateTenantAgreementPdfReport,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData != null) {
          if (preview) {
            setDocumentUrl(returnData);
            setIsModalVisible(true);
          } else {
            window.open(returnData, "_blank");
          }
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleSaleAgreementDownload = (record, preview) => {
    const formData = {
      PropertyUId: record,
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generatesalesagreementPdfReport,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData != null) {
          if (preview) {
            setDocumentUrl(returnData);
            setIsModalVisible(true);
          } else {
            window.open(returnData, "_blank");
          }
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleAgreementDownload = (record, preview) => {
    const formData = {
      PropertyUId: record,
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generatePdfReport,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData != null) {
          if (preview) {
            setDocumentUrl(returnData);
            setIsModalVisible(true);
          } else {
            window.open(returnData, "_blank");
          }
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleTenantDownload = (records, preview) => {
    const formData = {
      AgencyId: getDetails.agencyId,
      UserId: records.userId,
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generatetenantcheckreport,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData != null) {
          if (preview) {
            setDocumentUrl(returnData);
            setIsModalVisible(true);
          } else {
            window.open(returnData, "_blank");
          }
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleUploadAgreement = (rowData) => {
    if (rowData.title === "Management Agreement") {
      dynamicUrl(
        navigate,
        "UploadAgreementManagement" +
          `?propertyUID=${propertyUId}&propertyId=${getDetails.propertyId}`
      );
    } else if (rowData.title === "Residential Tenancy Agreement") {
      dynamicUrl(
        navigate,
        "UploadAgreementTenant" +
          `?propertyUID=${propertyUId}&propertyId=${getDetails.propertyId}`
      );
    } else {
      dynamicUrl(
        navigate,
        "UploadAgreement" +
          `?propertyUID=${propertyUId}&propertyId=${getDetails.propertyId}`
      );
    }
  };

  const handleAgreement = (rowData) => {
    if (rowData.title === "Management Agreement") {
      dynamicUrl(
        navigate,
        "agreementtabs" +
          `?propertyUID=${propertyUId}&agencyId=${
            getDetails.agencyId
          }&properties=${true}`
      );
    }
    if (rowData.title === "Residential Tenancy Agreement") {
      dynamicUrl(
        navigate,
        "tenancyagreement" +
          `?propertyUID=${propertyUId}&agencyId=${getDetails.agencyId}&properties=true`
      );
    }
    if (rowData.title === "Exclusive Sales Agency Agreement") {
      dynamicUrl(
        navigate,
        "SaleAgreementTabs" +
          `?propertyUID=${propertyUId}&agencyId=${getDetails.agencyId}&properties=true`
      );
    }
  };

  const columns = [
    { title: "Title", dataIndex: "title", key: "title" },
    { title: "Created At", dataIndex: "createdAt", key: "createdAt" },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        const isMatched = getDocDetails?.some(
          (agreement) => agreement.sAgreementType === record.title
        );

        return (
          <div style={{ display: "flex" }}>
            {!isMatched ? (
              <>
                <PlusOutlined
                  title="Add Agreement"
                  style={{
                    fontSize: "20px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleAgreement(record)}
                />
                <UploadOutlined
                  title="Upload Agreement"
                  style={{
                    fontSize: "20px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleUploadAgreement(record)}
                />
              </>
            ) : (
              <>
                <DownloadOutlined
                  title="Download Agreement"
                  style={{
                    fontSize: "20px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDownload(record, "", getDocDetails)}
                />
                <EyeOutlined
                  title="Preview"
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={() =>
                    handleDownload(record, "preview", getDocDetails)
                  }
                />
              </>
            )}
          </div>
        );
      },
    },
  ];

  const handleNextStep = () => {
    handleCurrentPage("Listings");
  };

  const handleSubmitFees = () => {
    handleCurrentPage("Listings");

    dynamicUrl(
      navigate,
      "propertiesList" +
        `?propertyUID=${getDetails.propertyUniqueID}&tab=${"photos"}`
    );
  };

  const closePreview = () => {
    setIsModalVisible(false);
  };

  const handleAgreementNext = () => {
    setTabValue("fees");
  };

  const handleDeleteOwner = (index) => {
    const updatedOwners = values.owners.filter((_, i) => i !== index);
    setFieldValue("owners", updatedOwners);
  };
  
  const handleABNLoopup = (event,index) => {
      const abnNumber = event;
  
      handleBlur(event);
      if (!isNullOrEmpty(abnNumber)) {
        const url = `${ABNLookUpbaseURL}/AbnDetails.aspx?abn=${abnNumber}&guid=${ABNLookUpGuid}`;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "get")
          .then((returnData) => {
            const jsonString = returnData.match(/\{.*\}/)[0];
            const parsedData = JSON.parse(jsonString.slice(jsonString.indexOf("{")));
            if (!isNullOrEmpty(parsedData.Abn)) {
              const updatedOwners = [...values.owners];
              updatedOwners[index].companyName = parsedData.EntityName || "";
              setFieldValue("owners", updatedOwners);
            } else {
              setFieldError(`owners[${index}].abn`, "Invalid ABN number");
              ToastRef.current.notify(["error", "Invalid ABN"]);

            }
          })
          .catch((error) => {
            setFieldError(`owners[${index}].abn`, "Error fetching ABN details");
            ToastRef.current.notify(["error", error.message]);

          });
      }
    };
  

  return (
    <div>
      <div style={{ width: "100%" }} className="mt-2 mb-2">
      <ToastAlert ref={ToastRef} />

        <ToggleTabs
          tabs={tabs}
          onTabChange={handleTabChange1}
          externalTabValue={tabValue}
        />
      </div>
      {tabValue === "ownership" && (
        <div style={{ paddingTop: "10px" }}>
          <form onSubmit={handleSubmit}>
            <div>
              {values.owners.map((owner, index) => (
                <Collapse
                  id={index}
                  title={`Owner ${index + 1}`}
                  data={
                    <div className="row">
                      {/* Contact Selection */}
                      <div className="col-lg-6 col-sm-12">
                        <label className="s1">Contacts</label>
                        <div style={{ display: "flex" }}>
                          <Select
                            style={{ width: "100%", height: "40px" }}
                            placeholder="Search for contacts"
                            labelInValue
                            showSearch
                            filterOption={false}
                            onSearch={debounceFetcher}
                            notFoundContent={
                              fetching ? <Spin size="small" /> : null
                            }
                            options={option}
                            onChange={(label, value) =>
                              handleSearchChange(label, [value], index)
                            }
                            defaultValue={selectedValues}
                            allowClear
                          />
                        </div>
                      </div>
                      <SelectBox
                        label="Type"
                        placeholder="Select Type"
                        name={`owners[${index}].type`}
                        options={typeData}
                        value={
                          values.owners[index]
                            ? values.owners[index].type
                            : null
                        }
                        onChange={(val) =>
                          handleOwnerChange(index, "type", val.value)
                        }
                      />

                      <SelectBox
                        label="Title"
                        placeholder="Select Title"
                        name={`owners[${index}].title`}
                        options={titleData}
                        value={
                          values.owners[index]
                            ? values.owners[index].title
                            : null
                        }
                        onChange={(val) =>
                          handleOwnerChange(index, "title", val.value)
                        }
                      />

                      <TextBox
                        label="First Name"
                        placeholder="First Name"
                        name={`owners[${index}].firstName`} // Name must match the Formik field path
                        value={
                          values.owners[index]
                            ? values.owners[index].firstName
                            : ""
                        }
                        onChange={(e) =>
                          handleOwnerChange(index, "firstName", e.target.value)
                        }
                        error={errors.owners && errors.owners[index]?.firstName}
                        touched={
                          touched.owners && touched.owners[index]?.firstName
                        }
                      />

                      <TextBox
                        label="Last Name"
                        placeholder="Last Name"
                        name={`owners[${index}].lastName`}
                        value={
                          values.owners[index]
                            ? values.owners[index].lastName
                            : ""
                        }
                        onChange={(e) =>
                          handleOwnerChange(index, "lastName", e.target.value)
                        }
                        error={errors.owners && errors.owners[index]?.lastName}
                        touched={
                          touched.owners && touched.owners[index]?.lastName
                        }
                      />

                      {/* Phone */}
                      <TextBox
                        label="Phone"
                        placeholder="Phone"
                        name={`owners[${index}].phone`}
                        value={
                          values.owners[index] ? values.owners[index].phone : ""
                        }
                        onChange={(e) =>
                          handleOwnerChange(index, "phone", e.target.value)
                        }
                        error={errors.owners && errors.owners[index]?.phone}
                        touched={touched.owners && touched.owners[index]?.phone}
                        icon={<BiPhone height="100%" width="100%" />}
                      />

                      {/* Email */}
                      <TextBox
                        label="Email"
                        placeholder="Email"
                        name={`owners[${index}].email`}
                        value={
                          values.owners[index] ? values.owners[index].email : ""
                        }
                        onChange={(e) =>
                          handleOwnerChange(index, "email", e.target.value)
                        }
                        error={errors.owners && errors.owners[index]?.email}
                        touched={touched.owners && touched.owners[index]?.email}
                        icon={<BiEnvelope height="100%" width="100%" />}
                      />

                      {/* Address Input */}
                      <AddressInput
                        labelName="Address"
                        address={
                          values.owners[index]
                            ? values.owners[index].ownerShipAddress
                            : ""
                        }
                        setAddress={(value) =>
                          handleOwnerChange(index, "ownerShipAddress", value)
                        }
                      />
                      <TextBox
                          label="ABN"
                          placeholder="ABN Number"
                          name={`owners[${index}].abn`}
                          value={values.owners[index]?.abn || ""}
                          onChange={(e) => handleOwnerChange(index, "abn", e.target.value)}
                          onBlur={(e) => handleABNLoopup(e.target.value, index)}
                          error={errors.owners && errors.owners[index]?.abn}
                          touched={touched.owners && touched.owners[index]?.abn}
                        />
                        <TextBox
                          label="Company Name"
                          placeholder="Company Name"
                          name={`owners[${index}].companyName`}
                          value={values.owners[index]?.companyName || ""}
                          readOnly={true}
                          error={errors.owners && errors.owners[index]?.companyName}
                          touched={touched.owners && touched.owners[index]?.companyName}
                        />

                      <div className="mt-3 col-lg-4 mb-3 d-flex align-items-center">
                        <Checkbox
                          checked={
                            values.owners[index]
                              ? values.owners[index].isPrimary
                              : false
                          }
                          onChange={(e) =>
                            handleOwnerChange(
                              index,
                              "isPrimary",
                              e.target.checked
                            )
                          }
                        />
                        
                        <label
                          className="ml-2"
                          htmlFor={`primary-owner-${index}`}
                        >
                          Set as primary
                        </label>
                      </div>
                      <div className="col-lg-12 mt-2 text-right">
                        {index !== 0 && ( // Ensure the delete button is not displayed for the first owner
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "white", border: "1px solid gray", color: "gray" }}
                            onClick={() => handleDeleteOwner(index)}
                          >
                            <DeleteOutlined
                              style={{
                                fontSize: "16px",
                                cursor: "pointer",
                                marginRight: "5px",
                              }}
                              title="Delete Owner"
                            />
                            Delete Owner
                          </button>
                        )}
                      </div>

                    </div>
                  }
                />
              ))}
            </div>

            <div className="add-owner-btn1 mt-3" onClick={handleAddOwner1}>
              <PlusOutlined className="plus-icon1" />
              <span className="span1">Add Another Owner</span>
            </div>

            <div className="property-details-buttons">
              <button className="pdb-cancel" type="reset" onClick={cancel}>
                Cancel
              </button>
              <button className="pdb-save-changes" onClick={handleSubmit}>
                Next
              </button>
            </div>
          </form>
        </div>
      )}
      {tabValue === "agreement" ? (
        <div>
          <Table
            dataSource={recentSalesData}
            columns={columns}
            pagination={false}
          />

          <div className="property-details-buttons">
            <button
              className="pdb-save-changes"
              type="button"
              onClick={handleAgreementNext}
            >
              Next
            </button>
          </div>
          <CustomModal
            title="Preview"
            width={600}
            closable={closePreview}
            visible={isModalVisible}
            onCancel={closePreview}
            footer={null}
            modalData={
              <div
                className="row"
                style={{ marginTop: "30px", marginLeft: "10px" }}
              >
                {documentUrl && (
                  <iframe
                    src={documentUrl}
                    width="100%"
                    height="600px"
                    title="Document Viewer"
                  />
                )}
              </div>
            }
          />
        </div>
      ) : null}
      {tabValue === "fees" ? (
        <div>
          <div className="property-details-content df-all all-input">
            <div className="details-form" style={{ width: "100%" }}>
              <div className="contacts-group">
                {getDetails.managementAgreement ? (
                  <div>
                    <div className="Heading">
                      <strong>Management Agreement</strong>
                    </div>
                    <ShowValue
                      colval={3}
                      Label="Agreement Start Date"
                      setData={FormatDateView(
                        getDetails.managementAgreement?.agreementDate
                      )}
                    />
                    <ShowValue
                      colval={3}
                      Label="Management Fee"
                      setData={
                        "$" + getDetails.managementAgreement?.managementFee
                      }
                    />
                    <ShowValue
                      colval={3}
                      Label="Marketing Fee"
                      setData={
                        "$" + getDetails.managementAgreement?.marketingFee
                      }
                    />
                    <ShowValue
                      colval={3}
                      Label="Letting Fee"
                      setData={
                        "$" + getDetails.managementAgreement?.initialWeekRent
                      }
                    />
                    <ShowValue
                      colval={3}
                      Label="NCAT Attendence Fee"
                      setData={
                        "$" +
                        getDetails.managementAgreement
                          ?.applicationFeesForUtilites
                      }
                    />
                    <ShowValue
                      colval={3}
                      Label="Admin Fee"
                      setData={
                        "$" + getDetails.managementAgreement?.administrationFee
                      }
                    />
                  </div>
                ) : null}

                {getDetails.tenantAgreement ? (
                  <div>
                    <div className="Heading">
                      <strong>Tenancy Agreement</strong>
                    </div>
                    <ShowValue
                      colval={3}
                      Label="Lease Start Date"
                      setData={FormatDateView(
                        getDetails.tenantAgreement?.agreementStart
                      )}
                    />
                    <ShowValue
                      colval={3}
                      Label="Lease End Date"
                      setData={FormatDateView(
                        getDetails.tenantAgreement?.agreementEnd
                      )}
                    />
                    <ShowValue
                      colval={3}
                      Label="Rent"
                      setData={"$" + getDetails.tenantAgreement?.rentAmount}
                    />
                    <ShowValue
                      colval={3}
                      Label="Lease Term"
                      setData={
                        getDetails.tenantAgreement?.termsAgreement === 1
                          ? "Weekly"
                          : "Monthly"
                      }
                    />
                  </div>
                ) : null}
                {getDetails.saleAgreement ? (
                  <div>
                    <div className="Heading">
                      <strong>Sales Agreement</strong>
                    </div>
                    <ShowValue
                      colval={3}
                      Label="Start Date"
                      setData={FormatDateView(
                        getDetails.saleAgreement?.agreementStartDate
                      )}
                    />
                    <ShowValue
                      colval={3}
                      Label="End Date"
                      setData={FormatDateView(
                        getDetails.saleAgreement?.agreementEndDate
                      )}
                    />
                    <ShowValue
                      colval={3}
                      Label="Marketing Fee"
                      setData={
                        "$" +
                        getDetails.saleAgreement?.expenseANDChargesEstimate1
                      }
                    />
                    <ShowValue
                      colval={3}
                      Label="Price"
                      setData={
                        "$" + getDetails.saleAgreement?.sellingFixedPrice
                      }
                    />
                    <ShowValue
                      colval={3}
                      Label="commission %"
                      setData={"$" + getDetails.saleAgreement?.salePriceFlatFee}
                    />
                    <ShowValue
                      colval={3}
                      Label="Commission Amount"
                      setData={
                        "$" + getDetails.saleAgreement?.gstInclusiveRenumeration
                      }
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {!getDetails.saleAgreement &&
          !getDetails.tenantAgreement &&
          !getDetails.managementAgreement ? (
            <div className="no-message">No Agreement Found!</div>
          ) : null}
          <div className="property-details-buttons">
            <button className="pdb-cancel" type="reset" onClick={handleCancel}>
              Cancel
            </button>
            <button
              className="pdb-save-changes"
              type="button"
              onClick={handleSubmitFees}
            >
              Next
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PropertyOwnerShip;
