import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ListAllProperties ,deletepropertylisting} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import "../../../components/Dashboard/Dashboard-agent/AgentList.css";
import { ActionStatus } from "../../../Utilities/enum";
import {addParametersForProfiles,dynamicUrl} from "../../../Utilities/profileManagement";
import SubNav from "../../navbar/subNav";
import AntdTableExample from "../../input-components/dataTable";
import CustomModal from "../../input-components/Modal";
import AddNewProperty from "./addNewProperty";
import { Radio } from "antd";
import AddNewPropertyListing from "./addNewPropertyListing";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { useSidebarContext } from "../../../Utilities/sidebarcontext";
import { useReadNotificationContext } from '../../../Utilities/readNotificationContext';

const PropertyListings = () => {
  const [propertyLists, setPropertyLists] = useState([]);
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const navigate = useNavigate();
  const [propertyType, setPropertyType] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [purpose, setPurpose] = useState("");
  const [tabIdVal, setTabIdVal] = useState(null);
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [openAddListings, setOpenAddListings] = useState(false);
  const [agentId, setAgentId] = useState("");
  const [chartData, setChartData] = useState();
  const [listingOperation, setListingOperation] = useState(1);
  const { sideBarData, getLoginDataforSideBar } = useSidebarContext(); 
  const { readNotificationApi } = useReadNotificationContext();

  const ToastRef = useRef();
  
  const sidebarReferenceIds = new Set(
    sideBarData.flatMap(section => 
      section.items.flatMap(item => item.referenceIds)
    )
  );
  
  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setAgentId(parsedData.agentId);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);


  useEffect(() => {
    if (userId) getSupportList(1, 10,null,null);
  }, [userId, searchText]);

  const handleReadNotification = (referenceId) => {
    readNotificationApi(referenceId, getLoginDataforSideBar) // Pass the function as a parameter
      .then(() => {
        console.log("Notification successfully marked as read.");
      })
      .catch((error) => {
        console.error("Failed to mark notification as read:", error);
      });
  };

  const handleOperationChange = (e) => {
    setListingOperation(e.target.value);
  };

  const getSupportList = (currentPage, totalRows,propertyType,tabIdVal) => {
    const baseUrl = ListAllProperties;
    let data = {
      AgencyId: agencyID,
      SortBy: "AddedOn",
      SortOrder: "DESC",
      Search: searchText,
      RecordsPerPage: totalRows,
      PageNo: currentPage,
      PropertyType: propertyType,
      tabid: tabIdVal,
    };
    data = addParametersForProfiles(data);
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          setChartData(response.object);
          const propertyData = response.object.propertyListingList || [];
          let propertyList = propertyData.map((property) => ({
            Address: property.address,
            PropertyType: property.propertySaleRental === 1 ? "Sale" : "Rent",
            agentName: property.listingAgentName,
            propertyPrice: property?.propertyPrice
            ? "$" +
              new Intl.NumberFormat("en-US").format(property.propertyPrice)
            : property?.rent
            ? "$" + new Intl.NumberFormat("en-US").format(property.rent)
            : "",
            ShowPrice: property.isShowPrice ? "Yes" : "No",
            guid: property.propertyUId,
            uniqueId: property.propertyId,
            IsReaUploaded: property.reaUploadId === null ? "No" : "Yes",
            enquiryCount: property.enquiryCount,
            Status: property.sListingStatus,
            listingId:property.listingId
          }));
          setTotalPages(response.totalCount);
          setPropertyLists(propertyList);
        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Handler for page change
  const handlePageChange = (pageNumber, totalRows) => {
    setCurrentPage(pageNumber);
    setRows(totalRows);
    setTotalPages(0);
    setPropertyLists([]);
    getSupportList(pageNumber, totalRows,propertyType, tabIdVal);
  };

  const handleAddListings = () => {
    setOpenAddListings(true);
  };

  const closeAddListings = () => {
    const baseUrl = window.location.pathname;
    navigate(baseUrl);
    setOpenAddListings(false);
  };

  const handleSearchValueChange = (value) => {
    setSearchText(value);
  };

  const tabColumns = {
    Status: {
      colorMap: {
        Active: "#28a745",       // A fresh, vibrant green
        Leased: "#f1c40f",       // A bold, golden yellow
        Sold: "#e74c3c",         // A striking, bright red
        "Off Market": "#95a5a6", // A neutral, cool gray
      },
      defaultColor: "#007bff",    // A clean, standard blue for default
    },
  };
  

  const handleStatClick = (purposeValue, propertyTypeValue, tabidValue) => {
    setPurpose(purposeValue);
    setPropertyType(propertyTypeValue);
    setTabIdVal(tabidValue);
    setPropertyLists([]);
    setCurrentPage(1);
    getSupportList(1, 10,propertyTypeValue,tabidValue)
  };

  const handleView = (e, data) => {
    handleReadNotification(data.listingId)
    dynamicUrl(navigate, "viewPropertyDetails" + `?propertyUID=${data.guid}&listingId=${data.listingId}`);
  };

  const toggleProperty = (e) => {
    setListingOperation(e);
  }

  const handleDelete = (e,data) => {
    e.stopPropagation()
    e.preventDefault()
    const baseUrl = deletepropertylisting;
    let formdata = {
      ListingId : data.listingId,
      AgencyId : agencyID,
    };
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", formdata)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
         getSupportList(1, 10,null,null);
         ToastRef.current.notify(["success", response.message]);

        } else {
          ToastRef.current.notify(["error", response.message]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const actions = [
    { icon: "/assets/images/trash.png",key:"View", onClick: (e, data) => handleDelete(e, data) },
]

  return (
    <div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
          <SubNav
            title={"Property Listings"}
            goback={false}
            extraData={true}
            extraContent={
              <div className="property-details-buttons">
                <button
                  onClick={handleAddListings}
                  className="subnav-btn"
                  type="submit"
                >
                  Add Listings
                </button>
              </div>
            }
          />
          <div className="material-work-desk-new bg-white m-3">
          <ToastAlert ref={ToastRef} />
            <div style={{ borderBottom: "1px solid #e9ecef", padding: "10px" }}>
              <div className="col-lg-8">
                <span
                  className={`mr-2 stat-item ${purpose === "" ? "active" : ""}`}
                  onClick={() => handleStatClick("", null, "")}
                >
                  All <span className="count">{chartData?.countAll}</span>
                </span>
                <span
                  className={`mr-2  stat-item ${purpose === 1 ? "active" : ""}`}
                  onClick={() => handleStatClick(1, 1, 1)}
                >
                  Sale Listing{" "}
                  <span className="count">{chartData?.countSaleListing}</span>
                </span>
                <span
                  className={`mr-2  stat-item ${purpose === 2 ? "active" : ""}`}
                  onClick={() => handleStatClick(2, 2, 1)}
                >
                  Rental Listing{" "}
                  <span className="count">{chartData?.countRentalListing}</span>
                </span>
                <span
                  className={`mr-2 stat-item ${purpose === 3 ? "active" : ""}`}
                  onClick={() => handleStatClick(3, 1, 4)}
                >
                  Sold <span className="count">{chartData?.countSold}</span>
                </span>
                <span
                  className={`mr-2 stat-item ${purpose === 4 ? "active" : ""}`}
                  onClick={() => handleStatClick(4, 2, 5)}
                >
                  Leased <span className="count">{chartData?.countLeased}</span>
                </span>
                <span
                  className={`mr-2 stat-item ${purpose === 5 ? "active" : ""}`}
                  onClick={() => handleStatClick(5, null, 3)}
                >
                  Off Market{" "}
                  <span className="count">{chartData?.countOffMarket}</span>
                </span>
              </div>
            </div>

            <AntdTableExample
              label="List All Properties"
              dataSource={propertyLists}
              globalSearch
              hideCol={"uniqueId,listingId"}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              totalRows={rows}
              totalRecords={totalPages}
              onSearchValueChange={handleSearchValueChange}
              tagColumns={tabColumns}
              onRowClick={handleView}
              actions={actions}
              rowClassName={(record) => {
                return sidebarReferenceIds.has(record.listingId.toString()) ? "highlighted-row" : "";
              }}
            />
          </div>
          <CustomModal
            footer={null}
            title={"Add Listings"}
            width={800}
            closable={closeAddListings}
            visible={openAddListings}
            onCancel={closeAddListings}
            modalData={
              <div>
                <Radio.Group
                  onChange={handleOperationChange}
                  value={listingOperation}
                >
                  <Radio value={1}>Search Property</Radio>
                  <Radio value={2}>Add Property</Radio>
                </Radio.Group>
                {listingOperation === 1 && (
                  <AddNewPropertyListing navigate={navigate} closeModal={closeAddListings}/>
                )}
                {listingOperation === 2 && (
                  <AddNewProperty closeModal={closeAddListings} toggleProperty={toggleProperty}/>
                )}
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PropertyListings;
