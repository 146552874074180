// FileUploadWithPreview.js
import React, { useRef, useState } from "react";
import { BiTrash } from "react-icons/bi";

const FileUploadWithPreview = ({
  label,
  icon,
  onChange,
  onBlur,
  error,
  touched,
  isMandatory = false,
  colVal = "",
  
}) => {
  const fileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState("");

  let colVals = colVal === 12 ? "col-lg-12 col-sm-12" : "col-lg-6 col-sm-12";

  const handleClickIcon = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const fileName = e.target.files[0]?.name || "";
    setSelectedFileName(fileName);
    if (onChange) {
      onChange(e);
    }
  };

  const handleDeleteClick = () => {
    setSelectedFileName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the input value
    }
  };

  return (
    <div className={`pd-${label} ${colVals}`}>
      <label htmlFor={label} className="s1">
        {label} {isMandatory && <span style={{ color: "red" }}>*</span>}
      </label>
      <div className="input-group file-container mb-3">
        {icon && (
          <div
            className="input-group-prepend"
            style={{ cursor: "pointer" }}
            onClick={handleClickIcon}
          >
            <span className="input-group-text" id="basic-addon1">
              Browse
            </span>
          </div>
        )}
        <input
          ref={fileInputRef}
          type="file"
          className="form-control tenant-date fileInput-Selector"
          onChange={handleFileChange}
          onBlur={onBlur}
          aria-describedby="basic-addon1"
          hidden
        />
        {selectedFileName && (
          <div
            className="file-info"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "80%",
            }}
          >
            <div className="selected-file">{selectedFileName}</div>
            <div className="delete-icon" onClick={handleDeleteClick}>
              <BiTrash />
            </div>
          </div>
        )}
      </div>
      {error && touched ? <p className="form-error">{error}</p> : null}
    </div>
  );
};

export default FileUploadWithPreview;
