import React from "react";
import { useEffect, useState, useRef } from "react";
import SubNav from "../../navbar/subNav";
import ChatScreen from "./ai-agreement";

const AiAssistant = () => {
  return (
    <div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
          <SubNav title={"AI-Assistant"} goback={false} extraData={true} />
          <div className="material-work-desk-new bg-white m-3">
            <ChatScreen />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiAssistant;
