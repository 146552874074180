import React, { useEffect, useState, useMemo, useRef } from "react";
import { Input, Select, Button, Tabs, Collapse, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { Dropdown } from "primereact/dropdown";
import { LandingPageSearch } from "../../CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useNavigate } from "react-router-dom";
import "./mainLandingPage.css";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import FooterNew from "../FooterNew/FooterNew";
import Topbar from '../../../src/navbar/navbar';

const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Option } = Select;

const RealEstateUI = () => {

  const [selectedBeds, setSelectedBeds] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [togglePrice, setTogglePrice] = useState("Price");
  const [searchValue, setSearchValue] = useState("");
  const fetchRef = useRef(0);
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();

  const bedsSize = [
    { value: "1", label: "1-4 Beds" },
    { value: "2", label: "5-8 Beds" },
    { value: "3", label: "9-12 Beds" },
    { value: "4", label: "13-16 Beds" },
    { value: "5", label: "17-20 Beds" },
  ];

  const price = [
    { value: "1", label: "$50,000 - $100,000" },
    { value: "2", label: "$100,000 - $250,000" },
    { value: "3", label: "$250,000 - $400,000" },
    { value: "4", label: "$400,000 - $550,000" },
    { value: "5", label: "$550,000 - $700,000" },
  ];

  const rent = [
    { value: "1", label: "$100 - $250 pw" },
    { value: "2", label: "$250 - $500 pw" },
    { value: "3", label: "$500 - $1000 pw" },
    { value: "4", label: "$1000 - $2000 pw" },
    { value: "5", label: "$2000 - $4000 pw" },
  ];

  useEffect(() => {
    fetchInitialOptions();
  }, []);

  const fetchInitialOptions = () => {
    const data = { Search: 8 };
    makeRequestWithSpinner(showSpinner, hideSpinner, LandingPageSearch, "post", data, null, false)
      .then((response) => {
        if (response.status === 1) {
          const transformedData = response.list.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setOptions(transformedData);
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      const data = { Search: value };
      makeRequestWithSpinner(showSpinner, hideSpinner, LandingPageSearch, "post", data, null, false)
        .then((response) => {
          if (response.status === 1 && fetchId === fetchRef.current) {
            const transformedData = response.list.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            setOptions(transformedData);
          }
        })
        .finally(() => setFetching(false));
    };
    return debounce(loadOptions, 800);
  }, []);

  const handleSearchChange = (value) => {
    const values = value.map((item) => item.value).join(",");
    setSearchValue(values);
    debounceFetcher(value);
  };

  const handleFilteredData = () => {
    const propertyType = togglePrice === "Price" ? 2 : 3;
    const params = new URLSearchParams();
    params.append("catId", propertyType);
    if (selectedBeds) params.append("Minbeds", selectedBeds.code);
    if (selectedPrice) params.append("MinPrice", selectedPrice.code);
    if (searchValue) params.append("Search", searchValue);
    navigate(`/listLandingPage?${params.toString()}`);
  };

  return (
    <div className="real-estate-container">
    <Topbar/>
      {/* Search Section */}
      <div className="p-4" style={{ backgroundColor: 'white' }}>
        <div className="search-section">
          <div className="search-container">
            <div style={{ width: '40%' }}>
              <Tabs
                defaultActiveKey="1"
                className="tabs"
                onChange={(key) => setTogglePrice(key === "1" ? "Price" : "Rent")}
              >
                <TabPane tab="Buy" key="1" />
                <TabPane tab="Lease" key="2" />
              </Tabs>

            </div>
            <div className="search-bar">
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search suburb or postcode"
                className="search-input"
              />
              <Select
                mode="multiple"
                placeholder="Search suburb or postcode"
                labelInValue
                filterOption={false}
                onSearch={debounceFetcher}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                options={options}
                className="multi-select"
                onChange={handleSearchChange}
              />
              <Select
                placeholder="Beds"
                options={bedsSize}
                onChange={(value) => setSelectedBeds(value)}
                className="select1"
              />
              <Select
                placeholder={togglePrice}
                options={togglePrice === "Price" ? price : rent}
                onChange={(value) => setSelectedPrice(value)}
                className="select1"
              />
              <Button type="primary" className="search-button" onClick={handleFilteredData}>
                Search
              </Button>
            </div>
          </div>
        </div>

      </div>

      <div className="stats-section">
        <div className="stats-item">
          <h3>1</h3>
          <p>Locations</p>
        </div>
        <div className="stats-item">
          <h3>4k+</h3>
          <p>Happy Customers</p>
        </div>
        <div className="stats-item">
          <h3>500+</h3>
          <p>Property Listings</p>
        </div>
      </div>

      <div className="overview-container">
        <div className="image-text-section">
          <div className="image-containers">
            <img
              src="/assets/images/home1.svg" // Replace with your actual image path
              alt="Property"
              className="property-image"
            />
          </div>
          <div className="text-container">
            <h2 className="section-heading">Beyond Real Estate. Untraditional Foreal Property Way</h2>
            <p className="section-text">
              Control the process and change your future, you have everything at
              your fingertips. Explore our innovative platform and redefine the
              way you approach real estate. With our cutting-edge tools and
              personalized solutions, you'll gain a competitive edge in today's
              dynamic market. Experience the power of seamless integration and
              unleash the potential of your property investments. Elevate your
              journey with Foreal Property.
            </p>
          </div>
        </div>
      </div>


      <div className="solutions-section">
        <h2 className="solutions-heading">Our Featured Solutions</h2>
        <p className="solutions-description">
          Explore our simplified property management solutions, covering leasing, rent/bill management, repairs, and review/renewal processes. Enhance tenant interactions, streamline operations, and optimize maintenance tasks effortlessly.
        </p>
        <div className="solutions-content">
          <div className="solutions-left">
            <Collapse accordion>
              <Panel header="Leasing" key="1">
                <p>
                  Explore our all-inclusive leasing platform aimed at simplifying property management tasks. Our cloud-based solution automates leasing processes, streamlines tenant interactions, and optimizes facility operations.
                </p>
              </Panel>
              <Panel header="Rent / Bills" key="2">
                <p>
                  Manage rent collection and utility billing efficiently with our system that keeps both property owners and tenants informed and organized.
                </p>
              </Panel>
              <Panel header="Repairs" key="3">
                <p>
                  Handle maintenance requests effectively, ensuring timely repairs and smooth communication between tenants and service providers.
                </p>
              </Panel>
              <Panel header="Review / Renewal" key="4">
                <p>
                  Automate contract review and renewal processes, making it easier to manage tenant agreements and maintain compliance.
                </p>
              </Panel>
            </Collapse>
          </div>
          <div className="solutions-right">
            <img
              src="/assets/images/home2.svg" // Replace with your actual image path
              alt="Featured Solutions"
              className="solutions-image"
            />
          </div>
        </div>
      </div>

      {/* <div className="get-started-section">
        <h2 className="get-started-heading">Let's Get Started</h2>
        <div className="get-started-content">
          <img
            src="/assets/images/flow.svg" // Replace with your actual image path
            alt="Featured Solutions"
            className="flow-image"
          />
        </div>
      </div> */}


      <div className="propertyscape-section">
        <div className="propertyscape-header">
          <img
            src="/assets/images/furniture.svg" // Replace with your actual image path
            alt="PropertyScape Difference"
            className="propertyscape-image"
          />
          <div className="propertyscape-text">
            <h2 className="propertyscape-heading">PropertyScape Difference</h2>
            <p className="propertyscape-description">
              PropertyScape has transformed the world of Real Estate and changed
              client communication and interaction. Our real-time platform empowers
              your relationships with your clients, fuels your efficiency, and
              gives you time to focus on what you do best!
            </p>
          </div>
        </div>
        <div className="propertyscape-features">
          <div className="feature-card">
            <img src="/assets/images/mails.svg" alt="Featured Solutions" />
            <h3 className="feature-title">Email Notifications</h3>
            <p className="feature-description">
              Access real-time information, whenever and wherever you need it.
            </p>
          </div>
          <div className="feature-card">
            <img src="/assets/images/user-shield.svg" alt="Featured Solutions" />
            <h3 className="feature-title">Easily Identify and Secure Leads</h3>
            <p className="feature-description">
              Easily identify and secure new leads.
            </p>
          </div>
          <div className="feature-card">
            <img src="/assets/images/clipboard-list.svg" alt="Featured Solutions" />
            <h3 className="feature-title">Empower Clients with Information</h3>
            <p className="feature-description">
              Give your clients the information they need to make confident
              decisions.
            </p>
          </div>
          <div className="feature-card">
            <img src="/assets/images/tools.svg" alt="Featured Solutions" />
            <h3 className="feature-title">Tools for Your Business</h3>
            <p className="feature-description">
              Receive the tools to run your own business but never feel alone.
            </p>
          </div>
          <div className="feature-card">
            <img src="/assets/images/heart-handshake.svg" alt="Featured Solutions" />
            <h3 className="feature-title">Strong Client Relationships</h3>
            <p className="feature-description">
              Experience strong client relationships and long-term loyalty.
            </p>
          </div>
          <div className="feature-card">
            <img src="/assets/images/adjustments-alt.svg" alt="Featured Solutions" />
            <h3 className="feature-title">Customizable Features</h3>
            <p className="feature-description">
              Customize according to your needs for a unique experience.
            </p>
          </div>
        </div>
      </div>


      <FooterNew/>
    </div>
  );
};

export default RealEstateUI;
