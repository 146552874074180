import React from "react";
import { Modal } from "antd";
import "./modal.css";

const CustomModal = ({
  title,
  style,
  closable,
  afterClose,
  maskClosable,
  visible,
  width,
  onCancel,
  footer,
  modalData,
}) => {
  return (
    <Modal
      title={title}
      style={{ top: 20 }}
      closable={closable}
      visible={visible}
      maskClosable={maskClosable}
      width={width}
      afterClose={afterClose}
      onCancel={onCancel}
      footer={footer}
      destroyOnClose={true}
    >
      {modalData}
    </Modal>
  );
};
export default CustomModal;
