export const dateChnager = (d) => {
  const date = new Date(d);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based, so add 1
  const day = date.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

const data = {
  userUnique_ID: "b090dca821244",
  profilePicture: null,
  userName: null,
  userEmail: null,
  isActive: null,
  image: null,
  imageLink: null,
  imageName: null,
  lastUpdated: null,
  isAuthenticated: false,
  isSoleProp: false,
  facebookID: null,
  eAuthenticationType: 0,
  token: "f4ad918a-bd56-4205-9117-bd74c9caf19f",
  abn: null,
  isNewUser: null,
  hideTask: null,
  hideCalendar: null,
  hideBranches: null,
  hideAnalytics: null,
  disableeSignature: null,
  userId: 2954,
  firstName: "Raj",
  lastName: "Tiwari",
  email: "rajkalashtiwari888@gmail.com",
  userType: 10,
  userRoles: [10, 11],
  userRoleID: 10,
  subscriptionId: 0,
  subscriptionName: null,
  agencyUniqueID: null,
  addedByAgencyID: null,
  address: null,
  contact: null,
  agencyID: null,
  isSecondry: false,
  isBranch: false,
  hasSolicitorDetails: false,
  agentUniqueId: null,
  agentId: null,
  affiliateUniqueId: null,
  affiliateId: null,
  contactId: null,
  contactUniqueId: null,
  phone: null,
  staffId: 0,
  agencyStaffUniqueId: null,
  isStaff: false,
  agentMgt: null,
  taskCalendarMgt: null,
  contactMgt: null,
  propertyMgt: null,
  agreementDocsMgt: null,
  packagesPricingstructure: null,
  subscriptionBilling: null,
  paymentsReconcillation: null,
  dashboard: null,
  support: null,
  newsletter: null,
  hasMultipleProfiles: true,
  userProfiles: [
    {
      userId: 2954,
      createdAt: "2023-09-09T17:13:37.803",
      userRoleId: 10,
      userRoleLabel: "Contact",
      isActive: true,
    },
    {
      userId: 2954,
      createdAt: "2023-09-20T19:42:54.413",
      userRoleId: 11,
      userRoleLabel: "Landlord",
      isActive: true,
    },
  ],
};

 export const FormatDate = (dateString) => {
    if (!dateString) {
        return '';
    }

  const dateObject = new Date(dateString);

  // Check if the dateObject is valid
  if (isNaN(dateObject.getTime())) {
    return ""; // Invalid date string
  }

  // Extract year, month, and day
  const year = dateObject.getFullYear();
  let month = dateObject.getMonth() + 1;
  let day = dateObject.getDate();

  // Pad month and day with leading zeros if necessary
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;

  return `${year}-${month}-${day}`;
};

export const FormatDateView = (dateString) => {
  if (!dateString) {
    return "";
  }

  const dateObject = new Date(dateString);

  // Check if the dateObject is valid
  if (isNaN(dateObject.getTime())) {
    return ""; // Invalid date string
  }

  // Extract year, month, and day
  const year = dateObject.getFullYear();
  let month = dateObject.getMonth() + 1;
  let day = dateObject.getDate();

  // Pad month and day with leading zeros if necessary
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;

  return `${day}-${month}-${year}`;
};
