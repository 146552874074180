import React from 'react'

const ShowValue = ({ Data , setData, Label, agreement, icon, colval,displayrow, active}) => {
    const getLabelByValue = (value) => {
        const property = Data.find(item => item.value === value);
        return property ? property.label : " ";
    };

    

    return (
        <div className={!displayrow ? '':`row`} style={{display:'flex',lineHeight:'3'}}>
            <div style={{whiteSpace:'nowrap'}} className={`${agreement ? "label2 col-lg-6 col-sm-12" : colval === 3 ? "col-lg-3" : "label"}`}>
                <div style={{fontWeight:'500',color:"#5E696E",fontSize:'14px'}}>{Label}:</div>
            </div>
            {
                Data ? (
                    <div className={`${agreement ? "value2" : "value"}`} style={{fontWeight:'500',color:'#21333b'}}>
                    {icon && <span className="icon">{icon}</span>}
                    {getLabelByValue(setData)}
                    </div>
                ) : (
                    <div className={`${agreement ? "value2" : "value"}`} style={{fontWeight:'500',color: active ? '#00A5A2' : active === false ? '#E4A000': '#21333b', fontSize:'14px'}}>
                    {icon && <span className="icon">{icon}</span>}
                   <span style={{whiteSpace:"initial", display:'flex', alignItems:'center'}}> {setData}</span>
                    </div>

                )
            }
           
        </div>
    )
}

export default ShowValue