import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../../components/CommonSection/Header/Header";
import Sidebar from "../../../../components/CommonSection/SideBar/sideBar";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import SubNav from "../../../../components/CommonSection/Header/subHeader";
import VerticalProgressive from "../../../input-components/verticalProgressivebar";
import {
  GetCookie,
  currentProfile,
} from "../../../../components/CommonSection/Cookie/Session";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { dynamicUrl } from "../../../../Utilities/profileManagement";
import {
  viewjobdetailsbymaintenanceuid,
  FilePath,
  addjobschedule,
  reviewjobschedule,
  updatemaintenancestatus,
  uploadjobphotos,
} from "../../../../components/CommonSection/apiresources";
import {
  makeRequestWithSpinner,
  uploadToS3WithSpinner,
} from "../../../../Utilities/client";
import {ActionStatus,eMaintenanceStatus,UserRoleId} from "../../../../Utilities/enum";
import "./../maintainance.css";
import CustomModal from "../../../input-components/Modal";
import AntDatePicker from "../../../input-components/datePicker";
import moment from "moment";
import { Table, Spin } from "antd";
import { BiPencil } from "react-icons/bi";
import SelectBox from "../../../input-components/selectBoxNew";
import { TiDeleteOutline } from "react-icons/ti";
import ImageGalleryModal from "../../../input-components/imageGallary";

const JobDetails = () => {
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agencyUID, setAgencyUID] = useState("");
  const [issueDetails, setIssueDetails] = useState({});
  const [activeTopic, setActiveTopic] = useState("ListMaintainanceRequest");
  const [activeStep, setActiveStep] = useState(0);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [openReSchedule, setOpenReSchedule] = useState(false);
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const navigate = useNavigate();
  const [allDetails, setAllDetails] = useState({});
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();
  const urlParams = new URLSearchParams(window.location.search);
  const maintenanceUId = urlParams.get("muid");
  const uid = urlParams.get("uid");
  const [reviewStatusArr, setReviewStatusArr] = useState([
    { value: 1, label: "Accepted" },
    { value: 5, label: "Cancelled" },
  ]);
  const [reviewStatus, setReviewStatus] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [btnName, setBtnName] = useState("");
  const [uploadImage, setUploadImage] = useState(false);
  const [propertyimageitems, setPropertyimageitems] = useState([]);
  const [propertyImages, setPropertyImages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const primaryDocumentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [galleryImages, setGallaryImages] = useState([]);
  const [galleryImagesJob, setGallaryImagesJob] = useState([]);
  const [messages, setMessages] = useState([]);

  const [records, setRecords] = useState({});
  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgencyUID(parsedData.agencyUniqueID);
          setUserProfile(parsedData.currentProfile);
        } else {
          navigate("/signin");
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (maintenanceUId) {
      getDetails(maintenanceUId);
    }
    window.scrollTo(0, 0);
  }, []);

  const getDetails = (maintenanceUId, isNew) => {
    const url = `${viewjobdetailsbymaintenanceuid}?maintenaceUId=${maintenanceUId}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "get")
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          setIssueDetails(returnData.object.maintenanceDetails);
          let images = returnData.object?.maintenanceDetails.photos?.map(
            (item) => `${FilePath}${item}`
          );
          setGallaryImages(images, "12");

          let imagesJob = returnData.object?.jobPhotos?.map(
            (item) => `${FilePath}${item}`
          );
          setGallaryImagesJob(imagesJob);

          setAllDetails(returnData.object);
          const steps =
            returnData.object.trackDetails?.trackActionDetails || [];

          // Find the first incomplete step or set to the last step if all are completed
          const currentStep = steps.findIndex((step) => !step.isCompleted);
          setActiveStep(currentStep !== -1 ? currentStep : steps.length - 1);
          let status = returnData.object.maintenanceDetails.status;
          setBtnName(
            status === 6
              ? "Start Job"
              : status === 7
              ? "Job Completed"
              : status === 8
              ? "Upload Photos"
              : ""
          );
          const sortedMessages = [...returnData.object?.additionNotes].sort((a, b) =>
            new Date(a.createdAt) - new Date(b.createdAt)
        );
        setMessages(sortedMessages);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", returnData.message]);
      });
  };

  const handleTopicValue = (text) => {
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const stepsData =
    issueDetails.trackDetails?.trackActionDetails?.map((detail) => ({
      img:
        detail.actionTime && detail.actionTime !== "0001-01-01T00:00:00"
          ? new Date(detail.actionTime).toLocaleDateString()
          : "No data",
      title: detail.action,
      isCompleted: detail.isCompleted,
    })) || [];

  const currentSideBar = currentProfile.get();

  const handleGoBack = () => {
    dynamicUrl(
      navigate,
      currentSideBar === 12 ? "ListTradsmanMaintainance":currentSideBar === 13 ? "ListIssueDetails" : "ListMaintainanceRequest"
    );
  };

  const closeSchedule = () => {
    setOpenSchedule(false);
    setOpenReSchedule(false);
  };

  const handleSchedule = () => {
    setOpenSchedule(true);
    setDate("");
    setStartTime("");
    setEndTime("");
    setReviewStatus("");
  };

  const handleDate = (date, dateString) => {
    setDate(date);
  };

  const handleTimeChange = (time,timeString, label) => {
    if (label === 'Start') {
        setStartTime(time);
    } else if (label === 'End') {
        setEndTime(time);
    }
};

  

  const handleFinalSubmit = (data) => {
    let formData = {
      JobId: allDetails.jobId,
      ScheduledDate: date,
      ScheduledStartTime: startTime,
      ScheduledEndTime: endTime,
      ScheduledByUserType: 12,
      UserUniqueId: uid,
      LastScheduleId: records.scheduleId,
      LastScheduleStatus: reviewStatus,
    };

    const url = addjobschedule;
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      url,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          setOpenSchedule(false);
          getDetails(maintenanceUId);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleApprove = (data) => {
    let formData = {
      ScheduleId: records.scheduleId,
      Status: reviewStatus,
    };
    const url = reviewjobschedule;
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      url,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          setOpenSchedule(false);
          getDetails(maintenanceUId);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleJobs = (data) => {
    if (btnName === "Upload Photos") {
      setUploadImage(true);
    } else {
      let formData = {
        MaintenanceUniqueID: issueDetails?.maintenanceUniqueId,
        Status:
          btnName === "Start Job"
            ? eMaintenanceStatus.JobStarted
            : btnName === "Job Completed"
            ? eMaintenanceStatus.JobCompleted
            : "",
      };
      const url = updatemaintenancestatus;
      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        url,
        "post",
        formData,
        null,
        false
      )
        .then((returnData) => {
          if (returnData.status === ActionStatus.Successfull) {
            ToastRef.current.notify(["success", returnData.message]);
            getDetails(maintenanceUId);
          } else {
            ToastRef.current.notify(["error", returnData.message]);
          }
        })
        .catch((error) => {
          ToastRef.current.notify(["error", error.message]);
        });
    }
  };

  const handleUplodeImages = (data) => {
    let formData = {
      JobId: allDetails.jobId,
      Photos: propertyImages,
    };
    const url = uploadjobphotos;
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      url,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          getDetails(maintenanceUId);
          setUploadImage(false);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleActions = (record) => {
    setOpenReSchedule(true);
    setOpenSchedule(true);
    setDate("");
    setStartTime("");
    setEndTime("");
    setReviewStatus(null);
    setRecords(record);
  };

  const columns = [
    {
      title: "Scheduled Date",
      dataIndex: "scheduledDate",
      key: "scheduledDate",
      render: (text) => moment(text).format("DD-MM-YYYY"), // Format date
    },
    {
      title: "Start Time",
      dataIndex: "scheduledStartTime",
      key: "scheduledStartTime",
      render: (text) => moment(text).format("HH:mm A"), // Format start time
    },
    {
      title: "End Time",
      dataIndex: "scheduledEndTime",
      key: "scheduledEndTime",
      render: (text) => moment(text).format("HH:mm A"), // Format end time
    },
    {
      title: "Schedule By",
      dataIndex: "sScheduleBy",
      key: "scheduledEndTime",
    },
    {
      title: "Schedule To",
      dataIndex: "sScheduleTo",
      key: "sScheduleTo",
    },
    {
      title: "Status",
      dataIndex: "sStatus",
      key: "sStatus",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          {UserRoleId.Trade === record.scheduledToUserType &&
          record.status === null ? (
            <BiPencil
              style={{ fontSize: "22px", cursor: "pointer" }}
              onClick={() => handleActions(record)}
            />
          ) : null}
        </span>
      ),
    },
  ];

  const handleInputChange = (e, label) => {
    setReviewStatus(e.value);
  };

  const closeUploadImg = () => {
    setUploadImage(false);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };

  const handleInputChangeFile = (e, indexforfiles, documentType) => {
    const files = e.target.files;
    handleFileChange(files, indexforfiles, documentType);
  };

  const handleFileChange = async (files, indexforfiles, documentType) => {
    for (let i = 0; i < files.length; i++) {
      const selectedFile = files[i];
      await handleUpload(selectedFile, indexforfiles, documentType);
    }
  };

  const handleUpload = async (selectedFile) => {
    await uploadToS3WithSpinner(
      showSpinner,
      hideSpinner,
      "maintenancejob/",
      `maintenancejob`,
      selectedFile
    ).then((returnData) => {
      if (returnData.status === ActionStatus.Successfull) {
        const transformedItem = {
          id: Math.floor(Math.random() * 1000000),
          src: FilePath + returnData.Key,
          width: 1,
          height: 1,
        };
        setPropertyimageitems((prevItems) => [...prevItems, transformedItem]);
        setPropertyImages((prevDocuments) => [
          ...prevDocuments,
          returnData.Key,
        ]);
        ToastRef.current.notify([
          "success",
          "File uploaded successfully",
          true,
        ]);
      } else {
        ToastRef.current.notify([
          "error",
          "Error uploading file. Please try again.",
        ]);
      }
    });
  };

  const handleFileUploader = () => {
    primaryDocumentRef.current.click();
  };

  const handleDeleteImage = (id, src) => {
    setPropertyimageitems((prevItems) =>
      prevItems.filter((item) => item.id !== id)
    );
    setPropertyImages((prevImages) =>
      prevImages.filter((image) => image !== src)
    );
  };

  

  return (
    <div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
          <SubNav
            title={"View Repair Details"}
            goBack={handleGoBack}
            actionButton={false}
            extraData={true}
            extraContent={
              <div className="property-details-buttons">
                {allDetails?.schedulesDetails?.length > 0 ? (
                  ""
                ) : (
                  <button className="pdb-save-changes" onClick={handleSchedule}>
                    Schedule
                  </button>
                )}
                {btnName ? (
                  <button
                    style={{ marginRight: "20px" }}
                    className="pdb-save-changes mt-2"
                    onClick={handleJobs}
                  >
                    {btnName}
                  </button>
                ) : null}
              </div>
            }
          />
          <div className="material-work-desk-new bg-white m-3">
            <ToastAlert ref={ToastRef} />
            <div className="section-left p-5">
              <div className="mb-4 d-flex align-items-center">
                {issueDetails.photos && (
                  <img
                    src={FilePath + issueDetails.photos[0]}
                    alt="upload-icon"
                    className="upload-iconMaintainance1 mr-5"
                  />
                )}
                <div>
                <div className="text-primary font-weight-bold">{issueDetails.title}</div>
                  <div >
                    {issueDetails.description}
                  </div>
                 
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 labelMaintainance mb-3 pl-2 text-dark">
                Details
              </div>
              <div className="pl-2 d-flex align-items-center">
                <div className="col-lg-4 col-sm-6 labelMaintainance mb-3 pl-0">
                  Tracking ID
                </div>
                <div className="col-lg-4 col-sm-10 mb-3 pl-0">
                  {issueDetails.maintenanceUniqueId}
                </div>
              </div>
              <div className="pl-2 d-flex align-items-center">
                <div className="col-lg-4 col-sm-6 labelMaintainance mb-3 pl-0">
                  Property Address
                </div>
                <div className="col-lg-4 col-sm-10 mb-3 pl-0">
                  {issueDetails.propertyAddress}
                </div>
              </div>
              <div className="pl-2 d-flex align-items-center">
                <div className="col-lg-4 col-sm-6 labelMaintainance mb-3 pl-0">
                  Status
                </div>
                <div className="col-lg-4 col-sm-10 mb-3 pl-0">
                  {issueDetails.sStatus}
                </div>
              </div>
              {issueDetails.sStatus === "Repair Rejected" ? (
                <div className="pl-2 d-flex align-items-center">
                  <div className="col-lg-4 col-sm-6 labelMaintainance mb-3 pl-0">
                    Rejected Reason
                  </div>
                  <div
                    className="col-lg-4 col-sm-10 mb-3 pl-0"
                    style={{ wordBreak: "break-word" }}
                  >
                    {issueDetails.reasonOfRejection}
                  </div>
                </div>
              ) : null}
              {issueDetails.status >= eMaintenanceStatus.JobAssigned ? (
                <div className="pl-2 d-flex align-items-center">
                  <div className="col-lg-4 col-sm-6 labelMaintainance mb-3 pl-0">
                    Assign to
                  </div>
                  <div className="col-lg-4 col-sm-10 mb-3 pl-0">
                    {issueDetails.tradesmanName}
                  </div>
                </div>
              ) : null}
              {issueDetails.status >= eMaintenanceStatus.JobAssigned  ? (
                <div className="pl-2 d-flex align-items-center">
                  <div className="col-lg-4 col-sm-4 labelMaintainance mb-3 pl-0">
                    Contact Number
                  </div>
                  <div className="col-lg-4 col-sm-10 mb-3 pl-0">
                    {issueDetails.tradesmanContact}
                  </div>
                </div>
              ) : null}
              <div>
                <div className="col-lg-4 col-sm-4 labelMaintainance mb-3 pl-2">
                  Images
                </div>
                <div className="uploaded-images-container">
                  <ImageGalleryModal
                    images={galleryImages?.length > 0 ? galleryImages : []}
                  />
                </div>
              </div>
              <div>
                <div className="col-lg-4 col-sm-4 labelMaintainance mb-3 pl-2">
                  Job Images
                </div>
                <div className="uploaded-images-container">
                  <ImageGalleryModal
                    images={
                      galleryImagesJob?.length > 0 ? galleryImagesJob : []
                    }
                  />
                </div>
              </div>
            </div>
            <div className="section-right pt-3">
              {allDetails?.schedulesDetails?.length > 0 ? (
                <div>
                  <div className="p-3 d-flex justify-content-center">
                    Schedule Details
                  </div>

                  <div className="w-100 h-100 border border-gray rounded">
                    <Table
                      dataSource={allDetails.schedulesDetails}
                      columns={columns}
                      pagination={false} // Disable pagination if only showing one row
                    />
                  </div>
                 
                </div>
              ) : null}
              {messages.length > 0 ?
              <div className="correspondence-container p-3">
                                <div>{"Additional Information"}</div>
                                {messages.map((msg) => (
                                    <div key={msg.id} className="correspondence-item">
                                        <div className="left-sections">
                                            <div className="logo">
                                                <img
                                                    src="..\..\assets\images\logo.png"
                                                    alt="Foreal Logo"
                                                    width={10}
                                                />
                                            </div>
                                            <div>
                                                <div className="user-name mt-1">{msg.createdBy}</div>
                                                <div className="timestamp">
                                                    {new Date(msg.createdAt).toLocaleString("en-GB", {
                                                        weekday: "short",
                                                        day: "numeric",
                                                        month: "short",
                                                        year: "numeric",
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                        second: "2-digit",
                                                        timeZoneName: "short",
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="right-section">
                                            <p>{msg.message}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>: null}
            </div>
            <CustomModal
              title={reviewStatus === 5 ? "Re-Schedule" : "Schedule"}
              width={600}
              closable={closeSchedule}
              visible={openSchedule}
              onCancel={closeSchedule}
              modalData={
                <div className={"row"} style={{ marginLeft: "10px" }}>
                  {openReSchedule ? (
                    <div
                      className="col-lg-12 col-xl-12"
                      style={{
                        padding: "0px 40px 0px 35px",
                        marginBottom: "-20px",
                      }}
                    >
                      <SelectBox
                        colVal={12}
                        label="Review Status"
                        id="issueType"
                        name="issueType"
                        value={reviewStatus}
                        onChange={(e) => handleInputChange(e, "status")}
                        options={reviewStatusArr}
                      />
                    </div>
                  ) : null}
                  {reviewStatus === 5 || !openReSchedule ? (
                    <AntDatePicker
                      colvalue={12}
                      label="Schedule Date"
                      value={date}
                      format="HH:mm"
                      customeDateFormatDate={"YYYY-MM-DD"}
                      onChange={(date, dateString) =>
                        handleDate(date, dateString)
                      }
                      disabledDate={(current) =>
                        current && current < moment().startOf("day")
                      }
                    />
                  ) : null}
                  {reviewStatus === 5 || !openReSchedule ? (
                    <AntDatePicker
                      colvalue={12}
                      label="Start Time"
                      showTime
                      value={startTime} // Pass 24-hour format value
                      onChange={(time, timeString) =>
                        handleTimeChange(time,timeString, "Start")
                      }
                      disabledTime={() => ({
                        disabledHours: () => [
                          ...Array(8).keys(), // Disable hours before 8 AM
                          ...Array.from({ length: 24 - 18 }, (_, i) => i + 18), // Disable hours after 6 PM
                        ],
                        disabledMinutes: () => [], // Enable all minutes
                        disabledSeconds: () => [], // Enable all seconds
                      })}
                    />
                  ) : null}
                  {reviewStatus === 5 || !openReSchedule ? (
                    <AntDatePicker
                      colvalue={12}
                      label="End Time"
                      showTime
                      value={endTime} // Pass 24-hour format value
                      onChange={(time, timeString) =>
                        handleTimeChange(time,timeString, "End")
                      }
                      disabledTime={() => ({
                        disabledHours: () => [
                          ...Array(8).keys(), // Disable hours before 8 AM
                          ...Array.from({ length: 24 - 18 }, (_, i) => i + 18), // Disable hours after 6 PM
                        ],
                        disabledMinutes: () => [], // Enable all minutes
                        disabledSeconds: () => [], // Enable all seconds
                      })}
                    />
                  ) : null}
                </div>
              }
              footer={[
                <div
                  key="footer"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    style={{ marginRight: "20px" }}
                    className="pdb-save-changes mt-2"
                    onClick={
                      reviewStatus === 1 ? handleApprove : handleFinalSubmit
                    }
                  >
                    {reviewStatus === 5 ? "Update" : "Submit"}
                  </button>
                </div>,
              ]}
            />

            <CustomModal
              title={"Upload Image"}
              width={800}
              closable={closeUploadImg}
              visible={uploadImage}
              onCancel={closeUploadImg}
              modalData={
                <div style={{ marginLeft: "10px" }}>
                  <div
                    className={`contacts-group ${
                      propertyimageitems.length > 2 ? "col-md-12" : "col-md-12"
                    }`}
                  >
                    <div
                      className={`upload-section1 ${
                        isDragging ? "dragging" : ""
                      }`}
                      onDragEnter={handleDragEnter}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                    >
                      <Spin spinning={loading}>
                        <div className="upload-setion-input">
                          <div>
                            <img
                              src="../../../assets/images/upload.png"
                              alt="upload-icon"
                              className="upload-icon"
                            />
                            <span
                              className="upload-select"
                              onClick={handleFileUploader}
                            >
                              Click to upload
                            </span>
                            <input
                              ref={primaryDocumentRef}
                              type="file"
                              onChange={(e) => handleInputChangeFile(e, 0)}
                              className="upload-input"
                              name="upload"
                              multiple
                            />
                            or drag or drop
                          </div>
                        </div>
                      </Spin>
                      <div>
                        {/* Display uploaded images */}
                        <div className="uploaded-images-container">
                          {propertyimageitems.map((item) => (
                            <div
                              key={item.id}
                              className="uploaded-image-wrapper"
                            >
                              <img
                                src={item.src}
                                alt={`Property Image ${item.id}`}
                                className="uploaded-image"
                              />
                              <TiDeleteOutline
                                className="delete-icon1"
                                onClick={() =>
                                  handleDeleteImage(item.id, item.src)
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              footer={[
                <div
                  key="footer"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    style={{ marginRight: "20px" }}
                    className="pdb-save-changes mt-2"
                    onClick={handleUplodeImages}
                  >
                    {"Submit"}
                  </button>
                </div>,
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
