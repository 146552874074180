import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TaskDetails ,ListTask,assignedTo} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import "../../../components/Dashboard/Dashboard-agent/AgentList.css";
import { ActionStatus } from "../../../Utilities/enum";
import { addParametersForProfiles, dynamicUrl } from "../../../Utilities/profileManagement";
import SubNav from "../../navbar/subNav";
import AntdTableExample from "../../input-components/dataTable";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { Select,Steps,DatePicker } from 'antd';
import CustomModal from "../../input-components/Modal";
import axios from "axios";
import AddNewTask from "./addTask";
import ShowValue from "../../input-components/ShowValue";
import { format } from 'date-fns';
import ViewTaskDetails from "./taskDetails"


const TaskList = () => {
    const [contactLists, setContactLists] = useState([]);
    const [userId, setUserId] = useState("");
    const [agencyID, setAgencyId] = useState("");
    const navigate = useNavigate();
    const [propertyType, setPropertyType] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [purpose, setPurpose] = useState("");
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [rows, setRows] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [openAddProperty, setOpenAddProperty] = useState(false);
    const [getDetails, setGetDetails] = useState(null);
    const [dpdata, setDpdata] = useState([]);
    const [tabValue, setTabValue] = useState(1);
    const [addNew, setAddNew] = useState(false);
    const [edit, setEdit] = useState(false);
    const [view, setView] = useState(false);
    const [selectedCountries, setSelectedCountries] = useState(null);
    const [isStaff, setIsStaff] = useState(false);
    const [isBranch, setIsBranch] = useState(false);
    const [propertyTypeVal, setPropertyTypeVal] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [agentId, setAgentId] = useState("");

    const { Step } = Steps;
    const { RangePicker } = DatePicker;


    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setAgentId(parsedData.agentId)
                    setUserId(parsedData.userId);
                    setAgencyId(parsedData.agencyID);
                    setIsStaff(parsedData.isStaff)
                    setIsBranch(parsedData.isBranch)
                    reportData(parsedData.agencyUniqueID);
                }
            } catch (error) {
                console.error("Error retrieving data from AsyncStorage:", error);
            }
        };
        retrieveData();
    }, [userId]);

    useEffect(() => {
        if (userId) PostApiWithSpinner(1,10);
    }, [userId, searchText, purpose, propertyType,propertyTypeVal,fromDate,toDate]);
    var url = new URL(window.location.href);
    var TaskDetailsId = url.searchParams.get("TaskDetailsId");

    useEffect(() => {
        if (TaskDetailsId) {
            GetApi(TaskDetailsId)
        }
    }, [TaskDetailsId])

    useEffect(() => {
        if (getDetails?.postcode != null) {
            setSelectedCountries(getDetails.postcode.split(";"));
        }
    }, [])


    const PostApiWithSpinner = (currentPage, totalRows) => {
        const baseUrl = ListTask;
        let data = {
            SortBy: "CreatedDate",
            SortOrder: "Desc",
            RecordsPerPage: rows,
            AgencyId: agencyID,
            Search: searchText,
            PageNo: currentPage,
            IsBranch: isStaff,
            IsStaff: isBranch,
            minDate: fromDate,
            maxDate: toDate,
            SearchDateType: propertyTypeVal,
            isCompleted: propertyType === 1 ? true : propertyType === 2 ? false : null
        };
        if (data.SearchDateType === "") {
            delete data.SearchDateType;
          }
        data = addParametersForProfiles(data);
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    let propertyData = response.list;
                    let propertyList = propertyData.map((task) => ({
                        Status: task.isApproved ? task.isCompleted ? "Completed":"In-Progress":"Not Approved",
                        AssignedTo: task.asignee,
                        Name: task.name,
                        DueDate: task.dueDate,
                        Property: task.sProperty,
                        Priority: task.sPriorityLevel,
                        taskUniqueID: task.taskUniqueID,
                    }));
                    setTotalPages(response.totalCount)
                    setContactLists(propertyList);
                } else {
                    alert(`Message: ${response.message}`);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    // Handler for page change
    const handlePageChange = (pageNumber, totalRows) => {
        setCurrentPage(pageNumber);
        setRows(totalRows);
        PostApiWithSpinner(pageNumber, totalRows);
    };

    const tabColumns = {
        Status: {
            colorMap: {
                "In-Progress": "#E5A000",
                "Completed": "#00A5A2",
            },
            defaultColor: 'blue'
        }
    };

    const handleSearchValueChange = (value) => {
        setSearchText(value);
    };

    const handleOpenProperty = () => {
        const baseUrl = window.location.pathname;
        navigate(baseUrl);
        setOpenAddProperty(true);
        setTabValue(1);
        setAddNew(true);
        setEdit(false);
        setView(false);
        setGetDetails(null);
    }

    const closeProperty = () => {
        const baseUrl = window.location.pathname;
        navigate(baseUrl);
        setOpenAddProperty(false);
        setEdit(false);
        setView(false);
        setGetDetails(null);
        setAddNew(false);                   
    }

    const GetApi = (value) => {
        let data = {
            taskUniqueID: value,
            AgentId: agentId,
        }
        const baseUrl = TaskDetails;
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post",data)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    setGetDetails(response.object);
                } else {
                    handleparentsuccess(["warn", response.data.message]);
                }
            })
            .catch((error) => {
                handleparentsuccess(["error", error]);
            });
    };

    const reportData = (agencyUID) => {
        const apiUrl = assignedTo;
        const baseUrl = apiUrl + "?agencyUID=" + agencyUID;
        axios.get(baseUrl).then((response) => {
            if (response.data.object) setDpdata(response.data.object);
        });
    };

    const handleTabChange = (key) => {
        if (key === null) {
            setOpenAddProperty(false);
            PostApiWithSpinner();

        } else {
            setTabValue(key)
        }
    };

    const handleDataChild = (value) => {
        GetApi(value);
    };

    const handleEdit = (e, data) => {
        e.stopPropagation()
        e.preventDefault()
        const baseUrl = window.location.pathname; // Get the current path without query params
        const newUrl = `${baseUrl}?TaskDetailsId=${data.taskUniqueID}`; // Append the contactUID to the URL
        navigate(newUrl);
        setOpenAddProperty(true);
        setEdit(true);
        setTabValue(1)
    }

    const handleView = (e, data) => {
        const baseUrl = window.location.pathname; // Get the current path without query params
        const newUrl = `${baseUrl}?TaskDetailsId=${data.taskUniqueID}`; // Append the contactUID to the URL
        navigate(newUrl);
        setOpenAddProperty(true);
        setView(true);
        setTabValue(1)
    }

    const actions = [
        { icon: "/assets/images/dots.svg",key:"View", onClick: (e, data) => handleView(e, data) },
        { icon: "/assets/images/editing.svg", onClick: (e, data) => handleEdit(e, data) },
    ]

    const options = [
        { value: 1, label: "Seller" },
        { value: 2, label: "Buyer" },
        { value: 3, label: "Investor" },
        { value: 4, label: "Tenant" },
        { value: 5, label: "Landlord" },
        { value: 6, label: "Developer" },
        { value: 7, label: "Supplier" },
    ];

    const commaSeparatedString =
        getDetails?.typeIAM && typeof getDetails?.typeIAM === "string"
            ? getDetails?.typeIAM
                .split(",")
                .map((value) => {
                    const option = options.find((opt) => opt.value === parseInt(value));
                    return option ? option.label : null;
                })
                .filter((label) => label !== null)
                .join(", ")
            : null;

    const optionsPrefference = [
        { value: 1, label: "Investment" },
        { value: 2, label: "Development" },
        { value: 3, label: "First Home Buyer" },
        { value: 4, label: "Renting" },
        { value: 5, label: "Selling" },
        { value: 6, label: "Buying" },
    ];

    const commaSeparatedString1 =
        getDetails?.otherDetailsType && typeof getDetails?.otherDetailsType === "string"
            ? getDetails?.otherDetailsType
                .split(",")
                .map((value) => {
                    const option = optionsPrefference.find((opt) => opt.value === parseInt(value));
                    return option ? option.label : null;
                })
                .filter((label) => label !== null)
                .join(", ")
            : null;

            const propertyTypeitems = [
                { label: "All", value: 0 },
                { label: "Completed", value: 1 },
                { label: "Due", value: 2 },
              ];

              const TypeArr = [
                { label: "Select", value: "" },
                { label: "Added On", value: 1 },
                { label: "Due On", value: 2 },
                { label: "Completed On", value: 3 },
              ]

              const propertyTypeChangeHandler = (value) => {
                setPropertyType(value);
              };

              const handleInput = (value) => {
                setPropertyTypeVal(value);
            };

            const handleRangePickerChange = (dates, dateStrings) => {
                setFromDate(dateStrings[0]);
                setToDate(dateStrings[1]);
            };

    return (
        <div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
                    <SubNav title={"Task"} goback={false} extraData={true} extraContent={
                        <div className="property-details-buttons">
                            <button onClick={handleOpenProperty} className="subnav-btn" type="submit">
                                <HiOutlinePlusSmall style={{ fontSize: '24px' }} /> Add
                            </button>
                        </div>
                    } />
                    <div className="material-work-desk-new bg-white m-3">
                        <div style={{margin: '10px' }}>
                            <Select className="mr-2" value={propertyType} style={{ width: 120 }} options={propertyTypeitems} onChange={propertyTypeChangeHandler} />
                            <Select value={propertyTypeVal} style={{ width: 120 }} options={TypeArr} onChange={handleInput} />
                            <RangePicker
                                style={{ marginLeft: '10px' }}
                                onChange={handleRangePickerChange}
                                format="YYYY-MM-DD"
                            />
                        </div>
                        <AntdTableExample
                            label="List of Task"
                            dataSource={contactLists}
                            globalSearch
                            hideCol={"taskUniqueID,DueDate"}
                            onPageChange={handlePageChange}
                            currentPage={currentPage}
                            totalRows={rows}
                            totalRecords={totalPages}
                            tagColumns={tabColumns}
                            onSearchValueChange={handleSearchValueChange}
                            actions={actions}
                            onRowClick={handleView}
                        />
                    </div>
                    <CustomModal footer={null} title={view ? "View Task Details": edit ? "Update Contacts":"Add Tasks"} width={800} closable={closeProperty} visible={openAddProperty} onCancel={closeProperty}
                        modalData={
                            <div>
                                
                                <div>
                                    {view && (
                                        <div className="details-form p-3">
                                                <ViewTaskDetails getDetails={getDetails} closeProperty={closeProperty}/>
                                        </div>
                                    )}

                                    {!view && (
                                        <div>
                                            {(addNew)||(edit) ? (
                                                <AddNewTask
                                                    handleCurrentPage={handleTabChange}
                                                    getDetails={getDetails}
                                                    onDataRecive={handleDataChild}
                                                />
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                            </div>
                        }

                    />
                </div>
            </div>
        </div>
    );
};

export default TaskList;
