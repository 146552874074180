import * as Yup from "yup";
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
const validDate = Yup.string().required("Enter the date");
const validEmail = Yup.string().email().required("Email is required");
const validOptionalEmail = Yup.string().nullable().email("Email must be a valid email.");
const validAustralianMob = Yup.string()
  .matches(
    /^(04)\d{8}$/,
    "Mobile number must start with 04 and be 10 digits long"
  )
  .required("Enter Mobile Number");

const validOptionalAustralianMob = Yup.string()
  .nullable()
  .matches(
    /^(04)\d{8}$/,
    "Mobile number must start with 04 and be 10 digits long"
  )
  .min(5, "Number must be greateer than 5 digits");

const validBSB = Yup.string()
  .matches(/^[0-9]{6}$/, "BSB must be a 6-digit number")
  .length(6)
  .required()
  .label("BSB Number");

const validABN = Yup.string()
  .matches(/^[0-9]{11}$/, "ABN must be a 11-digit number")
  .required()
  .label("ABN Number");

  const optionalValidABN = Yup.string()
  .matches(/^[0-9]{11}$/, "ABN must be a 11-digit number")
  .label("ABN Number");

const validACN = Yup.string()
  .matches(/^[0-9]{9}$/, "ACN must be a 9-digit number")
  .required()
  .label("ACN Number");

const validFname = Yup.string()
  .min(2, "FirstName must be greater than 1 word")
  .max(25)
  .required("First Name is required");

const validName = Yup.string()
  .min(2, "Name must be greater than 1 word")
  .max(25)
  .required(" Name is required");

const validLname = Yup.string()
  .min(2, "LastName must be greater than 1 word")
  .max(25)
  .required("Last Name is required");

const validMname = Yup.string()
  .min(2, "Middle Name must be greater than 1 word")
  .max(25)
  .required("Middle Name is required");
const validOptionalMname = Yup.string()
  .min(2, "Middle Name must be greater than 1 word")
  .max(25);

const validNumber = Yup.string().matches(/^[0-9]+$/, "accept only numbers");

const validDocNumber = Yup.string().required("Document Number is required");

const validDocType = Yup.string().required("Document Type is required");

export const signUpSchema = Yup.object({
  name: Yup.string()
    .min(2, "Name must be greater than 1 word")
    .max(25)
    .required("Name is required"),

  email: validEmail,
  password: Yup.string()
    .matches(passwordRules, { message: "Create a stronger password" })
    .required("Enter the password."),

  cpassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Password must match"
  ),
  rem: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
});

export const signInSchema = Yup.object({
  email: validEmail,
  password: Yup.string()
    .required("Password is required"),
});

export const forgotSchema = Yup.object({
  email: Yup.string().email("Enter a valid Email").required("Enter your email"),
});

export const amoSchema = Yup.object({
  fname: Yup.string()
    .min(2, "FirsstName must be greater than 1 word")
    .max(25)
    .required("First Name is required"),
  lname: Yup.string()
    .min(2, "LastName must be greater than 1 word")
    .max(25)
    .required("Last Name is required"),
  email: validEmail,
  number: Yup.string()
    .min(5, "Number must be greateer than 5 digits")
    .required("Enter Mobile Number"),
});

export const addresshistorySchema = Yup.object({
  name: Yup.string()
    .min(2, "Name must be greater than 1 word")
    .max(25)
    .required("Name is required"),
  fname1: Yup.string()
    .min(2, "FirsstName must be greater than 1 word")
    .max(25)
    .required("First Name is required"),
  lname1: Yup.string()
    .min(2, "LastName must be greater than 1 word")
    .max(25)
    .required("Last Name is required"),
  email: validEmail,
  phone: Yup.string()
    .min(5, "Number must be greateer than 5 digits")
    .required("Enter Mobile Number"),
  fname2: Yup.string()
    .min(2, "FirsstName must be greater than 1 word")
    .max(25)
    .required("First Name is required"),
  lname2: Yup.string()
    .min(2, "LastName must be greater than 1 word")
    .max(25)
    .required("Last Name is required"),
  phone1: Yup.string()
    .min(5, "Number must be greateer than 5 digits")
    .required("Enter Mobile Number"),
  phone2: Yup.string()
    .min(5, "Number must be greateer than 5 digits")
    .required("Enter Mobile Number"),
  date1: Yup.string().required("Enter the date"),
  date2: validDate,
  type: Yup.string().required("Please select "),
});

export const employementSchema = Yup.object({
  companyname: Yup.string()
    .min(2, "Company Name must be greater than 1 word")
    .max(25)
    .required("Company Name is required"),
  role: Yup.string()
    .min(2, "Role must be greater than 1 word")
    .max(25)
    .required("Role is required"),
  name: Yup.string()
    .min(2, "Name must be greater than 1 word")
    .max(25)
    .required("Name is required"),
  email: validEmail,
  phone: Yup.string()
    .min(5, "Number must be greateer than 5 digits")
    .required("Enter Mobile Number"),
  incometype: Yup.string().required("Please select income tenure"),
  otherincometype: Yup.string().required("Please select other income tenure"),
  startdate: Yup.string().required("Enter the date"),
  income: Yup.string().required("Enter the income"),
});

export const VerificationSchema = Yup.object({
  companyname: Yup.string()
    .min(2, "Company Name must be greater than 1 word")
    .max(25)
    .required("Company Name is required"),
  role: Yup.string()
    .min(2, "Role must be greater than 1 word")
    .max(25)
    .required("Role is required"),
  name: Yup.string()
    .min(2, "Name must be greater than 1 word")
    .max(25)
    .required("Name is required"),
  email: validEmail,
  phone: Yup.string()
    .min(5, "Number must be greateer than 5 digits")
    .required("Enter Mobile Number"),
  incometype: Yup.string().required("Please select income tenure"),
  otherincometype: Yup.string().required("Please select other income tenure"),
  startdate: Yup.string().required("Enter the date"),
  income: Yup.string().required("Enter the income"),
});

export const questionnaireSchema = Yup.object({
  value1: Yup.string().required("Please select Value"),
  value2: Yup.string().required("Please select Value"),
  value3: Yup.string().required("Please select Value"),
  value4: Yup.string().required("Please select Value"),
});

export const commonValidationSchema = Yup.object({
  companyname: Yup.string()
    .min(2, "Company Name must be greater than 1 word")
    .max(25)
    .required("Company Name is required"),
  role: Yup.string()
    .min(2, "Role must be greater than 1 word")
    .max(25)
    .required("Role is required"),
  name: Yup.string()
    .min(2, "Name must be greater than 1 word")
    .max(25)
    .required("Name is required"),
  email: validEmail,
  incometype: Yup.string().required("Please select income tenure"),
  otherincometype: Yup.string().required("Please select other income tenure"),
  startdate: validDate,
  income: Yup.string().required("Enter the income"),
  date: validDate,
  phone: validAustralianMob,
  fname: validFname,
  lname: validLname,
});

//create Agency

export const AgencyPrincipalDetails = Yup.object({
  jobTitle: Yup.string().required().label("Job Title"),
  firstName: validFname,
  lastName: validLname,
  phone: validAustralianMob,
  email: validEmail,
});
export const UserVerificationSchema = Yup.object({
  primaryDocumentType: validDocType,
  primaryDocFirstName: validFname,
  primaryDocMidName: validOptionalMname,
  primaryDocLastName: validLname,
  primaryDocNumber: validDocNumber,
  primaryDocExpiry: Yup.string().required().label("Document Expiry"),
  secondaryDocumentType: validDocType,
  secondaryDocFirstName: validFname,
  secondaryDocMidName: validLname,
  secondaryDocLastName: validDocNumber,
  secondaryDocNumber: validDocNumber,
  secondaryDocExpiry: Yup.string().required().label("Document Expiry"),
});

export const LicenseDetailsSchema = Yup.object({
  license: Yup.string().required("License required"),
  corporateLicenseNumber: validNumber.label("License Number"),
  corporateLicenseNumberExpiry: Yup.string().required(
    "Expiry Date is required"
  ),
  expiryDate: Yup.string().required("Expiry Date is required"),
});

export const AgencyCompanyDetailsSchema = Yup.object({
  compnayName: validName,
  tradingName: validName,
  abnNum: validABN,
  acnNumber: validACN,
  tradingRegion: Yup.string().required().label("Trade state"),
  companyRegNumber: Yup.string().required().label("Company Address"),
  companyRegNumberStreetNumber: Yup.string().required().label("Street Number"),
  companyRegNumberStreetName: Yup.string().required().label("Street Name"),
  companyRegNumberSuburb: Yup.string().required().label("Suburb"),
  companyRegNumberPostCode: Yup.string().required().label("Postcode"),
  companyRegNumberState: Yup.string().required().label("State"),
});

export const AgencyInsuranceDetailsSchema = Yup.object({
  insuranceCompanyName: Yup.string().required().label("Company Name"),
  insurancePolicyNumber: Yup.string().required().label("Policy Number"),
  insurancePolicyExpiry: Yup.string().required().label("Policy Expiry Date"),
});

export const AgencyPaymentDetailsSchema = Yup.object({
  saleAccountName: Yup.string().required("Account Name is required"),
  saleAccountNumber: Yup.string().required().label("Account Number"),
  saleBsb: validBSB,
});

export const AgencyProfilePersonalDetailsSchema = Yup.object({
  firstName: validFname,
  lastName: validLname,
  phone: validAustralianMob,
  email: validEmail,
  jobTitle: Yup.string().required("jobTitle is required"),
  address: Yup.string().required("Address is required"),
  license: Yup.string().required("license is required"),
  expiryDate: Yup.string().required("expiryDate is required"),
});

export const AgencyProfileCompanyDetailsSchema = Yup.object({
  compnayName: Yup.string().required("Company Name is required"),
  companyRegNumber: Yup.string().required("Company Address is required"),
  tradingName: Yup.string().required("Trading Name is required"),
  acnNumber: validACN,
  abnNum: validABN,
  corporateLicenseNumber: Yup.string().required(
    "Corporate License Number is required"
  ),
  corporateLicenseNumberExpiry: Yup.string().required(
    "Corporate License Number Expiry is required"
  ),
  tradingRegion: Yup.string().required("Trading Region is required"),
  director: Yup.string().required("Director is required"),

  companyRegNumberStreetNumber: Yup.string().required().label("Street Number"),
  companyRegNumberStreetName: Yup.string().required().label("Street Name"),
  companyRegNumberSuburb: Yup.string().required().label("Suburb"),
  companyRegNumberPostCode: Yup.string().required().label("Postcode"),
  companyRegNumberState: Yup.string().required().label("State"),
});

export const AgencyProfileTrustAccountDetailsSchema = Yup.object({
  rentalBsb: validBSB,
  saleBsb: validBSB,
});

export const AgencyPersonalDetails = Yup.object({
  agentType: Yup.string().required("Please select Type"),
  fname1: Yup.string().required("First Name is required"),
  lname1: Yup.string().required("Last Name is required"),
  phone: validAustralianMob,
  email: validEmail,

});

export const AgentLicensingDetailsSchema = Yup.object({
  licenseType: Yup.string().required("Agent Type is required"),
  licenseNumber: Yup.string()
    .required("Number is required")
    .matches(/^[0-9]+$/, "Accept only numbers"),
  licenseExpirydate: Yup.string().required("Expiry Date is required"),
});

export const AgentExperienceSchema = Yup.object({
  yearOfExperience: Yup.object().required("Years of experience is required"),
  areaOfExpertise: Yup.array().required("Area of experience is required"),
  profileDesc: Yup.string().required("Profile Description is required"),
});

export const AgentContactDetailsSchema = Yup.object({
  reportTo: Yup.string().required("Report to is required"),
});

export const PropertyDetailsSchema = Yup.object({
  propertyType: Yup.string().required("Property Type is required"),
  address: Yup.string().required("Address  is required"),
  streetNumber: Yup.string().required("Street Number is required"),
  streetName: Yup.string().required("Street Name is required"),
  suburb: Yup.string().required("Suburb is required"),
  postCode: Yup.string().required("Postcode is required"),
  state:Yup.string().required("State is required"),

});

export const photosValidationSchema = Yup.object({
  propertyHeading: Yup.string().required("Property Heading is required"),
});

export const OwnershipSchema = Yup.object({
  phone: validOptionalAustralianMob,
  email: validOptionalEmail,
  firstName: Yup.string().required("First Name is required"),
});
export const MultiOwnershipSchema = Yup.object().shape({
  owners: Yup.array().of(
    Yup.object().shape({
      // firstName: Yup.string().required("First Name is required"),
      phone: validOptionalAustralianMob,
      email: Yup.string().email("Invalid email"),
      ownerShipAddress: Yup.string(),
      isPrimary: Yup.boolean(),
      contactUniqueId: Yup.string(),
      abn: optionalValidABN
    })
  ),
});

export const PropertyLegalSchema = Yup.object({
  sellerEmail: validOptionalEmail,
  buyerEmail: validOptionalEmail,
  buyerNumber: validOptionalAustralianMob,
  sellerNumber: validOptionalAustralianMob,
});

//Contact Schema

export const CompanyDetailsContactSchema = Yup.object({
  contactname: validName,
  acn: validACN,
  abn: validABN,
});

export const ContactDetailsSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  mobileNumber: validOptionalAustralianMob,
  email: validOptionalEmail,
});

export const ContactOthersSchema = Yup.object({
  spouseMobileNumber: validOptionalAustralianMob,
  spouseEmail: validOptionalEmail,
});

export const addNewUserOpenHomeValidation = Yup.object({
  firstName: Yup.string().required("First Name  is required"),
  ContactNo: validAustralianMob,
  email: validOptionalEmail,
});

export const AddOpenHomeSchema = Yup.object({
  property: Yup.string().required("Property for is required"),
  eventDate: Yup.string().required("EventDate is required"),
  //startTime: Yup.string().required("Start Time is required"),
  // endTime: Yup.string().required("End Time is required"),
});

export const addNewTask = Yup.object({
  assignedTo: Yup.string().required("Assignee is required"),
  DataTime: Yup.string().required("Due Date is required"),
  taskName: Yup.string().required("task Name is required"),
});

export const agreementPeriodValid = Yup.object({
  AgreementDate: Yup.string().required("Commencing On is required"),
});

export const feesAndChargesValid = Yup.object({
  InitialWeekRent: Yup.string().required("1 week Rent is required"),
  ManagementFee: Yup.string().required("Management Fee of is required"),
  MarketingFee: Yup.string().required("Marketing Fee of is required"),
  AdministrationFee: Yup.string().required(
    "An Administration Fee of is required"
  ),
});

export const tribunalFees = Yup.object({
  ApplicationFeesForUtilites: Yup.string().required(
    "Amount Per Month is required"
  ),
  OfficeExpenses: Yup.string().required("Amount is required"),
  PAccountName: Yup.string().required("A/C Name is required"),
  PBSB: validBSB,
  PAccountNo: Yup.string()
    .required("A/C No is required")
    .matches(/^[0-9]+$/, "A/C No must contain only numbers"),
});

export const tribunalFeesAgency = Yup.object({
  ApplicationFeesForUtilites: Yup.string().required(
    "Amount Per Month is required"
  ),
  //OfficeExpenses: Yup.string().required("Amount is required"),
});

export const prominantalActivities = Yup.object({
  ThirdPartyName: Yup.string().required("Name of Third party is required"),
  AmountOfRebateDiscount: Yup.string().required(
    "Estimated Amount of Rebate, Discount or Commission is required"
  ),
});

export const tenantAgreement = Yup.object({
  ThirdPartyName: Yup.string().required("Name of Third party is required"),
  AmountOfRebateDiscount: Yup.string().required(
    "Estimated Amount of Rebate, Discount or Commission is required"
  ),
});

export const managementAgreement = Yup.object({
  contactEmail: validEmail,
  fName: Yup.string().required("First Name is required"),
  lName: Yup.string().required("Last Name is required"),
  rent: Yup.number()
    .min(1, "Rent must be greater than 0")
    .required("Rent is required")
    .nullable(true),
  bondAmount: Yup.number()
    .min(1, "Bond must be greater than 0")
    .required("Bond is required")
    .nullable(true),
  mobileNumber: validAustralianMob,
  address: Yup.string().required("Address is required"),
  propertyUnfurnished: Yup.boolean()
    .test(
      "is-true",
      "Property Furnished is required",
      (value) => value === true
    )
    .required("Property Furnished is required"),
});

export const RepairsAndMaintainanceValidation = Yup.object({
  SupplyUrgentRepairToTenant: Yup.boolean().required("required"),
  LooseFillAsbestosInstallation: Yup.boolean().required("required"),
  SwimmingPoolRegistered: Yup.boolean().required("required"),
  SmokeAlarm: Yup.boolean().required("required"),
  HealthIssues: Yup.boolean().required("required"),
  Flooding: Yup.boolean().required("required"),
  Bushfire: Yup.boolean().required("required"),
  SeriousViolentCrime: Yup.boolean().required("required"),
  ParkingRestriction: Yup.boolean().required("required"),
  ShareDriveway: Yup.boolean().required("required"),
  AffectdFlooding: Yup.boolean().required("required"),
  AffectdBushfire: Yup.boolean().required("required"),
  AffectdSeriousViolent: Yup.boolean().required("required"),
  IsContractForSalePremises: Yup.boolean().required("required"),
  IsProposelToSell: Yup.boolean().required("required"),
  HasMortgageeCommenced: Yup.boolean().required("required"),
  isMortgageeTakgActnPossssion: Yup.boolean().required("required"),
  WaterEfficiencyByNSW: Yup.boolean().required("required"),
  PrincipalWarrantsSmokeAlarm: Yup.boolean().required("required"),
});

export const leaserent = Yup.object({
  BSB: validBSB,
  AccountName: Yup.string().required("Account Number is required."),
  AccountNo: Yup.string()
    .required("A/C Number is required")
    .matches(/^[0-9]+$/, "A/C Nummber must contain only numbers"),
  RentAmount: Yup.string().required("Rent is required."),
  Period: Yup.string().required("Period is required."),
});

export const propertyListingSale = Yup.object().shape({
  // Other field validations...
  listingStatus: Yup.string().required("Listing Status is required."),
  propertyPrice: Yup.string().required("Property Price is required"),
  leadAgent: Yup.string().required("Lead Agent is required."),
});

export const propertyListingRental = Yup.object().shape({
  // Other field validations...
  listingStatus: Yup.string().required("Listing Status is required."),
  leadAgent: Yup.string().required("Lead Agent is required."),
  rentPrice: Yup.string().required("Rental Price is required."),
  rentTenure: Yup.string().required("Rental Interval is required."),
});

export const rentDetails = Yup.object().shape({
  Rent: Yup.number().typeError("Rent must be a number"),
  BondAmount: Yup.number().typeError("Bond Amount must be a number"),
  BondInTrust: Yup.number().typeError("Bond In Trust must be a number"),
  Deposited: Yup.number().typeError("Deposited must be a number"),
  Balance: Yup.number().typeError("Balance must be a number"),
  RentReviewFrequency: Yup.number().typeError(
    "Rent Review Frequency must be a number"
  ),
});

export const salesAgreement = Yup.object({
  contactEmail: validEmail,
  fName: Yup.string().required("First Name is required"),
  lName: Yup.string().required("Last Name is required"),
  mobileNumber: Yup.string().required("Mobile Number is required"),
  address: Yup.string().required("Address is required"),
});

export const salesAgreementPeriod = Yup.object({
  AgreementStartDate: Yup.string().required("From Date is required"),
  AgreementEndDate: Yup.string().required("To Date is required"),
  FixFitIncluded: Yup.string().required("Fix Fit Included is required"),
  FixFitExcluded: Yup.string().required("Fix Fit Excluded is required"),
  SellingPriceFrom: Yup.number()
    .typeError("Selling Price From must be a number")
    .required("Selling Price From is required"),
  SellingPriceTo: Yup.number()
    .typeError("Selling Price To must be a number")
    .required("Selling Price To is required")
    .test(
      "is-10-percent-more",
      "Selling Price To can be up to 10% greater than Selling Price From but not more than 10%",
      function (value) {
        const { SellingPriceFrom } = this.parent;
        return value <= SellingPriceFrom * 1.1 + 0.01; // adding a small tolerance
      }
    ),
  SellingFixedPrice: Yup.number()
    .required("Price at which the Property is to be offered is required")
    .min(0.01, "Price should be greater than 0"),
  LicenceeRecomendMethodOfSale: Yup.string().required(
    "Licensee’s Recommendation as to the Most Suitable Method of Sale required"
  ),
});


export const salesRemunation = Yup.object({
  SalePriceFlatFee: Yup.number()
    .typeError("Sale price must be a number")
    .required("Sale Price Flat Fee is required"),
  LicenceeEstimateSalePrice: Yup.number()
    .typeError("Licensee Estimate must be a number")
    .required("Licensee Estimate Sale Price is required"),
  // GSTInclusiveRenumeration: Yup.number().typeError("GST Inclusive must be a number").required("GST Inclusive Renumeration is required"),
  // PBSB:validBSB
});

export const tenantInformation = Yup.object({
  MaximumTenant: Yup.string().required("No more than is required"),
  SmokeAlarmType: Yup.number()
    .required("Please select a smoke alarm type")
    .oneOf([1, 2], "Please select a smoke alarm type"),
});

export const uplodeFileManagement = Yup.object({
  AgreementFilePath: Yup.string()
    .required("File is required"),
  MarketingFee: Yup.number()
    .typeError("Marketing Fee must be a number")
    .required("Marketing Fee is required"),
  AdministrationFee: Yup.number()
    .typeError("Administration Fee must be a number")
    .required("Administration Fee is required"),
  InitialWeekRent: Yup.number()
    .typeError("Initial Week Rent must be a number")
    .required("Initial Week Rent is required"),
  AgreementDate: Yup.string()
    .required("Agreement Start Date is required"),
  ApplicationFeesForUtilites: Yup.number()
    .typeError("Admin Fee must be a number")
    .required("Admin Fee is required"),
  ManagementFee: Yup.number()
    .typeError("Management Fee must be a number")
    .required("Management Fee is required"),
});


export const uplodeFileTenant = Yup.object({
  AgreementFilePath: Yup.string()
    .required("File is required"),
  RentAmount: Yup.number()
    .typeError("Rent Amount must be a number")
    .required("Rent Amount is required"),
  AgreementStart: Yup.string()
    .required("Start Date is required"),
  AgreementEnd: Yup.string()
    .required("End Date is required"),
  TermsAgreement: Yup.string()
    .required("Terms Agreement is required"),
  Period: Yup.string()
    .required("Period is required"),
});


export const uplodeFileSale = Yup.object({
  AgreementFilePath: Yup.string().required("File is required"),
  PropertyPrice: Yup.number()
    .typeError("Property Price must be a number")
    .required("Property Price is required"),
  CommissionPercentage: Yup.number()
    .typeError("Commission Percentage must be a number")
    .required("Commission Percentage is required"),
  CommissionAmount: Yup.number()
    .typeError("Commission Amount must be a number")
    .required("Commission Amount is required"),
  MarketingFee: Yup.number()
    .typeError("Marketing Fee must be a number")
    .required("Marketing Fee is required"),
  ManagementFee: Yup.number()
    .typeError("Management Fee must be a number")
    .required("Management Fee is required"),
  AdministrationFee: Yup.number()
    .typeError("Administration Fee must be a number")
    .required("Administration Fee is required"),
  AgreementStartDate: Yup.string().required("Agreement Start Date is required"),
  AgreementEndDate: Yup.string().required("Agreement End Date is required"),
  ExpenseANDChargesEstimate1: Yup.number()
    .typeError("Marketing Fee must be a number")
    .required("Marketing Fee is required"),
  SellingFixedPrice: Yup.number()
    .typeError("Price must be a number")
    .required("Price is required"),
  SalePriceFlatFee: Yup.number()
    .typeError("Commission % must be a number")
    .required("Commission % is required"),
  GSTInclusiveRenumeration: Yup.number()
    .typeError("Commission amount must be a number")
    .required("Commission amount is required"),
});


export const ManagementAgentAgreement = Yup.object({
  businessName: Yup.string().required("Business Name is required"),
  agencyLicenceNumber: Yup.string().required(
    "Agency Licence Number is required"
  ),
  agencyName: Yup.string().required("Agency Name is required"),
  agencyEmail: Yup.string().required("Agency Email is required"),
  address: Yup.string().required("Address is required"),
});

export const ManagementViewPage = Yup.object({
  PAccountName: Yup.string().required("A/C Name is required"),
  PBSB: validBSB,
  PAccountNo: Yup.string()
    .required("A/C No is required")
    .matches(/^[0-9]+$/, "A/C No must contain only numbers"),
  supplyUrgentRepairToTenant: Yup.boolean().required("required"),
  looseFillAsbestosInstallation: Yup.boolean().required("required"),
  swimmingPoolRegistered: Yup.boolean().required("required"),
  smokeAlarm: Yup.boolean().required("required"),
  healthIssues: Yup.boolean().required("required"),
  flooding: Yup.boolean().required("required"),
  bushfire: Yup.boolean().required("required"),
  seriousViolentCrime: Yup.boolean().required("required"),
  parkingRestriction: Yup.boolean().required("required"),
  shareDriveway: Yup.boolean().required("required"),
  affectdFlooding: Yup.boolean().required("required"),
  affectdBushfire: Yup.boolean().required("required"),
  affectdSeriousViolent: Yup.boolean().required("required"),
  isContractForSalePremises: Yup.boolean().required("required"),
  isProposelToSell: Yup.boolean().required("required"),
  hasMortgageeCommenced: Yup.boolean().required("required"),
  isMortgageeTakgActnPossssion: Yup.boolean().required("required"),
  waterEfficiencyByNSW: Yup.boolean().required("required"),
  principalWarrantsSmokeAlarm: Yup.boolean().required("required"),
});

export const TenantContactSchema = Yup.object({
  fname1: Yup.string().required("First Name is required"),
  email: validEmail,
  mobileNumber: validOptionalAustralianMob,
});

export const repairsandmaintainance = Yup.object({
  TradesmanPayment: Yup.string().required("Payment is required"),
});