import React,{useEffect, useState} from 'react'
import './footernew.css'
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

const FooterNew = () => {
const navigate = useNavigate();
const [userDatas , setUserData] = useState({})
    useEffect(() => {
        const retrieveData = async () => {
          try {
            const userData = GetCookie("@UserData");
    
            if (userData) {
              const parsedData = JSON.parse(userData);
              setUserData(parsedData)
            }
          } catch (error) {
            console.error("Error retrieving data from AsyncStorage:", error);
          }
        };
        retrieveData();
      }, []);


      const handleAgency = () => {
        // Check if userDatas and userProfiles are defined
        if (userDatas !== undefined && userDatas.userProfiles !== undefined) {
            // Use some to check if any userRoleId is equal to 2
            const userRoleIds = userDatas.userProfiles.some(item => item.userRoleId === 2);
            console.log(userRoleIds, 'userRoleIds');
            if (userRoleIds) {
                navigate("/agency/Dashboard");
            }else{
                navigate('/selection')
            }
        } else {
            navigate('/signin')
            console.error('userDatas or userProfiles is undefined');
        }
    };
    

  return (
    <footer className="footer-sections">
        <div className="footer-content">
          <div className="footer-column">
            <div className="footer-logo">
              <img src="/assets/images/iconlogo.png" alt="Foreal Property Logo" style={{ width: '120px' }} />
            </div>
            <div className="footer-column mt-4">
            <span>Adderss : 513/7 Maitland Place Norwest NSW 2153</span>
          </div>
          <div className="footer-column mt-4">
            <span>Phone No : 02 7804 2863</span>
          </div>
          <div className="footer-column mt-4">
            <span>Email : pm@forealproperty.com.au</span>
          </div>
          </div>
          {/* <div className="footer-column">
            <h4>BUY</h4>
            <ul>
              <li>Residential Property</li>
              <li>Commercial Property</li>
              <li>Events</li>
              <li>Find Agent</li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>LOGIN</h4>
            <ul>
              <li>Agency</li>
              <li>Agent</li>
              <li>Employee</li>
              <li>Affiliate</li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>SELL</h4>
            <ul>
              <li>Find Agent</li>
              <li>Appraisal</li>
              <li>Recent Sales</li>
            </ul>
          </div> */}
         
          <div className="footer-column contact-us-column">
            <h4>CONTACT US</h4>
            <form>
              <div className="form-group">
                <input type="text" placeholder="Name" />
                <input type="email" placeholder="Email" />
              </div>
              <textarea placeholder="Description"></textarea>
              <Button type="primary" className="submit-button">
                Submit
              </Button>
            </form>
          </div>
        </div>
        <div className="footer-bottom">
          <p>
            © Copyright 2022, All Rights Reserved by FOREAL PROPERTY
          </p>
          <div className="footer-icons">
            <i className="fa fa-facebook"></i>
            <i className="fa fa-twitter"></i>
            <i className="fa fa-instagram"></i>
          </div>
        </div>
      </footer>
  )
}

export default FooterNew