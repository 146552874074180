import React from 'react';
import { Card, Row, Col, Table } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import SubNav from '../../../navbar/subNav';

// Data for charts and tables
const marketingData = [
    { name: 'Jan', value: 1000 },
    { name: 'Feb', value: 2000 },
    { name: 'Mar', value: 1500 },
    { name: 'Apr', value: 2500 },
    { name: 'May', value: 3200 },
    { name: 'Jun', value: 2900 },
    { name: 'Jul', value: 3400 },
    { name: 'Aug', value: 3000 },
];

const salesData = [
    { name: 'Jan', lorem: 2000, ipsum: 2400 },
    { name: 'Feb', lorem: 3000, ipsum: 1398 },
    { name: 'Mar', lorem: 2000, ipsum: 2800 },
    { name: 'Apr', lorem: 2780, ipsum: 3908 },
    { name: 'May', lorem: 1890, ipsum: 4800 },
    { name: 'Jun', lorem: 2390, ipsum: 3800 },
    { name: 'Jul', lorem: 3490, ipsum: 4300 },
];

const revenueData = [
    { name: 'USA', value: 39.11 },
    { name: 'Canada', value: 28.02 },
    { name: 'UK', value: 23.13 },
    { name: 'Australia', value: 5.03 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const recentSalesData = [
    { key: '1', orderId: '#0028', date: '1/15/12', customer: 'Jane Cooper', price: '$500k', agent: 'Jacob Jones', status: 'Pending' },
    { key: '2', orderId: '#0029', date: '5/7/16', customer: 'Jenny Wilson', price: '$123k', agent: 'Jenny Wilson', status: 'Negotiating' },
    { key: '3', orderId: '#0030', date: '1/15/12', customer: 'Floyd Miles', price: '$49k', agent: 'Kristin Watson', status: 'Pending' },
];

const columns = [
    { title: 'Order ID', dataIndex: 'orderId', key: 'orderId' },
    { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'Customer', dataIndex: 'customer', key: 'customer' },
    { title: 'Price', dataIndex: 'price', key: 'price' },
    { title: 'Agent', dataIndex: 'agent', key: 'agent' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
];

// Main Dashboard Page
const AgentDashboard = () => {
  const data = [
    { name: 'Jan', value: 400 },
    { name: 'Feb', value: 300 },
    { name: 'Mar', value: 500 },
    { name: 'Apr', value: 600 },
    { name: 'May', value: 700 },
  ];

  // Reusable Card Design for the dashboard cards
  const renderCard = (title, value, percentage, direction, color, icon) => {
    return (
      <Card>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
         <ResponsiveContainer width="100%" height={50}>
          <LineChart data={data}>
            <Tooltip />
            <Line type="monotone" dataKey="value" stroke="#008080" strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
          <div>
            <img
              src={icon} // Replace this with your actual icon path or use a different icon component
              alt="Icon"
              style={{
                backgroundColor: '#E6F7F2',
                borderRadius: '8px',
                padding: '10px',
                width: '40px',
                height: '40px',
              }}
            />
          </div>
        </div>
        <div>
            <div style={{ fontSize: '16px', color: '#000' }}>{title}</div>
            <div style={{ fontSize: '30px', fontWeight: 'bold' }}>{value}</div>
            <div style={{ display: 'flex', alignItems: 'center', color: color }}>
              {direction === 'up' ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
              &nbsp;<span>{percentage} vs Last Month</span>
            </div>
          </div>
       
      </Card>
    );
  };

  return (
    <div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
          <SubNav title={"Agent Dashboard"} goback={false} extraData={true} />
          <div className="material-work-desk-new bg-white m-3">
            <div style={{ padding: '20px' }}>
              {/* Stat Cards Section */}
              <Row gutter={16}>
                <Col span={6}>
                  {renderCard("My Applications", "63", "9.0%", "up", "green","/assets/images/suitcase.svg")}
                </Col>
                <Col span={6}>
                  {renderCard("My Applications", "27", "7.0%", "down", "red","/assets/images/brick.svg")}
                </Col>
                <Col span={6}>
                  {renderCard("My Applications", "45", "10.0%", "up", "green","/assets/images/addUser.svg")}
                </Col>
                <Col span={6}>
                  {renderCard("My Applications", "81", "9.0%", "up", "green","/assets/images/box.svg")}
                </Col>
              </Row>

              {/* Charts Section */}
              <Row gutter={16} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Card title="Marketing Campaign" extra="1 Month">
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart data={marketingData}>
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Line type="monotone" dataKey="value" stroke="#82ca9d" strokeWidth={2} />
                      </LineChart>
                    </ResponsiveContainer>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card title="Sales Data" extra="1 Month">
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart data={salesData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="lorem" stroke="#8884d8" strokeWidth={2} />
                        <Line type="monotone" dataKey="ipsum" stroke="#82ca9d" strokeWidth={2} />
                      </LineChart>
                    </ResponsiveContainer>
                  </Card>
                </Col>
              </Row>

              {/* Recent Sales and Revenue Section */}
              <Row gutter={16} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Card title="Recent Sales" extra="1 Month">
                    <Table dataSource={recentSalesData} columns={columns} pagination={false} />
                  </Card>
                </Col>
                <Col span={12}>
                  <Card title="Revenue" extra="2024">
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie data={revenueData} cx="50%" cy="50%" outerRadius={120} fill="#8884d8" dataKey="value">
                          {revenueData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentDashboard;
