import React, { useMemo, useRef, useState, useEffect } from "react";
import Header from "../../../components/CommonSection/Header/Header";
import debounce from "lodash/debounce";
import { BiSearch, BiPlus } from "react-icons/bi";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import { getinspectionspagedlist, SearchProperty, assignedTo, addinspection } from "../../../components/CommonSection/apiresources";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { GetCookie ,currentProfile} from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
//import "../../Dashboard-agent/AgentList.css";
//import "./propertiesagency.css";
import { SelectButton } from "primereact/selectbutton";
import { ActionStatus } from "../../../Utilities/enum";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import Sidebar from "../../../components/CommonSection/SideBar/sideBar"
import { Paginator } from 'primereact/paginator';
import { Select, Spin } from "antd";
import CustomModal from "../../input-components/Modal";
import TextBox from "../../input-components/textbox";
import AddressInput from "../../input-components/address";
import { useFormik } from "formik";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import SelectBox from "../../input-components/selectBoxNew"
import { Calendar } from 'primereact/calendar';
import { FormatDate } from "../../../components/CommonSection/Utility/Utility";
import TextArea from '../../input-components/textarea';
import moment from 'moment';
import SubNav from "../../../components/CommonSection/Header/subHeader";
import AntdTableExample from "../../input-components/dataTable";

const initialValues = {
    assignedTo: "",
    address: "",
    date: "",
    type: "",
    propertyManager: "",
    startTime: "",
    endTime: "",
    summary: ""
};

const ListInspection = () => {
    const [inspectionLists, setInspectionLists] = useState([]);
    const [visiblet, setVisiblet] = useState(true);
    const [userId, setUserId] = useState("");
    const [agencyID, setAgencyId] = useState("");
    const [chartData, setChartData] = useState();
    const navigate = useNavigate();
    const ToastRef = useRef();
    const [propertyType, setPropertyType] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [purpose, setPurpose] = useState("");
    const dataTableRef = useRef(null);
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [activeTopic, setActiveTopic] = useState("ListInspection");
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchValue, setSearchValues] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [openInspection, setOpenInspection] = useState(false);
    const [address, setAddress] = useState("");
    const [agencyUniqueID, setAgencyUniqueID] = useState(false);
    const [selectedCity, setSelectedCity] = useState(null);
    const [dpdata, setDpdata] = useState([]);
    const [tenantFolioId, setTenantFolioId] = useState(null);
    const currentUser = currentProfile.get();
    
    const typeOptions = [
        { label: "Rountine", value: 1 },
        { label: "Entry", value: 2 },
        { label: "Exit", value: 3 },
    ];
    const fetchRef = useRef(0);
    const propertyTypeitems = [
        { name: "All", value: null },
        { name: "Sale", value: 1 },
        { name: "Rental", value: 2 },
    ];
    const propertyTypeChangeHandler = (value) => {
        setPropertyType(value);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setUserId(parsedData.userId);
                    setAgencyId(parsedData.agencyID);
                    PostApiWithSpinner(parsedData.agencyID,currentUser === 13 ? parsedData.tenantFolioId: null)
                    setTenantFolioId(parsedData.tenantFolioId)
                    setAgencyUniqueID(parsedData.agencyUniqueID)
                }
            } catch (error) {
                console.error("Error retrieving data from AsyncStorage:", error);
            }
        };
        retrieveData();
    }, [userId, searchText, searchValue, purpose, propertyType]);

    const reportData = (agencyUID) => {
        const apiUrl = assignedTo;
        const baseUrl = apiUrl + "?agencyUID=" + agencyUID;
        axios.get(baseUrl).then((response) => {
            if (response.data.object) setDpdata(response.data.object);
        });
    };

    const PostApiWithSpinner = (agencyID,tenantid) => {
        const baseUrl = getinspectionspagedlist;
        let data = {
            AgencyId: agencyID,
            Search: searchText,
            TenantFolioId:tenantid
        };
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    // let propertyData = response.list;
                    const propertyData = response.list || [];
                    let propertyList = propertyData.map((property) => ({
                        Address: property.propertyAddress,
                        InspectionType: property.sInspectionType,
                        InspectionDate: FormatDate(property.inspectionDate),
                        StartTime: moment(property.startTime).format('hh:mm A'),
                        EndTime: moment(property.endTime).format('hh:mm A'),
                        AssignedAgent: property.sAssignedAgent,
                        InspectionUniqueId: property.inspectionUniqueId
                    }));
                    setInspectionLists(propertyList);
                    setTotalPages(response.totalCount)
                    setChartData(response.object);
                } else {
                    alert(`Message: ${response.message}`);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleTopicValue = (text) => {
        dynamicUrl(navigate, text);
        setActiveTopic(text);
    };

    const handleAddAgentClick = () => {
        navigate("/agency/AddInspectionMaster");
    };

    const getStatusClassName = (status) => {
        switch (status) {
            case "No":
                return "grey-status";
            case "Yes":
                return "green-status";
            default:
                return "";
        }
    };


    const handleRowClick = (event, rowData) => {
        dynamicUrl(navigate, "ViewInspection" + "?InspectionUniqueId=" + rowData.InspectionUniqueId + '&inspectionType=' + rowData.InspectionType);
    };


    const handlePageChange = (event, pageNumber) => {
        PostApiWithSpinner(event.page + 1, event.rows)
        setCurrentPage(pageNumber);
        setFirst(event.first);
        setRows(event.rows);
    };

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            const baseUrl = SearchProperty;
            const data = {
                Search: value,
                RecordsPerPage: 10,
                PageNo: 1,
            };

            makeRequestWithSpinner(
                showSpinner,
                hideSpinner,
                baseUrl,
                "post",
                data,
                "",
                false
            )
                .then((response) => {
                    if (response.status === 1) {
                        let landingPageListData = response.list;

                        // Transform data to match Select options
                        const transformedData = landingPageListData.map((item) => ({
                            value: item.id,
                            label: item.name,
                        }));

                        // Update options and fetching state
                        if (fetchId === fetchRef.current) {
                            setOptions(transformedData);
                            setFetching(false);
                        }
                    } else {
                        setFetching(false);
                    }
                })
                .catch((error) => {
                    setFetching(false);
                });
        };
        return debounce(loadOptions, 800);
    }, []);

    const handleSearchProperty = (value) => {
        debounceFetcher(value);
        setSearchValues(value.value);
        setFieldValue("address", value.value);
    };

    const handleClearProperty = () => {
        setSearchValues("");
    };

    const handleAddInspection = () => {
        setOpenInspection(true);
        setFieldValue('assignedTo', '')
        setFieldValue('address', '')
        setFieldValue('date', '')
        setFieldValue('type', '')
        setFieldValue('propertyManager', '')
        setFieldValue('startTime', '')
        setFieldValue('endTime', '')
        setFieldValue('summary', '')
        reportData(agencyUniqueID)
    }

    const closeInspection = () => {
        setOpenInspection(false);
    }

    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        setFieldValue,
    } = useFormik({
        initialValues,
        //validationSchema: PropertyDetailsSchema,
        onSubmit: (values) => {
            handleFinalInspection(values);
        },
    });


    const handleAddressSelect = async (address) => {
        try {
            const results = await geocodeByAddress(address);
            const details = results[0];
            const fullFormattedAddress = details.formatted_address;
            //const addressComponents = extractAddressComponents(details);
            setAddress(fullFormattedAddress);
            setFieldValue("address", fullFormattedAddress);
            setValues({ ...values, address: fullFormattedAddress });
        } catch (error) {
            console.error("Error fetching address details:", error);
        }
    };


    const handleFinalInspection = () => {
        let finalData = {
            CreatedBy: parseInt(userId),
            InspectionType: parseInt(values.type),
            PropertyId: parseInt(values.address),
            InspectionDate: values.date,
            StartTime: moment(values.startTime).format('hh:mm A'),
            EndTime: moment(values.endTime).format('hh:mm A'),
            Summary: values.summary,
            AssignedAgent: parseInt(values.propertyManager),
            AssignedTo: values.assignedTo,
            AgencyId: parseInt(agencyID)
        }
        const url = addinspection;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", finalData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    setOpenInspection(false);
                    PostApiWithSpinner(agencyID, currentUser === 13 ? tenantFolioId : null);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };



    const onInputChange = (e) => {
        setSearchText(e.target.value);
    };

    return (
        <div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
                    <SubNav
                        title={"Inspection"}
                        actionButton={false}
                        extraData={true}
                        goback={false}
                        extraContent={
                            <div className="property-details-buttons">
                            {currentUser !== 13 ?
                               <div>
                               <button
                                    className="Add-Property-button"
                                    type="submit"
                                    onClick={handleAddAgentClick}
                                >
                                    <div className="text-parent">
                                        <BiPlus style={{ width: "25px", height: "25px" }} />
                                        <div className="text7" style={{ fontSize: "14px" }}>
                                            Add Master
                                        </div>
                                    </div>
                                </button>
                                <button
                                    className="Add-Property-button"
                                    type="submit"
                                    onClick={handleAddInspection}
                                    style={{ width: "175px" }}
                                >
                                    <div className="text-parent">
                                        <BiPlus style={{ width: "25px", height: "25px" }} />
                                        <div className="text7" style={{ fontSize: "14px" }}>
                                            Add Inspection
                                        </div>
                                    </div>
                                </button>
                               </div> : null }
                            </div>
                        }

                    />
                    <div className="material-work-desk-new bg-white m-3">
                        <ToastAlert ref={ToastRef} />
                           <div style={{ margin: '10px' }}>
                                  <Select
                                            style={{ width:300 }}
                                              className="landingPageSearchs mr-2"
                                              mode="single"
                                              showSearch
                                              placeholder="Search for Property"
                                              labelInValue
                                              filterOption={false}
                                              onSearch={debounceFetcher}
                                              notFoundContent={fetching ? <Spin size="small" /> : null}
                                              options={options}
                                              onChange={handleSearchProperty}
                                              allowClear
                                              onClear={handleClearProperty}
                                            />
                                          
                                  </div>
                        <AntdTableExample
                            label="List Inspection"
                            dataSource={inspectionLists}
                            globalSearch
                            hideCol={"InspectionUniqueId"}
                            onPageChange={handlePageChange}
                            currentPage={currentPage}
                            totalRows={rows}
                            totalRecords={totalPages}
                            onRowClick={handleRowClick}
                        />

                        <CustomModal
                            title="Add Inspection"
                            width={650}
                            closable={closeInspection}
                            visible={openInspection}
                            onCancel={closeInspection}
                            modalData={
                                <div className="row" style={{ marginTop: "30px", marginLeft: "10px" }}>
                                    <div className={`lda-${name} col-lg-6 col-sm-12`}>
                                        <label className="s1">
                                            Property <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <div>
                                            <Select
                                                style={{ width: '100%' }}
                                                className="landingPageSearchs"
                                                mode="single"
                                                showSearch
                                                placeholder="Search for Property"
                                                labelInValue
                                                filterOption={false}
                                                onSearch={debounceFetcher}
                                                notFoundContent={fetching ? <Spin size="small" /> : null}
                                                options={options}
                                                onChange={handleSearchProperty}
                                                allowClear
                                                onClear={handleClearProperty}
                                            />
                                            {errors.address && touched.address ? (
                                                <p className="form-error">{errors.address}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                    <SelectBox
                                        isMandatory
                                        label="Type"
                                        name="type"
                                        options={typeOptions}
                                        value={values.type}
                                        onChange={(val) => setFieldValue("type", val.value)}
                                    />
                                    <TextBox
                                        label="Date"
                                        isMandatory
                                        type="date"
                                        name="date"
                                        placeholder="dd/mm/yyyy"
                                        value={FormatDate(values.date)}
                                        onChange={(e) => setFieldValue("date", e.target.value)}
                                        onBlur={handleBlur}
                                        error={errors.date}
                                        touched={touched.date}
                                    />
                                    <div className="cda-reportTo col-lg-6 col-sm-12">
                                        <label htmlFor="propertyManager" className="s1 w-100">
                                            Assigned To<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <Dropdown
                                            value={values.propertyManager}
                                            onChange={(e) => setFieldValue("propertyManager", e.value)}
                                            options={dpdata}
                                            optionLabel="text"
                                            className="w-100 md:w-14rem"
                                            placeholder="Assigned To"
                                            style={{
                                                width: "100%",
                                                height: "40px",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        />
                                        {errors.propertyManager && touched.propertyManager && (
                                            <p className="form-error">{errors.propertyManager}</p>
                                        )}
                                    </div>
                                    <div className="cda-reportTo col-lg-6 col-sm-12">
                                        <label htmlFor="startTime" className="s1 w-100">
                                            Start Time<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <Calendar
                                            style={{ width: '100%', height: '40px' }}
                                            id="calendar-start-time"
                                            value={values.startTime}
                                            onChange={(e) => {
                                                setFieldValue("startTime", e.value);

                                                // Reset endTime if it becomes invalid
                                                if (values.endTime && moment(e.value).add(15, 'minutes').isAfter(moment(values.endTime))) {
                                                    setFieldValue("endTime", null);
                                                }
                                            }}
                                            timeOnly
                                            hourFormat="12"
                                        />
                                    </div>

                                    <div className="cda-reportTo col-lg-6 col-sm-12">
                                        <label htmlFor="endTime" className="s1 w-100">
                                            End Time<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <Calendar
                                            style={{ width: '100%', height: '40px' }}
                                            id="calendar-end-time"
                                            value={values.endTime}
                                            onChange={(e) => {
                                                const startTime = moment(values.startTime);
                                                const selectedEndTime = moment(e.value);

                                                if (startTime.isValid() && selectedEndTime.isBefore(startTime.add(15, 'minutes'))) {
                                                    // Show an error message or reset the endTime
                                                    ToastRef.current.notify(["error", "End time must be at least 15 minutes after start time"]);
                                                } else {
                                                    setFieldValue("endTime", e.value);
                                                }
                                            }}
                                            timeOnly
                                            hourFormat="12"
                                        />
                                    </div>



                                    <TextArea value={values.summary} name="summary" label="Summary" error={errors.summary} touched={touched.summary} onChange={(e) => setFieldValue("summary", e.target.value)} />
                                </div>
                            }
                            footer={[
                                <div
                                    key="footer"
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <button
                                        style={{ marginRight: "20px" }}
                                        className="pdb-save-changes mt-2"
                                        onClick={handleFinalInspection}
                                    >
                                        Submit
                                    </button>
                                </div>,
                            ]}
                        />

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListInspection;
