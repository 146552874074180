// Import all necessary components
import {currentProfile} from "../components/CommonSection/Cookie/Session"
import Signin from "../components/CommonSection/SignIn/Signin";
import Temp from "../components/temp/Temp";
import SignUp from "../components/CommonSection/SignUp/SignUp";
import ForgotPassword from "../components/CommonSection/ForgotPassword/ForgotPassword";
import Check from "../components/CommonSection/Check_Email/Check";
import PrivateRoutes from "./PrivateRoutes";
import Selection from "../components/CommonSection/Selection/Selection";
import AddProperty from "../components/Landlord/AddProperty/AddProperty";
import Verify from "../components/Landlord/Verify/Verify";
import Ownership from "../components/Landlord/Ownership/Ownership";
import Addonwer from "../components/Landlord/AddMoreOwners/Addowner";
import Completetion_np from "../components/Landlord/Completion-newproperty/Completion_np";
import Verifytenant from "../components/TenantSection/VerifyTenant/Verifytenant";
import AddressHistory from "../components/TenantSection/AddressHistory/AddressHistory";
import PropertyDetails from "../components/TenantSection/PropertyDetails/PropertyDetails";
import Employment from "../components/TenantSection/Employment/Employment";
import Questionnaire from "../components/TenantSection/Questionnaire/Questionnaire";
import Dashboard from "../components/Dashboard/Agency/Dashboard-agency/DashboardAgency";
import Documentation from "../components/TenantSection/Documentation/Documentation";
import Consent from "../components/TenantSection/Consent/Consent";
import PrincipalDetails from "../components/AgencySection/PrincipalDetails/PrincipalDetails";
import LicenseDetails from "../components/AgencySection/LicenseDetails/LicenseDetails";
import AddCompanyDetails from "../components/AgencySection/AddCompanyDetails/AddCompanyDetails";
import InsuranceDetails from "../components/AgencySection/InsuranceDetails/InsuranceDetails";
import PaymentDetails from "../components/AgencySection/PaymentDetails/PaymentDetails";
import VerifyAgency from "../components/AgencySection/VerifyAgency/VerifyAgency";
import ApproveAgency from "../components/AgencySection/ApproveAgency/ApproveAgency";
import SetNewPassword from "../components/CommonSection/SetNewPassword/SetNewPassword";
import PasswordResetConfirmation from "../components/CommonSection/PasswordResetConfirmation/PasswordResetConfirmation";
import Statuspage from "../components/AgencySection/StatusPage/Statuspage";
import AgencyCompletion from "../components/AgencySection/AgencyCompletion/AgencyCompletion";
import DashboardAgency from "../components/Dashboard/Agency/Dashboard-agency/DashboardAgency";
import LandingPage from "../components/CommonSection/LandingPage/LandingPage";
import PropertiesAgency from "../components/Dashboard/Agency/PropertiesAgecny/PropertiesAgency";
import Profile from "../components/Dashboard/Profile/Profile";
import Example from "../components/temp/Example";
import AddAgent from "../components/Dashboard/Dashboard-agent/AddAgent";
import AgentList from "../components/Dashboard/Dashboard-agent/AgentList";
import EmailVerificationAgent from "../components/CommonSection/EmailVerificationAgent/EmailVerificationAgent";
import PersonalDetailsProfile from "../components/Dashboard/Profile/PersonalDetailsProfile";
import ListAllProperties from "./view/PropertyListings/propertyListings";
import AddNewProperty from "./view/PropertyListings/addNewProperty";
import AddNewPropertiesListings from "./view/PropertyListings/addNewPropertyListing";
import PropertiesList from "./view/PropertiesAgency/propertyList";
import PropertiesAttributes from "./view/PropertiesAgency/propertyAttributes";
import PropertiesLegal from "./view/PropertiesAgency/propertyLegal";
import PropertiesListings from "../src/view/PropertiesAgency/propertyListings";
import PropertiesDocuments from "./view/PropertiesAgency/propertyDocuments";
import PropertiesOwnerShip from "../src/view/PropertiesAgency/propertyOwnerShip";
import PropertiesPhoto from "./view/PropertiesAgency/propertyPhoto";
import AddProfile from "../components/CommonSection/Selection/AddProfile";
import SelectAddProfile from "../components/CommonSection/Selection/SelectAddProfile";
import ListLandingPage from "../components/CommonSection/LandingPage/listDetails";
import ContactList from "../src/view/Contacts/contactList";
import Contacts from "../src/Components/Areas/Agency/Contact/contacts";
import ContactDetails from "../src/Components/Areas/Agency/Contact/contacts";
import ContactPrefference from "../src/view/Contacts/contactPrefference";
import ContactOthers from "../src/Components/Areas/Agency/Contact/contactOthers";
import LandingPageDetails from "../components/CommonSection/LandingPage/detailsPage";
import Header from "../components/CommonSection/Header/Header";
import AgentDashboard from "./Components/Areas/Agent/AgentDashboard";
import AgentProfile from "./Components/Areas/Agent/AgentProfile";
import ListOpenHome from "./view/OpenHome/listOpenHome";
import AddOpenHome from "./view/OpenHome/addOpenHome";
import OpenHomeDetails from "../src/Components/openHome/openHomeDetails";
import AddNewUserOpenHome from "./view/OpenHome/addNewUser";
import ListPortalEnquiry from "./view/PortalEnquiry/listPortalEnquiry";
import ListActivities from "./view/Activities/MainActivities";
import ListTask from "../src/view/Task/listTask";
import AddTask from "../src/view/Task/addTask";
import TaskDetails from "./view/Task/taskDetails";
import ListAgreement from "./Components/ManagementAgreement/MainListingPage";
import ManagementAgreement from "./Components/ManagementAgreement/managemantAgreement";
import ManagementAgreementTabs from "./Components/ManagementAgreement/agreementTabs";
import AddNewPropertyAgreement from "./Components/ManagementAgreement/addNewProperty";
import AgentAgreement from "./Components/ManagementAgreement/agentAgreement";
import AgreementPeriod from "./Components/ManagementAgreement/agreementPeriod";
import FeesAndCharges from "./Components/ManagementAgreement/feesAndCharges";
import TribunalFees from "./Components/ManagementAgreement/TribunalFees";
import PromotionalActivities from "./Components/ManagementAgreement/PromotionalActivities";
import RepairAndMaintainance from "./Components/ManagementAgreement/RepairsAndMaintenance";
import AddNewOwnerShipAgreement from "./Components/ManagementAgreement/AddNewOwnerShip";
import AgreementSignature from "./Components/ManagementAgreement/signature";
import AgreementSummary from "./Components/ManagementAgreement/summary";
import OwnerListAgreement from "./Components/ManagementAgreement/OwnwerAgreement/ListAgreementType";
import OwnerManagementAgreement from "./Components/ManagementAgreement/OwnwerAgreement/managemantAgreement";
import OwnerManagementAgreementTabs from "./Components/ManagementAgreement/OwnwerAgreement/agreementTabs";
import OwnerAddNewPropertyAgreement from "./Components/ManagementAgreement/OwnwerAgreement/AgreementViewPage";
import OwnerAgentAgreement from "./Components/ManagementAgreement/OwnwerAgreement/agentAgreement";
import OwnerAgreementPeriod from "./Components/ManagementAgreement/OwnwerAgreement/agreementPeriod";
import OwnerFeesAndCharges from "./Components/ManagementAgreement/OwnwerAgreement/feesAndCharges";
import OwnerTribunalFees from "./Components/ManagementAgreement/OwnwerAgreement/TribunalFees";
import OwnerPromotionalActivities from "./Components/ManagementAgreement/OwnwerAgreement/PromotionalActivities";
import OwnerRepairAndMaintainance from "./Components/ManagementAgreement/OwnwerAgreement/RepairsAndMaintenance";
import OwnerAddNewOwnerShipAgreement from "./Components/ManagementAgreement/OwnwerAgreement/AddNewOwnerShip";
import OwnerAgreementSignature from "./Components/ManagementAgreement/OwnwerAgreement/signature";
import TenantPropertyDetails from "../components/TenantSection/PropertyDetails/PropertyDetails";
import TenancyAgreementTabs from "./Components/TenantAgreement/tenantAgreementTabs";
import AddNewPropertyTenancy from "./Components/TenantAgreement/addNewProperty";
import AgreementViewAddNewProperty from "./Components/TenantAgreement/TenantLeaseAgreementView/addNewProperty";
import AgreementViewtenantAgreementTabs from "./Components/TenantAgreement/TenantLeaseAgreementView/tenantAgreementTabs";
//import ViewPropertyDetails from "../components/Dashboard/Agency/PropertiesAgecny/viewPropertyDetails";
import UploadAgreement from "./view/PropertiesAgency/uploadAgreement";
import UploadAgreementManagement from "./view/PropertiesAgency/uploadAgreementManagement";
import UploadAgreementTenant from "./view/PropertiesAgency/uploadAgreementTenant";
import SaleAgreementTabs from "./Components/SaleAgreement/agreementTabs";
import OwnerSalesAgreementTabs from "./Components/SaleAgreement/OwnwerAgreement/agreementTabs";
import ListTickets from "./view/Support/ListTickets";
import ListTicketDetails from "./Components/Support/ListTicketDetails";
import ListAccounts from "./view/AgencyDetails/Accounts";
import ViewPropertyDetails from "./view/PropertiesAgency/viewPropertDetails"
import ListInvoice from "./view/Accounts/Invoice/ListInvoice"
import NewDashboard from "./view/dashboard/Dashboard"
import ListBills from "./view/Accounts/bills/ListBills"
import TenantAgreementView from "./Components/TenantAgreement/TenantLeaseAgreementView/partiesPrinciples"
import ListMaintainanceRequest from "./view/Maintainance/Agency/listMaintainanceRequest";
import ListQuote from "./view/Maintainance/Agency/listQuote";
import ViewIssueDetails from "./view/Maintainance/Tenant/viewIssueDetails";
import MaintainanceIssueDetails from "./view/Maintainance/Agency/maintainanceIssueDetails";
import ListInspection from "./view/inspection/listInspection";
import AddInspectionMaster from "./view/inspection/addMaster";
import ViewInspection from "./view/inspection/inspectionDetails";
import EditReport from "./view/inspection/editReport";
import TenantDashboard from "./view/Tenant/TenantDashboard";
import ListIssueDetails from "./view/Maintainance/Tenant/listIssueDetails"
import IssueDetails from "./view/Maintainance/Tenant/issueDetails"
import ViewTenantPropertyDetails from "./view/Maintainance/Tenant/viewPropertyDetails"
import TradesmanDashboard from "./view/Tradesman/Tradesman"
import JobSearch from "./view/Maintainance/Common/JobPortal";
import JobDetails from "./view/Maintainance/Common/jobDetails"
import AddQuote from "./view/Maintainance/Common/addQuotes";
import ListTradsmanMaintainance from "./view/Tradesman/listTradsmanMaintainance";
import JobPortalDetails from "./view/Maintainance/Common/JobPortalDetails";
import AdditionalInfomation from "./view/Maintainance/Common/additionalInfomation"
import AiAssistant from "./view/AI-Assistant/ai-assistant"
import ViewPropertActivityDetails from "./view/Activities/propertyActivityDetails";
import Permission from "./view/permission/permission";

const currentUser = currentProfile.get();
let route = currentUser === 3 ? "agent" : "agency"

const appRoutes = [
  // Landlord Screens
  { path: "/selection", element: Selection , layout: true },
  { path: "/addproperty", element: AddProperty , layout: true },
  { path: "/verify", element: Verify , layout: true },
  { path: "/ownership", element: Ownership , layout: true },
  { path: "/addowner", element: Addonwer , layout: true },
  { path: "/completion", element: Completetion_np , layout: true },

  // Agency Screens
  { path: `/${route}/principaldetails`, element: PrincipalDetails , layout: true },
  { path: `/${route}/licensedetails`, element: LicenseDetails , layout: true },
  { path: `/${route}/addcompanydetails`, element: AddCompanyDetails , layout: true },
  { path: `/${route}/insurancedetails`, element: InsuranceDetails , layout: true },
  { path: `/${route}/paymentdetails`, element: PaymentDetails , layout: true },
  { path: `/${route}/verifyagency`, element: VerifyAgency , layout: true },
  { path: `/${route}/statuspage`, element: Statuspage , layout: true },
  { path: `/${route}/approveagency`, element: ApproveAgency , layout: true },
  { path: `/${route}/completion`, element: AgencyCompletion , layout: true },
  // { path: "`/${route}/dashboard", element: DashboardAgency , layout: true },
  { path: `/${route}/properties`, element: PropertiesAgency , layout: true },
  { path: `/${route}/viewPropertyDetails`, element: ViewPropertyDetails , layout: true },
  { path: `/${route}/addproperty`, element: AddNewProperty , layout: true },
  { path: `/${route}/addnewlisting`, element: AddNewPropertiesListings , layout: true },
  { path: `/${route}/propertieslist`, element: PropertiesList , layout: true },
  { path: `/${route}/propertiesattributes`, element: PropertiesAttributes , layout: true },
  { path: `/${route}/propertieslegal`, element: PropertiesLegal , layout: true },
  { path: `/${route}/propertieslistings`, element: PropertiesListings , layout: true },
  { path: `/${route}/propertiesdocuments`, element: PropertiesDocuments , layout: true },
  { path: `/${route}/propertiesownership`, element: PropertiesOwnerShip , layout: true },
  { path: `/${route}/propertiesphoto`, element: PropertiesPhoto , layout: true },
  { path: `/${route}/listAllProperties`, element: ListAllProperties , layout: true },

  // Tenant Screens
  { path: "/tenant/verify", element: Verifytenant , layout: true },
  { path: "/tenant/addresshistory", element: AddressHistory , layout: true },
  { path: "/tenant/propertydetails", element: PropertyDetails , layout: true },
  { path: "/tenant/employment", element: Employment , layout: true },
  { path: "/tenant/questionnaire", element: Questionnaire , layout: true },
  { path: "/tenant/documentation", element: Documentation , layout: true },
  { path: "/tenant/consent", element: Consent , layout: true },
  { path: `/${route}/tenancyagreement`, element: TenancyAgreementTabs , layout: true },
  { path: "/tenant/addproperty", element: AddNewPropertyTenancy , layout: true },
  { path: "/tenant/viewproperty", element: AgreementViewAddNewProperty , layout: true },
  { path: "/tenant/tenantagreementtabs", element: AgreementViewtenantAgreementTabs , layout: true },

  // Agent Screens
  { path: "/agent/dashboard", element: AgentDashboard , layout: true },
  { path: "/agent/profile", element: AgentProfile , layout: true },
  { path: `/${route}/addagent`, element: AddAgent , layout: true },
  { path: `/${route}/agentlist`, element: AgentList , layout: true },
  { path: "/emailverificationagent", element: EmailVerificationAgent , layout: false },
  { path: "/agent/propertieslist", element: PropertiesList , layout: true },
  { path: "/agent/propertiesattributes", element: PropertiesAttributes , layout: true },
  { path: "/agent/propertieslegal", element: PropertiesLegal , layout: true },
  { path: "/agent/propertieslistings", element: PropertiesListings , layout: true },
  { path: "/agent/propertiesdocuments", element: PropertiesDocuments , layout: true },
  { path: "/agent/propertiesownership", element: PropertiesOwnerShip , layout: true },
  { path: "/agent/propertiesphoto", element: PropertiesPhoto , layout: true },
  { path: "/agent/listAllProperties", element: ListAllProperties , layout: true },

  // Auth Screens
  { path: "/signin", element: Signin , layout: false },
  { path: "/signup", element: SignUp , layout: false },
  { path: "/forgot_password", element: ForgotPassword , layout: false },
  { path: "/check", element: Check , layout: false },
  { path: "/setnewpassword", element: SetNewPassword , layout: false },
  { path: "/passwordresetconfirmation", element: PasswordResetConfirmation , layout: false },

  // Miscellaneous Screens
  { path: "/temp", element: Temp , layout: false },
  { path: "/contactlist", element: ContactList , layout: true },
  { path: "/contacts", element: Contacts , layout: true },
  { path: "/contactdetails", element: ContactDetails , layout: true },
  { path: "/contactprefference", element: ContactPrefference , layout: true },
  { path: "/contactothers", element: ContactOthers , layout: true },
  { path: "/landingPageDetails", element: LandingPageDetails , layout: false },
  { path: "/example", element: Example , layout: true },
  { path: "/addprofile", element: AddProfile , layout: false },
  { path: "/selectaddprofile", element: SelectAddProfile , layout: false },

  // Dashboard Screens
  { path: "/agency/profile", element: Profile , layout: true },

  // Support Screens
  { path: "/support/listTickets", element: ListTickets , layout: true },
  { path: "/support/listticketdetails", element: ListTicketDetails , layout: true },

  // Task Screens
  { path: "/task/listtask", element: ListTask , layout: true },
  { path: "/task/addtask", element: AddTask , layout: true },
  { path: "/task/taskdetails", element: TaskDetails , layout: true },

  // Agreement Screens
  { path: `/${route}/listagreementtype`, element: OwnerListAgreement , layout: true },
  { path: `/${route}/listagreement`, element: ListAgreement , layout: true },
  { path: `/${route}/managemantagreement`, element: ManagementAgreement , layout: true },
  { path: `/${route}/agreementtabs`, element: ManagementAgreementTabs , layout: true },
  { path: `/${route}/addnewpropertyagreement`, element: AddNewPropertyAgreement , layout: true },
  { path: `/${route}/agentagreement`, element: AgentAgreement , layout: true },
  { path: `/${route}/agreementperiod`, element: AgreementPeriod , layout: true },
  { path: `/${route}/feesandcharges`, element: FeesAndCharges , layout: true },
  { path: `/${route}/tribunalfees`, element: TribunalFees , layout: true },
  { path: `/${route}/promotionalactivities`, element: PromotionalActivities , layout: true },
  { path: `/${route}/repairsandmaintainance`, element: RepairAndMaintainance , layout: true },
  { path: `/${route}/addnewownershipagreement`, element: AddNewOwnerShipAgreement , layout: true },
  { path: `/${route}/signature`, element: AgreementSignature , layout: true },
  { path: `/${route}/summary`, element: AgreementSummary , layout: true },

  // Owner Agreement Screens
  { path: "/owner/listagreementtype", element: OwnerListAgreement , layout: true },
  { path: "/owner/managemantagreement", element: OwnerManagementAgreement , layout: true },
  { path: "/managementagreement/sign", element: OwnerAddNewPropertyAgreement , layout: false },
  { path: "/owner/addnewpropertyagreement", element: OwnerAddNewPropertyAgreement , layout: true },
  { path: "/owner/agentagreement", element: OwnerAgentAgreement , layout: true },
  { path: "/owner/agreementperiod", element: OwnerAgreementPeriod , layout: true },
  { path: "/owner/feesandcharges", element: OwnerFeesAndCharges , layout: true },
  { path: "/owner/tribunalfees", element: OwnerTribunalFees , layout: true },
  { path: "/owner/promotionalactivities", element: OwnerPromotionalActivities , layout: true },
  { path: "/owner/repairsandmaintainance", element: OwnerRepairAndMaintainance , layout: true },
  { path: "/owner/addnewownershipagreement", element: OwnerAddNewOwnerShipAgreement , layout: true },
  { path: "/owner/signature", element: OwnerAgreementSignature , layout: true },

    // Tenancy Agreement Screens
    { path: `/${route}/TenancyAgreementTabs`, element: SaleAgreementTabs , layout: true },
    { path: "/tenantleaseagreement/sign", element: TenantAgreementView , layout: false },

  // Sales Agreement Screens
  { path: `/${route}/SaleAgreementTabs`, element: SaleAgreementTabs , layout: true },
  { path: "/salesagreement/sign", element: OwnerSalesAgreementTabs , layout: false },

  // Open Home Screens
  { path: "/openhome/listopenhome", element: ListOpenHome , layout: true },
  { path: "/openhome/addopenhome", element: AddOpenHome , layout: true },
  { path: "/openhome/openhomedetails", element: OpenHomeDetails , layout: true },
  { path: "/openhome/addnewuser", element: AddNewUserOpenHome , layout: true },

  // Portal Enquiry Screens
  { path: "/portal/enquiry", element: ListPortalEnquiry , layout: true },

  // Activities Screens
  { path: "/activities/list", element: ListActivities , layout: true },

  // Agency Details Screens
  { path: "`/${route}Details/accounts", element: ListAccounts , layout: true },

  // Accounts Invoice Screens
  { path: "/accounts/invoice", element: ListInvoice , layout: true },
  { path: `/${route}/dashboard`  , element: NewDashboard , layout: true },
  { path: "/accounts/listBills", element: ListBills , layout: true },

    // Maintainancve Screens
    { path: `/${route}/ListMaintainanceRequest`, element: ListMaintainanceRequest , layout: true },
    { path: `/${route}/ListQuote`, element: ListQuote , layout: true },
    { path: `/${route}/ViewIssueDetails`, element: ViewIssueDetails , layout: true },
    { path: `/${route}/maintainanceIssueDetails`, element: MaintainanceIssueDetails , layout: true },

    { path: `/${route}/ListInspection`, element: ListInspection , layout: true },
    { path: `/${route}/AddInspectionMaster`, element: AddInspectionMaster , layout: true },
    { path: `/${route}/ViewInspection`, element: ViewInspection , layout: true },
    { path: `/${route}/EditReport`, element: EditReport , layout: true },
    { path: `/tenant/EditReport`, element: EditReport , layout: true },

  { path: `/${route}/UploadAgreementManagement`, element: UploadAgreementManagement , layout: true },
  { path: `/${route}/UploadAgreement`, element: UploadAgreement , layout: true },
  { path: `/${route}/UploadAgreementTenant`, element: UploadAgreementTenant , layout: true },


  { path: "/tenant/DashboardTenant", element: TenantDashboard , layout: true },
  { path: "/tenant/ListIssueDetails", element: ListIssueDetails , layout: true },
  { path: "/tenant/IssueDetails", element: IssueDetails , layout: true },
  { path: "/tenant/ViewIssueDetails", element: ViewIssueDetails , layout: true },
  { path: "/tenant/ListInspection", element: ListInspection , layout: true },
  { path: "/tenant/ViewTenantPropertyDetails", element: ViewTenantPropertyDetails , layout: true },
  { path: "/tenant/ViewInspection", element: ViewInspection , layout: true },

  { path: "/trade/TradesmanDashboard", element: TradesmanDashboard , layout: true },

  { path: "/JobPortal", element: JobSearch , layout: true },
  { path: "/home", element: LandingPage , layout: false },
  { path: "/ListLandingPage", element: ListLandingPage , layout: false },
  { path: "/AddQuote", element: AddQuote , layout: true },
  { path: "/scheduledjob", element: JobDetails , layout: true },

  { path: "/trade/ListTradsmanMaintainance", element: ListTradsmanMaintainance , layout: true },
  { path: "/trade/ViewIssueDetails", element: JobDetails , layout: true },

  { path: `/${route}/IssueDetails`, element: IssueDetails , layout: true },
  { path: "/JobPortalDetails", element: JobPortalDetails , layout: true },

  { path: "/additionalInformation", element: AdditionalInfomation , layout: false },

  { path: "/AiAssistant", element: AiAssistant , layout: true },
  { path: "/ViewPropertActivityDetails", element: ViewPropertActivityDetails , layout: true },

  { path: "/ViewPropertActivityDetails", element: ViewPropertActivityDetails , layout: true },

  { path: "/agency/Permission", element: Permission , layout: true },


];

export default appRoutes;
