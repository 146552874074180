import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  OpenListHome,
  deleteOpenhome,
  getOpenHomeDetails,
  addNewUserOpenHome,
} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import "../../../components/Dashboard/Dashboard-agent/AgentList.css";
import { ActionStatus } from "../../../Utilities/enum";
import { addParametersForProfiles } from "../../../Utilities/profileManagement";
import SubNav from "../../navbar/subNav";
import AntdTableExample from "../../input-components/dataTable";
import CustomModal from "../../input-components/Modal";
import AddNewProperty from "./addOpenHome";
import dayjs from "dayjs";
import ShowValue from "../../input-components/ShowValue";
import AddNewUser from "./addNewUser";
import { useFormik } from "formik";
import TextBox from "../../input-components/textbox";
import { BiEnvelope, BiPhone, BiRightArrowAlt } from "react-icons/bi";
import { message } from "antd";
import Collapse from "../../input-components/collapse";
import { Table } from "antd";
import { BiArrowBack } from "react-icons/bi";
import { addNewUserOpenHomeValidation } from "../../../components/CommonSection/Schema/schema";

const initialValues = {
  firstName: "",
  lastName: "",
  ContactNo: "",
  email: "",
};

const OpenHomeList = () => {
  const [openHomeLists, setOpenHomeLists] = useState([]);
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const navigate = useNavigate();
  const [propertyType, setPropertyType] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [purpose, setPurpose] = useState("");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [openAddListings, setOpenAddListings] = useState(false);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [propertyTypeVal, setPropertyTypeVal] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [agentId, setAgentId] = useState("");
  const [resetModal, setResetModal] = useState(false);
  const [contactdata, setContactData] = useState({});
  const [userHistoryArr, setUserHistoryArr] = useState([]);
  const [OpenHomeUserlist, setOpenHomeUserlist] = useState([]);
  const [addNewUser, setAddNewUser] = useState(true);
  const propertyTypeitems = [
    { name: "All", value: 0 },
    { name: "Current", value: 1 },
    { name: "Past", value: 2 },
  ];

  const propertyTypeChangeHandler = (value) => {
    setPropertyType(value);
  };

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setAgentId(parsedData.agentId);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          getOpenHomeList(parsedData.agencyID, 1, 10);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [openAddListings]);

  const getOpenHomeList = (agencyID, currentPage, totalRows) => {
    const baseUrl = OpenListHome;
    let data = {
      SortBy: "CreatedOn",
      SortOrder: "Desc",
      RecordsPerPage: totalRows,
      AgencyId: agencyID,
      Search: searchText,
      PageNo: currentPage,
      IsCurrent: propertyType === 1 ? true : propertyType === 2 ? false : null,
    };
    data = addParametersForProfiles(data);
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          let openHomeData = response.list;
          let openHomeList = openHomeData.map((property) => ({
            Address: property.property,
            eventDate: dayjs(property.eventDate).format("DD/MM/YYYY"),
            startTime: dayjs(property.startTime, "HH:mm").format("h:mm A"),
            endTime: dayjs(property.endTime, "HH:mm").format("h:mm A"),
            price: property.propertyPrice + " " + "$",
            guid: property.propertyUniqueID,
            uniqueId: property.propertyId,
            OpenHomeUniqueId: property.openHomeuniqueId,
          }));
          setTotalPages(response.totalCount);
          setOpenHomeLists(openHomeList);
        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Handler for page change
  const handlePageChange = (pageNumber, totalRows) => {
    setCurrentPage(pageNumber);
    setRows(totalRows);
    getSupportList(pageNumber, totalRows);
  };

  const handleAddListings = () => {
    setOpenAddListings(true);
    setResetModal(true);
    setView(false);
  };

  const closeAddListings = () => {
    const baseUrl = window.location.pathname;
    navigate(baseUrl);
    setOpenAddListings(false);
    setResetModal(false);
    setView(false);
  };
  console.log(resetModal);

  const actions = [
    {
      icon: "/assets/images/dots.svg",
      onClick: (e, data) => handleView(e, data),
      key:"View",
    },
    {
      icon: "/assets/images/x.svg",
      onClick: (e, data) => deletOpenHome(e, data),
      iconSize: "12px",
    },
  ];

  const handleView = (e, data) => {
    const baseUrl = window.location.pathname; // Get the current path without query params
    const newUrl = `${baseUrl}?OpenHomeUniqueId=${data.OpenHomeUniqueId}`; // Append the contactUID to the URL
    navigate(newUrl);
    setOpenAddListings(true);
    setView(true);
  };

  var url = new URL(window.location.href);
  var OpenHomeUniqueId = url.searchParams.get("OpenHomeUniqueId");

  useEffect(() => {
    if (OpenHomeUniqueId) {
      openHomeDetails(OpenHomeUniqueId);
    }
  }, [OpenHomeUniqueId]);

  const openHomeDetails = (OpenHomeUniqueId) => {
    const apiUrl = `${getOpenHomeDetails}`;
    let data = {
      openHomeUniqueId: OpenHomeUniqueId,
      RecordsPerPage: 10,
      AgencyId: agencyID,
      SortBy: "CreatedOn",
      SortOrder: "Desc",
    };
    makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          const responseData = response.object;
          setContactData(responseData);
          console.log(responseData, "responseData");
          let propertyList = responseData.addOpenHomeRegistrationModel.map(
            (property) => ({
              email: property.email,
              firstName: property.firstName,
              phone: property.phone,
            })
          );
          setOpenHomeUserlist(propertyList);
          setUserHistoryArr(responseData.updateHistory);
        } else {
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error]);
      });
  };

  const handleAddNewUser = () => {
    setAddNewUser(false);
  };

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addNewUserOpenHomeValidation,
    onSubmit: (values, action) => {
      debugger;
      AddNewUser();
    },
  });

  const AddNewUser = () => {
    var urls = new URL(window.location.href);
    var OpenHomeUniqueId = urls.searchParams.get("OpenHomeUniqueId");
    let formData = {
      FirstName: values.firstName,
      LastName: values.lastName,
      Email: values.email,
      Phone: values.ContactNo,
      OpenHomeUniqueId: OpenHomeUniqueId,
      FromBackend: true,
    };
    const url = addNewUserOpenHome;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          message.success(returnData.message);
          setAddNewUser(true);
          openHomeDetails(OpenHomeUniqueId);
        } else {
          message.warning(returnData.message);
        }
      })
      .catch((error) => {
        message.warning(returnData.message);
      });
  };

  const handleClear = () => {
    setAddNewUser(true);
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
  ];

  const deletOpenHome = async (e, data) => {
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      `${deleteOpenhome}?openHomeUniqueId=${data.OpenHomeUniqueId}`,
      "delete"
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          PostApiWithSpinner();
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error]);
      });
  };

  return (
    <div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
          <SubNav
            title={"Open Home"}
            goback={false}
            extraData={true}
            extraContent={
              <div className="property-details-buttons">
                <button
                  onClick={handleAddListings}
                  className="subnav-btn"
                  type="submit"
                >
                  Add
                </button>
              </div>
            }
          />
          <div className="material-work-desk-new bg-white m-3">
            <div style={{ margin: "10px" }}></div>
            <AntdTableExample
              label="List Open Homes"
              dataSource={openHomeLists}
              globalSearch
              hideCol={"uniqueId,OpenHomeUniqueId"}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              totalRows={rows}
              totalRecords={totalPages}
              actions={actions}
              onRowClick={handleView}
            />
          </div>
          <CustomModal
            footer={null}
            title={
              view
                ? "View Open Details"
                : edit
                ? "Update Contacts"
                : "Add Open Home"
            }
            width={800}
            closable={closeAddListings}
            visible={openAddListings}
            onCancel={closeAddListings}
            modalData={
              <div>
                <div>
                  {/* View Mode: Display only details */}
                  {view && (
                    <div className="details-form p-3">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          color: "#155c75",
                          fontWeight: "500",
                        }}
                      >
                        <BiArrowBack onClick={handleClear} />
                        <div onClick={handleAddNewUser}>Add New User</div>
                      </div>

                      {view && addNewUser ? (
                        <Collapse
                          key={"HouseDetails"}
                          title={`House Details`}
                          data={
                            <div className="contacts-group">
                              <ShowValue
                                Label="Property"
                                setData={contactdata?.property}
                              />
                              <ShowValue
                                Label="Event Date"
                                setData={dayjs(contactdata?.eventDate).format(
                                  "DD/MM/YYYY"
                                )}
                              />
                              <ShowValue
                                Label="Time"
                                setData={`${dayjs(
                                  contactdata?.startTime,
                                  "HH:mm"
                                ).format("h:mm A")} - ${dayjs(
                                  contactdata?.endTime,
                                  "HH:mm"
                                ).format("h:mm A")}`}
                              />
                              {OpenHomeUserlist.length > 0 ? (
                                <Table
                                  dataSource={OpenHomeUserlist}
                                  columns={columns}
                                  rowKey="openHomeRegistrationId"
                                />
                              ) : null}
                            </div>
                          }
                        />
                      ) : null}
                      {view && !addNewUser ? (
                        <Collapse
                          key={"UserDetails"}
                          title={`Add User`}
                          data={
                            <div className="contacts-group">
                              <form
                                onSubmit={handleSubmit}
                                className="details-form"
                                style={{ display: "initial", width: "100%" }}
                              >
                                <div>
                                  <div className="contacts-group row">
                                    <TextBox
                                      isMandatory
                                      label="First Name"
                                      placeholder="First name"
                                      id="Unit"
                                      name="firstName"
                                      value={values.firstName}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={errors.firstName}
                                      touched={touched.firstName}
                                    />
                                    <TextBox
                                      label="Last Name"
                                      placeholder="Last name"
                                      id="lastName"
                                      name="lastName"
                                      value={values.lastName}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={errors.lastName}
                                      touched={touched.lastName}
                                    />
                                    <TextBox
                                      isMandatory
                                      label="Phone"
                                      placeholder="Phone"
                                      name="ContactNo"
                                      value={values.ContactNo}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={errors.ContactNo}
                                      touched={touched.ContactNo}
                                      icon={
                                        <BiPhone height="100%" width="100%" />
                                      }
                                    />
                                    <TextBox
                                      label="Email address"
                                      placeholder="Email"
                                      name="email"
                                      value={values.email}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={errors.email}
                                      touched={touched.email}
                                      icon={
                                        <BiEnvelope
                                          height="100%"
                                          width="100%"
                                        />
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="property-details-buttons">
                                  <button
                                    className="pdb-cancel"
                                    type="reset"
                                    onClick={handleClear}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="pdb-save-changes"
                                    type="submit"
                                  >
                                    Save
                                  </button>
                                </div>
                              </form>
                            </div>
                          }
                        />
                      ) : null}
                    </div>
                  )}

                  {/* Edit Mode: Display forms for editing */}
                  {!view && (
                    <div>
                      <AddNewProperty
                        resetAll={resetModal}
                        closeModal={closeAddListings}
                      />
                    </div>
                  )}
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default OpenHomeList;
