import React from "react";
import { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ContactLists, ContactDetailsApi, SearchContacts, assignedTo, addupdatesupplier, getaccountcharts, updatesupplierdetails,updatesupplierpaymentdetails } from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import "../../../components/Dashboard/Dashboard-agent/AgentList.css";
import { ActionStatus, UserRoleId ,contactCategory} from "../../../Utilities/enum";
import { addParametersForProfiles, dynamicUrl } from "../../../Utilities/profileManagement";
import SubNav from "../../navbar/subNav";
import AntdTableExample from "../../input-components/dataTable";
import { message, Steps } from 'antd';
import CustomModal from "../../input-components/Modal";
import ToggleTabs from "../../input-components/toggleButtonTabs";
import axios from "axios";
import ContactDetails from "./contactDetails"
import CompanyDetailsContact from "./CompanyDetailsContact"
import ContactPrefference from "./contactPrefference";
import ShowValue from "../../input-components/ShowValue";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import TextBox from "../../input-components/textbox";
import SelectBox from "../../input-components/selectBoxNew";
import AddressInput from "../../input-components/address";
import { BiEnvelope, BiPhone } from "react-icons/bi";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";

const ContactList = () => {
    const [contactLists, setContactLists] = useState([]);
    const [userId, setUserId] = useState("");
    const [agencyID, setAgencyId] = useState("");
    const navigate = useNavigate();
    const [propertyType, setPropertyType] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [purpose, setPurpose] = useState("");
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [rows, setRows] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [openAddProperty, setOpenAddProperty] = useState(false);
    const [currentStep, setCurrentStep] = useState(2); // State for current step in Steps component
    const [getDetails, setGetDetails] = useState(null);
    const [dpdata, setDpdata] = useState([]);
    const [tabValue, setTabValue] = useState(1);
    const [tabValueSupplier, setTabValueSupplier] = useState(1);
    const [addNew, setAddNew] = useState(false);
    const [edit, setEdit] = useState(false);
    const [view, setView] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [option, setOption] = useState([]);
    const fetchRef = useRef(0);
    const [typeData, setTypeData] = useState([
        { value: 1, label: "Individual" },
        { value: 2, label: "Company" },
    ]);
    const [titleData, setTitleData] = useState([
        { value: 1, label: "Mr." },
        { value: 2, label: "Miss." },
        { value: 3, label: "Mrs." },
    ]);
    const [budgetData, setBudgetData] = useState([
        { value: 1, label: "$700000 & below" },
        { value: 2, label: "1M below" },
        { value: 3, label: "1M to 1.2M" },
        { value: 4, label: "$1.2 M & above" },
    ]);

    const billPriorityData = [
        { value: 1, label: "High" },
        { value: 2, label: "Medium" },
        { value: 3, label: "Low" },
    ]
    const ePaymentModes = [
        { value: 2, label: "Cheque" },
        { value: 4, label: "EFT" },
        { value: 5, label: "BPay" }
    ];
    const [selectedCountries, setSelectedCountries] = useState(null);
    const [openAddNewSupplier, setOpenAddNewSupplier] = useState(false)
    const { Step } = Steps;
    const [selectedValues, setSelectedValues] = useState([]);
    const [sUid, setSUid] = useState(null);

    const [contactId, setContactId] = useState(null);
    const [owner, setOwner] = useState({
        type: '',
        title: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        ownerShipAddress: '',
        isPrimary: false,
    });

    const [supplier, setSupplier] = useState({
        abn: '',
        website: '',
        billPriority: '',
        autoApproveBills: '',
        tenantInvoiceAccount: '',
        accountChartId: ''
    });
    const [agentId, setAgentId] = useState("");
    const [autoApproveBillsData, setAutoApproveBillsData] = useState([
        { value: true, label: "Yes" },
        { value: false, label: "No" },
    ]);
    const [tenantInvoiceAccountData, settenantInvoiceAccountData] = useState([
        { value: true, label: "Yes" },
        { value: false, label: "No" },
    ]);
    const [accArr, setAccArr] = useState(10);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [payee, setPayee] = useState("");
    const [split, setSplit] = useState("");
    const [billerCode, setBillerCode] = useState("");
    const [bsb, setBsb] = useState("");  // Default or placeholder BSB
    const [accountNumber, setAccountNumber] = useState("");
    const [issueType,setIssueType]  = useState("");
    const [issueTypeData, setIssueTypeData] = useState([
        { value: 1, label: "Plumbing" },
        { value: 2, label: "Door/Window" },
        { value: 3, label: "Electric" },
        { value: 4, label: "Flooring/Stairs" },
        { value: 5, label: "General" },
        { value: 6, label: "Gas Plumbing" },
        { value: 7, label: "Roofing" },
        { value: 8, label: "Cleaner" },
        { value: 9, label: "Aircon repair" },
        { value: 10, label: "Painter" },
      ]);
      const ToastRef = useRef();

    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setUserId(parsedData.userId);
                    setAgencyId(parsedData.agencyID);
                    reportData(parsedData.agencyUniqueID);
                    setAgentId(parsedData.agentId);
                    getAccountList()
                }
            } catch (error) {
                console.error("Error retrieving data from AsyncStorage:", error);
            }
        };
        retrieveData();
    }, [userId]);

    useEffect(() => {
        if (userId) PostApiWithSpinner();
    }, [userId, searchText, purpose, propertyType]);
    var url = new URL(window.location.href);
    var contactUID = url.searchParams.get("contactUID");

    useEffect(() => {
        if (contactUID) {
            GetApi(contactUID)
        }
    }, [contactUID])

    useEffect(() => {
        if (getDetails?.postcode != null) {
            setSelectedCountries(getDetails.postcode.split(";"));
        }
    }, [])

    useEffect(() => {
        const url = new URL(window.location.href);
        const contactUID = url.searchParams.get("contactUID");
      
        if (contactUID) {
          GetApi(contactUID); // Fetch data for the contact
          setTabValue(2); // Automatically set to Tab 2
        }
      }, []);
      


    const PostApiWithSpinner = (currentPage, totalRows) => {
        const baseUrl = ContactLists;
        let data = {
            AgencyUniqueId: "",
            SortBy: "CreatedDate",
            SortOrder: "Desc",
            AgencyId: agencyID,
            Search: searchText,
            RecordsPerPage: totalRows,
            PageNo: currentPage,
            SearchcontactType: purpose
        };
        data = addParametersForProfiles(data);
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    let propertyData = response.list;
                    let propertyList = propertyData.map((property) => ({
                        Name: property.firstName + " " + property.lastName,
                        Email: property.email,
                        PostCode: property.postcode,
                        Phone: property.phone,
                        Type: property.type,
                        guid: property.contactUniqueId,
                        uniqueId: property.contactId,
                    }));
                    setTotalPages(response.totalCount)
                    setContactLists(propertyList);
                } else {
                    ToastRef.current.notify(["error", response.message]);

                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    // Handler for page change
    const handlePageChange = (pageNumber, totalRows) => {
        setCurrentPage(pageNumber);
        setRows(totalRows);
        PostApiWithSpinner(pageNumber, totalRows);
    };

    const handleAddAgentClick = () => {
        dynamicUrl(navigate, "properties");
    };

    const tabColumns = {
        Status: {
            colorMap: {
                "Prospect": "gray",
                "Listed": "green",
            },
            defaultColor: 'blue'
        }
    };


    const handleEditClick = (rowData) => {
        dynamicUrl(navigate, "properties" + "?propertyUID=" + rowData.guid);
    };

    const handleRowClick = (event, rowData) => {
        const clickedElement = event.originalEvent.target;
        if (clickedElement.className.includes("action-column")) {
            return;
        } else {
            dynamicUrl(navigate, "properties" + "?propertyUID=" + rowData.guid);
        }
    };

    const handleStatClick = (selectedPurpose) => {
        setPurpose(selectedPurpose);
    };

    const handleSearchValueChange = (value) => {
        setSearchText(value);
    };

    const handleOpenProperty = () => {
        const baseUrl = window.location.pathname;
        navigate(baseUrl);
        setOpenAddProperty(true);
        setTabValue(1);
        setAddNew(true);
        setEdit(false);
        setView(false);
        setGetDetails(null);
    }

    const closeProperty = () => {
        const baseUrl = window.location.pathname;
        navigate(baseUrl);
        setOpenAddProperty(false);
        setEdit(false);
        setView(false);
        setGetDetails(null);
        setAddNew(false);
    }

    const handleStepChange = (step) => {
        setCurrentStep(step); // Updates the current step based on user action
    };

    const GetApi = (value) => {
        var url = new URL(window.location.href);
        var contactUniqueId = value ? value : url.searchParams.get("contactUID");
        const baseUrl = ContactDetailsApi;
        const apiUrl = `${baseUrl}?contactUniqueId=${contactUniqueId}`;
        makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get")
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    setGetDetails(response.object);
                } else {
                    ToastRef.current.notify(["error", response.data.message]);

                }
            })
            .catch((error) => {
                handleparentsuccess(["error", error]);
            });
    };

    const reportData = (agencyUID) => {
        const apiUrl = assignedTo;
        const baseUrl = apiUrl + "?agencyUID=" + agencyUID;
        axios.get(baseUrl).then((response) => {
            if (response.data.object) setDpdata(response.data.object);
        });
    };

    const handleTabChange = (key) => {
        if (key === null) {
            setOpenAddProperty(false);
            PostApiWithSpinner();

        } else {
           setTabValue(key)
        }
    };

    const handleTabChangeSupplier = (key) => {
        if (key === null) {
            setOpenAddProperty(false);
            PostApiWithSpinner();

        } else {
            setTabValueSupplier(key)
        }
    };

    const tabs = [
        { label: 'Personal Details', value: 1 },
        { label: 'Company Details', value: 2 },
        { label: 'Preference', value: 3 },
    ];

    const supplierTabs = [
        { label: 'Contacts', value: 1 },
        { label: 'Supplier', value: 2 },
        { label: 'Payment Methods', value: 3 },
    ];

    const handleDataChild = (value) => {
        GetApi(value);
    };

    const handleEdit = (e, data) => {
        const baseUrl = window.location.pathname; // Get the current path without query params
        const newUrl = `${baseUrl}?contactUID=${data.guid}`; // Append the contactUID to the URL
        navigate(newUrl);
        setOpenAddProperty(true);
        setEdit(true);
        setTabValue(1)
    }

    const handleView = (e, data) => {
        const baseUrl = window.location.pathname; // Get the current path without query params
        const newUrl = `${baseUrl}?contactUID=${data.guid}`; // Append the contactUID to the URL
        navigate(newUrl);
        setOpenAddProperty(true);
        setView(true);
        setTabValue(1)
    }

    const actions = [
        { icon: "/assets/images/dots.svg",key:"View", onClick: (e, data) => handleView(e, data) },
        { icon: "/assets/images/editing.svg", onClick: (e, data) => handleEdit(e, data) },
    ]

    const options = [
        { value: 1, label: "Seller" },
        { value: 2, label: "Buyer" },
        { value: 3, label: "Investor" },
        { value: 4, label: "Tenant" },
        { value: 5, label: "Landlord" },
        { value: 6, label: "Developer" },
        { value: 7, label: "Supplier" },
    ];

    const commaSeparatedString =
        getDetails?.typeIAM && typeof getDetails?.typeIAM === "string"
            ? getDetails?.typeIAM
                .split(",")
                .map((value) => {
                    const option = options.find((opt) => opt.value === parseInt(value));
                    return option ? option.label : null;
                })
                .filter((label) => label !== null)
                .join(", ")
            : null;

    const optionsPrefference = [
        { value: 1, label: "Investment" },
        { value: 2, label: "Development" },
        { value: 3, label: "First Home Buyer" },
        { value: 4, label: "Renting" },
        { value: 5, label: "Selling" },
        { value: 6, label: "Buying" },
    ];

    const commaSeparatedString1 =
        getDetails?.otherDetailsType && typeof getDetails?.otherDetailsType === "string"
            ? getDetails?.otherDetailsType
                .split(",")
                .map((value) => {
                    const option = optionsPrefference.find((opt) => opt.value === parseInt(value));
                    return option ? option.label : null;
                })
                .filter((label) => label !== null)
                .join(", ")
            : null;

    const closeSupplier = () => {
        setOpenAddNewSupplier(false)
    }

    const handleAddNewSupplier = () => {
        setOpenAddNewSupplier(true)
        setTabValueSupplier(1)
    }

    useEffect(() => {
        agentUIDRef.current = agentId;
    }, [agentId]);

    const agentUIDRef = useRef(agentId);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            const agentId = agentUIDRef.current;
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOption([]);
            setFetching(true);
            const baseUrl = SearchContacts;
            let data = {
                Search: value,
                RecordsPerPage: 10,
                PageNo: 1,
                AgentID: agentId,
            };
            data = addParametersForProfiles(data);
            makeRequestWithSpinner(
                showSpinner,
                hideSpinner,
                baseUrl,
                "post",
                data,
                "",
                false
            )
                .then((response) => {
                    if (response.status === 1) {
                        let landingPageListData = response.list;
                        const transformedData = landingPageListData.map((item) => ({
                            value: item.id,
                            label: item.name,
                            uid: item.uid
                        }));
                        if (fetchId === fetchRef.current) {
                            setOption(transformedData);
                            setFetching(false);
                        }
                    } else {
                        setFetching(false);
                    }
                })
                .catch((error) => {
                    setFetching(false);
                });
        };
        return debounce(loadOptions, 800);
    }, []);

    const handleSearchChange = (e, value) => {
        const label = value.map((item) => item.label).join(",");
        const values = value.map((item) => item.value).join(",");
        const uid = value.map((item) => item.uid).join(",");
        GetContactDetails(uid)
        setFetching("propertyName", label);
        setContactId(uid);
        debounceFetcher(value);
    };

    const GetContactDetails = (value) => {
        var url = new URL(window.location.href);
        var contactUniqueId = value;
        const baseUrl = ContactDetailsApi;
        const apiUrl = `${baseUrl}?contactUniqueId=${contactUniqueId}`;
        makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get")
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    let data = response.object
                    setOwner((prevOwner) => ({
                        ...prevOwner, // Keep the previous state
                        firstName: data.firstName,
                        lastName: data.lastName,
                        phone: data.mobileNumber,
                        email: data.email,
                        title: data.title,
                        address: data.address
                    }));
                } else {
                    message.warning(response.data.message);
                }
            })
            .catch((error) => {
                message.error(error);
            });
    };


    const addSupplier = () => {
        if(owner.type === ""){
            ToastRef.current.notify(["error", "Type is required."]);
        }else{
        const formData = {
            AgencyId: agencyID,
            AgentId: getDetails ? getDetails.agentId : agentId,
            Address: owner.ownerShipAddress,
            userRoleID: 10,
            FirstName: owner.firstName,
            LastName: owner.lastName,
            Email: owner.email,
            MobileNumber: owner.phone,
            ContactType: owner.type,
            Title: owner.title,
            ContactUniqueId: contactId

        };

        const url = addupdatesupplier;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    setTabValueSupplier(2);
                    setSUid(returnData.id)
                } else {
                    //handleparentsuccess(["error", returnData.message]);
                }
            })
            .catch((error) => {
                // handleparentsuccess(["error", error.message]);
            });
        }
    };

    const handleOwnerChange = (e, label, data) => {
        if (label === "ownerShipAddress") {
            setOwner((prev) => ({ ...prev, ["ownerShipAddress"]: data }));
        } else {
            const { name, value } = e.target;
            setOwner((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleSupplierChange = (e, label, data) => {
        const { name, value } = e.target;
        setSupplier((prev) => ({ ...prev, [name]: value }));
    };

    const handleSelectChange = (selected, data) => {
        if (data === "type") {
            setOwner((prevOwner) => ({
                ...prevOwner, // Keep the previous state
                type: selected.value
            }));
        } else {
            setOwner((prevOwner) => ({
                ...prevOwner, // Keep the previous state
                title: selected.value
            }));
        }
    };

    const handleCancel = () => {
        var url = new URL(window.location.href);
        var propertyUId = url.searchParams.get("propertyUID");
        if (propertyUId) {
            handleCurrentPage("Overview")
        } else {
            dynamicUrl(navigate, "propertiesList");
        }
    };

    const handleSelectSupplier = (selected, data) => {
        setSupplier((prevOwner) => ({
            ...prevOwner, // Keep the previous state
            [data]: selected.value
        }));
    }

    const getAccountList = () => {
        const baseUrl = getaccountcharts + "?type=0";
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "get")
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    const accArr = response.object.map(item => ({
                        label: item.text,
                        value: item.value
                    }));
                    setAccArr(accArr)
                } else {
                    alert(`Message: ${response.message}`);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const Addupdatesupplierdetails = () => {

        const formData = {
            SupplierId: sUid,
            ABN: supplier.abn,
            Website: supplier.website,
            AccountChartId: parseInt(supplier.accountChartId),
            BillPriority: supplier.billPriority,
            AutoApproveBills: supplier.autoApproveBills,
            TenantInvoiceAccount: supplier.tenantInvoiceAccount,
            SupplierType: issueType
        };

        const url = updatesupplierdetails;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    setTabValueSupplier(3);
                } else {
                    //handleparentsuccess(["error", returnData.message]);
                }
            })
            .catch((error) => {
                // handleparentsuccess(["error", error.message]);
            });
    };

    const Addupdatesupplierpaymentdetails = () => {
        const formData = {
            SupplierId: sUid,
            PaymentMethod: paymentMethod,
            ChequePayeeName: paymentMethod === 2 ? payee : null,
            AccountName: paymentMethod === 4 ? payee : null,
            AccountBSB: paymentMethod === 4 ? bsb : null,
            AccountNumber: paymentMethod === 4 ? accountNumber : null,
            BpayPayee: paymentMethod === 5 ? payee : null,
            BillerCode: paymentMethod === 5 ? billerCode : null
        };

        // Make the API call
        const url = updatesupplierpaymentdetails;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    setOpenAddNewSupplier(false)
                } else {
                    // handle error
                }
            })
            .catch((error) => {
                // handle error
            });
    };


    const handlePaymentMethodChange = (selectedOption) => {
        setPaymentMethod(selectedOption);
    };


    return (
        <div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
                    <SubNav title={"Contacts"} goback={false} extraData={true} extraContent={
                        <div className="property-details-buttons">
                            <button onClick={handleOpenProperty} className="subnav-btn" type="submit">
                                Add Contacts
                            </button>
                            <button onClick={handleAddNewSupplier} className="subnav-btn" type="submit">
                                Add Suppliers
                            </button>
                        </div>
                    } />
                    <div className="material-work-desk-new bg-white m-3">
                    <ToastAlert ref={ToastRef} />
                        <div style={{ borderBottom: '1px solid #e9ecef', padding: '10px' }}>
                            <div className="col-lg-8">
                                <span
                                    className={`col-lg-2 col-sm-2 stat-item ${purpose === "" ? 'active' : ''}`}
                                    onClick={() => handleStatClick("")}
                                >
                                    Contacts 
                                </span>
                                <span
                                    className={`col-lg-2 col-sm-2 stat-item ${purpose === contactCategory.Owner? 'active' : ''}`}
                                    onClick={() => handleStatClick(contactCategory.Owner)}
                                >
                                    Owners 
                                </span>
                                <span
                                    className={`col-lg-2 col-sm-2 stat-item ${purpose === contactCategory.Supplier ? 'active' : ''}`}
                                    onClick={() => handleStatClick(contactCategory.Supplier)}
                                >
                                    Suppliers 
                                </span>
                                <span
                                    className={`col-lg-2 col-sm-2 stat-item ${purpose === contactCategory.Tenant ? 'active' : ''}`}
                                    onClick={() => handleStatClick(contactCategory.Tenant)}
                                >
                                    Tenant
                                </span>
                                
                            </div>
                        </div>
                        <AntdTableExample
                            label="List of Contacts"
                            dataSource={contactLists}
                            globalSearch
                            hideCol={"uniqueId"}
                            onPageChange={handlePageChange}
                            currentPage={currentPage}
                            totalRows={rows}
                            totalRecords={totalPages}
                            tagColumns={tabColumns}
                            onSearchValueChange={handleSearchValueChange}
                            actions={actions}
                            onRowClick={handleView}
                        />
                    </div>
                    <CustomModal footer={null} title={view ? "View Contact Details" : edit ? "Update Contacts" : "Add Contacts"} width={800} closable={closeProperty} visible={openAddProperty} onCancel={closeProperty}
                        modalData={
                            <div>
                                <div className="col-lg-12">
                                    <ToggleTabs tabs={tabs} onTabChange={handleTabChange} externalTabValue={tabValue} />
                                </div>
                                <div>
                                    {/* View Mode: Display only details */}
                                    {view && (
                                        <div className="details-form p-3">
                                            {tabValue === 1 && (
                                                <div className="contacts-group">
                                                    <ShowValue Label='Contact Type' Data={typeData} setData={getDetails?.contactType} />
                                                    <ShowValue Label='Title' Data={titleData} setData={getDetails?.title} />
                                                    <ShowValue Label='First Name' setData={getDetails?.firstName} />
                                                    <ShowValue Label='Last Name' setData={getDetails?.lastName} />
                                                    <ShowValue Label='Type (I am)' setData={commaSeparatedString} />
                                                    <ShowValue Label='Phone' setData={getDetails?.mobileNumber} />
                                                    <ShowValue Label='Email Address' setData={getDetails?.email} />
                                                    <ShowValue Label='Address' setData={getDetails?.address} />
                                                </div>
                                            )}
                                            {tabValue === 2 && (
                                                <div className="contacts-group">
                                                    <ShowValue Label='ABN' setData={getDetails?.abn} />
                                                    <ShowValue Label='ACN' setData={getDetails?.acn} />
                                                    <ShowValue Label='Company Name' setData={getDetails?.companyName} />
                                                </div>
                                            )}
                                            {tabValue === 3 && (
                                                <div className="contacts-group">
                                                    <ShowValue Label='Purpose' setData={commaSeparatedString1} />
                                                    <ShowValue Label='Budget' Data={budgetData} setData={getDetails?.priceRange} />
                                                    <ShowValue Label='State / Region' setData={getDetails?.regions} />
                                                    <ShowValue Label='Postcode' setData={selectedCountries} />
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {/* Edit Mode: Display forms for editing */}
                                    {!view && (
                                        <div>
                                            {(tabValue === 1 && addNew) || (tabValue === 1 && edit) ? (
                                                <ContactDetails
                                                    handleCurrentPage={handleTabChange}
                                                    getDetails={getDetails}
                                                    onDataRecive={handleDataChild}
                                                    closeModal={closeProperty}
                                                />
                                            ) : null}
                                            {(tabValue === 2 && addNew) || (tabValue === 2 && edit) ? (
                                                <CompanyDetailsContact
                                                    getDetails={getDetails}
                                                    handleCurrentPage={handleTabChange}
                                                    closeModal={closeProperty}
                                                />
                                            ) : null}
                                            {(tabValue === 3 && addNew) || (tabValue === 3 && edit) ? (
                                                <ContactPrefference
                                                    onDataRecive={handleDataChild}
                                                    getDetails={getDetails}
                                                    handleCurrentPage={handleTabChange}
                                                    closeModal={closeProperty}
                                                />
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                            </div>
                        }

                    />
                    <CustomModal footer={null} title={"New Supplier"} width={800} closable={closeSupplier} visible={openAddNewSupplier} onCancel={closeSupplier}
                        modalData={
                            <div>
                                <div className="col-lg-12">
                                    <ToggleTabs tabs={supplierTabs} onTabChange={handleTabChangeSupplier} externalTabValue={tabValueSupplier} />
                                </div>
                                <div>
                                    {/* Edit Mode: Display forms for editing */}

                                    <div>
                                        {(tabValueSupplier === 1) || (tabValue === 1 && edit) ? (
                                            <div style={{ paddingTop: "10px" }}>

                                                            <div className="p-3 row">
                                                                <div className="col-lg-6 col-sm-12">
                                                                    <label className="s1">Contacts</label>
                                                                    <div style={{ display: "flex" }}>
                                                                        <Select
                                                                            style={{ width: "100%", height: "40px" }}
                                                                            mode="multiple"
                                                                            placeholder="Search for contacts"
                                                                            labelInValue
                                                                            filterOption={false}
                                                                            onSearch={debounceFetcher}
                                                                            notFoundContent={fetching ? <Spin size="small" /> : null}
                                                                            options={option}
                                                                            onChange={(label, value) => handleSearchChange(label, value)}
                                                                            defaultValue={selectedValues}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <SelectBox
                                                                    isMandatory
                                                                    label="Type"
                                                                    placeholder="Select Type"
                                                                    name={`type`}
                                                                    options={typeData}
                                                                    value={owner.type}
                                                                    onChange={(e) => handleSelectChange(e, "type")} // Update type for this owner
                                                                />
                                                                <SelectBox
                                                                    label="Title"
                                                                    placeholder="Select Title"
                                                                    name={`title`}
                                                                    options={titleData}
                                                                    value={owner.title}
                                                                    onChange={(e) => handleSelectChange(e, "title")} // Update type for this owner
                                                                />
                                                                <TextBox
                                                                    label="First Name"
                                                                    name={`firstName`}
                                                                    value={owner.firstName}
                                                                    onChange={handleOwnerChange} // Update first name
                                                                />
                                                                <TextBox
                                                                    label="Last Name"
                                                                    name={`lastName`}
                                                                    value={owner.lastName}
                                                                    onChange={handleOwnerChange} // Update last name
                                                                />
                                                                <TextBox
                                                                    label="Phone"
                                                                    name={`phone`}
                                                                    value={owner.phone}
                                                                    onChange={handleOwnerChange} // Update phone number
                                                                    icon={<BiPhone height="100%" width="100%" />}

                                                                />
                                                                <TextBox
                                                                    label="Email"
                                                                    name={`email`}
                                                                    value={owner.email}
                                                                    onChange={handleOwnerChange} // Update email
                                                                    icon={<BiEnvelope height="100%" width="100%" />}
                                                                />
                                                                <AddressInput
                                                                    labelName="Address"
                                                                    address={owner.ownerShipAddress}
                                                                    setAddress={value => handleOwnerChange("", "ownerShipAddress", value)} // Update address
                                                                />

                                                            </div>

                                                <div className="property-details-buttons">
                                                    <button
                                                        className="pdb-cancel"
                                                        type="reset"
                                                        onClick={closeSupplier}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button className="pdb-save-changes" onClick={addSupplier}>
                                                        Save
                                                    </button>
                                                </div>

                                            </div>
                                        ) : null}
                                        {(tabValueSupplier === 2) || (tabValue === 2 && edit) ? (
                                            <div style={{ paddingTop: "10px" }}>

                                                            <div className="p-3 row">
                                                                <SelectBox
                                                                    label="Type"
                                                                    placeholder="Type"
                                                                    id="issueType"
                                                                    name="issueType"
                                                                    value={""}
                                                                    onChange={(val) => setIssueType(val.value)}
                                                                    options={issueTypeData}
                                                                />
                                                                <TextBox
                                                                    label="ABN"
                                                                    name={`abn`}
                                                                    value={supplier.abn}
                                                                    onChange={handleSupplierChange} // Update first name
                                                                />
                                                                <TextBox
                                                                    label="Website"
                                                                    name={`website`}
                                                                    value={supplier.website}
                                                                    onChange={handleSupplierChange} // Update last name
                                                                />

                                                                <SelectBox
                                                                    label="Bill Account"
                                                                    placeholder="Select"
                                                                    name={`accountChartId`}
                                                                    options={accArr}
                                                                    value={supplier.accountChartId}
                                                                    onChange={(e) => handleSelectSupplier(e, "accountChartId")} // Update type for this owner
                                                                />
                                                                <SelectBox
                                                                    label="Bill Priority"
                                                                    placeholder="Select"
                                                                    name={`billPriority`}
                                                                    options={billPriorityData}
                                                                    value={supplier.billPriority}
                                                                    onChange={(e) => handleSelectSupplier(e, "billPriority")} // Update type for this owner
                                                                />

                                                                <SelectBox
                                                                    label="Auto Approve Bills"
                                                                    placeholder="Select"
                                                                    name={`autoApproveBills`}
                                                                    options={autoApproveBillsData}
                                                                    value={supplier.autoApproveBills}
                                                                    onChange={(e) => handleSelectSupplier(e, "autoApproveBills")} // Update type for this owner
                                                                />

                                                                <SelectBox
                                                                    label="Tenant invoice  account"
                                                                    placeholder="Select"
                                                                    name={`tenantInvoiceAccount`}
                                                                    options={tenantInvoiceAccountData}
                                                                    value={supplier.tenantInvoiceAccount}
                                                                    onChange={(e) => handleSelectSupplier(e, "tenantInvoiceAccount")} // Update type for this owner
                                                                />

                                                            </div>
                                                   

                                                <div className="property-details-buttons">
                                                    <button
                                                        className="pdb-cancel"
                                                        type="reset"
                                                        onClick={closeSupplier}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button className="pdb-save-changes" onClick={Addupdatesupplierdetails}>
                                                        Save
                                                    </button>
                                                </div>

                                            </div>
                                        ) : null}
                                        {(tabValueSupplier === 3) || (tabValue === 3 && edit) ? (
                                            <div className="p-3" style={{ paddingTop: "10px" }}>

                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Method</th>
                                                            <th>Payee</th>
                                                            {paymentMethod !== 5 && paymentMethod !== 2 && <th>BSB</th>}
                                                            {paymentMethod !== 5 && paymentMethod !== 2 && <th>Account #</th>}
                                                            {paymentMethod === 5 && <th>Biller Code</th>}
                                                            <th>Split</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            {/* Method Dropdown */}
                                                            <td>
                                                                <Select
                                                                    options={ePaymentModes}
                                                                    value={paymentMethod}
                                                                    onChange={handlePaymentMethodChange}
                                                                    placeholder="Select method"
                                                                    className="mb-3"
                                                                />
                                                            </td>

                                                            {/* Payee Input */}
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={payee}
                                                                    onChange={(e) => setPayee(e.target.value)}
                                                                    placeholder="Enter payee name"
                                                                />
                                                            </td>

                                                            {paymentMethod === 4 && (
                                                                <>
                                                                    {/* Input fields for BSB and Account # for EFT */}
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={bsb}
                                                                            onChange={(e) => setBsb(e.target.value)}
                                                                            placeholder="Enter BSB"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={accountNumber}
                                                                            onChange={(e) => setAccountNumber(e.target.value)}
                                                                            placeholder="Enter Account Number"
                                                                        />
                                                                    </td>
                                                                </>
                                                            )}

                                                            {paymentMethod === 5 && (
                                                                <td>
                                                                    {/* Input field for Biller Code for BPay */}
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={billerCode}
                                                                        onChange={(e) => setBillerCode(e.target.value)}
                                                                        placeholder="Enter Biller Code"
                                                                    />
                                                                </td>
                                                            )}

                                                            {/* Split Input */}
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={split}
                                                                    onChange={(e) => setSplit(e.target.value)}
                                                                    placeholder="100.00%"
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <div className="property-details-buttons">
                                                    <button
                                                        className="pdb-cancel"
                                                        type="reset"
                                                        onClick={closeSupplier}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button className="pdb-save-changes" onClick={Addupdatesupplierpaymentdetails}>
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        ) : null}

                                    </div>

                                </div>
                            </div>
                        }

                    />
                </div>
            </div>
        </div>
    );
};

export default ContactList;
