import React, { useRef, useState } from "react";
import OpenAI from "openai";
import "./style/chat-component.css";
import { INSTRUCTION_FOR_AGREEMENT } from "../../../Utilities/openai/ai-commands";
import { processaisaleagreement } from "../../../components/CommonSection/apiresources";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import {DownloadFiletWithSpinner} from "../../../Utilities/client";
import { ActionStatus } from "../../../Utilities/enum";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { ReloadOutlined } from "@ant-design/icons";

const ChatScreen = () => {
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();
  const [messages, setMessages] = useState([
    { role: "system", text: "How can I help you?" },
  ]);
  const [input, setInput] = useState("");
  const [jsonObject, setJsonObject] = useState(null);

  const handleSendMessage = async () => {
    if (!input.trim()) return;
    const updatedMessages = [...messages, { role: "user", text: input }];
    setMessages(updatedMessages);
    setInput("");
    await sendCommandToOpenAI(input, updatedMessages);
  };

  const sendCommandToOpenAI = async (commandInput, currentMessages) => {
    const openai = new OpenAI({
      apiKey: import.meta.env.VITE_OPENAI_KEY, // Replace with your OpenAI API key
      dangerouslyAllowBrowser: true,
    });

    try {
      const completion = await openai.chat.completions.create({
        model: "gpt-4",
        messages: [
          { role: "system", content: INSTRUCTION_FOR_AGREEMENT },
          ...currentMessages.map((msg) => ({
            role: msg.role,
            content: msg.text,
          })),
          { role: "user", content: commandInput },
        ],
      });

      const content = completion.choices[0].message.content;
      const updatedMessages = [
        ...currentMessages,
        { role: "system", text: content },
      ];
      const jsonResponse = validateJsonResponse(content);

      if (jsonResponse) processJsonResponse(jsonResponse);
      setMessages(updatedMessages);
    } catch (error) {
      console.error("Error communicating with OpenAI:", error);
      setMessages([
        ...currentMessages,
        { role: "bot", text: "Something went wrong. Please try again." },
      ]);
    }
  };

  const processJsonResponse = (jsonResponse) => {
    if (jsonResponse.agreement_type && jsonResponse.agreement_type == "2") {
      DownloadFiletWithSpinner(
        showSpinner,
        hideSpinner,
        processaisaleagreement,
        "post",
        jsonResponse
      )
        .then((returnData) => {
          consol
          if (returnData != null) {
            window.open(returnData, "_blank");
          } else {
            ToastRef.current.notify(["error", "Internal Server Error"]);
          }
        })
        .catch((error) => {
          ToastRef.current.notify(["error", error.message]);
        });
    }
  };

  const validateJsonResponse = (responseContent) => {
    try {
      const jsonPattern = /<\s*(\{.*?\})\s*>/s; // Match JSON after "json\n"
      const match = responseContent.match(jsonPattern);

      if (match) {
        const cleanedString = match[0].replace(/^<\s*|\s*>$/g, "").trim();
        const parsedJson = JSON.parse(cleanedString);
        setJsonObject(parsedJson);
        return parsedJson;
      }
      console.warn("No valid JSON found in the response.");
    } catch (error) {
      console.error("Error parsing JSON:", error.message);
    }
    return null;
  };

  const handlePageRefresh = () => {
    window.location.reload(); // Refresh the page
  };


  return (
    <div >
      <ToastAlert ref={ToastRef} />
      <div className="chat-container">
      <div className="chat-header">
          <h2></h2>
          <button className="refresh-button" onClick={handlePageRefresh}>
            <ReloadOutlined style={{ fontSize: "20px", color: "#007bff" }} />
          </button>
        </div>
        <div className="chat-window">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`chat-message ${msg.role === "user" ? "user" : "bot"}`}
            >
              <pre>{msg.text}</pre>
            </div>
          ))}
        </div>
        <div className="chat-input-container">
          <input
            type="text"
            className="chat-input"
            placeholder="Type your message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
          />
          <button className="send-message-button" onClick={handleSendMessage}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatScreen;
