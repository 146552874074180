import React from 'react';
import { useEffect, useState } from 'react'
import './DashboardAgent.css';
import { useFormik } from "formik";
import { addLicenseAgentDetails, LicenseVerifyUrl, LicenseTokenUrl, LicenceNumberBaseKey, LicenseApiKey } from "../../CommonSection/apiresources";
import { GetCookie } from '../../CommonSection/Cookie/Session';
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { AgentLicensingDetailsSchema } from "../../CommonSection/Schema/schema";
import TextBox from "../../../src/input-components/textbox";
import RadioInput from "../../../src/input-components/radioBtn";
import { isNullOrEmpty, parseToDate } from "../../CommonSection/Utility/ComponentFunction"
import ShowValue from '../../../src/input-components/ShowValue';
import { FiEdit } from "react-icons/fi";
import { format } from "date-fns";

const initialValues = {
    licenseExpirydate: "",
    licenseNumber: "",
    licenseType: "",
}
const LicensingDetailsAgent = ({ handleparentsuccess, handleCurrentPage, getDetails }) => {
    var url = new URL(window.location.href);
    var agentId = url.searchParams.get("value");
    const [userId, setUserId] = useState('')
    const [userUId, setUserUId] = useState('')
    const [agencyID, setAgencyId] = useState('');
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [licenseToken, setLicenseToken] = useState(null);
    const [view, setView] = useState(true)
    var url = new URL(window.location.href);
    var edit = url.searchParams.get("edit");

    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie('@UserData');
                if (userData) {
                    const parsedData = JSON.parse(userData)
                    setUserUId(parsedData.userUnique_ID);
                    setUserId(parsedData.userId);
                    setAgencyId(parsedData.agencyID);
                }
            } catch (error) {
                console.error('Error retrieving data from AsyncStorage:', error);
            }
        };
        retrieveData();
    }, [userId]);

    useEffect(() => {
        if (getDetails) {
            setFieldValue('licenseExpirydate', formatDate(getDetails.licenseExpirydate));
            setFieldValue('licenseNumber', getDetails.licenseNumber);
            setFieldValue('licenseType', getDetails.licenseType === 1 ? 'LicenseNumber' : 'CertificateOfRegistration');
        }
    }, [getDetails]);
    function formatDate(dateString) {
        if (!dateString) {
            return '';
        }
        if (dateString.includes('-')) {
            const [year, month, day] = dateString.split('T')[0].split('-');
            return `${year}-${month}-${day}`;
        }
        else if (dateString.includes('/')) {
            const [day, month, year] = dateString.split('/');
            return `${year}-${month}-${day}`;
        }
        else {
            return '';
        }
    }

    const { errors, values, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: AgentLicensingDetailsSchema,
        onSubmit: (values, action) => {
            if (agentId != '0')
                handleFinalSubmit();
        }
    });

    const handleFinalSubmit = () => {
        const objectData = {
            AgencyId: agencyID,
            AgentId: agentId,
            LicenseNumber: values.licenseNumber,
            LicenseExpirydate: values.licenseExpirydate,
            LicenseType: values.licenseType === 'LicenseNumber' ? 1 : 2,
        };
        makeRequestWithSpinner(showSpinner, hideSpinner, addLicenseAgentDetails, "post", objectData).then((response) => {
            if (response.status == ActionStatus.Successfull) {
                handleparentsuccess(['success', response.message]);
                handleCurrentPage("Experience");
            } else {
                handleparentsuccess(['error', response.message]);
            }
        })
            .catch((error) => {
                handleparentsuccess(['error', error]);
            });
    };

    const licenseTypeOptions = [
        { label: 'License Number', value: 'LicenseNumber' },
        { label: 'Certificate Of Registration', value: 'CertificateOfRegistration' },
    ];

    const handleLicenseNum = async (event) => {
        debugger;
        let corporateLicenseNumber = event.target.value;
        let bearerToken = licenseToken;
        if (bearerToken == null) {
            TokenGenerator(corporateLicenseNumber);
        } else {
            if (!isNullOrEmpty(bearerToken)) {
                makeRequestWithSpinner(
                    showSpinner,
                    hideSpinner,
                    `${LicenseVerifyUrl}?licenceNumber=${corporateLicenseNumber}`,
                    "get",
                    null,
                    {
                        Authorization: `Bearer ${bearerToken}`,
                        apiKey: LicenseApiKey,
                    }
                )
                    .then((returnData) => {
                        if (returnData.length > 0) {
                            const firstItem = returnData[0];
                            console.log(firstItem, 'firstItem')
                            setFieldValue(
                                "licenseExpirydate",
                                formatDate(firstItem.expiryDate)
                            );
                            handleparentsuccess(['success', `Valid License Number ${corporateLicenseNumber}`]);
                        } else {
                            handleparentsuccess(["error", "Invalid License Number"]);
                        }
                    })
                    .catch((error) => {
                        console.error("Error Details:", error);
                        console.error(
                            "Error Data:",
                            error.response ? error.response.data : "No response data"
                        );
                        console.error(
                            "Error Status:",
                            error.response ? error.response.status : "No response status"
                        );
                    });
            } else {
                handleparentsuccess(["error", "Please try again"]);
            }
        }
    };

    const TokenGenerator = (corporateLicenseNumber) => {
        if (!isNullOrEmpty(corporateLicenseNumber)) {
            makeRequestWithSpinner(
                showSpinner,
                hideSpinner,
                LicenseTokenUrl,
                "get",
                null,
                {
                    Authorization: LicenceNumberBaseKey,
                }
            )
                .then((returnData) => {
                    if (returnData.status == "approved") {
                        setLicenseToken(returnData.access_token);
                        // Once the token is generated, make the request using the generated token
                        makeRequestWithSpinner(
                            showSpinner,
                            hideSpinner,
                            `${LicenseVerifyUrl}?licenceNumber=${corporateLicenseNumber}`,
                            "get",
                            null,
                            {
                                Authorization: `Bearer ${returnData.access_token}`,
                                apiKey: LicenseApiKey,
                            }
                        )
                            .then((returnData) => {
                                if (returnData.length > 0) {
                                    const firstItem = returnData[0];
                                    console.log(firstItem, 'firstItem')
                                    setFieldValue(
                                        "licenseExpirydate",
                                        formatDate(firstItem.expiryDate)
                                    );
                                    handleparentsuccess(['success', `Valid License Number ${corporateLicenseNumber}`]);
                                } else {
                                    handleparentsuccess(["error", "Invalid License Number"]);
                                }
                            })
                            .catch((error) => {
                                console.error("Error Details:", error);
                                console.error(
                                    "Error Data:",
                                    error.response ? error.response.data : "No response data"
                                );
                                console.error(
                                    "Error Status:",
                                    error.response ? error.response.status : "No response status"
                                );
                            });
                    } else {
                        handleparentsuccess(["error", "Authentication Failed"]);
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    };

    const handleView = () => {
        setView(!view)
    }

    return (
        // <div>
        //     <form action="none" onSubmit={handleSubmit} className='details-form'>
        //         <div className="df-all">
        //             <div className="all-input-lda">
        //                 <RadioInput row={true} label="Type" name="licenseType" value={values.licenseType} checked={values.licenseType} onChange={handleChange} onBlur={handleBlur} error={errors.licenseType} touched={touched.licenseType} options={licenseTypeOptions} isMandatory />

        //                 <TextBox isMandatory  label="License Number" placeholder="Number" name="licenseNumber" value={values.licenseNumber} onChange={handleChange} onBlur={handleLicenseNum} error={errors.licenseNumber} touched={touched.licenseNumber} />
        //                 <TextBox isMandatory type="date" label="Expiry Date" placeholder="" name="licenseExpirydate" value={values.licenseExpirydate} onChange={handleChange} onBlur={handleBlur} error={errors.licenseExpirydate} touched={touched.licenseExpirydate} />
        //             </div>
        //         </div>
        //         <div className="property-details-buttons" >
        //             <button className="pdb-cancel" type='reset' onClick={() => handleparentsuccess(['warn', 'Changes are cancelled'])}>
        //                 Cancel
        //             </button>
        //             <button className="pdb-save-changes" type='submit' >
        //                 Save Changes
        //             </button>
        //         </div>
        //     </form>
        // </div>
        <div style={{ background: '#fff' }}>
            <div className='pl-4 pr-4 pt-4 mainLabel'>
                <div>Licensing Details </div>
                {edit && view ?   <div className='edit' onClick={handleView}><FiEdit /> Edit </div> : null}
            </div>
            {edit && view ? <div className="all-input pl-4 pr-4 pb-2">
                <div className="details-form w-100">
                    <div className="contacts-group d-flex">
                        <div className='col-lg-4'>
                            <ShowValue displayrow={false} Label='Working Address' setData={getDetails?.licenseType} />
                        </div>
                        <div className='col-lg-4'>
                            <ShowValue displayrow={false} Label='Rea Portal Name' setData={getDetails?.licenseNumber} />
                        </div>
                        <div className='col-lg-4'>
                            <ShowValue displayrow={false} Label='Domain Portal Name' setData={getDetails?`${format(new Date(getDetails?.licenseExpirydate), "dd/MM/yyyy")}`:''} />
                        </div>
                    </div>
                </div>
            </div> :
                <form onSubmit={handleSubmit} className="details-form" style={{ display: "initial", width: "100%" }}>
                    <div className="p-4 contacts-group">
                        <RadioInput row={true} label="Type" name="licenseType" value={values.licenseType} checked={values.licenseType} onChange={handleChange} onBlur={handleBlur} error={errors.licenseType} touched={touched.licenseType} options={licenseTypeOptions} isMandatory />
                        <TextBox isMandatory label="License Number" placeholder="Number" name="licenseNumber" value={values.licenseNumber} onChange={handleChange} onBlur={handleLicenseNum} error={errors.licenseNumber} touched={touched.licenseNumber} />
                        <TextBox isMandatory type="date" label="Expiry Date" placeholder="" name="licenseExpirydate" value={values.licenseExpirydate} onChange={handleChange} onBlur={handleBlur} error={errors.licenseExpirydate} touched={touched.licenseExpirydate} />
                    </div>
                    <div className="property-details-buttons">
                        <button className="pdb-cancel" type="reset">
                            Cancel
                        </button>
                        <button className="pdb-save-changes" type="submit">
                            Save
                        </button>
                    </div>
                </form>}
        </div>
    )
}

export default LicensingDetailsAgent