import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  SearchUserByContact,
  addExistingUser,
  addNewUserOpenHome,
} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { addNewUserOpenHomeValidation } from "../../../components/CommonSection/Schema/schema";
import { BiEnvelope, BiPhone, BiRightArrowAlt } from "react-icons/bi";
import {addParametersForProfiles,dynamicUrl} from "../../../Utilities/profileManagement";
import Header from "../../../components/CommonSection/Header/Header";
import Sidebar from "../../../components/CommonSection/SideBar/sideBar";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";

const initialValues = {
  firstName: "",
  lastName: "",
  ContactNo: "",
  email: "",
};

const AddNewUser = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
}) => {
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("ListOpenHome");
  const [topicValue, setTopicValue] = useState("Profile");
  const [isUserExist, setIsUserExist] = useState(false);
  const [OpenHomeUserId, SetOpenHomeUserId] = useState("");
  const ToastRef = useRef();

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgencyUID(parsedData.agencyUniqueID);
        } else {
          navigate("/signin");
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
    }
  }, [getDetails]);
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addNewUserOpenHomeValidation,
    onSubmit: (values, action) => {
      debugger;
      AddNewUser();
    },
  });

  const AddNewUser = () => {
    var urls = new URL(window.location.href);
    var OpenHomeUniqueId = urls.searchParams.get("OpenHomeUniqueId");
    let formData = {
      FirstName: values.firstName,
      LastName: values.lastName,
      Email: values.email,
      Phone: values.ContactNo,
      OpenHomeUniqueId: OpenHomeUniqueId,
      FromBackend: true,
    };
    const url = addNewUserOpenHome;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          dynamicUrl(
            navigate,
            "OpenHomeDetails" + "?OpenHomeUniqueId=" + OpenHomeUniqueId
          );
        } else {
          ToastRef.current.notify(["warn", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["warn", returnData.message]);
      });
  };

  const handleTopicValue = (text) => {
    setTopicValue(text);
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const handleClear = () => {
    var urls = new URL(window.location.href);
    var OpenHomeUniqueId = urls.searchParams.get("OpenHomeUniqueId");
    resetForm();
    dynamicUrl(
      navigate,
      "OpenHomeDetails" + "?OpenHomeUniqueId=" + OpenHomeUniqueId
    );
    ToastRef.current.notify(["warn", "Changes are cancelled"]);
  };

  return (
    <div className="dashboard-agency">
      <Header />
      <ToastAlert ref={ToastRef} />
      <div
        className="blank_space"
        style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
      ></div>
      <div className="dashboardagency-content">
        <Sidebar
          visible={sidebarVisible}
          handleTopicValue={handleTopicValue}
          topicValue={activeTopic}
        />
        <form
          onSubmit={handleSubmit}
          className="details-form"
          style={{ display: "initial", width: "100%" }}
        >
          <div className="property-details-content df-all all-input">
            <div className="contacts-group" style={{ padding: "0px 30px" }}>
              <TextBox
                isMandatory
                label="First Name"
                placeholder="First name"
                id="Unit"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.firstName}
                touched={touched.firstName}
              />
              <TextBox
                label="Last Name"
                placeholder="Last name"
                id="lastName"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.lastName}
                touched={touched.lastName}
              />
              <TextBox
                isMandatory
                label="Phone"
                placeholder="Phone"
                name="ContactNo"
                value={values.ContactNo}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.ContactNo}
                touched={touched.ContactNo}
                icon={<BiPhone height="100%" width="100%" />}
              />
              <TextBox
                label="Email Address"
                placeholder="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email}
                touched={touched.email}
                icon={<BiEnvelope height="100%" width="100%" />}
              />
            </div>
          </div>

          <div className="property-details-buttons">
            <button className="pdb-cancel" type="reset" onClick={handleClear}>
              Cancel
            </button>
            <button className="pdb-save-changes" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewUser;
