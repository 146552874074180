import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { GetCookie, currentProfile } from "../../../../components/CommonSection/Cookie/Session";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { getmaintenancebyuniqueid, addupdatemessagethread,FilePath,defaultProfilePic,getsupplierslistwithtype } from "../../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner,uploadToS3WithSpinner  } from "../../../../Utilities/client";
import { ActionStatus } from "../../../../Utilities/enum";
import "./../maintainance.css";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import ChatBox from "../../../input-components/chatbox";
import TextArea from "../../../input-components/textarea";
import DocumentUpload from "../../../input-components/documentUpload";
import { FiSend } from "react-icons/fi";

const ViewIssueDetails = () => {
    let selectedFile ;
    const [issueDetails, setIssueDetails] = useState({});
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const ToastRef = useRef();
    const urlParams = new URLSearchParams(window.location.search);
    const maintenanceUId = urlParams.get("maintenanceuid");
    const isNew = urlParams.get("isNew");
    const userProfile = urlParams.get("userid");
    const userId = urlParams.get("usertype");
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const inputRef = useRef();
    const [propertyImages, setPropertyImages] = useState("");
    const [propertyimageitems, setPropertyimageitems] = useState([]);
    const [agentId, setAgentId] = useState(false);
        const [supplierslistwithtype, setSupplierslistwithtype] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const currentSideBar = currentProfile.get();
    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setAgentId(parsedData.agentId);

                } else {
                    navigate("/signin");
                }
            } catch (error) {
                console.error("Error retrieving data from AsyncStorage:", error);
            }
        };
        retrieveData();
    }, [userId]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const maintenanceUId = urlParams.get("maintenanceuid");
        const isNew = urlParams.get("isNew");
        if (maintenanceUId) {
            getIssueDetails(maintenanceUId, isNew);
        }
        window.scrollTo(0, 0);
    }, []);

    const getsupplierslistDetails = (maintenanceUId, isNew) => {
            let formData = {
                AgencyId: issueDetails.agencyId,
                IssueType: issueDetails.issueType
            }
            const url = getsupplierslistwithtype;
            makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData, null, false)
                .then((returnData) => {
                    debugger
    
                    if (returnData.status === 0) {
                        setSupplierslistwithtype(returnData.list)
                    } else {
                        //ToastRef.current.notify(["error", returnData.message]);
                    }
                })
                .catch((error) => {
                    //ToastRef.current.notify(["error", error.message]);
                });
        };


    const getIssueDetails = (maintenanceUId, isNew) => {
        const url = `${getmaintenancebyuniqueid}?maintenaceUId=${maintenanceUId}&UpdateNewFlag=${isNew == null ? false : isNew}`;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "get")
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    setIssueDetails(returnData.object);
                    const sortedMessages = [...returnData.object?.additionNotes].sort((a, b) =>
                        new Date(a.createdAt) - new Date(b.createdAt)
                    );
                    setMessages(sortedMessages);
                } else {
                    //ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                //ToastRef.current.notify(["error", error.message]);
            });
    };

    

    const sendMessage = (data) => {
        const payload = {
            Id: 0,
            ModuleType: 2,
            ModuleReference: issueDetails.maintenanceId,
            UserType: userProfile,
            UserId: userId,
            Message: inputMessage,
            ReceipientType: userProfile === 2 ? issueDetails.reportedBy : 2,
            ReceipientReference:userProfile === 2 ? issueDetails.reportedReference : issueDetails.agencyId,
            PhotoPaths: propertyImages
        };
        const url = addupdatemessagethread;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", payload, null, false)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    setInputMessage("");
                    getIssueDetails(maintenanceUId, isNew);
                    setPropertyImages("")
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const select = (event) => {
        inputRef.current.click();
    };

    const handleUplodeImg = async (e, indexforfiles) => {
        for (let i = 0; i < e.target.files.length; i++) {
            selectedFile = e.target.files[i];
            await handleUpload();
        }
    };

    const handleUpload = async () => {
        await uploadToS3WithSpinner(
            showSpinner,
            hideSpinner,
            "messagethreads/",
            `messagethreads_${issueDetails.propertyId}`,
            selectedFile
        ).then((returnData) => {
            if (returnData.status == ActionStatus.Successfull) {
                const fileName1 = returnData.Key.split('/').pop();
                const transformedItem = {
                    id: Math.floor(Math.random() * 1000000),
                    src: FilePath + returnData.Key,
                    width: 1,
                    height: 1,
                    fileName: fileName1
                };
                setPropertyimageitems((prevItems) => [...prevItems, transformedItem]);
                setPropertyImages((prevDocuments) => 
                    prevDocuments
                        ? `${prevDocuments},${returnData.Key}` // Append new path to the existing string
                        : returnData.Key // If empty, initialize with the first file path
                );
            } else {
                // handleparentsuccess([
                //   "error",
                //   "Error uploading file. Please try again.",
                // ]);
            }
        });
    };

    const handleImagesSorted = (sortedImages) => {
        const relativePaths = sortedImages.map((image) => {
            const url = image.src; // Get the `src` property of each image object
            const parts = url.split(".com/"); // Split the URL at '.com/'
            return parts[1]; // Get the relative part after '.com/'
        });
        setPropertyImages(relativePaths)
    };
   
    const handleCheckboxChange = (checkedValues) => {
        setSelectedValues(checkedValues);
    };

    return (
        <div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
                    <div className="material-work-desk-new bg-white m-3">
                        <ToastAlert ref={ToastRef} />
                        <div className="correspondence-container">
                            <div className="pl-2 fw-bold">{"Additional Information"}</div>
                            <div className="mt-3">
                            <div className="pl-2 d-flex align-items-center">
                                    <div className="col-lg-5 col-sm-6 labelMaintainance mb-3 pl-0">
                                        Tracking ID
                                    </div>
                                    <div className="col-lg-6 col-sm-10 mb-3 pl-0">
                                        {issueDetails.maintenanceUniqueId}
                                    </div>
                                </div>
                                <div className="pl-2 d-flex align-items-center">
                                    <div className="col-lg-5 col-sm-6 labelMaintainance mb-3 pl-0">
                                    Property Address
                                    </div>
                                    <div className="col-lg-6 col-sm-10 mb-3 pl-0">
                                        {issueDetails.propertyAddress}
                                    </div>
                                </div>
                                <div className="pl-2 d-flex align-items-center">
                                    <div className="col-lg-5 col-sm-6 labelMaintainance mb-3 pl-0">
                                    Issue Type
                                    </div>
                                    <div className="col-lg-6 col-sm-10 mb-3 pl-0">
                                        {issueDetails.sIssueType}
                                    </div>
                                </div>

                                <div className="pl-2 d-flex align-items-center">
                                    <div className="col-lg-5 col-sm-6 labelMaintainance mb-3 pl-0">
                                    Status
                                    </div>
                                    <div className="col-lg-6 col-sm-10 mb-3 pl-0">
                                        {issueDetails.sStatus}
                                    </div>
                                </div>
                            </div>
                            {/* {messages.map((msg) => (
                                <div key={msg.id} className="correspondence-item">
                                    <div className="left-sections">
                                        <div className="logo">
                                            <img
                                                src={messages.profilePic ? `${FilePath}${messages.profilePic}` : `${FilePath}${defaultProfilePic}`}
                                                alt="Foreal Logo"
                                                width={10}
                                            />
                                        </div>
                                        <div>
                                            <div className="user-name mt-1">{msg.createdBy}</div>
                                            <div className="timestamp">
                                                {new Date(msg.createdAt).toLocaleString("en-GB", {
                                                    weekday: "short",
                                                    day: "numeric",
                                                    month: "short",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    second: "2-digit",
                                                    timeZoneName: "short",
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-section">
                                        <p>{msg.message}</p>
                                        <div className="d-flex">
                                        {msg.photoPaths?.split(',').map((photoPath, index) => (
                                                <img
                                                    key={index}
                                                    src={`${FilePath}${photoPath.trim()}`} // Trim to avoid extra spaces
                                                    alt={`uploaded-image-${index}`}
                                                    className="upload-iconMaintainance1 mr-5"
                                                />
                                            ))}
                                            </div>
                                    </div>
                                </div>
                            ))}
                           */}
                                    <div className="message-tab-container mr-5 ml-5 mt-2">
                                        {/* Message Display Section */}
                                        <div className="message-display-container">
                                            {messages.length > 0 ? (
                                                <div className="messages-list">
                                                    {messages.map((msg) => (
                                                        <div
                                                            key={msg.id}
                                                            className={`message-item ${msg.isSender ? "sent-message" : "received-message"}`}
                                                        >
                                                            {/* Sender Details */}
                                                            <div className="sender-info">
                                                                <img
                                                                    src={msg.profilePic ? `${FilePath}${msg.profilePic}` : `${FilePath}${defaultProfilePic}`}
                                                                    alt="User"
                                                                    className="sender-avatar"
                                                                />
                                                                <div className="sender-details">
                                                                    <span className="sender-name">{msg.createdBy}</span>
                                                                    <span className="message-timestamp">
                                                                        {new Date(msg.createdAt).toLocaleString("en-GB", {
                                                                            weekday: "short",
                                                                            day: "numeric",
                                                                            month: "short",
                                                                            year: "numeric",
                                                                            hour: "2-digit",
                                                                            minute: "2-digit",
                                                                            second: "2-digit",
                                                                            timeZoneName: "short",
                                                                        })}
                                                                    </span>
                                                                    <div className="message-text">{msg.message}</div>

                                                                </div>

                                                            </div>

                                                            {/* Message Text */}

                                                            {/* Attachments */}
                                                            {msg.photoPaths?.length > 0 && (
                                                                <div className="message-attachments">
                                                                    {msg.photoPaths.split(",").map((photoPath, index) => (
                                                                        <img
                                                                            key={index}
                                                                            src={`${FilePath}${photoPath.trim()}`}
                                                                            alt={`attachment-${index}`}
                                                                            className="attachment-thumbnail"
                                                                        />
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div className="no-messages">No messages to display</div>
                                            )}
                                        </div>

                                        {/* Input Section */}
                                        <div className="message-input-section">
                                            <div className="message-input-container input-wrapper">
                                                <TextArea
                                                    colVal={12}
                                                    className="message-input"
                                                    placeholder="Type your message..."
                                                    value={inputMessage}
                                                    onChange={(e) => setInputMessage(e.target.value)}
                                                />
                                                <DocumentUpload
                                                    label=""
                                                    inputRef={inputRef}
                                                    onClickHandler={select}
                                                    handleFileChange={handleUplodeImg}
                                                    images={propertyimageitems}
                                                    setImages={setPropertyimageitems}
                                                    onImagesSorted={handleImagesSorted}
                                                    attachment={true}
                                                />
                                            </div>
                                            <button className="send-button" >
                                                <FiSend onClick={sendMessage} />
                                            </button>
                                        </div>

                                        {/* Supplier Approval Section */}
                                        {/* {issueDetails.sStatus !== "Repair Approved" && currentSideBar !== 13 && (
                                            <div className="supplier-approval-section">
                                                <Checkbox
                                                    className="approval-checkbox"
                                                    style={{ color: "cadetblue", fontWeight: "600" }}
                                                >
                                                    Approve & Send Notification to Supplier
                                                </Checkbox>

                                                {showSuppliers && (
                                                    <div className="supplier-list">
                                                        <Checkbox.Group
                                                            value={selectedValues}
                                                            onChange={handleCheckboxChange}
                                                            className="supplier-checkbox-group"
                                                        >
                                                            {supplierslistwithtype.map((item) => (
                                                                <Checkbox key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </Checkbox>
                                                            ))}
                                                        </Checkbox.Group>
                                                        <button
                                                            className="approve-send-button"
                                                            onClick={() => handleApproveReject("Approved", true)}
                                                        >
                                                            Approve & Send
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        )} */}
                                    </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewIssueDetails;
