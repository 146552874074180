import React, { useEffect, useState, useMemo, useRef } from "react";
import debounce from "lodash/debounce";
import "./listDetails.css";
import { Checkbox, Radio, Select, Spin,Slider ,Input,Button ,Pagination } from "antd";
import {BiCar,BiBed,BiBath} from "react-icons/bi";
import {LandingPageListDetails,FilePath,LandingPageSearch,ViewSelectedPostCode} from "../../CommonSection/apiresources";
import { useNavigate } from "react-router-dom";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";
import FooterNew from "../FooterNew/FooterNew";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Topbar from '../../../src/navbar/navbar';
const { Option } = Select;

const ListDetails = () => {
  const ToastRef = useRef();
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [mainData, setMainData] = useState([]);
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [defaultSearch, setDefaultSearch] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedBed, setSelectedBed] = useState(0);
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(10); 
  const [totalRecords, setTotalRecords] = useState(0);

  const navigate = useNavigate();
  const fetchRef = useRef(0);
  const [catId, setCatId] = useState("");
  const [currentImageIndexes, setCurrentImageIndexes] = useState({});
  const [filters, setFilters] = useState({
    suburb: "",
    propertyType: "",
    priceRange: [0, 0],
    bedrooms: "",
    livingArea: "Any",
    bathrooms: "Any",
    carSpace: "Any",
    landSize: { min: "", max: "" },
    propertyStatus: "Any",
    saleMethod: "All Types",
  });
  const url = new URL(window.location.href);
  const newCatId = url.searchParams.get("catId");
 
  const propertyOptions = [
    { label: "Acreage/Semi-rural", value: 1 },
    { label: "Alpine", value: 2 },
    { label: "Apartment", value: 3 },
    { label: "Block Of Units", value: 4 },
    { label: "Duplex/Semi-detached", value: 5 },
    { label: "Flat", value: 6 },
    { label: "House", value: 7 },
    { label: "Retirement Living", value: 8 },
    { label: "Serviced", value: 9 },
    { label: "Studio", value: 10 },
    { label: "Terrace", value: 11 },
    { label: "Town House", value: 12 },
    { label: "Unit", value: 13 },
    { label: "Villa", value: 14 },
    { label: "Ware House", value: 15 },
    { label: "Land", value: 16 },
    { label: "Other", value: 17 },
  ];


  useEffect(() => {
    var url = new URL(window.location.href);
    var Minbeds = url.searchParams.get("Minbeds");
    var Search = url.searchParams.get("Search");
    var MinPrice = url.searchParams.get("MinPrice");
    filteredDetailsList(Search);
    setSelectedBed(parseInt(Minbeds, 10));
    setSelectedPrice(parseInt(MinPrice, 10));
    if (newCatId) {
      // Call your API function with the updated catId
      getAllDetailsList(Search, 1, 10, newCatId);
    }
  }, [newCatId]);

  useEffect(() => {
    if (mainData.length > 0) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [mainData]);
  

  const filteredDetailsList = (Search, currentPage, totalRows) => {
    const baseUrl = ViewSelectedPostCode;
    const data = {
      ids: Search ? Search : "",
      PageNo: currentPage ? currentPage : 1,
      RecordsPerPage: totalRows ? totalRows : 10,
    };
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      baseUrl,
      "post",
      data,
      null,
      false
    )
      .then((response) => {
        if (response.status === 0) {
          let landingPageListData = response.list;
          const selectedValues = Search != null ? Search.split(",") : "";
          setDefaultSearch(selectedValues);
          const transformedData = landingPageListData.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setOptions(transformedData);
          const outputArray = transformedData.map((item) => item.label);
          setSelectedValues(outputArray);
        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getAllDetailsList = (Search, page = currentPage, totalRows = rowsPerPage, catId) => {
    const baseUrl = LandingPageListDetails;
  
    const minPrice = filters.priceRange[0] === 0 && filters.priceRange[1] === 0
      ? null
      : parseFloat(filters.priceRange[0]);
    const maxPrice = filters.priceRange[0] === 0 && filters.priceRange[1] === 0
      ? null
      : parseFloat(filters.priceRange[1]);

    const searchValues = filters.suburb
    ? filters.suburb.map((suburb) => suburb.value).join(",")
    : null;
  
    const data = {
      SortBy: "CreatedDate",
      SortOrder: "Desc",
      RecordsPerPage: totalRows,
      PageNo: page,
      PropertyType: filters.propertyType,
      MinPrice: minPrice,
      MaxPrice: maxPrice,
      Minbeds: filters.bedrooms ? parseInt(filters.bedrooms, 10) : null,
      Bathrooms: filters.bathrooms === "Any" ? null : parseInt(filters.bathrooms, 10),
      CarParking: filters.carSpace === "Any" ? null : parseInt(filters.carSpace, 10),
      LivingArea: filters.livingArea === "Any" ? null : parseInt(filters.livingArea, 10),
      MinLand: filters.landSize.min ? parseFloat(filters.landSize.min) : null,
      MaxLand: filters.landSize.max ? parseFloat(filters.landSize.max) : null,
      IsNew: filters.propertyStatus === "New" ? 1 : filters.propertyStatus === "Established" ? 2 : null,
      cat: newCatId ? newCatId : 1,
      Search:searchValues 
    };
  
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data, null, false)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          let landingPageListData = response.list;
          cardDetails(landingPageListData);
          setTotalRecords(response.totalCount); // Update the total records
          setTotalPages(Math.ceil(response.totalCount / totalRows)); // Update total pages
        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  
  const cardDetails = (landingPageListData) => {
    let meetingCardsData = landingPageListData.map((item) => {
      let landAreaLabel = "";

      switch (item.propertyLandAreaW) {
        case 1:
          landAreaLabel = "Feed";
          break;
        case 2:
          landAreaLabel = "Sgms";
          break;
        case 3:
          landAreaLabel = "Acres";
          break;
        case 4:
          landAreaLabel = "Hectares";
          break;
        default:
          landAreaLabel = "Unknown"; // Default label if the value is unexpected
      }
      return {
        title: item.propertyHeading,
        description: item.agencyName,
        address: item.getFormatedAddress,
        price: `${
          item.showText
            ? item.showText
            : item.propertyPrice !== null && item.propertyPrice > 0
            ? `$ ${item.propertyPrice}`
            : ""
        }`,
        nearby: "Nearby Placeholder",
        features: [
          {
            label: `${
              item.countBedrooms ? item.countBedrooms : 0
            } BHK Apartment`,
            value: `${
              item.propertyLandAreaL ? item.propertyLandAreaL : 0
            }-${landAreaLabel}`,
          },
        ],
        additionalFeatures: [
          "Highway secured society",
          "MRL Auto-door branded",
          "MRL Auto",
        ],
        bedroom: item.countBedrooms ? item.countBedrooms : "N/A",
        bathroom: item.countBathrooms ? item.countBathrooms : "N/A",
        car: item.countCarParking ? item.countCarParking : "N/A",
        rating: 1,
        imageUrls:
          item.propertyPics.length > 0
            ? item.propertyPics.map((pic) => ({
                itemImageSrc: FilePath + pic,
                thumbnailImageSrc: FilePath + pic,
                alt: "Description for Image",
                title: "Title",
              }))
            : [
                {
                  itemImageSrc: FilePath + "property/property-dummy-image.png",
                  thumbnailImageSrc:
                    FilePath + "property/property-dummy-image.png",
                  alt: "Default Image",
                  title: "Default Title",
                },
              ],
        agentProfilePic: item.agentProfilePic
          ? FilePath + item.agentProfilePic
          : `${FilePath}profile/default.png`,
        propertyType: item.sPropertyType,
        propertyUniqueID: item.propertyUniqueID,
        propertyID: item.propertyId,
        agentName: item.agentName,
        agentContact: item.agentContact,
        agentEmail: item.agentEmail,
      };
    });
    setMainData(meetingCardsData);
    setIsLoading(false);
  };

  const handleMoreDetails = (propertyUniqueId, propertyId) => {
    navigate(
      "/landingPageDetails?propertyUniqueId=" +
        propertyUniqueId +
        "&propertyId=" +
        propertyId
    );
  };


  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      const baseUrl = LandingPageSearch;
      const data = {
        Search: value,
      };
      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        null,
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;

            // Transform data to match Select options
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name,
            }));

            // Update options and fetching state
            if (fetchId === fetchRef.current) {
              setOptions(transformedData);
              setFetching(false);
            }
          } else {
            setFetching(false);
          }
        })
        .catch((error) => {
          setFetching(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);



  const handleNextImage = (property) => {
    setCurrentImageIndexes((prevIndexes) => {
      const currentIndex = prevIndexes[property.propertyUniqueID] || 0;
        const nextIndex =
        currentIndex >= property.imageUrls.length - 1 ? 0 : currentIndex + 1;
      return {
        ...prevIndexes,
        [property.propertyUniqueID]: nextIndex,
      };
    });
  };
  

  const handlePreviousImage = (property) => {
    setCurrentImageIndexes((prevIndexes) => {
      const currentIndex = prevIndexes[property.propertyUniqueID] || 0;
        const prevIndex =
        currentIndex <= 0 ? property.imageUrls.length - 1 : currentIndex - 1;
      return {
        ...prevIndexes,
        [property.propertyUniqueID]: prevIndex,
      };
    });
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleLandSizeChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      landSize: {
        ...prevFilters.landSize,
        [key]: value,
      },
    }));
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page); // Update current page
    setRowsPerPage(pageSize); // Update rows per page
    getAllDetailsList(undefined,page, pageSize); // Fetch data for the new page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
        <Topbar/>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
          <div className="landingpage">
            <ToastAlert ref={ToastRef} />
            <div className="app">
            {mainData.length > 0 ? (
              <div className="filter-section">
                <h3 className="filter-header">Filter</h3>
                <Button
                  type="link"
                  danger
                  className="clear-button"
                  onClick={() =>
                    setFilters({
                      suburb: "",
                      propertyType: "",
                      priceRange: [0, 10000],
                      bedrooms: "",
                      livingArea: "Any",
                      bathrooms: "Any",
                      carSpace: "Any",
                      landSize: { min: "", max: "" },
                    })
                  }
                >
                  Clear all
                </Button>
                {/* Suburb Filter */}
                <div className="filter-group">
                  <label>Search for suburb or postcode</label>
                  <Select
                    mode="multiple"
                    placeholder="Search for suburb or postcode"
                    labelInValue
                    filterOption={false}
                    onSearch={debounceFetcher}
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    style={{ width: "100%" }}
                    options={options}
                    onChange={(value) => handleFilterChange("suburb", value)}
                    value={filters.suburb}
                    />
                </div>
                {/* Property Type Filter */}
                <div className="filter-group">
                  <label>Property Type</label>
                  <Select
                    placeholder="Select property type"
                    value={filters.propertyType}
                    onChange={(value) => handleFilterChange("propertyType", value)}
                    style={{ width: "100%" }}
                  >
                    {propertyOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </div>
                {/* Price Range Filter */}
                <div className="filter-group">
                  <label>Price Range</label>
                  <Slider
                    range
                    min={0}
                    max={10000}
                    defaultValue={filters.priceRange}
                    onChange={(value) => handleFilterChange("priceRange", value)}
                    />
                  <div className="slider-values">
                    <span>${filters.priceRange[0]}</span>
                    <span>${filters.priceRange[1]}</span>
                  </div>
                </div>
                {/* Bedrooms Filter */}
                <div className="filter-group">
                  <label>Bedrooms</label>
                  <Input
                    type="number"
                    placeholder="Enter bedrooms"
                    value={filters.bedrooms}
                    onChange={(e) => handleFilterChange("bedrooms", e.target.value)}

                  />
                </div>
                {/* Living Area Filter */}
                <div className="filter-group">
                  <label>Living Area</label>
                  <div className="range-buttons">
                    {["Any", "1+", "2+", "3+", "4+", "5+"].map((val) => (
                      <Button
                        key={val}
                        type={filters.livingArea === val ? "primary" : "default"}
                        onClick={() => handleFilterChange("livingArea", val)}
                        >
                        {val}
                      </Button>
                    ))}
                  </div>
                </div>
                {/* Bathrooms Filter */}
                <div className="filter-group">
                  <label>Bathrooms</label>
                  <div className="range-buttons">
                    {["Any", "1+", "2+", "3+", "4+", "5+"].map((val) => (
                      <Button
                        key={val}
                        type={filters.bathrooms === val ? "primary" : "default"}
                        onClick={() => handleFilterChange("bathrooms", val)}
                        >
                        {val}
                      </Button>
                    ))}
                  </div>
                </div>
                {/* Car Space Filter */}
                <div className="filter-group">
                  <label>Car Space</label>
                  <div className="range-buttons">
                    {["Any", "1+", "2+", "3+", "4+", "5+"].map((val) => (
                      <Button
                        key={val}
                        type={filters.carSpace === val ? "primary" : "default"}
                        onClick={() => handleFilterChange("carSpace", val)}
                        >
                        {val}
                      </Button>
                    ))}
                  </div>
                </div>
                {/* Land Size Filter */}
                <div className="filter-group">
                  <label>Land Size</label>
                  <div className="land-size-inputs">
                    <Input
                      type="number"
                      placeholder="Min"
                      value={filters.landSize.min}
                      onChange={(e) => handleLandSizeChange("min", e.target.value)}
                      />
                    <span className="land-size-separator">—</span>
                    <Input
                      type="number"
                      placeholder="Max"
                      value={filters.landSize.max}
                      onChange={(e) => handleLandSizeChange("max", e.target.value)}
                      />
                  </div>
                </div>

                <div className="filter-group">
                  <label>New or Established Property</label>
                  <Radio.Group
                    value={filters.propertyStatus}
                    onChange={(e) =>
                      handleFilterChange("propertyStatus", e.target.value)
                    }
                    style={{display:'flex'}}
                  >
                    <Radio value="Any">Any</Radio>
                    <Radio value="New">New</Radio>
                    <Radio value="Established">Established</Radio>
                  </Radio.Group>
                </div>
      <div className="filter-group">
      <Button
      style={{width:'100%'}}
                        type={"primary"}
                        onClick={getAllDetailsList}
                        >
                        Search
                      </Button>
      </div>
              </div>) : null }
              <div className="property-listing">
                <div className="property-listing-grid">

                  {mainData.length > 0 ? (
                    mainData.map((property, index) => {
                      const currentIndex =
                        currentImageIndexes[property.propertyUniqueID] || 0;
                      return (
                        <div
                          key={property.propertyUniqueID || index}
                          className="property-card"
                        >
                          <div className="property-image-slider">
                            <LeftOutlined
                              className="slider-arrow"
                              onClick={() => handlePreviousImage(property)}
                            />
                            <img
                              src={
                                property.imageUrls && property.imageUrls.length > 0
                                  ? property.imageUrls[currentIndex].itemImageSrc
                                  : "https://via.placeholder.com/300"
                              }
                              alt={property.address}
                            />
                            <RightOutlined
                              className="slider-arrow"
                              onClick={() => handleNextImage(property)}
                            />
                          </div>

                          {/* Property Type Badge */}
                          <div className="property-type">
                            {property.propertyType || "Type"}
                          </div>

                          <div>
                            {/* Property Details */}
                            <div className="property-details1" onClick={() => handleMoreDetails(property.propertyUniqueID, property.propertyID)}
                            >
                              <div className="propertyAdrress">{property.address}</div>
                              <div className="propertyPrice">
                                {property.price}
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                              {property.sPropertyType != "Land" ?
                                <div className="property-info1">
                                {property.bedroom != "N/A"?
                                  <span>
                                    <BiBed style={{ marginRight: "2px" }} />
                                    {property.bedroom} Bed
                                  </span> : null }
                                  {property.bathroom != "N/A"?
                                  <span>
                                    <BiBath style={{ marginRight: "2px" }} />
                                    {property.bathroom} Bath
                                  </span>: null }
                                  {property.car != "N/A"?
                                  <span>
                                    <BiCar style={{ marginRight: "2px" }} />
                                    {`${property.car} Car`}
                                  </span>: null }
                                </div> : null }
                                {/* <div className="rating1">
                                  ⭐ {property.rating || "N/A"}
                                </div> */}
                              </div>
                            </div>

                            {/* Agent Info */}
                            <div className="agent-info1">
                              <img
                                src={
                                  property.agentProfilePic ||
                                  "https://via.placeholder.com/50"
                                }
                                alt={property.agentName}
                                className="agent-profile-pic"
                              />
                              <div>
                                <div>{property.agentName}</div>
                                <div>{property.agentContact}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="no-records-found">
                      <h3>No Records Found</h3>
                    </div>
                  )}
                </div>
                <div className="pagination-container">
                  <Pagination
                    current={currentPage}
                    pageSize={rowsPerPage}
                    total={totalRecords}
                    onChange={handlePaginationChange}
                    showSizeChanger
                    pageSizeOptions={["10", "20", "50", "100"]}
                    responsive
                  />
                </div>

              </div>
             
            </div>
          </div>
        </div>
      </div>
      <FooterNew/>
    </div>
  );
};

export default ListDetails;
