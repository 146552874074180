import React, { useMemo, useRef, useState, useEffect } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import {ListPropertyActiviyies,SearchProperty,searchAreaOfOperation} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { Select, Spin } from "antd";
import { ActionStatus } from "../../../Utilities/enum";
import "./activities.css"
import AntdTableExample from "../../input-components/dataTable";
import SubNav from "../../navbar/subNav";


const PropertyActivitiesList = ({handleparentsuccess}) => {
  const [activitiesList, setActivitiesList] = useState([]);
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [options1, setOptions1] = useState([]);
  const fetchRef = useRef(0);
  const fetchRef1 = useRef(0);
  const [searchValue, setSearchValues] = useState("");
  const [searchValue1, setSearchValues1] = useState("");
  const [postCode, setPostCode] = useState("Search for PostCode");
  
  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) ListPropertyActivities(1,10);
  }, [userId]);

  const ListPropertyActivities = (currentPage, totalRows) => {
    const baseUrl = ListPropertyActiviyies;
    let data = {
        sortby: "CreatedDate",
        SortOrder: "Desc",
      RecordsPerPage: totalRows,
      AgencyId: agencyID,
      PropertyId : searchValue,
      PostCode : searchValue1,
      PageNo: currentPage ,
    };
    //data = addParametersForProfiles(data);
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
            if(response.list.length != 0){
              let contactData = response.list;
              let contactList = contactData
                .map((conatact) => ({
                  id: conatact.propertyUniqueId,
                  PropertyAddress: conatact.getFormatedAddress,
                  ListingType: conatact.propertySaleRental === 1 ? "Sale" : "Rental",
                  Owner: conatact.primaryContactName,
                  Enquiry: conatact.countofEnquiry,
                  Openhome: conatact.countofOpenhome,
                  SaleOffers: conatact.countofsaleoffers,
                  RentalApplications : conatact.countofRental
                }));
                setActivitiesList(contactList);
                setTotalPages(response.totalCount);
            }else{
                handleparentsuccess(["error", "No Data Found"]);
            }
        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  const handlePageChange = (event, pageNumber) => {
    ListPropertyActivities(event , event.rows);
    setFirst(event.first);
    setRows(event.rows);
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      const baseUrl = SearchProperty;
      const data = {
        Search: value,
        RecordsPerPage: 10,
        PageNo: 1,
      };

      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;

            // Transform data to match Select options
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name,
            }));

            // Update options and fetching state
            if (fetchId === fetchRef.current) {
              setOptions(transformedData);
              setFetching(false);
            }
          } else {
            setFetching(false);
          }
        })
        .catch((error) => {
          setFetching(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);

  const handleSearchProperty = (value) => {
    debounceFetcher(value);
    setSearchValues(value.value);
  };

  const debounceFetcherPostCode = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef1.current += 1;
      const fetchId = fetchRef1.current;
      setOptions1([]);
      const baseUrl = searchAreaOfOperation;
      const data = {
        Search: value,
        RecordsPerPage: 10,
        PageNo: 1,
      };

      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;

            // Transform data to match Select options
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name,
            }));

            // Update options and fetching state
            if (fetchId === fetchRef1.current) {
              setOptions1(transformedData);
            }
          } 
        })
        .catch((error) => {
          //setFetching1(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);

  const handlePostCode = (value) => {
   const numericPart = value.label.split(',').pop().trim();
    setPostCode(numericPart)
    setSearchValues1(value.value);
    debounceFetcherPostCode(value);
  };
  const handleProtalEnquiryList = () => {
    ListPropertyActivities();
  };

  const handleClearProperty = () => {
    setSearchValues("");
  };

  const handleClearPostCode = () => {
    setSearchValues1("");
    setPostCode("Search for Post code")
  };

  const handleRowClick = (event, rowData) => {
      navigate("/ViewPropertActivityDetails" + "?propertyUID=" + rowData.id);
  };


  return (
    <div>
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
        <SubNav title={"List Property Activities"} extraData={true} />
        <div className="material-work-desk-new bg-white m-3">
          <div style={{ margin: '10px' }}>
          <Select
                    style={{ width: 150 }}
                      className="landingPageSearchs mr-2"
                      mode="single"
                      showSearch
                      placeholder="Search for Property"
                      labelInValue
                      filterOption={false}
                      onSearch={debounceFetcher}
                      notFoundContent={fetching ? <Spin size="small" /> : null}
                      options={options}
                      onChange={handleSearchProperty}
                      allowClear
                      onClear={handleClearProperty}
                    />
                     <Select
                     style={{ width: 150 }}
                       className="landingPageSearchs new-css-class"
                       mode="single"
                       showSearch
                       placeholder="Search for PostCode"
                       labelInValue
                       filterOption={false}
                       onSearch={debounceFetcherPostCode}
                       notFoundContent={fetching ? <Spin size="small" /> : null}
                       options={options1}
                       onChange={handlePostCode}
                        allowClear
                       onClear={handleClearPostCode} 
                       value={postCode}
                     />
                      <button
                    className="Add-Property-button mt-4 ml-3"
                    type="button"
                    onClick={handleProtalEnquiryList}
                  >
                    <div className="text-parent">
                      <div className="text7" style={{ fontSize: "14px" }}>
                        Search
                      </div>
                    </div>
                  </button>
          </div>
          <AntdTableExample
            dataSource={activitiesList}
            globalSearch
            hideCol={"AgencyId"}
            onPageChange={handlePageChange}
            totalRows={rows}
            totalRecords={totalPages}
          showCheckbox
          onRowClick={handleRowClick}
          displayDoc
          />
        </div>
      </div>
    </div>
  </div>
  );
};

export default PropertyActivitiesList;
