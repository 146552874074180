import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { resetEmail, generatePdfReport, resendcompletedagreement } from "../../../components/CommonSection/apiresources";
import { currentProfile, } from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner, DownloadFiletWithSpinner, } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import "../../../components/Dashboard/Dashboard-agent/AgentList.css";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { ActionStatus } from "../../../Utilities/enum";
import "../openHome/openHome.css";
import "../Task/task.css";
import moment from "moment";
import { format } from "date-fns";
import CustomModal from "../../input-components/Modal";
import TextBox from "../../input-components/textbox";
import { Divider } from "antd"
import AntdTableExample from "../../input-components/dataTable";
import { Steps } from "antd";
const { Step } = Steps;

const Summary = ({getDetails,reportTo,handleAddNewAgreement}) => {
  const [agreementlist, setAgreementList] = useState([]);
  const navigate = useNavigate();
  const ToastRef = useRef();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const signatureRef = useRef(null);
  const [isSigned, setIsSigned] = useState(false);
  const [isOwnerSigned, setIsOwnerSigned] = useState(false);
  const [isAgentSigned, setIsAgentSigned] = useState(false);
  const [date, setDate] = useState("");
  const [openReset, setOpenReset] = useState(false);
  const [email, setEmail] = useState(false);
  const [recipientId, setRecipientId] = useState("")
  const [activeTab, setActiveTab] = useState("Agreement Summary");
  const tabs = ["Agreement Summary", "Activity"];
  const [activitylist, setActivityList] = useState([]);

  useEffect(() => {
    if (getDetails) {
      let filteredArray = [];
      if (getDetails.agreementStatus === 10) {
        filteredArray = getDetails.documentReceipients;
        setIsSigned(true);
      } else {
        filteredArray = getDetails.documentReceipients
          ? getDetails.documentReceipients.filter(
            (item) => item.contactId !== null
          )
          : [];
      }
      setIsAgentSigned(getDetails.isAgentSigned);
      setIsOwnerSigned(getDetails.isOwnerSigned);

      let templatesList = getDetails.documentReceipients.map((task) => ({
        id: task.id,
        Name: task.name,
        Email: task.emailAddress,
        Status: !task.isSent ? "Awaiting to send" : task.isSigned ? "Completed" : "Awaiting Signature",
        ContactId: task.contactId,
        SignedDate: task.signedDate,
        IsSent: task.isSent,
        SentDate: task.sentDate,
        IsSigned: task.isSigned,
        ipAddress: task.ipAddress,
        History: `
    ${task.isSent ? "Document sent Successfully\nSent On : " + format(new Date(task.sentDate), "dd/MM/yyyy hh:mm aa") : ""}
    ${task.isSent && task.isSigned ? "\n" : ""}
    ${task.isSigned ? "Document signed Successfully\nSigned On : " + format(new Date(task.signedDate), "dd/MM/yyyy hh:mm aa") + "\nIp Address : " + task.ipAddress : ""}
  `
      }));
      setAgreementList(templatesList);
      setActivityList(getDetails.activities.activities)
    }
  }, [getDetails, reportTo]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);

  useEffect(() => {
    const today = new Date();
    setDate(today.toISOString().split("T")[0]); // Set the default value to yesterday's date
  }, []);

  const handleFinalSubmit = () => {
    let formData = {
      RecipientId: recipientId,
      EmailAddress: email,
    };
    const url = resetEmail;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          setOpenReset(false);
          window.location.reload();
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleResendAll = () => {
    let formData = {
      PropertyUniqueId: propertyUID,
      DocumentType: 8,
      DocumentId: getDetails.documentReceipients[0].documentId
    };
    const url = resendcompletedagreement;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          //window.location.reload();
          ToastRef.current.notify(["success", returnData.message]);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleClear = () => {
    navigate(`/agency/listagreementtype?Page=AgreementList`);
  };

  const handleAgreementDownload = () => {
    const formData = {
      PropertyUId: getDetails.propertyUId,
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generatePdfReport,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData != null) {
          window.open(returnData, "_blank");
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleResend = (e, data) => {
    setOpenReset(true)
    setEmail(data.Email)
    setRecipientId(data.id)
  }

  const closeReSet = () => {
    setOpenReset(false);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const actions = [
    { icon: "/assets/images/mail.svg", onClick: (e, data) => handleResend(e, data), iconSize: "30px", key: "Resend Mail" },
  ]

  return (
    <div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
            <div style={{ display: "flex",padding: "5px", background: 'white', margin: '5px 15px', backgroundColor: 'whitesmoke', borderRadius: '4px' }}>
          
            {tabs.map((tab) => (
              <div key={tab} className={`button ${activeTab === tab ? "active-tab" : ""}`} onClick={() => setActiveTab(tab)}>
                {tab}
              </div>
            ))}
           
          </div>
          {activeTab === "Agreement Summary" ?
          <div className="bg-white m-3">
            <div style={{ margin: '10px' }}>

            </div>
            <AntdTableExample
              hideCol={"id,ipAddress,ContactId,SignedDate,IsSent,SentDate,IsSigned"}
              label="List of contacts"
              dataSource={agreementlist}
              actions={actions}
            />
            <CustomModal
              title="Resend Email"
              width={500}
              closable={closeReSet}
              visible={openReset}
              onCancel={closeReSet}
              modalData={
                <div
                  style={{ marginTop: "30px", marginLeft: "10px" }}
                >
                  <TextBox label="Email" colVal={12} value={email} onChange={handleEmail} />
                </div>
              }
              footer={[
                <div
                  key="footer"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    style={{ marginRight: "15px" }}
                    className="pdb-save-changes mt-2"
                    onClick={handleFinalSubmit}
                  >
                    Submit
                  </button>
                </div>,
              ]}
            />
            {!isOwnerSigned || !isAgentSigned ? (
              <div className="property-details-buttons">
                <button className="pdb-cancel" type="reset" onClick={handleClear}>
                  Cancel
                </button>
                <button
                  className="pdb-save-changes"
                  type="button"
                  onClick={handleAgreementDownload}
                >
                  Preview
                </button>
              </div>
            ) : null}
            {isSigned ? (
              <div className="property-details-buttons">
                <button
                  className="pdb-cancel"
                  type="button"
                  onClick={handleResendAll}
                >
                  Resend Agreement
                </button>
                <button
                  className="pdb-save-changes"
                  type="button"
                  onClick={handleAgreementDownload}
                >
                  Download Agreement
                </button>
              </div>
            ) : null}
          </div>:
          <div className="bg-white m-5">
              <Steps direction="vertical"  className="customStep" current={activitylist.length - 1}>
                {activitylist.map((activity, index) => (
                  <Step
                   className="customStep1"
                    key={index}
                    title={activity.description}
                    description={moment(activity.activityDate).format("YYYY-MM-DD HH:mm:ss")}
                    status="finish" 
                  />
                ))}
              </Steps>
            </div>}
        </div>
      </div>
    </div>
  );
};

export default Summary;
