import React from "react";
import { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getaccountcharts, getInvoicelist, addupdateInvoice, FilePath, getInvoiceDetailsById, SearchProperty,payinvoices,getsupplierslist } from "../../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner, uploadToS3WithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import "../../../../components/Dashboard/Dashboard-agent/AgentList.css";
import { ActionStatus, isManualFilePath } from "../../../../Utilities/enum";
import { addParametersForProfiles } from "../../../../Utilities/profileManagement";
import SubNav from "../../../navbar/subNav";
import AntdTableExample from "../../../input-components/dataTable";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { Steps, DatePicker, message } from 'antd';
import CustomModal from "../../../input-components/Modal";
import ShowValue from "../../../input-components/ShowValue";
import { format } from 'date-fns';
import SelectBox from "../../../input-components/selectBoxNew";
import TextBox from "../../../input-components/textbox";
import TextArea from "../../../input-components/textarea";
import FileUpload from "../../../input-components/documentUpload";
import { FaDownload } from "react-icons/fa";
import debounce from "lodash/debounce";
import { Select, Spin } from "antd";
import { useFormik } from "formik";

const initialValues = {
    date: "",
    accounts: "",
    details: "",
    taxInc: false,
    totalAmt: "",
    fromInv:"",
    supplier:""
};

const ListInvoice = () => {
    let selectedFile;
    const [invoiceLists, setInvoiceLists] = useState([]);
    const [userId, setUserId] = useState("");
    const [agencyID, setAgencyId] = useState("");
    const navigate = useNavigate();
    const [propertyType, setPropertyType] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [purpose, setPurpose] = useState("");
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [rows, setRows] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [openAddProperty, setOpenAddProperty] = useState(false);
    const [getDetails, setGetDetails] = useState(null);
    const [addNew, setAddNew] = useState(false);
    const [edit, setEdit] = useState(false);
    const [view, setView] = useState(false);
    const [propertyTypeVal, setPropertyTypeVal] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [agentId, setAgentId] = useState("");
    const [totalRow, setTotalRow] = useState(10);
    const [accArr, setAccArr] = useState(10);

    const isTaxArr = [
        { value: true, label: "Yes" },
        { value: false, label: "No" },
    ];
    const fromInvArr = [
        { value: 1, label: "Owner" },
        { value: 2, label: "Supplier" },
    ];
    const [propertyDocuments, setPropertyDocuments] = useState("");
    const inputRef = useRef();
    const [propertyimageitems, setPropertyimageitems] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchValue, setSearchValues] = useState("");
    const [rowIds, setRowIds] = useState("");
    const fetchRef = useRef(0);
    const [supplierArr,setSupplierArr] = useState([]);
    const [fetchingSupplier, setFetchingSupplier] = useState(false);
    const [optionSupplier, setOptionSupplier] = useState([]);
    const [supplierValue, setSupplierValues] = useState("");
    const { Step } = Steps;
    const { RangePicker } = DatePicker;


    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setAgentId(parsedData.agentId)
                    setUserId(parsedData.userId);
                    setAgencyId(parsedData.agencyID);
                    getSupplierslist(parsedData.agencyID)
                }
            } catch (error) {
                console.error("Error retrieving data from AsyncStorage:", error);
            }
        };
        retrieveData();
    }, [userId]);

    useEffect(() => {
        getAccountList();
    }, [])

    useEffect(() => {
        if (userId) getSupportList(1, 10);
    }, [userId, searchText, purpose, propertyType, propertyTypeVal, fromDate, toDate]);
    var url = new URL(window.location.href);
    var invoiceId = url.searchParams.get("InvoiceId");

    useEffect(() => {
        if (invoiceId) {
            GetDetailsApi(invoiceId)
        }
    }, [invoiceId])

    const getSupplierslist = (agencyID) => {
        const baseUrl = getsupplierslist;
        let data = {
            AgencyId: agencyID,
            Search: '',
        };
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
            .then((response) => {
                    const supplierArr = response.list.map(item => ({
                        label: item.name + `(${item.accountFolio})`,
                        value: item.contactId
                    }));
                    setSupplierArr(supplierArr)
                
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    const getSupportList = (currentPage, totalRows) => {
        const baseUrl = getInvoicelist;
        let data = {
            AgencyId: agencyID,
            RecordsPerPage: totalRow,
            Search: searchText ? searchText : null,
        };
        if (data.SearchDateType === "") {
            delete data.SearchDateType;
        }
        data = addParametersForProfiles(data);
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    let invoiceData = response.list;
                    let invoiceList = invoiceData
                        .map((inv) => ({
                            Invoice: inv.id,
                            Tenant: inv.reference,
                            Property: inv.propertyName,
                            FolioBalance: inv.folio,
                            Account: inv.accountName,
                            Details: inv.details,
                            Amount: inv.amount,
                            Paid: inv.isPaid ? "Yes" : "No",
                            Sent: inv.isenable ? "Yes": "No",
                            id: inv.uid
                        }));
                    setTotalPages(response.totalCount)
                    setInvoiceLists(invoiceList);
                } else {
                    alert(`Message: ${response.message}`);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const getAccountList = () => {
        const baseUrl = getaccountcharts + "?type=0";
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "get")
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    const accArr = response.object.map(item => ({
                        label: item.text,
                        value: item.value
                    }));
                    setAccArr(accArr)
                } else {
                    alert(`Message: ${response.message}`);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm
    } = useFormik({
        initialValues: initialValues,
       // validationSchema: addNewUserOpenHome,
        onSubmit: (values, action) => {
            AddupdateInvoice()
        },
    });


    const AddupdateInvoice = () => {
        const baseUrl = addupdateInvoice;
        let data = {
            Id: 0,
            From: values.fromInv,
            Date: values.date,
            Account: parseInt(values.accounts),
            PropertyId: searchValue,
            Amount: parseFloat(values.totalAmt),
            IsTax: values.taxInc,
            Details: values.details,
            AgencyId: agencyID,
            attachment: propertyDocuments,
            Supplier: supplierValue ? supplierValue : null
        };
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    message.success(response.message)
                    getSupportList();
                    setOpenAddProperty(false);
                } else {
                    //ToastRef.current.notify(["error", "error"]);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    // Handler for page change
    const handlePageChange = (pageNumber, totalRows) => {
        setCurrentPage(pageNumber);
        setRows(totalRows);
        getSupportList(pageNumber, totalRows);
    };

    const tabColumns = {
        Status: {
            colorMap: {
                "Resolved": "#00A5A2",
                "Created": "#E5A000",
            },
            defaultColor: 'blue'
        }
    };

    const handleSearchValueChange = (value) => {
        setSearchText(value);
    };

    const handleSupportTickets = () => {
        setOpenAddProperty(true);
        setEdit(false);
        setView(false);
        setGetDetails(null);
        setAddNew(true);
    }

    const closeProperty = () => {
        const baseUrl = window.location.pathname;
        navigate(baseUrl);
        setOpenAddProperty(false);
        setEdit(false);
        setView(false);
        setGetDetails(null);
        setAddNew(false);
    }


    const GetDetailsApi = () => {
        const url = getInvoiceDetailsById + `?InvoiceId=${invoiceId}`;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "get", "", null, false)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    setGetDetails(response.object);
                }
            })
            .catch((error) => {
                console.error("Error fetching ticket details:", error);
            })
            .finally(() => {
                //setLoading(false); // Set loading to false once API call completes
            });
    };

    const handleView = (e, data) => {
        const baseUrl = window.location.pathname; // Get the current path without query params
        const newUrl = `${baseUrl}?InvoiceId=${data.id}`; // Append the contactUID to the URL
        navigate(newUrl);
        setOpenAddProperty(true);
        setView(true);
    }

    const handleFileChange = async (e, indexforfiles) => {
        for (let i = 0; i < e.target.files.length; i++) {
            selectedFile = e.target.files[i];
            await handleUpload(indexforfiles);
        }
    };

    const handleUpload = async (indexforfiles) => {
        await uploadToS3WithSpinner(
            showSpinner,
            hideSpinner,
            "agencyticket/",
            "agencyticket",
            selectedFile
        ).then((returnData) => {
            if (returnData.status == ActionStatus.Successfull) {
                //ToastRef.current.notify(["success", "File uploaded successfully"]);
                const fileName1 = returnData.Key.split('/').pop();
                const transformedItem = {
                    id: Math.floor(Math.random() * 1000000),
                    src: FilePath + returnData.Key,
                    width: 1,
                    height: 1,
                    fileName: fileName1
                };
                setPropertyimageitems((prevItems) => [...prevItems, transformedItem]);
                setPropertyDocuments(returnData.Key);
            } else {
                //ToastRef.current.notify(["error", "Error uploading file. Please try again."]);

            }
        });
    };
    const select = (event) => {
        inputRef.current.click();
    };

    const actions = [
        { icon: "/assets/images/dots.svg",key:"View", onClick: (e, data) => handleView(e, data) },
        // { icon: "/assets/images/editing.svg", onClick: (e, data) => handleEdit(e, data) },
    ]

    const handleDownload = () => {
        const fileUrl = `${isManualFilePath}${getDetails?.docPath}`;
        window.open(fileUrl, '_blank');
    };


    const extractFileName = (path) => {
        return path.split('/').pop();
    };

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            const baseUrl = SearchProperty;
            const data = {
                Search: value,
                RecordsPerPage: 10,
                PageNo: 1,
            };

            makeRequestWithSpinner(
                showSpinner,
                hideSpinner,
                baseUrl,
                "post",
                data,
                "",
                false
            )
                .then((response) => {
                    if (response.status === 1) {
                        let landingPageListData = response.list;

                        // Transform data to match Select options
                        const transformedData = landingPageListData.map((item) => ({
                            value: item.id,
                            label: item.name,
                        }));

                        // Update options and fetching state
                        if (fetchId === fetchRef.current) {
                            setOptions(transformedData);
                            setFetching(false);
                        }
                    } else {
                        setFetching(false);
                    }
                })
                .catch((error) => {
                    setFetching(false);
                });
        };
        return debounce(loadOptions, 800);
    }, []);

    const handleSearchProperty = (value) => {
        debounceFetcher(value);
        setSearchValues(value.value);
    };

    const handleClearProperty = () => {
        setSearchValues("");
    };

    const getPayinvoices = () => {
        const baseUrl = payinvoices;
        let data = {
            UserId: userId,
            uids: rowIds,
        };
        if(rowIds === ""){
            message.error("Select Invoice")
        }else{
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                   message.success(response.message)
                } else {
                    message.error(`${response.message}`);
                }
            })
            .catch((error) => {
                message.error("Error:", error);
            });
        }
    };
   
    const onSelectedRowKeysChange = (data, id) => {
        const selectedIds = id.map(row => row.id).join(',');
        setRowIds(selectedIds)
    }

    const debounceFetcherSupplier = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptionSupplier([]);
            setFetchingSupplier(true);
            const baseUrl = getsupplierslist ;
            const data = {
                Search: value,
                AgencyId: agencyID
            };

            makeRequestWithSpinner(
                showSpinner,
                hideSpinner,
                baseUrl,
                "post",
                data,
                "",
                false
            )
                .then((response) => {
                    if (response.status === 0) {
                        let supplierListData = response.list;
                        // Transform data to match Select options
                        const transformedData = supplierListData.map((item) => ({
                            label: item.name + `(${item.accountFolio})`,
                            value: item.contactId,
                        }));

                        // Update options and fetching state
                        if (fetchId === fetchRef.current) {
                            setOptionSupplier(transformedData);
                            setFetchingSupplier(false);
                        }
                    } else {
                        setFetchingSupplier(false);
                    }
                })
                .catch((error) => {
                    setFetchingSupplier(false);
                });
        };
        return debounce(loadOptions, 800);
    }, []);

    const handleSearchSupplier = (value) => {
        debounceFetcherSupplier(value);
        setSupplierValues(value.value);
    };

    const handleClearSupplier = () => {
        setSupplierValues("");
    };

    return (
        <div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
                    <SubNav title={"Invoice"} goback={false} extraData={true} extraContent={
                        <div className="property-details-buttons">
                            <button onClick={handleSupportTickets} className="subnav-btn" type="submit">
                                <HiOutlinePlusSmall style={{ fontSize: '24px' }} /> Add Invoice
                            </button>
                            <button onClick={getPayinvoices} className="subnav-btn">
                                 Pay Invoice
                            </button>
                        </div>
                    } />
                    <div className="material-work-desk-new bg-white m-3">
                        <div style={{ margin: '10px' }}>
                           
                        </div>
                        <AntdTableExample
                            showCheckbox
                            label="List Invoice"
                            dataSource={invoiceLists}
                            globalSearch
                            hideCol={"id"}
                            onPageChange={handlePageChange}
                            currentPage={currentPage}
                            totalRows={rows}
                            totalRecords={totalPages}
                            tagColumns={tabColumns}
                            onSearchValueChange={handleSearchValueChange}
                            actions={actions}
                            onSelectedRowKeysChange={onSelectedRowKeysChange}
                        />
                    </div>
                    <CustomModal footer={null} title={view ? `Invoice #${getDetails?.id} - ${getDetails?.details}` : edit ? "Update Contacts" : "Add Invoice"} width={800} closable={closeProperty} visible={openAddProperty} onCancel={closeProperty}
                        modalData={
                            <div>

                                <div>
                                    {view && (
                                        <div className="details-form p-3">
                                            <div className="contacts-group">
                                                <ShowValue Label='Created Date' setData={getDetails?.addedOn ? format(new Date(getDetails.addedOn), 'dd/MM/yyyy hh:mm') : "N/A"} />
                                                <ShowValue Label='Issued By' setData={getDetails?.IssueByFolio} />
                                                <ShowValue Label='Journal' setData={getDetails?.ticketStatus === 1 ? "Resolved" : "Created"} />
                                                <ShowValue Label='Due Date' setData={getDetails?.date ? format(new Date(getDetails.date), 'dd/MM/yyyy hh:mm') : "N/A"} />
                                                {getDetails?.attachment_Path && (
                                                    <div className={`row`}>
                                                        <div className={"label"}>
                                                            <div style={{ color: "#5E696E", fontWeight: '500' }}>{"Document"}:</div>
                                                        </div>
                                                        <div className={"value"} style={{ cursor: 'pointer' }} onClick={handleDownload}>
                                                            {extractFileName(getDetails.attachment_Path)}
                                                            <span className="ml-2 icon" title="Download"><FaDownload /></span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    {!view && (
                                        <div>
                                            {(addNew) ? (
                                                <form onSubmit={handleSubmit} className="details-form" style={{ display: 'initial', width: '100%' }}>
                                                    <div className="row">
                                                    <SelectBox value={values.fromInv} name="fromInv" label={"From Invoice"} options={fromInvArr} onBlur={handleBlur} onChange={(val) => setFieldValue("fromInv", val.value)} error={errors.fromInv} touched={touched.fromInv}/>
                                                    {values.fromInv === 2 ? 
                                                    <div className="col-12 col-lg-6 col-md-12 col-sm-12" >
                                                            <label className='s1'>
                                                                Supplier
                                                            </label>
                                                            <Select
                                                                style={{ width: '100%' , height:'40px'}}
                                                                className="landingPageSearchs"
                                                                mode="single"
                                                                showSearch
                                                                placeholder="Search for supplier"
                                                                labelInValue
                                                                filterOption={false}
                                                                onSearch={debounceFetcherSupplier}
                                                                notFoundContent={fetchingSupplier ? <Spin size="small" /> : null}
                                                                options={optionSupplier}
                                                                onChange={handleSearchSupplier}
                                                                allowClear
                                                                onClear={handleClearSupplier}
                                                            />
                                                        </div> : null }
                                                        <TextBox value={values.date} type="date" name="date" label={"Date"}  onChange={handleChange} onBlur={handleBlur} error={errors.date} touched={touched.date}/>
                                                        <SelectBox value={values.accounts} name="accounts" label={"Account"} options={accArr} onBlur={handleBlur} onChange={(val) => setFieldValue("accounts", val.value)}  error={errors.accounts} touched={touched.accounts}/>

                                                        <div className="col-12 col-lg-6 col-md-12 col-sm-12" >
                                                            <label className='s1'>
                                                                Property
                                                            </label>
                                                            <Select
                                                                style={{ width: '100%' , height:'40px'}}
                                                                className="landingPageSearchs"
                                                                mode="single"
                                                                showSearch
                                                                placeholder="Search for Property"
                                                                labelInValue
                                                                filterOption={false}
                                                                onSearch={debounceFetcher}
                                                                notFoundContent={fetching ? <Spin size="small" /> : null}
                                                                options={options}
                                                                onChange={handleSearchProperty}
                                                                allowClear
                                                                onClear={handleClearProperty}
                                                            />
                                                        </div>
                                                        <TextBox value={values.details} name="details" label={"Details"} onChange={handleChange} onBlur={handleBlur} error={errors.details} touched={touched.details}/>
                                                        <SelectBox value={values.taxInc} name="taxInc" label={"Tax Inc"} options={isTaxArr} onChange={(val) => setFieldValue("taxInc", val.value)} onBlur={handleBlur} error={errors.taxInc} touched={touched.taxInc}/>
                                                        <TextBox value={values.totalAmt} name="totalAmt" label={"Total Invoice Amount"} onChange={handleChange} error={errors.totalAmt} onBlur={handleBlur} touched={touched.totalAmt}/>

                                                        <FileUpload
                                                            label="Upload Image"
                                                            inputRef={inputRef}
                                                            onClickHandler={select}
                                                            handleFileChange={handleFileChange}
                                                            images={propertyimageitems}
                                                            setImages={setPropertyimageitems}
                                                        />
                                                        <div className="property-details-buttons">
                                                            <button
                                                                className="pdb-cancel"
                                                                type="reset"

                                                            >
                                                                Cancel
                                                            </button>
                                                            <button className="pdb-save-changes" type="submit">
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                            </div>
                        }

                    />
                </div>
            </div>
        </div>
    );
};

export default ListInvoice;
