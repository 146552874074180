import React, { useState, useEffect } from 'react';
import './input-component.css'; // This is your updated CSS

const ToggleTabs = ({ tabs, onTabChange ,externalTabValue }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]?.value || '');

  useEffect(() => {
    if (externalTabValue && externalTabValue !== activeTab) {
      setActiveTab(externalTabValue);
    }
  }, [externalTabValue, activeTab]);

  const handleTabClick = (tab) => {
    setActiveTab(tab.value);
    if (onTabChange) onTabChange(tab.value);
  };

  return (
    <div className="toggle-buttons">
      {tabs.map((tab) => (
        <button
          key={tab.value}
          className={`tab-btn ${activeTab === tab.value ? 'active' : ''}`}
          onClick={() => handleTabClick(tab)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default ToggleTabs;
