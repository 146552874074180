import React, { useState, useEffect, useRef } from "react";
import Header from "../../../../../components/CommonSection/Header/NewHeader";
import "../../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { BiDotsVerticalRounded, BiChevronLeft } from "react-icons/bi";
import ToastAlert from "../../../../../components/CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import ContactDetails from "../../../../view/Contacts/contactDetails";
//import ContactPrefference from "./contactPrefference";
//import CompanyDetailsContact from "./CompanyDetailsContact";
import { ContactDetailsApi } from "../../../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../../../Utilities/client";
import { useSpinnerContext } from "../../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../../Utilities/enum";
import { GetCookie, currentProfile } from "../../../../../components/CommonSection/Cookie/Session";
import { dynamicUrl } from "../../../../../Utilities/profileManagement";
import Sidebar from "../../../../../components/CommonSection/SideBar/sideBar";

const Contacts = ({ handleparentsuccess }) => {
  const [visiblet, setVisiblet] = useState(true);
  const [getDetails, setGetDetails] = useState(null);
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();
  const navigate = useNavigate();
  const currentSideBar = currentProfile.get();
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("ContactList");
  const [activeSection, setActiveSection] = useState("Personal Details");
  var url = new URL(window.location.href);
  var contactUniqueId = url.searchParams.get("contactUID");
  const menuItems = [
    { label: "Personal Details", isActive: activeSection === "Personal Details" },
    { label: "Company Details", isActive: activeSection === "Company Details",isDisable:contactUniqueId ? false : true },
    { label: "Preference", isActive: activeSection === "Preference" ,isDisable: contactUniqueId ? false : true},
  ];
  const handleTopicValue = (text) => {
    dynamicUrl(navigate, text);
    setActiveTopic(text);
    };

  const handleDataChild = (value) => {
    GetApi(value);
  };

  useEffect(() => {
    var url = new URL(window.location.href);
    var contactUID = url.searchParams.get("contactUID");
    if (contactUID) {
      GetApi();
    }
  }, []);

  const GetApi = (value) => {
    var url = new URL(window.location.href);
    var contactUniqueId = value ? value : url.searchParams.get("contactUID");
    const baseUrl = ContactDetailsApi;
    const apiUrl = `${baseUrl}?contactUniqueId=${contactUniqueId}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get")
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          setGetDetails(response.object);
        } else {
          handleparentsuccess(["warn", response.data.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error]);
      });
  };

  const handleToastMessage = (toastdata) => {
    ToastRef.current.notify(toastdata);
  };

  const handleListContacts = () => {
    dynamicUrl(navigate, "ContactList");
  };
console.log(activeTopic,'activeTopicactiveTopic')

const handleMenuChange = (section) => {
  console.log(section,'section')
  setActiveSection(section);
};

const handleCurrentPage = (text) => {
  setActiveSection(text);

}
  return (
    <>
      <ToastAlert ref={ToastRef} />
      <div className="dashboard-agency">
        <Header menuItems={menuItems}  handleMenuChange={handleMenuChange}/>
        <div
          className="blank_space"
          style={{
            backgroundColor: "white",
            height: "80px",
            padding: "10px",
          }}
        >
          <BiDotsVerticalRounded onClick={() => setVisiblet(!visiblet)} />
        </div>
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <Sidebar
              visible={sidebarVisible}
              handleTopicValue={handleTopicValue}
              topicValue={activeTopic}
            />

            <div
              className="pa-content-top-level"
              onClick={() => setVisiblet(true)}
            >
              <div className=" pb-2">
                <div
                  className="list-property-label"
                  onClick={handleListContacts}
                >
                  <BiChevronLeft style={{ fontSize: "24px" }} /> List Contacts
                </div>
              </div>
              
              {/* Render selected section based on active topic */}
              {activeSection  === "Personal Details" && (
                <ContactDetails
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  getDetails={getDetails}
                  handleCurrentPage={handleCurrentPage}
                />
              )}
              {/* {activeSection  === "Company Details" && (
                <CompanyDetailsContact
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  getDetails={getDetails}
                  handleCurrentPage={handleCurrentPage}
                />
              )}
              {activeSection  === "Preference" && (
                <ContactPrefference
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  getDetails={getDetails}
                />
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contacts;
