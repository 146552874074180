import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SubNav from "../../navbar/subNav";
import "./activities.css";
import { getpropertyactivitiesdetails } from "../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import AntdTableExample from "../../input-components/dataTable";
import { FormatDate } from "../../../components/CommonSection/Utility/Utility";

const ViewPropertActivityDetails = () => {
  const [agencyID, setAgencyId] = useState("");
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Enquiry");
  const [tabId, setTabId] = useState(1);
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [dataLists, setDataLists] = useState({});
  const [rows, setRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [allData, setAllData] = useState({});
  const propertyUId = new URL(window.location.href).searchParams.get("propertyUID");
  const ToastRef = useRef();

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setAgencyId(parsedData.agencyID);
          getPropertyDetails(propertyUId, parsedData.agencyID);
        } else {
          navigate("/signin");
        }
      } catch (error) {
        console.error("Error retrieving data:", error);
      }
    };
    retrieveData();
  }, [rows, tabId, currentPage]);

  const getPropertyDetails = (value, agencyID) => {
    const baseUrl = getpropertyactivitiesdetails;
    const data = {
      SortBy: "CreatedDate",
      SortOrder: "Desc",
      RecordsPerPage: rows,
      AgencyId: agencyID,
      PageNo: currentPage,
      TabId: tabId,
      PropertyUniqueId: value,
    };

    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        const activityData = response.object;
        setAllData(activityData);
        setDataLists({
          Enquiry: formatData(activityData.enquiries, "Enquiry"),
          OpenHome: formatData(activityData.openHome, "OpenHome"),
          Offers: formatData(activityData.offers, "Offers"),
          RentApplication: formatData(activityData.rentalApplication, "RentApplication"),
        });
      })
      .catch((error) => {
        console.error("Error fetching property details:", error);
      });
  };

  const formatData = (data, type) => {
    if (!data) return [];
    switch (type) {
      case "Enquiry":
        return data.map((item) => ({
          UserName: item.userName,
          UserEmail: item.userEmail,
          UserPhone: item.userPhone,
          PortalName: item.portalName,
          CreatedOn: FormatDate(item.createdOn),
          EnquiryDetails: item.enquiryDetails,
        }));
      case "OpenHome":
        return data.map((item) => ({
          venue: item.venue,
          UserName: item.userName,
          propertyPrice: item.propertyPrice,
          startTime: FormatDate(item.startTime),
          eventDate: FormatDate(item.eventDate),
          endTime: FormatDate(item.endTime),
          detail: item.detail,
        }));
      case "Offers":
        return data.map((item) => ({
          OfferByName: item.OfferByName,
          OfferByEmail: item.OfferByEmail,
          OfferAmount: item.OfferAmount,
          OfferDate: FormatDate(item.OfferDate),
        }));
      case "RentApplication":
        return data.map((item) => ({
          PropertyRent: item.PropertyRent,
          RentTenure: item.RentTenure,
          ApplicantMobile: item.ApplicantMobile,
          ApplicantEmail: item.ApplicantEmail,
        }));
      default:
        return [];
    }
  };

  const handleGoBack = () => navigate("/activities/list");

  const handleTabs = (tab) => {
    setActiveTab(tab);
    setTabId(tabs.indexOf(tab) + 1);
  };

  const handlePageChange = (pageNumber, totalRows) => {
    setCurrentPage(pageNumber);
    setRows(totalRows);
  };

  const tabs = ["Enquiry", "Open Home", "Offers", "Rent Application"];

  const countsList = () => (
    <div style={{ borderBottom: "1px solid #e9ecef", padding: "10px" }}>
      <div className="col-lg-8">
        {tabs.map((tab) => {
          const countKey = `countof${tab}`; // Dynamic key for counts
          const count = allData[countKey] || 0; // Default to 0 if count is undefined
          return (
            <span
              key={tab}
              className={`col-lg-3 col-sm-2 stat-item ${activeTab === tab ? "active" : ""}`} // Highlight active tab
              onClick={() => handleTabs(tab)} // Handle tab click
            >
              {tab} <span className="count">{count}</span>
            </span>
          );
        })}
      </div>
    </div>
  );
  

  const renderTable = () => (
    <div className="material-work-desk-new bg-white m-3" style={{ fontFamily: "Inter" }}>
      {countsList()}
      <AntdTableExample
        label={allData.propertyAddress}
        dataSource={dataLists[activeTab] || []}
        globalSearch
        onPageChange={handlePageChange}
        totalRows={rows}
        totalRecords={currentPage}
        hideCol={''}
      />
    </div>
  );

  return (
    <div>
      <div className="col-12 px-0 changedateantdstyle">
        <div className="col-12 px-0 daily-date-style">
          <SubNav
            title={`${activeTab} List`}
            goback={handleGoBack}
            extraData={true}
          />
          <ToastAlert ref={ToastRef} />
          {renderTable()}
        </div>
      </div>
    </div>
  );
};

export default ViewPropertActivityDetails;
