import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { TaskDetails, getTicketlist, addupdateTicket, FilePath,getTicketbyid } from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner, uploadToS3WithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import "../../../components/Dashboard/Dashboard-agent/AgentList.css";
import { ActionStatus ,isManualFilePath} from "../../../Utilities/enum";
import { addParametersForProfiles } from "../../../Utilities/profileManagement";
import SubNav from "../../navbar/subNav";
import AntdTableExample from "../../input-components/dataTable";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { Steps, DatePicker, message } from 'antd';
import CustomModal from "../../input-components/Modal";
import ShowValue from "../../input-components/ShowValue";
import { format } from 'date-fns';
import SelectBox from "../../input-components/selectbox";
import TextBox from "../../input-components/textbox";
import TextArea from "../../input-components/textarea";
import FileUpload from "../../input-components/documentUpload";
import { FaDownload } from "react-icons/fa";


const ListTickets = () => {
    let selectedFile;
    const [contactLists, setContactLists] = useState([]);
    const [userId, setUserId] = useState("");
    const [agencyID, setAgencyId] = useState("");
    const navigate = useNavigate();
    const [propertyType, setPropertyType] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [purpose, setPurpose] = useState("");
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [rows, setRows] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [openAddProperty, setOpenAddProperty] = useState(false);
    const [getDetails, setGetDetails] = useState(null);
    const [addNew, setAddNew] = useState(false);
    const [edit, setEdit] = useState(false);
    const [view, setView] = useState(false);
    const [propertyTypeVal, setPropertyTypeVal] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [agentId, setAgentId] = useState("");
    const [totalRow, setTotalRow] = useState(10);
    const [formData, setFormData] = useState({
        TicketType: "",
        TicketDetail: "",
        TicketHeading: ""
    })
    const eTicketType = [
        { value: 1, label: "Incident" },
        { value: 2, label: "Service Request" },
    ];
    const [propertyDocuments, setPropertyDocuments] = useState("");
    const inputRef = useRef();
    const [propertyimageitems, setPropertyimageitems] = useState([]);

    const { Step } = Steps;
    const { RangePicker } = DatePicker;


    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setAgentId(parsedData.agentId)
                    setUserId(parsedData.userId);
                    setAgencyId(parsedData.agencyID);
                }
            } catch (error) {
                console.error("Error retrieving data from AsyncStorage:", error);
            }
        };
        retrieveData();
    }, [userId]);

    useEffect(() => {
        if (userId) getSupportList(1, 10);
    }, [userId, searchText, purpose, propertyType, propertyTypeVal, fromDate, toDate]);
    var url = new URL(window.location.href);
    var ticketUID = url.searchParams.get("ticketUID");

    useEffect(() => {
        if (ticketUID) {
            GetDetailsApi(ticketUID)
        }
    }, [ticketUID])


    const getSupportList = (currentPage, totalRows) => {
        const baseUrl = getTicketlist;
        let data = {
            AgencyId: agencyID,
            PageNo: currentPage,
            RecordsPerPage: totalRow,
            Search: searchText ? searchText : null,
            SortBy: "CreatedOn",
            SortOrder: "Desc",
            maxDate: toDate,
            minDate: fromDate
        };
        if (data.SearchDateType === "") {
            delete data.SearchDateType;
        }
        data = addParametersForProfiles(data);
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    let ticketData = response.list;
                    let ticketList = ticketData
                        .map((ticket) => ({
                            Type: ticket.ticketType === 1 ? "Incident" : "Service Request",
                            Heading: ticket.ticketHeading,
                            Details: ticket.ticketDetail,
                            CreatedDate: format(new Date(ticket.createdOn), 'dd/MM/yyyy hh:mm'),
                            Status: ticket.ticketStatus === 1 ? "Created" : ticket.ticketStatus === 2 ? "Accepted" : ticket.ticketStatus === 3 ? "Resolved" : "",
                            ticketUid: ticket.ticketUniqueId
                        }));
                    setTotalPages(response.totalCount)
                    setContactLists(ticketList);
                } else {
                    alert(`Message: ${response.message}`);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const AddupdateTicket = () => {
        const baseUrl = addupdateTicket;
        let data = {
            AgencyId: agencyID,
            TicketType: formData.TicketType.value,
            TicketDetail: formData.TicketDetail,
            TicketHeading: formData.TicketHeading,
            DocPath: propertyDocuments,
            CreatedBy: userId
        };
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    message.success(response.message)
                    getSupportList();
                    setOpenAddProperty(false);
                } else {
                    //ToastRef.current.notify(["error", "error"]);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    // Handler for page change
    const handlePageChange = (pageNumber, totalRows) => {
        setCurrentPage(pageNumber);
        setRows(totalRows);
        getSupportList(pageNumber, totalRows);
    };

    const tabColumns = {
        Status: {
            colorMap: {
                "Resolved": "#00A5A2",
                "Created": "#E5A000",
            },
            defaultColor: 'blue'
        }
    };

    const handleSearchValueChange = (value) => {
        setSearchText(value);
    };

    const handleSupportTickets = () => {
        setOpenAddProperty(true);
        setEdit(false);
        setView(false);
        setGetDetails(null);
        setAddNew(true);
        setFormData(() => ({
            TicketType: "",
            TicketDetail: "",
            TicketHeading: ""
        }))
    }

    const closeProperty = () => {
        const baseUrl = window.location.pathname;
        navigate(baseUrl);
        setOpenAddProperty(false);
        setEdit(false);
        setView(false);
        setGetDetails(null);
        setAddNew(false);
    }


    const GetDetailsApi = () => {
        const url = getTicketbyid + `?TicketId=${ticketUID}`;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "get", "", null, false)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    setGetDetails(response.object);
                }
            })
            .catch((error) => {
                console.error("Error fetching ticket details:", error);
            })
            .finally(() => {
                //setLoading(false); // Set loading to false once API call completes
            });
    };

    const handleView = (e, data) => {
        const baseUrl = window.location.pathname; // Get the current path without query params
        const newUrl = `${baseUrl}?ticketUID=${data.ticketUid}`; // Append the contactUID to the URL
        navigate(newUrl);
        setOpenAddProperty(true);
        setView(true);
    }

    const handleRangePickerChange = (dates, dateStrings) => {
        setFromDate(dateStrings[0]);
        setToDate(dateStrings[1]);
    };

    const handleInput = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }

    const handleInputSelect = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        setFormData((prev) => ({
            ...prev,
            [name]: selectedOption, // Store the entire selected object
        }));
    };

    const handleFileChange = async (e, indexforfiles) => {
        for (let i = 0; i < e.target.files.length; i++) {
            selectedFile = e.target.files[i];
            await handleUpload(indexforfiles);
        }
    };

    const handleUpload = async (indexforfiles) => {
        await uploadToS3WithSpinner(
            showSpinner,
            hideSpinner,
            "agencyticket/",
            "agencyticket",
            selectedFile
        ).then((returnData) => {
            if (returnData.status == ActionStatus.Successfull) {
                //ToastRef.current.notify(["success", "File uploaded successfully"]);
                const fileName1 = returnData.Key.split('/').pop();
                const transformedItem = {
                    id: Math.floor(Math.random() * 1000000),
                    src: FilePath + returnData.Key,
                    width: 1,
                    height: 1,
                    fileName: fileName1
                };
                setPropertyimageitems((prevItems) => [...prevItems, transformedItem]);
                setPropertyDocuments(returnData.Key);
            } else {
                //ToastRef.current.notify(["error", "Error uploading file. Please try again."]);

            }
        });
    };
    const select = (event) => {
        inputRef.current.click();
    };

    const actions = [
        { icon: "/assets/images/dots.svg",key:"View", onClick: (e, data) => handleView(e, data) },
        // { icon: "/assets/images/editing.svg", onClick: (e, data) => handleEdit(e, data) },
    ]

    const handleDownload = () => {
        const fileUrl = `${isManualFilePath}${getDetails?.docPath}`;
        window.open(fileUrl, '_blank');
    };

    
    const extractFileName = (path) => {
        return path.split('/').pop();
    };

    return (
        <div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
                    <SubNav title={"Support"} goback={false} extraData={true} extraContent={
                        <div className="property-details-buttons">
                            <button onClick={handleSupportTickets} className="subnav-btn" type="submit">
                                <HiOutlinePlusSmall style={{ fontSize: '24px' }} /> Report Incident
                            </button>
                        </div>
                    } />
                    <div className="material-work-desk-new bg-white m-3">
                        <div style={{ margin: '10px' }}>
                            <RangePicker
                                style={{ marginLeft: '10px' }}
                                onChange={handleRangePickerChange}
                                format="YYYY-MM-DD"
                            />
                        </div>
                        <AntdTableExample
                            label="Ticket List"
                            dataSource={contactLists}
                            globalSearch
                            hideCol={"ticketUid"}
                            onPageChange={handlePageChange}
                            currentPage={currentPage}
                            totalRows={rows}
                            totalRecords={totalPages}
                            tagColumns={tabColumns}
                            onSearchValueChange={handleSearchValueChange}
                            actions={actions}
                        />
                    </div>
                    <CustomModal footer={null} title={view ? "View Tickets Details" : edit ? "Update Contacts" : "Add Report"} width={600} closable={closeProperty} visible={openAddProperty} onCancel={closeProperty}
                        modalData={
                            <div>

                                <div>
                                    {view && (
                                        <div className="details-form p-3">
                                            <div className="contacts-group">
                                                <ShowValue Label='Type' setData={getDetails?.ticketType === 1 ? "Incident" : "Service Request"} />
                                                <ShowValue Label='Details' setData={getDetails?.ticketDetail} />
                                                <ShowValue Label='Created Date' setData={getDetails?.createdOn ? format(new Date(getDetails.createdOn), 'dd/MM/yyyy hh:mm') : "N/A"} />
                                                <ShowValue Label='Status' setData={getDetails?.ticketStatus === 1 ? "Resolved" : "Created"} />
                                                {getDetails?.resolveDetail ? <ShowValue colval={3} Label='Resolved Note' setData={getDetails?.resolveDetail} /> : null}
                                                {getDetails?.docPath && (
                                                    <div className={`row`}>
                                                        <div className={"label"}>
                                                            <div style={{color:"#5E696E", fontWeight:'500'}}>{"Document"}:</div>
                                                        </div>
                                                        <div className={"value"} style={{ cursor: 'pointer' }} onClick={handleDownload}>
                                                            {extractFileName(getDetails.docPath)}
                                                            <span className="ml-2 icon" title="Download"><FaDownload /></span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    {!view && (
                                        <div>
                                            {(addNew) ? (
                                                <div className="row">
                                                    <SelectBox value={formData.TicketType} name="TicketType" label={"Type"} options={eTicketType} onChange={handleInputSelect} />
                                                    <TextBox value={formData.TicketHeading} name="TicketHeading" label={"Heading"} onChange={handleInput} />
                                                    <TextArea colVal={12} value={formData.TicketDetail} name="TicketDetail" label={"Description"} onChange={handleInput} />
                                                    {/* <FileUpload colVal={12} icon={<BiEnvelope height="100%" width="100%" />} onChange={(e) => handleFileChange(e)} /> */}
                                                    <FileUpload
                                                        label="Upload Image"
                                                        inputRef={inputRef}
                                                        onClickHandler={select}
                                                        handleFileChange={handleFileChange}
                                                        images={propertyimageitems}
                                                        setImages={setPropertyimageitems}
                                                    />
                                                    <div className="property-details-buttons">
                                                        <button
                                                            className="pdb-cancel"
                                                            type="reset"

                                                        >
                                                            Cancel
                                                        </button>
                                                        <button className="pdb-save-changes" onClick={AddupdateTicket}>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                            </div>
                        }

                    />
                </div>
            </div>
        </div>
    );
};

export default ListTickets;
