import Cookies from "js-cookie";
import Cookie from "js-cookie";

let userProfileData = {};

export const CurrentProfileString = "currentProfile";

export const SetCookie = (cookiename, usrin, rem) => {
  let expiry_time;
  if (!rem) expiry_time = new Date(new Date().getTime() + 30 * 60 * 1000);
  else expiry_time = new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000);

  Cookie.remove(cookiename);
  Cookie.set(cookiename, usrin, { expires: expiry_time });
};

export const GetCookie = (cookiename) => {
  localStorage.clear();
  const cookieValue = Cookie.get(cookiename);
  //if (!cookieValue && cookiename === "@UserData") {
  // window.location.href = "/signin";
  //}
  return cookieValue;
};

export const RemoveCookie = (cookiename) => {
  Cookie.remove(cookiename);
  window.location.href = "/signin";
};

export const currentProfile = {
  set: (value) => {
    const existingCookie = GetCookie("@UserData");
    if (existingCookie) {
      const cookieObj = JSON.parse(existingCookie);
      cookieObj.currentProfile = value;
      Cookies.set("@UserData", JSON.stringify(cookieObj));
    }
  },
  get: () => {
    const existingCookie = GetCookie("@UserData");
    if (existingCookie) {
      const cookieObj = JSON.parse(existingCookie);
      return cookieObj.currentProfile;
    }
  },
};

export const logout = () => {
  sessionStorage.clear(); // Clear all session data
  RemoveCookie("@UserData"); // Remove cookie
  window.location.href = "/signin"; // Redirect to sign-in page
};