import React from 'react';
import { DatePicker, TimePicker } from 'antd';
import './collapse.css';
import './collapse.css';
import moment from 'moment';

const AntDatePicker = ({ customeDateFormatDate,colvalue, mandatory, onSubmitForm, label, disabledDate, value, onChange, disabled, showTime ,disabledTime}) => {
    const mandatoryVal = mandatory === true ? 'true' : 'false';
    const labelCalssUp = 'label-placeholder';
    const labelClassDown = 'label-placeholder2';
    const colvalueData =
        colvalue == '3'
            ? 'col-lg-3 col-md-6 col-xl-3'
            : colvalue == '4'
                ? 'col-lg-4 col-xl-4'
                : colvalue == '8'
                    ? 'col-lg-8 col-xl-8'
                    : colvalue == '12'
                        ? 'col-lg-12 col-xl-12'
                        : 'col-lg-6 col-xl-6';

    const handleChange = (time, timeString) => {
        onChange(timeString);
    };

    const handleTimeChange = (time,timeString) => {
        if (timeString) {
            onChange(timeString); // Send the formatted time
        } else {
            onChange(null); // Clear value if time is null
        }
    };

    const  checkAllNullCheck = (value) =>{
        return value === null || value === undefined  || value === "";
        }

        const timeFormat = 'HH:mm'; // 24-hour format
        let checkDateOrDateTime = !showTime ? customeDateFormatDate : customeDateFormatDate+" HH:mm A"

    return (
        <div className={colvalueData + ' datePickerMainWrapper'}>
            <div value={value} mandatory={mandatoryVal} id={label} required>
                {showTime ? (
                    <TimePicker
                        disabled={disabled}
                        disabledTime={disabledTime}
                        style={{
                            border:
                                onSubmitForm && mandatory && checkAllNullCheck(value)
                                    ? '1px solid #EC7063'
                                    : '',
                            borderRadius: '4px',
                            width: '84%',
                            marginLeft: '7%',
                        }}
                        placeholder=""
                        value={value === '' ? null : value !== null ? moment(value, timeFormat) : ''}
                        format={timeFormat}
                        onChange={handleTimeChange}
                    />
                ) : (
                    <DatePicker
                        disabledDate={disabledDate}
                        disabled={disabled}
                        style={{
                            border:
                                onSubmitForm && mandatory && checkAllNullCheck(value)
                                    ? '1px solid #EC7063'
                                    : '',
                            borderRadius: '4px',
                            width: '84%',
                            marginLeft: '7%',
                        }}
                        showTime={false}
                        placeholder=""
                        value={value === '' ? null : value !== null ? moment(value, checkDateOrDateTime) : ''}
                        format={checkDateOrDateTime}
                        onChange={handleChange}
                    />
                )}
            </div>
            <label style={{ marginLeft: '9%' }} className={labelCalssUp}>
                {label} {showTime ? `(${timeFormat})` : `(${checkDateOrDateTime})`}
                <span style={{ color: 'red' }}>{mandatory ? '*' : ''}</span>
            </label>
        </div>
    );
};


export default AntDatePicker;