import React from "react";
import { useEffect, useState, useRef } from "react";
import "./propertiesagency.css";
import { BiEnvelope, BiDownload,BiShow  } from "react-icons/bi";
import { useFormik } from "formik";
import { PropertyDocumentsUrl, FilePath ,generatePdfReport,generateTenantAgreementPdfReport,generatesalesagreementPdfReport} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner, uploadToS3WithSpinner,DownloadFiletWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import Collapse from "../../input-components/collapse";
import SelectBox from "../../input-components/selectbox";
import FileUpload from "../../input-components/fileUplode";
import { Divider } from "antd";
import { isNullOrEmpty } from "../../../components/CommonSection/Utility/ComponentFunction";
import { useNavigate } from "react-router-dom";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { Modal } from "antd";

const initialValues = {
  propertyId: "",
  propertyDocuments: "",
  docDesc: "",
  docPath: "",
  docTitle: "",
};

const PropertyDocumentsAgency = ({ getDetails, recallApi }) => {
  let selectedFile;
  const [userId, setUserId] = useState("");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [docArray, setDocArray] = useState([
    { value: "appraisal", label: "Appraisal" },
    { value: "agreement", label: "Agreement" },
    { value: "contractofsale", label: "Contract of Sale" },
    { value: "salesadvise", label: "Sales Advise" },
    { value: "other", label: "Other" },
  ]);
  const [selections, setSelections] = useState([
    { id: 1, label: "", value: "" },
  ]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [documentUrl, setDocumentUrl] = useState("");
  const [propertyDocuments, setPropertyDocuments] = useState([]);
  const ToastRef = useRef();
  const navigate = useNavigate();
  var url = new URL(window.location.href);
  var propertyUID = url.searchParams.get("propertyUID");
  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = () => {
    const formData = {
      PropertyId: getDetails ? getDetails.propertyId : 0,
      PropertyDocuments: propertyDocuments,
    };
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      PropertyDocumentsUrl,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          recallApi(propertyUID);
          handleClearFiles();
          ToastRef.current.notify(["success", returnData.message]);

        } else {
          ToastRef.current.notify(["error", returnData.message]);

        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);

      });
  };

  const handleOptionChange = (id, e) => {
    setSelections((prevSelections) =>
      prevSelections.map((selection) =>
        selection.id === id
          ? { ...selection, label: e.label, value: e.value }
          : selection
      )
    );
    setFieldValue("docTitle", e.label);
  };

  const addSelectionBox = () => {
    const newSelection = {
      id: selections.length + 1,
      label: "",
      value: "",
      selectedOption: "",
    };
    const commonValues = docArray
      .filter(
        (doc) =>
          doc.value !== "other" &&
          selections.some((selection) => doc.value === selection.value)
      )
      .map((commonDoc) => commonDoc.value);
    const updatedDocArray = docArray.filter(
      (doc) => doc.value === "other" || !commonValues.includes(doc.value)
    );
    setDocArray(updatedDocArray);
    setSelections((prevSelections) => [...prevSelections, newSelection]);
  };

  const removeSelectionBox = (id) => {
    setSelections((prevSelections) =>
      prevSelections.filter((selection) => selection.id !== id)
    );
  };

  const handleFileChange = async (e, indexforfiles) => {
    for (let i = 0; i < e.target.files.length; i++) {
      selectedFile = e.target.files[i];
      await handleUpload(indexforfiles);
    }
  };

  const handleUpload = async (indexforfiles) => {
    await uploadToS3WithSpinner(
      showSpinner,
      hideSpinner,
      "propertydocuments/",
      "propertydocuments",
      selectedFile
    ).then((returnData) => {
      if (returnData.status == ActionStatus.Successfull) {
        setFieldValue("docPath", returnData.Key);
        ToastRef.current.notify(["success", "File uploaded successfully"]);
        const newSection1 = {
          docId: "0",
          docTitle: values.docTitle,
          docDesc: values.docDesc,
          docPath: returnData.Key,
        };
        setPropertyDocuments((prevState) => [...prevState, newSection1]);
      } else {
        ToastRef.current.notify(["error", "Error uploading file. Please try again."]);

      }
    });
  };

  const renderFileIcon = (docPath) => {
    return <img src="../../../assets/images/PDF.png" width={20} alt="PDF" />;
  };

  const handleDownload = (path) => {
    if (path) {
      const downloadLink = `${FilePath}${path}`;
      window.open(downloadLink, "_blank");
    }
  };

  const handleClearFiles = () => {
    // Clear selected files by resetting the state
    const newItem = [{ id: 1, label: "", value: "" }];
    setSelections(newItem);
    setPropertyDocuments('');
  };

  const handleCancle = () => {
    navigate("/agency/ListAgreement");
  }

  const handleDownload1 = (record) => {
    if (record === "Management Agreement") {
      handleAgreementDownload(propertyUID);
    }
    else if (record === "Residential Tenancy Agreement") {
      handleTenantAgreementDownload(propertyUID);
    } else if (record === "Exclusive Sales Agency  Agreement") {
      handleSaleAgreementDownload(propertyUID);
    }
  };

  const handleAgreementDownload = (record) => {
    const formData = {
        PropertyUId: record,
    };
    DownloadFiletWithSpinner(
        showSpinner,
        hideSpinner,
        generatePdfReport,
        "post",
        formData,
        null,
        false
    )
        .then((returnData) => {
            if (returnData != null) {
                window.open(returnData, "_blank");
            } else {
                ToastRef.current.notify(["error", "Internal Server Error"]);
            }
        })
        .catch((error) => {
            ToastRef.current.notify(["error", error.message]);
        });
};

const handleTenantAgreementDownload = (record) => {
    const formData = {
        PropertyUId: record,
    };
    DownloadFiletWithSpinner(
        showSpinner,
        hideSpinner,
        generateTenantAgreementPdfReport,
        "post",
        formData,
        null,
        false
    )
        .then((returnData) => {
            if (returnData != null) {
                window.open(returnData, "_blank");
            } else {
                ToastRef.current.notify(["error", "Internal Server Error"]);
            }
        })
        .catch((error) => {
            ToastRef.current.notify(["error", error.message]);
        });
};

const handleSaleAgreementDownload = (record) => {
    const formData = {
        PropertyUId: record,
    };
    DownloadFiletWithSpinner(
        showSpinner,
        hideSpinner,
        generatesalesagreementPdfReport,
        "post",
        formData,
        null,
        false
    )
        .then((returnData) => {
            if (returnData != null) {
                window.open(returnData, "_blank");
            } else {
                ToastRef.current.notify(["error", "Internal Server Error"]);
            }
        })
        .catch((error) => {
            ToastRef.current.notify(["error", error.message]);
        });
};

  const allSelectionsHaveValue = selections.every(selection => selection.value !== "");

  const handleView = (path, docTitle) => {
    if (path) {
      const documentLink = `${FilePath}${path}`;
      console.log(documentLink,'documentLink')
      setDocumentUrl(documentLink);
      setIsModalVisible(true);
    } else {
      ToastRef.current.notify(["error", `Document ${docTitle} not available`]);
    }
  }
console.log(documentUrl,'documentUrldocumentUrl')
  return (
    <div>
      <ToastAlert ref={ToastRef} />
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
          <form
            onSubmit={handleSubmit}
            className="details-form"
            style={{ paddingTop: "10px" }}
          >
            <div className="property-details-content all-input p-3">
              <Collapse
                id={"Uplode"}
                title={"Upload Documents"}
                data={
                  <div>
                    <div>
                      <div className="ml-3">
                        {getDetails &&
                          getDetails.propertyDocuments &&
                          getDetails.propertyDocuments.map((document) => (
                            <div className="col-lg-6 col-sm-12 ">
                              <div
                                key={document.docId}
                                className="existing-document"
                              >
                                {
                                  renderFileIcon(document.docPath)}
                                <span className="ml-1 document-title">
                                  {!isNullOrEmpty(document.docDesc)
                                    ? document.docDesc
                                    : document.docTitle}
                                </span>
                                <span className="mr-2 document-date">
                                  {document.createdOn &&
                                    new Date(document.createdOn).toLocaleString(
                                      undefined,
                                      {
                                        day: "numeric",
                                        month: "numeric",
                                        year: "numeric",
                                      }
                                    )}
                                </span>
                                <span className="document-time">
                                  {document.createdOn &&
                                    new Date(document.createdOn).toLocaleString(
                                      undefined,
                                      {
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: true,
                                      }
                                    )}
                                </span>
                                <BiDownload
                                  className="ml-2"
                                  style={{ fontSize: "18px" , cursor:'pointer'}}
                                  onClick={() => {
                                    if (document?.systemGenerated === false) {
                                      handleDownload(document.docPath);
                                    } else {
                                      handleDownload1(document.docTitle);
                                    }
                                  }}
                                />
                                <BiShow
                                  className="ml-2"
                                  style={{ fontSize: "18px", cursor: "pointer" }}
                                  onClick={() => handleView(document.docPath, document.docTitle)}
                                />
                              </div>
                              <Divider />
                            </div>
                          ))}
                      </div>
                      {selections.map((selection) => (
                        <div key={selection.id}>
                          <SelectBox
                            name="docTitle"
                            value={selection}
                            onChange={(e) => handleOptionChange(selection.id, e)}
                            options={docArray}
                            label={"Select Documents"}
                            error={errors.docTitle}
                            touched={touched.docTitle}
                          />
                          {selection.value == "other" ? (
                            <TextBox
                              label=""
                              placeholder=""
                              name="docDesc"
                              value={values.docDesc}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.docDesc}
                              touched={touched.docDesc}
                            />
                          ) : null}
                          <FileUpload
                            icon={<BiEnvelope height="100%" width="100%" />}
                            onChange={(e) => handleFileChange(e)}
                            onBlur={() => { }}
                          />

                          {selection.id !== 1 && (
                            <button
                              className="ml-3 remove-document-btn"
                              onClick={() => removeSelectionBox(selection.id)}
                            >
                              Remove
                            </button>

                          )}

                        </div>
                      ))}

                      {
                        allSelectionsHaveValue && (
                          <button
                            type="button"
                            className="add-documents-btn mt-3 ml-3"
                            onClick={addSelectionBox}
                          >
                            Add Documents
                          </button>
                        )
                      }


                    </div>
                  </div>
                }
              />
               <Modal
        title="View Document"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={800}
        centered
      >
        {documentUrl ? (
          <iframe
            src={documentUrl}
            title="Document Viewer"
            style={{ width: "100%", height: "500px", border: "none" }}
          ></iframe>
        ) : (
          <p>Document not available</p>
        )}
      </Modal>
            </div>
            <div className="property-details-buttons">
              <button
                className="pdb-cancel"
                type="reset"
                onClick={handleCancle}
              >
                Cancel
              </button>
              <button className="pdb-save-changes" type="submit">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

  );
};

export default PropertyDocumentsAgency;
