import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { sendtenantagrmnttotenants} from "../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import "../openHome/openHome.css";
import Collapse from "../../input-components/collapse";

dayjs.extend(customParseFormat);

const initialValues = {
    AgreementDate: "",
    LeasePremisesAtMarketRent: "",
    ReferToPrincipalForReLease: "",
    ReviewRentBfrRLease: "",
    PropertyImages: [],
};

const SignaturePage = ({ onDataRecive, getDetails, reportTo, handleNextPage, }) => {
    let selectedFile;
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const ToastRef = useRef();
    const [propertyimageitems, setPrincipleimageitems] = useState([]);
    const [propertyImages, setPrincipleImages] = useState([]);
    const [secondaryOwner, setSecondaryOwner] = useState([]);
    const inputRef = useRef();
    const signatureRef = useRef(null);
    const [sign, setSign] = useState(null);

    useEffect(() => {
        if (getDetails) {
            setValues({ ...values, ...getDetails });
            setFieldValue("AgreementDate", formatDate(getDetails.agreementDate));
            setFieldValue(
                "ReferToPrincipalForReLease",
                getDetails.referToPrincipalForReLease
            );
            setFieldValue("ReviewRentBfrRLease", getDetails.reviewRentBfrRLease);
            setFieldValue(
                "LeasePremisesAtMarketRent",
                getDetails.leasePremisesAtMarketRent
            );
            setSecondaryOwner(getDetails.contactlistModel);
        }
    }, [getDetails, reportTo]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
    }, []);

    function formatDate(dateString) {
        if (!dateString) {
            return "";
        }
        const [year, month, day] = dateString.split("T")[0].split("-");
        return `${year}-${month}-${day}`;
    }


    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
    } = useFormik({
        initialValues: initialValues,
        //validationSchema: agreementPeriodValid,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });

    const handleFinalSubmit = (data) => {
        let formData = {
            AgreementId: getDetails.agreeementId,
            AgencyId: getDetails.agencyId,
        };
        const url = sendtenantagrmnttotenants;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    onDataRecive(getDetails.propertyUId)
                    navigate(`/agency/TenancyAgreementTabs?propertyUID=${getDetails.propertyUId}`);
                    //handleNextPage("AgreementSummary")
                    ToastRef.current.notify(["success", returnData.message]);
                    window.location.reload();
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };


    const handleClear = () => {
        handleNextPage("RepairAndMaintainance");
    };

    const handlePrevious = () => {
        handleNextPage("RepairAndMaintainance")
    };
    return (
        <>
            <div className="dashboard-agency">
                <ToastAlert ref={ToastRef} />
                <div className="continer-dbag">
                    <div className="dashboardagency-content">
                        <div style={{ width: "100%" }}>
                            <form onSubmit={handleSubmit} className="details-form">
                                <div className="property-details-content df-all all-input">
                                    <div>
                                        <Collapse
                                            id={"Agreement-Period"}
                                            title={"Privacy Statement"}
                                            data={
                                                <div>
                                                    <div style={{ padding: "0px 30px" }}>
                                                        <div className="s1" style={{ fontWeight: "600" }}>
                                                            All information collected by the Agent is done so
                                                            with respect of the Privacy Act 1998 and the
                                                            Privacy Principals.
                                                        </div>
                                                        <div className="s1 mt-3">
                                                            Information collected is necessary
                                                            for one or more of the activities associated with
                                                            this agreement.
                                                        </div>
                                                        <div className="s1 mt-3">
                                                            The Agent will not use or disclose
                                                            your personal information for another purpose,
                                                            unless it would be reasonable to expect such
                                                            disclosure is necessary for the completion of
                                                            activities associated with this agreement.
                                                        </div>
                                                        <div className="s1 mt-3">
                                                            The Agent will take all reasonable
                                                            steps to ensure that the personal information
                                                            collected, used or disclosed is accurate, complete
                                                            and up-to-date.
                                                        </div>
                                                        <div className="s1 mt-3">
                                                            The Agent will take reasonable
                                                            steps to protect the personal information it holds
                                                            from misuse and loss and from unauthorised access,
                                                            modification or disclosure and also take
                                                            reasonable steps to destroy or permanently
                                                            de-identify personal information it no longer
                                                            needs.
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="property-details-buttons">
                                    <button
                                        className="pdb-cancel"
                                        type="reset"
                                        onClick={handleClear}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="pdb-cancel"
                                        type="reset"
                                        onClick={handlePrevious}
                                    >
                                        Previous
                                    </button>

                                    <button className="pdb-save-changes" type="submit">
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignaturePage;
