import React from "react";
import Select from "react-select";

const SelectInput = ({
  label,
  name,
  options,
  placeholder,
  value,
  onChange,
  error,
  touched,
  isMulti,
  isMandatory,
  colVal = "",
}) => {
  let colVals =
    colVal === 12
      ? "col-lg-12 col-sm-12"
      : colVal === 3
      ? "col-lg-3 col-sm-12"
      : colVal === 8
      ? "col-lg-8 col-sm-12"
      : colVal === 2 
      ? "col-lg-2 col-sm-4" 
      : "col-lg-6 col-sm-12";
  // Find the option object that matches the provided value
  let selectedValue;
  if (isMulti && typeof value === "string") {
    const selectedValuesArray = value.split(",");
    selectedValue = options ? options.filter((option) =>
      selectedValuesArray.includes(option.value.toString())
    ) : [];
  } else if (options && value !== undefined && value !== null) {
    selectedValue = options.find((option) => option.value === value);
  } else {
    selectedValue = null; // Handle case when no valid value is selected
  }

  return (
    <div className={`ca-${name} ${colVals}`}>
     {label ? <label htmlFor={name} className="s1">
        {label} {isMandatory ? <span style={{color:'red'}}>*</span> : null }
      </label>: null}
      <Select
        options={options}
        placeholder={placeholder}
        className={`mb-2`}
        value={selectedValue} // Set the selected option directly
        name={name}
        onChange={onChange}
        isMulti={isMulti}
      />
      {error && touched ? <p className="form-error">{error}</p> : null}
    </div>
  );
};

export default SelectInput;
