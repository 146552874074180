import React from "react";
import { useEffect, useState, useRef } from "react";
import "./propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../../src/input-components/selectBoxNew";
import {updatetenantfoliodetails} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../../src/input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { rentDetails } from "../../../components/CommonSection/Schema/schema";
import {dynamicUrl} from "../../../Utilities/profileManagement";
import { BiDollar } from "react-icons/bi";


const initialValues = {
  Rent: "",
  Period: "",
  RentIncludeTax: false,
  BondAmount: "",
  BondInTrust: "",
  AgreementStart: "",
  AgreementEnd: "",
  Deposited: "",
  Balance: "",
  RentReviewFrequency: "",
  IsCommercial: "",
  ABN: "",
};

const TenantFolio = ({onDataRecive,handleparentsuccess, getDetails,handleCurrentPage,cancel}) => {
  const [userId, setUserId] = useState("");
  const [agentId, setAgentId] = useState("");
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  var url = new URL(window.location.href);
  const [isCommercialData, setIsCommercialData] = useState([
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]);
  const [periodData, setPeriodData] = useState([
    { label: "Weekly", value: 1 },
    { label: "Monthly", value:  2 },
  ]);

  const [rentIncludeTaxData, setRentIncludeTaxData] = useState([
    { label: "Yes", value: true },
    { label: "No", value:  false },
  ]);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgentId(parsedData.agentId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);


  useEffect(() => {
    if(getDetails){
      let ownerListData = getDetails.tenantDetails ? getDetails.tenantDetails : [];
      setFieldValue("Rent", ownerListData[0]?.tenantFolioDetails.rent);
      setFieldValue("Period", ownerListData[0]?.tenantFolioDetails.period);
      setFieldValue("IsCommercial", ownerListData[0]?.tenantFolioDetails.isCommercial);
      setFieldValue("RentIncludeTax", ownerListData[0]?.tenantFolioDetails.rentIncludeTax);
      setFieldValue("Deposited",ownerListData[0]?.tenantFolioDetails.deposited);
      setFieldValue("Balance", ownerListData[0]?.tenantFolioDetails.balance);
      setFieldValue("RentReviewFrequency", ownerListData[0]?.tenantFolioDetails.rentReviewFrequency);
      setFieldValue("ABN", ownerListData[0]?.tenantFolioDetails.abn);
      setFieldValue("AgreementStart", formatDate(ownerListData[0]?.tenantFolioDetails.agreementStart));
      setFieldValue("AgreementEnd", formatDate(ownerListData[0]?.tenantFolioDetails.agreementEnd));
      setFieldValue("BondAmount", ownerListData[0]?.tenantFolioDetails.bondAmount);
      setFieldValue("BondInTrust", ownerListData[0]?.tenantFolioDetails.bondInTrust);
    }
  },[getDetails])

  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }
    const [year, month, day] = dateString.split("T")[0].split("-");
    return `${year}-${month}-${day}`;
  }

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: rentDetails,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });


  const handleFinalSubmit = () => {
    const formData = {
      Id: getDetails?.tenantDetails[0]?.tenantFolioDetails.id,
      Rent: parseFloat(values.Rent),
      Period: values.Period ? parseInt(values.Period) : null,
      RentIncludeTax: values.RentIncludeTax,
      BondAmount: parseFloat(values.BondAmount),
      BondInTrust: parseFloat(values.BondInTrust),
      AgreementStart: values.AgreementStart ,
      AgreementEnd: values.AgreementEnd ,
      Deposited: parseFloat(values.Deposited),
      Balance: parseFloat(values.Balance),
      RentReviewFrequency: values.RentReviewFrequency,
      IsCommercial: values.IsCommercial,
      ABN: values.ABN,
      UserId: userId,
    };
    const url = updatetenantfoliodetails;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
            cancel();
        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error]);
      });
  };


  useEffect(() => {
    agentUIDRef.current = agentId;
  }, [agentId]);

  const agentUIDRef = useRef(agentId);

  const handleCancel = () => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
    if (propertyUId) {
      handleCurrentPage("Overview")
    } else {
      dynamicUrl(navigate, "propertiesList");
    }
  };

  return (
    <div>
        <form onSubmit={handleSubmit}>
          <div className="property-details-content df-all all-input">
            <div className="pddpn-group pd-address">
              <div className="contacts-group row">
                  <TextBox
                    label={"Rent"}
                    placeholder={"Rent"}
                    name="Rent"
                    value={values.Rent}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.Rent}
                    touched={touched.Rent}
                    icon={
                      <BiDollar height="100%" width="100%" />
                    }
                  />
                <SelectBox
                  label={"Period"}
                  placeholder="Period"
                  name="Period"
                  options={periodData}
                  value={values.Period }
                  onChange={(val) =>
                    setFieldValue("Period", val.value)
                  }
                  onBlur={handleBlur}
                  error={errors.Period }
                  touched={touched.Period }
                />
                <SelectBox
                label={"Rent Include Tax"}
                  placeholder="Rent Include Tax"
                  name="RentIncludeTax"
                  options={rentIncludeTaxData}
                  value={values.RentIncludeTax}
                  onChange={(val) =>
                    setFieldValue("RentIncludeTax", val.value)
                  }
                  error={errors.RentIncludeTax}
                  touched={touched.RentIncludeTax}
                  icon={
                    <BiDollar height="100%" width="100%" />
                  }
                />
                <TextBox
                label={"Bond Amount"}
                  placeholder="Bond Amount"
                  name="BondAmount"
                  value={values.BondAmount}
                  onChange={handleChange}
                    onBlur={handleBlur}
                  error={errors.BondAmount}
                  touched={touched.BondAmount}
                  icon={
                    <BiDollar height="100%" width="100%" />
                  }
                />
                 <TextBox
                 label={"Bond In Trust"}
                  placeholder="Bond In Trust"
                  name="BondInTrust"
                  value={values.BondInTrust}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.BondInTrust}
                  touched={touched.BondInTrust}
                  icon={
                    <BiDollar height="100%" width="100%" />
                  }
                />
                 <TextBox
                  label="Agreement Start"
                  name="AgreementStart"
                  type="date"
                  value={values.AgreementStart}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.AgreementStart}
                  touched={touched.AgreementStart}
                />
                 <TextBox
                  label="Agreement End"
                  name="AgreementEnd"
                  type="date"
                  value={values.AgreementEnd}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.AgreementEnd}
                  touched={touched.AgreementEnd}
                />
                 <TextBox
                 label={"Deposited"}
                  placeholder="Deposited"
                  name="Deposited"
                  value={values.Deposited}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.Deposited}
                  touched={touched.Deposited}
                  icon={
                    <BiDollar height="100%" width="100%" />
                  }
                />
                 <TextBox
                 label={"Balance"}
                  placeholder="Balance"
                  name="Balance"
                  value={values.Balance}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.Balance}
                  touched={touched.Balance}
                  icon={
                    <BiDollar height="100%" width="100%" />
                  }
                />
                 <TextBox
                 label={"Rent Review Frequency"}
                  placeholder="Rent Review Frequency"
                  name="RentReviewFrequency"
                  value={values.RentReviewFrequency}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.RentReviewFrequency}
                  touched={touched.RentReviewFrequency}
                />
                <SelectBox
                label={"Is Commercial"}
                  placeholder="Is Commercial "
                  name="IsCommercial "
                  options={isCommercialData}
                  value={values.IsCommercial }
                  onChange={(val) =>
                    setFieldValue("IsCommercial", val.value)
                  }
                  error={errors.IsCommercial }
                  touched={touched.IsCommercial }
                />
                 <TextBox
                 label={"ABN"}
                  placeholder="ABN"
                  name="ABN"
                  value={values.ABN}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.ABN}
                  touched={touched.ABN}
                />
              </div>
            </div>
          </div>
          <div className="property-details-buttons">
            <button
              className="pdb-cancel"
              type="reset"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button className="pdb-save-changes" type="submit">
              Save
            </button>
          </div>
          </form>
    </div>
  );
};

export default TenantFolio;
