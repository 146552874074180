import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import "../../openHome/openHome.css";
import ShowValue from "../../../input-components/ShowValue";
import { BiDollar } from "react-icons/bi";
import { MdPercent } from "react-icons/md";
import TextBox from "../../../input-components/textbox";
import CustomCheckbox from "../../../input-components/customCheckbox";
import CustomModal from "../../../input-components/Modal";
import TextArea from "../../../input-components/textarea";
import {FilePath,managementagreementsignature,generatePdfReport,updatemangmntagrmnttribunalinsurancedetails,updatemangmntagrmntrepairsdetails,getmanagementagreementforsign } from "../../../../components/CommonSection/apiresources";
import {makeRequestWithSpinner,DownloadFiletWithSpinner,uploadSignToS3WithSpinner,} from "../../../../Utilities/client";
import { ActionStatus, signatureStatus } from "../../../../Utilities/enum";
import SignatureCanvas from "react-signature-canvas";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import { ManagementViewPage } from "../../../../components/CommonSection/Schema/schema";
import { FormatDate } from "../../../../components/CommonSection/Utility/Utility"
import { useScrollToFirstError } from "../../../../Utilities/ScrollToErrorField";
import {Checkbox,DatePicker } from "antd";

dayjs.extend(customParseFormat);

const initialValues = {
  TradesmanPayment: 0,
  supplyUrgentRepairToTenant: null,
  looseFillAsbestosInstallation: null,
  swimmingPoolRegistered: null,
  smokeAlarm: null,
  sealthIssues: null,
  slooding: null,
  bushfire: null,
  seriousViolentCrime: null,
  parkingRestriction: null,
  shareDriveway: null,
  affectdFlooding: null,
  affectdBushfire: null,
  affectdSeriousViolent: null,
  isContractForSalePremises: null,
  isProposelToSell: null,
  hasMortgageeCommenced: null,
  isMortgageeTakgActnPossssion: null,
  waterEfficiencyByNSW: null,
  principalWarrantsSmokeAlarm: null,
  flooding: null,
  healthIssues: null,

  AgreementDate: "",
  LeasePremisesAtMarketRent: "",
  ReferToPrincipalForReLease: "",
  ReviewRentBfrRLease: "",
  PropertyImages: [],
  SignedDate: "",
  AgentSignedDate: "",

  ApplicationFeesForUtilites: "",
  OfficeExpenses: "",
  Redirect: false,
  STRATA: false,
  Water: false,
  CouncilRates: false,
  Insurance: false,
  PAccountName: "",
  PBSB: "",
  PAccountNo: "",
};

const initialValues1 = {
  TradesmanPayment: 0,
  supplyUrgentRepairToTenant: null,
  looseFillAsbestosInstallation: null,
  swimmingPoolRegistered: null,
  smokeAlarm: null,
  healthIssues: null,
  flooding: null,
  bushfire: null,
  seriousViolentCrime: null,
  parkingRestriction: null,
  shareDriveway: null,
  affectdFlooding: null,
  affectdBushfire: null,
  affectdSeriousViolent: null,
  isContractForSalePremises: null,
  isProposelToSell: null,
  hasMortgageeCommenced: null,
  isMortgageeTakgActnPossssion: null,
  waterEfficiencyByNSW: null,
  principalWarrantsSmokeAlarm: null,
};

const AgreementViewPage = ({ handleparentsuccess,reportTo}) => {
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();
  const [secondaryOwner, setSecondaryOwner] = useState([]);
  const [primaryContact, setPrimaryContact] = useState({});
  const [openDescription, setopenDescription] = useState(false);
  const [materialComments, setMaterialComments] = useState("");
  const [storedComments, setStoredComments] = useState("");
  const [descriptions, setDescriptions] = useState("");
  const [commentsObject, setCommentsObject] = useState({});
  const [getDetails, setGetDetails] = useState(null);

  const inputRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const signatureRef = useRef(null);
  const [sign, setSign] = useState(null);
  const [IsSigned, setIsSigned] = useState(null);
  const [date, setDate] = useState("");
  const [verified, setVerified] = useState(false);
  const [signatureText, setSignatureText] = useState("");
  const urls = new URL(window.location.href);
  let ref = urls.searchParams.get("ref");
  let utype = urls.searchParams.get("utype");
  let did = urls.searchParams.get("did");


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);


  useEffect(() => {
    var url = new URL(window.location.href);
    var did = url.searchParams.get("did");
    var utype = url.searchParams.get("utype");
    var ref = url.searchParams.get("ref");
    var activeTab = url.searchParams.get("activeTab");
    GetApi(did, utype, ref);
  }, []);

  const GetApi = (did, utype, ref) => {
    let formData = {
      DocumentId: did,
      UserType: utype,
      Reference: ref,
    };
    const url = getmanagementagreementforsign;
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      url,
      "post",
      formData,
      null,
      false
    )
      .then((response) => {
        if (response.object !== null) {
          setGetDetails(response.object);
          let getDetails = response.object
          setValues({ ...values, ...getDetails });
          setFieldValue(
            "ApplicationFeesForUtilites",
            getDetails.applicationFeesForUtilites
          );
          setFieldValue("OfficeExpenses", getDetails.officeExpenses);
          setFieldValue("Redirect", getDetails.redirect);
          setFieldValue("STRATA", getDetails.strata);
          setFieldValue("Water", getDetails.water);
          setFieldValue("Insurance", getDetails.insurance);
          setFieldValue("PAccountName", getDetails.pAccountName);
          setFieldValue("PBSB", getDetails.pbsb);
          setFieldValue("PAccountNo", getDetails.pAccountNo);
          setFieldValue("CouncilRates", getDetails.councilRates);
    
          setFieldValue("TradesmanPayment", getDetails.tradesmanPayment);
          setFieldValue(
            "supplyUrgentRepairToTenant",
            getDetails.supplyUrgentRepairToTenant
          );
          setFieldValue(
            "looseFillAsbestosInstallation",
            getDetails.looseFillAsbestosInstallation
          );
          setFieldValue(
            "swimmingPoolRegistered",
            getDetails.swimmingPoolRegistered
          );
          setFieldValue("smokeAlarm", getDetails.smokeAlarm);
          setFieldValue("healthIssues", getDetails.healthIssues);
          setFieldValue("flooding", getDetails.flooding);
          setFieldValue("bushfire", getDetails.bushfire);
          setFieldValue("seriousViolentCrime", getDetails.seriousViolentCrime);
          setFieldValue("parkingRestriction", getDetails.parkingRestriction);
          setFieldValue("shareDriveway", getDetails.shareDriveway);
          setFieldValue("affectdFlooding", getDetails.affectdFlooding);
          setFieldValue("affectdBushfire", getDetails.affectdBushfire);
          setFieldValue("affectdSeriousViolent", getDetails.affectdSeriousViolent);
          setFieldValue(
            "isContractForSalePremises",
            getDetails.isContractForSalePremises
          );
          setFieldValue("isProposelToSell", getDetails.isProposelToSell);
          setFieldValue("hasMortgageeCommenced", getDetails.hasMortgageeCommenced);
          setFieldValue(
            "isMortgageeTakgActnPossssion",
            getDetails.isMortgageeTakgActnPossssion
          );
          setFieldValue("waterEfficiencyByNSW", getDetails.waterEfficiencyByNSW);
          setFieldValue(
            "principalWarrantsSmokeAlarm",
            getDetails.principalWarrantsSmokeAlarm
          );
          setPrimaryContact(getDetails.primarycontactModel);
          const refInt = parseInt(ref);
          const document = response.object.documentReceipients.find(
            (doc) => doc.contactId === refInt
          );
          if (document) {
            if (document.isSigned) {
              setHandleNextPages("AgreementSignature");
            }
          }
        }
      })
      .catch((error) => {
        //handleparentsuccess(["error", error]);
      });
  };

  const {
    errors,
    values,
    touched,
    submitCount,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: utype === '3' ? null : ManagementViewPage,
    onSubmit: (values, action) => {
      debugger;
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (data) => {
    let formData = {
      AgreementId: getDetails.id,
      OfficeExpenses: values.OfficeExpenses,
      Redirect: values.Redirect,
      STRATA: values.STRATA ,
      Water: values.Water,
      CouncilRates: values.CouncilRates,
      Insurance: values.Insurance ,
      PAccountName: values.PAccountName ? values.PAccountName : getDetails.pAccountName,
      PBSB: values.PBSB ? values.PBSB: getDetails.pbsb,
      PAccountNo: values.PAccountNo ? values.PAccountNo : getDetails.pAccountNo,
      ApplicationFeesForUtilites: getDetails.applicationFeesForUtilites,
    };
    const url = updatemangmntagrmnttribunalinsurancedetails;

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      url,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          handleFinalSubmitRepair();
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleFinalSubmitRepair = (data) => {
    let formData = {
      AgreementId: getDetails.id,
      TradesmanPayment: parseInt(
        values.TradesmanPayment ? values.TradesmanPayment : 0
      ),
      SupplyUrgentRepairToTenant: values.supplyUrgentRepairToTenant,
      LooseFillAsbestosInstallation: values.looseFillAsbestosInstallation,
      SwimmingPoolRegistered: values.swimmingPoolRegistered,
      SmokeAlarm: values.smokeAlarm,
      HealthIssues: values.healthIssues,
      Flooding: values.flooding,
      Bushfire: values.bushfire,
      SeriousViolentCrime: values.seriousViolentCrime,
      ParkingRestriction: values.parkingRestriction,
      ShareDriveway: values.shareDriveway,
      AffectdFlooding: values.affectdFlooding,
      AffectdBushfire: values.affectdBushfire,
      AffectdSeriousViolent: values.affectdSeriousViolent,
      IsContractForSalePremises: values.isContractForSalePremises,
      IsProposelToSell: values.isProposelToSell,
      HasMortgageeCommenced: values.hasMortgageeCommenced,
      isMortgageeTakgActnPossssion: values.isMortgageeTakgActnPossssion,
      WaterEfficiencyByNSW: values.waterEfficiencyByNSW,
      PrincipalWarrantsSmokeAlarm: values.principalWarrantsSmokeAlarm,
      MaterialComments: materialComments,
      SendToOwner: false,
    };
    const url = updatemangmntagrmntrepairsdetails;
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      url,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          //handleNextPage("AgreementSignature");
          ToastRef.current.notify(["success", returnData.message]);
          getSignatureImage();
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleCheckboxChange = (value, label) => {
    if (
      label === "WaterEfficiencyByNSW" ||
      label == "PrincipalWarrantsSmokeAlarm"
    ) {
    } else {
      value ? setopenDescription(true) : null;
      setStoredComments(label);
      setDescriptions("");
    }
    if (label === "TradesmanPayment") {
      setFieldValue("TradesmanPayment", value);
    } else if (label === "supplyUrgentRepairToTenant") {
      setFieldValue("supplyUrgentRepairToTenant", value);
    } else if (label === "LooseFillAsbestosInstallation") {
      setFieldValue("looseFillAsbestosInstallation", value);
    } else if (label === "SwimmingPoolRegistered") {
      setFieldValue("swimmingPoolRegistered", value);
    } else if (label === "SmokeAlarm") {
      setFieldValue("smokeAlarm", value);
    } else if (label === "HealthIssues") {
      setFieldValue("healthIssues", value);
    } else if (label === "Flooding") {
      setFieldValue("flooding", value);
    } else if (label === "Bushfire") {
      setFieldValue("bushfire", value);
    } else if (label === "SeriousViolentCrime") {
      setFieldValue("seriousViolentCrime", value);
    } else if (label === "ParkingRestriction") {
      setFieldValue("parkingRestriction", value);
    } else if (label === "ShareDriveway") {
      setFieldValue("shareDriveway", value);
    } else if (label === "AffectdFlooding") {
      setFieldValue("affectdFlooding", value);
    } else if (label === "AffectdBushfire") {
      setFieldValue("affectdBushfire", value);
    } else if (label === "AffectdSeriousViolent") {
      setFieldValue("affectdSeriousViolent", value);
    } else if (label === "IsContractForSalePremises") {
      setFieldValue("isContractForSalePremises", value);
    } else if (label === "IsProposelToSell") {
      setFieldValue("isProposelToSell", value);
    } else if (label === "HasMortgageeCommenced") {
      setFieldValue("hasMortgageeCommenced", value);
    } else if (label === "isMortgageeTakgActnPossssion") {
      setFieldValue("isMortgageeTakgActnPossssion", value);
    } else if (label === "WaterEfficiencyByNSW") {
      setFieldValue("waterEfficiencyByNSW", value);
    } else if (label === "PrincipalWarrantsSmokeAlarm") {
      setFieldValue("principalWarrantsSmokeAlarm", value);
    }
  };

  const handleSelectAllChange = (isChecked) => {
    console.log(isChecked, 'ppppp');
    Object.keys(initialValues1).forEach((key) => {
      if (key !== 'TradesmanPayment') {
        let value =
          key === 'waterEfficiencyByNSW' || key === 'principalWarrantsSmokeAlarm'
            ? ''
            : key;
        setFieldValue(value, isChecked);
      }
    });
  };

  const closeDescription = () => {
    setopenDescription(false);
  };

  const handleDescriptions = () => {
    if (descriptions === "") {
      ToastRef.current.notify(["error", "Description is Mandatory"]);
    } else {
      const updatedCommentsObject = {
        ...commentsObject,
        [storedComments]: descriptions,
      };
      const updatedComments = Object.entries(updatedCommentsObject)
        .map(([key, value]) => `${key}: ${value}`)
        .join("; ");

      // Set the updated comments
      setCommentsObject(updatedCommentsObject);
      setMaterialComments(updatedComments);
      if (descriptions != "") {
        setopenDescription(false);
      } else {
        ToastRef.current.notify(["error", "Description is Mandatory"]);
        setFieldValue(storedComments, false);
        setopenDescription(false);
      }
    }
  };

  const handleDescription = (e) => {
    setDescriptions(e.target.value);
  };

  // Function to clear the signature canvas
  const clearSignature = () => {
    signatureRef.current.clear();
  };

  // Function to get the signature data as an image URL
  const getSignatureImage = () => {
    if (activeIndex === 1) {
      handleFinalSubmitSign();
    } else {
      setSign(signatureRef.current.toDataURL());
      handleSignUpload(signatureRef.current.toDataURL());
      return signatureRef.current.toDataURL();
    }
  };
  useEffect(() => {
    // Set default value after component mounts
    const matchedObject = getDetails?.documentReceipients
      ? getDetails.documentReceipients.filter((doc) =>
          utype === "3" ? doc.agentId : doc.contactId === parseInt(ref)
        )
      : null;
    if (matchedObject) {
      setIsSigned(matchedObject.length > 0 ? matchedObject[0].isSigned : false);
      if (signatureRef.current && matchedObject[0].signValue) {
        signatureRef.current.clear(); // Clear the canvas
        // Draw the saved signature
        const ctx = signatureRef.current.getCanvas().getContext("2d");
        const image = new Image();
        image.src = matchedObject[0].signValue;
        image.onload = () => {
          ctx.drawImage(image, 0, 0);
        };
      }
    }
  }, [activeIndex]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);

  useEffect(() => {
    const today = new Date();
    const formattedDate = `${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${today.getDate().toString().padStart(2, "0")}/${today.getFullYear()}`;
    setDate(formattedDate); // Initialize the date as mm/dd/yyyy
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    let ref = url.searchParams.get("ref");
    let utype = url.searchParams.get("utype");
    setVerified(utype === "3" ? true : false);
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setFieldValue("AgreementDate", formatDate(getDetails.agreementDate));
      setFieldValue(
        "ReferToPrincipalForReLease",
        getDetails.referToPrincipalForReLease
      );
      setFieldValue("ReviewRentBfrRLease", getDetails.reviewRentBfrRLease);
      setFieldValue(
        "LeasePremisesAtMarketRent",
        getDetails.leasePremisesAtMarketRent
      );
      setSecondaryOwner(getDetails.contactlistModel);
      const matchedObject = getDetails?.documentReceipients?.filter(
        (doc) => doc.contactId === parseInt(ref)
      );
      setFieldValue(
        "SignedDate",
        formatDate(matchedObject.length > 0 ? matchedObject[0].signedDate : "")
      );
      function formatDate(dateString) {
        if (!dateString) {
          return "";
        }
        const [year, month, day] = dateString.split("T")[0].split("-");
        return `${month}-${day}-${year}`;
      }
      let contacts = getDetails.contactlistModel
        ? getDetails.contactlistModel
        : [];
      const matchedObjects =
        contacts.length === 0
          ? []
          : getDetails.contactlistModel.filter(
              (doc) => doc.contactId === parseInt(ref)
            );
      if (getDetails.primarycontactModel.contactId === parseInt(ref)) {
        setVerified(getDetails.primarycontactModel.isUserVerified);
      } else if (contacts.length != 0) {
        if (
          matchedObjects[0] != null &&
          matchedObjects[0].contactId === parseInt(ref)
        ) {
          setVerified(matchedObjects[0].isUserVerified);
        }
      }
    }
  }, [getDetails, reportTo]);

  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }
    const [year, month, day] = dateString.split("T")[0].split("-");
    return `${month}-${day}-${year}`;
  }

  const isVerified = () => {
    setVerified(true);
  };

  const handleFinalSubmitSign = (principleImages) => {
    const urls = new URL(window.location.href);
    let ref = urls.searchParams.get("ref");
    let signMethods =
      activeIndex + 1 === signatureStatus.Image
        ? 1
        : activeIndex + 1 === signatureStatus.Type
        ? 2
        : activeIndex + 1 === signatureStatus.Draw
        ? 3
        : "";
    let formData = {
      DocumentId: did,
      ContactId: utype === "10" ? ref : null,
      AgentId: utype === "3" ? ref : null,
      SignedDate: moment(),
      SignMethod: signMethods,
      SignPicturePath: principleImages,
      SignValue:
        activeIndex === 0 ? sign : activeIndex === 1 ? signatureText : null,
    };

    const url = managementagreementsignature;
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      url,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          setIsSigned(true);
          ToastRef.current.notify(["success", returnData.message]);
          setIsSigned(true);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleClear = () => {
    handleNextPage("RepairAndMaintainance");
  };

  const textWithBullet = "\u2022 ";

  const select = (event) => {
    inputRef.current.click();
  };
  const handleSignUpload = async (imgByte) => {
    const url = new URL(window.location.href);
    let ref = url.searchParams.get("ref");
    await uploadSignToS3WithSpinner(
      showSpinner,
      hideSpinner,
      "signatures/",
      `contact_${ref}`,
      imgByte
    ).then((returnData) => {
      if (returnData.status == ActionStatus.Successfull) {
        const transformedItem = {
          id: Math.floor(Math.random() * 1000000),
          src: FilePath + returnData.Key,
          width: 1,
          height: 1,
        };
        handleFinalSubmitSign(returnData.Key);
        handleparentsuccess(["success", "Sign uploaded successfully", true]);
      } else {
        handleparentsuccess([
          "error",
          "Error uploading file. Please try again.",
        ]);
      }
    });
  };



  const handleAgreementDownload = () => {
    const formData = {
      PropertyUId: getDetails.propertyUId,
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generatePdfReport,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData != null) {
          window.open(returnData, "_blank");
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const isRefMatched = getDetails?.documentReceipients
    ? getDetails.documentReceipients.some(
        (doc) => doc.contactId === parseInt(ref)
      )
    : null;

  const handleSignature = (e) => {
    setSignatureText(e.target.value);
  };
  useScrollToFirstError(isValid, errors, submitCount);

  const onChangeRadio = (e, label) => {
    if (label === "STRATA") {
        setFieldValue("STRATA", e.target.checked);
    } else if (label === "Water") {
        setFieldValue("Water", e.target.checked);
    } else if (label === "CouncilRates") {
        setFieldValue("councilRates", e.target.checked);
    } else if (label === "Insurance") {
        setFieldValue("Insurance", e.target.checked);
    }else if (label === "Redirect"){
      setFieldValue("Redirect", e.target.checked)
    }
};

const handleChangeDate = (date, dateString) => {
  setDate(dateString); // `dateString` is already in the desired format
};

  return (
    <>
      <div className="dashboard-agency">
        <ToastAlert ref={ToastRef} />
        <div className="continer-dbag">
          <div className="dashboardagency-content" style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <form onSubmit={handleSubmit} className="">
                <div
                  className="property-details-content  all-input"
                  style={{ justifyContent: "center" }}
                >
                  <div>
                    {!IsSigned ? (
                      <div>
                        <div className="pddpn-group pddpn-group1 "></div>
                        <div className="pddpn-group2 pddpn-group3">
                          <div className=" agreementLabel">{`Primary Property Owner(s)`}</div>

                          <div className={`lda-${name} `}>
                            <div className="address-pair">
                              <div className="labelssss">Name</div>
                              <div style={{ marginTop: "3px" }}>:</div>
                              <div className="valuessss">
                                {" "}
                                {primaryContact.fName +
                                  " " +
                                  primaryContact.lName}
                              </div>
                            </div>
                          </div>
                          <div className={`lda-${name} `}>
                            <div className="address-pair">
                              <div className="labelssss">Email</div>
                              <div style={{ marginTop: "3px" }}>:</div>
                              <div className="valuessss">
                                {" "}
                                {primaryContact.contactEmail}
                              </div>
                            </div>
                          </div>
                          <div className={`lda-${name} `}>
                            <div className="address-pair">
                              <div className="labelssss">Mobile Number</div>
                              <div style={{ marginTop: "3px" }}>:</div>
                              <div className="valuessss">
                                {" "}
                                {primaryContact.mobileNumber}
                              </div>
                            </div>
                          </div>
                          <div className={`lda-${name}`}>
                            <div className="address-pair">
                              <div className="labelssss">Address</div>
                              <div style={{ marginTop: "3px" }}>:</div>
                              <div className="valuessss"> {primaryContact.address}</div>
                            </div>
                          </div>
                          <div className={`lda-${name}`}>
                            <div className="address-pair">
                              <div className="labelssss">
                                Registered for GST
                              </div>
                              <div style={{ marginTop: "3px" }}>:</div>
                              <div className="valuessss"> {"Yes"}</div>
                            </div>
                          </div>
                          <div className={`lda-${name}`}>
                            <div className="address-pair">
                              <div className="labelssss">ABN/ACN</div>
                              <div style={{ marginTop: "3px" }}>:</div>
                              <div className="valuessss">
                                {" "}
                                {getDetails
                                  ? getDetails.primarycontactModel
                                    ? getDetails.primarycontactModel.abn !==
                                      null
                                      ? getDetails.primarycontactModel.abn
                                      : "Nill"
                                    : "Nill"
                                  : "Nill"}
                              </div>
                            </div>
                          </div>
                          {secondaryOwner
                            ? secondaryOwner.map((contact, index) => (
                                <div style={{ width: "100%" }}>
                                  <div className="mt-4 agreementLabel">{`Secondary Owner (${
                                    index + 1
                                  })`}</div>
                                  <div className={`lda-${name} `}>
                                    <div className="address-pair">
                                      <div className="labelssss">Name</div>
                                      <div style={{ marginTop: "3px" }}>:</div>
                                      <div className="valuessss">
                                        {" "}
                                        {contact.fName + " " + contact.lName}
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`lda-${name} `}>
                                    <div className="address-pair">
                                      <div className="labelssss">Email</div>
                                      <div style={{ marginTop: "3px" }}>:</div>
                                      <div className="valuessss">
                                        {" "}
                                        {contact.contactEmail}
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`lda-${name} `}>
                                    <div className="address-pair">
                                      <div className="labelssss">
                                        Mobile Number
                                      </div>
                                      <div style={{ marginTop: "3px" }}>:</div>
                                      <div className="valuessss">
                                        {" "}
                                        {contact.mobileNumber}
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`lda-${name} `}>
                                    <div className="address-pair">
                                      <div className="labelssss">Address</div>
                                      <div style={{ marginTop: "3px" }}>:</div>
                                      <div className="valuessss">
                                        {" "}
                                        {contact.getFullAddress}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                        <div>
                          <div className="pddpn-group pddpn-group2 pddpn-group3">
                            <div
                              className="mt-4 agreementLabel"
                              style={{ width: "100%" }}
                            >{`Property Details`}</div>
                            <div
                              className={`lda-${name} `}
                              style={{ width: "100%" }}
                            >
                              <div className="address-pair">
                                <div className="labelssss">Address</div>
                                <div>:</div>
                                <div className="valuessss">
                                  {" "}
                                  {getDetails ? getDetails.propertyAddress : ""}
                                </div>
                              </div>
                            </div>
                            <div
                              className={`lda-${name} `}
                              style={{ width: "100%" }}
                            >
                              <div className="address-pair">
                                <div className="labelssss">
                                  The property is Unfurnished
                                </div>
                                <div>:</div>
                                <div className="valuessss">
                                  {" "}
                                  {values.propertyUnfurnished ? "Yes" : "No"}
                                </div>
                              </div>
                            </div>
                            <div
                              className={`lda-${name} `}
                              style={{ width: "100%" }}
                            >
                              <div className="address-pair">
                                <div className="labelssss">
                                  Furnished (inventory prepared by)
                                </div>
                                <div>:</div>
                                <div className="valuessss">
                                  {" "}
                                  {values.propertyFurnished ? "Yes" : "No"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="pddpn-group pddpn-group2 pddpn-group3">
                      {!IsSigned ? (
                        <>
                          <div
                            className="mt-4 agreementLabel"
                            style={{ width: "100%" }}
                          >{`Rent`}</div>
                          <div
                            className={`lda-${name} `}
                            style={{ width: "100%" }}
                          >
                            <div className="address-pair">
                              <div className="labelssss">
                                Rent at which the property is to be
                                offered(including GST if any)
                              </div>
                              <div>:</div>
                              <div className="valuessss">
                                {" "}
                                <BiDollar /> {values.rent}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`lda-${name} `}
                            style={{ width: "100%" }}
                          >
                            <div className="address-pair">
                              <div className="labelssss">Bond</div>
                              <div>:</div>
                              <div className="valuessss">
                                {" "}
                                <BiDollar /> {values.bondAmount}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}

                      {getDetails ? (
                        <div style={{ width: "100%" }}>
                          {!IsSigned ? (
                            <div>
                              <div
                                className="mt-4 agreementLabel mb-3"
                                style={{ width: "100%" }}
                              >{`Agent`}</div>
                              <div
                                className={`lda-${name} `}
                                style={{ width: "100%" }}
                              >
                                <div className="address-pair">
                                  <div className="labelssss">Business Name</div>
                                  <div style={{ marginTop: "3px" }}>:</div>
                                  <div className="valuessss">
                                    {" "}
                                    {getDetails.companyName}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`lda-${name} `}
                                style={{ width: "100%" }}
                              >
                                <div className="address-pair">
                                  <div className="labelssss">
                                    License Number
                                  </div>
                                  <div style={{ marginTop: "3px" }}>:</div>
                                  <div className="valuessss">
                                    {" "}
                                    {getDetails.agencyLicenceNumber}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`lda-${name} `}
                                style={{ width: "100%" }}
                              >
                                <div className="address-pair">
                                  <div className="labelssss">ABN/ACN</div>
                                  <div style={{ marginTop: "3px" }}>:</div>
                                  <div className="valuessss">
                                    {" "}
                                    {getDetails.agencyAbn}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`lda-${name} `}
                                style={{ width: "100%" }}
                              >
                                <div className="address-pair">
                                  <div className="labelssss">Trading as</div>
                                  <div style={{ marginTop: "3px" }}>:</div>
                                  <div className="valuessss">
                                    {" "}
                                    {getDetails.tradingName}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`lda-${name} `}
                                style={{ width: "100%" }}
                              >
                                <div className="address-pair">
                                  <div className="labelssss">Address</div>
                                  <div style={{ marginTop: "3px" }}>:</div>
                                  <div className="valuessss">
                                    {" "}
                                    {getDetails.agencyAddress}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`lda-${name} `}
                                style={{ width: "100%" }}
                              >
                                <div className="address-pair">
                                  <div className="labelssss">Phone</div>
                                  <div style={{ marginTop: "3px" }}>:</div>
                                  <div className="valuessss">
                                    {" "}
                                    {getDetails.agencyWorkPhone}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`lda-${name} `}
                                style={{ width: "100%" }}
                              >
                                <div className="address-pair">
                                  <div className="labelssss">PostCode</div>
                                  <div style={{ marginTop: "3px" }}>:</div>
                                  <div className="valuessss">
                                    {" "}
                                    {getDetails.agencyPostCode}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`lda-${name} `}
                                style={{ width: "100%" }}
                              >
                                <div className="address-pair">
                                  <div className="labelssss">Fax</div>
                                  <div style={{ marginTop: "3px" }}>:</div>
                                  <div className="valuessss">
                                    {" "}
                                    {getDetails.fax}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`lda-${name} `}
                                style={{ width: "100%" }}
                              >
                                <div className="address-pair">
                                  <div className="labelssss">Mobile</div>
                                  <div style={{ marginTop: "3px" }}>:</div>
                                  <div className="valuessss">
                                    {" "}
                                    {getDetails.mobile}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`lda-${name} `}
                                style={{ width: "100%" }}
                              >
                                <div className="address-pair">
                                  <div className="labelssss">
                                    Email Address{" "}
                                  </div>
                                  <div style={{ marginTop: "3px" }}>:</div>
                                  <div className="valuessss">
                                    {" "}
                                    {getDetails.agencyEmail}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {!IsSigned ? (
                            <div>
                              <div className="mt-4 agreementLabel mb-3 ">{`Agreement Period`}</div>

                              <div className={`lda-${name} `}>
                                <ShowValue
                                  Label="Commencing On"
                                  setData={formatDate(getDetails.agreementDate)}
                                />
                              </div>
                              <div className="s1 ">
                                In consideration of the Agent promising to use
                                their best endeavors, the Principal hereby
                                grants to the Agent the right to act on behalf
                                of the Principal in leasing and management the
                                property in accordance with this agreement,30
                                Days’ notice given by either party, If notice is
                                given all fees and charges will be payable on
                                the completion date.
                              </div>

                              <div className=" agreementLabel mb-3 mt-4">{`Agent’s Authority for Leasing`}</div>

                              <div className="s1 ">
                                At the end of each tenancy, the Agent is
                                authorized to
                              </div>
                              <div
                                className={`lda-${name} col-lg-12 col-sm-12`}
                                style={{ paddingLeft: "0px" }}
                              >
                                <div className="address-pair ">
                                  <div className="labelssss">
                                    Lease and Re-lease the Premises at market
                                    rent
                                  </div>
                                  <div style={{ marginTop: "3px" }}>:</div>
                                  <div className="valuessss">
                                    {" "}
                                    {getDetails.leasePremisesAtMarketRent
                                      ? "Yes"
                                      : "No"}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`lda-${name} col-lg-12 col-sm-12`}
                                style={{ paddingLeft: "0px" }}
                              >
                                <div className="address-pair ">
                                  <div className="labelssss">
                                    Review the rent when appropriate before
                                    re-leasing
                                  </div>
                                  <div style={{ marginTop: "3px" }}>:</div>
                                  <div className="valuessss">
                                    {" "}
                                    {getDetails.reviewRentBfrRLease
                                      ? "Yes"
                                      : "No"}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`lda-${name} col-lg-12 col-sm-12`}
                                style={{ paddingLeft: "0px" }}
                              >
                                <div className="address-pair ">
                                  <div className="labelssss">
                                    Refer to the Principal for instructions
                                    concerning re-leasing and
                                    advertising/promotion
                                  </div>
                                  <div style={{ marginTop: "3px" }}>:</div>
                                  <div className="valuessss">
                                    {" "}
                                    {getDetails.referToPrincipalForReLease
                                      ? "Yes"
                                      : "No"}
                                  </div>
                                </div>
                              </div>

                              <div
                                className="col-lg-12 col-sm-12 mt-4 agreementLabel mb-3"
                                style={{ paddingLeft: "0px" }}
                              >{`Fees and Charges`}</div>
                              <div className="s1">
                                The agent shall be entitled to a leasing fee of
                                1 week. If Principal/client cancels or back-off
                                from market they shall be liable to pay 1 weeks
                                rent
                              </div>
                              <div
                                className={`lda-${name} col-lg-12 col-sm-12`}
                                style={{ paddingLeft: "0px" }}
                              >
                                <div className="address-pair ">
                                  <div className="labelssss">Letting Fee</div>
                                  <div style={{ marginTop: "3px" }}>:</div>
                                  <div className="valuessss">
                                    {" "}
                                    <BiDollar /> {getDetails.initialWeekRent}
                                  </div>
                                </div>
                              </div>

                              <div
                                className="col-lg-12 col-sm-12 mt-4 agreementLabel mb-3"
                                style={{ paddingLeft: "0px" }}
                              >{`If the property is leased either`}</div>
                              <div
                                className={`lda-${name} col-lg-12 col-sm-12`}
                                style={{ paddingLeft: "0px" }}
                              >
                                <div className="s1">
                                  The fee to which the Agent is entitled shall
                                  be due and payable on the signing of a tenancy
                                  agreement or upon demand if the tenancy
                                  agreement is not completed owing to a default
                                  of the Principal after the parties have
                                  entered into a binding agreement or if after
                                  making the agreement the Principal and Tenant
                                  mutually agree not to proceed with the
                                  agreement
                                </div>
                                <div className="address-pair ">
                                  <div className="labelssss">
                                    During the agreement period
                                  </div>
                                  <div style={{ marginTop: "3px" }}>:</div>
                                  <div className="valuessss">
                                    {" "}
                                    {getDetails.prprtyLeasdPrdDuringAgrmnt
                                      ? "Yes"
                                      : "No"}
                                  </div>
                                </div>
                              </div>
                              <div className="address-pair">
                                <div className="labelssss">
                                  Within 60 days of the termination of the
                                </div>
                                <div style={{ marginTop: "3px" }}>:</div>
                                <div className="valuessss">
                                  {" "}
                                  {getDetails.prprtyLeasdPrdWithinMonth
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div
                            className={`lda-${name} col-lg-12 col-sm-12`}
                            style={{ paddingLeft: "0px" }}
                          >
                            {!IsSigned ? (
                              <div>
                                <div
                                  className="col-lg-12 col-sm-12 mt-4 agreementLabel mb-3"
                                  style={{ paddingLeft: "0px" }}
                                >{`The Agent shall be entitled to the following ongoing fees`}</div>

                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Management Fee of{" "}
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      <MdPercent />{" "}
                                      {getDetails.managementFee +
                                        " " +
                                        "+" +
                                        "GST"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Marketing Fee of
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      <BiDollar /> {getDetails.marketingFee}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      An Administration Fee of
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      <BiDollar />{" "}
                                      {getDetails.administrationFee}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {!IsSigned ? (
                              <div>
                                <div
                                  className=" agreementLabel mb-3 mt-4 tribulanceBreak"
                                  style={{ paddingLeft: "0px" }}
                                >{`Tribunal Fees & Insurance Claims (Charges In Relation To The Management Of The Property)`}</div>
                                <div className="s1">
                                  Application fees for NCAT, Sherriff or other
                                  statutory authorities. Preparation of NCAT
                                  case. Organizing repairs, maintenance or
                                  refurbishments. Calculation of payments of any
                                  outgoings.
                                </div>
                                <div className="s1">
                                  Office expenses in respect of each management
                                  being postage, phone calls and out of pocket
                                  expenses
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss ">
                                    Application fees for NCAT/Tribunal
                                    </div>
                                    <div className="valuessss">
                                      : {"At Cost"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss ">
                                    Sherriff or other statutory authorities
                                    </div>
                                    <div className="valuessss">
                                      : {"At Cost"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss ">
                                    Office expenses/Postage in relation to NCAT
                                    </div>
                                    <div className="valuessss">
                                      : {"At Cost"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss ">Postage </div>
                                    <div className="valuessss">
                                      : {"At Cost"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                    Preparation, commuting and attendance of NCAT/Tribunal & Insurance
                                    </div>
                                    <div className="valuessss">
                                      : <BiDollar />
                                      {getDetails.applicationFeesForUtilites}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {!IsSigned ? (
                              <div>
                                <div
                                  className="agreementLabel mb-3 mt-4"
                                  style={{ paddingLeft: "0px" }}
                                >{`I Authorize Foreal Property To Pay The Following Accounts`}</div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="s1">
                                    Please provide copy of rates for payment
                                    reference
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12 mt-3`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                    <Checkbox className='s1' checked={values.Redirect} onChange={(e) => onChangeRadio(e, "Redirect")}>Redirect</Checkbox>
                                    <Checkbox className='s1' checked={values.STRATA} onChange={(e) => onChangeRadio(e, "STRATA")}>Strata</Checkbox>
                                    <Checkbox className='s1' checked={values.Water} onChange={(e) => onChangeRadio(e, "Water")}>Water</Checkbox>
                                    <Checkbox className='s1' checked={values.councilRates} onChange={(e) => onChangeRadio(e, "CouncilRates")}>Council Rates</Checkbox>
                                    <Checkbox className='s1' checked={values.Insurance} onChange={(e) => onChangeRadio(e, "Insurance")}>Insurance</Checkbox>
                                </div>
                               
                              </div>
                            ) : null}
                            {!IsSigned ? (
                              <div>
                                <div className=" agreementLabel mb-3 mt-4">{`Rental Monies to Be Deposited Into the Following Account`}</div>
                                {utype !== "3" ? (
                                  <div className="">
                                    <TextBox
                                      isMandatory
                                      label="A/C Name"
                                      name="PAccountName"
                                      value={values.PAccountName ? values.PAccountName: getDetails.pAccountName}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={errors.PAccountName}
                                      touched={touched.PAccountName}
                                    />
                                    <TextBox
                                      isMandatory
                                      label="BSB"
                                      name="PBSB"
                                      value={values.PBSB ? values.PBSB : getDetails.pbsb}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={errors.PBSB}
                                      touched={touched.PBSB}
                                    />
                                    <TextBox
                                      isMandatory
                                      label="A/C No"
                                      name="PAccountNo"
                                      value={values.PAccountNo ? values.PAccountNo : getDetails.pAccountNo}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={errors.PAccountNo}
                                      touched={touched.PAccountNo}
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <div
                                      className={`lda-${name} col-lg-12 col-sm-12`}
                                      style={{ paddingLeft: "0px" }}
                                    >
                                      <div className="address-pair">
                                        <div className="labelssss">
                                          A/C Name
                                        </div>
                                        <div className="valuessss">
                                          : {getDetails.pAccountName}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={`lda-${name} col-lg-12 col-sm-12`}
                                      style={{ paddingLeft: "0px" }}
                                    >
                                      <div className="address-pair">
                                        <div className="labelssss">BSB</div>
                                        <div className="valuessss">
                                          : {getDetails.pbsb}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={`lda-${name} col-lg-12 col-sm-12`}
                                      style={{ paddingLeft: "0px" }}
                                    >
                                      <div className="address-pair">
                                        <div className="labelssss">A/C No</div>
                                        <div className="valuessss">
                                          : {getDetails.pAccountNo}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : null}
                            {!IsSigned ? (
                              <div>
                                <div
                                  className="agreementLabel mb-3 mt-4"
                                  style={{ paddingLeft: "0px" }}
                                >{`Promotional Activities`}</div>

                                <div className="s1">
                                  The Rental Property is to be advertised and/or
                                  otherwise promoted as followed
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Listing on Realestate.com.au
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      {getDetails.isListedOnREA ? "Yes" : "No"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Social Media Campaign
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      {getDetails.socialMedia ? "Yes" : "No"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Listing on Domain.com.au
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      {getDetails.isListedOnDomain
                                        ? "Yes"
                                        : "No"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">Sign board</div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      {getDetails.signboard ? "Yes" : "No"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {!IsSigned ? (
                              <div>
                                <div className="agreementLabel mb-3 mt-4 tribulanceBreak">{`Disclosures of Rebates, Discounts or Commissions in Respect of Expenses`}</div>

                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Name of Third party
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      {getDetails.thirdPartyName}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Estimated Amount of Rebate, Discount or
                                      Commission
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      <BiDollar />
                                      {getDetails.amountOfRebateDiscount}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {!IsSigned ? (
                              <div>
                                <div
                                  className="agreementLabel mb-3 mt-4"
                                  style={{ paddingLeft: "0px" }}
                                >{`Agent’s Authority for Managing the Property`}</div>
                                <div className="s1">
                                  The agent is authorized to:
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Obtain references from prospective
                                      tenants, arrange inspections of the
                                      property by prospective tenants and choose
                                      a tenant
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      {getDetails.obtainReferenceFromTenant
                                        ? "Yes"
                                        : "No"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Enter into and sign a tenancy agreement
                                      (specifying the term for which the
                                      property may be let)
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      {getDetails.enterIntoSignTenacyAgrmnt
                                        ? "Yes"
                                        : "No"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Undertake inspections of the property
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      {getDetails.undertakeInspection
                                        ? "Yes"
                                        : "No"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Effect repairs to and maintain the
                                      property or engage tradespersons to do so
                                      up to an approved amount without obtaining
                                      the approval of Principal
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      {getDetails.effectRepairMaintainProprty
                                        ? "Yes"
                                        : "No"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Pay disbursements and expenses incurred in
                                      connection with the agent’s management of
                                      the property
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      {getDetails.payDisbursemnt ? "Yes" : "No"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Pay disbursements and expenses incurred in
                                      connection with the agent’s management of
                                      the property
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      {getDetails.payDisbursemnt ? "Yes" : "No"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Pay disbursements and expenses incurred in
                                      connection with the agent’s management of
                                      the property
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      {getDetails.payDisbursemnt ? "Yes" : "No"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Pay disbursements and expenses incurred in
                                      connection with the agent’s management of
                                      the property
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      {getDetails.payDisbursemnt ? "Yes" : "No"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Represent the Principal in any tribunal or
                                      court proceedings in respect of the
                                      tenancy of the property
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      {getDetails.representPrincipalCourt
                                        ? "Yes"
                                        : "No"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Pay accounts for amounts owing in relation
                                      to the property (for example, water rates
                                      and charges, council rates, maintenance
                                      expenses and owners corporation levies)
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      {getDetails.payAccountsForAmount
                                        ? "Yes"
                                        : "No"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Advertise the property for letting or
                                      re-letting,
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      {getDetails.advertisePropertyLetting
                                        ? "Yes"
                                        : "No"}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      Review the rent at the end of a tenancy
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      {getDetails.reviewRentEndTenancy
                                        ? "Yes"
                                        : "No"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {!IsSigned ? (
                              <div>
                                <div
                                  className="agreementLabel mb-3 mt-4"
                                  style={{ paddingLeft: "0px" }}
                                >{`Repairs and Maintenance`}</div>
                                <div
                                  className={`lda-${name} col-lg-12 col-sm-12`}
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="address-pair">
                                    <div className="labelssss">
                                      The agent is authorised to engage a
                                      suitably qualified tradesperson to
                                      undertake repairs up to an amount of
                                    </div>
                                    <div style={{ marginTop: "3px" }}>:</div>
                                    <div className="valuessss">
                                      {" "}
                                      <BiDollar /> {getDetails.tradesmanPayment}
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="agreementLabel mb-3"
                                  style={{
                                    paddingLeft: "0px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {`Material Facts`}
                                  <div style={{ display: "flex" }}>
                                    <span className="mr-3">Select All</span>
                                    <div style={{ marginRight: "16px" }}>
                                      <CustomCheckbox
                                        labelName="SelectAll"
                                        defaultChecked={Object.values(values).every(Boolean)} 
                                        onChange={handleSelectAllChange}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="pddpn-group">
                                  <div
                                    className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      style={{ width: "100%" }}
                                      className="mr-5 s1"
                                    >
                                      Loose Fill Asbestos Installation
                                    </div>
                                    <CustomCheckbox
                                      name="looseFillAsbestosInstallation"
                                      labelName="LooseFillAsbestosInstallation"
                                      error={
                                        errors.looseFillAsbestosInstallation
                                      }
                                      touched={
                                        touched.looseFillAsbestosInstallation
                                      }
                                      defaultChecked={
                                        values.looseFillAsbestosInstallation
                                      }
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          "looseFillAsbestosInstallation"
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      style={{ width: "100%" }}
                                      className="mr-5 s1"
                                    >
                                      Smoke alarm
                                    </div>
                                    <CustomCheckbox
                                      name="smokeAlarm"
                                      error={errors.smokeAlarm}
                                      touched={touched.smokeAlarm}
                                      defaultChecked={values.smokeAlarm}
                                      onChange={(e) =>
                                        handleCheckboxChange(e, "smokeAlarm")
                                      }
                                    />
                                  </div>
                                  <div
                                    className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      style={{ width: "100%" }}
                                      className="mr-5 s1"
                                    >
                                      Swimming pool registered
                                    </div>
                                    <CustomCheckbox
                                      name="swimmingPoolRegistered"
                                      error={errors.swimmingPoolRegistered}
                                      touched={touched.swimmingPoolRegistered}
                                      defaultChecked={
                                        values.swimmingPoolRegistered
                                      }
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          "swimmingPoolRegistered"
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      style={{ width: "100%" }}
                                      className="mr-5 s1"
                                    >
                                      Health issues
                                    </div>
                                    <CustomCheckbox
                                      name="healthIssues"
                                      error={errors.healthIssues}
                                      touched={touched.healthIssues}
                                      defaultChecked={values.healthIssues}
                                      onChange={(e) =>
                                        handleCheckboxChange(e, "HealthIssues")
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {!IsSigned ? (
                              <div>
                                <div
                                  className="agreementLabel mb-3 mt-4"
                                  style={{ paddingLeft: "0px" }}
                                >{`In The Last 5 Years Has The Property Been Subject To`}</div>
                                <div className="pddpn-group">
                                  <div
                                    className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      style={{ width: "100%" }}
                                      className="mr-5 s1"
                                    >
                                      Flooding
                                    </div>
                                    <CustomCheckbox
                                      name="flooding"
                                      error={errors.flooding}
                                      touched={touched.flooding}
                                      defaultChecked={values.flooding}
                                      onChange={(e) =>
                                        handleCheckboxChange(e, "Flooding")
                                      }
                                    />
                                  </div>
                                  <div
                                    className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      style={{ width: "100%" }}
                                      className="mr-5 s1"
                                    >
                                      Bushfire
                                    </div>
                                    <CustomCheckbox
                                      name="bushfire"
                                      error={errors.bushfire}
                                      touched={touched.bushfire}
                                      defaultChecked={values.bushfire}
                                      onChange={(e) =>
                                        handleCheckboxChange(e, "Bushfire")
                                      }
                                    />
                                  </div>
                                  <div
                                    className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      style={{ width: "100%" }}
                                      className="mr-5 s1"
                                    >
                                      Serious violent crime
                                    </div>
                                    <CustomCheckbox
                                      name="seriousViolentCrime"
                                      error={errors.seriousViolentCrime}
                                      touched={touched.seriousViolentCrime}
                                      defaultChecked={
                                        values.seriousViolentCrime
                                      }
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          "SeriousViolentCrime"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {!IsSigned ? (
                              <div>
                                <div
                                  className="agreementLabel mb-3 mt-4"
                                  style={{ paddingLeft: "0px" }}
                                >{`Affected by`}</div>
                                <div className="pddpn-group">
                                  <div
                                    className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      style={{ width: "100%" }}
                                      className="mr-5 s1"
                                    >
                                      Parking Restrictions
                                    </div>
                                    <CustomCheckbox
                                      name="parkingRestriction"
                                      error={errors.parkingRestriction}
                                      touched={touched.parkingRestriction}
                                      defaultChecked={values.parkingRestriction}
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          "ParkingRestriction"
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      style={{ width: "100%" }}
                                      className="mr-5 s1"
                                    >
                                      Share driveways
                                    </div>
                                    <CustomCheckbox
                                      name="shareDriveway"
                                      error={errors.shareDriveway}
                                      touched={touched.shareDriveway}
                                      defaultChecked={values.shareDriveway}
                                      onChange={(e) =>
                                        handleCheckboxChange(e, "ShareDriveway")
                                      }
                                    />
                                  </div>
                                  <div
                                    className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      style={{ width: "100%" }}
                                      className="mr-5 s1"
                                    >
                                      Serious violent crime
                                    </div>
                                    <CustomCheckbox
                                      name="affectdSeriousViolent"
                                      error={errors.affectdSeriousViolent}
                                      touched={touched.affectdSeriousViolent}
                                      defaultChecked={
                                        values.affectdSeriousViolent
                                      }
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          "AffectdSeriousViolent"
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      style={{ width: "100%" }}
                                      className="mr-5 s1"
                                    >
                                      Flooding
                                    </div>
                                    <CustomCheckbox
                                      name="affectdFlooding"
                                      error={errors.affectdFlooding}
                                      touched={touched.affectdFlooding}
                                      defaultChecked={values.affectdFlooding}
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          "AffectdFlooding"
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      style={{ width: "100%" }}
                                      className="mr-5 s1"
                                    >
                                      Bushfire
                                    </div>
                                    <CustomCheckbox
                                      name="affectdBushfire"
                                      error={errors.affectdBushfire}
                                      touched={touched.affectdBushfire}
                                      defaultChecked={values.affectdBushfire}
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          "AffectdBushfire"
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      style={{ width: "100%" }}
                                      className="mr-5 s1"
                                    >
                                      Is there a contract for the sale of the
                                      premises
                                    </div>
                                    <CustomCheckbox
                                      name="isContractForSalePremises"
                                      error={errors.isContractForSalePremises}
                                      touched={
                                        touched.isContractForSalePremises
                                      }
                                      defaultChecked={
                                        values.isContractForSalePremises
                                      }
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          "IsContractForSalePremises"
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      style={{ width: "100%" }}
                                      className="mr-5 s1"
                                    >
                                      Is there a proposal to sell the premises
                                    </div>
                                    <CustomCheckbox
                                      name="isProposelToSell"
                                      error={errors.isProposelToSell}
                                      touched={touched.isProposelToSell}
                                      defaultChecked={values.isProposelToSell}
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          "IsProposelToSell"
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      style={{ width: "100%" }}
                                      className="mr-5 s1"
                                    >
                                      Has a mortgagee commenced proceedings in
                                      court to enforce a mortgage over the
                                      premises
                                    </div>
                                    <CustomCheckbox
                                      name="hasMortgageeCommenced"
                                      error={errors.hasMortgageeCommenced}
                                      touched={touched.hasMortgageeCommenced}
                                      defaultChecked={
                                        values.hasMortgageeCommenced
                                      }
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          "HasMortgageeCommenced"
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      style={{ width: "100%" }}
                                      className="mr-5 s1"
                                    >
                                      Is the mortgagee taking action for
                                      possession of the property
                                    </div>
                                    <CustomCheckbox
                                      name="isMortgageeTakgActnPossssion"
                                      error={
                                        errors.isMortgageeTakgActnPossssion
                                      }
                                      touched={
                                        touched.isMortgageeTakgActnPossssion
                                      }
                                      defaultChecked={
                                        values.isMortgageeTakgActnPossssion
                                      }
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          "isMortgageeTakgActnPossssion"
                                        )
                                      }
                                    />
                                  </div>
                                </div>{" "}
                              </div>
                            ) : null}
                            {!IsSigned ? (
                              <div>
                                <div
                                  className=" agreementLabel mb-3 mt-4"
                                  style={{ paddingLeft: "0px" }}
                                >{`Water Efficiency Devises`}</div>

                                <div className="s1">
                                  The agent is authorized to:
                                </div>
                                <div>
                                  <div
                                    className=""
                                    style={{ display: "flex", width: "100%" }}
                                  >
                                    <div
                                      className="mr-5 s1"
                                      style={{ width: "100%" }}
                                    >
                                      The premises contain the water efficiency
                                      measures prescribed by the Residential
                                      Tenancies Act 2010 (NSW)
                                    </div>
                                    <CustomCheckbox
                                      name="waterEfficiencyByNSW"
                                      error={errors.waterEfficiencyByNSW}
                                      touched={touched.waterEfficiencyByNSW}
                                      defaultChecked={
                                        values.waterEfficiencyByNSW
                                      }
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          "WaterEfficiencyByNSW"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {!IsSigned ? (
                              <div
                                className="agreementLabel mb-3 mt-4"
                                style={{ paddingLeft: "0px" }}
                              >{`Smoke Alarms`}</div>
                            ) : null}
                            <div className="pddpn-group">
                              {!IsSigned ? (
                                <div>
                                  <div
                                    className=""
                                    style={{ display: "flex", width: "100%" }}
                                  >
                                    <div
                                      className="mr-5 s1"
                                      style={{ width: "100%" }}
                                    >
                                      The Principal warrants that the Premises
                                      comply with the Environmental Planning and
                                      Assessment Amendment (Smoke Alarms)
                                      Regulation 2006 (NSW)
                                    </div>
                                    <CustomCheckbox
                                      name="principalWarrantsSmokeAlarm"
                                      error={errors.principalWarrantsSmokeAlarm}
                                      touched={
                                        touched.principalWarrantsSmokeAlarm
                                      }
                                      defaultChecked={
                                        values.principalWarrantsSmokeAlarm
                                      }
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          "PrincipalWarrantsSmokeAlarm"
                                        )
                                      }
                                    />
                                    <CustomModal
                                      title="Remarks"
                                      width={500}
                                      closable={closeDescription}
                                      visible={openDescription}
                                      onCancel={closeDescription}
                                      modalData={
                                        <div
                                          className=""
                                          style={{
                                            marginTop: "30px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <TextArea
                                            colVal={12}
                                            label="Description"
                                            value={descriptions}
                                            onChange={handleDescription}
                                          />
                                        </div>
                                      }
                                      footer={[
                                        <div
                                          key="footer"
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <button
                                            style={{ marginRight: "20px" }}
                                            className="pdb-save-changes mt-2"
                                            onClick={handleDescriptions}
                                          >
                                            Submit
                                          </button>
                                        </div>,
                                      ]}
                                    />
                                  </div>
                                </div>
                              ) : null}
                              {!IsSigned ? (
                                <div>
                                  <div>
                                    <div className=" agreementLabel mb-3 mt-4">{`Repairs and Maintenance`}</div>
                                    <div>
                                      <div className="s1">
                                        {textWithBullet}The Agent will be
                                        indemnified from any claims or actions,
                                        suits, legal costs or demands taken
                                        against the Principal(s) named in this
                                        agreement and the Agent will not be
                                        liable for any claims made against the
                                        Principal(s) due to any actions,
                                        omissions or statements made or not made
                                        by the Principal(s).
                                      </div>
                                      <div className="s1 mt-3">
                                        {textWithBullet}The Principal
                                        acknowledges that the Agent is not a
                                        Financial and Investment Advisor and any
                                        financial advice would be general advice
                                        and the Principal should seek their own
                                        financial advice.
                                      </div>
                                      <div className="s1 mt-3">
                                        {textWithBullet}The Principal
                                        acknowledges that the Property Stock and
                                        Business Agents Act requires the Agent
                                        to disclose all Material Facts relating
                                        to the property throughout the
                                        transaction and warrants that all
                                        materials facts have been disclosed to
                                        the best of their knowledge.
                                      </div>
                                      <div className="s1 mt-3">
                                        {textWithBullet}The Principal
                                        acknowledges that at all times the
                                        Principal has sole control of the
                                        property and that the Agent acts under
                                        the instructions of the Principal during
                                        the sale of the property. The Principal
                                        is responsible for the Work Health and
                                        Safety requirements of the property.
                                      </div>
                                      <div className="s1 mt-3">
                                        {textWithBullet}The Principal
                                        acknowledges that by signing this
                                        agreement the Principal is denied the
                                        right to lease the Property without
                                        paying a fee
                                      </div>
                                    </div>
                                    <div className=" agreementLabel mb-3 mt-4">{`Privacy Statement`}</div>
                                    <div>
                                      <div>
                                        <div
                                          className="s1"
                                          style={{ fontWeight: "600" }}
                                        >
                                          All information collected by the Agent
                                          is done so with respect of the Privacy
                                          Act 1998 and the Privacy Principals.
                                        </div>
                                        <div className="s1 mt-3">
                                          {textWithBullet}Information collected
                                          is necessary for one or more of the
                                          activities associated with this
                                          agreement.
                                        </div>
                                        <div className="s1 mt-3">
                                          {textWithBullet}The Agent will not use
                                          or disclose your personal information
                                          for another purpose, unless it would
                                          be reasonable to expect such
                                          disclosure is necessary for the
                                          completion of activities associated
                                          with this agreement.
                                        </div>
                                        <div className="s1 mt-3">
                                          {textWithBullet}The Agent will take
                                          all reasonable steps to ensure that
                                          the personal information collected,
                                          used or disclosed is accurate,
                                          complete and up-to-date.
                                        </div>
                                        <div className="s1 mt-3">
                                          {textWithBullet}The Agent will take
                                          reasonable steps to protect the
                                          personal information it holds from
                                          misuse and loss and from unauthorised
                                          access, modification or disclosure and
                                          also take reasonable steps to destroy
                                          or permanently de-identify personal
                                          information it no longer needs.
                                        </div>
                                      </div>
                                    </div>
                                    {isRefMatched || utype === "3" ? (
                                      <div>
                                        <div className=" agreementLabel mb-3 mt-4">{`Signature of Principal(s)`}</div>
                                        <div>
                                          <div
                                            className="mt-2"
                                            style={{
                                              padding: "0px 30px",
                                              display: "flex",
                                            }}
                                          >
                                            <div
                                              className="card"
                                              style={{ display: "flex" }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    padding:
                                                      "10px 10px 0px 20px",
                                                  }}
                                                  className="s1"
                                                  htmlFor="name"
                                                >
                                                  Signature{" "}
                                                </div>
                                                <DatePicker
                                                  id="SignedDate"
                                                  format="MM/DD/YYYY" // Display and output format
                                                  value={date ? moment(date, "MM/DD/YYYY") : null} // Set the value for DatePicker
                                                  onChange={handleChangeDate}
                                                  placeholder="mm/dd/yyyy"
                                                />
                                              </div>

                                              <div>
                                                <TabView
                                                  activeIndex={activeIndex}
                                                  onTabChange={(e) =>
                                                    setActiveIndex(e.index)
                                                  }
                                                >
                                                  <TabPanel header="Draw">
                                                    <div className="tc-sign ">
                                                      <div className="sign-tc">
                                                        <SignatureCanvas
                                                          penColor="black"
                                                          ref={signatureRef}
                                                          canvasProps={{
                                                            width: "300",
                                                            height: 75,
                                                            className:
                                                              "signature-canvas",
                                                          }}
                                                        />
                                                        {/* <div className="sign-option">
                                              
                                              <div
                                                onClick={getSignatureImage}
                                                className="tc-b2"
                                              >
                                                Confirm
                                              </div>
                                            </div> */}
                                                      </div>
                                                      <div
                                                        onClick={clearSignature}
                                                        className="tc-b3"
                                                      >
                                                        Clear
                                                      </div>
                                                    </div>
                                                  </TabPanel>
                                                  <TabPanel header="Text">
                                                    <div className="tc-sign ">
                                                      <TextArea
                                                        colVal={12}
                                                        label={"Signature"}
                                                        value={signatureText}
                                                        onChange={
                                                          handleSignature
                                                        }
                                                      />
                                                      <div
                                                        onClick={clearSignature}
                                                        className="tc-b3 ml-3"
                                                      >
                                                        Clear
                                                      </div>
                                                    </div>
                                                  </TabPanel>
                                                </TabView>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              ) : null}
                              {IsSigned ? (
                                <div className="signTxt df-all">
                                  <div>Thank you</div>
                                  <div>your document has been signed</div>
                                </div>
                              ) : null}
                              <div className="property-details-buttons">
                                {!IsSigned ? (
                                  <>

                                    <button
                                      className="pdb-save-changes"
                                      type="submit"
                                    >
                                      {"Submit"}
                                    </button>
                                  </>
                                ) : null}

                                {IsSigned ? (
                                  <button
                                    className="pdb-cancel"
                                    type="button"
                                    onClick={handleAgreementDownload}
                                  >
                                    {"Download Agreement"}
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgreementViewPage;
