import React, { useState } from 'react';
import { Modal, Image, Button } from 'antd';
import './ImageGalleryModal.css';
import { FilePath } from "../../components/CommonSection/apiresources";

const ImageGalleryModal = ({ images ,filePath}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const downloadImage = async (url) => {
    try {
      const response = await fetch(url, {
        mode: 'cors',
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.statusText}`);
      }

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `image-${currentImageIndex + 1}.jpg`; // Set a default filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  };

  return (
    <div>
      {/* Thumbnail Gallery */}
      <div className="thumbnail-gallery">
        {images.slice(0, 4).map((img, index) => (
          <img
            key={index}
            src={filePath ? `${FilePath}${img}` : img}
            alt={`Property ${index + 1}`}
            onClick={() => openModal(index)}
            className="thumbnail-image"
            style={{ width: '100px', height: '100px', cursor: 'pointer' }}
          />
        ))}
        {images.length > 4 && (
          <div className="see-all-thumbnail" onClick={() => openModal(0)}>
            See all images →
          </div>
        )}
      </div>

      {/* Modal for Enlarged View */}
      <Modal
        visible={isModalOpen}
        onCancel={closeModal}
        footer={null}
        width={600}
      >
        <div className="modal-content">
          {/* Main Image with Ant Design Image Component */}
          <div className="main-image-container">
            <Image
              src={filePath ? `${FilePath}${images[currentImageIndex]}`  : images[currentImageIndex]}
              alt={`Property Large ${currentImageIndex + 1}`}
              className="main-image"
              style={{ maxWidth: '100%', maxHeight: '400px' }}
            />
          </div>

          {/* Download Button */}
          <div style={{ textAlign: 'center', margin: '10px 0' }}>
            <Button
              type="primary"
              onClick={() => downloadImage(images[currentImageIndex])}
            >
              Download Image
            </Button>
          </div>

          {/* Thumbnails below main image */}
          <div className="modal-thumbnail-gallery">
            {images.map((img, index) => (
              <img
                width={100}
                height={100}
                key={index}
                src={filePath ? `${FilePath}${img}` : img}
                alt={`Thumbnail ${index + 1}`}
                onClick={() => setCurrentImageIndex(index)}
                className={`modal-thumbnail ${
                  index === currentImageIndex ? 'active-thumbnail' : ''
                }`}
              />
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ImageGalleryModal;
