import React, { useCallback } from "react";
import { useEffect, useState, useRef } from "react";
import "./profile.css";
import PlacesAutocomplete, {geocodeByPlaceId,geocodeByAddress,getLatLng} from "react-places-autocomplete";
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";
import { useFormik } from "formik";
import {
  apiUrlowner,
  BucketName,
  Region,
  AccessKey,
  Keyid,
  apiUrlamo,
  GetPendingProfiletenant,
  getProfileDetails,
  searchAreaOfOperation,
  addCompanyProfileDetails,
  ABNLookUpbaseURL,
  ABNLookUpGuid,
  LicenseVerifyUrl,
  LicenseApiKey,
  LicenceNumberBaseKey,
  LicenseTokenUrl,
} from "../../CommonSection/apiresources";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import { AutoComplete } from "primereact/autocomplete";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import {
  getDefaultDateValue,
  isNullOrEmpty,
  parseToDate,
} from "../../CommonSection/Utility/ComponentFunction";
import DatePicker from "react-datepicker";
import { AgencyProfileCompanyDetailsSchema } from "../../CommonSection/Schema/schema";

const initialValues = {
  compnayName: "",
  companyRegNumber: "",
  tradingName: "",
  companyKey: "",
  acnNumber: "",
  abnNum: "",
  country: "",
  services: "",
  areaOfOperation: "",
  description: "",
  corporateLicenseNumber: "",
  corporateLicenseNumberExpiry: "",
  tradingRegion: "",
  director: "",
  companyRegNumberUnitNo: "",
  companyRegNumberStreetNumber: "",
  companyRegNumberStreetName: "",
  companyRegNumberSuburb: "",
  companyRegNumberPostCode: "",
  companyRegNumberState: "",
  isGstRegistered: false,
};

const ComanyDetailsProfile = ({ handleToastMessage, handleCurrentPage }) => {
  const { showSpinner, hideSpinner } = useSpinnerContext();

  const ToastRef = useRef();

  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyUniqueID, setAgencyUniqueId] = useState("");

  const [licenseToken, setLicenseToken] = useState(null);

  const [selectedCountries, setSelectedCountries] = useState(null);
  const [filteredCountries, setFilteredCountries] = useState(null);

  const [isVisible, setIsVisible] = useState(false);

  const search = (event) => {
    setTimeout(() => {
      const apiUrl = searchAreaOfOperation;
      if (event.query.toLowerCase()) {
        const formData = {
          Search: event.query.toLowerCase(),
        };
        makeRequestWithSpinner(
          showSpinner,
          hideSpinner,
          searchAreaOfOperation,
          "post",
          formData
        ).then((returnData) => {         
          setFilteredCountries(returnData.list.map((obj) => obj.name));
        });
      }
    }, 250);
  };

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyUniqueId(parsedData.agencyUniqueID);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };

    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userUId !== "") {
      GetAgencyDetails();
    }
  }, [userUId]);

  const GetAgencyDetails = () => {
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      `${getProfileDetails}?agencyUID=${agencyUniqueID}`,
      "get"
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {         
          let agencyDetails = returnData.object;
          setValues({ ...values, ...agencyDetails });
          if (agencyDetails.areaOfOperation != null) {
            setSelectedCountries(agencyDetails.areaOfOperation.split(";"));
          }
        } else {
          ToastRef.current.notify(["error", response.data.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error]);
      });
  };

  const handleABNLoopup = (event) => {
    const abnNumber = event.target.value;
    handleBlur(event);
    if (!isNullOrEmpty(abnNumber)) {
      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        `${ABNLookUpbaseURL}/AbnDetails.aspx?abn=${abnNumber}&guid=${ABNLookUpGuid}`,
        "get"
      ).then((returnData) => {        
        const jsonString = returnData.match(/\{.*\}/)[0];
        const parsedData = JSON.parse(
          jsonString.slice(jsonString.indexOf("{"))
        );
        if (!isNullOrEmpty(parsedData.Abn)) {
          handleToastMessage(["success", parsedData.EntityName]);
          setFieldError("abnNum", "");
          setFieldValue("compnayName", parsedData.EntityName);
          setFieldValue("tradingName", parsedData.EntityName);
          setFieldValue("acnNumber", parsedData.Acn);
          setFieldValue("tradingRegion", parsedData.AddressState);
          if (parsedData.Gst != null) {          
            setFieldValue("isGstRegistered", true);
          }
        } else {
          setFieldError("abnNum", "Invalid ABN number");
          setFieldValue("compnayName", "");
          setFieldValue("tradingName", "");
          setFieldValue("acnNumber", "");
          setFieldValue("tradingRegion", "");
          setFieldValue("isGstRegistered", false);
          ToastRef.current.notify(["error", "Invalid ABN "]);
        }
      });
    }
  };

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: AgencyProfileCompanyDetailsSchema,
    onSubmit: (values, action) => {     
      values.areaOfOperation = selectedCountries.join(";");
      submitCompanyDetails();
    },
  });

  const submitCompanyDetails = () => {
    debugger;
    const formData = {
      AgencyUID: agencyUniqueID,

      CompanyName: values.compnayName,

      CompanyKey: values.companyKey,

      CompanyAddress: values.companyRegNumber,

      Country: values.country,

      AcnNumber: values.acnNumber,

      AbnNum: values.abnNum,

      TradingRegion: values.tradingRegion,

      Director: values.director,

      Services: values.services,

      Description: values.description,

      CorporateLicenseNumber: values.corporateLicenseNumber,

      CorporateLicenseNumberExpiry: values.corporateLicenseNumberExpiry,

      AreaOfOperation: values.areaOfOperation,

      TradingName: values.tradingName,

      CompanyRegNumberPostCode: values.companyRegNumberPostCode,

      CompanyRegNumberState: values.companyRegNumberState,

      CompanyRegNumberSuburb: values.companyRegNumberSuburb,

      CompanyRegNumberStreetName: values.companyRegNumberStreetName,

      CompanyRegNumberUnitNo: values.companyRegNumberUnitNo,

      CompanyRegNumberStreetNumber: values.companyRegNumberStreetNumber,
    };

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      addCompanyProfileDetails,
      "post",
      formData
    )
      .then((returnData) => {      
        if (returnData.status == ActionStatus.Successfull) {
          handleToastMessage(["success", returnData.message]);
          handleCurrentPage("Branding");
        } else {
          handleToastMessage(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleToastMessage(["error", returnData.message]);
        console.error("Error:", error);
      });
  };

  const onFrameContainerClick = useCallback((isVisible) => {
    setIsVisible(isVisible);
  }, []);

  const handlepressSelect = async (addrs) => {
    handleChange({
      target: { name: "companyRegNumber", value: addrs },
    });
    try {
      const results = await geocodeByAddress(addrs);
      const details = results[0];
      const fullFormattedAddress = details.formatted_address;

      setFieldValue("companyRegNumber", fullFormattedAddress);
      const { address_components } = details;

      const address = {};
      address_components.forEach((component) => {
        const { types, long_name } = component;
        if (types.includes("subpremise")) {
          address.companyRegNumberUnitNo = long_name;
        }
        if (types.includes("street_number")) {
          address.companyRegNumberStreetNumber = long_name;
        } else if (types.includes("route")) {
          address.companyRegNumberStreetName = long_name;
        } else if (types.includes("locality")) {
          address.companyRegNumberSuburb = long_name;
        } else if (types.includes("postal_code")) {
          address.companyRegNumberPostCode = long_name;
        } else if (types.includes("administrative_area_level_1")) {
          address.companyRegNumberState = long_name;
        }
        setFieldValue("companyRegNumberUnitNo", address.companyRegNumberUnitNo);
        setFieldValue(
          "companyRegNumberStreetNumber",
          address.companyRegNumberStreetNumber
        );
        setFieldValue(
          "companyRegNumberStreetName",
          address.companyRegNumberStreetName
        );
        setFieldValue("companyRegNumberSuburb", address.companyRegNumberSuburb);
        setFieldValue(
          "companyRegNumberPostCode",
          address.companyRegNumberPostCode
        );
        setFieldValue("companyRegNumberState", address.companyRegNumberState);
      });
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleLicenseNum = async (event) => {
    let licenseNumberValue = event.target.value;
    let bearerToken = licenseToken;
    if (bearerToken == null) {
      await makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        LicenseTokenUrl,
        "get",
        null,
        {
          Authorization: LicenceNumberBaseKey,
        }
      ).then((returnData) => {
        if (returnData.access_token != null) {
          bearerToken = returnData.access_token;
          setLicenseToken(bearerToken);
        } else {
          handleToastMessage(["error", "Authentication Failed"]);
        }
      });
    }

    if (bearerToken != null) {
      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        `${LicenseVerifyUrl}?licenceNumber=${licenseNumberValue}`,
        "get",
        null,
        {
          Authorization: `Bearer ${bearerToken}`,
          apiKey: LicenseApiKey,
        }
      ).then((returnData) => {
        if (returnData[0] != null) {
          setFieldValue(
            "corporateLicenseNumberExpiry",
            parseToDate(returnData[0].expiryDate)
          );
        } else {
          handleToastMessage(["error", "Invalid License Number"]);
        }
      });
    }
  };

  return (
    <div>
      <form action="none" onSubmit={handleSubmit} className="details-form">
        <div className="df-all">
          <div className="all-input" style={{display:'initial'}}>
            <div className="cd-abnNumber col-lg-5 col-sm-12">
              <label htmlFor="fname" className="s1">
                ABN Number<span style={{color:'red'}}>*</span>
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control tenant-date"
                  placeholder="ABN Number"
                  value={values.abnNum}
                  onChange={handleChange}
                  onBlur={handleABNLoopup}
                  name="abnNum"
                  aria-describedby="basic-addon1"
                />
              </div>
              {errors.abnNum && touched.abnNum ? (
                <p className="form-error">{errors.abnNum}</p>
              ) : null}

              <label htmlFor="fname" className="s1">
                Company Name<span style={{color:'red'}}>*</span>
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control tenant-date"
                  placeholder="Company Name"
                  value={values.compnayName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="compnayName"
                  aria-describedby="basic-addon1"
                />
              </div>
              {errors.compnayName && touched.compnayName ? (
                <p className="form-error">{errors.compnayName}</p>
              ) : null}

              <label htmlFor="tradingname" className="s1">
                Trading Name<span style={{color:'red'}}>*</span>
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control tenant-date"
                  placeholder="Trading Name"
                  value={values.tradingName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="tradingName"
                  aria-describedby="basic-addon1"
                />
              </div>
              {errors.tradingName && touched.tradingName ? (
                <p className="form-error">{errors.tradingName}</p>
              ) : null}

              <label htmlFor="acnNumber" className="s1">
                ACN Number<span style={{color:'red'}}>*</span>
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  name="acnNumber"
                  className="form-control tenant-date"
                  placeholder="ACN Number"
                  value={values.acnNumber}
                  onChange={handleChange}
                  aria-describedby="basic-addon1"
                />
              </div>
              {errors.acnNumber && touched.acnNumber ? (
                <p className="form-error">{errors.acnNumber}</p>
              ) : null}

              <label htmlFor="trading state" className="s1">
                Trade State<span style={{color:'red'}}>*</span>
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control tenant-date"
                  placeholder=""
                  value={values.tradingRegion}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="tradingRegion"
                  aria-describedby="basic-addon1"
                />
              </div>
              {errors.tradingstate && touched.tradingstate ? (
                <p className="form-error">{errors.tradingstate}</p>
              ) : null}
              <label className="ppf-label s1">GST Registered<span style={{color:'red'}}>*</span></label>
              <div className="ppf-radio-section">
                <label htmlFor="radio 10 ">
                  <input
                    type="radio"
                    id="radio"
                    name="pdgst"
                    value={true}
                    checked={values.isGstRegistered}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  &nbsp; &nbsp;Yes&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp; &nbsp;
                </label>
                <label htmlFor="radio">
                  <input
                    id="radio"
                    type="radio"
                    name="isGstRegistered"
                    value={false}
                    checked={!values.isGstRegistered}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  &nbsp;&nbsp;No
                </label>
              </div>
              {errors.isGstRegistered && touched.isGstRegistered ? (
                <p className="form-error">{errors.isGstRegistered}</p>
              ) : null}
              <label htmlFor="lnumber" className="s1">
                Company Profile Description<span style={{color:'red'}}>*</span>
              </label>
              <div className="input-group mb-3">
                <ReactQuill
                  className="w-100 d-flex flex-column"
                  theme="snow"
                  value={values.description}
                  onChange={(val) => setFieldValue("description", val)}
                />
              </div>
              {errors.description && touched.description ? (
                <p className="form-error">{errors.description}</p>
              ) : null}
            </div>

            <div className="cd-companykey col-lg-5 col-sm-12">
              <div className="company-address">
                <label htmlFor="current-address">Current Address<span style={{color:'red'}}>*</span></label>
                <div className="input-group mb-3">
                  <PlacesAutocomplete
                    className="w-100"
                    value={values.companyRegNumber}
                    onChange={(address) =>
                      handleChange({
                        target: { name: "companyRegNumber", value: address },
                      })
                    }
                    onSelect={handlepressSelect}
                    searchOptions={{
                      types: ["geocode"], // Restrict to addresses
                      componentRestrictions: { country: "AU" }, // Restrict to Australia
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="company-address-input">
                        <input
                          {...getInputProps({
                            placeholder: "Address",
                            className: "input w-100",
                          })}
                        />
                        <div className="dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "input"
                              : "input";

                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  {errors.companyRegNumber && touched.companyRegNumber ? (
                    <p className="form-error">{errors.companyRegNumber}</p>
                  ) : null}
                </div>
              </div>
              <div
                className="label-wrapper"
                onClick={() => onFrameContainerClick(!isVisible)}
              >
                <div className="label1">Details</div>
              </div>
              <div className="subaddress">
                {isVisible && (
                  <div className="input-dropdown-parent">
                    <div className="input-dropdown1">
                      <div className="input-with-label">
                        <input
                          type="text"
                          className="input"
                          placeholder="Unit Number"
                          id="Unit"
                          name="companyRegNumberUnitNo"
                          value={values.companyRegNumberUnitNo}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="hint-text" />
                    </div>
                    <div className="input-dropdown1">
                      <div className="input-with-label">
                        <input
                          type="text"
                          className="input"
                          placeholder="Street Number"
                          id="StreetNumber"
                          name="companyRegNumberStreetNumber"
                          value={values.companyRegNumberStreetNumber}
                          onChange={handleChange}
                        />
                      </div>
                      {errors.companyRegNumberStreetNumber &&
                      touched.companyRegNumberStreetNumber ? (
                        <p className="form-error">
                          {errors.companyRegNumberStreetNumber}
                        </p>
                      ) : null}

                      <div className="hint-text" />
                    </div>
                    <div className="input-dropdown1">
                      <div className="input-with-label">
                        <input
                          type="text"
                          className="input"
                          placeholder="Street Name"
                          id="StreetName"
                          name="streecompanyRegNumberStreetNameName"
                          value={values.companyRegNumberStreetName}
                          onChange={handleChange}
                        />
                      </div>
                      {errors.companyRegNumberStreetName &&
                      touched.companyRegNumberStreetName ? (
                        <p className="form-error">
                          {errors.companyRegNumberStreetName}
                        </p>
                      ) : null}
                      <div className="hint-text" />
                    </div>
                    <div className="input-dropdown1">
                      <div className="input-with-label">
                        <input
                          type="text"
                          className="input"
                          placeholder="Suburb"
                          id="companyRegNumberSuburb"
                          name="companyRegNumberSuburb"
                          value={values.companyRegNumberSuburb}
                          onChange={handleChange}
                        />
                      </div>
                      {errors.companyRegNumberSuburb &&
                      touched.companyRegNumberSuburb ? (
                        <p className="form-error">
                          {errors.companyRegNumberSuburb}
                        </p>
                      ) : null}
                      <div className="hint-text" />
                    </div>
                    <div className="input-dropdown1">
                      <div className="input-with-label">
                        <input
                          type="text"
                          className="input"
                          placeholder="Postcode"
                          id="companyRegNumberPostCode"
                          name="companyRegNumberPostCode"
                          value={values.companyRegNumberPostCode}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {errors.companyRegNumberPostCode &&
                    touched.companyRegNumberPostCode ? (
                      <p className="form-error">
                        {errors.companyRegNumberPostCode}
                      </p>
                    ) : null}
                    <div className="input-dropdown1">
                      <div className="input-with-label">
                        <input
                          type="text"
                          className="input"
                          placeholder="State"
                          id="State"
                          name="companyRegNumberState"
                          value={values.companyRegNumberState}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {errors.companyRegNumberState &&
                    touched.companyRegNumberState ? (
                      <p className="form-error">
                        {errors.companyRegNumberState}
                      </p>
                    ) : null}
                  </div>
                )}
              </div>
              <label htmlFor="lnumber" className="s1">
                Principal/Director<span style={{color:'red'}}>*</span>
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control tenant-date"
                  placeholder="Simmi Wadhwa"
                  value={values.director}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="director"
                  aria-describedby="basic-addon1"
                />
              </div>
              {errors.prdr && touched.prdr ? (
                <p className="form-error">{errors.director}</p>
              ) : null}

              <label htmlFor="lnumber" className="s1">
                Service
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control tenant-date"
                  placeholder=""
                  value={values.services}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="services"
                  aria-describedby="basic-addon1"
                />
              </div>
              {errors.services && touched.services ? (
                <p className="form-error">{errors.services}</p>
              ) : null}
              <label htmlFor="lnumber" className="s1">
                Corporate License Number<span style={{color:'red'}}>*</span>
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control tenant-date"
                  placeholder=""
                  value={values.corporateLicenseNumber}
                  onChange={handleChange}
                  onBlur={handleLicenseNum}
                  name="corporateLicenseNumber"
                  aria-describedby="basic-addon1"
                />
              </div>
              {errors.corporateLicenseNumber &&
              touched.corporateLicenseNumber ? (
                <p className="form-error">{errors.corporateLicenseNumber}</p>
              ) : null}
              <label htmlFor="fname" className="s1">
                Expiry Date
              </label>
              <div className="input-group mb-3">
                <DatePicker
                  className="form-control"
                  selected={getDefaultDateValue(
                    values.corporateLicenseNumberExpiry
                  )}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) =>
                    setFieldValue("corporateLicenseNumberExpiry", date)
                  }
                />
              </div>
              {errors.corporateLicenseNumberExpiry &&
              touched.corporateLicenseNumberExpiry ? (
                <p className="form-error">
                  {errors.corporateLicenseNumberExpiry}
                </p>
              ) : null}
              <label htmlFor="lname" className="s1">
                Company Key
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control tenant-date"
                  placeholder="Company Key"
                  value={values.companyKey}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="companyKey"
                  aria-describedby="basic-addon1"
                />
              </div>
              {errors.companyKey && touched.companyKey ? (
                <p className="form-error">{errors.companyKey}</p>
              ) : null}
              <label htmlFor="lnumber" className="s1">
                Area of Operation
              </label>
              <div className="">
                <AutoComplete
                  multiple
                  value={selectedCountries}
                  suggestions={filteredCountries}
                  completeMethod={search}
                  onChange={(e) => setSelectedCountries(e.value)}
                  className="w-100"
                />
              </div>
              {errors.areaOfOperation && touched.areaOfOperation ? (
                <p className="form-error">{errors.areaOfOperation}</p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="property-details-buttons">
          <button
            className="pdb-cancel"
            type="reset"
            onClick={() =>
              handleToastMessage(["warn", "changes are cancelled"])
            }
          >
            Cancel
          </button>
          <button className="pdb-save-changes" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default ComanyDetailsProfile;
