import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {FaChevronDown,FaChevronUp,FaBars} from "react-icons/fa";
import "./sidebar.css";
import { currentProfile } from "../../components/CommonSection/Cookie/Session";
import { getsidebarforuser,readnotification} from "../../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../Utilities/client";
import { useSpinnerContext } from "../../Utilities/SpinnerContext";
import { ActionStatus } from "../../Utilities/enum";
import { useSidebarContext } from "../../Utilities/sidebarcontext";

const Sidebar = () => {
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const location = useLocation();
  const currentLogin = currentProfile.get();
  const { sideBarData, getLoginDataforSideBar  } = useSidebarContext();
  const [agentId, setAgentId] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [userId, setUserId] = useState("")
  const [collapsedSections, setCollapsedSections] = useState({
    general: false,
    activity: false,
    support: false,
  });
  const [storedUserData, setStoredUserData] = useState(
    sessionStorage.getItem("@UserData")
  );

  useEffect(() => {
    const retrieveData = () => {
      try {
        const userData = sessionStorage.getItem("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setAgentId(parsedData.agentId);
          setStoredUserData(userData); // Sync state with sessionStorage
          getLoginDataforSideBar(parsedData.userId,currentLogin)
          setUserId(parsedData.userId)
        } else {
          setStoredUserData(null); // Clear state if no data
        }
      } catch (error) {
        console.error("Error retrieving data from sessionStorage:", error);
      }
    };
    retrieveData();
  }, [ ]); // Run once on component mount



  const bottomItems = [
    {
      name: "Settings",
      icon: "fa fa-cog",
      path: currentLogin === 3 ? `/agent/profile?value=${agentId}` : "/agency/profile", // Fixed path assignment
    },
    { name: "Log out", icon: "fa fa-sign-out-alt", path: "/signin" },
  ];

  const toggleSectionCollapse = (key) => {
    setCollapsedSections((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  //let sideBarData = toggleSideBar ? menuItemsTenant : toggleSideBarTrade ? menuItemsTradesman : toggleSideBarAgent ? menuItemsAgent : menuItems;
  return (
    <>
      <button className="menu-toggle" onClick={toggleSidebar}>
        <FaBars />
      </button>
      <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
        <div className="section-logo">
          <img
            src="/assets/images/mainLogo.png"
            alt="logo"
            className="pointer"
            style={{ width: "80px", filter: "brightness(0) invert(1)" }}
          />
        </div>
        <div className="sidebar-container">
          {sideBarData.map((section, index) => (
            <div key={index} className="menu-section">
              <div
                className="section-header"
                onClick={() => toggleSectionCollapse(section.key)}
              >
                <h6>{section.section}</h6>
                <button>
                  {collapsedSections[section.key] ? (
                    <FaChevronDown />
                  ) : (
                    <FaChevronUp />
                  )}
                </button>
              </div>
              {!collapsedSections[section.key] && (
                <ul className="menu">
                  {section.items.map((item, idx) => (
                    <li key={idx}>
                      <Link
                        to={item.path}
                        className={location.pathname === item.path ? "active" : ""}
                      >
                        <i className={item.icon}></i>
                        <span>{item.name}</span>
                        {item.hasNotification && <span className="notification-indicator"></span>}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
        <div className="sidebar-bottom">
          <ul className="menu">
            {bottomItems.map((item, idx) => (
              <li
                key={idx}
                className={location.pathname === item.path ? "active" : ""}
              >
                <Link to={item.path}>
                <i className={item.icon}></i>
                <span>{item.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
