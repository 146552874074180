import React, { useState, useRef, useEffect } from "react";
import ToastAlert from "../../../CommonSection/ToastAlert/ToastAlert";
import "./viewPropertyDetails.css";
import { Divider } from "primereact/divider";
import {generatePdfReport,generatetenantcheckreport,generatesalesagreementPdfReport,generateTenantAgreementPdfReport} from "../../../CommonSection/apiresources";
import {  DownloadFiletWithSpinner} from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { BiEdit } from "react-icons/bi";
import RatingStar from "../../../../src/input-components/ratingStar";
import { BiBath, BiBed, BiCar } from "react-icons/bi";
import { Table, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import TabsComponent from "../../../../src/input-components/history";
import { isManualFilePath,eAgreementTemplateType } from "../../../../Utilities/enum";

const ViewPropertyDetails = ({
  getDetails,
  handleCurrentPage,
  getDocDetails,
}) => {
  const [activeTab, setActiveTab] = useState("all");
  const ToastRef = useRef();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [activeSection, setActiveSection] = useState("Overview");
  var url = new URL(window.location.href);
  var propertyUId = url.searchParams.get("propertyUID");
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const columns = [
    {
      title: "Agreement Type",
      dataIndex: "sAgreementType",
      key: "sAgreementType",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD/MM/YYYY"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          icon={<DownloadOutlined />}
          onClick={() => handleDownload(record)}
        ></Button>
      ),
    },
  ];

  const handleDownload = (record) => {
    if (record.agreementType === eAgreementTemplateType.ManagementAgreement) {
      if (record.isManuallyAdded) {
        const fileUrl = `${isManualFilePath}${record.agreementFilePath}`;
        window.open(fileUrl, "_blank");
      } else {
        handleAgreementDownload(record);
      }
    } else if (record.agreementType === eAgreementTemplateType.TenantCheck) {
      if (record.isManuallyAdded) {
        const fileUrl = `${isManualFilePath}${record.agreementFilePath}`;
        window.open(fileUrl, "_blank");
      } else {
        handleTenantDownload(record,10);
      }
    }
    else if (record.agreementType === eAgreementTemplateType.OwnerCheck) {
      if (record.isManuallyAdded) {
        const fileUrl = `${isManualFilePath}${record.agreementFilePath}`;
        window.open(fileUrl, "_blank");
      } else {
        handleTenantDownload(record,11);
      }
    } else if (record.agreementType === eAgreementTemplateType.ExclusiveAgencyAgreement) {
      if (record.isManuallyAdded) {
        const fileUrl = `${isManualFilePath}${record.agreementFilePath}`;
        window.open(fileUrl, "_blank");
      } else {
        handleSaleAgreementDownload(record);
      }
    } else if (record.agreementType === eAgreementTemplateType.TenantLeaseAgreement) {
      if (record.isManuallyAdded) {
        const fileUrl = `${isManualFilePath}${record.agreementFilePath}`;
        window.open(fileUrl, "_blank");
      } else {
        handleTenantAgreementDownload(record);
      }
    }
  };

  const handleTenantAgreementDownload = (record) => {
    const formData = {
      PropertyUId: record.propertyUniqueId,
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generateTenantAgreementPdfReport,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData != null) {
          window.open(returnData, "_blank");
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleSaleAgreementDownload = (record) => {
    const formData = {
      PropertyUId: record.propertyUniqueId,
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generatesalesagreementPdfReport,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData != null) {
          window.open(returnData, "_blank");
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleAgreementDownload = (record) => {
    const formData = {
      PropertyUId: record.propertyUniqueId,
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generatePdfReport,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData != null) {
          window.open(returnData, "_blank");
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleTenantDownload = (records,id) => {
    const formData = {
      AgencyId: getDetails.agencyId,
      UserId: records.userId,
      ReportType: id
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generatetenantcheckreport,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData != null) {
          window.open(returnData, "_blank");
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };
  const groupedHistories = getDetails?.updateHistories.reduce((acc, item) => {
    const date = formatDate(item.createdOn);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {});

  const handleProperty = () => {
    handleCurrentPage("Property");
  };

  const handleOwner = () => {
    handleCurrentPage("Ownership");
  };

  const handleTenants = () => {
    handleCurrentPage("Tenants");
  };

  const handleListings = () => {
    handleCurrentPage("Listing");
  };

  const renderOwners = () => {
    if (
      !getDetails ||
      !getDetails.addUpdatesPropertyOwnersModel ||
      !getDetails.addUpdatesPropertyOwnersModel?.contactlistModelView
    ) {
      return null;
    }

    const sortedContacts =
      getDetails.contactlistModel.sort(
        (a, b) => {
          return b.isPrimary - a.isPrimary;
        }
      );

    return sortedContacts.map((contact, index) => {
      const isPrimary = contact.isPrimary ? "Primary owner" : "Secondary owner";
      return (
        <table key={index} className="property- mb-5" style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td
                className="left-menu-owner"
                style={{ display: "flex" }}
                colSpan="2"
              >
                {isPrimary}
                {contact.isPrimary && (
                  <span className="ml-3" style={{ marginTop: "-5px" }}>
                    <RatingStar totalStars={1} initialRating={1} />
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td className="left-menu-owner">Name</td>
              <td className="left-menu-value">{contact.name}</td>
            </tr>
            <tr>
              <td className="left-menu-owner">Phone</td>
              <td className="left-menu-value">{contact.phone}</td>
            </tr>
            <tr>
              <td className="left-menu-owner">Email</td>
              <td className="left-menu-value">{contact.contactEmail}</td>
            </tr>
            <tr>
              <td className="left-menu-owner">Address</td>
              <td className="left-menu-value">
                {contact.address ? contact.address : "N/A"}
              </td>
            </tr>
          </tbody>
        </table>
      );
    });
  };

  const renderTenent = () => {
    if (!getDetails || !getDetails.tenantDetails) {
      return null;
    }

    const sortedContacts = getDetails.tenantDetails.sort((a, b) => {
      return b.isPrimary - a.isPrimary;
    });

    return sortedContacts.map((contact, index) => {
      const isPrimary = contact.isPrimary
        ? "Primary Tenant"
        : "Secondary Tenant";
      return (
        <table key={index} className="property- mb-5" style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td
                className="left-menu-owner"
                style={{ display: "flex" }}
                colSpan="2"
              >
                {isPrimary}
                {contact.isPrimary && (
                  <span className="ml-3" style={{ marginTop: "-5px" }}>
                    <RatingStar totalStars={1} initialRating={1} />
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td className="left-menu-owner">Name</td>
              <td className="left-menu-value">{contact.getFullName}</td>
            </tr>
            <tr>
              <td className="left-menu-owner">Phone</td>
              <td className="left-menu-value">{contact.tenantMobile}</td>
            </tr>
            <tr>
              <td className="left-menu-owner">Email</td>
              <td className="left-menu-value">{contact.tenantEmail}</td>
            </tr>
          </tbody>
        </table>
      );
    });
  };

  const getTrueProperties = (details) => {
    const trueProps = [];

    for (const [key, value] of Object.entries(details)) {
      if (value === true) {
        // Convert camelCase to human-readable format
        const formattedKey = key
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        trueProps.push(formattedKey);
      }
    }

    return trueProps.join(", ");
  };

  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }
    const [year, month, day] = dateString.split("T")[0].split("-");
    return `${year}-${month}-${day}`;
  }

  const renderListings = () => {
    if (!getDetails || !getDetails.listingDetails) {
      return null;
    }
    const saleListings = getDetails.listingDetails.filter(
      (listing) => listing.propertySaleRental === 1
    );
    const rentListings = getDetails.listingDetails.filter(
      (listing) => listing.propertySaleRental === 2
    );

    const renderListingTable = (listings, rent) => (
      listings.map((listing, index) => (
        <table className="property-details" key={index}>
          <tbody>
            <tr>
              <td className="left-menu-name">Property For</td>
              <td className="left-menu-value">
                {listing.propertySaleRental === 1 ? "Sale" : "Rent"}
              </td>
            </tr>
            <tr>
              <td className="left-menu-name">Listing Status</td>
              <td className="left-menu-value">
                {listing.listingStatus === 1 ? "Active" : "Inactive"}
              </td>
            </tr>
            <tr>
              <td className="left-menu-name">Is Commercial</td>
              <td className="left-menu-value">
                {listing.isCommercial ? "Yes" : "No"}
              </td>
            </tr>
            <tr>
              <td className="left-menu-name">New or Established</td>
              <td className="left-menu-value">
                {listing.isNew === 1 ? "New" : "Established"}
              </td>
            </tr>
            {rent === undefined ? (
              <tr>
                <td className="left-menu-name">Agreement Type</td>
                <td className="left-menu-value">
                  {listing.sAgreementType || "N/A"}
                </td>
              </tr>
            ) : null}
            {rent ? (
              <tr>
                <td className="left-menu-name">Rental Price</td>
                <td className="left-menu-value">
                  {`$${listing.rent}` || "N/A"}
                </td>
              </tr>
            ) : null}
            {rent === undefined ? (
              <tr>
                <td className="left-menu-name">Property Price</td>
                <td className="left-menu-value">
                  {listing.propertyPrice ? `$${listing.propertyPrice}` : "N/A"}
                </td>
              </tr>
            ) : null}
            {rent ? (
              <tr>
                <td className="left-menu-name">Rental Interval</td>
                <td className="left-menu-value">
                  {getDetails.rentTenure === 1 ? "Weekly" : "Monthly"}
                </td>
              </tr>
            ) : null}
            <tr>
              <td className="left-menu-name">Listing Agent</td>
              <td className="left-menu-value">{listing.agentName}</td>
            </tr>
            <tr>
              <td className="left-menu-name">Dual Agent</td>
              <td className="left-menu-value">
                {listing.isDualAgent ? listing.dualAgentName : "No"}
              </td>
            </tr>
            <tr>
              <td className="left-menu-name">Show Text</td>
              <td className="left-menu-value">{listing.showText || "N/A"}</td>
            </tr>
            {rent ? (
              <tr>
                <td className="left-menu-name">Date Available</td>
                <td className="left-menu-value">
                  {listing.dateAvailable
                    ? new Date(listing.dateAvailable).toLocaleDateString()
                    : "N/A"}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      ))
    );

    return (
      <div style={{ display: "flex" }}>
        {saleListings.length > 0 && (
          <div>
            <div className="left-menu-owner">Sale Listing</div>
            {renderListingTable(saleListings)}
          </div>
        )}
        {rentListings.length > 0 && (
          <div>
            <div className="left-menu-owner">Rent Listing</div>
            {renderListingTable(rentListings, "rent")}
          </div>
        )}
      </div>
    );
  };

  const handleMenuChange = (section) => {
    setActiveSection(section);
    if (section === "Property") {
      handleProperty();
    } else if (section === "Ownership") {
      handleOwner();
    } else if (section === "Listing") {
      handleListings();
    } else if (section === "Tenants") {
      handleTenants();
    }
  };

  return (
    <div className="dashboard-agency">
      <div className="container-dbag">
        <ToastAlert ref={ToastRef} />
        <div className="dashboardagency-content">
          <div className="main-content">
            <div style={{ width: "60%" }}>
              <div className="left-content">
                {getDetails && (
                  <div className="mt-3">
                    <div
                      style={{
                        width: "100%",
                        fontWeight: "500",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={handleProperty}
                    >
                      <span className="left-label">
                        {getDetails.getFormatedAddress}
                      </span>
                      <BiEdit
                        style={{ fontSize: "18px", marginRight: "5px" }}
                      />
                    </div>
                    <Divider style={{ fontWeight: "600" }} />

                    <table className="property-details">
                      <tbody>
                        <tr>
                          <td className="left-menu-name">Type</td>
                          <td className="left-menu-value">
                            {getDetails ? getDetails.sPropertyType : ""}
                          </td>
                        </tr>
                        <tr>
                          <td className="left-menu-name">Attributes</td>
                          <td className="left-menu-value">
                            <div className="details-icons">
                              <span className="details-icon">
                                <BiBed />
                                {getDetails.countBedrooms != null
                                  ? getDetails.countBedrooms
                                  : "0"}
                              </span>
                              <span className="details-icon">
                                <BiBath />
                                {getDetails.countBathrooms != null
                                  ? getDetails.countBathrooms
                                  : "0"}
                              </span>
                              <span className="details-icon">
                                <BiCar />
                                {(getDetails.countCarParking ?? 0) +
                                  (getDetails.countGarageSpace ?? 0)}
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="left-menu-name">Assigned To</td>
                          <td className="left-menu-value">
                            {getDetails ? getDetails.agent_Name : ""}
                          </td>
                        </tr>
                        <tr>
                          <td className="left-menu-name">Features</td>
                          <td className="left-menu-value">
                            {getTrueProperties(
                              getDetails.addUpdatePropertyAttributesModel
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                <div className="mt-4">
                  {getDetails  &&
                  getDetails.addUpdatesPropertyOwnersModel?.contactlistModelView
                    .length === 0 ? (
                    <span onClick={handleOwner} className="left-label">
                      + Add Owner
                    </span>
                  ) : null}
                  {getDetails &&
                  getDetails.addUpdatesPropertyOwnersModel?.contactlistModelView
                    .length > 0 ? (
                    <div>
                      <div
                        style={{
                          width: "100%",
                          fontWeight: "500",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        onClick={handleOwner}
                      >
                        <span className="left-label">Owners</span>

                        <BiEdit
                          style={{ fontSize: "18px", marginRight: "5px" }}
                        />
                      </div>
                      <Divider style={{ fontWeight: "600" }} />
                    </div>
                  ) : null}
                  {getDetails &&
                  getDetails.addUpdatesPropertyOwnersModel &&
                  getDetails.addUpdatesPropertyOwnersModel?.contactlistModelView
                    .length > 0 ? (
                    <div>{renderOwners()}</div>
                  ) : null}
                </div>

                <div className="mt-4">
                  {getDetails  &&
                  getDetails?.listingDetails?.length === 0 ? (
                    <span onClick={handleListings} className="left-label">
                      + Add Listing
                    </span>
                  ) : null}
                  {getDetails &&
                  getDetails?.listingDetails?.length > 0 ? (
                    <div>
                      <div
                        style={{
                          width: "100%",
                          fontWeight: "500",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        onClick={handleListings}
                      >
                        <span className="left-label">Listings</span>
                        <BiEdit
                          style={{ fontSize: "18px", marginRight: "5px" }}
                        />
                      </div>
                      <Divider style={{ fontWeight: "600" }} />
                    </div>
                  ) : null}
                  {getDetails &&
                  getDetails?.listingDetails?.length > 0 ? (
                    <div>{renderListings()}</div>
                  ) : null}
                </div>

                <div className="mt-4">
                  {getDetails  &&
                  getDetails.tenantDetails?.length === 0 ? (
                    <span onClick={handleTenants} className="left-label">
                      + Add Tenants
                    </span>
                  ) : null}
                  {getDetails  &&
                  getDetails.tenantDetails?.length > 0 ? (
                    <div>
                      <div
                        style={{
                          width: "100%",
                          fontWeight: "500",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        onClick={handleTenants}
                      >
                        <span className="left-label">Tenants</span>

                        <BiEdit
                          style={{ fontSize: "18px", marginRight: "5px" }}
                        />
                      </div>
                      <Divider style={{ fontWeight: "600" }} />
                    </div>
                  ) : null}
                  {getDetails  &&
                  getDetails.tenantDetails?.length > 0 ? (
                    <div>
                      {renderTenent()}
                      <table className="property-details">
                        <tbody>
                          <tr>
                            <td className="left-menu-name">
                              Tenant Account Folio
                            </td>
                            <td className="left-menu-value">
                              {getDetails &&
                                getDetails.tenantDetails[0]?.tenantFolioDetails
                                  .tenantAccountFolio}
                            </td>
                          </tr>
                          <tr>
                            <td className="left-menu-name">Tenant Checksum</td>
                            <td className="left-menu-value">
                              {getDetails &&
                                getDetails.tenantDetails[0]?.tenantFolioDetails
                                  .tenantChecksum}
                            </td>
                          </tr>
                          <tr>
                            <td className="left-menu-name">
                              Rent Review Frequency
                            </td>
                            <td className="left-menu-value">
                              {getDetails &&
                                getDetails.tenantDetails[0]?.tenantFolioDetails
                                  .rentReviewFrequency}
                            </td>
                          </tr>
                          <tr>
                            <td className="left-menu-name">Rent Include Tax</td>
                            <td className="left-menu-value">
                              {getDetails &&
                              getDetails.tenantDetails[0]?.tenantFolioDetails
                                .rentIncludeTax
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td className="left-menu-name">Rent</td>
                            <td className="left-menu-value">
                              {getDetails &&
                                getDetails.tenantDetails[0]?.tenantFolioDetails
                                  .rent}
                            </td>
                          </tr>
                          <tr>
                            <td className="left-menu-name">Period</td>
                            <td className="left-menu-value">
                              {getDetails &&getDetails.tenantDetails[0]?.tenantFolioDetails.period === 1 ? "Weekly" : "Monthly"}
                            </td>
                          </tr>
                          <tr>
                            <td className="left-menu-name">Agreement Start</td>
                            <td className="left-menu-value">
                              {getDetails &&
                                formatDate(
                                  getDetails.tenantDetails[0]
                                    ?.tenantFolioDetails.agreementStart
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td className="left-menu-name">Agreement End</td>
                            <td className="left-menu-value">
                              {getDetails &&
                                formatDate(
                                  getDetails.tenantDetails[0]
                                    ?.tenantFolioDetails.agreementEnd
                                )}
                            </td>
                          </tr>

                          <tr>
                            <td className="left-menu-name">Abn</td>
                            <td className="left-menu-value">
                              {getDetails &&
                                getDetails.tenantDetails[0]?.tenantFolioDetails
                                  .abn}
                            </td>
                          </tr>
                          <tr>
                            <td className="left-menu-name">Balance</td>
                            <td className="left-menu-value">
                              {getDetails &&
                                getDetails.tenantDetails[0]?.tenantFolioDetails
                                  .balance}
                            </td>
                          </tr>
                          <tr>
                            <td className="left-menu-name">Bond Amount</td>
                            <td className="left-menu-value">
                              {getDetails &&
                                getDetails.tenantDetails[0]?.tenantFolioDetails
                                  .bondAmount}
                            </td>
                          </tr>
                          <tr>
                            <td className="left-menu-name">Bond In-Trust</td>
                            <td className="left-menu-value">
                              {getDetails &&
                                getDetails.tenantDetails[0]?.tenantFolioDetails
                                  .bondInTrust}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </div>

                <div className="mt-4">
                  {getDocDetails !== null && getDocDetails?.length > 0 ? (
                    <div>
                      <div
                        style={{
                          width: "100%",
                          fontWeight: "500",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        onClick={handleTenants}
                      >
                        <span className="left-label">Documents</span>
                        <BiEdit
                          style={{ fontSize: "18px", marginRight: "5px" }}
                        />
                      </div>
                      <Divider style={{ fontWeight: "600" }} />
                    </div>
                  ) : null}
                  {getDocDetails !== null && getDocDetails?.length > 0 ? (
                    <div>
                      <Table
                        dataSource={getDocDetails}
                        columns={columns}
                        rowKey="propertyUniqueId"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <TabsComponent
              activeTab={activeTab}
              handleTabChange={handleTabChange}
              groupedHistories={groupedHistories}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPropertyDetails;
