import React, { useMemo } from "react";
import { useEffect, useState, useRef } from "react";
import debounce from "lodash/debounce";
import axios from "axios";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../input-components/selectBoxNew";
import {
  assignedTo,
  addPropertyListings,
  propertyDetails,
  SearchPropertyForAddListing,
} from "../../../components/CommonSection/apiresources";
import { Dropdown } from "primereact/dropdown";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus, ListingType } from "../../../Utilities/enum";
import {
  Dropdown as AntDropdown,
  Checkbox,
  Tabs,
  Radio,
  Select,
  Spin,
} from "antd";
import CustomModal from "../../input-components/Modal";
import { FormatDate } from "../../../components/CommonSection/Utility/Utility";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import {
  propertyListingRental,
  propertyListingSale,
} from "../../../components/CommonSection/Schema/schema";

const { TabPane } = Tabs;

const initialValues = {
  listingStatus: "",
  propertyAgreementType: "",
  rentTenure: "",
  propertyPrice: "",
  rentPrice: "",
  isCommercial: "",
  isNew: null,
  dualAgent: "",
  leadAgent: "",
  isDualAgent: "",
  isShowPrice: "1",
  showText: "",
  reaUploadId: "",
  displaySales: false,
  displayRental: false,
  propertyFor: "",
  listingId: 0,
  DateAvailable: null,
};

const AddNewPropertyListing = ({ navigate, closeModal }) => {
  const [userId, setUserId] = useState("");
  const [leadAgent, setLeadAgent] = useState(null);
  const [dualAgent, setDualAgent] = useState(null);
  const [dpdata, setDpdata] = useState([]);
  const [getDetails, setGetDetails] = useState(null);

  const [statusSaleData, setStatusSaleData] = useState([
    { label: "Active", value: 1 },
    { label: "Deleted", value: 2 },
    { label: "Off Market", value: 3 },
    { label: "Sold", value: 4 },
    { label: "Under Offer", value: 5 },
  ]);
  const [statusRentData, setStatusRentData] = useState([
    { label: "Active", value: 1 },
    { label: "Deposit Taken", value: 2 },
    { label: "Deleted", value: 3 },
    { label: "Off Market", value: 4 },
    { label: "Leased", value: 5 },
  ]);

  const [agreeData, setAgreeData] = useState([
    { label: "Auction", value: 1 },
    { label: "Exclusive", value: 2 },
    { label: "Multi List", value: 3 },
    { label: "Conjunctional", value: 4 },
    { label: "Open", value: 5 },
    { label: "Sale by Negotiation", value: 6 },
    { label: "Set Sale", value: 7 },
    { label: "Management", value: 8 },
    { label: "Tenant Lease", value: 8 },
  ]);

  const [rentData, setRentData] = useState([
    { label: "Weekly", value: 1 },
    { label: "Monthly", value: 2 },
  ]);

  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [selectedLeadAgents, setSelectedLeadAgents] = useState([]);
  const [isReaUploaded, setIsReaUploaded] = useState(null);
  const [isDomainUploaded, setIsDomainUploaded] = useState(null);
  const [edit, setEdit] = useState(false);
  const [listStatusOptions, setListStatusOptions] = useState(false);
  const [displaySaleFields, setDisplaySaleFields] = useState(false);
  const [displayRentalFields, setDisplayRentalFields] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [reaUplode, setReaUplode] = useState(false);
  const [domainUplode, setDomainUplode] = useState(false);
  const [propertyId, setPropertyId] = useState(null);
  const [AgentId, setAgentId] = useState(null);
  const ToastRef = useRef();
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [displayPropertyListing, setDisplayPropertyListing] = useState(false);
  const [isCommercialData, setIsCommercialData] = useState([
    { label: "Yes", value: 1 },
    { label: "No", value: 2 },
  ]);

  const [isNewData, setIsNewData] = useState([
    { label: "Yes", value: 1 },
    { label: "No", value: 2 },
  ]);

  const [showPriceData, setShowPriceData] = useState([
    { label: "Yes", value: 1 },
    { label: "No", value: 2 },
  ]);
  const searchParams = new URLSearchParams(window.location.search);

  const finalReportToObject = [
    {
      disabled: false,
      group: null,
      selected: false,
      text: "--Select--",
      value: "Select",
    },
  ];

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgentId(parsedData.agentId);
          reportData(parsedData.agencyUniqueID);
          GetApi();
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {}, []);

  const reportData = (agencyUID) => {
    const apiUrl = assignedTo;
    const baseUrl = apiUrl + "?agencyUID=" + agencyUID;
    axios.get(baseUrl).then((response) => {
      console.log(response, "res");
      if (response.data.object) {
        const newData = response.data.object;
        const updatedData = [...newData, ...finalReportToObject];
        setDpdata(updatedData);
      }
    });
  };
  const propertyId1 = searchParams.get("propertyId");

  useEffect(() => {
    debugger;
    const searchParams = new URLSearchParams(window.location.search);
    const propertyId = searchParams.get("propertyId");
    const isRentalListed = searchParams.get("isRentalListed");
    const isSaleListed = searchParams.get("isSaleListed");
    setPropertyId(propertyId);
    if (isRentalListed === "true" && isSaleListed === "true") {
      ToastRef.current.notify(["error", "Both Sale and Rent Added"]);
    } else if (isRentalListed === "true" && isSaleListed === "false") {
      setDisplaySaleFields(true);
      setEdit(true);
      setListStatusOptions(statusSaleData);
      setFieldValue("displaySales", true);
      setFieldValue("propertyFor", 1);
    } else if (isRentalListed === "false" && isSaleListed === "true") {
      setEdit(true);
      setDisplaySaleFields(false);
      setListStatusOptions(statusRentData);
      setFieldValue("displayRental", true);
      setFieldValue("propertyFor", 2);
    } else if (isRentalListed === "false" && isSaleListed === "false") {
      setListStatusOptions(statusSaleData);
      setFieldValue("propertyFor", 1);
      handleDisplaySaleOrRental("sale");
    }
  }, [displayPropertyListing, propertyId1]);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: () =>
      values.propertyFor === ListingType.Rental
        ? propertyListingRental
        : propertyListingSale,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (UploadPortal = false) => {
    values.dualAgent = parseInt(values.dualAgent, 10);
    values.leadAgent = parseInt(values.leadAgent, 10);
    const formData = {
      ListingId: values.listingId ? values.listingId : 0,
      AgentId: AgentId ? AgentId : "",
      PropertyId: propertyId,
      ListingStatus: values.listingStatus,
      PropertyAgreementType: values.propertyAgreementType,
      RentTenure: values.rentTenure,
      PropertyPrice: values.propertyPrice,
      RentPrice: values.rentPrice,
      IsCommercial: values.isCommercial === "1" ? true : false,
      IsNew: values.isNew,
      DualAgent: values.dualAgent,
      IsDualAgent: dualAgent ? true : dualAgent === "Select" ? null : false,
      IsShowPrice: values.isShowPrice === "1" ? true : false,
      ShowText: values.showText,
      ReaUploadId: values.reaUploadId,
      PropertySaleRental: values.propertyFor,
      DateAvailable: values.DateAvailable,
    };
    if (UploadPortal) {
      formData.UploadPortal = true;
      formData.ReaUpload = reaUplode;
      formData.DomainUpload = domainUplode;
    }
    const url = addPropertyListings;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          navigate("/agency/ListAllProperties");
          closeModal();
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });

    console.group("yes");
  };

  const handleLeadAgent = (e) => {
    const selectedLeadAgentValue = e.value;
    setLeadAgent(e.value);
    setFieldValue("leadAgent", e.value);
    setSelectedLeadAgents(selectedLeadAgentValue);
  };

  const updatedReportToWithoutSelectedLead = dpdata.filter(
    (agent) => !selectedLeadAgents.includes(agent.value)
  );

  const handleDualAgent = (e) => {
    setDualAgent(e.value);
    setFieldValue("dualAgent", e.value);
  };

  const GetApi = () => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUId");
    const baseUrl = propertyDetails;
    const apiUrl = `${baseUrl}?propertyID=${propertyUId}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get")
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          setGetDetails(response.object);
        } else {
          ToastRef.current.notify(["warn", response.data.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error]);
      });
  };

  const handleItemClickInPropertyListings = (e) => {
    setOpenUpload(true);
  };

  const handleCancel = () => {
    navigate("/agency/ListAllProperties");
  };

  const onChangeUpload = (type) => {
    if (type === "rea") {
      setReaUplode(!reaUplode);
    } else if (type === "domain") {
      setDomainUplode(!domainUplode);
    }
  };

  const closeUpload = () => {
    setOpenUpload(false);
  };

  const handleReaSubmit = () => {
    handleFinalSubmit(true);
  };

  const handleDisplaySaleOrRental = (value) => {
    if (value === "sale") {
      setEdit(true);
      setDisplaySaleFields(true);
      setDisplayRentalFields(false);
      setFieldValue("propertyFor", 1); // 1 for Sale
    } else if (value === "rent") {
      setEdit(true);
      setDisplaySaleFields(false);
      setDisplayRentalFields(true);
      setFieldValue("propertyFor", 2); // 2 for Rent
    }
  };

  const fetchRef = useRef(0);

  // Debounced fetch function
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      setOptions([]);
      setFetching(true);

      const baseUrl = SearchPropertyForAddListing;
      const data = {
        Search: value,
        RecordsPerPage: 10,
        PageNo: 1,
      };

      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1 && fetchId === fetchRef.current) {
            const transformedData = response.list.map((item) => ({
              value: item.id,
              label: item.name,
              id: item.id,
              isRentalListed: item.isRentalListed,
              isSaleListed: item.isSaleListed,
              propertyUId: item.propertyUId,
            }));
            setOptions(transformedData);
          }
        })
        .finally(() => {
          setFetching(false);
        });
    };

    return debounce(loadOptions, 800);
  }, []);

  const handleSearchProperty = (label, value, listingId) => {
    setSelectedValue(value);
    if (value) {
      console.log(value, "value");
      const queryString = `?propertyId=${value.id}&isRentalListed=${value.isRentalListed}&isSaleListed=${value.isSaleListed}&propertyUId=${value.propertyUId}`;
      window.history.pushState(null, "", queryString);
      if (value.isSaleListed) {
        setDisplaySaleFields(false);
        setDisplayRentalFields(true);
      } else if (value.isRentalListed) {
        setDisplaySaleFields(true);
        setDisplayRentalFields(false);
      }
    }
    setDisplayPropertyListing(true);
  };

  const isRentalListed = searchParams.get("isRentalListed");
  const isSaleListed = searchParams.get("isSaleListed");
  return (
    <div className="dashboard-agency">
      <ToastAlert ref={ToastRef} />
      <CustomModal
        title="Upload property to"
        width={500}
        closable={closeUpload}
        visible={openUpload}
        onCancel={closeUpload}
        modalData={
          <div
            className="row"
            style={{ marginTop: "30px", marginLeft: "10px" }}
          >
            <div className="col-lg-5 col-sm-12">
              <Checkbox
                checked={reaUplode}
                onChange={() => onChangeUpload("rea")}
              >
                REA {isReaUploaded ? "(Update)" : null}
              </Checkbox>
            </div>
            <div className="col-lg-5 col-sm-12">
              <Checkbox
                checked={domainUplode}
                onChange={() => onChangeUpload("domain")}
              >
                Domain {isDomainUploaded ? "(Update)" : null}
              </Checkbox>
            </div>
          </div>
        }
        footer={[
          <div
            key="footer"
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              style={{ marginRight: "20px" }}
              className="pdb-save-changes mt-2"
              onClick={handleReaSubmit}
            >
              Submit
            </button>
          </div>,
        ]}
      />
      <label className="s1">Select Existing Property</label>
      <div style={{ display: "flex" }}>
        <Select
          style={{ height: "40px", width: "100%" }}
          className="landingPageSearchs"
          mode="single"
          showSearch
          placeholder="Search Existing Property"
          labelInValue
          filterOption={false}
          onSearch={debounceFetcher}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          options={options}
          value={selectedValue}
          onChange={(label, value, listingId) =>
            handleSearchProperty(label, value, listingId)
          }
          allowClear
        />
      </div>
      {displayPropertyListing ? (
        <div className="dashboardagency-content">
          <form
            onSubmit={handleSubmit}
            className="details-form"
            style={{ display: "initial", width: "100%" }}
          >
            {isRentalListed === "false" && isSaleListed === "false" ? (
              <div className="mt-3">
                <Radio.Group
                  onChange={(e) => handleDisplaySaleOrRental(e.target.value)}
                  value={values.propertyFor === 1 ? "sale" : "rent"}
                >
                  <Radio value="sale">Sales</Radio>
                  <Radio value="rent">Rent</Radio>
                </Radio.Group>
              </div>
            ) : null}

            {edit ? (
              <div className="row">
                <div className="row pt-2 pl-3">
                  <div className="label">
                    <strong>Property For:</strong>
                  </div>
                  <strong style={{ color: "black" }} className="value">
                    {values.propertyFor === 1 ? "Sale" : "Rent"}
                  </strong>
                </div>

                <SelectBox
                  isMandatory
                  label="Listing Status"
                  name="listingStatus"
                  options={listStatusOptions}
                  placeholder={"Select Status"}
                  value={values.listingStatus}
                  onChange={(val) => setFieldValue("listingStatus", val.value)}
                  error={errors.listingStatus}
                  touched={touched.listingStatus}
                />

                {displaySaleFields ? (
                  <SelectBox
                    label="Agreement Type"
                    name="propertyAgreementType"
                    options={agreeData}
                    placeholder={"Select Agreement "}
                    value={values.propertyAgreementType}
                    onChange={(val) =>
                      setFieldValue("propertyAgreementType", val.value)
                    }
                    error={errors.propertyAgreementType}
                    touched={touched.propertyAgreementType}
                  />
                ) : null}
                {displaySaleFields ? (
                  <TextBox
                    label="Property Price"
                    name="propertyPrice"
                    placeholder="Price"
                    value={values.propertyPrice}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.propertyPrice}
                    touched={touched.propertyPrice}
                  />
                ) : null}
                {displayRentalFields ? (
                  <TextBox
                   isMandatory
                    label="Rental Price"
                    name="rentPrice"
                    placeholder="Price"
                    value={values.rentPrice}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.rentPrice}
                    touched={touched.rentPrice}
                  />
                ) : null}
                {displayRentalFields ? (
                  <SelectBox
                    isMandatory
                    label="Rental Intervals"
                    name="rentTenure"
                    options={rentData}
                    placeholder={"Select Intervals"}
                    value={values.rentTenure}
                    onChange={(val) => setFieldValue("rentTenure", val.value)}
                    error={errors.rentTenure}
                    touched={touched.rentTenure}
                  />
                ) : null}
                <SelectBox
                  label="Commercial"
                  placeholder="Is Commercial"
                  name="isCommercial"
                  options={isCommercialData}
                  value={values.isCommercial}
                  onChange={(val) => setFieldValue("isCommercial", val.value)}
                  error={errors.isCommercial}
                  touched={touched.isCommercial}
                />

                <SelectBox
                  label="New or Established"
                  placeholder="New or Established"
                  name="isNew"
                  options={isNewData}
                  value={values.isNew}
                  onChange={(val) => setFieldValue("isNew", val.value)}
                  error={errors.isNew}
                  touched={touched.isNew}
                />

                <div className="cda-reportTo col-lg-6 col-sm-12">
                  <label htmlFor="trading state" className="s1 w-100">
                    Listing Agent <span style={{color:'red'}}>*</span>
                  </label>
                  <Dropdown
                    value={leadAgent}
                    onChange={handleLeadAgent}
                    options={dpdata}
                    optionLabel="text"
                    className="w-100 md:w-14rem"
                    placeholder="Listing Agent"
                    style={{
                      width: "100%",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  />

                  {errors.leadAgent && touched.leadAgent ? (
                    <p className="form-error">{errors.leadAgent}</p>
                  ) : null}
                </div>
                <div className="cda-reportTo col-lg-6 col-sm-12">
                  <label htmlFor="trading state" className="s1 w-100">
                    Dual Agent
                  </label>
                  <Dropdown
                    value={dualAgent}
                    onChange={handleDualAgent}
                    options={updatedReportToWithoutSelectedLead}
                    optionLabel="text"
                    className="w-100 md:w-14rem"
                    placeholder="Select dual agent"
                    style={{
                      width: "100%",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  />

                  {errors.dualAgent && touched.dualAgent ? (
                    <p className="form-error">{errors.dualAgent}</p>
                  ) : null}
                </div>

                {!displayRentalFields ? (
                  <SelectBox
                    label="Show Price"
                    placeholder=""
                    name="isShowPrice"
                    options={showPriceData}
                    value={values.isShowPrice}
                    onChange={(val) => setFieldValue("isShowPrice", val.value)}
                    error={errors.isShowPrice}
                    touched={touched.isShowPrice}
                  />
                ) : null}
                {!displayRentalFields && values.isShowPrice === 2 && (
                  <TextBox
                    isMandatory
                    label="Show Text"
                    placeholder="Text"
                    name="showText"
                    value={values.showText}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.showText}
                    touched={touched.showText}
                  />
                )}
                {displayRentalFields ? (
                  <TextBox
                    label="Date Available"
                    type="date"
                    name="DateAvailable"
                    placeholder="dd/mm/yyyy"
                    value={FormatDate(values.DateAvailable)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.DateAvailable}
                    touched={touched.DateAvailable}
                  />
                ) : null}
              </div>
            ) : null}
            {edit ? (
              <div className="property-details-buttons">
                <button className="pdb-save-changes" type="submit">
                  Save
                </button>
                <button
                  className="pdb-cancel"
                  type="button"
                  onClick={handleItemClickInPropertyListings}
                >
                  Save & Publish
                </button>
              </div>
            ) : null}
          </form>
        </div>
      ) : null}
    </div>
  );
};

export default AddNewPropertyListing;
