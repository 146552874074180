import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import { SpinnerProvider } from "../Utilities/SpinnerContext";
import CustomSpinner from "../Utilities/Spinner/Spinner";
import Layout from "../src/Layout/layout";  // Import the Layout component
import appRoutes from "./routes"; // Import the routes configuration
import SignIn from "../components/CommonSection/SignIn/Signin";
import HomePage from "../components/CommonSection/LandingPage/LandingPage"

function App() {
  return (
    <>
      <SpinnerProvider>
        <div>
          <CustomSpinner />
        </div>

        <BrowserRouter>
        <Routes>
        <Route path="/" element={<HomePage />} />
            <Route >
              {appRoutes.map(({ path, element: Element, layout }, index) => (
                <Route
                  key={index}
                  path={path}
                  element={layout ? (
                    <Layout>
                      <Element />
                    </Layout>
                  ) : (
                    <Element />
                  )}
                />
              ))}
            </Route>
          </Routes>
        </BrowserRouter>
      </SpinnerProvider>
    </>
  );
}

export default App;
