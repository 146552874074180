import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { defaultProfilePic, getAgentList,resendverificationtonewuser } from "../../CommonSection/apiresources";
import { GetCookie } from '../../CommonSection/Cookie/Session';
import "./AgentList.css";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { FilePath } from "../../CommonSection/apiresources";
import SubNav from '../../CommonSection/Header/subHeader';
import AntdTableExample from '../../../src/input-components/dataTable';
import { HiOutlinePlusSmall } from "react-icons/hi2";

const AgentList = () => {
    const [agentLists, setAgentLists] = useState([]);
    const [agencyID, setAgencyId] = useState('');
    const [search, setSearch] = useState('')
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const ToastRef = useRef();

    const { showSpinner, hideSpinner } = useSpinnerContext();

    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie('@UserData');
                if (userData) {
                    const parsedData = JSON.parse(userData)
                    setAgencyId(parsedData.agencyID);
                }
            } catch (error) {
                console.error('Error retrieving data from AsyncStorage:', error);
            }
        };
        retrieveData();
    }, [agencyID]);

    useEffect(() => {
        if (agencyID)
            PostApiWithSpinner()
    }, [agencyID, search]);

    const PostApiWithSpinner = (currentPage,totalRows) => {
        const baseUrl = getAgentList;
        const data = {
            SortBy: "CreatedDate",
            SortOrder: "Desc",
            AgencyId: agencyID,
            Search: search,
            RecordsPerPage: totalRows,
            PageNo: currentPage,
        };
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data).then((response) => {
            if (response.status === 1) {
                let agentData = response.list;
                let agentList = agentData.map((agent) => ({
                    Name: `${agent.agentName}`,
                    Email: agent.email,
                    Supervision: agent.reportTo,
                    LicienceId: agent.licenseNumber,
                    Expiry: formatDate(agent.licenseExpirydate),
                    Phone: agent.contactNumber,
                    Status: agent.isApproved != true ? 'Not Approved' : agent.isEnabled ? "Enable" : "Disable",
                    guid: agent.agentId,
                    Experiance: agent.yearsOfExperience == 11 ? 'More than 10' : agent.yearsOfExperience,
                    sAgentType: agent.sAgentType,
                    profilePic: agent.profilePic,
                    userId: agent.userId 
                }));
                setTotalPages(response.totalCount)
                setAgentLists(agentList);

            } else {
                alert(`Message: ${response.message}`);
            }
        })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    function formatDate(dateString) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString('en-IN', options);
    }

    const handleRowClick = (e,rowData) => {
        navigate('/agency/addagent' + `?value=${rowData.guid}&edit=${true}&userId=${rowData.userId}`);
    };

    const handlePageChange = (event , pageNumber) => {
        PostApiWithSpinner(event.page + 1, event.rows)
        setCurrentPage(pageNumber);
        setFirst(event.first);
        setRows(event.rows);
      };

      const handleResend = (e,rowData) => {
        const baseUrl = resendverificationtonewuser;
        const data = {
            UserId: rowData.userId,
        };
        makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data).then((response) => {
            if (response.status === 1) {
                PostApiWithSpinner();
                ToastRef.current.notify(["success", response.message]);

            } else {
                alert(`Message: ${response.message}`);
            }
        })
            .catch((error) => {
                console.error('Error:', error);
            });
      }

      const actions = [
        { icon: "/assets/images/mail.svg",key:"resend", onClick: (e, data) => handleResend(e, data) },
        // { icon: "/assets/images/editing.svg", onClick: (e, data) => handleEdit(e, data) },
    ]

    const handleAddAgent = () => {
        navigate('/agency/addagent');
    }


    return (
          <div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
                    <SubNav title={"Agent List"} goback={false} extraData={true}  extraContent={
                                <div className="property-details-buttons">
                                  <button onClick={handleAddAgent} className="subnav-btn" type="submit">
                                    <HiOutlinePlusSmall style={{ fontSize: '24px' }} /> Add
                                  </button>
                                </div>
                              } />
                    <div className="material-work-desk-new bg-white m-3">
                        <AntdTableExample
                            label="List Agent"
                            dataSource={agentLists}
                            globalSearch
                            hideCol={"id,guid,sAgentType,profilePic,userId"}
                            onPageChange={handlePageChange}
                            currentPage={currentPage}
                            totalRows={rows}
                            totalRecords={totalPages}
                            actions={actions}
                            onRowClick={handleRowClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgentList