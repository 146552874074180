import React, { useMemo, useRef, useState, useEffect } from "react";
import debounce from "lodash/debounce";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import axios from "axios";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../input-components/selectBoxNew";
import {
  createPropertyAddress,
  SearchPropertyForAddListing,
  assignedTo,
} from "../../../components/CommonSection/apiresources";
import { Dropdown } from "primereact/dropdown";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { PropertyDetailsSchema } from "../../../components/CommonSection/Schema/schema";
import AddressInput from "../../input-components/address";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import { Select, Spin, Radio } from "antd";
import PropertyListings from "./addNewPropertyListing";

const initialValues = {
  propertyFor: "",

  propertyType: "",

  assignedTo: "",

  address: "",

  unitNumber: "",

  streetNumber: "",

  streetName: "",

  suburb: "",

  postCode: "",

  state: "",
};

const PropertyDetailsAgency = ({ reportTo, closeModal,toggleProperty }) => {
  const [address, setAddress] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [dpdata, setDpdata] = useState([]);
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [propertyId, setPropertyId] = useState(0);
  const [viewAddressDetails, setViewAddressDetails] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [activeTopic, setActiveTopic] = useState("ListAllProperties");
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [addNewProperty, setAddNewProperty] = useState(false);
  const fetchRef = useRef(0);
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  var url = new URL(window.location.href);
  const ToastRef = useRef();
  const [value, setValue] = useState(1);
  const [getDetails, setGetDetails] = useState(null);
  const [getPropertyListingData, setGetPropertyLisyingData] = useState([]);

  const [selectedValue, setSelectedValue] = useState(null); // State to hold the selected value

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");

        if (userData) {
          const parsedData = JSON.parse(userData);

          setUserId(parsedData.userId);

          setAgencyId(parsedData.agencyID);
          reportData(parsedData.agencyUniqueID);
        } else {
          navigate("/signin");
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };

    retrieveData();
  }, [userId]);

  useEffect(() => {}, [reportTo]);

  useEffect(() => {
    setSelectedValue(null);
    setValue(1);
  }, []);

  const reportData = (agencyUID) => {
    const apiUrl = assignedTo;
    const baseUrl = apiUrl + "?agencyUID=" + agencyUID;
    axios.get(baseUrl).then((response) => {
      if (response.data.object) setDpdata(response.data.object);
    });
  };

  const handleTopicValue = (text) => {
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const {
    errors,

    values,

    touched,

    handleBlur,

    handleChange,

    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,

    validationSchema: PropertyDetailsSchema,

    onSubmit: (values, action) => {
      values.address = address;

      values.propertyFor = values.propertyFor;

      values.propertyType = values.propertyType;

      values.assignedTo = parseInt(selectedCity, 10);

      handleFinalSubmit();
    },
  });

  const property_type = [
    { label: "House", value: 7 },
    { label: "Apartment", value: 3 },
    { label: "Flat", value: 6 },
    { label: "Acreage/Semi-rural", value: 1 },
    { label: "Alpine", value: 2 },
    { label: "Block Of Units", value: 4 },
    { label: "Duplex/Semi-detached", value: 5 },
    { label: "Retirement Living", value: 8 },
    { label: "Serviced", value: 9 },
    { label: "Studio", value: 10 },
    { label: "Terrace", value: 11 },
    { label: "Town House", value: 12 },
    { label: "Unit", value: 13 },
    { label: "Villa", value: 14 },
    { label: "Ware House", value: 15 },
    { label: "Land", value: 16 },
    { label: "Other", value: 17 },
  ];

  const handlepressSelect = async (addrs) => {
    setAddress(addrs);

    try {
      const results = await geocodeByAddress(addrs);

      const details = results[0];

      const fullFormattedAddress = details.formatted_address;

      setAddress(fullFormattedAddress);
      setFieldValue("address", fullFormattedAddress);

      const { address_components } = details;

      const address = {};

      address_components.forEach((component) => {
        const { types, long_name } = component;

        if (types.includes("subpremise")) {
          address.unitNumber = long_name;
        } else if (types.includes("street_number")) {
          address.streetNumber = long_name;
        } else if (types.includes("route")) {
          address.streetName = long_name;
        } else if (types.includes("locality")) {
          address.suburb = long_name;
        } else if (types.includes("postal_code")) {
          address.postCode = long_name;
        } else if (types.includes("administrative_area_level_1")) {
          address.state = long_name;
        }
        let latitude = details.geometry.location.lat();
        let longitude = details.geometry.location.lng();

        setLatitude(latitude);

        setLongitude(longitude);

        setValues({ ...values, ...address });
      });
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleFinalSubmit = () => {
    const formData = {
      PropertyId: propertyId ? propertyId : 0,

      PropertySaleRental: values.propertyFor,

      PropertyType: values.propertyType,

      AgentId: parseInt(selectedCity, 10),

      AgencyId: agencyID,

      PropertyGooglePlacesLocation: values.address,

      State: values.state,

      Suburb: values.suburb,

      PostCode: values.postCode,

      UnitNumber: values.unitNumber,

      StreetNumber: values.streetNumber,

      StreetName: values.streetName,

      UserId: userId,

      PropertyGooglePlacesLocationLong: longitude,

      PropertyGooglePlacesLocationLat: latitude,
    };

    const url = createPropertyAddress;

    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
  
          // Update the URL with the new parameters
          const currentURL = new URL(window.location.href);
          const newValue = returnData.sUID;
  
          if (newValue) {
            currentURL.searchParams.set("propertyId", returnData.id);
            currentURL.searchParams.set("propertyUId", newValue);
            currentURL.searchParams.set("isRentalListed", false); // true for Rent
            currentURL.searchParams.set("isSaleListed", false); // true for Sale
  
            // Push the updated URL without reloading the page
            window.history.pushState(null, "", currentURL.toString());
          }
          toggleProperty(1)
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })

      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleAssignedTo = (e) => {
    setSelectedCity(e.value);

    setFieldValue("assignedTo", e.value);
  };

  const handleView = () => {
    setViewAddressDetails(!viewAddressDetails);
  };

  const handlecancel = () => {
    closeModal();
  };
  const handlesetAddress = async (addrs) => {
    setAddress(addrs);
    setFieldValue("address", addrs);
  };

  return (
    <div className="dashboard-agency">
      <ToastAlert ref={ToastRef} />
      <div className="dashboardagency-content">
        <form
          onSubmit={handleSubmit}
          className="details-form"
          style={{ display: "initial", width: "100%" }}
        >
          <div className="property-details-content">
            <div className="contacts-group row">
              <SelectBox
                isMandatory
                label="Property Type"
                name="propertyType"
                options={property_type}
                placeholder={"Select Property Type"}
                value={values.propertyType}
                onChange={(val) => setFieldValue("propertyType", val.value)}
                error={errors.propertyType}
                touched={touched.propertyType}
              />

              <AddressInput
                labelName="Address"
                address={address}
                setAddress={handlesetAddress}
                handlePressSelect={handlepressSelect}
                error={errors.address}
                touched={touched.address}
                isMandatory
                handleView={handleView}
              />

              <TextBox
                label=""
                placeholder="Unit Number"
                id="unitNumber"
                name="unitNumber"
                value={values.unitNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.unitNumber}
                touched={touched.unitNumber}
              />

              <TextBox
                label=""
                placeholder="Street Number"
                id="streetNumber"
                name="streetNumber"
                value={values.streetNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.streetNumber}
                touched={touched.streetNumber}
              />

              <TextBox
                label=""
                placeholder="Street Name"
                id="streetName"
                name="streetName"
                value={values.streetName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.streetName}
                touched={touched.streetName}
              />

              <TextBox
                label=""
                placeholder="Suburb"
                id="suburb"
                name="suburb"
                value={values.suburb}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.suburb}
                touched={touched.suburb}
              />

              <TextBox
                label=""
                placeholder="State"
                id="state"
                name="state"
                value={values.state}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.state}
                touched={touched.state}
              />

              <TextBox
                label=""
                placeholder="Postcode"
                id="postCode"
                name="postCode"
                value={values.postCode}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.postCode}
                touched={touched.postCode}
              />
              <div className="cda-reportTo col-lg-6 col-sm-12">
                <label htmlFor="trading state" className="s1 w-100">
                  Assigned To<span style={{ color: "red" }}>*</span>
                </label>

                <Dropdown
                  value={selectedCity}
                  onChange={handleAssignedTo}
                  options={dpdata}
                  optionLabel="text"
                  className="w-100 md:w-14rem"
                  placeholder="Select Assigned To"
                />

                {errors.assignedTo && touched.assignedTo ? (
                  <p className="form-error">{errors.assignedTo}</p>
                ) : null}
              </div>
            </div>

            <div className="property-details-buttons">
              <button
                className="pdb-cancel"
                type="reset"
                onClick={() => handlecancel()}
              >
                Cancel
              </button>

              <button className="pdb-save-changes" type="submit">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PropertyDetailsAgency;
