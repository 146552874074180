import React from "react";
import { useEffect, useState, useRef } from "react";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import AddOrSelectProperty from "./addNewProperty"
import LandlordDetails from "./landlordDetails";
import RentDetails from "./rent";
import ImpInfo from "./importantInformation";
import Signature from "./signature";
import Summary from "./summary";
import { getTenancyAgreement,CreateTenancyAgreement } from "../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus, eTenancyAgreementStatus } from "../../../Utilities/enum";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import CustomModal from "../../input-components/Modal";

const AgreementTabs = ({ handleparentsuccess,handleAddNewAgreement }) => {
  const [activeKey, setActiveKey] = useState("PropertyDetails");
  const [getDetails, setGetDetails] = useState(null);
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [dpdata, setDpdata] = useState([]);
  const [userId, setUserId] = useState("");
  const ToastRef = useRef();
  const navigate = useNavigate();
  const [agencyID, setAgencyId] = useState("");
  const [HandleNextPages, setHandleNextPages] = useState('')

  const handleDataChild = (value) => {
    GetApi(value);
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  useEffect(() => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
    var activeTab = url.searchParams.get("activeTab");
    if (activeTab) {
      setActiveKey(activeTab);
    }
    if (propertyUId) {
      GetApi();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, [activeKey]);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          //reportData(parsedData.agencyUniqueID);
          setAgencyId(parsedData.agencyID);

        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  const closeAgreementModule = () => {
    var url = new URL(window.location.href);
    var properties = url.searchParams.get("properties");
    var propertyUId = url.searchParams.get("propertyUID");
    var viewDetails = url.searchParams.get("viewDetails");
    if(propertyUId && viewDetails){
      dynamicUrl(navigate, "viewPropertyDetails" + `?propertyUID=${propertyUId}`);
    }
    else if(properties){
      dynamicUrl(navigate, "propertiesList" + `?propertyUID=${propertyUId}&tab=OwnerShip&subTab=agreement`);
    }else{
    dynamicUrl(navigate, "listagreementtype");
    }
  };



  const GetApi = (value) => {
    var url = new URL(window.location.href);
    var propertyUId = value ? value : url.searchParams.get("propertyUID");
    const baseUrl = getTenancyAgreement;
    const apiUrl = `${baseUrl}?propId=${propertyUId}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get")
      .then((response) => {
        if (response.object === null) {
          createManagementAgreement(propertyUId,agencyID)
        }
        if (response.object !== null) {
          setGetDetails(response.object);
          if (response.object.agreementStatus === eTenancyAgreementStatus.PropertySelected) {
            setActiveKey("")
              setHandleNextPages("")
          } else if (response.object.agreementStatus === eTenancyAgreementStatus.AgencyOwnerTenant) {
            setActiveKey("LandlordDetails")
            setHandleNextPages("LandlordDetails")
          }
          else if (response.object.agreementStatus === eTenancyAgreementStatus.TenantOwnerAgent) {
            setActiveKey("rentDetails")
            setHandleNextPages("rentDetails")
          }
          else if (response.object.agreementStatus === eTenancyAgreementStatus.Rent) {
            setActiveKey("impInfo")
            setHandleNextPages("impInfo")
          }
          else if (response.object.agreementStatus === eTenancyAgreementStatus.Sent) {
            setActiveKey("summary")
            setHandleNextPages("summary")
          }
          else if (response.object.agreementStatus === eTenancyAgreementStatus.Signed) {
            setActiveKey("summary")
            setHandleNextPages("summary")
          }
          else if (response.object.agreementStatus === eTenancyAgreementStatus.PromotionalActivity) {
            setActiveKey("RepairAndMaintainance")
            setHandleNextPages("RepairAndMaintainance")
          }
          else if (response.object.agreementStatus === eTenancyAgreementStatus.Repairs) {
            setActiveKey("AgreementSignature")
            setHandleNextPages("AgreementSignature")
          }
          else if (response.object.agreementStatus === eTenancyAgreementStatus.Signed) {
            setActiveKey("AgreementSummary")
            setHandleNextPages("AgreementSummary")
          }
          else if (response.object.agreementStatus === eTenancyAgreementStatus.Completed) {
            setActiveKey("AgreementSummary")
            setHandleNextPages("AgreementSummary")
          }
          else if (getDetails.agreementStatus === eTenancyAgreementStatus.PropertySelected) {
            setActiveKey("")
            setHandleNextPages("")
          }
        } 
      })
      .catch((error) => {
        //handleparentsuccess(["error", error]);
      });
  };
  const createManagementAgreement = (data,agencyID,isNew) => {
    setHandleNextPages("")
    var urls = new URL(window.location.href);
    var Id = agencyID !== ""? agencyID : urls.searchParams.get("agencyId");
    let formData = {
      PropertyUId: data,
      AgencyId: Id,
      isNew: isNew? isNew : null
    };
    const url = CreateTenancyAgreement;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          setGetDetails(returnData.object);

        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleToastMessage = (toastdata) => {
    ToastRef.current.notify(toastdata);
  };

  const handleNextPage = (value) => {
    setHandleNextPages(value)
  }
  return (
    <>
      <ToastAlert ref={ToastRef} />
      <div className="dashboard-agency">
        <div className="continer-dbag">
          <div className="dashboardagency-content">
          <CustomModal
              title="Tenancy Agreement"
              width={1100}
              closable={closeAgreementModule}
              visible={true}
              onCancel={closeAgreementModule}
              modalData={
            <div>
              {HandleNextPages === '' ? <AddOrSelectProperty
                onDataRecive={handleDataChild}
                handleparentsuccess={handleToastMessage}
                handleCurrentPage={handleTabChange}
                getDetails={getDetails}
                reportTo={dpdata}
                handleNextPage={handleNextPage}
              /> : null}
{/*               
              {HandleNextPages === '' ? <PartiesPrinciple
                onDataRecive={handleDataChild}
                handleparentsuccess={handleToastMessage}
                handleCurrentPage={handleTabChange}
                getDetails={getDetails}
                reportTo={dpdata}
                handleNextPage={handleNextPage}
              /> : null} */}
              {HandleNextPages === 'LandlordDetails' ? <LandlordDetails
                onDataRecive={handleDataChild}
                handleparentsuccess={handleToastMessage}
                handleCurrentPage={handleTabChange}
                getDetails={getDetails}
                reportTo={dpdata}
                handleNextPage={handleNextPage}
              /> : null}
              {HandleNextPages === 'rentDetails' ? <RentDetails
                onDataRecive={handleDataChild}
                handleparentsuccess={handleToastMessage}
                handleCurrentPage={handleTabChange}
                getDetails={getDetails}
                reportTo={dpdata}
                handleNextPage={handleNextPage}
              /> : null}
               {HandleNextPages === 'impInfo' ? <ImpInfo
                onDataRecive={handleDataChild}
                handleparentsuccess={handleToastMessage}
                handleCurrentPage={handleTabChange}
                getDetails={getDetails}
                reportTo={dpdata}
                handleNextPage={handleNextPage}
              /> : null}
               {HandleNextPages === 'sign' ? <Signature
                onDataRecive={handleDataChild}
                handleparentsuccess={handleToastMessage}
                handleCurrentPage={handleTabChange}
                getDetails={getDetails}
                reportTo={dpdata}
                handleNextPage={handleNextPage}
              /> : null}
               {HandleNextPages === 'summary' ? <Summary
                onDataRecive={handleDataChild}
                handleparentsuccess={handleToastMessage}
                handleCurrentPage={handleTabChange}
                getDetails={getDetails}
                reportTo={dpdata}
                handleNextPage={handleNextPage}
                //handleAddNewAgreement={createManagementAgreement}
              /> : null}
            </div>
            
              }  footer={[null]}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgreementTabs;
