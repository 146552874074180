import React, { useState, useEffect } from 'react';
import { Table, Input,Checkbox,Popover,Tag,Select  } from 'antd';
import jsPDF from "jspdf";
import "jspdf-autotable";
import {MenuFoldOutlined} from '@ant-design/icons';
import * as XLSX from "xlsx";
const { Search } = Input;
import './input-component.css';
import { Tooltip } from "antd";


const DataTable  = ({onPriorityChange, priorityOptions,dataSource,onSearchValueChange , onRowClick, actions, showColumnFilter, onSelectedRowKeysChange,rowKey,displayDoc ,hideCol,initialSelectedRowKeys,showCheckbox,globalSearch,tagColumns,colSearch,customDropDownFilter,onFilterIconClick,filterIcon ,onSearchChange,onPageChange,currentPage,totalRows,totalRecords,searchText,label,rowClassName}) => {
  const [tableData, setTableData] = useState([]);
  const [initialData, setInitialData] = useState(dataSource);
  const [value, setValue] = useState('');
  const [placeholder, setPlaceholder] = useState('Search');
  const colInputData = dataSource?.length > 0 ? dataSource[0] : [];
  const [selectedRowKeys, setSelectedRowKeys] = useState(initialSelectedRowKeys || []);
  const [selectedRows, setSelectedRows] = useState([]);

  const [dropdownOptions, setDropdownOptions] = useState([]);

  useEffect(() => {
    // Extract unique status values from dataSource
    const statusOptions = Array.from(new Set(dataSource?.map(entry => entry.Status)));
    setDropdownOptions(statusOptions);
  }, [dataSource]);

  useEffect(() => {
    setTableData(dataSource); // Update tableData when dataSource changes
  }, [dataSource]);
  
  // Handle pagination
  const handleTableChange = (pagination) => {
    const page = pagination.current;
    const pageSize = pagination.pageSize;
    onPageChange(page, pageSize);
  };

  const capitalizeFirstLetter = (key) => {
    if (!key) return '';
    const formattedKey = key.replace(/([a-z])([A-Z])/g, '$1 $2');
    return formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
  };

  const renderTag = (text, column) => {
    if (tagColumns && tagColumns[column]) {
      const { colorMap, defaultColor } = tagColumns[column];
      const color = colorMap[text] || defaultColor;
      return (
        <span style={{ display: 'flex', alignItems: 'center', fontWeight: '500', color }}>
          <span style={{background:color}} className='color-dot'></span>
          {text}
        </span>
      );
    }
    return text;
  };
  

  const handleDropdownChange = (value, key) => {
    if (value === undefined) {
      setInitialData(dataSource);
      setDropdownOptions([]); // Assuming no dropdown options are needed in this case
      return;
    }
    const statusOptions = dataSource?.reduce((options, record) => {
      const statusValue = record[key];
      if (statusValue && !options.includes(statusValue)) {
        options.push(statusValue);
      }
      return options;
    }, []);
  
    const filteredData = dataSource.filter(entry =>
      entry[key] && entry[key].toLowerCase() === value.toLowerCase()
    );
  
    setInitialData(filteredData);
    setDropdownOptions(statusOptions);
  };
  

  const handleClearSelection = (key) => {
    setTableData(dataSource);
  };

  const columnFilter = dataSource?.length > 0
  ? Object.keys(dataSource[0])
      .filter(key => !["id", "ID", "Id", "Guid", "GUID", "guid"].includes(key) && !hideCol.split(',').map(h => h.trim().toLowerCase()).includes(key.toLowerCase()))
      .map(key => ({
        title: capitalizeFirstLetter(key),
        render: (data, record) => renderTag(data, key),
        dataIndex: key,
        key: key,
      }))
  : [];

  const handlePriorityChange = (value, recordKey) => {
    const updatedData = tableData.map(record =>
      record.key === recordKey ? { ...record, Priority: value } : record
    );
    setTableData(updatedData);
    if (onPriorityChange) {
      onPriorityChange(value, recordKey);
    }
  };

  const renderPriorityDropdown = (text, record) => (
    <Select defaultValue={text} style={{ width: 100 }} onChange={(value) => handlePriorityChange(value, record.key)}>
      {priorityOptions?.map(option => (
        <Option key={option} value={option}>{option}</Option>
      ))}
    </Select>
  );
  
  const columns = dataSource?.length > 0
  ? Object.keys(dataSource[0])
    .filter(key => !["id", "ID", "Id", "Guid", "GUID", "guid"].includes(key) && !hideCol.split(',').map(h => h.trim().toLowerCase()).includes(key.toLowerCase()))
    .map(key => {
      const isStatusOrRequestTypeColumn = customDropDownFilter?.includes(key);

      return {
        title: (
          <div>
            <div>
              {capitalizeFirstLetter(key)}
            </div>
            <div>
              {colSearch && !isStatusOrRequestTypeColumn && (
                <Input
                  placeholder={`Search ${capitalizeFirstLetter(key)}`}
                  onChange={(e) => handleSearch(e.target.value, key)}
                  style={{ width: 120, marginTop: 8 }}
                />
              )}
              {isStatusOrRequestTypeColumn && (
                <Select
                  placeholder={`Select ${capitalizeFirstLetter(key)}`}
                  onChange={(value) => handleDropdownChange(value, key)}
                  style={{ width: 120, marginTop: 8 }}
                  showSearch
                  allowClear
                  onClear={() => handleClearSelection(key)}
                >
                  {dataSource?.map(record => record[key]).filter((value, index, self) => self.indexOf(value) === index).map((value, index) => (
                    <Option key={index} value={value}>{value}</Option>
                  ))}
                </Select>
              )}
            </div>
          </div>
        ),
        render: (data, record) => {
          // Render PropertyAddress as a link
          if (key === "ContactDetails") {
            const contactDetails = record.ContactDetails || {};
            return (
              <div>
                <div><strong>Name:</strong> {contactDetails.fullName || "N/A"}</div>
                <div><strong>Phone:</strong> {contactDetails.phone || "N/A"}</div>
                <div><strong>Email:</strong> {contactDetails.email || "N/A"}</div>
              </div>
            );
          }
          if (key === "PropertyAddress") {
            return (
              <a style={{cursor:'pointer'}}  rel="noopener noreferrer">
                {data}
              </a>
            );
          }
          // if (key === "Type") {
          //   return renderPriorityDropdown(data, record);
          // }

          if (typeof data === "string" && data.length > 20 && key !== "Address") {
            return (
              <Tooltip title={data}>
                <span>{data.substring(0, 20)}...</span>
              </Tooltip>
            );
          }

          return renderTag(data, key); // Render tags for other columns if needed
        },
        dataIndex: key,
        key: key,
        ...(colSearch && !isStatusOrRequestTypeColumn && {
        }),
      };
    })
  : [];


// Handle search logic
const handleSearch = (searchValue, key) => {
  const filteredData = dataSource?.filter(entry =>
    entry[key] && entry[key].toString().toLowerCase().includes(searchValue.toLowerCase())
  );
  setInitialData(filteredData);
  onSearchChange(searchText);
  if (onSearchValueChange) {
    onSearchValueChange(searchValue);
  }
};

// Additional logic for actions column
if (actions && dataSource?.length > 0 && Object.keys(dataSource[0]).length > 0) {
  columns.push({
    title: "Action",
    key: "action",
    render: (text, record) => (
      <span style={{ display: "flex" }}>
        {actions.map((action, index) => {
          // Check if the action has a condition; if not, display by default
          const shouldDisplay = action.condition ? action.condition(record) : true;

          // Render the action based on the condition check
          return shouldDisplay ? (
            <div
              key={index}
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={(e) => action.onClick(e, record)}
              title={action.key}
            >
              <img
                width={action.iconSize ? action.iconSize : "20"}
                src={action.icon}
                alt={`action-${index}`}
              />
            </div>
          ) : null;
        })}
      </span>
    ),
  });
}



  let extendedTableData = [];
  if (showColumnFilter) {
    extendedTableData = [
      Object.fromEntries(
        Object.keys(colInputData).map(data => [
          data,
          <Input
            key={data}
            placeholder={'Search ' + data}
            value={value[data] || ''}
            onChange={e => {
              const currValue = e.target.value;
              setValue(prevState => ({
                ...prevState,
                [data]: currValue,
              }));
              const filteredData = dataSource?.filter(entry =>
                entry[data] && entry[data].toLowerCase().includes(currValue.toLowerCase())
              );
              setTableData(filteredData);
            }}
          />
        ])
      ),
      ...tableData
    ];
  } else {
    extendedTableData = initialData;
  }


  const onSearch = (value, _e, info) => {
    const searchVal = value.target.value.toLowerCase();
    const filteredData = dataSource?.filter(entry =>
      Object.values(entry).some(val => typeof val === 'string' && val.toLowerCase().includes(searchVal))
    );
    setInitialData(filteredData)
    if (onSearchValueChange) {
      onSearchValueChange(searchVal);
    }
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const visibleColumns = newColumnsFiles.filter(column => !column.hidden);
    
    doc.autoTable({
      head: [visibleColumns.map(col => col.title)],
      body: tableData.map(row => visibleColumns.map(col => row[col.dataIndex])),
    });
    doc.save('table.pdf');
  };
  

  const exportToCSV = () => {
    const visibleColumns = newColumnsFiles.filter(column => !column.hidden);
    const csvData = [visibleColumns.map(col => col.title), ...tableData.map(row => visibleColumns.map(col => row[col.dataIndex]))];
    const csvContent = csvData.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'table.csv');
    document.body.appendChild(link);
    link.click();
  };
  
  const exportToXLSX = () => {
    const visibleColumns = newColumnsFiles.filter(column => !column.hidden);
    const ws = XLSX.utils.aoa_to_sheet([visibleColumns.map(col => col.title), ...tableData.map(row => visibleColumns.map(col => row[col.dataIndex]))]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'table.xlsx');
  };
  
  const onSelectChange = (newSelectedRowKeys,newSelectedRows) => {
    setSelectedRows(newSelectedRows);
    setSelectedRowKeys(newSelectedRowKeys,selectedRows  );
    if (onSelectedRowKeysChange) {
      onSelectedRowKeysChange(newSelectedRowKeys, newSelectedRows);
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const defaultCheckedList = columnFilter .map((item) => item.key);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const options = columnFilter.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  const newColumnsFiles = columnFilter.map((item) => ({
    ...item,
    hidden:checkedList.length===0?"": !checkedList.includes(item.key),
    }));

  const newColumns = columns.map((item) => ({
    ...item,
    hidden:checkedList.length===0?"": !checkedList.includes(item.key),
    }));

  useEffect(() => {
    setTableData(dataSource);
    setInitialData(dataSource);
    if (initialSelectedRowKeys) {
      setSelectedRowKeys(initialSelectedRowKeys);
    }
  }, [dataSource,initialSelectedRowKeys]);

  useEffect(() => {
    if (initialData?.length > 0) {
      const firstKey = Object.keys(initialData[0])[0];
      setPlaceholder(`Search ${firstKey}`);
    }
  }, [initialData]);

  useEffect(() => {
    if (onSelectedRowKeysChange) {
      onSelectedRowKeysChange(selectedRowKeys,selectedRows);
    }
  }, [selectedRowKeys,selectedRows, onSelectedRowKeysChange]);  

  return (
    <div style={{margin:"10px 20px"}}>
    <div className='headerAction'>
    {displayDoc ? <div style={{display:"flex", alignItems:'baseline'}}>
          <img src={'/assets/images/pdfIcon.png'} onClick={exportToPDF} width="28"  style={{height:'26px', cursor:'pointer'}} className='mr-2'/>
          <img src={'/assets/images/csvIcon.png'} onClick={exportToCSV}  width="28" style={{height:'26px',cursor:'pointer'}} className='mr-2'/>
          <img src={'/assets/images/xlsxIcon.png'} onClick={exportToXLSX} width="28" style={{height:'26px',cursor:'pointer'}} className='mr-2'/>
          <Popover content={<Checkbox.Group
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row', // Change to column layout
                  maxWidth: '300px', // Set max width to control number of checkboxes per row
                }}
      value={checkedList}
      options={options}
      onChange={(value) => {
        setCheckedList(value);
      }}
    />}  trigger="hover">
      <MenuFoldOutlined title='columns' style={{fontSize:"24px",color:'black'}} className='exportIcon mr-2'/>
    </Popover>
    {filterIcon ? <img onClick={onFilterIconClick} src={'/images/filter_new.svg'} width="26" style={{height:'26px',cursor:'pointer'}} className='mr-2'/> : null }

        </div>
        : <div style={{fontWeight:'500',fontSize:'16px',color:'#2c3e50'}}>{label}</div>}
        {globalSearch&&<Search
          placeholder="Search..."
          onChange={onSearch}
          style={{
            width: 300,
          }}
        />}
      </div> 
      
      <Table
        dataSource={extendedTableData}
        columns={newColumns}
        rowKey={record => record.id || record.guid || record.Guid || record.GUID || record.Id}
        rowSelection={showCheckbox && rowSelection}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              if (onRowClick) {
                onRowClick(event,record);
              }
            },
          };
        }}
        pagination={{
        current: currentPage,
        total: totalRecords,
        pageSize: totalRows,
        onChange: (page, pageSize) => handleTableChange({ current: page, pageSize }),
        onSearchChange:{onSearchChange}
      }}
      style={{overflowX:'auto', whiteSpace:'nowrap'}}
      rowClassName={(record) =>
          rowClassName ? rowClassName(record) : "" // Apply rowClassName logic if provided
        }
      />
    </div>
  );
};

export default DataTable ;
