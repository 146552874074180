import React from 'react';
import { BiCheckCircle, BiSolidBullseye } from 'react-icons/bi';
import './verticalProgressive.css'; // Import your CSS file for styling

const Steps = ({ items, activeStep , title}) => {
    return (
        <div className="steps-container">
            <div className="pb-3 labelMaintainance">{title}</div>
            {items.map((item, index) => (
                <div className={`step-item ${index === activeStep ? 'active' : ''}`} key={index}>
                    <div className="step-date">
                        <div>{item.img}</div>
                    </div>
                    <div className="step-icon">
                        {index !== items.length - 1 && (
                            <div className={`vertical-line ${item.isCompleted ? 'active' : ''}`}></div>
                        )}
                        {item.isCompleted ? (
                            <BiCheckCircle style={{ fontSize: '24px', color: '#155b75' }} />
                        ) : (
                            <BiSolidBullseye style={{ fontSize: '24px', color: '#ccc' }} />
                        )}
                    </div>
                    <div className="step-content">
                        <h6>{item.title}</h6>
                        <p>{item.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Steps;
