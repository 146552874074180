import { useNavigate } from "react-router-dom";
import moment from "moment";
import { eNewAgencyProfileStatus } from "../../../Utilities/enum";

export const navigateToPageWithDelay = (path, navigate) => {
  setTimeout(() => {
    navigate(path);
  }, 2500);
};
export const parseToDate = (value) => {
  return value == null ? "" : moment(value);
};

export const getLabelFromValue = (options, targetValue) => {
  const option = options.find((opt) => opt.value === targetValue);
  return option ? option.label : "Label not found";
};
export const getDefaultFromValue = (value) => {
  return value == null ? "" : value;
};
export const getDefaultDateValue = (value) => {
  return value == null || value == "" ? new Date() : new Date(value);
};
export const isNullOrEmpty = (value) => {
  return value === null || value === undefined || value === "";
};
export const getdataForAgencyProgressbar = (status, currentScreen) => {
  return [
    {
      label: "Principal Details",
      isSelected:
        currentScreen == eNewAgencyProfileStatus.PrincipalDetailsAdded,
      isCompleted: status >= eNewAgencyProfileStatus.PrincipalDetailsAdded,
      url:
        status >= eNewAgencyProfileStatus.PrincipalDetailsAdded
          ? "/agency/principaldetails"
          : null,
    },
    {
      label: "Verify Agency",
      isSelected:
        currentScreen == eNewAgencyProfileStatus.VerificationDocumentAdded,
      isCompleted: status >= eNewAgencyProfileStatus.VerificationDocumentAdded,
      url:
        status >= eNewAgencyProfileStatus.VerificationDocumentAdded
          ? "/agency/verifyagency"
          : null,
    },
    {
      label: "License Details",
      isSelected: currentScreen == eNewAgencyProfileStatus.LicenseDetailsAdded,
      isCompleted: status >= eNewAgencyProfileStatus.LicenseDetailsAdded,
      url:
        status >= eNewAgencyProfileStatus.LicenseDetailsAdded
          ? "/agency/licensedetails"
          : null,
    },
    {
      label: "Add Company Details",
      isSelected: currentScreen == eNewAgencyProfileStatus.CompanyDetailsAdded,
      isCompleted: status >= eNewAgencyProfileStatus.CompanyDetailsAdded,
      url:
        status >= eNewAgencyProfileStatus.CompanyDetailsAdded
          ? "/agency/addcompanydetails"
          : null,
    },
    {
      label: "Insurance Details",
      isSelected: currentScreen == eNewAgencyProfileStatus.InsuranceDetails,
      isCompleted: status >= eNewAgencyProfileStatus.InsuranceDetails,
      url:
        status >= eNewAgencyProfileStatus.InsuranceDetails
          ? "/agency/insurancedetails"
          : null,
    },
    {
      label: "Payment Details",
      isSelected: currentScreen == eNewAgencyProfileStatus.PaymentDetails,
      isCompleted: status >= eNewAgencyProfileStatus.PaymentDetails,
      url:
        status >= eNewAgencyProfileStatus.PaymentDetails
          ? "/agency/paymentdetails"
          : null,
    },
  ];
};
