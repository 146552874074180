// TextBox.js
import React from 'react';

const TextBox = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  error,
  touched,
  icon,
  isMandatory = false,
  type,
  colVal = '',
  readOnly = false, 
  extraDetails
}) => {
  let colVals =
  colVal === 12 ? 'col-lg-12 col-sm-12' : colVal === 3 ? 'col-lg-3 col-sm-12' : colVal === 8 ? 'col-lg-8 col-sm-12' : 'col-lg-6 col-sm-12';

  return (
    <div className={`pd-${name} ${colVals} pl-3`}>
      <label htmlFor={name} className='s1'>
        {label} {isMandatory && <span style={{ color: 'red' }}>*</span>}
      </label>
      <div className="input-group mb-3">
        {icon && (
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              {icon}
            </span>
          </div>
        )}
        <textarea
          style={{ height: '100px',width:'410px', resize: 'both', overflow: 'auto',backgroundColor: readOnly ? '#e9ecef' : 'white', }}
          className="form-control tenant-date"
          placeholder={placeholder}
          value={value}
          onChange={!readOnly ? onChange : undefined}
          onBlur={onBlur}
          name={name}
          aria-describedby="basic-addon1"
          readOnly={readOnly} 
        />
      </div>
      {extraDetails && (<div className='s1'>{extraDetails}</div>)}

      {error && touched ? <p className="form-error">{error}</p> : null}
    </div>
  );
};

export default TextBox;
