import React, { useState, useRef, useEffect } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/viewPropertyDetails.css";
import { getpropertybyfolioid } from "../../../../components/CommonSection/apiresources";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { BiBath, BiBed, BiCar } from "react-icons/bi";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { GetCookie } from "../../../../components/CommonSection/Cookie/Session";
import { ActionStatus } from "../../../../Utilities/enum";
import SubNav from "../../../../components/CommonSection/Header/subHeader";
import ShowValue from "../../../input-components/ShowValue";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const ViewPropertyDetails = () => {
  const ToastRef = useRef();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [getDetails, setGetDetails] = useState([]);
  const [tenantCollapse, setTenantCollapse] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          getPropertyDetails(parsedData.folioUniqueId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, []);

  useEffect(() => {
  }, [])

  const getPropertyDetails = (folioId) => {
    const url = getpropertybyfolioid + `?folioUid=${folioId}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "get")
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          setGetDetails(returnData.object)
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const renderTenent = () => {
    if (!getDetails || !getDetails.tenantDetails) {
      return null;
    }

    const sortedContacts = getDetails.tenantDetails.sort((a, b) => {
      return b.isPrimary - a.isPrimary;
    });

    return sortedContacts.map((contact, index) => {
      const isPrimary = contact.isPrimary
        ? "Primary Tenant"
        : "Secondary Tenant";
      return (
        <table key={index} className="property- mb-5" style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td
                className="left-menu-owner"
                style={{ display: "flex" }}
                colSpan="2"
              >
                {isPrimary}
                {contact.isPrimary && (
                  <span className="ml-3" style={{ marginTop: "-5px" }}>
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td className="left-menu-owner">Name</td>
              <td className="left-menu-value">{contact.getFullName}</td>
            </tr>
            <tr>
              <td className="left-menu-owner">Phone</td>
              <td className="left-menu-value">{contact.tenantMobile}</td>
            </tr>
            <tr>
              <td className="left-menu-owner">Email</td>
              <td className="left-menu-value">{contact.tenantEmail}</td>
            </tr>
          </tbody>
        </table>
      );
    });
  };

  const handleTenant = () => {
    setTenantCollapse(!tenantCollapse)
  }

  const getTrueProperties = (details) => {
    if (!details || typeof details !== "object") {
      return "";
    }
    const trueProps = [];

    for (const [key, value] of Object.entries(details)) {
      if (value === true) {
        // Convert camelCase to human-readable format
        const formattedKey = key
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        trueProps.push(formattedKey);
      }
    }

    return trueProps.join(", ");
  };

  const primarytenantDetails = getDetails?.tenantDetails?.filter((tenant) => tenant.primaryTenant);
  const secondarytenantDetails = getDetails?.tenantDetails?.filter((tenant) => !tenant.primaryTenant);
  const tenantPortfolioDetails = getDetails?.tenantDetails?.[0]?.tenantFolioDetails;


  const renderTenantPortfolioDetails = () => {
    if (!tenantPortfolioDetails) return null;

    return (
      <div className="mt-2 detailsContainer mr-1 d-flex">
        <div className="owner-details">
          <ShowValue Label="Tenant Account Folio" setData={tenantPortfolioDetails.tenantAccountFolio || "Nil"} />
          <ShowValue Label="Tenant Checksum" setData={tenantPortfolioDetails.tenantChecksum || "Nil"} />
          <ShowValue Label="Rent Review Frequency" setData={tenantPortfolioDetails.rentReviewFrequency || "Nil"} />
          <ShowValue Label="Rent Include Tax" setData={tenantPortfolioDetails.rentIncludeTax ? "Yes" : "No"} />
          <ShowValue Label="Rent" setData={tenantPortfolioDetails.rent || "Nil"} />
          <ShowValue Label="Period" setData={tenantPortfolioDetails.period || "Nil"} />
        </div>
        <div className="owner-details">
          <ShowValue Label="Agreement Start" setData={tenantPortfolioDetails.agreementStart || "Nil"} />
          <ShowValue Label="Agreement End" setData={tenantPortfolioDetails.agreementEnd || "Nil"} />
          <ShowValue Label="Abn" setData={tenantPortfolioDetails.abn || "Nil"} />
          <ShowValue Label="Balance" setData={tenantPortfolioDetails.balance || "Nil"} />
          <ShowValue Label="Bond Amount" setData={tenantPortfolioDetails.bondAmount || "Nil"} />
          <ShowValue Label="Bond In-Trust" setData={tenantPortfolioDetails.bondInTrust || "Nil"} />
        </div>
      </div>
    );
  };


  const renderTenantInfo = (title, tenants) => (
    tenants?.map((tenant) => (
      <div key={tenant.contactId} className="mt-2 detailsContainer mr-1">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{title}</div>
        </div>
        <div className="owner-details">
          <ShowValue Label="Name" setData={tenant.getFullName || "Nil"} />
          <ShowValue Label="Email" setData={tenant.tenantEmail || "Nil"} />
          <ShowValue Label="Phone" setData={tenant.tenantMobile || "Nil"} />
        </div>
      </div>
    ))
  );

  return (
    <div>
      <div className="col-12 px-0 changedateantdstyle">
        <div className="col-12 px-0 daily-date-style">
          <SubNav title={"Property Details"} goBack={true} extraData={true} />
          <div className="material-work-desk-new bg-white m-3" style={{fontFamily:'Inter'}}>
            <div className="p-3">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="detailsLabel">Property</div>
              </div>
              <div className="mt-2 detailsContainer">
                <ShowValue Label='Address' setData={getDetails?.getFormatedAddress} />
                <ShowValue Label='Type' setData={getDetails?.sPropertyType} />
                <ShowValue Label='Attribute' setData={<>
                  <BiBed style={{ fontSize: '20px' }} />{"\u00A0"}{getDetails?.countBedrooms ? getDetails?.countBedrooms : 0 + " BedRoom"}
                  {"\u00A0\u00A0\u00A0"}
                  <BiBath style={{ fontSize: '20px' }} />{"\u00A0"}{getDetails?.countBathrooms ? getDetails?.countBathrooms : 0 + " BathRoom"}
                  {"\u00A0\u00A0\u00A0"}
                  <BiCar style={{ fontSize: '20px' }} />{"\u00A0"}{getDetails?.countCarParking ? getDetails?.countCarParking : 0 + " Parking Space"}
                </>} />
                <ShowValue Label='Assigned To' setData={getDetails?.agent_Name} />
                <ShowValue Label='Features' setData={getTrueProperties(getDetails?.addUpdatePropertyAttributesModel)} />
              </div>
            </div>
            <div className="p-3">
              {tenantCollapse && (
                <>
                  {getDetails?.tenantDetails?.length > 0 ?
                    <div>
                      <div className="d-flex justify-content-between">
                        <div className="detailsLabel">Tenant</div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="detailsCollapse mt-2" onClick={handleTenant}>
                          {tenantCollapse ? <IoIosArrowDown style={{ fontSize: "20px" }} /> : <IoIosArrowUp style={{ fontSize: "20px" }} />} Tenant Account Folio
                        </div>
                      </div>
                    </div> : null}
                  {renderTenantPortfolioDetails()}
                  {getDetails?.tenantDetails?.length > 0 ?
                    <div className="d-flex justify-content-between detailsCollapse mt-2">
                      <div>{tenantCollapse ? <IoIosArrowDown style={{ fontSize: "20px" }} /> : <IoIosArrowUp style={{ fontSize: "20px" }} />} Tenant Details</div>
                    </div> : null}
                  <div className="d-flex">
                    {renderTenantInfo("Primary Tenant", primarytenantDetails)}
                    {renderTenantInfo("Secondary Tenant", secondarytenantDetails)}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPropertyDetails;
