import React from 'react';
import { ApartmentOutlined, KeyOutlined, UnorderedListOutlined } from '@ant-design/icons';
import CustomModal from "../../input-components/Modal";
import PropertyDetailsAgency from "./PropertyDetailsAgency";
import PropertyOwnerShip from "./propertyOwnerShip";
import PropertyListings from "./propertyListings";

const PropertyModal = ({
  isVisible,
  onClose,
  propertyUID,
  currentStep,
  setCurrentStep,
  dpdata,
  getDetails,
  getDocDetails,
  resetAllPage,
  handleTabChange,
  listingType
}) => {
  return (
    <CustomModal
      footer={null}
      title={propertyUID ? "Update Property" : "Add Property"}
      width={800}
      closable={onClose}
      visible={isVisible}
      onCancel={onClose}      modalData={
        <div>
          <div className="custom-steps-container">
            <div className={`stepCustom ${currentStep >= 1 ? 'active' : ''}`}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="circle">
                  <ApartmentOutlined className="stepCustom-icon" />
                </div>
                <div className={`line ${currentStep >= 2 ? 'active' : ''}`}></div>
              </div>
              <h3>STEP 1</h3>
              <p className={currentStep === 1 ? 'active' : ''}>Property</p>
            </div>
            
            <div className={`stepCustom ${currentStep >= 2 ? 'active' : ''}`}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="circle">
                  <KeyOutlined className="stepCustom-icon" />
                </div>
                <div className={`line ${currentStep >= 3 ? 'active' : ''}`}></div>
              </div>
              <h3>STEP 2</h3>
              <p className={currentStep === 2 ? 'active' : ''}>Ownership</p>
            </div>
            
            <div className={`stepCustom ${currentStep >= 3 ? 'active' : ''}`}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="circle">
                  <UnorderedListOutlined className="stepCustom-icon" />
                </div>
              </div>
              <h3>STEP 3</h3>
              <p className={currentStep === 3 ? 'active' : ''}>Listings</p>
            </div>
          </div>

          {currentStep === 1 ? (
            <PropertyDetailsAgency 
              cancel={onClose} 
              handleCurrentPage={handleTabChange} 
              reportTo={dpdata} 
              resetAll={resetAllPage} 
              getDetails={getDetails} 
            />
          ) : currentStep === 2 ? (
            <PropertyOwnerShip 
              cancel={onClose} 
              getDetails={getDetails} 
              handleCurrentPage={handleTabChange} 
              getDocDetails={getDocDetails} 
            />
          ) : (
            <PropertyListings 
              cancel={onClose} 
              handleCurrentPage={handleTabChange} 
              getDetails={getDetails} 
              reportTo={dpdata} 
              listingType={listingType}
            />
          )}
        </div>
      }
    />
  );
};

export default PropertyModal;
