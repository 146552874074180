import React, { useRef, useState, useEffect } from "react";
import "./propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { uploadagreementfromproperty,AccessKey,BucketName,Keyid,Region,FilePath } from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner,uploadToS3WithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import { Spin } from "antd";
import Collapse from "../../input-components/collapse";
import { AiOutlineDelete } from "react-icons/ai";
import { uplodeFileSale } from "../../../components/CommonSection/Schema/schema";
import { useScrollToFirstError } from "../../../Utilities/ScrollToErrorField";
import SubNav from "../../navbar/subNav";

const initialValues = {
  AgreementStartDate : "",
  AgreementEndDate : "",
  ExpenseANDChargesEstimate1: "",
  SellingFixedPrice : "",
  SalePriceFlatFee : "",
  GSTInclusiveRenumeration : "",
};

const UploadAgreement = () => {
  let selectedFile;
  const [userId, setUserId] = useState("");
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("propertiesList");
  const [isDragging, setIsDragging] = useState(false);
  const [getDetails, setGetDetails] = useState(null);
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  var url = new URL(window.location.href);
  const ToastRef = useRef();
  const primaryDocumentRef = useRef(null);
  const [loading, setLoading] = useState(false); // Added loading state
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");

        if (userData) {
          const parsedData = JSON.parse(userData);

          setUserId(parsedData.userId);

        } else {

          navigate("/signin");
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };

    retrieveData();
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
  }, [userId]);


  useEffect(() => {
    if (getDetails) {
        setFieldValue("PropertyPrice", getDetails.propertyPrice)
    }
  }, [getDetails]);

  const handleTopicValue = (text) => {
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };


  const {
    errors,
    values,
    touched,
    isValid,
    submitCount,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: uplodeFileSale,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });
  const handleFinalSubmit = () => {
    var urls = new URL(window.location.href);
    var propertyId = urls.searchParams.get("propertyId");
    const formData = {
      PropertyId: propertyId,
      AgreementStartDate : values.AgreementStartDate,
      AgreementEndDate : values.AgreementEndDate,
      AgreementFilePath: values.AgreementFilePath,
      ExpenseANDChargesEstimate1: values.ExpenseANDChargesEstimate1,
      SellingFixedPrice : values.SellingFixedPrice,
      SalePriceFlatFee : values.SalePriceFlatFee,
      GSTInclusiveRenumeration : values.GSTInclusiveRenumeration,
    };

    const url = uploadagreementfromproperty;

    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          var url = new URL(window.location.href);
          var propertyUId = url.searchParams.get("propertyUID");
          dynamicUrl(navigate, "viewPropertyDetails" + `?propertyUID=${propertyUId}`);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })

      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };

  const handleFileChange = async (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      selectedFile = e.target.files[i];
      await handleUpload();
    }
  };

    const handleUpload = async () => {
      await uploadToS3WithSpinner(
        showSpinner,
        hideSpinner,
        "propertyagreement/",
        `propertyagreement`,
        selectedFile
      ).then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          const fileName1 = returnData.Key.split("/").pop();
          const transformedItem = {
            id: Math.floor(Math.random() * 1000000),
            src: FilePath + returnData.Key,
            width: 1,
            height: 1,
            fileName: fileName1,
          };
          setFileName(transformedItem.fileName)
          setFieldValue("AgreementFilePath", 
            returnData.Key,
          );
         
        } else {
          // handleparentsuccess([
          //   "error",
          //   "Error uploading file. Please try again.",
          // ]);
        }
      });
    };
  const handleFileUploader = (documentType) => {
      primaryDocumentRef.current.click();
  };

  const handlecancel = () => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
      dynamicUrl(navigate, "viewPropertyDetails" + `?propertyUID=${propertyUId}`);
  }

  const handledeletefile = () => {
   setFileName('')
  }

  useScrollToFirstError(isValid, errors, submitCount);
  
  return (
  <div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 changedateantdstyle">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 daily-date-style">
          <SubNav title={"Upload Agreements"} goback={true} extraData={true} />
          <ToastAlert ref={ToastRef} />
          <form onSubmit={handleSubmit} className="details-form" style={{ display: 'initial', width: '100%' }}>
          <div className="property-details-content df-all all-input">
          <Collapse
            id={"Agent"}
            title={"Upload Sales Agreement"}
            data={<div>
            <div className="contacts-group row">
            <TextBox isMandatory type={"date"} label="Agreement Start Date" placeholder="Agreement Start Date" name="AgreementStartDate" value={values.AgreementStartDate } onChange={handleChange} onBlur={handleBlur} error={errors.AgreementStartDate } touched={touched.AgreementStartDate } />
            <TextBox  isMandatory type={"date"} label="Agreement End Date"  placeholder="Agreement End Date" name="AgreementEndDate" value={values.AgreementEndDate} onChange={handleChange} onBlur={handleBlur} error={errors.AgreementEndDate} touched={touched.AgreementEndDate} />
            <TextBox  isMandatory label="Marketing Fee" placeholder="Marketing Fee" name="ExpenseANDChargesEstimate1" value={values.ExpenseANDChargesEstimate1} onChange={handleChange} onBlur={handleBlur} error={errors.ExpenseANDChargesEstimate1} touched={touched.ExpenseANDChargesEstimate1} />
            <TextBox  isMandatory label="Price" placeholder="Price" name="SellingFixedPrice" value={values.SellingFixedPrice} onChange={handleChange} onBlur={handleBlur} error={errors.SellingFixedPrice} touched={touched.SellingFixedPrice} />
            <TextBox  isMandatory label="Commission %" placeholder="Commission %" name="SalePriceFlatFee" value={values.SalePriceFlatFee} onChange={handleChange} onBlur={handleBlur} error={errors.SalePriceFlatFee} touched={touched.SalePriceFlatFee} />
            <TextBox  isMandatory label="Commission amount" placeholder="Commission amount" name="GSTInclusiveRenumeration" value={values.GSTInclusiveRenumeration} onChange={handleChange} onBlur={handleBlur} error={errors.GSTInclusiveRenumeration} touched={touched.GSTInclusiveRenumeration} />
            <div className="contacts-group col-md-5">
                <div className="s1 mb-2">Upload Agreement <span style={{color:'red'}}>*</span></div>
           <div className={`upload-section ${isDragging ? "dragging" : ""}`} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDragOver={handleDragOver}>
                <Spin spinning={loading}>
                  <div className="upload-setion-input">
                 {fileName === '' ? <div>
                 <img src="../../../assets/images/upload.png" alt="upload-icon" className="upload-icon" />
                    <span className="upload-select" onClick={() => handleFileUploader()}>Click to upload</span>
                    <input ref={primaryDocumentRef} type="file" onChange={(e) => handleFileChange(e, 0)} className="upload-input" name="upload" multiple />
                    or drag or drop
                  </div> : <div className="mt-3"><span className="upload-select">{fileName}</span><AiOutlineDelete onClick={handledeletefile} style={{fontSize:'18px',cursor:'pointer'}} /></div>}
                 </div>
                </Spin>
              </div>
           </div>
           {errors.AgreementFilePath && touched.AgreementFilePath ? <p className="ml-3 form-error">{errors.AgreementFilePath}</p> : null}

            </div>
            </div> }/>
          </div>

          <div className="property-details-buttons">
            <button
              className="pdb-cancel"
              type="reset"
              onClick={() =>
                handlecancel()
              }
            >
              Cancel
            </button>

            <button className="pdb-save-changes" type="submit">
              Save
            </button>
          </div>
        </form>
        </div>
      </div>
    </div>
  );
};

export default UploadAgreement;
